//  eslint-disable react-hooks/exhaustive-deps
import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  filterBy,
  CompositeFilterDescriptor,
} from "@progress/kendo-data-query";
import {
  Grid as KendoGrid,
  GridColumn as Column,
  GridCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  GridCustomHeaderCellProps,
  GridCustomCellProps,
} from "@progress/kendo-react-grid";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { HeaderThElement } from "@progress/kendo-react-data-tools";
import { Typography } from "@progress/kendo-react-common";
import { Button } from "@progress/kendo-react-buttons";
import { Switch, TextBox } from "@progress/kendo-react-inputs";
import { LoadingPanel } from "../../components/layout/Loading";
import IconButton from "../../components/common/IconButton";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import { MdDelete } from "react-icons/md";

import { closed } from "../../components/muidrawer/muiDrawerSlice";
import ButtonCreate from "../../components/muidrawer/ButtonCreate";
import AppDrawer from "../../components/muidrawer/Drawer";
import ButtonEdit from "../../components/muidrawer/ButtonEdit";
import { checkAcessRights } from "../../_helper/helper";
import {
  DELETE_ACCESS,
  EDIT_ACCESS,
  INSERT_ACCESS,
} from "../../_contstants/common";

import {
  competitorsActiveInactive,
  deleteCompetitors,
  getAllCompetitors,
} from "./services/competitorsServices";
import CreateCompetitors from "./CreateCompetitors";
import {
  clearCompetitorsID,
  clearFilterCompetitors,
  setCompetitorsID,
  setFilterCompetitors,
} from "./competitorsSlice";
import { filterClearIcon } from "@progress/kendo-svg-icons";
import _debounce from "lodash/debounce";
import { setCurrentPage, setPageLimit } from "../pagination/paginationSlice";
const Competitors: React.FC = () => {
  const isDrawerOpen = useAppSelector((state) => state.muidrawer.open);
  const drawerName = useAppSelector((state) => state.muidrawer.name);
  const isAddAccess = checkAcessRights(location.pathname, INSERT_ACCESS);
  return (
    <>
      <DeleteCompetitorsDialog />
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "4px 20px 20px 0",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            Competitor List
          </Typography.h4>
          {isAddAccess && (
            <ButtonCreate label="Add New" drawerName="competitorsForm" />
          )}
        </GridLayoutItem>
        <GridLayoutItem>
          <CompetitorsGridComponent />
        </GridLayoutItem>
        {isDrawerOpen && drawerName === "competitorsForm" && (
          <AppDrawer>
            <CreateCompetitors />
          </AppDrawer>
        )}
      </GridLayout>
    </>
  );
};

const CompetitorsGridComponent: React.FC = () => {
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const isEditAccess = checkAcessRights(location.pathname, EDIT_ACCESS);
  const isDeleteAccess = checkAcessRights(location.pathname, DELETE_ACCESS);

  const CompetitorsList = useAppSelector(
    (state) => state.competitors.CompetitorsList
  );
  const FilterCompetitors = useAppSelector(
    (state) => state.competitors.FilterCompetitors
  );
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);
  const isEditBtnRef = useRef<any>(false);
  const loading = useAppSelector((state) => state.competitors.loading);

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);

  useEffect(() => {
    return () => {
      dispatch(closed());
      dispatch(closeDialog());
      if (!isEditBtnRef.current) {
        dispatch(clearFilterCompetitors());
      }
    };
  }, []);
  useEffect(() => {
    const payload = {
      ...FilterCompetitors,
      limit: +pageLimit,
      skip: +currentPage * +pageLimit,
    };
    dispatch(getAllCompetitors(payload));
  }, [currentPage, pageLimit]);

  const pageChange = (event: GridPageChangeEvent) => {
    const newPage = event.page.skip / event.page.take;
    dispatch(setPageLimit(event.page.take));
    dispatch(setCurrentPage(newPage));
  };

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleOpenDeleteDialog = (competitors_guid: number) => {
      dispatch(openDialog("deleteCompetitors"));
      dispatch(setCompetitorsID(competitors_guid));
    };

    return (
      <td
        className="action-td"
        style={{
          ...props.style,
        }}
      >
        {isEditAccess && (
          <ButtonEdit
            ID={props.dataItem.competitors_guid}
            name="competitorsForm"
            tooltipTitle="Edit"
          />
        )}
        {isDeleteAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Delete"
            onClick={() =>
              handleOpenDeleteDialog(props.dataItem.competitors_guid)
            }
          >
            <MdDelete
              className="absolute-position"
              style={{ fontSize: "24px" }}
            />
          </IconButton>
        )}
      </td>
    );
  };

  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
  };
  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className="table-header"
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  const MyActiveInActtiveCommandCell = (props: GridCellProps) => {
    const [IsActive, setIsActive] = useState<boolean>(props.dataItem.isactive);

    const handleActiveInActive = () => {
      setIsActive(!IsActive);
      const data = {
        competitors_guid: props.dataItem.competitors_guid,
        isactive: !IsActive,
      };

      const payload = {
        ...FilterCompetitors,
        limit: +pageLimit,
        skip: +currentPage * +pageLimit,
      };
      dispatch(getAllCompetitors(payload));
      dispatch(competitorsActiveInactive(data)).then((response: any) => {
        if (response.payload?.data?.Status === 200) {
          dispatch(getAllCompetitors(payload));
        } else {
          dispatch(getAllCompetitors(payload));
        }
      });
    };

    return (
      <td style={{ textAlign: "center" }}>
        <Switch
          disabled={isEditAccess ? false : true}
          name="isactive"
          checked={IsActive}
          onChange={handleActiveInActive}
          size="small"
        />
      </td>
    );
  };

  // if (loading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <>
      {loading && <LoadingPanel gridRef={gridRef} />}

      <KendoGrid
        style={{ maxHeight: "calc(100vh - 152px)" }}
        className="responsive-table"
        filterable={true}
        filter={filter}
        data={filter ? filterBy(CompetitorsList, filter) : CompetitorsList}
        onFilterChange={handleFilterChange}
        skip={currentPage * pageLimit}
        take={pageLimit}
        total={CompetitorsList[0]?.itemcount || CompetitorsList?.length}
        pageable={{
          buttonCount: 5,
          pageSizes: [10, 50, 100, 300, 500],
          pageSizeValue: pageLimit,
          type: "input",
        }}
        onPageChange={pageChange}
        cells={{
          headerCell: HeaderCustomCell,
          data: MyDataCustomCell,
        }}
      >
        <Column
          field="competitors"
          filterCell={CommonFilterCell}
          title="Competitor Name"
          cell={(props: any) => (
            <td className={isEditAccess ? "hoverableName" : "normalName"}>
              {isEditAccess ? (
                <ButtonEdit
                  ID={props.dataItem.competitors_guid}
                  name="competitorsForm"
                  label={props.dataItem?.competitors}
                />
              ) : (
                props.dataItem?.competitors
              )}
            </td>
          )}
        />

        <Column
          field="id"
          title="Status"
          cell={MyActiveInActtiveCommandCell}
          width={"90px"}
          filterable={false}
          locked={true}
        />
        {(isEditAccess || isDeleteAccess) && (
          <Column
            field="id"
            title="Actions"
            cell={MyEditCommandCell}
            width={"110px"}
            filterable={false}
          />
        )}
      </KendoGrid>
    </>
  );
};

const DeleteCompetitorsDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const CompetitorsID = useAppSelector(
    (state) => state.competitors.CompetitorsID
  );
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const FilterCompetitors = useAppSelector(
    (state) => state.competitors.FilterCompetitors
  );
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);

  const handleDeleteAction = async (ID: number | null) => {
    if (ID) {
      const response = await dispatch(deleteCompetitors(ID));
      const payload = {
        ...FilterCompetitors,
        limit: +pageLimit,
        skip: +currentPage * +pageLimit,
      };
      dispatch(getAllCompetitors(payload));
      if (response.payload.Data.Status === 200) {
        dispatch(getAllCompetitors(payload));
        dispatch(closeDialog());
        dispatch(clearCompetitorsID());
      } else {
        dispatch(getAllCompetitors(payload));
        dispatch(closeDialog());
        dispatch(clearCompetitorsID());
      }
    }
  };

  return (
    <>
      {dialogName === "deleteCompetitors" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Competitors?"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this Competitors?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(CompetitorsID)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

const CommonFilterCell = (props: any) => {
  const dispatch = useAppDispatch();
  const FilterCompetitors: any = useAppSelector(
    (state) => state.competitors.FilterCompetitors
  );
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);
  const isFilterRef = useRef(true);
  const [inputValue, setInputValue] = useState(props.value || "");
  const [inputField, setInputField] = useState(props.field || "");

  const onChange = (event: any) => {
    setInputValue(event.target.value);
    setInputField(event.target.name);
  };

  const delayedApiCall = React.useCallback(
    _debounce((updatedFilters: any) => {
      const payload = {
        ...updatedFilters,
        limit: +pageLimit,
        skip: +currentPage * +pageLimit,
      };

      dispatch(getAllCompetitors(payload));
    }, 300),
    [dispatch]
  );

  useEffect(() => {
    if (!isFilterRef.current) {
      const updatedFilters = {
        ...FilterCompetitors,
        [inputField]: inputValue,
      };
      dispatch(setFilterCompetitors(updatedFilters));
      delayedApiCall(updatedFilters);
    } else {
      isFilterRef.current = false;
    }
    return () => {
      delayedApiCall.cancel();
    };
  }, [inputValue, delayedApiCall]);

  const onClearButtonClick = (event: any) => {
    event.preventDefault();
    props.onChange({
      value: null,
      operator: "",
      syntheticEvent: event,
    });

    const updatedFilters = { ...FilterCompetitors, [props.field]: "" };
    dispatch(setFilterCompetitors(updatedFilters));

    const payload = {
      ...updatedFilters,
      limit: +pageLimit,
      skip: +currentPage * +pageLimit,
    };

    dispatch(getAllCompetitors(payload));
  };

  return (
    <div className="d-flex">
      <TextBox
        value={FilterCompetitors[props.field] || ""}
        name={props.field || ""}
        onChange={onChange}
      />
      <Button
        svgIcon={filterClearIcon}
        title="Clear"
        disabled={!FilterCompetitors[props.field]}
        onClick={onClearButtonClick}
        type="button"
        style={{
          marginLeft: "8px",
        }}
      ></Button>
    </div>
  );
};

export default Competitors;
