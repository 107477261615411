import React, { useCallback, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import { Typography } from "@progress/kendo-react-common";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  FieldArray,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import FormTextField from "../../components/formFields/FormTextField";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import ShadowCard from "../../components/common/ShadowCard";
import InwardItemDetailsArray from "./InwardItemDetailsArray";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import FormDatePicker from "../../components/formFields/FormDateField";
import { clearInwardDetails, setInwardStateForGST } from "./inwardSlice";
import {
  getAccountByID,
  getAllAccountIncremental,
} from "../account/services/account.services";
import { getAllItemIncremental } from "../Item/services/item.services";
import { findAllActiveUnit } from "../unit/services/unit.services";
import {
  createInward,
  getInwardByID,
  updateInward,
} from "./services/inward.services";
import ButtonForAll from "../../components/common/Button";
import {
  getAllLimitedPurchaseOrders,
  getPurchaseNoPurchaseOrderByID,
} from "../purchaseorder/services/purchaseOrder.services";
import FormTimePicker from "../../components/formFields/FormTimeField";
import { findAllActiveWarehouse } from "../warehouse/services/warehouse.services";
import moment from "moment";
import FormRichTextField from "../../components/formFields/FormRichTextField";
import { clearItemList } from "../Item/itemSlice";
import {
  CONDITION,
  FINANCIAL_YEAR,
  GUJARATIDFORGST,
} from "../../_contstants/common";
import FormIncrementalSearch from "../../components/formFields/FormIncrementalSearch";
import { openDialog } from "../../components/dialog/dialogSlice";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import {
  calculateAmount,
  calculateGST,
  formatIndianNumber,
  formatIndianNumberForQty,
} from "../../_helper/helper";
import AlertBox from "../../components/common/AlertBox";
import { clearPurchaseOrderDetails } from "../purchaseorder/purchaseOrderSlice";
import { FaCheck } from "react-icons/fa";
import { getAllActiveGST } from "../gst/services/gst.services";
import { getInwardRegisterReport } from "../reports/services/reports.services";
import InwardDutyAndTaxArray from "./InwardDutyAndTaxArray";
import { getAllDutyAndTaxLedgeAccount } from "../purchase/services/purchase.services";
import FormNumericTextField from "../../components/formFields/FormNumericTextField";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}
const QtychangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  // const location = useLocation();
  const inward_items = formRenderProps.valueGetter("inward_items");
  // const isFetchGrnOption = React.useRef(true);
  // const inward_guid = location.state?.inward_guid;
  const UnitList = useAppSelector((state) => state.unit.UnitList);

  const inward_duty_and_taxes = formRenderProps.valueGetter(
    "inward_duty_and_taxes"
  );

  const taxable_amount = formRenderProps.valueGetter("taxable_amount");
  const sub_total = formRenderProps.valueGetter("sub_total");
  const round_off = formRenderProps.valueGetter("round_off");
  const InwardStateForGST = useAppSelector(
    (state) => state.inward.InwardStateForGST
  );

  useEffect(() => {
    let totalGSTamount = 0;
    let totalGST5 = 0;
    let totalGST12 = 0;
    let totalGST18 = 0;
    let totalGST28 = 0;

    inward_items?.map((item: any, index: number) => {
      const gst_id = item?.gst_id;
      const amount = item?.total;
      const gstid = gst_id ? parseInt(gst_id?.split("--")[1]) : 0;
      if (gstid === 5) {
        totalGST5 += (+amount * +gstid) / 100;
      } else if (gstid === 12) {
        totalGST12 += (+amount * +gstid) / 100;
      } else if (gstid === 18) {
        totalGST18 += (+amount * +gstid) / 100;
      } else if (gstid === 28) {
        totalGST28 += (+amount * +gstid) / 100;
      }
      totalGSTamount += (+amount * +gstid) / 100;
      if (!gst_id) {
        formRenderProps.onChange(`inward_items.${index}.gst_id`, {
          value: null,
        });
      }
    });

    formRenderProps.onChange("gst", {
      value: totalGSTamount || 0,
    });

    if (InwardStateForGST === GUJARATIDFORGST) {
      formRenderProps.onChange("cgst", {
        value: totalGSTamount / 2 || 0,
      });
      formRenderProps.onChange("sgst", {
        value: totalGSTamount / 2 || 0,
      });
    } else {
      formRenderProps.onChange("igst", {
        value: totalGSTamount || 0,
      });
    }
    formRenderProps.onChange("gst5", {
      value: totalGST5 || 0,
    });

    formRenderProps.onChange("gst12", {
      value: totalGST12 || 0,
    });
    formRenderProps.onChange("gst18", {
      value: totalGST18 || 0,
    });
    formRenderProps.onChange("gst28", {
      value: totalGST28 || 0,
    });

    // Account Implementation Starts
    const gstConfig = {
      2.5: (isGujarat: boolean) => (isGujarat ? totalGST5 / 2 : 0),
      5: (isGujarat: boolean) => (!isGujarat ? totalGST5 : 0),
      6: (isGujarat: boolean) => (isGujarat ? totalGST12 / 2 : 0),
      12: (isGujarat: boolean) => (!isGujarat ? totalGST12 : 0),
      9: (isGujarat: boolean) => (isGujarat ? totalGST18 / 2 : 0),
      18: (isGujarat: boolean) => (!isGujarat ? totalGST18 : 0),
      14: (isGujarat: boolean) => (isGujarat ? totalGST28 / 2 : 0),
      28: (isGujarat: boolean) => (!isGujarat ? totalGST28 : 0),
    };

    inward_duty_and_taxes?.forEach((item: any, index: number) => {
      const gst_per = item?.gst_per;
      // const ledger_name = item?.ledger_name;
      const ledger_data =
        inward_duty_and_taxes[index].ledger_options?.filter(
          (tax: any) => tax.id === item?.ledger_id
        ) || [];
      const ledger_name = ledger_data[0]?.tax_type || "";
      const typeOfSupply = ledger_data[0]?.type_of_supply || "";

      const isGujarat = InwardStateForGST === GUJARATIDFORGST;
      if (item?.ledger_id) {
        if (ledger_name?.toLowerCase()?.includes("gst")) {
          let gstAmount =
            gst_per && gstConfig[gst_per as keyof typeof gstConfig]
              ? gstConfig[gst_per as keyof typeof gstConfig](isGujarat)
              : 0;
          if (typeOfSupply === "Service") {
            if (item?.gst_per) {
              formRenderProps.onChange(
                `inward_duty_and_taxes.${index}.gst_amount`,
                {
                  value: (taxable_amount * item?.gst_per) / 100,
                }
              );
            }
          } else {
            formRenderProps.onChange(
              `inward_duty_and_taxes.${index}.gst_amount`,
              {
                value: gstAmount,
              }
            );
          }
        } else {
          if (gst_per) {
            formRenderProps.onChange(
              `inward_duty_and_taxes.${index}.gst_amount`,
              {
                value: (taxable_amount * gst_per) / 100,
              }
            );
          }
        }
      } else {
        formRenderProps.onChange(`inward_duty_and_taxes.${index}.gst_amount`, {
          value: 0,
        });
        formRenderProps.onChange(`inward_duty_and_taxes.${index}.gst_per`, {
          value: 0,
        });
      }
    });

    const grandTotals = inward_duty_and_taxes?.reduce(
      (acc: Record<string, number>, item: any, index: number) => {
        // const firstWord = item?.ledger_name?.split(" ")[0];
        const firstWord =
          inward_duty_and_taxes[index].ledger_options?.filter(
            (tax: any) => tax.id === item?.ledger_id
          )[0]?.tax_type || "";

        acc[firstWord] = (acc[firstWord] || 0) + item?.gst_amount;
        acc["duty_tax_total"] = (acc["duty_tax_total"] || 0) + item?.gst_amount;
        acc["sub_total"] =
          (acc["duty_tax_total"] || 0) + (+taxable_amount || 0);
        return acc;
      },
      {} as Record<string, number>
    );

    formRenderProps.onChange("sub_total", {
      value: grandTotals?.sub_total || 0,
    });

    if (InwardStateForGST === GUJARATIDFORGST) {
      formRenderProps.onChange("cgst", {
        value: grandTotals?.CGST || 0,
      });
      formRenderProps.onChange("sgst", {
        value: grandTotals?.SGST || 0,
      });
      formRenderProps.onChange("gst", {
        value: +(+grandTotals?.CGST + +grandTotals?.SGST)?.toFixed(2) || 0,
      });
    } else {
      formRenderProps.onChange("igst", {
        value: grandTotals?.IGST || 0,
      });
      formRenderProps.onChange("gst", {
        value: +grandTotals?.IGST?.toFixed(2) || 0,
      });
    }

    // Account Implementation Ends
  }, [
    InwardStateForGST,
    taxable_amount,
    inward_items?.map((item: any) => item?.gst_id).join("-"),
    inward_items?.map((item: any) => item?.total).join("-"),
    inward_duty_and_taxes?.map((item: any) => item?.ledger_id).join("-"),
    inward_duty_and_taxes?.map((item: any) => item?.gst_per).join("-"),
    inward_duty_and_taxes?.map((item: any) => item?.gst_amount).join("-"),
  ]);

  useEffect(() => {
    if (inward_items && inward_items.length > 0) {
      inward_items.forEach((item: any, index: number) => {
        const weight = parseFloat(
          item?.weight?.toFixed(item?.unit_decimal || 0) || "0"
        );
        const rate = item?.rate || 0;
        const discount = item?.discount || 0;
        const discountType = item?.discount_type || "₹";

        const gstIdStr = item?.gst_id;
        const gstid = gstIdStr ? parseInt(gstIdStr.split("--")[1], 10) : 0;

        const amount = calculateAmount(weight, rate, discount, discountType);
        const gstDetails = calculateGST(
          amount,
          gstid,
          InwardStateForGST === GUJARATIDFORGST
        );
        // Update the form fields
        const updates: Record<string, any> = {
          [`inward_items.${index}.total`]: { value: amount },
          [`inward_items.${index}.sgst_amount`]: {
            value: gstDetails.sgstAmount,
          },
          [`inward_items.${index}.cgst_amount`]: {
            value: gstDetails.cgstAmount,
          },
          [`inward_items.${index}.igst_amount`]: {
            value: gstDetails.igstAmount,
          },
          [`inward_items.${index}.total_gst_amount`]: {
            value:
              gstDetails.sgstAmount +
              gstDetails.cgstAmount +
              gstDetails.igstAmount,
          },
          [`inward_items.${index}.sgst_per`]: { value: gstDetails.sgstPer },
          [`inward_items.${index}.cgst_per`]: { value: gstDetails.cgstPer },
          [`inward_items.${index}.igst_per`]: { value: gstDetails.igstPer },
        };

        // Apply all updates
        Object.entries(updates).forEach(([key, update]) => {
          formRenderProps.onChange(key, update);
        });
      });
    }
  }, [
    InwardStateForGST,
    inward_items?.map((item: any) => item?.gst_id).join("-"),
    inward_items.map((item: any) => item?.weight).join("-"),
    inward_items.map((item: any) => item?.rate).join("-"),
    inward_items.map((item: any) => item?.discount).join("-"),
    inward_items.map((item: any) => item?.discount_type).join("-"),
    inward_items.map((item: any) => item?.unit_decimal).join("-"),
  ]);

  let totalamount = 0;
  let totalQty = 0;

  useEffect(() => {
    if (inward_items && inward_items.length > 0) {
      inward_items?.map((item: any) => {
        totalQty += +item?.weight || 0;
        totalamount += +item?.total || 0;
      });
    }
    formRenderProps.onChange("totalQty", {
      value: totalQty,
    });
    formRenderProps.onChange("taxable_amount", {
      value: totalamount,
    });
  }, [
    inward_items.map((item: any) => item?.weight).join("-"),
    inward_items.map((item: any) => item?.total).join("-"),
  ]);

  // useEffect(() => {
  //   const handleQtyChange = () => {
  //     // if (inward_items && inward_items.length > 0) {
  //     //   inward_items?.map((item: any, index: number) => {
  //     //     const weight =
  //     //       item?.weight?.toFixed(
  //     //         item?.unit_decimal ? item?.unit_decimal : 0
  //     //       ) || 0;

  //     //     const rate = item?.rate || 0;
  //     //     formRenderProps.onChange(`inward_items.${index}.total`, {
  //     //       value: +weight * +rate,
  //     //     });
  //     //   });
  //     // }
  //     if (inward_items && inward_items.length > 0) {
  //       inward_items?.map((item: any, index: number) => {
  //         const weight =
  //           item?.weight?.toFixed(
  //             item?.unit_decimal ? item?.unit_decimal : 0
  //           ) || 0;
  //         const rate = item?.rate || 0;
  //         const discount = item?.discount || 0;
  //         const discount_type = item?.discount_type || "₹";
  //         if (discount_type && discount_type === "₹") {
  //           formRenderProps.onChange(`inward_items.${index}.total`, {
  //             value:
  //               +weight * +rate > +discount ? +weight * +rate - +discount : 0,
  //           });
  //         } else if (discount_type && discount_type === "%") {
  //           let total = +weight * +rate;
  //           formRenderProps.onChange(`inward_items.${index}.total`, {
  //             value:
  //               +weight * +rate > (+total * +discount) / 100
  //                 ? +weight * +rate - (+total * +discount) / 100
  //                 : 0,
  //           });
  //         } else {
  //           formRenderProps.onChange(`inward_items.${index}.total`, {
  //             value: +weight * +rate,
  //           });
  //         }
  //       });
  //     }
  //   };

  //   if (isFetchGrnOption.current && inward_guid) {
  //     isFetchGrnOption.current = false;
  //   } else {
  //     handleQtyChange();
  //   }
  // }, [
  //   inward_items.map((item: any) => item?.weight).join("-"),
  //   inward_items.map((item: any) => item?.rate).join("-"),
  //   inward_items.map((item: any) => item?.unit_decimal).join("-"),
  //   inward_items.map((item: any) => item?.discount).join("-"),
  //   inward_items.map((item: any) => item?.discount_type).join("-"),
  // ]);

  useEffect(() => {
    if (inward_items && inward_items.length > 0) {
      inward_items?.map((item: any, index: number) => {
        const unit_id = item?.unit_id;

        const unit = UnitList.find((unit: any) => unit?.id === unit_id);
        if (unit) {
          formRenderProps.onChange(`inward_items.${index}.unit_decimal`, {
            value: unit?.unit_decimal || 0,
          });
        } else {
          formRenderProps.onChange(`inward_items.${index}.unit_decimal`, {
            value: 0,
          });
        }
      });
    }
  }, [inward_items.map((item: any) => item?.unit_id).join("-")]);

  // let totalTaxableAmount = 0;

  // useEffect(() => {
  //   if (inward_items && inward_items.length > 0) {
  //     inward_items?.map((item: any) => {
  //       totalTaxableAmount += +item?.total || 0;
  //     });
  //   }
  //   formRenderProps.onChange("total_amount", {
  //     value: totalTaxableAmount,
  //   });
  // }, [inward_items.map((item: any) => item?.total).join("-")]);

  useEffect(() => {
    formRenderProps.onChange("round_off", {
      value: Math.round(+sub_total) - +sub_total,
    });
    formRenderProps.onChange("grand_total", {
      value: +sub_total + +round_off || 0,
    });
  }, [sub_total, round_off]);

  return null;
};

const VendorChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const inward_guid = location.state?.inward_guid;
  const vendor_id = formRenderProps.valueGetter("vendor_id");
  const isVendorRef = useRef(true);

  useEffect(() => {
    if (vendor_id) {
      const handleVendorChange = async () => {
        const payload = {
          id: vendor_id,
        };
        const response = await dispatch(getAccountByID(payload));
        if (response.meta.requestStatus === "fulfilled") {
          dispatch(setInwardStateForGST(response.payload?.state_id));
          formRenderProps.onChange("state_id", {
            value: response.payload?.state_id,
          });
        } else {
          formRenderProps.onChange("state_id", {
            value: null,
          });
        }
      };
      if (!isVendorRef.current) {
        handleVendorChange();
      } else {
        if (!inward_guid) {
          handleVendorChange();
        }
        isVendorRef.current = false;
      }
    } else {
      dispatch(setInwardStateForGST(null));
      formRenderProps.onChange("state_id", {
        value: "",
      });
    }
  }, [vendor_id]);

  return null;
};

// const PONoChangeWatcher: React.FC<FormChangeWatcherProps> = ({
//   formRenderProps,
// }) => {
//   const dispatch = useAppDispatch();

//   const po_no = formRenderProps.valueGetter("po_no");

//   const handlePONoChange = async (
//   ) => {
//     if (po_no) {
//       const payload = {
//         po_no: po_no,
//         financial_account_year: FINANCIAL_YEAR,
//       };
//       const response = await dispatch(getPurchaseNoPurchaseOrderByID(payload));
//       if (response.meta.requestStatus === "fulfilled") {
//         formRenderProps.onChange("vendor_id", {
//           value: response.payload?.vendor_id,
//         });
//         const inwardItems =
//           response.payload?.purchase_order_items &&
//           response.payload?.purchase_order_items.length > 0 &&
//           response.payload?.purchase_order_items?.map((item: any) => {
//             return {
//               item_id: item?.item_id,
//               weight: item?.qty,
//               rate: item?.rate,
//               unit_id: item?.unit_id,
//               cold_storage_id: item?.cold_storage_id,
//             };
//           });
//         formRenderProps.onChange("inward_items", {
//           value: inwardItems || [],
//         });
//       } else {
//         formRenderProps.onChange("vendor_id", {
//           value: null,
//         });
//         formRenderProps.onChange("inward_items", {
//           value: [],
//         });
//       }
//     }
//   };

//   useEffect(() => {
//     handlePONoChange();
//   }, [po_no]);

//   return null;
// };

const CreateInward: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const inward_guid = location.state?.inward_guid;
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.inward.loading);
  const InwardDetail = useAppSelector((state) => state.inward.InwardDetail);
  const PurchaseOrderList = useAppSelector(
    (state) => state.purchaseOrder.PurchaseOrderList
  );
  const InwardRegisterDetails = useAppSelector(
    (state) => state.reports.InwardRegisterDetails
  );

  const [formKey, setFormKey] = React.useState(1);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [InwardDetail]);

  useEffect(() => {
    if (inward_guid) {
      const payload = {
        inward_guid: inward_guid,
      };
      dispatch(getInwardByID(payload));
    }
  }, [inward_guid]);

  useEffect(() => {
    const payload = {
      account_name: "",
      gst_no: "",
      financial_account_year: FINANCIAL_YEAR,
      limit: 50,
      skip: 0,
    };

    dispatch(findAllActiveUnit());
    dispatch(getAllActiveGST());
    dispatch(findAllActiveWarehouse());
    dispatch(getAllLimitedPurchaseOrders(payload));

    return () => {
      dispatch(clearInwardDetails());
      dispatch(clearPurchaseOrderDetails());
      dispatch(clearItemList());
    };
  }, []);

  useEffect(() => {
    const fetchAccountDetails = async () => {
      const payload = {
        id: InwardDetail?.vendor_id,
      };
      const response = await dispatch(getAccountByID(payload));
      if (response.meta.requestStatus === "fulfilled") {
        dispatch(setInwardStateForGST(response.payload?.state_id));
      } else {
        dispatch(setInwardStateForGST(null));
      }
    };
    if (InwardDetail?.vendor_id) {
      fetchAccountDetails();
    }
  }, [InwardDetail]);

  const handlePONoChange = async (
    PONo: any,
    formRenderProps: FormRenderProps
  ) => {
    if (PONo) {
      const payload = {
        po_no: PONo,
        financial_account_year: FINANCIAL_YEAR,
      };
      const response = await dispatch(getPurchaseNoPurchaseOrderByID(payload));
      if (response.meta.requestStatus === "fulfilled") {
        formRenderProps.onChange("vendor_id", {
          value: response.payload?.vendor_id,
        });
        formRenderProps.onChange("vendor_options", {
          value: response.payload?.vendor_options,
        });
        formRenderProps.onChange("po_date", {
          value: response.payload?.po_date,
        });
        const inwardItems =
          response.payload?.purchase_order_items &&
          response.payload?.purchase_order_items.length > 0 &&
          response.payload?.purchase_order_items
            ?.filter((item: any) => !item?.isdelivered)
            ?.map((item: any) => {
              return {
                item_id: item?.item_id,
                item_options: item?.item_options,
                weight: item?.qty,
                rate: item?.rate,
                // gst_id: item?.gst_id_new,
                gst_id: `${item?.gst_id_new}--${item?.gst || "0"}`,
                dagina: item?.dagina,
                unit_id: item?.unit_id,
                unit_decimal: item?.unit_decimal,
                discount: item?.discount ? item?.discount : null,
                discount_type: item?.discount_type,
                cold_storage_id: item?.cold_storage_id,
                // total: item?.qty * item?.rate,
                total: item?.totalAmount,
              };
            });
        formRenderProps.onChange("inward_items", {
          value: inwardItems || [],
        });
      } else {
        formRenderProps.onChange("vendor_id", {
          value: null,
        });
        formRenderProps.onChange("vendor_options", {
          value: [],
        });
        formRenderProps.onChange("po_date", {
          value: "",
        });
        formRenderProps.onChange("inward_items", {
          value: [],
        });
      }
    }
  };

  const handleItemSearchChange = useCallback(
    async (search: string, field: string, formRenderProps: FormRenderProps) => {
      const result = await dispatch(getAllItemIncremental({ search }));
      formRenderProps.onChange(field, {
        value: result.payload,
      });
    },
    [dispatch]
  );

  const handleVendorSearchChange = useCallback(
    async (search: string, field: string, formRenderProps: FormRenderProps) => {
      const result = await dispatch(getAllAccountIncremental(search));
      formRenderProps.onChange(field, {
        value: result.payload,
      });
    },
    [dispatch]
  );

  const handleLedgerSearchChange = useCallback(
    async (search: string, field: string, formRenderProps: FormRenderProps) => {
      const result = await dispatch(getAllDutyAndTaxLedgeAccount(search));
      formRenderProps.onChange(field, {
        value: result.payload,
      });
    },
    [dispatch]
  );

  const handleSubmit = async (values: any) => {
    if (inward_guid) {
      try {
        const updatePayload = {
          inward_guid: InwardDetail?.inward_guid,
          po_no: values?.po_no ? +values?.po_no : null,
          po_date: values?.po_date
            ? moment(values?.po_date).format("YYYY-MM-DD")
            : "",
          inward_date: values?.inward_date
            ? moment(values?.inward_date).format("YYYY-MM-DD")
            : "",
          bill_no: values?.bill_no ? values?.bill_no : "",
          vendor_id: values?.vendor_id ? +values?.vendor_id : null,
          vehicle_no: values?.vehicle_no ? values?.vehicle_no : "",
          remarks: values?.remarks ? values?.remarks : "",
          chalan_no: values?.chalan_no ? values?.chalan_no : "",
          bill_date: values?.bill_date
            ? moment(values?.bill_date).format("YYYY-MM-DD")
            : "",
          vehicle_in_time: values?.vehicle_in_time
            ? moment(values?.vehicle_in_time).format("HH:mm:ss")
            : "",
          vehicle_out_time: values?.vehicle_out_time
            ? moment(values?.vehicle_out_time).format("HH:mm:ss")
            : "",
          financial_year: FINANCIAL_YEAR,
          vehicle_condition: values?.vehicle_condition
            ? values?.vehicle_condition
            : "",
          region: values?.region ? values?.region : "",
          // total_amount: values?.total_amount ? values?.total_amount : null,

          total_amount: values?.grand_total ? +values?.grand_total : null,
          taxable_amount: values?.taxable_amount
            ? values?.taxable_amount
            : null,
          sub_total: values?.sub_total ? +values?.sub_total?.toFixed(2) : null,
          round_off: values?.round_off ? +values?.round_off?.toFixed(2) : null,

          inward_items: values?.inward_items
            ? values?.inward_items?.map((inwarditem: any) => {
                return {
                  id: inwarditem?.id ? +inwarditem?.id : 0,
                  item_id: inwarditem?.item_id ? +inwarditem?.item_id : null,
                  gst_id: inwarditem?.gst_id
                    ? parseInt(inwarditem?.gst_id.split("--")[0])
                    : null,
                  dagina: inwarditem?.dagina ? +inwarditem?.dagina : null,
                  weight: inwarditem?.weight
                    ? +inwarditem?.weight?.toFixed(
                        inwarditem.unit_decimal ? inwarditem.unit_decimal : 0
                      )
                    : 0,
                  discount: inwarditem?.discount ? inwarditem?.discount : null,
                  discount_type: inwarditem?.discount_type === "₹" ? 1 : 2,
                  sgst_per: inwarditem?.sgst_per
                    ? +inwarditem?.sgst_per?.toFixed(2)
                    : 0,
                  sgst_amount: inwarditem?.sgst_amount
                    ? +inwarditem?.sgst_amount?.toFixed(2)
                    : 0,
                  cgst_per: inwarditem?.cgst_per
                    ? +inwarditem?.cgst_per?.toFixed(2)
                    : 0,
                  cgst_amount: inwarditem?.cgst_amount
                    ? +inwarditem?.cgst_amount?.toFixed(2)
                    : 0,
                  igst_per: inwarditem?.igst_per
                    ? +inwarditem?.igst_per?.toFixed(2)
                    : 0,
                  igst_amount: inwarditem?.igst_amount
                    ? +inwarditem?.igst_amount?.toFixed(2)
                    : 0,
                  total_gst_amount: inwarditem?.total_gst_amount
                    ? +inwarditem?.total_gst_amount?.toFixed(2)
                    : 0,
                  rate: inwarditem?.rate ? +inwarditem?.rate : null,
                  total: inwarditem?.total ? +inwarditem?.total : null,
                  cold_storage_id: inwarditem?.cold_storage_id
                    ? +inwarditem?.cold_storage_id
                    : null,
                  receipt_no: inwarditem?.receipt_no
                    ? inwarditem?.receipt_no
                    : "",
                  unit_id: inwarditem?.unit_id ? +inwarditem?.unit_id : null,
                };
              })
            : [],
          inward_duty_and_taxes: values?.inward_duty_and_taxes
            ? values.inward_duty_and_taxes
                .filter(
                  (inwardduty: any) =>
                    inwardduty?.ledger_id && inwardduty?.gst_amount
                )
                .map((inwardduty: any) => ({
                  ledger_id: +inwardduty.ledger_id,
                  gst_per: inwardduty?.gst_per ? +inwardduty.gst_per : null,
                  gst_amount: inwardduty?.gst_amount
                    ? +inwardduty.gst_amount
                    : null,
                }))
            : [],
        };
        const response = await dispatch(updateInward(updatePayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          if (InwardRegisterDetails?.isInwardRef) {
            const payload = {
              item_id: InwardRegisterDetails?.item_id
                ? InwardRegisterDetails?.item_id
                : null,
              start_date: InwardRegisterDetails?.start_date
                ? moment(InwardRegisterDetails?.start_date).format("YYYY-MM-DD")
                : null,
              end_Date: InwardRegisterDetails?.end_Date
                ? moment(InwardRegisterDetails?.end_Date).format("YYYY-MM-DD")
                : null,
            };
            dispatch(getInwardRegisterReport(payload));
          }
          navigate(-1);
          // navigate("/inward");
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    } else {
      try {
        const insertPayload = {
          po_no: values?.po_no ? +values?.po_no : null,
          po_date: values?.po_date
            ? moment(values?.po_date).format("YYYY-MM-DD")
            : "",
          inward_date: values?.inward_date
            ? moment(values?.inward_date).format("YYYY-MM-DD")
            : "",
          bill_no: values?.bill_no ? values?.bill_no : "",
          vendor_id: values?.vendor_id ? +values?.vendor_id : null,
          vehicle_no: values?.vehicle_no ? values?.vehicle_no : "",
          remarks: values?.remarks ? values?.remarks : "",
          chalan_no: values?.chalan_no ? values?.chalan_no : "",
          bill_date: values?.bill_date
            ? moment(values?.bill_date).format("YYYY-MM-DD")
            : "",
          vehicle_in_time: values?.vehicle_in_time
            ? moment(values?.vehicle_in_time).format("HH:mm:ss")
            : "",
          vehicle_out_time: values?.vehicle_out_time
            ? moment(values?.vehicle_out_time).format("HH:mm:ss")
            : "",
          vehicle_condition: values?.vehicle_condition
            ? values?.vehicle_condition
            : "",
          region: values?.region ? values?.region : "",
          financial_year: FINANCIAL_YEAR,
          // total_amount: values?.total_amount ? values?.total_amount : null,

          total_amount: values?.grand_total ? +values?.grand_total : null,
          taxable_amount: values?.taxable_amount
            ? values?.taxable_amount
            : null,
          sub_total: values?.sub_total ? +values?.sub_total?.toFixed(2) : null,
          round_off: values?.round_off ? +values?.round_off?.toFixed(2) : null,

          inward_items: values?.inward_items
            ? values?.inward_items?.map((inwarditem: any) => {
                return {
                  item_id: inwarditem?.item_id ? +inwarditem?.item_id : null,
                  gst_id: inwarditem?.gst_id
                    ? parseInt(inwarditem?.gst_id.split("--")[0])
                    : null,
                  dagina: inwarditem?.dagina ? +inwarditem?.dagina : null,
                  weight: inwarditem?.weight
                    ? +inwarditem?.weight?.toFixed(
                        inwarditem.unit_decimal ? inwarditem.unit_decimal : 0
                      )
                    : 0,
                  discount: inwarditem?.discount ? inwarditem?.discount : null,
                  discount_type: inwarditem?.discount_type === "₹" ? 1 : 2,
                  sgst_per: inwarditem?.sgst_per
                    ? +inwarditem?.sgst_per?.toFixed(2)
                    : 0,
                  sgst_amount: inwarditem?.sgst_amount
                    ? +inwarditem?.sgst_amount?.toFixed(2)
                    : 0,
                  cgst_per: inwarditem?.cgst_per
                    ? +inwarditem?.cgst_per?.toFixed(2)
                    : 0,
                  cgst_amount: inwarditem?.cgst_amount
                    ? +inwarditem?.cgst_amount?.toFixed(2)
                    : 0,
                  igst_per: inwarditem?.igst_per
                    ? +inwarditem?.igst_per?.toFixed(2)
                    : 0,
                  igst_amount: inwarditem?.igst_amount
                    ? +inwarditem?.igst_amount?.toFixed(2)
                    : 0,
                  total_gst_amount: inwarditem?.total_gst_amount
                    ? +inwarditem?.total_gst_amount?.toFixed(2)
                    : 0,
                  rate: inwarditem?.rate ? +inwarditem?.rate : null,
                  total: inwarditem?.total ? +inwarditem?.total : null,
                  cold_storage_id: inwarditem?.cold_storage_id
                    ? +inwarditem?.cold_storage_id
                    : null,
                  receipt_no: inwarditem?.receipt_no
                    ? inwarditem?.receipt_no
                    : "",
                  unit_id: inwarditem?.unit_id ? +inwarditem?.unit_id : null,
                };
              })
            : [],
          inward_duty_and_taxes: values?.inward_duty_and_taxes
            ? values.inward_duty_and_taxes
                .filter(
                  (inwardduty: any) =>
                    inwardduty?.ledger_id && inwardduty?.gst_amount
                )
                .map((inwardduty: any) => ({
                  ledger_id: +inwardduty.ledger_id,
                  gst_per: inwardduty?.gst_per ? +inwardduty.gst_per : null,
                  gst_amount: inwardduty?.gst_amount
                    ? +inwardduty.gst_amount
                    : null,
                }))
            : [],
        };
        // console.log("insertPayload", insertPayload);
        const response = await dispatch(createInward(insertPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          // navigate("/inward");
          navigate(-1);
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    }
  };

  const handleInwardNoChange = (ev: React.KeyboardEvent<HTMLInputElement>) => {
    if (ev.key === "Enter" || ev.key === "Tab") {
      ev.preventDefault();
      if ((ev.target as HTMLInputElement).value) {
        const payload = {
          inward_no: (ev.target as HTMLInputElement).value,
          financial_year: FINANCIAL_YEAR,
        };
        dispatch(getInwardByID(payload));
      }
    }
  };

  const handlePONoView = async (ev: any) => {
    if (ev?.value) {
      const payload = {
        po_no: ev?.value,
        financial_account_year: FINANCIAL_YEAR,
      };
      const res = await dispatch(getPurchaseNoPurchaseOrderByID(payload));
      if (res.meta?.requestStatus === "fulfilled") {
        dispatch(openDialog("poItemdetailsView"));
      }
    }
  };

  return (
    <>
      {loading && <LoadingPanel gridRef={gridRef} />}
      <POChildItemDialog />
      <Form
        key={formKey}
        onSubmit={handleSubmit}
        initialValues={InwardDetail}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12 }}>
              <GridLayout
                style={{ marginRight: 30 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                ]}
              >
                <GridLayoutItem colSpan={4}>
                  <Typography.h4>
                    {inward_guid ? "Update Inward" : "Add Inward"}
                  </Typography.h4>
                </GridLayoutItem>
                {inward_guid && (
                  <GridLayoutItem>
                    <Field
                      name="inward_no"
                      onKeyDown={handleInwardNoChange}
                      label="Inward No"
                      placeholder="Inward No"
                      component={FormTextField}
                      validator={requiredValidator}
                      astrike={true}
                    />
                  </GridLayoutItem>
                )}
                {inward_guid && <GridLayoutItem colSpan={3}></GridLayoutItem>}
                {inward_guid ? (
                  <GridLayoutItem colSpan={2}>
                    <Field
                      name="po_no_label"
                      label="Purchase Order No"
                      placeholder="Purchase Order No"
                      disabled={true}
                      component={FormTextField}
                      validator={requiredValidator}
                      astrike={true}
                    />
                  </GridLayoutItem>
                ) : (
                  <>
                    <GridLayoutItem>
                      <Field
                        name="po_no"
                        label="Purchase Order No"
                        placeholder="Purchase Order No"
                        disabled={inward_guid ? true : false}
                        component={FormSelectionField}
                        validator={requiredValidator}
                        onChange={handlePONoView}
                        astrike={true}
                        options={PurchaseOrderList?.map((po: any) => {
                          return {
                            value: po?.po_no,
                            label: `${po?.po_no} - ${po?.account_name}`,
                          };
                        })}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem className="position-relative">
                      <ButtonForAll
                        label={"View"}
                        disabled={inward_guid ? true : false}
                        style={{ position: "absolute", top: 35 }}
                        onClick={() =>
                          handlePONoChange(
                            formRenderProps.valueGetter("po_no"),
                            formRenderProps
                          )
                        }
                      />
                    </GridLayoutItem>
                  </>
                )}
                <GridLayoutItem>
                  <Field
                    name="inward_date"
                    label="Inward Date"
                    format="dd/MM/yyyy"
                    component={FormDatePicker}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="po_date"
                    label="PO Date"
                    format="dd/MM/yyyy"
                    disabled={true}
                    component={FormDatePicker}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="vendor_id"
                    label="Vendor"
                    placeholder="Party Name"
                    isAddNew={true}
                    addNewLink="account/create"
                    component={FormIncrementalSearch}
                    validator={requiredValidator}
                    astrike={true}
                    fetchIncrementalData={(search: any) =>
                      handleVendorSearchChange(
                        search,
                        `vendor_options`,
                        formRenderProps
                      )
                    }
                    options={
                      formRenderProps
                        .valueGetter("vendor_options")
                        ?.map((item: any) => {
                          return {
                            value: item.id,
                            label: item.account_name,
                          };
                        }) || []
                    }
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="chalan_no"
                    label="Chalan No"
                    placeholder="Chalan No"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="bill_no"
                    label="Bill No."
                    placeholder="Bill No."
                    component={FormTextField}
                    // validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="bill_date"
                    label="Bill Date"
                    format="dd/MM/yyyy"
                    component={FormDatePicker}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="vehicle_in_time"
                    label="Vehicle In Time"
                    component={FormTimePicker}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="vehicle_out_time"
                    label="Vehicle Out Time"
                    component={FormTimePicker}
                  />
                </GridLayoutItem>
                <GridLayoutItem colSpan={2} rowSpan={3}>
                  <Field
                    name="remarks"
                    label="Remarks"
                    placeholder="Remarks"
                    component={FormRichTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="vehicle_no"
                    label="Vehicle No."
                    placeholder="GJ01AA1111"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="vehicle_condition"
                    label="Vehicle Condition"
                    placeholder="Vehicle Condition"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    astrike={true}
                    options={CONDITION?.map((condition: any) => {
                      return {
                        value: condition?.value,
                        label: condition?.label,
                      };
                    })}
                  />
                </GridLayoutItem>
                {formRenderProps.valueGetter("vehicle_condition") ===
                  "NOT OK" && (
                  <GridLayoutItem colSpan={2}>
                    <Field
                      name="region"
                      label="Reason"
                      placeholder="Reason"
                      component={FormTextField}
                      validator={requiredValidator}
                      astrike={true}
                    />
                  </GridLayoutItem>
                )}
                <QtychangeWatcher formRenderProps={formRenderProps} />
                <VendorChangeWatcher formRenderProps={formRenderProps} />
              </GridLayout>
            </ShadowCard>
            <ShadowCard style={{ padding: 12, marginTop: 10 }}>
              <GridLayout
                style={{ marginRight: 30 }}
                gap={{ rows: 10, cols: 10 }}
                cols={[
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                ]}
              >
                <GridLayoutItem colSpan={4}>
                  <Typography.h4>Item Details</Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={4}
                  style={{ borderBottom: "1px solid lightgray" }}
                >
                  <FieldArray
                    formRenderProps={formRenderProps}
                    handleItemSearchChange={handleItemSearchChange}
                    component={InwardItemDetailsArray}
                    name="inward_items"
                  />
                </GridLayoutItem>
                <GridLayoutItem colSpan={2}></GridLayoutItem>
                <GridLayoutItem
                  colSpan={2}
                  style={{ display: "flex", alignItems: "end" }}
                >
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      <tr>
                        <td
                          style={{
                            width: "50%",
                            textAlign: "end",
                            fontWeight: 600,
                          }}
                        >
                          Total
                        </td>
                        <td style={{ width: "5%", textAlign: "center" }}>:</td>
                        <td style={{ width: "45%" }}>
                          <Field
                            wrapperClassName="calculation-label"
                            wrapperStyle={{ margin: 0, width: "100%" }}
                            name="taxable_amount"
                            format="n2"
                            component={FormNumericTextField}
                            disabled="true"
                          />
                        </td>
                      </tr>
                    </table>
                  </div>
                </GridLayoutItem>
                <GridLayoutItem colSpan={4}>
                  <FieldArray
                    formRenderProps={formRenderProps}
                    handleLedgerSearchChange={handleLedgerSearchChange}
                    component={InwardDutyAndTaxArray}
                    name="inward_duty_and_taxes"
                  />
                </GridLayoutItem>
                <GridLayoutItem colSpan={2}></GridLayoutItem>

                <GridLayoutItem
                  colSpan={2}
                  style={{ display: "flex", alignItems: "end" }}
                >
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      <tr>
                        <td
                          style={{
                            width: "50%",
                            textAlign: "end",
                            fontWeight: 600,
                          }}
                        >
                          Sub Total
                        </td>
                        <td style={{ width: "5%", textAlign: "center" }}>:</td>
                        <td style={{ width: "45%" }}>
                          <Field
                            wrapperClassName="calculation-label"
                            wrapperStyle={{ margin: 0, width: "100%" }}
                            name="sub_total"
                            placeholder="0"
                            disabled={true}
                            format="n2"
                            component={FormNumericTextField}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            width: "50%",
                            textAlign: "end",
                            fontWeight: 600,
                          }}
                        >
                          Round Off
                        </td>
                        <td style={{ width: "5%", textAlign: "center" }}>:</td>
                        <td style={{ width: "45%" }}>
                          <Field
                            wrapperClassName="calculation-label"
                            wrapperStyle={{ margin: 0, width: "100%" }}
                            name="round_off"
                            placeholder="0"
                            disabled={true}
                            format="n2"
                            component={FormNumericTextField}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            width: "50%",
                            textAlign: "end",
                            fontWeight: 600,
                          }}
                        >
                          Grand Total
                        </td>
                        <td style={{ width: "5%", textAlign: "center" }}>:</td>
                        <td style={{ width: "45%" }}>
                          <Field
                            wrapperClassName="calculation-label"
                            wrapperStyle={{ margin: 0, width: "100%" }}
                            name="grand_total"
                            placeholder="0"
                            disabled={true}
                            format="n2"
                            component={FormNumericTextField}
                          />
                        </td>
                      </tr>
                    </table>
                  </div>
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={4}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "end",
                  }}
                >
                  <div>
                    <ButtonWithLoading
                      label={inward_guid ? "Update" : "Save"}
                      type="submit"
                      disabled={!formRenderProps.allowSubmit || loading}
                      loading={loading}
                    />
                    <Button
                      type="button"
                      fillMode={"outline"}
                      themeColor={"primary"}
                      style={{ marginLeft: 4 }}
                      onClick={
                        () => navigate(-1)
                        //  navigate("/inward")
                      }
                    >
                      Cancel
                    </Button>
                  </div>
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
          </FormElement>
        )}
      />
    </>
  );
};

const POChildItemDialog: React.FC = () => {
  const gridRef = useRef<any>(null);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const PurchaseOrderDetail = useAppSelector(
    (state) => state.purchaseOrder.PurchaseOrderDetail
  );
  const loading = useAppSelector((state) => state.purchaseOrder.loading);

  let total_qty = 0;
  let total_dagina = 0;
  let total_amount = 0;

  PurchaseOrderDetail &&
    PurchaseOrderDetail?.purchase_order_items &&
    PurchaseOrderDetail?.purchase_order_items.length > 0 &&
    PurchaseOrderDetail?.purchase_order_items?.forEach((item: any) => {
      total_qty += item?.qty || 0;
      total_dagina += item?.dagina || 0;
      total_amount += item?.totalAmount || 0;
    });

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      {dialogName === "poItemdetailsView" && (
        <AppDialog className="po-items-dialog">
          <>
            <Typography.h5>{"Purchase Order Items"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                {PurchaseOrderDetail &&
                PurchaseOrderDetail?.purchase_order_items &&
                PurchaseOrderDetail?.purchase_order_items?.length > 0 ? (
                  <div
                    className="table-responsive m-0"
                    style={{
                      maxHeight: "calc(100vh - 227px)",
                      scrollbarWidth: "thin",
                    }}
                  >
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th style={{ background: "black", color: "white" }}>
                            No
                          </th>
                          <th style={{ background: "black", color: "white" }}>
                            Item
                          </th>
                          <th style={{ background: "black", color: "white" }}>
                            Item Category
                          </th>
                          <th style={{ background: "black", color: "white" }}>
                            Qty
                          </th>
                          <th style={{ background: "black", color: "white" }}>
                            Rate
                          </th>
                          <th style={{ background: "black", color: "white" }}>
                            Unit
                          </th>
                          <th style={{ background: "black", color: "white" }}>
                            Dagina
                          </th>
                          <th style={{ background: "black", color: "white" }}>
                            Discount
                          </th>
                          <th style={{ background: "black", color: "white" }}>
                            Total
                          </th>
                          <th style={{ background: "black", color: "white" }}>
                            Warehouse
                          </th>
                          <th style={{ background: "black", color: "white" }}>
                            Delivery
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {PurchaseOrderDetail?.purchase_order_items?.map(
                          (item: any, index: number) => (
                            <tr>
                              <td>{index + 1}</td>
                              <td>{item?.product_name}</td>
                              <td>{item?.category_name}</td>
                              <td className="text-end">
                                {formatIndianNumberForQty(item?.qty || 0)}
                              </td>
                              <td className="text-end">
                                {formatIndianNumber(item?.rate || 0)}
                              </td>
                              <td>{item?.unit_code}</td>
                              <td className="text-end">{item?.dagina}</td>
                              <td className="text-end">
                                {item?.discount}{" "}
                                {item?.discount && item?.discount_type
                                  ? item?.discount_type
                                  : ""}
                              </td>
                              <td className="text-end">
                                {item?.totalAmount
                                  ? formatIndianNumber(item?.totalAmount)
                                  : ""}
                              </td>
                              <td>{item?.warehouse_name}</td>
                              <td className="text-center">
                                {item?.isdelivered && (
                                  <FaCheck style={{ color: "green" }} />
                                )}
                              </td>
                            </tr>
                          )
                        )}
                        <tr className="fw-bold">
                          <td
                            colSpan={3}
                            className="text-end pe-5"
                            style={{
                              background: "lightgray",
                              color: "black",
                            }}
                          >
                            Total
                          </td>
                          <td
                            style={{
                              textAlign: "end",
                              background: "lightgray",
                              color: "black",
                            }}
                          >
                            {formatIndianNumberForQty(total_qty)}
                          </td>
                          <td
                            style={{
                              background: "lightgray",
                              color: "black",
                            }}
                            colSpan={2}
                          ></td>
                          <td
                            style={{
                              textAlign: "end",
                              background: "lightgray",
                              color: "black",
                            }}
                          >
                            {total_dagina}
                          </td>
                          <td
                            style={{
                              background: "lightgray",
                              color: "black",
                            }}
                          ></td>
                          <td
                            style={{
                              textAlign: "end",
                              background: "lightgray",
                              color: "black",
                            }}
                          >
                            {formatIndianNumber(Math.round(total_amount))}
                          </td>
                          <td
                            style={{
                              background: "lightgray",
                              color: "black",
                            }}
                            colSpan={2}
                          ></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <AlertBox style={{ marginBottom: 10 }} />
                )}
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

export default CreateInward;
