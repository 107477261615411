import React, { useCallback, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import { Typography } from "@progress/kendo-react-common";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  FieldArray,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import FormTextField from "../../components/formFields/FormTextField";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import ShadowCard from "../../components/common/ShadowCard";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import FormDatePicker from "../../components/formFields/FormDateField";
import {
  getAccountByID,
  getAllSPDCIncremental,
} from "../account/services/account.services";
import { getAllItemIncremental } from "../Item/services/item.services";
import { findAllActiveUnit } from "../unit/services/unit.services";
import ButtonForAll from "../../components/common/Button";
import { clearItemList } from "../Item/itemSlice";
import FormIncrementalSearch from "../../components/formFields/FormIncrementalSearch";
import {
  createSales,
  generateInvoiceNoForSales,
  // getAllSalesLedgeAccount,
  getSalesByID,
  updateSales,
} from "./services/sales.services";
import { clearSalesDetails, setSalesStateForGST } from "./salesSlice";
import SalesItemDetailsArray from "./SalesItemDetailsArray";
import { getAllActiveGST } from "../gst/services/gst.services";
import FormNumericTextField from "../../components/formFields/FormNumericTextField";
import {
  COMMONTNCID,
  FINANCIAL_YEAR,
  GUJARATIDFORGST,
} from "../../_contstants/common";
import moment from "moment";
import {
  getAllLimitedSalesChallan,
  getProductionLotNoByItemID,
  getProductionLotNoCurrentStock,
  getSalesChallanByID,
} from "../saleschallan/services/salesChallan.services";
import { clearSalesChallanDetails } from "../saleschallan/salesChallanSlice";
import FormCheckbox from "../../components/formFields/FormCheckbox";
import { calculateGST } from "../../_helper/helper";
// import { getAllActiveAccountGroup } from "../accountgroup/services/accountGroup.services";
// import { IAccountGroup } from "../accountgroup/accountgroupModel";
import { IScheme } from "../scheme/schemeModel";
import FormTextArea from "../../components/formFields/FormTextArea";
import { getAllSchemes } from "../scheme/services/scheme.services";
import { getSalesRegisterReport } from "../reports/services/reports.services";
import { getAllDutyAndTaxLedgeAccount } from "../purchase/services/purchase.services";
import DutyAndTaxSalesDetailsArray from "./DutyAndTaxSalesArray";
import FormMultiSelectionFiled from "../../components/formFields/FormMultiSelectionFiled";
import { getAllVoucherTypes } from "../vouchertype/services/voucherType.services";
import { IVoucherType } from "../vouchertype/voucherTypeModel";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}
const QtyChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const sales_items = formRenderProps.valueGetter("sales_items");

  const sales_duty_and_taxes = formRenderProps.valueGetter(
    "sales_duty_and_taxes"
  );

  const taxable_amount = formRenderProps.valueGetter("taxable_amount");
  const sub_total = formRenderProps.valueGetter("sub_total");
  const round_off = formRenderProps.valueGetter("round_off");
  const SalesStateForGST = useAppSelector(
    (state) => state.sales.SalesStateForGST
  );
  const UnitList = useAppSelector((state) => state.unit.UnitList);

  useEffect(() => {
    let totalGSTamount = 0;
    let totalGST5 = 0;
    let totalGST12 = 0;
    let totalGST18 = 0;
    let totalGST28 = 0;

    sales_items?.map((item: any, index: number) => {
      const gst_id = item?.gst_id;
      const amount = item?.amount;
      const gstid = gst_id ? parseInt(gst_id?.split("--")[1]) : 0;
      if (gstid === 5) {
        totalGST5 += (+amount * +gstid) / 100;
      } else if (gstid === 12) {
        totalGST12 += (+amount * +gstid) / 100;
      } else if (gstid === 18) {
        totalGST18 += (+amount * +gstid) / 100;
      } else if (gstid === 28) {
        totalGST28 += (+amount * +gstid) / 100;
      }
      totalGSTamount += (+amount * +gstid) / 100;
      if (!gst_id) {
        formRenderProps.onChange(`sales_items.${index}.gst_id`, {
          value: null,
        });
      }
    });
    formRenderProps.onChange("gst", {
      value: totalGSTamount || 0,
    });
    if (SalesStateForGST === GUJARATIDFORGST) {
      formRenderProps.onChange("cgst", {
        value: totalGSTamount / 2 || 0,
      });
      formRenderProps.onChange("sgst", {
        value: totalGSTamount / 2 || 0,
      });
    } else {
      formRenderProps.onChange("igst", {
        value: totalGSTamount || 0,
      });
    }
    formRenderProps.onChange("gst5", {
      value: totalGST5 || 0,
    });
    formRenderProps.onChange("gst12", {
      value: totalGST12 || 0,
    });
    formRenderProps.onChange("gst18", {
      value: totalGST18 || 0,
    });
    formRenderProps.onChange("gst28", {
      value: totalGST28 || 0,
    });

    // Account Implementation Starts
    const gstConfig = {
      2.5: (isGujarat: boolean) => (isGujarat ? totalGST5 / 2 : 0),
      5: (isGujarat: boolean) => (!isGujarat ? totalGST5 : 0),
      6: (isGujarat: boolean) => (isGujarat ? totalGST12 / 2 : 0),
      12: (isGujarat: boolean) => (!isGujarat ? totalGST12 : 0),
      9: (isGujarat: boolean) => (isGujarat ? totalGST18 / 2 : 0),
      18: (isGujarat: boolean) => (!isGujarat ? totalGST18 : 0),
      14: (isGujarat: boolean) => (isGujarat ? totalGST28 / 2 : 0),
      28: (isGujarat: boolean) => (!isGujarat ? totalGST28 : 0),
    };

    sales_duty_and_taxes?.forEach((item: any, index: number) => {
      const gst_per = item?.gst_per;
      // const ledger_name = item?.ledger_name;
      const ledger_data =
        sales_duty_and_taxes[index].ledger_options?.filter(
          (tax: any) => tax.id === item?.ledger_id
        ) || [];
      const ledger_name = ledger_data[0]?.tax_type || "";
      const typeOfSupply = ledger_data[0]?.type_of_supply || "";

      const isGujarat = SalesStateForGST === GUJARATIDFORGST;
      // Handle invalid or unexpected gst_per values
      if (item?.ledger_id) {
        if (ledger_name?.toLowerCase()?.includes("gst")) {
          const gstAmount =
            gst_per && gstConfig[gst_per as keyof typeof gstConfig]
              ? gstConfig[gst_per as keyof typeof gstConfig](isGujarat)
              : 0; // Default value for invalid gst_per
          // formRenderProps.onChange(`sales_duty_and_taxes.${index}.gst_amount`, {
          //   value: gstAmount,
          // });

          if (typeOfSupply === "Service") {
            if (item?.gst_per) {
              formRenderProps.onChange(
                `sales_duty_and_taxes.${index}.gst_amount`,
                {
                  value: (taxable_amount * item?.gst_per) / 100,
                }
              );
            }
          } else {
            formRenderProps.onChange(
              `sales_duty_and_taxes.${index}.gst_amount`,
              {
                value: gstAmount,
              }
            );
          }
        } else {
          if (gst_per) {
            formRenderProps.onChange(
              `sales_duty_and_taxes.${index}.gst_amount`,
              {
                value: (taxable_amount * gst_per) / 100,
              }
            );
          }
        }
      } else {
        formRenderProps.onChange(`sales_duty_and_taxes.${index}.gst_amount`, {
          value: 0,
        });
        formRenderProps.onChange(`sales_duty_and_taxes.${index}.gst_per`, {
          value: 0,
        });
      }
    });

    const grandTotals = sales_duty_and_taxes.reduce(
      (acc: any, item: any, index: number) => {
        // const firstWord = item?.ledger_name?.split(" ")[0];
        const firstWord =
          sales_duty_and_taxes[index].ledger_options?.filter(
            (tax: any) => tax.id === item?.ledger_id
          )[0]?.tax_type || "";

        acc[firstWord] = (acc[firstWord] || 0) + item?.gst_amount;
        acc["duty_tax_total"] = (acc["duty_tax_total"] || 0) + item?.gst_amount;
        acc["sub_total"] =
          (acc["duty_tax_total"] || 0) + (+taxable_amount || 0);
        return acc;
      },
      {} as Record<string, number>
    );

    formRenderProps.onChange("sub_total", {
      value: grandTotals?.sub_total || 0,
    });

    if (SalesStateForGST === GUJARATIDFORGST) {
      formRenderProps.onChange("cgst", {
        value: grandTotals?.CGST || 0,
      });
      formRenderProps.onChange("sgst", {
        value: grandTotals?.SGST || 0,
      });
      formRenderProps.onChange("gst", {
        value: +(+grandTotals?.CGST + +grandTotals?.SGST)?.toFixed(2) || 0,
      });
    } else {
      formRenderProps.onChange("igst", {
        value: grandTotals?.IGST || 0,
      });
      formRenderProps.onChange("gst", {
        value: +grandTotals?.IGST?.toFixed(2) || 0,
      });
    }
    // Account Implementation Ends
  }, [
    SalesStateForGST,
    taxable_amount,
    sales_items?.map((item: any) => item?.gst_id).join("-"),
    sales_items?.map((item: any) => item?.amount).join("-"),
    sales_duty_and_taxes?.map((item: any) => item?.ledger_id).join("-"),
    sales_duty_and_taxes?.map((item: any) => item?.gst_per).join("-"),
    sales_duty_and_taxes?.map((item: any) => item?.gst_amount).join("-"),
  ]);

  useEffect(() => {
    if (sales_items && sales_items.length > 0) {
      sales_items?.map((item: any, index: number) => {
        const quantity =
          item?.quantity?.toFixed(
            item?.unit_decimal ? item?.unit_decimal : 0
          ) || 0;
        const rate = item?.rate || 0;
        const vatav =
          (+item?.quantity?.toFixed(
            item?.unit_decimal ? item?.unit_decimal : 0
          ) *
            +item?.rate *
            item?.vatav_per) /
            100 || 0;
        formRenderProps.onChange(`sales_items.${index}.vatav`, {
          value: +vatav,
        });
        formRenderProps.onChange(`sales_items.${index}.amount`, {
          value: +quantity * +rate - vatav,
        });

        const amount = +quantity * +rate - vatav;

        const gstIdStr = item?.gst_id;
        const gstid = gstIdStr ? parseInt(gstIdStr.split("--")[1], 10) : 0;
        const gstDetails = calculateGST(
          amount,
          gstid,
          SalesStateForGST === GUJARATIDFORGST
        );
        // Update the form fields
        const updates: Record<string, any> = {
          [`sales_items.${index}.amount`]: { value: amount },
          [`sales_items.${index}.sgst_amount`]: {
            value: gstDetails.sgstAmount,
          },
          [`sales_items.${index}.cgst_amount`]: {
            value: gstDetails.cgstAmount,
          },
          [`sales_items.${index}.igst_amount`]: {
            value: gstDetails.igstAmount,
          },
          [`sales_items.${index}.total_gst_amount`]: {
            value:
              gstDetails.sgstAmount +
              gstDetails.cgstAmount +
              gstDetails.igstAmount,
          },
          [`sales_items.${index}.sgst_per`]: { value: gstDetails.sgstPer },
          [`sales_items.${index}.cgst_per`]: { value: gstDetails.cgstPer },
          [`sales_items.${index}.igst_per`]: { value: gstDetails.igstPer },
        };

        // Apply all updates
        Object.entries(updates).forEach(([key, update]) => {
          formRenderProps.onChange(key, update);
        });
      });
    }
  }, [
    SalesStateForGST,
    sales_items?.map((item: any) => item?.gst_id).join("-"),
    sales_items.map((item: any) => item?.quantity).join("-"),
    sales_items.map((item: any) => item?.rate).join("-"),
    sales_items.map((item: any) => item?.vatav_per).join("-"),
    sales_items.map((item: any) => item?.unit_decimal).join("-"),
  ]);

  useEffect(() => {
    if (sales_items && sales_items.length > 0) {
      sales_items?.map((item: any, index: number) => {
        const unit_id = item?.unit_id;

        const unit = UnitList?.find((unit: any) => unit?.id === unit_id);
        if (unit) {
          formRenderProps.onChange(`sales_items.${index}.unit_decimal`, {
            value: unit?.unit_decimal || 0,
          });
        } else {
          formRenderProps.onChange(`sales_items.${index}.unit_decimal`, {
            value: 0,
          });
        }
      });
    }
  }, [sales_items.map((item: any) => item?.unit_id).join("-")]);

  let totalamount = 0;
  let totalQty = 0;

  useEffect(() => {
    if (sales_items && sales_items.length > 0) {
      sales_items?.map((item: any) => {
        totalQty += +item?.quantity || 0;
        totalamount += +item?.amount || 0;
      });
    }
    formRenderProps.onChange("totalQty", {
      value: totalQty,
    });
    formRenderProps.onChange("taxable_amount", {
      value: totalamount,
    });
  }, [
    sales_items.map((item: any) => item?.quantity).join("-"),
    sales_items.map((item: any) => item?.amount).join("-"),
  ]);

  // useEffect(() => {
  //   if (sales_items && sales_items.length > 0) {
  //     sales_items?.map((item: any) => {
  //       totalamount += +item?.amount || 0;
  //     });
  //   }
  //   formRenderProps.onChange("total_amount", {
  //     value: totalamount,
  //   });
  // }, [sales_items.map((item: any) => item?.amount).join("-")]);

  useEffect(() => {
    // formRenderProps.onChange("sub_total", {
    //   value: +taxable_amount + +gst + +frieght || 0,
    // });
    formRenderProps.onChange("round_off", {
      value: Math.round(+sub_total) - +sub_total,
    });
    formRenderProps.onChange("grand_total", {
      value: +sub_total + +round_off || 0,
    });
  }, [sub_total, round_off]);

  return null;
};

const VendorChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const dispatch = useAppDispatch();
  // const location = useLocation();
  // const sale_guid = location.state?.sale_guid;
  const vendor_id = formRenderProps.valueGetter("vendor_id");
  // const isVendorRef = useRef(true);

  useEffect(() => {
    if (vendor_id) {
      const handleVendorChange = async () => {
        const payload = {
          id: vendor_id,
        };
        const response = await dispatch(getAccountByID(payload));
        if (response.meta.requestStatus === "fulfilled") {
          dispatch(setSalesStateForGST(response.payload?.state_id));
          formRenderProps.onChange("state_id", {
            value: response.payload?.state_id,
          });
          formRenderProps.onChange("terms_day", {
            value: response.payload?.payment_due_days,
          });
        } else {
          formRenderProps.onChange("state_id", {
            value: null,
          });
          formRenderProps.onChange("terms_day", {
            value: null,
          });
        }
      };
      // if (!isVendorRef.current) {
      // handleVendorChange();
      // } else {
      // if (!sale_guid) {
      handleVendorChange();
      // }
      // isVendorRef.current = false;
      // }
    } else {
      dispatch(setSalesStateForGST(null));
      formRenderProps.onChange("state_id", {
        value: "",
      });
      formRenderProps.onChange("terms_day", {
        value: "",
      });
    }
  }, [vendor_id]);

  return null;
};

const DateChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const location = useLocation();
  const sale_guid = location.state?.sale_guid;
  const invoice_date = formRenderProps.valueGetter("invoice_date");
  const terms_day = formRenderProps.valueGetter("terms_day");
  // const due_date = formRenderProps.valueGetter("due_date");
  const isDateRef = useRef(true);

  useEffect(() => {
    const changeDueDate = () => {
      if (terms_day) {
        const dueDate = moment(invoice_date, "YYYY-MM-DD").add(
          terms_day || 0,
          "days"
        );

        formRenderProps.onChange("due_date", {
          value: moment(dueDate).toDate() || "",
        });
      }
      if (!terms_day) {
        formRenderProps.onChange("terms_day", {
          value: "",
        });
        formRenderProps.onChange("due_date", {
          value: "",
        });
      }
    };

    if (!isDateRef.current) {
      changeDueDate();
    } else {
      if (!sale_guid) {
        changeDueDate();
      }
      isDateRef.current = false;
    }
  }, [invoice_date, terms_day]);

  return null;
};

const InvoiveNoChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const sale_guid = location.state?.sale_guid;
  // const isInvoiceNoRef = useRef(false);
  const voucher_type_id = formRenderProps.valueGetter("voucher_type_id");

  useEffect(() => {
    const fetchInvoiveNoDate = async (voucher_type_id: number) => {
      const payload = {
        financial_year: FINANCIAL_YEAR,
        voucher_type_id: +voucher_type_id,
      };
      const response = await dispatch(generateInvoiceNoForSales(payload));
      if (response.meta.requestStatus === "fulfilled") {
        formRenderProps.onChange("invoice_no_string", {
          value: response.payload?.invoice_no_string || "",
        });
      }
    };
    // if (!isInvoiceNoRef.current) {
    if (!sale_guid && voucher_type_id) {
      fetchInvoiveNoDate(voucher_type_id);
    }
    //   isInvoiceNoRef.current = true;
    // } else {
    //   isInvoiceNoRef.current = true;
    // }
  }, [voucher_type_id]);

  return null;
};

const InnerItemChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const sales_items = formRenderProps.valueGetter("sales_items");
  const isFetchLotOption = React.useRef(true);
  const sale_guid = location.state?.sale_guid;

  const fetchLotNoOptions = useCallback(
    async (index: number, item: any) => {
      if (item?.item_id) {
        const response = await dispatch(
          getProductionLotNoByItemID(item.item_id)
        );
        const result =
          response.payload
            ?.filter((item: any) => item.item_id === item.item_id)
            .map((lotno: any) => ({
              value: `${lotno.lot_no}`,
              label: `${lotno.lot_no}`,
            })) || [];
        formRenderProps.onChange(`sales_items.${index}.lotNoOptions`, {
          value: result,
        });
      } else {
        formRenderProps.onChange(`sales_items.${index}.lotNoOptions`, {
          value: [],
        });
        formRenderProps.onChange(`sales_items.${index}.lot_no`, {
          value: null,
        });
      }
    },
    [dispatch, formRenderProps]
  );

  const fetchCurrentStock = useCallback(
    async (index: number, item: any) => {
      if (item?.item_id && item?.lot_no) {
        const payload = {
          item_id: +item.item_id,
          lot_no: item.lot_no,
        };
        const response = await dispatch(
          getProductionLotNoCurrentStock(payload)
        );
        formRenderProps.onChange(`sales_items.${index}.current_stock`, {
          value: response.payload?.actual_stock || 0,
        });
      }
    },
    [dispatch, formRenderProps]
  );

  useEffect(() => {
    if (isFetchLotOption.current && !sale_guid) {
      sales_items.forEach((item: any, index: number) => {
        fetchLotNoOptions(index, item);
        fetchCurrentStock(index, item);
      });
      isFetchLotOption.current = false;
    } else if (isFetchLotOption.current && sale_guid) {
      // if (!sale_guid) {
      sales_items.forEach((item: any, index: number) => {
        fetchLotNoOptions(index, item);
        fetchCurrentStock(index, item);
      });
      // }
      isFetchLotOption.current = false;
    }
  }, [
    sale_guid,
    sales_items.map((item: any) => item?.item_id).join("-"),
    sales_items.map((item: any) => item?.lot_id).join("-"),
  ]);

  return null;
};

const CreateSales = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const sale_guid = location.state?.sale_guid;
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.sales.loading);
  const SalesDetail = useAppSelector((state) => state.sales.SalesDetail);
  const SchemeList = useAppSelector((state) => state.scheme.SchemeList);
  const VoucherTypeList = useAppSelector(
    (state) => state.voucherType.VoucherTypeList
  );

  // const TermsAndConditionList = useAppSelector(
  //   (state) => state.termsandcondition.TermsAndConditionList
  // );
  const SalesStateForGST = useAppSelector(
    (state) => state.sales.SalesStateForGST
  );
  const SalesChallanList = useAppSelector(
    (state) => state.salesChallan.SalesChallanList
  );
  const SalesRegisterDetails = useAppSelector(
    (state) => state.reports.SalesRegisterDetails
  );
  const [formKey, setFormKey] = React.useState(1);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [SalesDetail]);

  useEffect(() => {
    if (sale_guid) {
      const payload = {
        sale_guid: sale_guid,
      };
      dispatch(getSalesByID(payload));
    }
  }, [sale_guid]);

  useEffect(() => {
    const payload = {
      account_name: "",
      financial_year: FINANCIAL_YEAR,
      limit: 50,
      skip: 0,
    };

    dispatch(findAllActiveUnit());
    dispatch(getAllActiveGST());
    dispatch(getAllVoucherTypes());
    // dispatch(getAllActiveAccountGroup());
    dispatch(getAllSchemes());
    // dispatch(getAllActiveTermsAndConditions());
    dispatch(getAllLimitedSalesChallan(payload));

    return () => {
      dispatch(clearSalesChallanDetails());
      dispatch(clearSalesDetails());
      dispatch(clearItemList());
    };
  }, []);

  const handleChallanNoChange = async (
    challan_no: any,
    formRenderProps: FormRenderProps
  ) => {
    const fetchCurrentStock = async (index: number, item: any) => {
      if (item?.item_id && item?.lot_no) {
        const payload = {
          item_id: +item.item_id,
          lot_no: item.lot_no,
        };
        const response = await dispatch(
          getProductionLotNoCurrentStock(payload)
        );
        formRenderProps.onChange(`sales_items.${index}.current_stock`, {
          value: response.payload?.actual_stock || 0,
        });
      }
    };

    if (challan_no) {
      const payload = {
        challan_no: challan_no,
        financial_year: FINANCIAL_YEAR,
      };
      const response = await dispatch(getSalesChallanByID(payload));
      if (response.meta.requestStatus === "fulfilled") {
        formRenderProps.onChange("vendor_id", {
          value: response.payload?.vendor_id,
        });
        formRenderProps.onChange("vendor_options", {
          value: response.payload?.vendor_options,
        });
        formRenderProps.onChange("free_scheme", {
          value: response.payload?.free_scheme,
        });
        formRenderProps.onChange("card_scheme", {
          value: response.payload?.card_scheme,
        });
        // formRenderProps.onChange("delivery_slip_no", {
        //   value: response.payload?.delivery_slip_no,
        // });
        formRenderProps.onChange("transport_name", {
          value: response.payload?.transport_name,
        });
        formRenderProps.onChange("vehicle_no", {
          value: response.payload?.vehicle_no,
        });
        formRenderProps.onChange("bag", {
          value: response.payload?.bag,
        });
        formRenderProps.onChange("cartoon", {
          value: response.payload?.cartoon,
        });
        formRenderProps.onChange("scheme", {
          value: response.payload?.scheme || [],
        });

        const salesItems =
          response.payload?.sales_challan_items &&
          response.payload?.sales_challan_items.length > 0 &&
          response.payload?.sales_challan_items?.map(
            (item: any, index: number) => {
              fetchCurrentStock(index, item);
              return {
                item_id: item?.item_id,
                item_options: item?.item_options,
                mrp: item?.mrp,
                lot_no: item?.lot_no,
                gst_id: item?.gst_id,
                quantity: item?.quantity,
                free_quantity: item?.free_quantity,
                rate: item?.rate,
                unit_id: item?.unit_id,
                unit_decimal: item?.unit_decimal,
                amount: item?.quantity * item?.rate,
                lotNoOptions:
                  item?.lot_no_list?.map((lotno: any) => ({
                    label: lotno?.lot_no,
                    value: lotno?.lot_no,
                  })) || [],
              };
            }
          );
        formRenderProps.onChange("sales_items", {
          value: salesItems || [],
        });
      } else {
        formRenderProps.onChange("vendor_id", {
          value: null,
        });
        formRenderProps.onChange("free_scheme", {
          value: null,
        });
        formRenderProps.onChange("card_scheme", {
          value: null,
        });
        formRenderProps.onChange("vendor_options", {
          value: [],
        });
        formRenderProps.onChange("po_date", {
          value: "",
        });
        // formRenderProps.onChange("delivery_slip_no", {
        //   value: "",
        // });
        formRenderProps.onChange("transport_name", {
          value: "",
        });
        formRenderProps.onChange("vehicle_no", {
          value: "",
        });
        formRenderProps.onChange("bag", {
          value: "",
        });
        formRenderProps.onChange("cartoon", {
          value: "",
        });
        formRenderProps.onChange("scheme", {
          value: "",
        });
      }
    }
  };

  const handleInvoiceNoChange = (e: any, formRenderProps: FormRenderProps) => {
    const companyCode = e?.value ? e?.value?.split("/")[0] : "";
    const GSTCode = e?.value ? e?.value?.split("/")[1] : "";
    const invoice_no_string = e?.value ? e?.value?.split("/")[2] : "";

    formRenderProps.onChange("invoice_no_string", {
      value: `${companyCode}/${GSTCode}/${invoice_no_string || ""}`,
    });
  };

  const handleItemSearchChange = useCallback(
    async (search: string, field: string, formRenderProps: FormRenderProps) => {
      const result = await dispatch(getAllItemIncremental({ search }));
      formRenderProps.onChange(field, {
        value: result.payload,
      });
    },
    [dispatch]
  );

  const handleLedgerSearchChange = useCallback(
    async (search: string, field: string, formRenderProps: FormRenderProps) => {
      const result = await dispatch(getAllDutyAndTaxLedgeAccount(search));
      formRenderProps.onChange(field, {
        value: result.payload,
      });
    },
    [dispatch]
  );

  const handleVendorSearchChange = useCallback(
    async (search: string, field: string, formRenderProps: FormRenderProps) => {
      const result = await dispatch(getAllSPDCIncremental(search));
      formRenderProps.onChange(field, {
        value: result.payload,
      });
    },
    [dispatch]
  );

  const handleSubmit = async (values: any) => {
    if (sale_guid) {
      try {
        const updatePayload: any = {
          id: values?.id ? +values?.id : null,
          sale_guid: sale_guid,
          sales_challan_id: values?.sales_challan_id
            ? +values?.sales_challan_id
            : null,
          financial_year: FINANCIAL_YEAR,
          // invoice_no: values?.invoice_no_string
          //   ? +values?.invoice_no_string
          //   : "",
          cartoon: values?.cartoon ? +values?.cartoon : null,
          scheme: values?.scheme ? values?.scheme?.join(", ") : "",
          bag: values?.bag ? +values?.bag : null,
          frieght: values?.frieght ? +values?.frieght : null,
          terms_and_condition_id: +COMMONTNCID,
          vehicle_no: values?.vehicle_no ? values?.vehicle_no : "",
          transport_name: values?.transport_name ? values?.transport_name : "",
          remarks: values?.remarks ? values?.remarks : "",
          // delivery_slip_no: values?.delivery_slip_no
          //   ? values?.delivery_slip_no
          //   : "",
          invoice_date: values?.invoice_date
            ? moment(values?.invoice_date).format("YYYY-MM-DD")
            : "",
          vendor_id: values?.vendor_id ? +values?.vendor_id : null,
          terms_day: values?.terms_day ? +values?.terms_day : null,
          due_date: values?.due_date
            ? moment(values?.due_date).format("YYYY-MM-DD")
            : null,
          // sales_ledger_id: values?.sales_ledger_id
          //   ? +values?.sales_ledger_id
          //   : null,
          total_amount: values?.grand_total ? +values?.grand_total : null,
          gst: values?.gst ? +values?.gst?.toFixed(2) : null,
          sales_items: values?.sales_items
            ? values?.sales_items?.map((salesitem: any) => {
                return {
                  id: salesitem?.id ? +salesitem?.id : 0,
                  sales_id: values?.id ? +values?.id : null,
                  item_id: salesitem?.item_id ? +salesitem?.item_id : null,
                  item_description: salesitem?.item_description
                    ? salesitem?.item_description
                    : "",
                  mrp: salesitem?.mrp ? +salesitem?.mrp : null,
                  lot_no: salesitem?.lot_no ? salesitem?.lot_no : "",
                  quantity: salesitem?.quantity
                    ? +salesitem?.quantity?.toFixed(
                        salesitem.unit_decimal ? salesitem.unit_decimal : 0
                      )
                    : 0,
                  free_quantity: salesitem?.free_quantity
                    ? +salesitem?.free_quantity?.toFixed(
                        salesitem.unit_decimal ? salesitem.unit_decimal : 0
                      )
                    : 0,
                  unit_id: salesitem?.unit_id ? +salesitem?.unit_id : null,
                  rate: salesitem?.rate ? +salesitem?.rate : null,
                  gst_id: salesitem?.gst_id
                    ? parseInt(salesitem?.gst_id.split("--")[0])
                    : null,

                  sgst_per: salesitem?.sgst_per
                    ? +salesitem?.sgst_per?.toFixed(2)
                    : 0,
                  sgst_amount: salesitem?.sgst_amount
                    ? +salesitem?.sgst_amount?.toFixed(2)
                    : 0,
                  cgst_per: salesitem?.cgst_per
                    ? +salesitem?.cgst_per?.toFixed(2)
                    : 0,
                  cgst_amount: salesitem?.cgst_amount
                    ? +salesitem?.cgst_amount?.toFixed(2)
                    : 0,
                  igst_per: salesitem?.igst_per
                    ? +salesitem?.igst_per?.toFixed(2)
                    : 0,
                  igst_amount: salesitem?.igst_amount
                    ? +salesitem?.igst_amount?.toFixed(2)
                    : 0,
                  total_gst_amount: salesitem?.total_gst_amount
                    ? +salesitem?.total_gst_amount?.toFixed(2)
                    : 0,

                  amount: salesitem?.amount ? +salesitem?.amount : null,
                  vatav_per: salesitem?.vatav_per
                    ? +salesitem?.vatav_per
                    : null,
                  vatav: salesitem?.vatav ? +salesitem?.vatav : null,
                };
              })
            : [],
          sales_duty_and_taxes: values?.sales_duty_and_taxes
            ? values.sales_duty_and_taxes
                .filter(
                  (salesduty: any) =>
                    salesduty?.ledger_id && salesduty?.gst_amount
                )
                .map((salesduty: any) => ({
                  ledger_id: +salesduty.ledger_id,
                  gst_per: salesduty?.gst_per ? +salesduty.gst_per : null,
                  gst_amount: salesduty?.gst_amount
                    ? +salesduty.gst_amount
                    : null,
                }))
            : [],
        };
        if (SalesStateForGST === GUJARATIDFORGST) {
          updatePayload.cgst = values?.cgst ? +values?.cgst?.toFixed(2) : null;
          updatePayload.sgst = values?.sgst ? +values?.sgst?.toFixed(2) : null;
          updatePayload.igst = null;
        } else {
          updatePayload.igst = values?.igst ? +values?.igst?.toFixed(2) : null;
          updatePayload.cgst = null;
          updatePayload.sgst = null;
        }

        const response = await dispatch(updateSales(updatePayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          if (SalesRegisterDetails?.isSalesRef) {
            const payload = {
              item_id: SalesRegisterDetails?.item_id
                ? SalesRegisterDetails?.item_id
                : null,
              vendor_id: SalesRegisterDetails?.vendor_id
                ? SalesRegisterDetails?.vendor_id
                : null,
              from_date: SalesRegisterDetails?.from_date
                ? moment(SalesRegisterDetails?.from_date).format("YYYY-MM-DD")
                : null,
              to_Date: SalesRegisterDetails?.to_Date
                ? moment(SalesRegisterDetails?.to_Date).format("YYYY-MM-DD")
                : null,
            };
            dispatch(getSalesRegisterReport(payload));
          }
          navigate(-1);
          // navigate("/sales");
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    } else {
      try {
        const insertPayload: any = {
          sales_challan_id: values?.sales_challan_id
            ? +values?.sales_challan_id
            : null,
          financial_year: FINANCIAL_YEAR,
          invoice_no_string: values?.invoice_no_string
            ? values?.invoice_no_string
            : "",
          voucher_type_id: values?.voucher_type_id
            ? +values?.voucher_type_id
            : null,
          cartoon: values?.cartoon ? +values?.cartoon : null,
          scheme: values?.scheme ? values?.scheme?.join(", ") : "",
          bag: values?.bag ? +values?.bag : null,
          frieght: values?.frieght ? +values?.frieght : null,
          terms_and_condition_id: +COMMONTNCID,
          vehicle_no: values?.vehicle_no ? values?.vehicle_no : "",
          transport_name: values?.transport_name ? values?.transport_name : "",
          remarks: values?.remarks ? values?.remarks : "",
          // delivery_slip_no: values?.delivery_slip_no
          //   ? values?.delivery_slip_no
          //   : "",
          invoice_date: values?.invoice_date
            ? moment(values?.invoice_date).format("YYYY-MM-DD")
            : "",
          vendor_id: values?.vendor_id ? +values?.vendor_id : null,
          terms_day: values?.terms_day ? +values?.terms_day : null,
          due_date: values?.due_date
            ? moment(values?.due_date).format("YYYY-MM-DD")
            : null,
          // sales_ledger_id: values?.sales_ledger_id
          //   ? +values?.sales_ledger_id
          //   : null,
          total_amount: values?.grand_total ? +values?.grand_total : null,
          gst: values?.gst ? +values?.gst?.toFixed(2) : null,
          sales_items: values?.sales_items
            ? values?.sales_items?.map((salesitem: any) => {
                return {
                  id: salesitem?.id ? +salesitem?.id : 0,
                  item_id: salesitem?.item_id ? +salesitem?.item_id : null,
                  item_description: salesitem?.item_description
                    ? salesitem?.item_description
                    : "",
                  mrp: salesitem?.mrp ? +salesitem?.mrp : null,
                  lot_no: salesitem?.lot_no ? salesitem?.lot_no : "",
                  quantity: salesitem?.quantity
                    ? +salesitem?.quantity?.toFixed(
                        salesitem.unit_decimal ? salesitem.unit_decimal : 0
                      )
                    : 0,
                  free_quantity: salesitem?.free_quantity
                    ? +salesitem?.free_quantity?.toFixed(
                        salesitem.unit_decimal ? salesitem.unit_decimal : 0
                      )
                    : 0,
                  unit_id: salesitem?.unit_id ? +salesitem?.unit_id : null,
                  rate: salesitem?.rate ? +salesitem?.rate : null,
                  gst_id: salesitem?.gst_id
                    ? parseInt(salesitem?.gst_id.split("--")[0])
                    : null,

                  sgst_per: salesitem?.sgst_per
                    ? +salesitem?.sgst_per?.toFixed(2)
                    : 0,
                  sgst_amount: salesitem?.sgst_amount
                    ? +salesitem?.sgst_amount?.toFixed(2)
                    : 0,
                  cgst_per: salesitem?.cgst_per
                    ? +salesitem?.cgst_per?.toFixed(2)
                    : 0,
                  cgst_amount: salesitem?.cgst_amount
                    ? +salesitem?.cgst_amount?.toFixed(2)
                    : 0,
                  igst_per: salesitem?.igst_per
                    ? +salesitem?.igst_per?.toFixed(2)
                    : 0,
                  igst_amount: salesitem?.igst_amount
                    ? +salesitem?.igst_amount?.toFixed(2)
                    : 0,
                  total_gst_amount: salesitem?.total_gst_amount
                    ? +salesitem?.total_gst_amount?.toFixed(2)
                    : 0,

                  amount: salesitem?.amount ? +salesitem?.amount : null,
                  vatav_per: salesitem?.vatav_per
                    ? +salesitem?.vatav_per
                    : null,
                  vatav: salesitem?.vatav ? +salesitem?.vatav : null,
                };
              })
            : [],

          sales_duty_and_taxes: values?.sales_duty_and_taxes
            ? values.sales_duty_and_taxes
                .filter(
                  (salesduty: any) =>
                    salesduty?.ledger_id && salesduty?.gst_amount
                )
                .map((salesduty: any) => ({
                  ledger_id: +salesduty.ledger_id,
                  gst_per: salesduty?.gst_per ? +salesduty.gst_per : null,
                  gst_amount: salesduty?.gst_amount
                    ? +salesduty.gst_amount
                    : null,
                }))
            : [],
        };
        if (SalesStateForGST === GUJARATIDFORGST) {
          insertPayload.cgst = values?.cgst ? +values?.cgst?.toFixed(2) : null;
          insertPayload.sgst = values?.sgst ? +values?.sgst?.toFixed(2) : null;
          insertPayload.igst = null;
        } else {
          insertPayload.igst = values?.igst ? +values?.igst?.toFixed(2) : null;
          insertPayload.cgst = null;
          insertPayload.sgst = null;
        }
        const response = await dispatch(createSales(insertPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate(-1);
          // navigate("/sales");
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    }
  };

  // const handleSalesLedgerSearchChange = useCallback(
  //   async (search: string, field: string, formRenderProps: FormRenderProps) => {
  //     const result = await dispatch(getAllSalesLedgeAccount(search));
  //     formRenderProps.onChange(field, {
  //       value: result.payload,
  //     });
  //   },
  //   [dispatch]
  // );

  // if (loading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <>
      {loading && <LoadingPanel gridRef={gridRef} />}
      <Form
        key={formKey}
        onSubmit={handleSubmit}
        initialValues={SalesDetail}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12 }}>
              <GridLayout
                style={{ marginRight: 30 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                ]}
              >
                <GridLayoutItem colSpan={4}>
                  <Typography.h4>
                    {sale_guid ? "Update Sales Invoice" : "Add Sales Invoice"}
                  </Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="voucher_type_id"
                    label="Voucher Type"
                    placeholder="Voucher Type"
                    astrike={true}
                    disabled={sale_guid && true}
                    validator={requiredValidator}
                    component={FormSelectionField}
                    options={VoucherTypeList?.map((scheme: IVoucherType) => {
                      return {
                        value: scheme?.id,
                        label: scheme?.name,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="invoice_no_string"
                    label="Invoice No"
                    disabled={sale_guid && true}
                    placeholder="Invoice No"
                    onChange={(e: any) =>
                      handleInvoiceNoChange(e, formRenderProps)
                    }
                    component={FormTextField}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem></GridLayoutItem>
                <GridLayoutItem>
                  {(formRenderProps.valueGetter("free_scheme") ||
                    formRenderProps.valueGetter("card_scheme")) && (
                    <div
                      style={{
                        marginTop: 30,
                        background: "whitesmoke",
                        padding: "7px 10px",
                        borderRadius: 5,
                        display: "flex",
                        gap: 10,
                      }}
                    >
                      {formRenderProps.valueGetter("free_scheme") && (
                        <Field
                          name="free_scheme"
                          wrapperStyle={{ marginTop: 0 }}
                          label="Free Scheme"
                          disabled={true}
                          component={FormCheckbox}
                          labelClassName={"fw-bold ms-2"}
                        />
                      )}
                      {formRenderProps.valueGetter("card_scheme") && (
                        <Field
                          name="card_scheme"
                          wrapperStyle={{ marginTop: 0 }}
                          label="Card Scheme"
                          disabled={true}
                          component={FormCheckbox}
                          labelClassName={"fw-bold ms-2"}
                        />
                      )}
                    </div>
                  )}
                </GridLayoutItem>
                {sale_guid ? (
                  <GridLayoutItem colSpan={2}>
                    <Field
                      name="sales_challan_id_label"
                      label="Sales Challan No"
                      disabled={true}
                      placeholder="Sales Challan No"
                      component={FormTextField}
                    />
                  </GridLayoutItem>
                ) : (
                  <GridLayoutItem
                    colSpan={2}
                    style={{ display: "flex", gap: 10 }}
                  >
                    <Field
                      wrapperClassName="w-100"
                      name="sales_challan_id"
                      label="Sales Challan No"
                      placeholder="Sales Challan No"
                      disabled={sale_guid ? true : false}
                      component={FormSelectionField}
                      options={SalesChallanList?.map((saleschallan: any) => {
                        return {
                          value: saleschallan?.challan_no,
                          label: `${saleschallan?.challan_no} - ${saleschallan?.challan_date} - ${saleschallan?.account_name}`,
                        };
                      })}
                    />
                    <ButtonForAll
                      label={"View"}
                      disabled={
                        sale_guid ||
                        !formRenderProps.valueGetter("sales_challan_id")
                          ? true
                          : false
                      }
                      style={{ marginTop: 35 }}
                      onClick={() =>
                        handleChallanNoChange(
                          formRenderProps.valueGetter("sales_challan_id"),
                          formRenderProps
                        )
                      }
                    />
                  </GridLayoutItem>
                )}
                <GridLayoutItem>
                  <Field
                    name="vendor_id"
                    label="Customer Name"
                    placeholder="Search Customer Name"
                    isAddNew={true}
                    addNewLink="account/create"
                    component={FormIncrementalSearch}
                    validator={requiredValidator}
                    astrike={true}
                    fetchIncrementalData={(search: any) =>
                      handleVendorSearchChange(
                        search,
                        `vendor_options`,
                        formRenderProps
                      )
                    }
                    options={
                      formRenderProps
                        .valueGetter("vendor_options")
                        ?.map((item: any) => {
                          return {
                            value: item.id,
                            label: item.account_name,
                          };
                        }) || []
                    }
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="invoice_date"
                    label="Invoice Date"
                    format="dd/MM/yyyy"
                    component={FormDatePicker}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                {/* <GridLayoutItem>
                  <Field
                    name="sales_ledger_id"
                    label="Sales Ledger"
                    placeholder="Search Sales Ledger"
                    isAddNew={true}
                    addNewLink="account/create"
                    component={FormIncrementalSearch}
                    validator={requiredValidator}
                    astrike={true}
                    fetchIncrementalData={(search: any) =>
                      handleSalesLedgerSearchChange(
                        search,
                        `sales_ledger_options`,
                        formRenderProps
                      )
                    }
                    options={
                      formRenderProps
                        .valueGetter("sales_ledger_options")
                        ?.map((item: any) => {
                          return {
                            value: item.id,
                            label: item.account_name,
                          };
                        }) || []
                    }
                  />
                </GridLayoutItem> */}
                <GridLayoutItem>
                  <Field
                    name="scheme"
                    label="Scheme"
                    placeholder="Scheme"
                    component={FormMultiSelectionFiled}
                    options={SchemeList?.map((scheme: IScheme) => {
                      return {
                        value: scheme?.id,
                        label: scheme?.scheme_name,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="right-alighned-field"
                    name="terms_day"
                    label="Terms Day"
                    placeholder="0"
                    disabled={true}
                    maxNumber={999}
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="due_date"
                    label="Due Date"
                    format="dd/MM/yyyy"
                    disabled={true}
                    component={FormDatePicker}
                  />
                </GridLayoutItem>
                {/* <GridLayoutItem>
                  <Field
                    name="delivery_slip_no"
                    label="Delivery Slip No"
                    placeholder="0"
                    component={FormTextField}
                  />
                </GridLayoutItem> */}
                <GridLayoutItem>
                  <Field
                    name="transport_name"
                    label="Transport Name"
                    placeholder="i.e. John Smith"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="vehicle_no"
                    label="Vehicle No"
                    placeholder="i.e. GJ01AA1111"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="right-alighned-field"
                    name="cartoon"
                    label="Cartoon"
                    placeholder="0"
                    component={FormNumericTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="right-alighned-field"
                    name="bag"
                    label="Bag"
                    placeholder="0"
                    component={FormNumericTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem colSpan={2}>
                  <Field
                    name="remarks"
                    label="Remarks"
                    placeholder="Remarks"
                    component={FormTextArea}
                    rows={2}
                  />
                </GridLayoutItem>
                {/* <GridLayoutItem>
                  <Field
                    name="terms_and_condition_id"
                    label="Terms And Conditions"
                    placeholder="Terms And Conditions"
                    component={FormSelectionField}
                    // validator={requiredValidator}
                    options={TermsAndConditionList?.map(
                      (tnc: ITermsAndCondition) => {
                        return {
                          value: tnc?.id,
                          label: tnc?.terms_and_condition,
                        };
                      }
                    )}
                  />
                </GridLayoutItem> */}
                <QtyChangeWatcher formRenderProps={formRenderProps} />
                <VendorChangeWatcher formRenderProps={formRenderProps} />
                <DateChangeWatcher formRenderProps={formRenderProps} />
                <InvoiveNoChangeWatcher formRenderProps={formRenderProps} />
                <InnerItemChangeWatcher formRenderProps={formRenderProps} />
              </GridLayout>
            </ShadowCard>
            <ShadowCard style={{ padding: 12, marginTop: 10 }}>
              <GridLayout
                style={{ marginRight: 30 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                ]}
              >
                <GridLayoutItem colSpan={4}>
                  <Typography.h4>Item Details</Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={4}
                  // style={{ borderBottom: "1px solid lightgray" }}
                >
                  <FieldArray
                    formRenderProps={formRenderProps}
                    handleItemSearchChange={handleItemSearchChange}
                    component={SalesItemDetailsArray}
                    name="sales_items"
                  />
                </GridLayoutItem>
                <GridLayoutItem colSpan={2}>
                  {/* <FieldArray
                    formRenderProps={formRenderProps}
                    handleLedgerSearchChange={handleLedgerSearchChange}
                    component={DutyAndTaxSalesDetailsArray}
                    name="sales_duty_and_taxes"
                  /> */}
                  {/* <SalesInvoiceTaxSummary formRenderProps={formRenderProps} /> */}
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={2}
                  style={{ display: "flex", alignItems: "end" }}
                >
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      <tr>
                        <td
                          style={{
                            width: "50%",
                            textAlign: "end",
                            fontWeight: 600,
                          }}
                        >
                          Total
                        </td>
                        <td style={{ width: "5%", textAlign: "center" }}>:</td>
                        <td style={{ width: "45%" }}>
                          <Field
                            wrapperClassName="calculation-label"
                            wrapperStyle={{ margin: 0, width: "100%" }}
                            name="taxable_amount"
                            format="n2"
                            component={FormNumericTextField}
                            disabled="true"
                          />
                        </td>
                      </tr>
                    </table>
                  </div>
                </GridLayoutItem>
                <GridLayoutItem colSpan={4}>
                  <FieldArray
                    formRenderProps={formRenderProps}
                    handleLedgerSearchChange={handleLedgerSearchChange}
                    component={DutyAndTaxSalesDetailsArray}
                    name="sales_duty_and_taxes"
                  />
                  {/* <PurchaseInvoiceTaxSummary
                    formRenderProps={formRenderProps}
                  /> */}
                </GridLayoutItem>
                <GridLayoutItem colSpan={2}></GridLayoutItem>

                {/* {SalesStateForGST === GUJARATIDFORGST && (
                        <tr>
                          <td
                            style={{
                              width: "50%",
                              textAlign: "end",
                              fontWeight: 600,
                            }}
                          >
                            CGST
                          </td>
                          <td style={{ width: "10%", textAlign: "center" }}>
                            :
                          </td>
                          <td style={{ width: "30%" }}>
                            <Field
                              wrapperClassName="calculation-label"
                              wrapperStyle={{ margin: 0, width: "100%" }}
                              name="cgst"
                              placeholder="0"
                              disabled={true}
                              format="n2"
                              component={FormNumericTextField}
                            />
                          </td>
                        </tr>
                      )}
                      {SalesStateForGST === GUJARATIDFORGST && (
                        <tr>
                          <td
                            style={{
                              width: "50%",
                              textAlign: "end",
                              fontWeight: 600,
                            }}
                          >
                            SGST
                          </td>
                          <td style={{ width: "10%", textAlign: "center" }}>
                            :
                          </td>
                          <td style={{ width: "30%" }}>
                            <Field
                              wrapperClassName="calculation-label"
                              wrapperStyle={{ margin: 0, width: "100%" }}
                              name="sgst"
                              placeholder="0"
                              disabled={true}
                              format="n2"
                              component={FormNumericTextField}
                            />
                          </td>
                        </tr>
                      )}
                      {SalesStateForGST !== GUJARATIDFORGST && (
                        <tr>
                          <td
                            style={{
                              width: "50%",
                              textAlign: "end",
                              fontWeight: 600,
                            }}
                          >
                            IGST
                          </td>
                          <td style={{ width: "10%", textAlign: "center" }}>
                            :
                          </td>
                          <td style={{ width: "30%" }}>
                            <Field
                              wrapperClassName="calculation-label"
                              wrapperStyle={{ margin: 0, width: "100%" }}
                              name="igst"
                              placeholder="0"
                              disabled={true}
                              format="n2"
                              component={FormNumericTextField}
                            />
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td
                          style={{
                            width: "50%",
                            textAlign: "end",
                            fontWeight: 600,
                          }}
                        >
                          Freight Charges
                        </td>
                        <td style={{ width: "10%", textAlign: "center" }}>:</td>
                        <td style={{ width: "30%" }}>
                          <Field
                            wrapperClassName="right-alighned-field"
                            wrapperStyle={{ margin: 0, width: "100%" }}
                            name="frieght"
                            placeholder="0"
                            // disabled={true}
                            format="n2"
                            component={FormNumericTextField}
                          />
                        </td>
                      </tr> */}
                <GridLayoutItem
                  colSpan={2}
                  style={{ display: "flex", alignItems: "end" }}
                >
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      <tr>
                        <td
                          style={{
                            width: "50%",
                            textAlign: "end",
                            fontWeight: 600,
                          }}
                        >
                          Sub Total
                        </td>
                        <td style={{ width: "5%", textAlign: "center" }}>:</td>
                        <td style={{ width: "45%" }}>
                          <Field
                            wrapperClassName="calculation-label"
                            wrapperStyle={{ margin: 0, width: "100%" }}
                            name="sub_total"
                            placeholder="0"
                            disabled={true}
                            format="n2"
                            component={FormNumericTextField}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            width: "50%",
                            textAlign: "end",
                            fontWeight: 600,
                          }}
                        >
                          Round Off
                        </td>
                        <td style={{ width: "5%", textAlign: "center" }}>:</td>
                        <td style={{ width: "45%" }}>
                          <Field
                            wrapperClassName="calculation-label"
                            wrapperStyle={{ margin: 0, width: "100%" }}
                            name="round_off"
                            placeholder="0"
                            disabled={true}
                            format="n2"
                            component={FormNumericTextField}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            width: "50%",
                            textAlign: "end",
                            fontWeight: 600,
                          }}
                        >
                          Grand Total
                        </td>
                        <td style={{ width: "5%", textAlign: "center" }}>:</td>
                        <td style={{ width: "45%" }}>
                          <Field
                            wrapperClassName="calculation-label"
                            wrapperStyle={{ margin: 0, width: "100%" }}
                            name="grand_total"
                            placeholder="0"
                            disabled={true}
                            format="n2"
                            component={FormNumericTextField}
                          />
                        </td>
                      </tr>
                    </table>
                  </div>
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={4}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "end",
                    marginTop: 15,
                  }}
                >
                  <div>
                    <ButtonWithLoading
                      label={sale_guid ? "Update" : "Save"}
                      type="submit"
                      disabled={!formRenderProps.allowSubmit || loading}
                      loading={loading}
                    />
                    <Button
                      type="button"
                      fillMode={"outline"}
                      themeColor={"primary"}
                      style={{ marginLeft: 4 }}
                      onClick={
                        () => navigate(-1)
                        // navigate("/sales")
                      }
                    >
                      Cancel
                    </Button>
                  </div>
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
          </FormElement>
        )}
      />
    </>
  );
};

// const SalesInvoiceTaxSummary: React.FC<FormChangeWatcherProps> = ({
//   formRenderProps,
// }) => {
//   const location = useLocation();
//   const SalesStateForGST = useAppSelector(
//     (state) => state.sales.SalesStateForGST
//   );
//   const sale_guid = location.state?.sale_guid;
//   const SalesDetail = useAppSelector((state) => state.sales.SalesDetail);
//   const isVendorRef = useRef(true);
//   const [isIGST, setIsIgst] = React.useState<boolean>(false);

//   const totalGST = formRenderProps.valueGetter("gst");
//   const totalGST5 = formRenderProps.valueGetter("gst5");
//   const totalGST12 = formRenderProps.valueGetter("gst12");
//   const totalGST18 = formRenderProps.valueGetter("gst18");
//   const totalGST28 = formRenderProps.valueGetter("gst28");

//   useEffect(() => {
//     if (sale_guid) {
//       if (!isVendorRef.current) {
//         setIsIgst(SalesStateForGST !== GUJARATIDFORGST);
//       } else {
//         if (SalesDetail?.igst && SalesDetail?.igst !== null) {
//           setIsIgst(true);
//         } else {
//           setIsIgst(false);
//         }
//         isVendorRef.current = false;
//       }
//     } else {
//       setIsIgst(SalesStateForGST !== GUJARATIDFORGST);
//     }
//   }, [SalesStateForGST]);

//   return (
//     <>
//       <div>
//         <table style={{ width: "100%" }} className="sctaxsummary-table">
//           {(totalGST5 > 0 ||
//             totalGST12 > 0 ||
//             totalGST18 > 0 ||
//             totalGST28 > 0) && (
//             <tr style={{ background: "lightgray" }}>
//               <td colSpan={4}>
//                 <h5 className="m-0">Tax Summery</h5>
//               </td>
//             </tr>
//           )}
//           {isIGST && totalGST5 > 0 && (
//             <tr>
//               <td
//                 style={{
//                   width: "30%",
//                   fontWeight: 600,
//                 }}
//               >
//                 IGST 5%
//               </td>
//               <td style={{ width: "10%", textAlign: "center" }}>:</td>
//               <td style={{ width: "10%", textAlign: "center" }}>5 %</td>
//               <td
//                 style={{
//                   width: "50%",
//                   textAlign: "right",
//                   paddingRight: 45,
//                 }}
//               >
//                 {formatIndianNumber(totalGST5)}
//               </td>
//             </tr>
//           )}
//           {isIGST && totalGST12 > 0 && (
//             <tr>
//               <td
//                 style={{
//                   width: "30%",
//                   fontWeight: 600,
//                 }}
//               >
//                 IGST 12%
//               </td>
//               <td style={{ width: "10%", textAlign: "center" }}>:</td>
//               <td style={{ width: "10%", textAlign: "center" }}>12 %</td>
//               <td
//                 style={{
//                   width: "50%",
//                   textAlign: "right",
//                   paddingRight: 45,
//                 }}
//               >
//                 {formatIndianNumber(totalGST12)}
//               </td>
//             </tr>
//           )}
//           {isIGST && totalGST18 > 0 && (
//             <tr>
//               <td
//                 style={{
//                   width: "30%",
//                   fontWeight: 600,
//                 }}
//               >
//                 IGST 18%
//               </td>
//               <td style={{ width: "10%", textAlign: "center" }}>:</td>
//               <td style={{ width: "10%", textAlign: "center" }}>18 %</td>
//               <td
//                 style={{
//                   width: "50%",
//                   textAlign: "right",
//                   paddingRight: 45,
//                 }}
//               >
//                 {formatIndianNumber(totalGST18)}
//               </td>
//             </tr>
//           )}
//           {isIGST && totalGST28 > 0 && (
//             <tr>
//               <td
//                 style={{
//                   width: "30%",
//                   fontWeight: 600,
//                 }}
//               >
//                 IGST 28%
//               </td>
//               <td style={{ width: "10%", textAlign: "center" }}>:</td>
//               <td style={{ width: "10%", textAlign: "center" }}>28 %</td>
//               <td
//                 style={{
//                   width: "50%",
//                   textAlign: "right",
//                   paddingRight: 45,
//                 }}
//               >
//                 {formatIndianNumber(totalGST28)}
//               </td>
//             </tr>
//           )}
//           {!isIGST && totalGST5 > 0 && (
//             <>
//               <tr>
//                 <td
//                   style={{
//                     width: "30%",
//                     fontWeight: 600,
//                   }}
//                 >
//                   CGST 2.5%
//                 </td>
//                 <td style={{ width: "10%", textAlign: "center" }}>:</td>
//                 <td style={{ width: "10%", textAlign: "center" }}>2.5 %</td>
//                 <td
//                   style={{
//                     width: "50%",
//                     textAlign: "right",
//                     paddingRight: 45,
//                   }}
//                 >
//                   {formatIndianNumber(totalGST5 / 2)}
//                 </td>
//               </tr>
//               <tr>
//                 <td
//                   style={{
//                     width: "30%",
//                     fontWeight: 600,
//                   }}
//                 >
//                   SGST 2.5%
//                 </td>
//                 <td style={{ width: "10%", textAlign: "center" }}>:</td>
//                 <td style={{ width: "10%", textAlign: "center" }}>2.5 %</td>
//                 <td
//                   style={{
//                     width: "50%",
//                     textAlign: "right",
//                     paddingRight: 45,
//                   }}
//                 >
//                   {formatIndianNumber(totalGST5 / 2)}
//                 </td>
//               </tr>
//             </>
//           )}
//           {!isIGST && totalGST12 > 0 && (
//             <>
//               <tr>
//                 <td
//                   style={{
//                     width: "30%",
//                     fontWeight: 600,
//                   }}
//                 >
//                   CGST 6%
//                 </td>
//                 <td style={{ width: "10%", textAlign: "center" }}>:</td>
//                 <td style={{ width: "10%", textAlign: "center" }}>6 %</td>
//                 <td
//                   style={{
//                     width: "50%",
//                     textAlign: "right",
//                     paddingRight: 45,
//                   }}
//                 >
//                   {formatIndianNumber(totalGST12 / 2)}
//                 </td>
//               </tr>
//               <tr>
//                 <td
//                   style={{
//                     width: "30%",
//                     fontWeight: 600,
//                   }}
//                 >
//                   SGST 6%
//                 </td>
//                 <td style={{ width: "10%", textAlign: "center" }}>:</td>
//                 <td style={{ width: "10%", textAlign: "center" }}>6 %</td>
//                 <td
//                   style={{
//                     width: "50%",
//                     textAlign: "right",
//                     paddingRight: 45,
//                   }}
//                 >
//                   {formatIndianNumber(totalGST12 / 2)}
//                 </td>
//               </tr>
//             </>
//           )}
//           {!isIGST && totalGST18 > 0 && (
//             <>
//               <tr>
//                 <td
//                   style={{
//                     width: "30%",
//                     fontWeight: 600,
//                   }}
//                 >
//                   CGST 9%
//                 </td>
//                 <td style={{ width: "10%", textAlign: "center" }}>:</td>
//                 <td style={{ width: "10%", textAlign: "center" }}>9 %</td>
//                 <td
//                   style={{
//                     width: "50%",
//                     textAlign: "right",
//                     paddingRight: 45,
//                   }}
//                 >
//                   {formatIndianNumber(totalGST18 / 2)}
//                 </td>
//               </tr>
//               <tr>
//                 <td
//                   style={{
//                     width: "30%",
//                     fontWeight: 600,
//                   }}
//                 >
//                   SGST 9%
//                 </td>
//                 <td style={{ width: "10%", textAlign: "center" }}>:</td>
//                 <td style={{ width: "10%", textAlign: "center" }}>9 %</td>
//                 <td
//                   style={{
//                     width: "50%",
//                     textAlign: "right",
//                     paddingRight: 45,
//                   }}
//                 >
//                   {formatIndianNumber(totalGST18 / 2)}
//                 </td>
//               </tr>
//             </>
//           )}
//           {!isIGST && totalGST28 > 0 && (
//             <>
//               <tr>
//                 <td
//                   style={{
//                     width: "30%",
//                     fontWeight: 600,
//                   }}
//                 >
//                   CGST 14%
//                 </td>
//                 <td style={{ width: "10%", textAlign: "center" }}>:</td>
//                 <td style={{ width: "10%", textAlign: "center" }}>14 %</td>
//                 <td
//                   style={{
//                     width: "50%",
//                     textAlign: "right",
//                     paddingRight: 45,
//                   }}
//                 >
//                   {formatIndianNumber(totalGST28 / 2)}
//                 </td>
//               </tr>
//               <tr>
//                 <td
//                   style={{
//                     width: "30%",
//                     fontWeight: 600,
//                   }}
//                 >
//                   SGST 14%
//                 </td>
//                 <td style={{ width: "10%", textAlign: "center" }}>:</td>
//                 <td style={{ width: "10%", textAlign: "center" }}>14 %</td>
//                 <td
//                   style={{
//                     width: "50%",
//                     textAlign: "right",
//                     paddingRight: 45,
//                   }}
//                 >
//                   {formatIndianNumber(totalGST28 / 2)}
//                 </td>
//               </tr>
//             </>
//           )}

//           {!isIGST && totalGST > 0 && (
//             <>
//               <tr style={{ background: "whitesmoke" }}>
//                 <td
//                   style={{
//                     width: "30%",
//                     fontWeight: 600,
//                   }}
//                 >
//                   Total CGST
//                 </td>
//                 <td style={{ width: "10%", textAlign: "center" }}>:</td>
//                 <td style={{ width: "10%", textAlign: "center" }}></td>
//                 <td
//                   style={{
//                     width: "50%",
//                     textAlign: "right",
//                     paddingRight: 45,
//                   }}
//                 >
//                   {formatIndianNumber(totalGST / 2)}
//                 </td>
//               </tr>
//               <tr style={{ background: "whitesmoke" }}>
//                 <td
//                   style={{
//                     width: "30%",
//                     fontWeight: 600,
//                   }}
//                 >
//                   Total SGST
//                 </td>
//                 <td style={{ width: "10%", textAlign: "center" }}>:</td>
//                 <td style={{ width: "10%", textAlign: "center" }}></td>
//                 <td
//                   style={{
//                     width: "50%",
//                     textAlign: "right",
//                     paddingRight: 45,
//                   }}
//                 >
//                   {formatIndianNumber(totalGST / 2)}
//                 </td>
//               </tr>
//             </>
//           )}
//           {isIGST && totalGST > 0 && (
//             <tr style={{ background: "whitesmoke" }}>
//               <td
//                 style={{
//                   width: "30%",
//                   fontWeight: 600,
//                 }}
//               >
//                 Total IGST
//               </td>
//               <td style={{ width: "10%", textAlign: "center" }}>:</td>
//               <td style={{ width: "10%", textAlign: "center" }}></td>
//               <td
//                 style={{
//                   width: "50%",
//                   textAlign: "right",
//                   paddingRight: 45,
//                 }}
//               >
//                 {formatIndianNumber(totalGST)}
//               </td>
//             </tr>
//           )}
//         </table>
//       </div>
//     </>
//   );
// };

export default CreateSales;
