import React, { useEffect, useRef } from "react";
import { Field, FieldArrayRenderProps } from "@progress/kendo-react-form";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closeDialog } from "../../components/dialog/dialogSlice";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import AppDialog from "../../components/dialog/Dialog";
import FormNumericTextField from "../../components/formFields/FormNumericTextField";
import { Typography } from "@progress/kendo-react-common";
import { setPaymentBillDialogOpenIndex } from "./paymentSlice";
import { LoadingPanel } from "../../components/layout/Loading";
import { useLocation } from "react-router-dom";
import { Button } from "@progress/kendo-react-buttons";
import FormCheckbox from "../../components/formFields/FormCheckbox";

const PaymentBillDetailsArray = (
  fieldArrayRenderProps: FieldArrayRenderProps
) => {
  const dispatch = useAppDispatch();
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const loading = useAppSelector((state) => state.payment.loading);
  const gridRef = useRef<any>(null);
  const location = useLocation();
  const payment_guid = location.state?.payment_guid;
  const PaymentBillDialogOpenIndex = useAppSelector(
    (state) => state.payment.PaymentBillDialogOpenIndex
  );
  const PaymentDetail = useAppSelector((state) => state.payment.PaymentDetail);

  useEffect(() => {
    const element = document.getElementsByName(
      `payment_bill_entry.0.referance_amount`
    );
    if (!loading) {
      if (element?.[0]) {
        setTimeout(() => {
          element?.[0]?.focus();
          if (payment_guid) {
            element?.[0]?.blur();
          }
        }, 1);
      }
    }

    // console.log(document.activeElement);

    // console.log("debitcredidialog", debitcredidialog);
  }, [loading]);

  const handleCloseDialog = () => {
    dispatch(closeDialog());
    dispatch(setPaymentBillDialogOpenIndex(-1));
    const element = document.getElementById(
      `payment_entry.${PaymentBillDialogOpenIndex + 1}.debit_credit_id`
    );
    if (element) {
      setTimeout(() => {
        element.focus();
      }, 1);
    } else {
      const element = document.getElementById(`remarks_for_focus`);
      if (element) {
        setTimeout(() => {
          element.focus();
        }, 1);
      }
    }
  };

  const handleKeyEvent = (
    e: any,
    index: number,
    fieldArrayRenderProps: FieldArrayRenderProps
  ) => {
    const isremainingAmount0 =
      Array.isArray(fieldArrayRenderProps?.value) &&
      fieldArrayRenderProps?.value.length > 0 &&
      fieldArrayRenderProps.value[0]?.IndexDetails?.remainingAmount;

    let pendingAmount = 0;
    if (payment_guid) {
      pendingAmount =
        (fieldArrayRenderProps.value[index].final_pending_amount || 0) +
        (+PaymentDetail?.payment_entry?.[
          PaymentBillDialogOpenIndex
        ]?.payment_entry_referance.filter(
          (item: any) =>
            item.refernace_name ===
            fieldArrayRenderProps.formRenderProps.valueGetter(
              `payment_bill_entry.${index}.bill_no_string`
            )
        )?.[0]?.referance_amount || 0);

      console.log("GUID key change pending amount", pendingAmount);
    } else {
      pendingAmount =
        fieldArrayRenderProps.value[index]?.final_pending_amount || 0;
    }

    const remainingAmount =
      Array.isArray(fieldArrayRenderProps?.value) &&
      fieldArrayRenderProps?.value.length > 0 &&
      (fieldArrayRenderProps.value[0]?.IndexDetails?.remainingAmount || 0) +
        (fieldArrayRenderProps.value[index]?.referance_amount || 0);

    if (pendingAmount > remainingAmount) {
      fieldArrayRenderProps.formRenderProps.onChange(
        `payment_bill_entry.${index}.MaxAmount`,
        {
          value: +remainingAmount,
        }
      );
    } else {
      fieldArrayRenderProps.formRenderProps.onChange(
        `payment_bill_entry.${index}.MaxAmount`,
        {
          value: +pendingAmount,
        }
      );
    }

    if (e.key === "Tab" && isremainingAmount0 === 0) {
      if (!e.shiftKey) {
        dispatch(closeDialog());
        const element = document.getElementById(
          `payment_entry.${PaymentBillDialogOpenIndex + 1}.ledger_id_id`
        );
        if (element) {
          setTimeout(() => {
            element.focus();
          }, 1);
        } else {
          const element = document.getElementById(`remarks_for_focus`);
          if (element) {
            setTimeout(() => {
              element.focus();
            }, 1);
          }
        }
        dispatch(setPaymentBillDialogOpenIndex(-1));
      }
    }
  };

  const handleRefAmountChange = (
    e: any,
    index: number,
    fieldArrayRenderProps: FieldArrayRenderProps
  ) => {
    console.log("fieldArrayRenderProps", fieldArrayRenderProps);
    console.log("e", e.value);
    let pendingAmount = 0;
    if (payment_guid) {
      pendingAmount =
        (fieldArrayRenderProps.formRenderProps.valueGetter(
          `payment_bill_entry.${index}.final_pending_amount`
        ) || 0) +
        (+PaymentDetail?.payment_entry?.[
          PaymentBillDialogOpenIndex
        ]?.payment_entry_referance.filter(
          (item: any) =>
            item.refernace_name ===
            fieldArrayRenderProps.formRenderProps.valueGetter(
              `payment_bill_entry.${index}.bill_no_string`
            )
        )?.[0]?.referance_amount || 0);
    } else {
      pendingAmount =
        fieldArrayRenderProps.formRenderProps.valueGetter(
          `payment_bill_entry.${index}.final_pending_amount`
        ) || 0;
    }

    console.log("pendingAmount White Ref Amount", pendingAmount);
    console.log("pendingAmount White Ref e.value", e.value);
    if (e.value === pendingAmount) {
      fieldArrayRenderProps.formRenderProps.onChange(
        `payment_bill_entry.${index}.isfullpayment`,
        { value: true }
      );
    } else {
      fieldArrayRenderProps.formRenderProps.onChange(
        `payment_bill_entry.${index}.isfullpayment`,
        { value: false }
      );
    }
    console.log("index", index);
  };
  const handleIsFullPayment = (
    e: any,
    index: number,
    fieldArrayRenderProps: FieldArrayRenderProps
  ) => {
    if (e.value) {
      let pendingAmount = 0;
      if (payment_guid) {
        pendingAmount =
          (fieldArrayRenderProps.formRenderProps.valueGetter(
            `payment_bill_entry.${index}.final_pending_amount`
          ) || 0) +
          (+PaymentDetail?.payment_entry?.[
            PaymentBillDialogOpenIndex
          ]?.payment_entry_referance.filter(
            (item: any) =>
              item.refernace_name ===
              fieldArrayRenderProps.formRenderProps.valueGetter(
                `payment_bill_entry.${index}.bill_no_string`
              )
          )?.[0]?.referance_amount || 0);
      } else {
        pendingAmount =
          fieldArrayRenderProps.formRenderProps.valueGetter(
            `payment_bill_entry.${index}.final_pending_amount`
          ) || 0;
      }
      const remainingAmount =
        Array.isArray(fieldArrayRenderProps?.value) &&
        fieldArrayRenderProps?.value.length > 0 &&
        (fieldArrayRenderProps.value[0]?.IndexDetails?.remainingAmount || 0) +
          (fieldArrayRenderProps.value[index]?.referance_amount || 0);

      if (remainingAmount >= pendingAmount) {
        fieldArrayRenderProps.formRenderProps.onChange(
          `payment_bill_entry.${index}.referance_amount`,
          { value: pendingAmount }
        );
      } else {
        fieldArrayRenderProps.formRenderProps.onChange(
          `payment_bill_entry.${index}.isfullpayment`,
          { value: false }
        );
      }
    } else {
      fieldArrayRenderProps.formRenderProps.onChange(
        `payment_bill_entry.${index}.referance_amount`,
        { value: 0 }
      );
    }
    console.log("e", e);
    console.log("index", index);
  };

  return (
    <>
      {dialogName === "BillItemDeleteDialog" && (
        <AppDialog>
          <GridLayout cols={[{ width: "950px" }]}>
            <GridLayoutItem>
              <Typography.p className="m-0 text-center">
                Bill-wise Details For :{" "}
                <b>
                  {Array.isArray(fieldArrayRenderProps?.value) &&
                    fieldArrayRenderProps?.value.length > 0 &&
                    fieldArrayRenderProps.value[0]?.IndexDetails?.ledger_name}
                </b>
              </Typography.p>
              <Typography.p className="m-0 text-center">
                Up to :{" "}
                <b>
                  ₹{" "}
                  {Array.isArray(fieldArrayRenderProps?.value) &&
                    fieldArrayRenderProps?.value.length > 0 &&
                    fieldArrayRenderProps.value[0]?.IndexDetails?.amount}{" "}
                  Dr
                </b>
              </Typography.p>
              <Typography.p
                className=" text-center"
                style={{ position: "relative" }}
              >
                Remaining Amount: ₹{" "}
                <b>
                  {Array.isArray(fieldArrayRenderProps?.value) &&
                    fieldArrayRenderProps?.value.length > 0 &&
                    fieldArrayRenderProps.value[0]?.IndexDetails
                      ?.remainingAmount}{" "}
                  Dr.
                </b>
                {/* <button style={{visibility: "hidden", position: "absolute" }} id="use-me-for-focus">
                  Focus Button
                </button> */}
              </Typography.p>
            </GridLayoutItem>
            <GridLayoutItem className="bg-dark text-white fw-600">
              <GridLayout
                gap={{ rows: 10, cols: 0 }}
                cols={[
                  { width: "8%" },
                  { width: "15%" },
                  { width: "15%" },
                  { width: "13%" },
                  { width: "14%" },
                  { width: "17%" },
                  { width: "15%" },
                  { width: "3%" },
                ]}
              >
                <GridLayoutItem
                  style={{ padding: "5px 10px", border: "0.5px solid grey" }}
                >
                  Sr. No
                </GridLayoutItem>
                <GridLayoutItem
                  style={{ padding: "5px 10px", border: "0.5px solid grey" }}
                >
                  Bill No
                </GridLayoutItem>
                <GridLayoutItem
                  style={{ padding: "5px 10px", border: "0.5px solid grey" }}
                >
                  Bill Date
                </GridLayoutItem>
                <GridLayoutItem
                  style={{ padding: "5px 10px", border: "0.5px solid grey" }}
                >
                  Due Date
                </GridLayoutItem>
                <GridLayoutItem
                  style={{ padding: "5px 10px", border: "0.5px solid grey" }}
                >
                  Bill Amount
                </GridLayoutItem>
                <GridLayoutItem
                  style={{ padding: "5px 10px", border: "0.5px solid grey" }}
                >
                  Pending Amount
                </GridLayoutItem>
                <GridLayoutItem
                  style={{ padding: "5px 10px", border: "0.5px solid grey" }}
                >
                  Amount
                </GridLayoutItem>
                <GridLayoutItem
                  style={{ padding: "5px 10px", border: "0.5px solid grey" }}
                ></GridLayoutItem>
              </GridLayout>
            </GridLayoutItem>
            {loading ? (
              <LoadingPanel gridRef={gridRef} />
            ) : (
              <>
                {fieldArrayRenderProps.value &&
                  fieldArrayRenderProps.value.length > 0 &&
                  fieldArrayRenderProps.value.map(
                    (payment_bill_entry: any, index: number) => (
                      <GridLayoutItem
                        key={index}
                        style={{
                          backgroundColor:
                            index % 2 === 1 ? "#f4f1f1" : "#f9fdf9",
                        }}
                      >
                        <GridLayout
                          style={{
                            padding: "5px 0",
                          }}
                          gap={{ rows: 10, cols: 0 }}
                          cols={[
                            { width: "8%" },
                            { width: "15%" },
                            { width: "15%" },
                            { width: "13%" },
                            { width: "14%" },
                            { width: "17%" },
                            { width: "15%" },
                            { width: "3%" },
                          ]}
                        >
                          <GridLayoutItem
                            style={{
                              display: "flex",
                              alignItems: "center",
                              padding: "5px 10px",
                            }}
                          >
                            {index + 1}
                          </GridLayoutItem>
                          <GridLayoutItem
                            style={{
                              display: "flex",
                              alignItems: "center",
                              padding: "5px 10px",
                            }}
                          >
                            {payment_bill_entry?.bill_no_string}
                          </GridLayoutItem>
                          <GridLayoutItem
                            style={{
                              display: "flex",
                              alignItems: "center",
                              padding: "5px 10px",
                            }}
                          >
                            {payment_bill_entry?.invoice_date}
                          </GridLayoutItem>
                          <GridLayoutItem
                            style={{
                              display: "flex",
                              alignItems: "center",
                              padding: "5px 10px",
                            }}
                          >
                            {payment_bill_entry?.due_date}
                          </GridLayoutItem>
                          <GridLayoutItem
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "end",
                              padding: "5px 10px",
                            }}
                          >
                            {Number(payment_bill_entry?.total_amount).toFixed(
                              2
                            ) || 0}
                          </GridLayoutItem>
                          <GridLayoutItem
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "end",
                              padding: "5px 15px",
                            }}
                          >
                            {Number(
                              payment_bill_entry?.final_pending_amount
                            ).toFixed(2) || 0}
                          </GridLayoutItem>
                          <GridLayoutItem>
                            <Field
                              wrapperClassName="right-alighned-field"
                              wrapperStyle={{ margin: 0, padding: "5px" }}
                              name={`payment_bill_entry.${index}.referance_amount`}
                              placeholder="0"
                              format="n2"
                              max={
                                +fieldArrayRenderProps.formRenderProps.valueGetter(
                                  `payment_bill_entry.${index}.MaxAmount`
                                )
                              }
                              onChange={(e: any) =>
                                handleRefAmountChange(
                                  e,
                                  index,
                                  fieldArrayRenderProps
                                )
                              }
                              component={FormNumericTextField}
                              onKeyDown={(e: any) =>
                                handleKeyEvent(e, index, fieldArrayRenderProps)
                              }
                            />
                          </GridLayoutItem>
                          <GridLayoutItem>
                            <Field
                              wrapperClassName="right-alighned-field"
                              wrapperStyle={{
                                margin: "0px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                                borderRadius: "5px",
                              }}
                              size="large"
                              style={{
                                border: "1px solid #ccc",
                              }}
                              onChange={(e: any) =>
                                handleIsFullPayment(
                                  e,
                                  index,
                                  fieldArrayRenderProps
                                )
                              }
                              name={`payment_bill_entry.${index}.isfullpayment`}
                              component={FormCheckbox}
                            />
                          </GridLayoutItem>
                        </GridLayout>
                      </GridLayoutItem>
                    )
                  )}
              </>
            )}
            <GridLayoutItem>
              <GridLayout cols={[{ width: "950px" }]}>
                <GridLayoutItem
                  colSpan={4}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    marginTop: "10px",
                  }}
                >
                  <Button themeColor={"primary"} onClick={handleCloseDialog}>
                    Close
                  </Button>
                  {/* // <DialogCloseButton themeColor="error" /> */}
                </GridLayoutItem>
              </GridLayout>
            </GridLayoutItem>
          </GridLayout>
        </AppDialog>
      )}
    </>
  );
};

export default PaymentBillDetailsArray;
