import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import moment from "moment";

export const updateOldDistributorData = createAsyncThunk(
  "SaleOrder/DistributerPreviousYearQtyAdd",
  async (OrderData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SaleOrder/DistributerPreviousYearQtyAdd`,
        OrderData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating distributors data:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getOldDistributorDataByID = createAsyncThunk(
  "SaleOrder/DistributerQtyDetailFindByID",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SaleOrder/DistributerQtyDetailFindByID`,
        { accountid: ID }
      );
      // return response.data?.Data as any;
      const result = response.data?.Data as any;
      return {
        id: result?.id,
        accountid: result?.accountid,
        date: result?.date ? moment(result?.date, "YYYY-MM-DD").toDate() : "",
        distributer_qty: result?.distributer_qty
          ? result?.distributer_qty?.map((distributorQty: any) => {
              return {
                item_group_id: distributorQty?.item_group_id
                  ? distributorQty?.item_group_id
                  : null,
                item_group: distributorQty?.item_group
                  ? distributorQty?.item_group
                  : "",
                distributer_Previous_qty_list:
                  distributorQty?.distributer_Previous_qty_list
                    ? distributorQty?.distributer_Previous_qty_list?.map(
                        (subitem: any) => {
                          return {
                            id: subitem?.id ? +subitem?.id : null,
                            item_id: subitem?.item_id
                              ? +subitem?.item_id
                              : null,
                            item_group_id: subitem?.item_group_id
                              ? +subitem?.item_group_id
                              : null,
                            product_name: subitem?.product_name
                              ? subitem?.product_name
                              : "",
                            previous_year_qty: subitem?.previous_year_qty
                              ? +subitem?.previous_year_qty
                              : null,
                            current_year_qty: subitem?.current_year_qty
                              ? +subitem?.current_year_qty
                              : null,
                            unit_id: subitem?.unit_id
                              ? +subitem?.unit_id
                              : null,
                            unit_name: subitem?.unit_name
                              ? subitem?.unit_name
                              : "",
                          };
                        }
                      )
                    : [],
              };
            })
          : [],
      } as any;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching order details:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
