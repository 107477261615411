import React, { useCallback, useEffect } from "react";
import { Field, FieldArrayRenderProps } from "@progress/kendo-react-form";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { Typography } from "@progress/kendo-react-common";
import IconButton from "../../components/common/IconButton";
import { MdDelete } from "react-icons/md";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import { useLocation } from "react-router-dom";
import FormNumericTextField from "../../components/formFields/FormNumericTextField";
import FormIncrementalSearch from "../../components/formFields/FormIncrementalSearch";
import { axiosApiInstance } from "../../app/axios";
import { API_URL } from "../../app/config";
import { ErrorToast, SuccessToast } from "../../components/toast/Toasts";
import _ from "lodash";
import { setPackingDeleteIndex } from "./packingSlice";
import { getPackingItemCurrentStock } from "./services/packing.services";

const PackingMaterialItemDetailsArray = (
  fieldArrayRenderProps: FieldArrayRenderProps
) => {
  const dispatch = useAppDispatch();
  const InputRef = React.useRef<any>(null);
  const location = useLocation();
  const packing_guid = location.state?.packing_guid;

  const PackingDeleteIndex = useAppSelector(
    (state) => state.packing.PackingDeleteIndex
  );
  const PackingDetail = useAppSelector((state) => state.packing.PackingDetail);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);

  useEffect(() => {
    if (!packing_guid) {
      pushElementInarray();
    }
  }, []);

  const handleOpenDeleteDialog = (index: number) => {
    dispatch(openDialog("packingItemDeleteDialog"));
    dispatch(setPackingDeleteIndex(index));
  };

  const handleDeleteAction = React.useCallback(
    async (index: number) => {
      if (fieldArrayRenderProps?.value[index].id) {
        try {
          const response = await axiosApiInstance.post(
            `${API_URL}/Packing/DeletePackingItem`,
            { id: fieldArrayRenderProps?.value[index].id }
          );
          fieldArrayRenderProps.onRemove({ index: index });
          dispatch(closeDialog());
          dispatch(setPackingDeleteIndex(-1));
          SuccessToast(response.data?.Details || "Success");
        } catch (error: any) {
          ErrorToast(error?.response?.data?.Details || "Something went wrong");
          console.error("Error deleting Item:", error);
          return Promise.reject({
            message: error?.response?.data?.Details || "Something went wrong",
          });
        }
      } else {
        fieldArrayRenderProps.onRemove({ index: index });
        dispatch(closeDialog());
        dispatch(setPackingDeleteIndex(-1));
      }
    },
    [fieldArrayRenderProps]
  );

  const pushElementInarray = React.useCallback(() => {
    fieldArrayRenderProps.onPush({
      value: {
        item_id: null,
        current_qty: null,
        used_qty: null,
      },
    });

    setTimeout(() => {
      InputRef.current?.element?.children[0]?.children[1]?.children[0]?.focus();
    }, 0);
  }, [fieldArrayRenderProps]);

  const fetchCurrentStock = useCallback(
    async (index: number, item: any) => {
      if (item?.item_id) {
        const response = await dispatch(
          getPackingItemCurrentStock(item.item_id)
        );
        fieldArrayRenderProps?.formRenderProps.onChange(
          `packing_item_details.${index}.current_qty`,
          {
            value: response.payload?.actual_stock || 0,
          }
        );
      }
    },
    [dispatch, fieldArrayRenderProps?.formRenderProps]
  );

  const handleFieldChange = (field: string, index: number, item: any) => {
    switch (field) {
      case "item_id":
        fetchCurrentStock(index, item);
        break;
      default:
        break;
    }
  };

  const debouncedHandleProductChange = React.useRef(
    _.debounce((field: any, index: number, item: any) => {
      handleFieldChange(field, index, item);
    }, 300)
  ).current;

  useEffect(() => {
    return () => {
      debouncedHandleProductChange.cancel();
    };
  }, []);

  const handleChange = (field: any, index: number, item: any) => {
    debouncedHandleProductChange(field, index, item);
  };

  return (
    <>
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          className="bg-dark text-white fw-600"
          style={{ width: "100%" }}
        >
          <GridLayout
            style={{
              marginRight: 30,
              padding: 10,
            }}
            gap={{ rows: 10, cols: 10 }}
            cols={[
              { width: "46%" },
              { width: "25%" },
              { width: "25%" },
              { width: "4%" },
            ]}
          >
            <GridLayoutItem>Item</GridLayoutItem>
            <GridLayoutItem>Current Stock</GridLayoutItem>
            <GridLayoutItem>Used Qty</GridLayoutItem>
            <GridLayoutItem></GridLayoutItem>
          </GridLayout>
        </GridLayoutItem>
        {dialogName === "packingItemDeleteDialog" && (
          <AppDialog>
            <>
              <Typography.h5>{"Delete Item"}</Typography.h5>
              <GridLayout>
                <GridLayoutItem>
                  <Typography.p>
                    Are you sure you want to delete this?
                  </Typography.p>
                </GridLayoutItem>
                <GridLayoutItem
                  style={{
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <DialogCloseButton themeColor="error" />
                  <Button
                    fillMode={"solid"}
                    themeColor={"error"}
                    onClick={() => handleDeleteAction(PackingDeleteIndex)}
                    type="button"
                  >
                    Delete
                  </Button>
                </GridLayoutItem>
              </GridLayout>
            </>
          </AppDialog>
        )}
        {fieldArrayRenderProps.value &&
          fieldArrayRenderProps.value.length > 0 &&
          fieldArrayRenderProps.value.map(
            (packing_item_details: any, index: number) => (
              <GridLayoutItem key={index} style={{ width: "100%" }}>
                <GridLayout
                  style={{ marginRight: 30, padding: 10, position: "relative" }}
                  gap={{ rows: 10, cols: 10 }}
                  cols={[
                    { width: "46%" },
                    { width: "25%" },
                    { width: "25%" },
                    { width: "4%" },
                  ]}
                >
                  <GridLayoutItem ref={InputRef}>
                    <Field
                      wrapperStyle={{ margin: 0 }}
                      name={`packing_item_details.${index}.item_id`}
                      placeholder="Product"
                      isAddNew={true}
                      addNewLink="item/create"
                      component={FormIncrementalSearch}
                      validator={requiredValidator}
                      onChange={() =>
                        handleChange(
                          `item_id`,
                          index,
                          fieldArrayRenderProps.value[index]
                        )
                      }
                      fetchIncrementalData={(search: any) =>
                        fieldArrayRenderProps.handlePackingItemSearchChange(
                          search,
                          `packing_item_details.${index}.item_options`,
                          fieldArrayRenderProps.formRenderProps
                        )
                      }
                      options={
                        fieldArrayRenderProps.value[index]?.item_options?.map(
                          (item: any) => {
                            return {
                              value: item?.packing_item_id,
                              label: item?.packing_item_name,
                            };
                          }
                        ) || []
                      }
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperClassName="stock-label right-alighned-field"
                      wrapperStyle={{ margin: 0 }}
                      name={`packing_item_details.${index}.current_qty`}
                      placeholder="0"
                      format="n3"
                      disabled={true}
                      component={FormNumericTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperClassName="right-alighned-field"
                      wrapperStyle={{ margin: 0 }}
                      name={`packing_item_details.${index}.used_qty`}
                      max={
                        packing_guid
                          ? (PackingDetail?.packing_item_details[index]
                              ?.used_qty || 0)  +
                            fieldArrayRenderProps.formRenderProps.valueGetter(
                              `packing_item_details.${index}.current_qty`
                            )
                          : fieldArrayRenderProps.formRenderProps.valueGetter(
                              `packing_item_details.${index}.current_qty`
                            )
                      }
                      placeholder="0"
                      type="number"
                      format="n3"
                      minNumber="0"
                      validator={requiredValidator}
                      component={FormNumericTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem
                    style={{
                      textAlign: "end",
                    }}
                  >
                    <IconButton
                      onClick={() => handleOpenDeleteDialog(index)}
                      disabled={fieldArrayRenderProps.value.length === 1}
                      themeColor={"error"}
                      size={"small"}
                      fillMode={"solid"}
                      type="button"
                      style={{
                        height: 32,
                        width: 32,
                      }}
                    >
                      <MdDelete
                        className="absolute-position"
                        style={{ fontSize: "16px" }}
                      />
                    </IconButton>
                  </GridLayoutItem>
                </GridLayout>
              </GridLayoutItem>
            )
          )}
      </GridLayout>
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{ marginTop: 10, borderTop: "1px solid lightgray" }}
        >
          <GridLayout
            style={{ marginRight: 25, padding: 10 }}
            gap={{ rows: 10, cols: 5 }}
            cols={[
              { width: "25%" },
              { width: "14%" },
              { width: "12%" },
              { width: "16%" },
              { width: "16%" },
              { width: "12%" },
              { width: "5%" },
            ]}
          >
            <GridLayoutItem colSpan={2} className="d-flex align-items-end">
              <Button
                fillMode={"solid"}
                themeColor={"primary"}
                onClick={pushElementInarray}
                type="button"
              >
                Add Row
              </Button>
            </GridLayoutItem>
            {/* <GridLayoutItem
              className="d-flex justify-content-end align-items-center fw-600"
              colSpan={3}
            >
              Total Qty
            </GridLayoutItem>
            <GridLayoutItem colSpan={2}>
              <Field
                wrapperClassName="right-alighned-field"
                wrapperStyle={{ margin: 0 }}
                name="total_qty"
                placeholder="0"
                format="n3"
                component={FormNumericTextField}
                disabled={true}
              />
            </GridLayoutItem> */}
          </GridLayout>
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

export default PackingMaterialItemDetailsArray;
