import { Button } from "@progress/kendo-react-buttons";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import {
  CompositeFilterDescriptor,
  filterBy,
} from "@progress/kendo-data-query";
import {
  GridCellProps,
  GridCustomCellProps,
  GridCustomHeaderCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  Grid as KendoGrid,
  GridColumn as Column,
} from "@progress/kendo-react-grid";
import { HeaderThElement } from "@progress/kendo-react-data-tools";
import IconButton from "../../components/common/IconButton";
import { MdDelete } from "react-icons/md";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { Switch, TextBox } from "@progress/kendo-react-inputs";
import { LoadingPanel } from "../../components/layout/Loading";
import {
  ActiveInactiveRetailer,
  deleteRetailer,
  getAllRetailers,
} from "./services/retailer.services";
import {
  clearRetailerDetails,
  setFilterRetailer,
  setRetailerID,
} from "./retailerSlice";
import { FiEdit } from "react-icons/fi";
import { checkAcessRights } from "../../_helper/helper";
import {
  DELETE_ACCESS,
  EDIT_ACCESS,
  INSERT_ACCESS,
} from "../../_contstants/common";
import { setCurrentPage, setPageLimit } from "../pagination/paginationSlice";
import _debounce from "lodash/debounce";
import { filterClearIcon } from "@progress/kendo-svg-icons";
// import RetailerTab from "./RetailerTab";

const Retailer: React.FC = () => {
  const navigate = useNavigate();
  const isAddAccess = checkAcessRights(location.pathname, INSERT_ACCESS);
  const handleRetailerCreate = () => {
    navigate("/retailer/create");
  };

  return (
    <>
      <DeleteRetailerDialog />
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "4px 20px 20px 0",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            Retailer List
          </Typography.h4>

          {isAddAccess && (
            <Button
              onClick={handleRetailerCreate}
              fillMode={"solid"}
              themeColor={"primary"}
            >
              Add New
            </Button>
          )}
        </GridLayoutItem>
        <GridLayoutItem>
          <RetailerGridComponent />
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

const RetailerGridComponent: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isEditAccess = checkAcessRights(location.pathname, EDIT_ACCESS);
  const isDeleteAccess = checkAcessRights(location.pathname, DELETE_ACCESS);
  const isEditBtnRef = useRef<any>(false);
  const RetailerList = useAppSelector((state) => state.retailer.RetailerList);
  const loading = useAppSelector((state) => state.retailer.loading);
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);
  const FilterRetailer: any = useAppSelector(
    (state) => state.retailer.FilterRetailer
  );
  useEffect(() => {
    return () => {
      dispatch(closeDialog());
      dispatch(setCurrentPage(0));
      dispatch(setCurrentPage(0));
      if (!isEditBtnRef.current) {
        dispatch(clearRetailerDetails());
      }
    };
  }, []);

  useEffect(() => {
    const payload = {
      ...FilterRetailer,
      limit: +pageLimit,
      skip: +currentPage * +pageLimit,
    };
    dispatch(getAllRetailers(payload));
  }, [currentPage, pageLimit]);

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);

  const pageChange = (event: GridPageChangeEvent) => {
    const newPage = event.page.skip / event.page.take;
    dispatch(setPageLimit(event.page.take));
    dispatch(setCurrentPage(newPage));
  };

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleEditRetailer = (retailer_guid: any) => {
      navigate("/retailer/edit", {
        state: { retailer_guid: retailer_guid },
      });
    };

    const handleOpenDeleteDialog = (retailer_guid: any) => {
      dispatch(openDialog("deleteRetailer"));
      dispatch(setRetailerID(retailer_guid));
    };

    return (
      <td className={`action-td ${props.className}`} style={props.style}>
        {isEditAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Edit"
            ref={isEditBtnRef}
            onClick={() => {
              isEditBtnRef.current = true;
              handleEditRetailer(props.dataItem.retailer_guid);
            }}
          >
            <FiEdit
              className="absolute-position"
              style={{ fontSize: "20px" }}
            />
          </IconButton>
        )}
        {isDeleteAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Delete"
            onClick={() => handleOpenDeleteDialog(props.dataItem.retailer_guid)}
          >
            <MdDelete
              className="absolute-position"
              style={{ fontSize: "24px" }}
            />
          </IconButton>
        )}
      </td>
    );
  };
  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
  };

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className={`table-header ${
        props.index === 8 || props.index === 9 ? "locked-header" : ""
      }`}
    >
      {props.children}
    </HeaderThElement>
  );
  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };
  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );
  const MyActiveInActtiveCommandCell = (props: GridCellProps) => {
    const [IsActive, setIsActive] = useState<boolean>(props.dataItem?.isactive);

    const handleActiveInActive = () => {
      setIsActive(!IsActive);
      const data = {
        retailer_guid: props.dataItem?.retailer_guid,
        isactive: !IsActive,
      };
      const payload = {
        retailer_name: "",
        limit: +pageLimit,
        skip: +currentPage * +pageLimit,
      };
      dispatch(ActiveInactiveRetailer(data)).then((response: any) => {
        if (response.payload?.data?.Status === 200) {
          dispatch(getAllRetailers(payload));
        } else {
          dispatch(getAllRetailers(payload));
        }
      });
    };

    return (
      <td
        style={{ textAlign: "center", ...props.style }}
        className={props.className}
      >
        <Tooltip anchorElement="target" position="top" parentTitle={true}>
          <div title={`Set ${IsActive ? "Inactive" : "Active"}.`}>
            <Switch
              disabled={isEditAccess ? false : true}
              name="isactive"
              checked={IsActive}
              onChange={handleActiveInActive}
              size="small"
            />
          </div>
        </Tooltip>
      </td>
    );
  };

  const gridRef = useRef<any>(null);
  // if (loading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <>
      {loading && <LoadingPanel gridRef={gridRef} />}

      <KendoGrid
        style={{ maxHeight: "calc(100vh - 152px)" }}
        className="responsive-table"
        filterable={true}
        filter={filter}
        data={filter ? filterBy(RetailerList, filter) : RetailerList}
        onFilterChange={handleFilterChange}
        skip={currentPage * pageLimit}
        take={pageLimit}
        total={RetailerList[0]?.itemcount || RetailerList?.length}
        pageable={{
          buttonCount: 5,
          pageSizes: [10, 50, 100, 300, 500],
          pageSizeValue: pageLimit,
          type: "input",
        }}
        onPageChange={pageChange}
        cells={{
          headerCell: HeaderCustomCell,
          data: MyDataCustomCell,
        }}
      >
        <Column
          field="retailer_name"
          title="Retailer Name"
          width={250}
          filterCell={CommonFilterCell}
          cell={(props: any) => (
            <td>
              <div
                className={isEditAccess ? "hoverableName" : "normalName"}
                onClick={() => {
                  if (isEditAccess) {
                    isEditBtnRef.current = true;
                    navigate("/retailer/edit", {
                      state: { retailer_guid: props.dataItem?.retailer_guid },
                    });
                  }
                }}
              >
                {props.dataItem?.retailer_name}
              </div>
            </td>
          )}
        />
        {/* <Column field="account_name" title="Distributor Name" width={200} /> */}
        <Column field="address1" title="Address" width={350} />
        <Column field="area_name" title="Area" width={200} />
        <Column field="city_name" title="City" width={200} />
        <Column field="state_name" title="State" width={200} />
        <Column field="country_name" title="Country" width={200} />
        <Column field="mobile_no" title="Mobile No" width={200} />
        <Column field="email_id" title="Email ID" width={250} />
        <Column
          field="isactive"
          title="Status"
          cell={MyActiveInActtiveCommandCell}
          width={"90px"}
          filterable={false}
          locked={true}
        />
        {(isEditAccess || isDeleteAccess) && (
          <Column
            field="retailer_guid"
            title="Actions"
            cell={MyEditCommandCell}
            width={"110px"}
            filterable={false}
            locked={true}
          />
        )}
      </KendoGrid>
    </>
  );
};

const DeleteRetailerDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const RetailerID = useAppSelector((state) => state.retailer.RetailerID);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);
  const FilterRetailer: any = useAppSelector(
    (state) => state.retailer.FilterRetailer
  );
  const handleDeleteAction = async (ID: number | null) => {
    if (ID) {
      const response = await dispatch(deleteRetailer(ID));
      const fetchpayload = {
        ...FilterRetailer,
        limit: +pageLimit,
        skip: +currentPage * +pageLimit,
      };
      if (response.payload?.Data.Status === 200) {
        dispatch(getAllRetailers(fetchpayload));
        dispatch(closeDialog());
        dispatch(clearRetailerDetails());
      } else {
        dispatch(getAllRetailers(fetchpayload));
        dispatch(closeDialog());
        dispatch(clearRetailerDetails());
      }
    }
  };

  return (
    <>
      {dialogName === "deleteRetailer" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Retailer ?"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this Retailer?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => {
                    handleDeleteAction(RetailerID);
                  }}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

const CommonFilterCell = (props: any) => {
  const dispatch = useAppDispatch();
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);
  const FilterRetailer: any = useAppSelector(
    (state) => state.retailer.FilterRetailer
  );
  const isFilterRef = useRef(true);
  const [inputValue, setInputValue] = useState(props.value || "");
  const [inputField, setInputField] = useState(props.field || "");

  const onChange = (event: any) => {
    setInputValue(event.target.value);
    setInputField(event.target.name);
  };

  const delayedApiCall = React.useCallback(
    _debounce((updatedFilters: any) => {
      dispatch(setCurrentPage(0));
      const payload = {
        ...updatedFilters,
        limit: +pageLimit,
        skip: 0 * +pageLimit,
      };

      dispatch(getAllRetailers(payload));
    }, 300),
    [dispatch]
  );

  useEffect(() => {
    if (!isFilterRef.current) {
      const updatedFilters = { ...FilterRetailer, [inputField]: inputValue };
      dispatch(setFilterRetailer(updatedFilters));
      delayedApiCall(updatedFilters);
    } else {
      isFilterRef.current = false;
    }
    return () => {
      delayedApiCall.cancel();
    };
  }, [inputValue, delayedApiCall]);

  const onClearButtonClick = (event: any) => {
    event.preventDefault();
    props.onChange({
      value: null,
      operator: "",
      syntheticEvent: event,
    });

    const updatedFilters = { ...FilterRetailer, [props.field]: "" };
    dispatch(setFilterRetailer(updatedFilters));

    const payload = {
      ...updatedFilters,
      limit: +pageLimit,
      skip: +currentPage * +pageLimit,
    };

    dispatch(getAllRetailers(payload));
  };

  return (
    <div className="d-flex">
      <TextBox
        value={FilterRetailer[props.field] || ""}
        name={props.field || ""}
        onChange={onChange}
      />
      <Button
        svgIcon={filterClearIcon}
        title="Clear"
        disabled={!FilterRetailer[props.field]}
        onClick={onClearButtonClick}
        type="button"
        style={{
          marginLeft: "8px",
        }}
      ></Button>
    </div>
  );
};

export default Retailer;
