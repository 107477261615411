import React, { useCallback, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Typography } from "@progress/kendo-react-common";
import {
  Field,
  FieldArray,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import FormTextField from "../../components/formFields/FormTextField";
import FormDatePicker from "../../components/formFields/FormDateField";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import ShadowCard from "../../components/common/ShadowCard";
import { Button } from "@progress/kendo-react-buttons";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { LoadingPanel } from "../../components/layout/Loading";
import { getAllItemIncremental } from "../Item/services/item.services";
import { clearProductionDetails } from "./productionSlice";
import {
  createProduction,
  getAllBOMDetails,
  getProductionByID,
  updateProduction,
} from "./services/production.services";
import { IItem } from "../Item/itemModel";
import FormTimePicker from "../../components/formFields/FormTimeField";
import { getAllActiveUsers } from "../user/services/user.services";
import { IUser } from "../user/userModel";
import moment from "moment";
import { getGRNByItemID } from "../inwardreturn/services/inwardReturn.services";
import FormNumericTextField from "../../components/formFields/FormNumericTextField";
import { findAllActiveProcess } from "../process/services/process.services";
import { getCurrentStock } from "../stocktransfer/services/stockTransfer.services";
import {
  formatIndianNumberForQty,
  getLocalStorageItem,
} from "../../_helper/helper";
import FormIncrementalSearch from "../../components/formFields/FormIncrementalSearch";
import { FINANCIAL_YEAR } from "../../_contstants/common";
import ProductionItemDetailsArray2 from "./ProductionItemDetailsArray2";
import _ from "lodash";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}

const QtyPercentageWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const total_qty = formRenderProps.valueGetter("total_qty");
  const loss_qty = formRenderProps.valueGetter("loss_qty");

  useEffect(() => {
    const lossPercentage = (loss_qty / total_qty) * 100;
    formRenderProps.onChange("production_loss_percentage", {
      value: `${lossPercentage ? lossPercentage?.toFixed(2) : 0} %`,
    });
  }, [total_qty, loss_qty]);

  return null;
};

const QtychangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const ProductionItemDetails = formRenderProps.valueGetter(
    "ProductionItemDetails"
  );
  const total_quantity = formRenderProps.valueGetter("total_quantity");
  const quantity = formRenderProps.valueGetter("quantity");
  const total_qty = formRenderProps.valueGetter("total_qty");
  const received_qty = formRenderProps.valueGetter("received_qty");

  let totalQty = 0;

  useEffect(() => {
    if (ProductionItemDetails && ProductionItemDetails.length > 0) {
      ProductionItemDetails?.map((item: any) => {
        totalQty += +item?.used_qty || 0;
      });
    }
    formRenderProps.onChange("total_quantity", {
      value: totalQty,
    });
  }, [ProductionItemDetails.map((item: any) => item?.used_qty).join("-")]);

  useEffect(() => {
    formRenderProps.onChange("total_qty", {
      value: +total_quantity + +quantity || 0,
    });
    formRenderProps.onChange("loss_qty", {
      value: +total_qty - +received_qty,
    });
  }, [total_quantity, quantity, total_qty, received_qty]);

  return null;
};

const InnerItemChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const ProductionItemDetails = formRenderProps.valueGetter(
    "ProductionItemDetails"
  );
  const isFetchGrnOption = React.useRef(true);
  const Production_guid = location.state?.Production_guid;

  const fetchGRNOptions = useCallback(
    async (index: number, item: any) => {
      if (item?.item_id && item?.process_id) {
        const grnPayload = {
          item_id: item.item_id,
          process_id: item?.process_id,
          warehouse_id: 1,
        };
        const response = await dispatch(getGRNByItemID(grnPayload));
        const result =
          response.payload
            ?.filter((grn: any) => grn.item_id === item.item_id)
            .map((grnno: any) => ({
              value: `${grnno.grn_no}`,
              label: `${grnno.grn_no} - ${formatIndianNumberForQty(
                grnno?.current_stock || 0
              )}`,
            })) || [];
        formRenderProps.onChange(
          `ProductionItemDetails.${index}.grnNoOptions`,
          {
            value: result,
          }
        );
      } else {
        formRenderProps.onChange(
          `ProductionItemDetails.${index}.grnNoOptions`,
          {
            value: [],
          }
        );
        formRenderProps.onChange(`ProductionItemDetails.${index}.grn_no`, {
          value: null,
        });
      }
    },
    [dispatch, formRenderProps]
  );

  const fetchCurrentStock = useCallback(
    async (index: number, item: any) => {
      if (item?.item_id && item?.grn_no && item?.process_id) {
        const payload = {
          item_id: +item.item_id,
          grn_no: item.grn_no,
          process_id: +item.process_id,
          warehouse_id: 1,
        };
        const response = await dispatch(getCurrentStock(payload));
        formRenderProps.onChange(`ProductionItemDetails.${index}.stock_qty`, {
          value: response.payload?.actual_stock || 0,
        });
        formRenderProps.onChange(`ProductionItemDetails.${index}.dagina`, {
          value: response.payload?.dagina || 0,
        });
      }
    },
    [dispatch, formRenderProps]
  );

  useEffect(() => {
    if (isFetchGrnOption.current && Production_guid) {
      ProductionItemDetails.forEach((item: any, index: number) => {
        fetchGRNOptions(index, item);
        fetchCurrentStock(index, item);
      });
      isFetchGrnOption.current = false;
    }
  }, [Production_guid]);

  return null;
};

const UserChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const location = useLocation();
  const UserID = getLocalStorageItem("UserID");
  const Production_guid = location.state?.Production_guid;

  useEffect(() => {
    if (!Production_guid) {
      formRenderProps.onChange("supervisor_by", {
        value: UserID ? +UserID : null,
      });
    }
  }, [UserID]);

  return null;
};

const CreateProduction2: React.FC = () => {
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const Production_guid = location.state?.Production_guid;
  const loading = useAppSelector((state) => state.production.loading);
  const ProductionDetail = useAppSelector(
    (state) => state.production.ProductionDetail
  );
  const UserList = useAppSelector((state) => state.user.UserList);

  const [formKey, setFormKey] = React.useState(1);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [ProductionDetail]);

  useEffect(() => {
    dispatch(getAllActiveUsers());
    dispatch(findAllActiveProcess());

    return () => {
      dispatch(clearProductionDetails());
    };
  }, []);

  useEffect(() => {
    if (Production_guid) {
      dispatch(getProductionByID(Production_guid));
    }
  }, [Production_guid]);

  const handleItemChange = async (formRenderProps: FormRenderProps) => {
    const item_id = formRenderProps.valueGetter("item_id");
    const total_bom_qty = formRenderProps.valueGetter("total_bom_qty");
    if (item_id && total_bom_qty) {
      const BOMPayload = {
        item_id: item_id,
        total_qty: total_bom_qty,
      };

      const response = await dispatch(getAllBOMDetails(BOMPayload));
      if (response.meta?.requestStatus === "fulfilled") {
        const ProductionDetails = response.payload?.ProductionItemDetails?.map(
          (item: any) => {
            return {
              item_options: item?.item_options,
              item_id: item?.item_id || null,
              bom_qty: item?.item_qty || null,
              used_qty: item?.used_qty || null,
              grnNoOptions:
                item?.ProductionItemDetails?.map((detail: any) => ({
                  label: `${detail.grn_no}`,
                  value: `${detail.grn_no}`,
                })) || [],
            };
          }
        );
        formRenderProps.onChange("ProductionItemDetails", {
          value: ProductionDetails || [],
        });
      }
    }
  };

  const debouncedHandleProductChange = React.useRef(
    _.debounce((formRenderProps: any) => {
      handleItemChange(formRenderProps);
    }, 300)
  ).current;

  useEffect(() => {
    return () => {
      debouncedHandleProductChange.cancel();
    };
  }, []);

  const handleChange = (formRenderProps: any) => {
    debouncedHandleProductChange(formRenderProps);
  };

  const handleItemSearchChange = useCallback(
    async (search: string, field: string, formRenderProps: FormRenderProps) => {
      const result = await dispatch(getAllItemIncremental({ search }));
      formRenderProps.onChange(field, {
        value: result.payload,
      });
    },
    [dispatch]
  );

  const handleSubmit = async (values: any) => {
    if (Production_guid) {
      try {
        const updatePayload = {
          Production_guid: Production_guid,
          start_date: values?.start_date
            ? moment(values?.start_date).format("YYYY-MM-DD")
            : "",
          // end_date: values?.end_date
          //   ? moment(values?.end_date).format("YYYY-MM-DD")
          //   : "",
          end_date: "",
          lot_no: values?.lot_no ? values?.lot_no : "",
          item_id: values?.item_id ? +values?.item_id : null,
          no_of_workers: values?.no_of_workers ? +values?.no_of_workers : null,
          start_time: values?.start_time
            ? moment(values?.start_time).format("HH:mm:ss")
            : "",
          end_time: values?.end_time
            ? moment(values?.end_time).format("HH:mm:ss")
            : "",
          roasting_duration: values?.roasting_duration
            ? values?.roasting_duration
            : "",
          old_material_lot_no: values?.old_material_lot_no
            ? values?.old_material_lot_no
            : "",
          total_quantity: values?.total_quantity ? +values?.total_quantity : 0,
          quantity: values?.quantity ? +values?.quantity : 0,
          total_qty: values?.total_qty ? +values?.total_qty : 0,
          total_quantities: values?.total_bom_qty ? +values?.total_bom_qty : 0,
          received_qty: values?.received_qty ? +values?.received_qty : 0,
          loss_qty: values?.loss_qty ? +values?.loss_qty : 0,
          supervisor_by: values?.supervisor_by ? +values?.supervisor_by : null,
          financial_year: FINANCIAL_YEAR,
          remarks: values?.remarks ? values?.remarks : "",
          ProductionItemDetails: values?.ProductionItemDetails
            ? values?.ProductionItemDetails?.map((item: any) => {
                return {
                  id: item?.id ? +item?.id : 0,
                  process_id: item?.process_id ? +item?.process_id : null,
                  item_id: item?.item_id ? +item?.item_id : null,
                  bom_qty: item?.bom_qty ? +item?.bom_qty : 0,
                  grn_no: item?.grn_no ? item?.grn_no : "",
                  stock_qty: item?.stock_qty ? +item?.stock_qty : 0,
                  used_qty: item?.used_qty ? +item?.used_qty : 0,
                };
              })
            : [],
        };
        const response = await dispatch(updateProduction(updatePayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate("/production");
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    } else {
      try {
        const insertPayload = {
          start_date: values?.start_date
            ? moment(values?.start_date).format("YYYY-MM-DD")
            : "",
          // end_date: values?.end_date
          //   ? moment(values?.end_date).format("YYYY-MM-DD")
          //   : "",
          end_date: "",
          lot_no: values?.lot_no ? values?.lot_no : "",
          item_id: values?.item_id ? +values?.item_id : null,
          no_of_workers: values?.no_of_workers ? +values?.no_of_workers : null,
          start_time: values?.start_time
            ? moment(values?.start_time).format("HH:mm:ss")
            : "",
          end_time: values?.end_time
            ? moment(values?.end_time).format("HH:mm:ss")
            : "",
          roasting_duration: values?.roasting_duration
            ? values?.roasting_duration
            : "",
          old_material_lot_no: values?.old_material_lot_no
            ? values?.old_material_lot_no
            : "",
          total_quantity: values?.total_quantity ? +values?.total_quantity : 0,
          quantity: values?.quantity ? +values?.quantity : 0,
          total_qty: values?.total_qty ? +values?.total_qty : 0,
          total_quantities: values?.total_bom_qty ? +values?.total_bom_qty : 0,
          received_qty: values?.received_qty ? +values?.received_qty : 0,
          loss_qty: values?.loss_qty ? +values?.loss_qty : 0,
          supervisor_by: values?.supervisor_by ? +values?.supervisor_by : null,
          financial_year: FINANCIAL_YEAR,
          remarks: values?.remarks ? values?.remarks : "",
          ProductionItemDetails: values?.ProductionItemDetails
            ? values?.ProductionItemDetails?.map((item: any) => {
                return {
                  id: item?.id ? +item?.id : 0,
                  process_id: item?.process_id ? +item?.process_id : null,
                  item_id: item?.item_id ? +item?.item_id : null,
                  bom_qty: item?.bom_qty ? +item?.bom_qty : 0,
                  grn_no: item?.grn_no ? item?.grn_no : "",
                  stock_qty: item?.stock_qty ? +item?.stock_qty : 0,
                  used_qty: item?.used_qty ? +item?.used_qty : 0,
                };
              })
            : [],
        };
        const response = await dispatch(createProduction(insertPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate("/production");
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    }
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      <Form
        key={formKey}
        onSubmit={handleSubmit}
        initialValues={ProductionDetail}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12 }}>
              <GridLayout
                style={{ marginRight: 20 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "33.33%" },
                  { width: "33.33%" },
                  { width: "33.33%" },
                  // { width: "25%" },
                ]}
              >
                <GridLayoutItem colSpan={3}>
                  <Typography.h4>
                    {Production_guid ? "Update Production" : "Add Production"}
                  </Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="item_id"
                    label="Product Name"
                    placeholder="Product Name"
                    component={FormIncrementalSearch}
                    validator={requiredValidator}
                    astrike={true}
                    onChange={() => handleChange(formRenderProps)}
                    fetchIncrementalData={(search: any) =>
                      handleItemSearchChange(
                        search,
                        `item_options`,
                        formRenderProps
                      )
                    }
                    options={
                      formRenderProps
                        .valueGetter("item_options")
                        ?.map((item: IItem) => {
                          return {
                            value: item?.id,
                            label: item?.product_name,
                          };
                        }) || []
                    }
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="right-alighned-field"
                    name="total_bom_qty"
                    label="Total Qty."
                    placeholder="0"
                    disabled={Production_guid}
                    onChange={() => handleChange(formRenderProps)}
                    component={FormNumericTextField}
                    // validator={requiredValidator}
                    // astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem style={{ display: "flex", gap: 10 }}>
                  <Field
                    wrapperStyle={{ width: "100%" }}
                    name="start_date"
                    label="Start Date"
                    format="dd/MM/yyyy"
                    component={FormDatePicker}
                    validator={requiredValidator}
                    astrike={true}
                  />
                  {/* </GridLayoutItem>
                <GridLayoutItem> */}
                  {/* <Field
                    wrapperStyle={{ width: "100%" }}
                    name="end_date"
                    label="End Date"
                    format="dd/MM/yyyy"
                    component={FormDatePicker}
                    validator={requiredValidator}
                    astrike={true}
                  /> */}
                </GridLayoutItem>
                <GridLayoutItem style={{ display: "flex", gap: 10 }}>
                  <Field
                    wrapperClassName="right-alighned-field w-100"
                    name="lot_no"
                    label="Lot No"
                    placeholder="0"
                    component={FormTextField}
                    validator={requiredValidator}
                    astrike={true}
                  />
                  <Field
                    wrapperClassName="right-alighned-field w-100"
                    name="no_of_workers"
                    label="No Of Workers"
                    placeholder="0"
                    component={FormNumericTextField}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                {/* <GridLayoutItem>
                 
                </GridLayoutItem> */}
                <GridLayoutItem style={{ display: "flex", gap: 10 }}>
                  <Field
                    wrapperStyle={{ width: "100%" }}
                    name="start_time"
                    label="Start Time"
                    component={FormTimePicker}
                    validator={requiredValidator}
                    astrike={true}
                  />
                  {/* </GridLayoutItem>
                <GridLayoutItem> */}
                  <Field
                    wrapperStyle={{ width: "100%" }}
                    name="end_time"
                    label="End Time"
                    component={FormTimePicker}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="roasting_duration"
                    label="Roasting Duration"
                    placeholder="Roasting Duration"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="supervisor_by"
                    label="Supervisor By"
                    placeholder="Supervisor Name"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    astrike={true}
                    options={UserList?.map((user: IUser) => {
                      return {
                        value: user?.id,
                        label: `${user?.first_name} ${user?.last_name}`,
                      };
                    })}
                  />
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
            <ShadowCard style={{ padding: 12, marginTop: 10 }}>
              <GridLayout
                style={{ marginRight: 30 }}
                gap={{ rows: 10, cols: 10 }}
                cols={[
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                ]}
              >
                <GridLayoutItem colSpan={4}>
                  <Typography.h4>Item Details</Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem colSpan={4}>
                  <FieldArray
                    formRenderProps={formRenderProps}
                    component={ProductionItemDetailsArray2}
                    handleItemSearchChange={handleItemSearchChange}
                    name="ProductionItemDetails"
                  />
                </GridLayoutItem>
                {/* <GridLayoutItem rowSpan={2} colSpan={3}>
                  <Field
                    name="remarks"
                    label="Remarks"
                    placeholder="Remarks"
                    component={FormRichTextField}
                  />
                </GridLayoutItem> */}
                <GridLayoutItem
                  colSpan={4}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "end",
                  }}
                >
                  <div>
                    <ButtonWithLoading
                      label={Production_guid ? "Update" : "Save"}
                      type="submit"
                      disabled={!formRenderProps.allowSubmit || loading}
                      loading={loading}
                    />
                    <Button
                      type="button"
                      fillMode={"outline"}
                      themeColor={"primary"}
                      style={{ marginLeft: 4 }}
                      onClick={() => navigate("/production")}
                    >
                      Cancel
                    </Button>
                  </div>
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
            <InnerItemChangeWatcher formRenderProps={formRenderProps} />
            <QtychangeWatcher formRenderProps={formRenderProps} />
            <QtyPercentageWatcher formRenderProps={formRenderProps} />
            <UserChangeWatcher formRenderProps={formRenderProps} />
          </FormElement>
        )}
      />
    </>
  );
};

export default CreateProduction2;
