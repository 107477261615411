import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  createInward,
  deleteInward,
  getAllInwards,
  getAllLimitedInwards,
  getCurrentStockLotNo,
  getInwardByID,
  updateInward,
} from "./services/inward.services";
import { IInward, IInwardState } from "./inwardModel";
import moment from "moment";

const initialState: IInwardState = {
  loading: false,
  error: "",
  InwardStateForGST: null,
  InwardDeleteIndex: -1,
  InwardDutyAndTaxDeleteIndex: -1,
  InwardID: null,
  InwardList: [],
  InwardDetail: {
    po_no: null,
    inward_date: moment().toDate(),
    bill_no: "",
    vendor_id: null,
    vehicle_no: "",
    remarks: "",
    chalan_no: "",
    bill_date: moment().toDate(),
    vehicle_in_time: "",
    vehicle_out_time: "",
    vehicle_condition: "OK",
    region: "",
    financial_year: null,
    total_amount: null,
    inward_items: [],
  },
  LotNoList: [],
  FilterCustomInward: {
    account_name: "",
    vehicle_no: "",
    chalan_no: "",
  },
};

const inwardSlice = createSlice({
  name: "inward",
  initialState,
  reducers: {
    clearInwardDetails: (state) => {
      state.InwardDetail = initialState.InwardDetail;
    },
    setInwardID: (state, action) => {
      state.InwardID = action.payload;
    },
    clearInwardID: (state) => {
      state.InwardID = initialState.InwardID;
    },
    setInwardDeleteIndex: (state, action) => {
      state.InwardDeleteIndex = action.payload;
    },
    setFilterCustomInward: (state, action) => {
      state.FilterCustomInward = action.payload;
    },
    clearFilterCustomInward: (state) => {
      state.FilterCustomInward = initialState.FilterCustomInward;
    },
    setInwardDutyAndTaxDeleteIndex: (state, action) => {
      state.InwardDutyAndTaxDeleteIndex = action.payload;
    },
    setInwardStateForGST: (state, action) => {
      state.InwardStateForGST = action.payload;
    },
    clearInwardStateForGST: (state) => {
      state.InwardStateForGST = initialState.InwardStateForGST;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllInwards.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllInwards.fulfilled,
      (state, action: PayloadAction<IInward[]>) => {
        state.loading = false;
        state.InwardList = action.payload || [];
      }
    );
    builder.addCase(getAllInwards.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.InwardList = [];
    });
    builder.addCase(getAllLimitedInwards.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllLimitedInwards.fulfilled,
      (state, action: PayloadAction<IInward[]>) => {
        state.loading = false;
        state.InwardList = action.payload || [];
      }
    );
    builder.addCase(getAllLimitedInwards.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.InwardList = [];
    });
    builder.addCase(createInward.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(createInward.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createInward.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateInward.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateInward.fulfilled, (state) => {
      state.loading = true;
    });
    builder.addCase(updateInward.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteInward.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteInward.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteInward.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getInwardByID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getInwardByID.fulfilled, (state, action: any) => {
      state.loading = false;
      state.InwardDetail = action.payload;
    });
    builder.addCase(getInwardByID.rejected, (state, action) => {
      state.loading = false;
      state.InwardDetail = initialState.InwardDetail;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getCurrentStockLotNo.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getCurrentStockLotNo.fulfilled,
      (state, action: PayloadAction<any[]>) => {
        state.loading = false;
        state.LotNoList = action.payload || [];
      }
    );
    builder.addCase(getCurrentStockLotNo.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.LotNoList = [];
    });
  },
});

export const {
  clearInwardDetails,
  clearInwardID,
  setInwardID,
  setInwardDeleteIndex,
  setFilterCustomInward,
  clearFilterCustomInward,
  setInwardDutyAndTaxDeleteIndex,
  setInwardStateForGST,
  clearInwardStateForGST,
} = inwardSlice.actions;
export default inwardSlice.reducer;
