import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast } from "../../../components/toast/Toasts";
import moment from "moment";

export const getProductMillingReport = createAsyncThunk(
  "Reports/FindAllProductMillingReport",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Reports/FindAllProductMillingReport`,
        formData
      );
      const result = response.data?.Data;
      return result.map((milling: any, index: number) => {
        return {
          UniqueNo: index + 1,
          id: milling?.id,
          itemcount: milling?.itemcount,
          totalpages: milling?.totalpages,
          milling_date: milling?.milling_date,
          milling_no: milling?.milling_no,
          item_id: milling?.item_id,
          product_name: milling?.product_name,
          ready_product_name: milling?.ready_product_name,
          ready_product_id: milling?.ready_product_id,
          grn_no: milling?.grn_no,
          no_of_workers: milling?.no_of_workers,
          machine_id: milling?.machine_id,
          machine_name: milling?.machine_name,
          start_time: milling?.start_time,
          end_time: milling?.end_time,
          total_qty: milling?.total_qty,
          received_qty: milling?.received_qty,
          production_loss_qty: milling?.production_loss_qty,
          loss_percentage: milling?.loss_percentage,
          lot_no: milling?.lot_no,
          lot_no_string: milling?.lot_no_string,
          katta: milling?.katta,
          checker_by: milling?.checker_by,
          checker_name: milling?.checker_name,
          department_id: milling?.department_id,
          department_name: milling?.department_name,
          process_id: milling?.process_id,
          process_name: milling?.process_name,
        };
      });
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching Product milling report:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllAdultWorkersReport = createAsyncThunk(
  "Reports/FindAllAdultWorkers",
  async (formdata: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Reports/FindAllAdultWorkers`,
        formdata
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Employee:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getProductionReport = createAsyncThunk(
  "Reports/FindAllProductionReport",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Reports/FindAllProductionReport`,
        formData
      );
      const result = response.data?.Data;
      return result.map((production: any, index: number) => {
        return {
          UniqueNo: index + 1,
          id: production?.id,
          itemcount: production?.itemcount,
          totalpages: production?.totalpages,
          Production_guid: production?.Production_guid,
          start_date: production?.start_date,
          end_date: production?.end_date,
          lot_no: production?.lot_no,
          item_id: production?.item_id,
          product_name: production?.product_name,
          no_of_workers: production?.no_of_workers,
          roasting_duration: production?.roasting_duration,
          old_material_lot_no: production?.old_material_lot_no,
          total_quantity: production?.total_quantity,
          quantity: production?.quantity,
          total_qty: production?.total_qty,
          received_qty: production?.received_qty,
          loss_qty: production?.loss_qty,
          loss_percentage: production?.loss_percentage,
          supervisor_by: production?.supervisor_by,
          supervisor_name: production?.supervisor_name,
          remarks: production?.remarks,
        };
      });
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching Production report:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getGhantiCleaningReport = createAsyncThunk(
  "Reports/FindAllMachineCleaningReport",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Reports/FindAllMachineCleaningReport`,
        formData
      );
      const result = response.data?.Data;
      return result.map((ghanti: any, index: number) => {
        return {
          UniqueNo: index + 1,
          id: ghanti?.id,
          itemcount: ghanti?.itemcount,
          totalpages: ghanti?.totalpages,
          cleaning_no: ghanti?.cleaning_no,
          machine_no: ghanti?.machine_no,
          machine_name: ghanti?.machine_name,
          product_name: ghanti?.product_name,
          grinding_lot_no: ghanti?.grinding_lot_no,
          production_qty: ghanti?.production_qty,
          maintenance_time: ghanti?.maintenance_time,
          machine_cleaning_date: ghanti?.machine_cleaning_date,
          qc_by: ghanti?.qc_by,
          qc_name: ghanti?.qc_name,
          production_by: ghanti?.production_by,
          production_name: ghanti?.production_name,
          remarks: ghanti?.remarks,
        };
      });
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching Ghanti cleaning report:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getMetalCleaningReport = createAsyncThunk(
  "Reports/FindAllMagnetCleaningReport",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Reports/FindAllMagnetCleaningReport`,
        formData
      );
      const result = response.data?.Data;
      return result.map((metal: any, index: number) => {
        return {
          UniqueNo: index + 1,
          id: metal?.id,
          itemcount: metal?.itemcount,
          totalpages: metal?.totalpages,
          cleaning_no: metal?.cleaning_no,
          machine_no: metal?.machine_no,
          machine_name: metal?.machine_name,
          product_name: metal?.product_name,
          metal_qty: metal?.metal_qty,
          m_c_cleaning: metal?.m_c_cleaning,
          sieve_condition: metal?.sieve_condition,
          magnet_cleaning_date: metal?.magnet_cleaning_date,
          supervisor_by: metal?.supervisor_by,
          supervisor_name: metal?.supervisor_name,
          verify_by: metal?.verify_by,
          verify_name: metal?.verify_name,
        };
      });
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching Metal cleaning report:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getProductCleaningReport = createAsyncThunk(
  "Reports/FindAllProductCleaningReport",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Reports/FindAllProductCleaningReport`,
        formData
      );
      const result = response.data?.Data;
      return result.map((cleaning: any, index: number) => {
        return {
          UniqueNo: index + 1,
          id: cleaning?.id,
          itemcount: cleaning?.itemcount,
          totalpages: cleaning?.totalpages,
          cleaning_date: cleaning?.cleaning_date,
          cleaning_no: cleaning?.cleaning_no,
          item_id: cleaning?.item_id,
          product_name: cleaning?.product_name,
          grn_no: cleaning?.grn_no,
          no_of_workers: cleaning?.no_of_workers,
          machine_id: cleaning?.machine_id,
          machine_name: cleaning?.machine_name,
          start_time: cleaning?.start_time,
          end_time: cleaning?.end_time,
          total_qty: cleaning?.total_qty,
          received_qty: cleaning?.received_qty,
          rejection_qty: cleaning?.rejection_qty,
          loss_percentage: cleaning?.loss_percentage,
          cleaning_checker_by: cleaning?.cleaning_checker_by,
          cleaning_checker_name: cleaning?.cleaning_checker_name,
          department_id: cleaning?.department_id,
          department_name: cleaning?.department_name,
          process_id: cleaning?.process_id,
          process_name: cleaning?.process_name,
        };
      });
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching Product cleaning report:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getRoastingReport = createAsyncThunk(
  "Reports/FindAllRoastingReport",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Reports/FindAllRoastingReport`,
        formData
      );
      const result = response.data?.Data;
      return result.map((roasting: any, index: number) => {
        return {
          UniqueNo: index + 1,
          id: roasting?.id,
          itemcount: roasting?.itemcount,
          totalpages: roasting?.totalpages,
          roasting_guid: roasting?.roasting_guid,
          roasting_date: roasting?.roasting_date,
          // lot_no: roasting?.lot_no,
          item_id: roasting?.item_id,
          product_name: roasting?.product_name,
          ready_product_name: roasting?.ready_product_name,
          grn_no: roasting?.grn_no,
          no_of_workers: roasting?.no_of_workers,
          machine_id: roasting?.machine_id,
          machine_name: roasting?.machine_name,
          start_time: roasting?.start_time,
          end_time: roasting?.end_time,
          total_qty: roasting?.total_qty,
          // roasting_duration: roasting?.roasting_duration,
          // old_material_lot_no: roasting?.old_material_lot_no,
          received_qty: roasting?.received_qty,
          process_loss_qty: roasting?.process_loss_qty,
          loss_percentage: roasting?.loss_percentage,
          department_name: roasting?.department_name,
          process_name: roasting?.process_name,
          checker_name: roasting?.checker_name,
          // supervisor_name: roasting?.supervisor_name,
          remarks: roasting?.remarks,
        };
      });
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching Roasting report:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
export const getInwardPackingMaterialStockReport = createAsyncThunk(
  "Reports/FindAllInwardPackingMaterialReport",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Reports/FindAllInwardPackingMaterialReport`,
        formData
      );
      const result = response.data?.Data;
      return result.map((results: any, index: number) => {
        return {
          UniqueNo: index + 1,
          id: results?.id,
          itemcount: results?.itemcount,
          totalpages: results?.totalpages,
          inward_packing_no: results?.inward_packing_no,
          inward_packing_material_date: results?.inward_packing_material_date,
          item_id: results?.item_id,
          product_name: results?.product_name,
          inward_item_qty: results?.inward_item_qty,
          unit_id: results?.unit_id,
          unit_name: results?.unit_name,
          roll: results?.roll,
        };
      });
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error(
        "Error fetching inward packing material stock report:",
        error
      );
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getOutwardPackingMaterialStockReport = createAsyncThunk(
  "Reports/FindAllOutwardPackingMaterialReport",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Reports/FindAllOutwardPackingMaterialReport`,
        formData
      );
      const result = response.data?.Data;
      return result.map((results: any, index: number) => {
        return {
          UniqueNo: index + 1,
          id: results?.id,
          itemcount: results?.itemcount,
          totalpages: results?.totalpages,
          outward_packing_no: results?.outward_packing_no,
          outward_packing_material_date: results?.outward_packing_material_date,
          item_id: results?.item_id,
          product_name: results?.product_name,
          outward_item_qty: results?.outward_item_qty,
          unit_id: results?.unit_id,
          unit_name: results?.unit_name,
          roll: results?.roll,
          outward_type: results?.outward_type,
        };
      });
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error(
        "Error fetching outward packing material stock report:",
        error
      );
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getInwardRegisterReport = createAsyncThunk(
  "Inward/FindByRegisterPrintInward",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Inward/FindByRegisterPrintInward`,
        formData
      );
      const result = response.data?.Data;
      return result.map((results: any, index: number) => {
        return {
          UniqueNo: index + 1,
          id: results?.id,
          itemcount: results?.itemcount,
          inward_guid: results?.inward_guid,
          inward_no: results?.inward_no,
          po_no: results?.po_no,
          inward_date: results?.inward_date,
          bill_no: results?.bill_no,
          vendor_id: results?.vendor_id,
          account_name: results?.account_name,
          vehicle_no: results?.vehicle_no,
          remarks: results?.remarks,
          chalan_no: results?.chalan_no,
          bill_date: results?.bill_date,
          vehicle_in_time: results?.vehicle_in_time,
          vehicle_out_time: results?.vehicle_out_time,
          financial_year: results?.financial_year,
          grn_no: results?.grn_no,
          grn_no_string: results?.grn_no_string,
          inward_id: results?.inward_id,
          item_id: results?.item_id,
          item_name: results?.item_name,
          dagina: results?.dagina,
          weight: results?.weight,
          rate: results?.rate,
          total: results?.total,
          cold_storage_id: results?.cold_storage_id,
          warehouse_name: results?.warehouse_name,
          receipt_no: results?.receipt_no,
          unit_id: results?.unit_id,
          unit_code: results?.unit_code,
          unit_name: results?.unit_name,
          po_date: results?.po_date,
          vehicle_condition: results?.vehicle_condition,
        };
      });
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching inward register:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getItemStockReport = createAsyncThunk(
  "Inward/FindAllItemStock",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Inward/FindAllItemStock`,
        formData
      );
      const result = response.data?.Data;
      return result.map((results: any, index: number) => {
        return {
          UniqueNo: index + 1,
          item_id: results?.item_id,
          product_name: results?.product_name,
          grn_no: results?.grn_no,
          process_id: results?.process_id,
          process_name: results?.process_name,
          unit_id: results?.unit_id,
          unit_name: results?.unit_name,
          warehouse_id: results?.warehouse_id,
          warehouse_name: results?.warehouse_name,
          actual_stock: results?.actual_stock,
        };
      });
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching item stock:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getPackingRegisterReport = createAsyncThunk(
  "Reports/FindAllPackingReport",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Reports/FindAllPackingReport`,
        formData
      );
      const result = response.data?.Data;
      return result.map((packing: any, index: number) => {
        return {
          UniqueNo: index + 1,
          id: packing?.id,
          itemcount: packing?.itemcount,
          totalpages: packing?.totalpages,
          packing_guid: packing?.packing_guid,
          packing_no: packing?.packing_no,
          packing_date: packing?.packing_date,
          raw_item: packing?.raw_item,
          raw_item_name: packing?.raw_item_name,
          lot_no: packing?.lot_no,
          packing_item: packing?.packing_item,
          packing_item_name: packing?.packing_item_name,
          batch_no: packing?.batch_no,
          taken_qty: packing?.taken_qty,
          used_qty: packing?.used_qty,
          wastage_qty: packing?.wastage_qty,
          loss_percentage: packing?.loss_percentage,
          ready_qty_in_nos: packing?.ready_qty_in_nos,
          mfg_date: packing?.mfg_date,
          exp_date: packing?.exp_date,
          remarks: packing?.remarks,
          verify_by: packing?.verify_by,
          financial_year: packing?.financial_year,
        };
      });
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching packing register:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getPackingMaterialStockReport = createAsyncThunk(
  "Reports/FindAllPackingMaterialstockReport",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Reports/FindAllPackingMaterialstockReport`,
        formData
      );
      const result = response.data?.Data;
      return result?.map((packing: any, index: number) => {
        return {
          UniqueNo: index + 1,
          item_id: packing?.item_id,
          product_name: packing?.product_name,
          item_group_id: packing?.item_group_id,
          item_group_name: packing?.item_group_name,
          unit_id: packing?.unit_id,
          unit_name: packing?.unit_name,
          actual_qty: packing?.actual_qty,
        };
      });
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching packing material stock report:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getVisitorReport = createAsyncThunk(
  "Reports/FindAllVisitorReport",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Reports/FindAllVisitorReport`,
        formData
      );
      const result = response.data?.Data;
      return result?.map((visitor: any, index: number) => {
        return {
          UniqueNo: index + 1,
          id: visitor?.id,
          visitor_guid: visitor?.visitor_guid,
          visitor_date: visitor?.visitor_date,
          visitor_no: visitor?.visitor_no,
          user_id: visitor?.user_id,
          user_name: visitor?.user_name,
          name: visitor?.name,
          mobile_no: visitor?.mobile_no,
          email_id: visitor?.email_id,
          id_proof_no: visitor?.id_proof_no,
          from_company: visitor?.from_company,
          reason: visitor?.reason,
          in_time: visitor?.in_time,
          out_time: visitor?.out_time,
          financial_year: visitor?.financial_year,
          createdby: visitor?.createdby,
          createdby_name: visitor?.createdby_name,
          isactive: visitor?.isactive,
        };
      });
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching visitor report:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getOfficeStaffOutReport = createAsyncThunk(
  "Reports/FindAllStaffOutReport",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Reports/FindAllStaffOutReport`,
        formData
      );
      const result = response.data?.Data;
      return result?.map((staffout: any, index: number) => {
        return {
          UniqueNo: index + 1,
          id: staffout?.id,
          itemcount: staffout?.itemcount,
          totalpages: staffout?.totalpages,
          staff_out_guid: staffout?.staff_out_guid,
          staff_out_date: staffout?.staff_out_date,
          user_id: staffout?.user_id,
          user_name: staffout?.user_name,
          staff_out_no: staffout?.staff_out_no,
          reason_for_out: staffout?.reason_for_out,
          approved_by: staffout?.approved_by,
          out_time: staffout?.out_time,
          in_time: staffout?.in_time,
          financial_year: staffout?.financial_year,
          createdby: staffout?.createdby,
          createdby_name: staffout?.createdby_name,
        };
      });
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching office staff out report:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getPurchaseDueDateInvoiceReport = createAsyncThunk(
  "Reports/FindAllPurchaseDueDateReport",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Reports/FindAllPurchaseDueDateReport`,
        formData
      );
      const result = response.data?.Data;
      return result?.map((purchase: any, index: number) => {
        return {
          UniqueNo: index + 1,
          id: purchase?.id,
          purchase_guid: purchase?.purchase_guid,
          purchase_order_id: purchase?.purchase_order_id,
          inward_no: purchase?.inward_no,
          invoice_no: purchase?.invoice_no,
          invoice_date: purchase?.invoice_date,
          vendor_id: purchase?.vendor_id,
          account_name: purchase?.account_name,
          due_date: purchase?.due_date,
          mobile_no: purchase?.mobile_no,
          email_id: purchase?.email_id,
          place_supply_id: purchase?.place_supply_id,
          place_of_supply_name: purchase?.place_of_supply_name,
          terms_days: purchase?.terms_days,
          po_no: purchase?.po_no,
          packing: purchase?.packing,
          labour: purchase?.labour,
          dalali: purchase?.dalali,
          total_amount: purchase?.total_amount,
          total_gst: purchase?.total_gst,
          gst: purchase?.gst,
          cgst: purchase?.cgst,
          sgst: purchase?.sgst,
          igst: purchase?.igst,
          financial_year: purchase?.financial_year,
        };
      });
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching purchase due date invoice report:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getDistributorCurrentStockReportByID = createAsyncThunk(
  "SaleOrder/DistributerCurrentStock",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SaleOrder/DistributerCurrentStock`,
        formData
      );
      const result = response.data?.Data as any;
      return {
        id: result?.id,
        accountid: result?.accountid,
        account_name: result?.account_name,
        date: result?.date ? moment(result?.date, "YYYY-MM-DD").toDate() : "",
        distributer_stock: result?.distributer_stock
          ? result?.distributer_stock?.map((stock: any) => {
              return {
                item_group_id: stock?.item_group_id
                  ? stock?.item_group_id
                  : null,
                item_group: stock?.item_group ? stock?.item_group : "",
                distributer_stock_list: stock?.distributer_stock_list
                  ? stock?.distributer_stock_list?.map((curr_stock: any) => {
                      return {
                        id: curr_stock?.id ? +curr_stock?.id : null,
                        item_id: curr_stock?.item_id
                          ? +curr_stock?.item_id
                          : null,
                        item_group_id: curr_stock?.item_group_id
                          ? +curr_stock?.item_group_id
                          : null,
                        product_name: curr_stock?.product_name
                          ? curr_stock?.product_name
                          : "",
                        mrp: curr_stock?.mrp ? +curr_stock?.mrp : null,
                        net_quantity: curr_stock?.net_quantity
                          ? +curr_stock?.net_quantity
                          : null,
                        unit_id: curr_stock?.unit_id
                          ? +curr_stock?.unit_id
                          : null,
                        unit_name: curr_stock?.unit_name
                          ? curr_stock?.unit_name
                          : "",
                        rate: curr_stock?.rate ? +curr_stock?.rate : null,
                        amount: curr_stock?.amount ? +curr_stock?.amount : null,
                      };
                    })
                  : [],
              };
            })
          : [],
      } as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching current stock:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getRetailerRateReport = createAsyncThunk(
  "Reports/FindAllRetailerRateReport",
  async (ID: string) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Reports/FindAllRetailerRateReport`,
        {
          item_group_id: ID,
        }
      );
      const result = response.data?.Data?.retailer_report as any;
      return result
        ? result.map((itemgroup: any) => {
            return {
              from_date: response.data?.Data?.from_date,
              item_group_id: itemgroup?.item_group_id,
              item_group: itemgroup?.item_group,
              item_margin_rate: itemgroup?.item_margin_rate
                ? itemgroup?.item_margin_rate?.map((stock: any) => {
                    return {
                      item_group_id: stock?.item_group_id
                        ? +stock?.item_group_id
                        : null,
                      product_name: stock?.product_name
                        ? stock?.product_name
                        : "",
                      unit_id: stock?.unit_id ? +stock?.unit_id : null,
                      unit_name: stock?.unit_name ? stock?.unit_name : "",
                      mrp: stock?.mrp ? +stock?.mrp : null,
                      gst_id: stock?.gst_id ? +stock?.gst_id : null,
                      item_id: stock?.item_id ? +stock?.item_id : null,
                      gst: stock?.gst ? +stock?.gst : null,
                      distributor_rate: stock?.distributor_rate
                        ? +stock?.distributor_rate
                        : null,
                      retailer_rate: stock?.retailer_rate
                        ? +stock?.retailer_rate
                        : null,
                      retail_rate_include_gst: stock?.retail_rate_include_gst
                        ? +stock?.retail_rate_include_gst
                        : null,
                      retail_sgst: stock?.retail_sgst
                        ? +stock?.retail_sgst
                        : null,
                      retail_cgst: stock?.retail_cgst
                        ? +stock?.retail_cgst
                        : null,
                      distributor_rate_include_gst:
                        stock?.distributor_rate_include_gst
                          ? +stock?.distributor_rate_include_gst
                          : null,
                      distributor_sgst: stock?.distributor_sgst
                        ? +stock?.distributor_sgst
                        : null,
                      distributor_cgst: stock?.distributor_cgst
                        ? +stock?.distributor_cgst
                        : null,
                    };
                  })
                : [],
            };
          })
        : [];
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching retailer rate report:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getPORegisterReport = createAsyncThunk(
  "Reports/FindAllPORegisterReport",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Reports/FindAllPORegisterReport`,
        formData
      );
      const result = response.data?.Data as any;
      return result
        ? result.map((po: any) => {
            return {
              purchase_order_guid: po?.purchase_order_guid,
              itemcount: po?.itemcount,
              totalpages: po?.totalpages,
              po_no: po?.po_no,
              po_no_string: po?.po_no_string,
              po_date: po?.po_date,
              vendor_id: po?.vendor_id,
              account_name: po?.account_name,
              mobile_no: po?.mobile_no,
              email_id: po?.email_id,
              item_id: po?.item_id,
              product_name: po?.product_name,
              item_category: po?.item_category,
              qty: po?.qty,
              rate: po?.rate,
              unit_name: po?.unit_name,
              unit_code: po?.unit_code,
              discount: po?.discount,
              discount_type: po?.discount_type,
              quotation_no: po?.quotation_no,
              quotation_ref: po?.quotation_ref,
              delivery_date: po?.delivery_date,
              freight: po?.freight,
              p_and_f_charge: po?.p_and_f_charge,
              payment_terms: po?.payment_terms,
              mode_of_transport: po?.mode_of_transport,
              gst: po?.gst,
              gst_amount: po?.gst_amount,
              total_amount: po?.total_amount,
              sgst: po?.sgst,
              cgst: po?.cgst,
              igst: po?.igst,
              sgst_amount: po?.sgst_amount,
              cgst_amount: po?.cgst_amount,
              igst_amount: po?.igst_amount,
              total_gst: po?.total_gst,
              total_cgst: po?.total_cgst,
              total_sgst: po?.total_sgst,
              total_igst: po?.total_igst,
              taxable_amount: po?.taxable_amount,
              total_taxable_amount: po?.total_taxable_amount,
              ground_total: po?.ground_total,
            };
          })
        : [];
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching purchase order report:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getPurchaseRegisterReport = createAsyncThunk(
  "Reports/FindAllPurchaseReport",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Reports/FindAllPurchaseReport`,
        formData
      );
      const result = response.data?.Data as any;
      return result
        ? result.map((purchase: any) => {
            return {
              purchase_guid: purchase?.purchase_guid,
              purchase_no: purchase?.purchase_no,
              purchase_no_string: purchase?.purchase_no_string,
              invoice_no: purchase?.invoice_no,
              invoice_date: purchase?.invoice_date,
              inward_no: purchase?.inward_no,
              inward_date: purchase?.inward_date,
              po_no: purchase?.po_no,
              po_date: purchase?.po_date,
              vendor_id: purchase?.vendor_id,
              account_name: purchase?.account_name,
              pan_no: purchase?.pan_no,
              gst_no: purchase?.gst_no,
              item_id: purchase?.item_id,
              product_name: purchase?.product_name,
              quantity: purchase?.quantity,
              free_quantity: purchase?.free_quantity,
              rate: purchase?.rate,
              unit_id: purchase?.unit_id,
              terms_days: purchase?.terms_days,
              unit_name: purchase?.unit_name,
              unit_code: purchase?.unit_code,
              discount: purchase?.discount,
              discount_type: purchase?.discount_type,
              taxable_amount: purchase?.taxable_amount,
              gst: purchase?.gst,
              sgst: purchase?.sgst,
              cgst: purchase?.cgst,
              igst: purchase?.igst,
              sgst_amount: purchase?.sgst_amount,
              cgst_amount: purchase?.cgst_amount,
              igst_amount: purchase?.igst_amount,
              total_amount: purchase?.total_amount,
              total_gst_with_amount: purchase?.total_gst_with_amount,
              total_taxable_amount: purchase?.total_taxable_amount,
              total_gst: purchase?.total_gst,
              total_cgst: purchase?.total_cgst,
              total_sgst: purchase?.total_sgst,
              total_igst: purchase?.total_igst,
              due_date: purchase?.due_date,
              packing: purchase?.packing,
              labour: purchase?.labour,
              dalali: purchase?.dalali,
            };
          })
        : [];
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching purchase report:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getSalesRegisterReport = createAsyncThunk(
  "Reports/FindAllSaleRegisterReport",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Reports/FindAllSaleRegisterReport`,
        formData
      );
      const result = response.data?.Data as any;
      return result
        ? result.map((sales: any) => {
            return {
              sale_guid: sales?.sale_guid,
              invoice_no: sales?.invoice_no,
              invoice_no_string: sales?.invoice_no_string,
              invoice_date: sales?.invoice_date,
              vendor_id: sales?.vendor_id,
              account_name: sales?.account_name,
              item_id: sales?.item_id,
              product_name: sales?.product_name,
              lot_no: sales?.lot_no,
              quantity: sales?.quantity,
              mrp: sales?.mrp,
              vatav_per: sales?.vatav_per,
              vatav: sales?.vatav,
              free_quantity: sales?.free_quantity,
              rate: sales?.rate,
              unit_id: sales?.unit_id,
              unit_name: sales?.unit_name,
              unit_code: sales?.unit_code,
              item_group_id: sales?.item_group_id,
              item_group: sales?.item_group,
              terms_day: sales?.terms_day,
              taxable_amount: sales?.taxable_amount,
              frieght: sales?.frieght,
              gross_total: sales?.gross_total,
              gst: sales?.gst,
              sgst: sales?.sgst,
              cgst: sales?.cgst,
              igst: sales?.igst,
              sgst_amount: sales?.sgst_amount,
              cgst_amount: sales?.cgst_amount,
              igst_amount: sales?.igst_amount,
              total_amount: sales?.total_amount,
              due_date: sales?.due_date,
              vehicle_no: sales?.vehicle_no,
              cartoon: sales?.cartoon,
              bag: sales?.bag,
              total_taxable_amount: sales?.total_taxable_amount,
              total_gst: sales?.total_gst,
              total_cgst: sales?.total_cgst,
              total_sgst: sales?.total_sgst,
              total_igst: sales?.total_igst,
            };
          })
        : [];
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching sales report:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getSalaryRegisterReport = createAsyncThunk(
  "User/FindAllSalarySlip",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/FindAllSalarySlip`,
        formData
      );
      const result = response.data?.Data as any;
      return result
        ? result.map((salary: any) => {
            return {
              employee_id: salary.employee_id,
              employee_code_string: salary.employee_code_string,
              employee_name: salary.employee_name,
              designation_id: salary.designation_id,
              location: salary.location,
              designation_name: salary.designation_name,
              department_id: salary.department_id,
              department_name: salary.department_name,
              pf_no: salary.pf_no,
              uan_no: salary.uan_no,
              est_no: salary.est_no,
              ifsc_code: salary.ifsc_code,
              bank_name: salary.bank_name,
              account_no: salary.account_no,
              date_of_joining: salary.date_of_joining,
              working_days: salary.working_days,
              weekly_off: salary.weekly_off,
              ph: salary.ph,
              // cl: salary.cl,
              pl: salary.pl,
              sl: salary.sl,
              coff: salary.coff,
              present_days: salary.present_days,
              lwp: salary.lwp,
              actual_basic: salary.actual_basic,
              actual_da: salary.actual_da,
              actual_hra: salary.actual_hra,
              actual_wash: salary.actual_wash,
              actual_other: salary.actual_other,
              actual_adv_bonus: salary.actual_adv_bonus,
              actual_adv_leave: salary.actual_adv_leave,
              actual_food: salary.actual_food,
              actual_adv_std_be: salary.actual_adv_std_be,
              actual_prod_ins_all: salary.actual_prod_ins_all,
              actual_tds: salary.actual_tds,
              actual_other_earning: salary.actual_other_earning,
              basic: salary.basic,
              celling_amount: salary.celling_amount,
              da: salary.da,
              hra: salary.hra,
              wash: salary.wash,
              adv_bonus: salary.adv_bonus,
              adv_leave: salary.adv_leave,
              food: salary.food,
              pf: salary.pf,
              esi: salary.esi,
              pt: salary.pt,
              it: salary.it,
              lwf: salary.lwf,
              advance: salary.advance,
              prod_ins_all: salary.prod_ins_all,
              adv_std_be: salary.adv_std_be,
              other: salary.other,
              loan: salary.loan,
              tds: salary.tds,
              other_earning: salary.other_earning,
              total_income: salary.total_income,
              total_deduction: salary.total_deduction,
              payable_amount: salary.payable_amount,
              casual_leave: salary.casual_leave,
              payout_month: salary.payout_month,
              payout_year: salary.payout_year,
            };
          })
        : [];
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching salary Register Report:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getEmployeeDetailsReport = createAsyncThunk(
  "Reports/FindAllEmployeeReport",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Reports/FindAllEmployeeReport`,
        formData
      );
      const result = response.data?.Data as any;
      return result
        ? result.map((emp: any) => {
            return {
              id: emp?.id,
              user_guid: emp?.user_guid,

              employee_code_string: emp?.employee_code_string,
              first_name: emp?.first_name,
              middle_name: emp?.middle_name,
              last_name: emp?.last_name,
              gender: emp?.gender,
              mobile_no: emp?.mobile_no,
              email: emp?.email,
              department_id: emp?.department_id,
              department_name: emp?.department_name,
              designation_id: emp?.designation_id,
              designation_name: emp?.designation_name,
              official_email: emp?.official_email,
              date_of_joining: emp?.date_of_joining,
              left_date: emp?.left_date,
              work_shift_time: emp?.work_shift_time,
              weekly_off: emp?.weekly_off,
              location: emp?.location,
              reporting_person: emp?.reporting_person,
              reporting_person_name: emp?.reporting_person_name,
              user_image: emp?.user_image,
              reason_of_exit: emp?.reason_of_exit,

              dob: emp?.dob,
              blood_group: emp?.blood_group,
              personal_email: emp?.personal_email,
              marital_status: emp?.marital_status,
              date_of_marrige: emp?.date_of_marrige,
              spouse_name: emp?.spouse_name,
              nationality: emp?.nationality,

              address: emp?.address,
              street: emp?.street,
              area: emp?.area,
              city: emp?.city,
              pincode: emp?.pincode,
              state_id: emp?.state_id,
              state_name: emp?.state_name,
              is_permanent_address_same: emp?.is_permanent_address_same,
              permenent_address: emp?.permenent_address,
              permenent_street: emp?.permenent_street,
              permenent_area: emp?.permenent_area,
              permenent_city: emp?.permenent_city,
              permenent_pincode: emp?.permenent_pincode,
              permenent_state_id: emp?.permenent_state_id,
              permenent_state_name: emp?.permenent_state_name,

              emergency_name: emp?.emergency_name,
              emergency_mobile: emp?.emergency_mobile,
              emergency_relationship_name: emp?.emergency_relationship_name,
              emergency_relationship_id: emp?.emergency_relationship_id,

              bank_name: emp?.bank_name,
              account_no: emp?.account_no,
              ifsc_code: emp?.ifsc_code,
              bank_branch: emp?.bank_branch,

              pf_deduction: emp?.pf_deduction,
              pf_no: emp?.pf_no,
              pf_join_date: emp?.pf_join_date,
              uan_no: emp?.uan_no,

              est_no: emp?.est_no,
              esi_join_date: emp?.esi_join_date,

              pancard: emp?.pancard,
              aadharcard: emp?.aadharcard,
              passport_no: emp?.passport_no,

              apply_from_date: emp?.apply_from_date,
              basic: emp?.basic,
              celling_amount: emp?.celling_amount,
              hra: emp?.hra,
              wash: emp?.wash,
              prod_ins_all: emp?.prod_ins_all,
              adv_bonus: emp?.adv_bonus,
              adv_leave: emp?.adv_leave,
              adv_std_be: emp?.adv_std_be,
              food: emp?.food,
              da: emp?.da,
              other_earning: emp?.other_earning,
              pf_per: emp?.pf_per,
              pf: emp?.pf,
              esi_per: emp?.esi_per,
              esi: emp?.esi,
              it: emp?.it,
              pt: emp?.pt,
              loan: emp?.loan,
              advance: emp?.advance,
              lwf: emp?.lwf,
              tds: emp?.tds,
              other: emp?.other,
              total_income: emp?.total_income,
              total_deduction: emp?.total_deduction,
              payable_amount: emp?.payable_amount,
            };
          })
        : [];
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching Employee Details Report:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getEmployeeRegisterReport = createAsyncThunk(
  "Reports/FindAllEmployeeRegisterReport",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Reports/FindAllEmployeeRegisterReport`,
        formData
      );
      const result = response.data?.Data as any;
      return result
        ? result.map((emp: any) => {
            return {
              id: emp?.id,
              first_name: emp?.first_name,
              middle_name: emp?.middle_name,
              last_name: emp?.last_name,
              employee_code: emp?.employee_code,
              employee_code_string: emp?.employee_code_string,
              gender: emp?.gender,
              dob: emp?.dob,
              nationality: emp?.nationality,
              date_of_joining: emp?.date_of_joining,
              designation_id: emp?.designation_id,
              designation_name: emp?.designation_name,
              mobile_no: emp?.mobile_no,
              uan_no: emp?.uan_no,
              pan_card: emp?.pan_card,
              adharcard: emp?.adharcard,
              left_date: emp?.left_date,
              reason_of_exit: emp?.reason_of_exit,
              user_image: emp?.user_image,

              account_no: emp?.account_no,
              bank_name: emp?.bank_name,
              bank_branch: emp?.bank_branch,
              ifsc_code: emp?.ifsc_code,
              address: emp?.address,
              street: emp?.street,
              area: emp?.area,
              city: emp?.city,
              pincode: emp?.pincode,
              state_id: emp?.state_id,
              state_name: emp?.state_name,
              permenent_address: emp?.permenent_address,
              permenent_street: emp?.permenent_street,
              permenent_area: emp?.permenent_area,
              permenent_city: emp?.permenent_city,
              permenent_pincode: emp?.permenent_pincode,
              permenent_state_id: emp?.permenent_state_id,
              permenent_state_name: emp?.permenent_state_name,
            };
          })
        : [];
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching Employee Details Report:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getLeaveWithWageReport = createAsyncThunk(
  "Reports/Employeeleavereport",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Reports/Employeeleavereport`,
        formData
      );
      return response.data?.Data as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching Employee Details Report:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getSchemeReport = createAsyncThunk(
  "Reports/FindAllSchemeReport",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Reports/FindAllSchemeReport`,
        formData
      );
      return response.data?.Data as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching scheme report:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAccountReport = createAsyncThunk(
  "Account/FindAllAccountReport",
  async (UnderGroupID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Account/FindAllAccountReport`,
        { uncder_group_id: UnderGroupID }
      );
      return response.data?.Data as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching account report:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getPackingStoreWiseStockReport = createAsyncThunk(
  "Reports/FindAllPackingStoreWisestockReport",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Reports/FindAllPackingStoreWisestockReport`,
        formData
      );
      return response.data?.Data as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching Packing Stock report:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getDebitNoteRegisterReport = createAsyncThunk(
  "Reports/FindAllDebitNoteReport",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Reports/FindAllDebitNoteReport`,
        formData
      );
      const result = response.data?.Data as any;
      return result;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching Debit Note report:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getCreditNoteRegisterReport = createAsyncThunk(
  "Reports/FindAllCreditNoteReport",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Reports/FindAllCreditNoteReport`,
        formData
      );
      const result = response.data?.Data as any;
      return result;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching Credit Note report:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
