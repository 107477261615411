import React, { useEffect, useRef } from "react";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import moment from "moment";
import { Typography } from "@progress/kendo-react-common";
import ShadowCard from "../../../components/common/ShadowCard";
import AlertBox from "../../../components/common/AlertBox";
import { LoadingPanel } from "../../../components/layout/Loading";
import { Button } from "@progress/kendo-react-buttons";
import { MONTHS_MAP } from "../../../_contstants/common";
import { getSalaryRegisterReport } from "../services/reports.services";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { BsThreeDotsVertical } from "react-icons/bs";
import { RxCross2 } from "react-icons/rx";
import FormCheckboxGroup from "../../../components/formFields/FormCheckboxGroup";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

const SalaryRegisterReportFormWithExcel: React.FC = () => {
  const state = JSON.parse(
    sessionStorage.getItem("salaryRegisterExcelState") || "{}"
  );
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);
  const loading = useAppSelector((state) => state.reports.loading);
  const SalaryRegisterList = useAppSelector(
    (state) => state.reports.SalaryRegisterList
  );
  const fetchReport = async () => {
    const payload = {
      location: state.location,
      payout_month_year: `${String(state.payout_month).padStart(2, "0")}-${
        state.payout_year
      }`,
    };
    await dispatch(getSalaryRegisterReport(payload));
  };

  useEffect(() => {
    const handleTabClose = () => {
      sessionStorage.removeItem("salaryRegisterExcelState");
    };
    fetchReport();
    return () => {
      window.removeEventListener("unload", handleTabClose);
    };
  }, []);

  return (
    <GridLayout gap={{ rows: 10 }} cols={[{ width: "100%" }]}>
      <GridLayoutItem>
        {loading ? (
          <LoadingPanel gridRef={gridRef} />
        ) : (
          SalaryRegisterList &&
          SalaryRegisterList.length > 0 && (
            <ShadowCard style={{ marginTop: 10 }}>
              <SalaryRegisterViewGridExportTable />
            </ShadowCard>
          )
        )}
      </GridLayoutItem>
    </GridLayout>
  );
};
const SalaryRegisterViewGridExportTable: React.FC = () => {
  const SalaryRegisterList = useAppSelector(
    (state) => state.reports.SalaryRegisterList
  );

  const SalaryRegisterDetails = JSON.parse(
    sessionStorage.getItem("salaryRegisterExcelState") || "{}"
  );

  const handleExportExcel = () => {
    const table = document.querySelector(
      ".salary-register-report-excel-table"
    ) as HTMLTableElement;
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.table_to_sheet(table);

    // Adjust column widths dynamically
    const colWidths: number[] = [];
    const rows = table.querySelectorAll("tr");

    rows.forEach((row) => {
      const cells = row.querySelectorAll("th, td");
      cells.forEach((cell, colIndex) => {
        const cellContent = cell.textContent || "";
        const cellLength = cellContent.length;

        // Calculate width (max of existing or current cell)
        colWidths[colIndex] = Math.max(
          colWidths[colIndex] || 10,
          cellLength + 2
        );
      });
    });
    // Apply column widths
    ws["!cols"] = colWidths.map((width) => ({ wch: width }));

    Object.keys(ws).forEach((key) => {
      if (!key.startsWith("!")) {
        const cell = ws[key];
        if (cell.z !== "m/d/yy") {
          if (typeof cell.v === "number") {
            // Check if the cell is a date (custom logic depending on your use case)
            const isDate = cell.t === "d"; // 't' might indicate the cell type in some libraries
            if (!isDate) {
              cell.t = "s"; // Set cell type to 'string'
              cell.v = `${cell.v}`; // Convert number to string
            }
          }
        } else if (cell.z === "m/d/yy") {
          cell.z = "DD-MMM-YYYY"
        }
      }
    });

    XLSX.utils.book_append_sheet(
      wb,
      ws,
      `${MONTHS_MAP[SalaryRegisterDetails?.payout_month ?? 0]}`
    );

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(
      data,
      `Salary Register Report-${SalaryRegisterDetails?.payout_year}`
    );
  };

  const [showfilterColumns, setShowFilterColumns] = React.useState(false);

  const initialValues = {
    filter_columns: [
      "employee_code_string",
      "employee_name",
      "designation_id",
      "location",
      "designation_name",
      "department_id",
      "department_name",
      "pf_no",
      "uan_no",
      "est_no",
      "bank_name",
      "account_no",
      "date_of_joining",
      "working_days",
      "weekly_off",
      "ph",
      "casual_leave",
      "pl",
      "sl",
      "coff",
      "present_days",
      "lwp",
      "actual_basic",
      "actual_da",
      "actual_hra",
      "actual_wash",
      "actual_other",
      "actual_adv_bonus",
      "actual_adv_leave",
      "actual_food",
      "actual_adv_std_be",
      "actual_prod_ins_all",
      "actual_tds",
      "actual_other_earning",
      "basic",
      "da",
      "hra",
      "wash",
      "adv_bonus",
      "adv_leave",
      "food",
      "pf",
      "esi",
      "pt",
      "it",
      "lwf",
      "advance",
      "prod_ins_all",
      "adv_std_be",
      "other",
      "loan",
      "tds",
      "other_earning",
      "total_income",
      "total_deduction",
      "payable_amount",
    ],
  };

  const columns = [
    { label: "Employee Name", value: "employee_name" },
    { label: "Employee Code", value: "employee_code_string" },
    { label: "Location", value: "location" },
    { label: "Designation Name", value: "designation_name" },
    { label: "Department Name", value: "department_name" },
    { label: "PF No", value: "pf_no" },
    { label: "UAN No", value: "uan_no" },
    { label: "ESI No", value: "est_no" },
    { label: "Bank Name", value: "bank_name" },
    { label: "Account No", value: "account_no" },
    { label: "Date Of Joining", value: "date_of_joining" },
    { label: "WD", value: "working_days" },
    { label: "WO", value: "weekly_off" },
    { label: "PH", value: "ph" },
    { label: "PD", value: "present_days" },
    { label: "CL", value: "casual_leave" },
    { label: "PL", value: "pl" },
    { label: "SL", value: "sl" },
    { label: "Coff", value: "coff" },
    { label: "LWP", value: "lwp" },
    { label: "Actual BASIC", value: "actual_basic" },
    { label: "Actual HRA", value: "actual_hra" },
    { label: "Actual WASH", value: "actual_wash" },
    { label: "Actual Prod.Ins.All.", value: "actual_prod_ins_all" },
    { label: "Actual Adv. Bonus", value: "actual_adv_bonus" },
    { label: "Actual Adv. Leave", value: "actual_adv_leave" },
    { label: "Actual Adv.Std.Be", value: "actual_adv_std_be" },
    { label: "Actual Food", value: "actual_food" },
    { label: "Actual DA", value: "actual_da" },
    { label: "Actual Other Earning", value: "actual_other_earning" },
    { label: "BASIC", value: "basic" },
    { label: "HRA", value: "hra" },
    { label: "WASH", value: "wash" },
    { label: "Prod.Ins.All", value: "prod_ins_all" },
    { label: "Advance Bonus", value: "adv_bonus" },
    { label: "Advance Leave", value: "adv_leave" },
    { label: "Adv.Std.Be", value: "adv_std_be" },
    { label: "Food", value: "food" },
    { label: "DA", value: "da" },
    { label: "Other Earning", value: "other_earning" },
    { label: "PF", value: "pf" },
    { label: "ESI", value: "esi" },
    { label: "PT", value: "pt" },
    { label: "IT", value: "it" },
    { label: "LWF", value: "lwf" },
    { label: "Advance", value: "advance" },
    { label: "Loan", value: "loan" },
    { label: "TDS", value: "tds" },
    { label: "Other Deduction", value: "other" },

    { label: "Total Earning", value: "total_income" },
    { label: "Gross  Deduction", value: "total_deduction" },
    { label: "Payable Amount", value: "payable_amount" },
  ];

  return (
    <>
      {SalaryRegisterList && SalaryRegisterList.length ? (
        <Form
          initialValues={initialValues}
          render={(formRenderProps: FormRenderProps) => {
            return (
              <FormElement style={{ width: "100%" }}>
                <GridLayout
                  //   style={{ marginRight: 30 }}
                  gap={{ rows: 10, cols: 10 }}
                  cols={[{ width: "100%" }]}
                >
                  <GridLayoutItem className="d-flex justify-content-between align-items-center">
                    <Typography.h4 className="m-0">
                      Employee Salary Register Report
                      {SalaryRegisterDetails?.payout_month &&
                        SalaryRegisterDetails?.payout_year && (
                          <span style={{ fontSize: 16, marginLeft: "10px" }}>
                            {`( ${
                              MONTHS_MAP[
                                SalaryRegisterDetails?.payout_month ?? 0
                              ]
                            }-${SalaryRegisterDetails?.payout_year} ) ${
                              SalaryRegisterDetails?.location
                                ? `- ${SalaryRegisterDetails.location}`
                                : ""
                            } `}
                          </span>
                        )}
                    </Typography.h4>
                    <div className="position-relative d-flex">
                      {/* {isExcelAccess && ( */}
                      <Button
                        type="button"
                        fillMode={"solid"}
                        themeColor={"primary"}
                        onClick={handleExportExcel}
                        style={{ marginRight: 10 }}
                      >
                        Excel Export
                      </Button>
                      {/* )} */}
                      <Tooltip
                        anchorElement="target"
                        position="left"
                        parentTitle={true}
                      >
                        <Button
                          title="Filter Columns"
                          type="button"
                          themeColor={"primary"}
                          fillMode={"solid"}
                          onClick={() => setShowFilterColumns(true)}
                        >
                          Filter
                          <BsThreeDotsVertical />
                        </Button>
                      </Tooltip>
                      {showfilterColumns && (
                        <div
                          style={{
                            position: "absolute",
                            top: "32px",
                            right: "0",
                            background: "white",
                            padding: "12px",
                            border: "1px solid whitesmoke",
                            width: "250px",
                            zIndex: 1,
                          }}
                        >
                          <div className="d-flex justify-content-between align-items-baseline">
                            <p className="m-0 fw-600">Filter</p>
                            <Button
                              themeColor={"primary"}
                              fillMode={"flat"}
                              onClick={() => setShowFilterColumns(false)}
                            >
                              <RxCross2 />
                            </Button>
                          </div>
                          <hr className="m-1" style={{ color: "lightgray" }} />
                          <Field
                            wrapperClassName="d-flex flex-column checkBoxGroup-vertical"
                            inputClassName="checkBoxGroup-vertical-input"
                            id={"filter_columns"}
                            name={"filter_columns"}
                            marginRight={0}
                            marginBeetween={8}
                            component={FormCheckboxGroup}
                            options={columns}
                          />
                        </div>
                      )}
                    </div>
                  </GridLayoutItem>
                  <GridLayoutItem
                    style={{
                      maxHeight: "calc(100vh - 200px)",
                      overflow: "scroll",
                      scrollbarWidth: "thin",
                    }}
                  >
                    <table
                      cellSpacing={1}
                      cellPadding={2}
                      className="table table-bordered w-100  salary-register-report-excel-table"
                      style={{
                        marginTop: 1,
                      }}
                    >
                      <thead>
                        <tr
                          className="text-center fw-600"
                          style={{
                            fontSize: 12,
                            position: "sticky",
                            top: 0,
                            outline: "1px solid #3b3b3b",
                          }}
                        >
                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("employee_name") && (
                            <th style={{ minWidth: 200 }}>Employee Name</th>
                          )}
                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("employee_code_string") && (
                            <th style={{ minWidth: 120 }}>Employee Code</th>
                          )}
                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("location") && (
                            <th style={{ minWidth: 120 }}>Location</th>
                          )}
                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("designation_name") && (
                            <th style={{ minWidth: 120 }}>Designation Name</th>
                          )}
                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("department_name") && (
                            <th style={{ minWidth: 120 }}>Department Name</th>
                          )}
                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("pf_no") && (
                            <th style={{ minWidth: 120 }}>PF No</th>
                          )}
                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("uan_no") && (
                            <th style={{ minWidth: 120 }}>UAN No</th>
                          )}
                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("est_no") && (
                            <th style={{ minWidth: 120 }}>ESI No</th>
                          )}
                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("bank_name") && (
                            <th style={{ minWidth: 200 }}>Bank Name</th>
                          )}
                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("account_no") && (
                            <th style={{ minWidth: 120 }}>Account No</th>
                          )}
                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("date_of_joining") && (
                            <th style={{ minWidth: 120 }}>Date Of Joining</th>
                          )}
                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("working_days") && (
                            <th style={{ minWidth: 120 }}>WD</th>
                          )}
                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("weekly_off") && (
                            <th style={{ minWidth: 120 }}>WO</th>
                          )}
                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("ph") && (
                            <th style={{ minWidth: 120 }}>PH</th>
                          )}
                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("present_days") && (
                            <th style={{ minWidth: 120 }}>PD</th>
                          )}
                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("casual_leave") && (
                            <th style={{ minWidth: 120 }}>CL</th>
                          )}
                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("pl") && (
                            <th style={{ minWidth: 120 }}>PL</th>
                          )}
                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("sl") && (
                            <th style={{ minWidth: 120 }}>SL</th>
                          )}
                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("coff") && (
                            <th style={{ minWidth: 120 }}>COff</th>
                          )}
                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("lwp") && (
                            <th style={{ minWidth: 120 }}>LWP</th>
                          )}
                          {/* RATE */}
                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("actual_basic") && (
                            <th style={{ minWidth: 120 }}>ACTUAL BASIC</th>
                          )}
                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("actual_hra") && (
                            <th style={{ minWidth: 120 }}>ACTUAL HRA</th>
                          )}
                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("actual_wash") && (
                            <th style={{ minWidth: 120 }}>ACTUAL WASH</th>
                          )}
                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("actual_prod_ins_all") && (
                            <th style={{ minWidth: 120 }}>
                              ACTUAL PROD.INS.ALL
                            </th>
                          )}
                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("actual_adv_bonus") && (
                            <th style={{ minWidth: 120 }}>ACTUAL ADV. BONUS</th>
                          )}
                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("actual_adv_leave") && (
                            <th style={{ minWidth: 120 }}>ACTUAL ADV. LEAVE</th>
                          )}
                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("actual_adv_std_be") && (
                            <th style={{ minWidth: 120 }}>
                              ACTUAL ADV. STD. BE
                            </th>
                          )}
                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("actual_food") && (
                            <th style={{ minWidth: 120 }}>ACTUAL FOOD</th>
                          )}
                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("actual_da") && (
                            <th style={{ minWidth: 120 }}>ACTUAL DA</th>
                          )}
                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("actual_other_earning") && (
                            <th style={{ minWidth: 120 }}>
                              ACTUAL OTHER EARNING
                            </th>
                          )}
                          {/* EARNING */}
                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("basic") && (
                            <th style={{ minWidth: 120 }}>BASIC</th>
                          )}
                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("hra") && (
                            <th style={{ minWidth: 120 }}>HRA</th>
                          )}
                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("wash") && (
                            <th style={{ minWidth: 120 }}>WASH</th>
                          )}
                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("prod_ins_all") && (
                            <th style={{ minWidth: 120 }}>PROD.INS.ALL</th>
                          )}
                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("adv_bonus") && (
                            <th style={{ minWidth: 120 }}>ADV. BONUS</th>
                          )}
                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("adv_leave") && (
                            <th style={{ minWidth: 120 }}>ADV. LEAVE</th>
                          )}
                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("adv_std_be") && (
                            <th style={{ minWidth: 120 }}>ADV. STD. BE</th>
                          )}
                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("food") && (
                            <th style={{ minWidth: 120 }}>FOOD</th>
                          )}
                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("da") && (
                            <th style={{ minWidth: 120 }}>DA</th>
                          )}
                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("other_earning") && (
                            <th style={{ minWidth: 120 }}>OTHER EARNING</th>
                          )}

                          {/* {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("rate") && (
                            <th
                              style={{ minWidth: 120 }}
                              className="salary-multi-columns-header-cell"
                            >
                              <p>Rate</p>
                              <p>BASIC</p>
                              <p>HRA</p>
                              <p>WASH</p>
                              <p>PROD.INS.ALL</p>
                              <p>ADV. BONUS</p>
                              <p>ADV. LEAVE</p>
                              <p>ADV. STD. BE</p>
                              <p>DA</p>
                              <p>OTHER EARNING</p>
                            </th>
                          )} */}
                          {/* {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("earning") && (
                            <th
                              style={{ minWidth: 120 }}
                              className="salary-multi-columns-header-cell"
                            >
                              <p>Earning</p>
                              <p>BASIC</p>
                              <p>HRA</p>
                              <p>WASH</p>
                              <p>PROD.INS.ALL</p>
                              <p>ADV. BONUS</p>
                              <p>ADV. LEAVE</p>
                              <p>ADV. STD. BE</p>
                              <p>DA</p>
                              <p>OTHER EARNING</p>
                            </th>
                          )} */}

                          {/* DEDUCTION */}
                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("pf") && (
                            <th style={{ minWidth: 120 }}>PF</th>
                          )}
                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("esi") && (
                            <th style={{ minWidth: 120 }}>ESI</th>
                          )}
                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("pt") && (
                            <th style={{ minWidth: 120 }}>PT</th>
                          )}
                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("it") && (
                            <th style={{ minWidth: 120 }}>IT</th>
                          )}
                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("lwf") && (
                            <th style={{ minWidth: 120 }}>LWF</th>
                          )}
                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("advance") && (
                            <th style={{ minWidth: 120 }}>ADVANCE</th>
                          )}
                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("loan") && (
                            <th style={{ minWidth: 120 }}>LOAN</th>
                          )}
                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("tds") && (
                            <th style={{ minWidth: 120 }}>TDS</th>
                          )}
                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("other") && (
                            <th style={{ minWidth: 120 }}>OTHER DEDUCTION</th>
                          )}

                          {/* {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("deduction") && (
                            <th
                              style={{ minWidth: 120 }}
                              className="salary-multi-columns-header-cell"
                            >
                              <p>Deduction</p>
                              <p>P.F</p>
                              <p>ESI</p>
                              <p>IT</p>
                              <p>P.T.</p>
                              <p>Loan</p>
                              <p>Advn.</p>
                              <p>TSD</p>
                              <p>Other Deduction</p>
                            </th>
                          )} */}
                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("total_income") && (
                            <th style={{ minWidth: 120 }}>Total Earning</th>
                          )}
                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("total_deduction") && (
                            <th style={{ minWidth: 120 }}>Gross Deduction</th>
                          )}
                          {formRenderProps
                            .valueGetter("filter_columns")
                            ?.includes("payable_amount") && (
                            <th style={{ minWidth: 120 }}>
                              Net Salary Payable in Rs.
                            </th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {SalaryRegisterList &&
                          SalaryRegisterList?.length > 0 &&
                          SalaryRegisterList?.map(
                            (item: any, index: number) => {
                              return (
                                <tr className="text-center" key={index}>
                                  {formRenderProps
                                    .valueGetter("filter_columns")
                                    ?.includes("employee_name") && (
                                    <td className="text-start">
                                      {item?.employee_name}
                                    </td>
                                  )}
                                  {formRenderProps
                                    .valueGetter("filter_columns")
                                    ?.includes("employee_code_string") && (
                                    <td className="text-start">
                                      {item?.employee_code_string}
                                    </td>
                                  )}
                                  {formRenderProps
                                    .valueGetter("filter_columns")
                                    ?.includes("location") && (
                                    <td className="text-start">
                                      {item?.location}
                                    </td>
                                  )}
                                  {formRenderProps
                                    .valueGetter("filter_columns")
                                    ?.includes("designation_name") && (
                                    <td className="text-start">
                                      {item?.designation_name}
                                    </td>
                                  )}

                                  {formRenderProps
                                    .valueGetter("filter_columns")
                                    ?.includes("department_name") && (
                                    <td>{item?.department_name}</td>
                                  )}
                                  {formRenderProps
                                    .valueGetter("filter_columns")
                                    ?.includes("pf_no") && (
                                    <td>{item?.pf_no}</td>
                                  )}
                                  {formRenderProps
                                    .valueGetter("filter_columns")
                                    ?.includes("uan_no") && (
                                    <td>{item?.uan_no}</td>
                                  )}
                                  {formRenderProps
                                    .valueGetter("filter_columns")
                                    ?.includes("est_no") && (
                                    <td>{item?.est_no}</td>
                                  )}

                                  {formRenderProps
                                    .valueGetter("filter_columns")
                                    ?.includes("bank_name") && (
                                    <td>{`${item?.bank_name}`}</td>
                                  )}
                                  {formRenderProps
                                    .valueGetter("filter_columns")
                                    ?.includes("account_no") && (
                                    <td>{`${item?.account_no}`}</td>
                                  )}
                                  {formRenderProps
                                    .valueGetter("filter_columns")
                                    ?.includes("date_of_joining") && (
                                    <td>
                                      {moment(
                                        item?.date_of_joining,
                                        "DD/MM/YYYY"
                                      ).format("DD-MMM-YYYY")}
                                    </td>
                                  )}
                                  {formRenderProps
                                    .valueGetter("filter_columns")
                                    ?.includes("working_days") && (
                                    <td>{item?.working_days}</td>
                                  )}
                                  {formRenderProps
                                    .valueGetter("filter_columns")
                                    ?.includes("weekly_off") && (
                                    <td>{item?.weekly_off}</td>
                                  )}
                                  {formRenderProps
                                    .valueGetter("filter_columns")
                                    ?.includes("ph") && <td>{item?.ph}</td>}
                                  {formRenderProps
                                    .valueGetter("filter_columns")
                                    ?.includes("present_days") && (
                                    <td>{item?.present_days}</td>
                                  )}
                                  {formRenderProps
                                    .valueGetter("filter_columns")
                                    ?.includes("casual_leave") && (
                                    <td>{item?.casual_leave}</td>
                                  )}
                                  {formRenderProps
                                    .valueGetter("filter_columns")
                                    ?.includes("pl") && <td>{item?.pl}</td>}
                                  {formRenderProps
                                    .valueGetter("filter_columns")
                                    ?.includes("sl") && <td>{item?.sl}</td>}
                                  {formRenderProps
                                    .valueGetter("filter_columns")
                                    ?.includes("coff") && <td>{item?.coff}</td>}
                                  {formRenderProps
                                    .valueGetter("filter_columns")
                                    ?.includes("lwp") && <td>{item?.lwp}</td>}

                                  {/* RATE */}
                                  {formRenderProps
                                    .valueGetter("filter_columns")
                                    ?.includes("actual_basic") && (
                                    <td>{item?.actual_basic}</td>
                                  )}
                                  {formRenderProps
                                    .valueGetter("filter_columns")
                                    ?.includes("actual_hra") && (
                                    <td>{item?.actual_hra}</td>
                                  )}
                                  {formRenderProps
                                    .valueGetter("filter_columns")
                                    ?.includes("actual_wash") && (
                                    <td>{item?.actual_wash}</td>
                                  )}
                                  {formRenderProps
                                    .valueGetter("filter_columns")
                                    ?.includes("actual_prod_ins_all") && (
                                    <td>{item?.actual_prod_ins_all}</td>
                                  )}
                                  {formRenderProps
                                    .valueGetter("filter_columns")
                                    ?.includes("actual_adv_bonus") && (
                                    <td>{item?.actual_adv_bonus}</td>
                                  )}
                                  {formRenderProps
                                    .valueGetter("filter_columns")
                                    ?.includes("actual_adv_leave") && (
                                    <td>{item?.actual_adv_leave}</td>
                                  )}
                                  {formRenderProps
                                    .valueGetter("filter_columns")
                                    ?.includes("actual_adv_std_be") && (
                                    <td>{item?.actual_adv_std_be}</td>
                                  )}

                                  {formRenderProps
                                    .valueGetter("filter_columns")
                                    ?.includes("actual_food") && (
                                    <td>{item?.actual_food}</td>
                                  )}
                                  {formRenderProps
                                    .valueGetter("filter_columns")
                                    ?.includes("actual_da") && (
                                    <td>{item?.actual_da}</td>
                                  )}
                                  {formRenderProps
                                    .valueGetter("filter_columns")
                                    ?.includes("actual_other_earning") && (
                                    <td>{item?.actual_other_earning}</td>
                                  )}

                                  {/* EARNING */}
                                  {formRenderProps
                                    .valueGetter("filter_columns")
                                    ?.includes("basic") && (
                                    <td>{item?.basic}</td>
                                  )}
                                  {formRenderProps
                                    .valueGetter("filter_columns")
                                    ?.includes("hra") && <td>{item?.hra}</td>}
                                  {formRenderProps
                                    .valueGetter("filter_columns")
                                    ?.includes("wash") && <td>{item?.wash}</td>}
                                  {formRenderProps
                                    .valueGetter("filter_columns")
                                    ?.includes("prod_ins_all") && (
                                    <td>{item?.prod_ins_all}</td>
                                  )}
                                  {formRenderProps
                                    .valueGetter("filter_columns")
                                    ?.includes("adv_bonus") && (
                                    <td>{item?.adv_bonus}</td>
                                  )}
                                  {formRenderProps
                                    .valueGetter("filter_columns")
                                    ?.includes("adv_leave") && (
                                    <td>{item?.adv_leave}</td>
                                  )}
                                  {formRenderProps
                                    .valueGetter("filter_columns")
                                    ?.includes("adv_std_be") && (
                                    <td>{item?.adv_std_be}</td>
                                  )}

                                  {formRenderProps
                                    .valueGetter("filter_columns")
                                    ?.includes("food") && <td>{item?.food}</td>}
                                  {formRenderProps
                                    .valueGetter("filter_columns")
                                    ?.includes("da") && <td>{item?.da}</td>}
                                  {formRenderProps
                                    .valueGetter("filter_columns")
                                    ?.includes("other_earning") && (
                                    <td>{item?.other_earning}</td>
                                  )}

                                  {/* {formRenderProps
                                    .valueGetter("filter_columns")
                                    ?.includes("rate") && (
                                    <td className="salary-multi-columns-body-cell">
                                      <p>{item.actual_basic}</p>
                                      <p>{item.actual_hra}</p>
                                      <p>{item.actual_wash}</p>
                                      <p>{item.actual_prod_ins_all}</p>
                                      <p>{item.actual_adv_bonus}</p>
                                      <p>{item.actual_adv_leave}</p>
                                      <p>{item.actual_adv_std_be}</p>
                                      <p>{item.actual_other_earning}</p>
                                      <p>{item.actual_da}</p>
                                    </td>
                                  )}
                                  {formRenderProps
                                    .valueGetter("filter_columns")
                                    ?.includes("earning") && (
                                    <td className="salary-multi-columns-body-cell">
                                      <p>{item.basic}</p>
                                      <p>{item.hra}</p>
                                      <p>{item.wash}</p>
                                      <p>{item.prod_ins_all}</p>
                                      <p>{item.adv_bonus}</p>
                                      <p>{item.adv_leave}</p>
                                      <p>{item.adv_std_be}</p>
                                      <p>{item.other_earning}</p>
                                      <p>{item.da}</p>
                                    </td>
                                  )} */}

                                  {/* DEDUCTION */}

                                  {formRenderProps
                                    .valueGetter("filter_columns")
                                    ?.includes("pf") && <td>{item?.pf}</td>}
                                  {formRenderProps
                                    .valueGetter("filter_columns")
                                    ?.includes("esi") && <td>{item?.esi}</td>}
                                  {formRenderProps
                                    .valueGetter("filter_columns")
                                    ?.includes("pt") && <td>{item?.pt}</td>}
                                  {formRenderProps
                                    .valueGetter("filter_columns")
                                    ?.includes("it") && <td>{item?.it}</td>}
                                  {formRenderProps
                                    .valueGetter("filter_columns")
                                    ?.includes("lwf") && <td>{item?.lwf}</td>}
                                  {formRenderProps
                                    .valueGetter("filter_columns")
                                    ?.includes("advance") && (
                                    <td>{item?.advance}</td>
                                  )}
                                  {formRenderProps
                                    .valueGetter("filter_columns")
                                    ?.includes("loan") && <td>{item?.loan}</td>}
                                  {formRenderProps
                                    .valueGetter("filter_columns")
                                    ?.includes("tds") && <td>{item?.tds}</td>}
                                  {formRenderProps
                                    .valueGetter("filter_columns")
                                    ?.includes("other") && (
                                    <td>{item?.other}</td>
                                  )}
                                  {/* {formRenderProps
                                    .valueGetter("filter_columns")
                                    ?.includes("deduction") && (
                                    <td className="salary-multi-columns-body-cell">
                                      <p>{item.pf}</p>
                                      <p>{item.est}</p>
                                      <p>{item.it}</p>
                                      <p>{item.pt}</p>
                                      <p>{item.loan}</p>
                                      <p>{item.advance}</p>
                                      <p>{item.tds}</p>
                                      <p>{item.other}</p>
                                    </td>
                                  )} */}
                                  {formRenderProps
                                    .valueGetter("filter_columns")
                                    ?.includes("total_income") && (
                                    <td>{item?.total_income}</td>
                                  )}
                                  {formRenderProps
                                    .valueGetter("filter_columns")
                                    ?.includes("total_deduction") && (
                                    <td>{item?.total_deduction}</td>
                                  )}
                                  {formRenderProps
                                    .valueGetter("filter_columns")
                                    ?.includes("payable_amount") && (
                                    <td>{item?.payable_amount}</td>
                                  )}
                                </tr>
                              );
                            }
                          )}
                      </tbody>
                    </table>
                  </GridLayoutItem>
                </GridLayout>
              </FormElement>
            );
          }}
        />
      ) : (
        <AlertBox />
      )}
    </>
  );
};

export default SalaryRegisterReportFormWithExcel;
