import React from "react";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
// import ShadowCard from "../../components/common/ShadowCard";
import { Typography } from "@progress/kendo-react-common";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import {
  confirmPassValidator,
  passwordValidator,
} from "../../components/formFields/CommonValidator";
import FormPasswordField from "../../components/formFields/FormPasswordField";
// import { getLocalStorageItem } from "../../_helper/helper";
import { ErrorToast } from "../../components/toast/Toasts";
import { changeUserPassword } from "../user/services/user.services";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { closeDialog } from "../../components/dialog/dialogSlice";

const ChangePassword: React.FC = () => {
  const dispatch = useAppDispatch();
  //   const UserID = getLocalStorageItem("UserID");
  const userLoading = useAppSelector((state) => state.user.loading);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const AccountIDString = useAppSelector(
    (state) => state.login.loginDetails.AccountID
  );
  const UserIDString = useAppSelector(
    (state) => state.login.loginDetails.UserID
  );
  const AccountID = parseInt(AccountIDString);
  const UserID = parseInt(UserIDString);

  const handleSubmit = async (values: any) => {
    const payload = {
      ID: UserID ? +UserID : AccountID ? +AccountID : null,
      old_password: values?.old_password ? values?.old_password : "",
      password: values?.password ? values?.password : "",
      isemployee: UserID ? true : false,
    };
    if (values?.old_password === values?.password) {
      ErrorToast("Old password can not be same as new password!");
    } else if (values?.confirm_password === values?.password) {
      const res = await dispatch(changeUserPassword(payload));
      if (res.meta.requestStatus === "fulfilled") {
        dispatch(closeDialog());
      }
    } else {
      ErrorToast("Confirm password is not matched. Please try again!");
    }
  };

  return (
    <>
      {dialogName === "changePassword" && (
        <AppDialog className="change-pass-dialog">
          <>
            {/* <GridLayout
              gap={{ rows: 10 }}
              cols={[{ width: "50%" }]}
              style={{ justifyContent: "center" }}
            >
              <GridLayoutItem>
                <ShadowCard> */}
            <Form
              onSubmit={handleSubmit}
              render={(formRenderProps: FormRenderProps) => (
                <FormElement style={{ width: "100%" }}>
                  <GridLayout
                    gap={{ rows: 20, cols: 10 }}
                    cols={[{ width: "100%" }]}
                  >
                    <GridLayoutItem>
                      <Typography.h4
                        style={{ marginBottom: 0, marginLeft: "3px" }}
                      >
                        Change Password
                      </Typography.h4>
                    </GridLayoutItem>
                    <GridLayoutItem>
                      <Field
                        name={"old_password"}
                        type={"password"}
                        label={"Old Password"}
                        component={FormPasswordField}
                        validator={passwordValidator}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem>
                      <Field
                        name={"password"}
                        type={"password"}
                        label={"New Password"}
                        component={FormPasswordField}
                        validator={passwordValidator}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem>
                      <Field
                        name={"confirm_password"}
                        type={"password"}
                        label={"Confirm Password"}
                        component={FormPasswordField}
                        validator={confirmPassValidator}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem
                      style={{
                        textAlign: "end",
                        marginTop: "10px",
                        width: "100%",
                      }}
                    >
                      <DialogCloseButton themeColor="error" />
                      <ButtonWithLoading
                        label="Change"
                        type="submit"
                        disabled={!formRenderProps.allowSubmit || userLoading}
                        loading={userLoading}
                      />
                    </GridLayoutItem>
                  </GridLayout>
                </FormElement>
              )}
            />
            {/* </ShadowCard>
              </GridLayoutItem>
            </GridLayout> */}
          </>
        </AppDialog>
      )}
    </>
  );
};

export default ChangePassword;
