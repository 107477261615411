import React, { useEffect } from "react";
import { Field, FieldArrayRenderProps } from "@progress/kendo-react-form";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { Typography } from "@progress/kendo-react-common";
import { setDutyAndTaxSalesDeleteIndex } from "./salesSlice";
import { useLocation } from "react-router-dom";
import FormNumericTextField from "../../components/formFields/FormNumericTextField";
import FormIncrementalSearch from "../../components/formFields/FormIncrementalSearch";
import _ from "lodash";

const DutyAndTaxSalesDetailsArray = (
  fieldArrayRenderProps: FieldArrayRenderProps
) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const sale_guid = location.state?.sale_guid;

  const DutyAndTaxDeleteIndex = useAppSelector(
    (state) => state.purchase.DutyAndTaxDeleteIndex
  );
  const InputRef = React.useRef<any>(null);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const handleOpenDeleteDialog = (index: number) => {
    dispatch(openDialog("DutyAndTaxSalesItemDeleteDialog"));
    dispatch(setDutyAndTaxSalesDeleteIndex(index));
  };

  useEffect(() => {
    if (!sale_guid || fieldArrayRenderProps.value.length === 0) {
      pushElementInarray();
    }
  }, []);
  const handleDeleteAction = React.useCallback(
    (index: number) => {
      fieldArrayRenderProps.onRemove({ index: index });
      const nextIndex = fieldArrayRenderProps.value.length - 1;
      const focusedElement = document.getElementsByName(
        `sales_duty_and_taxes.${nextIndex}.gst_amount`
      );
      focusedElement[0].focus();
      dispatch(closeDialog());
      dispatch(setDutyAndTaxSalesDeleteIndex(-1));
    },
    [fieldArrayRenderProps]
  );
  // const handleDeleteAction = React.useCallback(
  //   async (index: number) => {
  //     if (fieldArrayRenderProps?.value[index]?.id) {
  //       try {
  //         const response = await axiosApiInstance.post(
  //           `${API_URL}/Purchase/DeletePurchaseIte`,
  //           { id: fieldArrayRenderProps?.value[index]?.id }
  //         );
  //         fieldArrayRenderProps.onRemove({ index: index });
  //         dispatch(closeDialog());
  //         dispatch(setDutyAndTaxSalesDeleteIndex(-1));
  //         SuccessToast(response.data?.Details || "Success");
  //       } catch (error: any) {
  //         ErrorToast(error?.response?.data?.Details || "Something went wrong");
  //         console.error("Error deleting Item:", error);
  //         return Promise.reject({
  //           message: error?.response?.data?.Details || "Something went wrong",
  //         });
  //       }
  //     } else {
  //       fieldArrayRenderProps.onRemove({ index: index });
  //       const nextIndex =
  //         index < fieldArrayRenderProps.value.length - 1 ? index : index - 1;
  //       const focusedElement = document.getElementsByName(
  //         `sales_duty_and_taxes.${nextIndex}.gst_amount`
  //       );
  //       focusedElement[0].focus();
  //       dispatch(closeDialog());
  //       dispatch(setDutyAndTaxSalesDeleteIndex(-1));
  //     }
  //   },
  //   [fieldArrayRenderProps]
  // );

  const pushElementInarray = React.useCallback(() => {
    fieldArrayRenderProps.onPush({
      value: {
        ledger_id: null,
        gst_per: null,
        gst_amount: null,
      },
    });

    setTimeout(() => {
      InputRef.current?.element?.children[0]?.children[1]?.children[0]?.focus();
    }, 0);
  }, [fieldArrayRenderProps]);

  const handleProductChange = async (
    e: any,
    innerIndex: number,
    fieldArrayRenderProps: FieldArrayRenderProps
  ) => {
    const sales_duty_and_taxes =
      fieldArrayRenderProps.formRenderProps.valueGetter("sales_duty_and_taxes");
    if (e?.value) {
      // const match = e?.label.match(/@([\d.]+)/);
      const matched =
        fieldArrayRenderProps.value[innerIndex]?.ledger_options?.filter(
          (item: any) => item.id === e?.value
        ) || [];

      if (matched[0]?.tax_percentage) {
        // const GST = parseFloat(match[1]);
        sales_duty_and_taxes &&
          sales_duty_and_taxes.length > 0 &&
          sales_duty_and_taxes.map((item: any, index: number) => {
            if (innerIndex === index) {
              if (matched[0]?.type_of_supply !== "Service") {
                fieldArrayRenderProps.formRenderProps.onChange(
                  `sales_duty_and_taxes.${index}.gst_per`,
                  {
                    value: matched[0]?.tax_percentage || null,
                  }
                );
              }
              fieldArrayRenderProps.formRenderProps.onChange(
                `sales_duty_and_taxes.${index}.type_of_supply`,
                {
                  value: matched[0]?.type_of_supply || "",
                }
              );
            }
          });
      }
    } else {
      sales_duty_and_taxes &&
        sales_duty_and_taxes.length > 0 &&
        sales_duty_and_taxes.map((item: any, index: number) => {
          if (innerIndex === index) {
            fieldArrayRenderProps.formRenderProps.onChange(
              `sales_duty_and_taxes.${index}.gst_per`,
              {
                value: null,
              }
            );
          }
        });
    }
  };

  const debouncedHandleProductChange = React.useRef(
    _.debounce((item_id: any, index: number, fieldArrayRenderProps: any) => {
      handleProductChange(item_id, index, fieldArrayRenderProps);
    }, 300)
  ).current;

  useEffect(() => {
    return () => {
      debouncedHandleProductChange.cancel();
    };
  }, []);

  const handleChange = (
    item_id: any,
    index: number,
    fieldArrayRenderProps: any
  ) => {
    fieldArrayRenderProps.formRenderProps.onChange(
      `sales_duty_and_taxes.${index}.ledger_name`,
      {
        value: item_id?.label,
      }
    );

    if (item_id?.value) {
      debouncedHandleProductChange(item_id, index, fieldArrayRenderProps);
    }
  };

  // const handleTotalChange = async (
  //   e: any,
  //   total: number,
  //   index: number,
  //   fieldArrayRenderProps: FieldArrayRenderProps
  // ) => {
  //   e?.syntheticEvent?.preventDefault();
  //   const Ledger = fieldArrayRenderProps.formRenderProps.valueGetter(
  //     `sales_duty_and_taxes.${index}.ledger_id`
  //   );

  //   console.log(Ledger);
  // };

  const handleKeyEvent = (
    e: any,
    index: number,
    fieldArrayRenderProps: FieldArrayRenderProps
  ) => {
    if (
      e.key === "Tab" &&
      fieldArrayRenderProps.value.length === index + 1 &&
      fieldArrayRenderProps.value[index].ledger_id
    ) {
      if (!e.shiftKey) {
        pushElementInarray();
      }
    }
    if (e.key === "Escape" && fieldArrayRenderProps.value.length !== 1) {
      handleOpenDeleteDialog(index);
    }
  };

  const handleSearchLedger = (search: any, index: any) => {
    if (search) {
      fieldArrayRenderProps.handleLedgerSearchChange(
        search,
        `sales_duty_and_taxes.${index}.ledger_options`,
        fieldArrayRenderProps.formRenderProps
      );
    }
  };

  return (
    <>
      <GridLayout cols={[{ width: "100%" }]}>
        {dialogName === "DutyAndTaxSalesItemDeleteDialog" && (
          <AppDialog>
            <>
              <Typography.h5>{"Delete Item"}</Typography.h5>
              <GridLayout>
                <GridLayoutItem>
                  <Typography.p>
                    Are you sure you want to delete this?
                  </Typography.p>
                </GridLayoutItem>
                <GridLayoutItem
                  style={{
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <Button
                    style={{ marginRight: 5 }}
                    fillMode={"solid"}
                    themeColor={"error"}
                    onClick={() => handleDeleteAction(DutyAndTaxDeleteIndex)}
                    type="button"
                  >
                    Delete
                  </Button>
                  <DialogCloseButton themeColor="error" />
                </GridLayoutItem>
              </GridLayout>
            </>
          </AppDialog>
        )}
        {fieldArrayRenderProps.value &&
          fieldArrayRenderProps.value.length > 0 &&
          fieldArrayRenderProps.value.map(
            (sales_duty_and_taxes: any, index: number) => (
              <GridLayoutItem key={index}>
                <GridLayout
                  style={{ marginRight: 15, padding: 10, position: "relative" }}
                  gap={{ rows: 10, cols: 5 }}
                  cols={[
                    { width: "49%" },
                    { width: "25%" },
                    { width: "8%" },
                    { width: "18%" },
                  ]}
                >
                  <GridLayoutItem></GridLayoutItem>
                  <GridLayoutItem ref={InputRef}>
                    <Field
                      wrapperStyle={{ margin: 0 }}
                      name={`sales_duty_and_taxes.${index}.ledger_id`}
                      placeholder="search Ledger"
                      component={FormIncrementalSearch}
                      onChange={
                        (e: any) =>
                          handleChange(e, index, fieldArrayRenderProps)
                        // handleProductChange(e, index, fieldArrayRenderProps)
                      }
                      fetchIncrementalData={(search: any) =>
                        handleSearchLedger(search, index)
                      }
                      options={
                        fieldArrayRenderProps.value?.[index]?.ledger_options?.map(
                          (item: any) => {
                            return {
                              value: item?.id,
                              label: item?.account_name,
                            };
                          }
                        ) || []
                      }
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperClassName="right-alighned-field"
                      wrapperStyle={{ margin: 0 }}
                      name={`sales_duty_and_taxes.${index}.gst_per`}
                      placeholder="0"
                      min="0"
                      disabled={fieldArrayRenderProps.value[
                        index
                      ]?.ledger_name?.includes("GST")}
                      // validator={requiredValidator}
                      component={FormNumericTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperClassName="right-alighned-field"
                      wrapperStyle={{ margin: 0 }}
                      name={`sales_duty_and_taxes.${index}.gst_amount`}
                      placeholder="0"
                      format="n2"
                      component={FormNumericTextField}
                      onKeyDown={(e: any) =>
                        handleKeyEvent(e, index, fieldArrayRenderProps)
                      }
                    />
                  </GridLayoutItem>
                </GridLayout>
              </GridLayoutItem>
            )
          )}
      </GridLayout>
    </>
  );
};

export default DutyAndTaxSalesDetailsArray;
