import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  createLoanEntry,
  deleteLoanEntry,
  getAllActiveLoanEntrys,
  getAllLoanEntrys,
  getLoanEntryByID,
  updateLoanEntry,
} from "./services/loanentry.services";
import { ILoanEntry, ILoanEntryInitialState } from "./loanentryModel";

const initialState: ILoanEntryInitialState = {
  loading: false,
  error: "",
  LoanEntryList: [],
  LoanEntryID: null,
  LoanEntryDetail: {
    Loan_date: "",
    employee_id: null,
    loan_amout: null,
    loan_installment: null,
    installment_start_month: null,
    total_installment: null,
  },
  FilterLoanEntry: {
    employee_code: "",
    employee_name: "",
  },
};

const loanentrySlice = createSlice({
  name: "loanentry",
  initialState,
  reducers: {
    clearLoanEntryDetails: (state) => {
      state.LoanEntryDetail = initialState.LoanEntryDetail;
    },
    setLoanEntryID: (state, action) => {
      state.LoanEntryID = action.payload;
    },
    clearLoanEntryID: (state) => {
      state.LoanEntryID = initialState.LoanEntryID;
    },
    clearLoanEntryList: (state) => {
      state.LoanEntryList = [];
    },
    setFilterLoanEntry: (state, action) => {
      state.FilterLoanEntry = action.payload;
    },

    clearFilterLoanEntry: (state) => {
      state.FilterLoanEntry = initialState.FilterLoanEntry;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllLoanEntrys.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllLoanEntrys.fulfilled,
      (state, action: PayloadAction<ILoanEntry[]>) => {
        state.loading = false;
        state.LoanEntryList = action.payload || [];
      }
    );
    builder.addCase(getAllLoanEntrys.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.LoanEntryList = [];
    });

    builder.addCase(createLoanEntry.pending, (state) => {
      state.loading = false;
      state.error = "";
    });
    builder.addCase(createLoanEntry.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createLoanEntry.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(updateLoanEntry.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateLoanEntry.fulfilled, (state) => {
      state.loading = true;
    });
    builder.addCase(updateLoanEntry.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(deleteLoanEntry.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteLoanEntry.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteLoanEntry.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getLoanEntryByID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getLoanEntryByID.fulfilled, (state, action: any) => {
      state.loading = false;
      state.LoanEntryDetail = action.payload;
    });
    builder.addCase(getLoanEntryByID.rejected, (state, action) => {
      state.loading = false;
      state.LoanEntryDetail = initialState.LoanEntryDetail;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getAllActiveLoanEntrys.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveLoanEntrys.fulfilled,
      (state, action: PayloadAction<ILoanEntry[]>) => {
        state.loading = false;
        state.LoanEntryList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveLoanEntrys.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.LoanEntryList = [];
    });
  },
});

export const {
  clearLoanEntryDetails,
  setLoanEntryID,
  clearLoanEntryID,
  clearLoanEntryList,
  clearFilterLoanEntry,
  setFilterLoanEntry,
} = loanentrySlice.actions;
export default loanentrySlice.reducer;
