import { useEffect, useRef } from "react";
import { COMPANY_ADDRESS, COMPANY_NAME } from "../../../_contstants/common";

import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  getAccountStatustoryByUserID,
  getEmployeeByID,
  getFamilyMemberByUserID,
} from "../../employee/services/employee.services";
import { LoadingPanel } from "../../../components/layout/Loading";
import moment from "moment";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";

const NominationAndDeclarationForm = () => {
  const state = JSON.parse(
    sessionStorage.getItem("nominationanddeclarationformState") || "{}"
  );
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);

  const loading = useAppSelector((state) => state.employee.loading);
  const EmployeeDetails = useAppSelector(
    (state) => state.employee.EmployeeDetails
  );
  const AccountAndStatutoryDetails = useAppSelector(
    (state) => state.employee.AccountAndStatutoryDetails
  );
  const FamilyDetails = useAppSelector((state) => state.employee.FamilyDetails);

  const setPrintStyles = () => {
    const style = document.createElement("style");
    style.type = "text/css";
    style.innerHTML = `
          @media print {
            @page {
            border:1px solid #ccc;
              margin-left: 0.9cm;
              margin-right: 0.9cm;
            }
          }
        `;
    document.head.appendChild(style);
  };
  const fetchReport = async () => {
    const user_guid = state.user_guid;
    const id = state.id;
    setPrintStyles();
    await dispatch(getEmployeeByID(user_guid));
    await dispatch(getAccountStatustoryByUserID(user_guid));
    await dispatch(getFamilyMemberByUserID(id));
  };

  useEffect(() => {
    const handleTabClose = () => {
      sessionStorage.removeItem("nominationanddeclarationformState");
    };
    fetchReport();
    return () => {
      window.removeEventListener("unload", handleTabClose);
    };
  }, []);

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      <div
        style={{
          fontSize: "12px",
        }}
      >
        <ButtonWithLoading
          buttonStyle={{ position: "fixed", right: 0, top: 0, zIndex: 100 }}
          label={"Print"}
          className="no-visible-print-btn"
          type="button"
          onClick={() => window.print()}
        />
        <div>
          <h6
            className="m-0"
            style={{
              fontSize: "16px",
              paddingLeft: "10px",
            }}
          >
            {COMPANY_NAME}
          </h6>
          <p
            className="m-0"
            style={{
              paddingLeft: "10px",
            }}
          >
            {COMPANY_ADDRESS}
          </p>
        </div>
        <div
          style={{
            textAlign: "end",
            padding: "0px 80px 0px 0px",
          }}
        >
          <h6
            style={{
              fontWeight: "900",
            }}
          >
            FORM-2 (REVISED)
          </h6>
          <div
            style={{
              textAlign: "start",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "end",
              fontWeight: "900",
              justifyContent: "flex-end",
            }}
          >
            <p className="m-0">P.F. A/c No:- &nbsp; GJVAT0024846000</p>
            <p
              className="m-0"
              style={{
                paddingRight: "120px",
              }}
            >
              Group No:- |||
            </p>
          </div>
        </div>
        <div
          style={{
            textAlign: "center",
          }}
        >
          <h6
            className="m-0"
            style={{
              fontSize: "14px",
              fontWeight: "900",
            }}
          >
            NOMINATION AND DECLARATION FORM FOR UNEXEMPTED / EXEMPTED
            ESTABLISHMENTS
          </h6>
          <p className="m-0">
            Declaration and Nomination Form under the Employee's Provident Fund
            & Employee's Pension Scheme.
          </p>
          <p className="m-0" style={{ fontSize: "11px" }}>
            (Paragraph 33 & 61(1) of the Employee's Provident Fund Scheme, 1952
            & Paragraph 18 of the Employee's Pension Scheme 1995)
          </p>
        </div>
        <div className="nominination-declaration-details">
          <table
            style={{
              width: "100%",
            }}
          >
            <tbody>
              <tr>
                <td>1. Name (in Block Letter) :- </td>
                <td
                  style={{
                    textTransform: "uppercase",
                  }}
                >
                  {/* {`${EmployeeDetails?.first_name} ${EmployeeDetails?.middle_name} ${EmployeeDetails?.last_name}`} */}
                  {EmployeeDetails?.first_name}&nbsp;
                  {EmployeeDetails?.middle_name}&nbsp;
                  {EmployeeDetails?.last_name}
                </td>
                <td>7. Date of Joining &nbsp; :- </td>
                <td
                  style={{
                    fontWeight: "900",
                  }}
                >
                  {EmployeeDetails?.date_of_joining
                    ? moment(EmployeeDetails?.date_of_joining).format(
                        "DD/MM/YYYY"
                      )
                    : ""}
                </td>
              </tr>
              <tr>
                <td>2. Father/Husband Name :- </td>
                <td
                  style={{
                    textTransform: "uppercase",
                  }}
                >
                  {EmployeeDetails?.middle_name}
                </td>

                <td>
                  <span style={{ paddingBottom: "5px" }}>
                    8. Date of joining in F.P.F'71
                  </span>
                  <br />
                  <span style={{ padding: "10px" }}>/E.P.S.95'</span>
                </td>
                <td
                  style={{
                    fontWeight: "900",
                  }}
                >
                  {AccountAndStatutoryDetails?.pf_join_date
                    ? moment(AccountAndStatutoryDetails?.pf_join_date).format(
                        "DD/MM/YYYY"
                      )
                    : ""}
                </td>
              </tr>
              <tr>
                <td>3. Date of Birth :-</td>
                <td>
                  {EmployeeDetails?.dob
                    ? moment(EmployeeDetails?.dob).format("DD/MM/YYYY")
                    : ""}
                </td>

                <td colSpan={2}>9. Permanent Address :-</td>
              </tr>
              <tr>
                <td>4. Sex:-</td>
                <td>{EmployeeDetails?.gender}</td>
                <td colSpan={2} rowSpan={2}>
                  {EmployeeDetails?.permenent_address} &nbsp;
                  {EmployeeDetails?.permenent_street}&nbsp;
                  {EmployeeDetails?.permenent_area}&nbsp;
                  {EmployeeDetails?.permenent_city}&nbsp;
                  {EmployeeDetails?.permenent_pincode} &nbsp;
                  {EmployeeDetails?.permenent_state_name}&nbsp;
                </td>
              </tr>
              <tr>
                <td>5. Marital Status :- </td>
                <td>&nbsp;{EmployeeDetails?.marital_status}</td>
              </tr>
              <tr>
                <td>6. Account No. :-</td>
                <td>GJVAT0024846000</td>
                <td>10. Temporary :- </td>
                <td> As above</td>
              </tr>
              <tr>
                <td style={{ paddingLeft: "17px" }}>UAN No:</td>
                <td>{AccountAndStatutoryDetails?.uan_no}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="nominees-details">
          <h6
            style={{
              textAlign: "center",
              fontWeight: "900",
            }}
          >
            PART - A (EPF)
          </h6>
          <p
            style={{
              textAlign: "center",
              padding: "9px",
              fontSize: "11px",
            }}
          >
            I here by nominate the person(s)/ cancel the nomination made by me
            previously and nominate the person(s), mentioned below to receive
            the amount standing to my credit in the Employee's Provident Fund in
            the event of my death.
          </p>

          <table style={{ width: "100%", border: "1px solid #ccc" }}>
            <thead style={{ border: "1px solid #ccc" }}>
              <tr>
                <th>
                  Name of the <br /> nominee / nominees
                </th>
                <th>Address</th>
                <th>Nominee's relationship with the member</th>
                <th>Date of Birth</th>
                <th
                  style={{
                    width: "18%",
                  }}
                >
                  Total amount or share of accumulations in Provident Fund to be
                  paid to each nominee
                </th>
                <th>
                  If the Nominee is a minor, name & relationship & address of
                  the guardian who may receive the amount during the minority of
                  nominee
                </th>
              </tr>
            </thead>
            <tbody
              style={{
                border: "1px solid #ccc",
              }}
            >
              <tr
                style={{
                  border: "1px solid #ccc",
                }}
              >
                <td>1</td>
                <td>2</td>
                <td>3</td>
                <td>4</td>
                <td>5</td>
                <td>6</td>
              </tr>
              <tr>
                <td
                  style={{
                    width: "20%",
                    height: "100px",
                  }}
                >
                  {EmployeeDetails?.nomination_name}
                </td>
                <td
                  style={{
                    width: "18%",
                  }}
                >
                  {EmployeeDetails?.nomination_name
                    ? " As Per Serial No.9%"
                    : ""}
                </td>
                <td> {EmployeeDetails?.nomination_relation}</td>
                <td>
                  {EmployeeDetails?.nomination_dob
                    ? moment(EmployeeDetails?.nomination_dob).format(
                        "DD/MM/YYYY"
                      )
                    : ""}
                </td>
                <td
                  style={{
                    textAlign: "center",
                  }}
                >
                  {EmployeeDetails?.nomination_name ? "100%" : ""}
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
          <ol
            style={{
              paddingTop: "10px",
              listStyle: "number",
            }}
          >
            <li>
              &nbsp; *Certified that i have no family as defined in para 2 (g)
              of the Employee's Provident Fund Scheme, 1952 and should i acquire
              a family here after, the above nomination shuold be deemed as
              cancelled.
            </li>
            <li>
              {" "}
              &nbsp; *Certified that may Father/ Mother is/are dependent upon
              me.
            </li>
          </ol>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "80px 20px 20px 20px",
          }}
        >
          <p>*Strike out whichever is not applicable</p>
          <p>Signature of thumb impresion of the subscriber</p>
        </div>
      </div>
      <div
        style={{
          fontSize: "12px",
        }}
      >
        <div
          className="declaration-nominee-details"
          style={{
            textAlign: "center",
          }}
        >
          <h6
            style={{
              paddingTop: "5px",
            }}
          >
            PART-B (EPS)
          </h6>
          <p>
            I hereby furnish below particulars of the members of my family who
            whould be receive window / childern Pension <br /> in the event of
            my death
          </p>

          <table style={{ width: "100%", borderRight: "0px" }}>
            <thead
              style={{
                fontWeight: "normal",
              }}
            >
              <tr>
                <th rowSpan={2}>
                  Sr. <br /> No
                </th>
                <th colSpan={2}>Name & Address of the Family Member</th>
                <th rowSpan={2}>Date of Birth</th>
                <th rowSpan={2}>Relationship with member</th>
              </tr>
              <tr>
                <th
                  style={{
                    fontWeight: "900",
                  }}
                >
                  Name
                </th>
                <th
                  style={{
                    fontWeight: "900",
                  }}
                >
                  Address
                </th>
              </tr>
            </thead>

            <tbody>
              {FamilyDetails?.FamilyMemberDetails &&
              FamilyDetails.FamilyMemberDetails.length > 0 ? (
                FamilyDetails.FamilyMemberDetails.map(
                  (member: any, index: number) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td
                        style={{
                          width: "30%",
                        }}
                      >
                        {member?.name}
                      </td>
                      <td
                        style={{
                          width: "30%",
                        }}
                      >
                        {member?.name ? "As per Serial No.9 over leaf" : ""}
                      </td>
                      <td>
                        {member?.date
                          ? moment(member?.date).format("DD/MM/YYYY")
                          : ""}
                      </td>
                      <td>{member?.relation}</td>
                    </tr>
                  )
                )
              ) : (
                <tr>
                  <td
                    style={{
                      height: "50px",
                    }}
                  ></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              )}

              <tr>
                <td
                  style={{ textAlign: "start", padding: "10px 5px" }}
                  colSpan={5}
                >
                  <p>
                    1. **Certified that i have no family as defined in para 2
                    (vii) of the Employee's Pension Scheme 1995 and should I
                    acquire a family here after I shall furnish particulars
                    there on in the above form.
                  </p>
                  <p>
                    I hereby nominate the following person for receiving the
                    monthly widow pension (admissibble under para 16-2(a)(i) &
                    (ii) in the event of my death without leaving any elligible
                    family member for receiving pension.
                  </p>
                </td>
              </tr>
              <tr>
                <td
                  colSpan={3}
                  style={{
                    textAlign: "start",
                    padding: "10px",
                  }}
                >
                  Name & Address of the nominee
                </td>
                <td>Date of Birth</td>
                <td style={{ width: "20%" }}>
                  Relationship with <br /> member
                </td>
              </tr>
              <tr
                style={{
                  padding: "10px",
                }}
              >
                <td
                  colSpan={3}
                  style={{
                    textAlign: "start",
                    padding: "10px",
                  }}
                >
                  {EmployeeDetails?.nomination_name}
                  <br />
                  {EmployeeDetails?.nomination_name
                    ? "As per Serial No.9 over leaf"
                    : ""}
                </td>
                <td>
                  {EmployeeDetails?.nomination_dob
                    ? moment(EmployeeDetails?.nomination_dob).format(
                        "DD/MM/YYYY"
                      )
                    : ""}
                </td>
                <td>{EmployeeDetails?.nomination_relation}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="details-declaration-nominee">
          <div
            style={{
              display: "flex",
              alignItems: "end",
              justifyContent: "space-between",
              padding: "40px 10px 15px 10px",
            }}
          >
            <div>
              <p>Date:- {moment().format("DD/MM/YYYY")} </p>
              <p>*Strike out whichever is not applicable</p>
            </div>
            <div>
              <p>Signature of thumb impresion of the subscriber</p>
            </div>
          </div>
        </div>

        <div
          style={{
            textAlign: "center",
          }}
        >
          <h6
            style={{
              fontWeight: "900",
            }}
          >
            CERTIFICATE BY EMPLOYER
          </h6>
          <p>
            Certified that the above declaration has been signed / thumb
            impressed before me by
          </p>
        </div>
        <div
          style={{
            padding: "10px",
          }}
        >
          <p>
            Shri/Smt/Kum.&nbsp;
            <b style={{ textTransform: "uppercase" }}>
              &nbsp;
              {EmployeeDetails?.first_name}&nbsp;
              {EmployeeDetails?.middle_name}&nbsp;
              {EmployeeDetails?.last_name}&nbsp;&nbsp;
            </b>
            employed in my establishment after he/she has read the entries have
            been read over to him/her by me and got confirmed by him/her.
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "20px",
            }}
          >
            <p>PLACE: -Ahmedabad</p>
            <p>
              Signature of the Employer or other authorised officers of the
              establishment
            </p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "8px 20px 8px 20px",
            }}
          >
            <p>
              DATE:-
              {moment().format("DD/MM/YYYY")}
            </p>
            <p>For&nbsp;, &nbsp;{COMPANY_NAME}</p>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "flex-end",
            alignItems: "end",
            flexDirection: "column",
            padding: "20px 40px 10px 0px",
          }}
        >
          <div
            style={{
              width: "40%",
            }}
          >
            <p>
              Name & Address of the Factory / Establishment and Ruber Stamp
              thereof
            </p>
            <h6>{COMPANY_NAME}</h6>
            <p>{COMPANY_ADDRESS}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default NominationAndDeclarationForm;
