import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISalesRepresentativeInitialState } from "./salesRepresentativeModel";
import {
  createSalesRespresentative,
  getAllSalesRepresentative,
  getAllSalesRepresentativeAssignArea,
} from "./services/salesRepresentative.services";

const initialState: ISalesRepresentativeInitialState = {
  loading: false,
  error: "",
  SalesRepresentativeID: "",
  SalesRepresentativeList: [],
  SalesRepresentativeAssignAreaList: [],
  SalesRepresentativeDetail: {
    sales_representative: [],
  },
  FilterCustomSalesRepresentative: {
    sr_name: "",
  },
};

const salesRepresentativeSlice = createSlice({
  name: "salesrepresentative",
  initialState,
  reducers: {
    clearSalesRepresentativeDetail: (state) => {
      state.SalesRepresentativeDetail = initialState.SalesRepresentativeDetail;
    },
    setFilterCustomSalesRepresentative: (state, action) => {
      state.FilterCustomSalesRepresentative = action.payload;
    },
    clearFilterCustomSalesRepresentative: (state) => {
      state.FilterCustomSalesRepresentative =
        initialState.FilterCustomSalesRepresentative;
    },
    setSalesRepresentativeID: (state, action) => {
      state.SalesRepresentativeID = action.payload;
    },
    clearSalesRepresentativeID: (state) => {
      state.SalesRepresentativeID = initialState.SalesRepresentativeID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllSalesRepresentativeAssignArea.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllSalesRepresentativeAssignArea.fulfilled,
      (state, action: PayloadAction<any[]>) => {
        state.loading = false;
        state.SalesRepresentativeAssignAreaList = action.payload || [];
      }
    );
    builder.addCase(
      getAllSalesRepresentativeAssignArea.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
        state.SalesRepresentativeAssignAreaList = [];
      }
    );
    builder.addCase(getAllSalesRepresentative.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllSalesRepresentative.fulfilled,
      (state, action: PayloadAction<any[]>) => {
        state.loading = false;
        state.SalesRepresentativeList = action.payload || [];
      }
    );
    builder.addCase(getAllSalesRepresentative.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.SalesRepresentativeList = [];
    });
    builder.addCase(createSalesRespresentative.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(createSalesRespresentative.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createSalesRespresentative.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearSalesRepresentativeDetail,
  setFilterCustomSalesRepresentative,
  clearFilterCustomSalesRepresentative,
  clearSalesRepresentativeID,
  setSalesRepresentativeID,
} = salesRepresentativeSlice.actions;
export default salesRepresentativeSlice.reducer;
