import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import React, { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  CompositeFilterDescriptor,
  filterBy,
} from "@progress/kendo-data-query";
import {
  GridCellProps,
  GridCustomCellProps,
  GridCustomHeaderCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  Grid as KendoGrid,
  GridColumn as Column,
} from "@progress/kendo-react-grid";
import {
  HeaderThElement,
  PagerTargetEvent,
} from "@progress/kendo-react-data-tools";
// import IconButton from "../../components/common/IconButton";
// import { FiEdit } from "react-icons/fi";
import { checkAcessRights } from "../../_helper/helper";
import { EDIT_ACCESS } from "../../_contstants/common";
import { LoadingPanel } from "../../components/layout/Loading";
import AlertBox from "../../components/common/AlertBox";
import { useNavigate } from "react-router-dom";
import { findAllAreaAssignDistributor } from "./services/areaAssignToDistributor.services";
import { Button } from "@progress/kendo-react-buttons";

const AreaAssignToDistributor: React.FC = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(findAllAreaAssignDistributor());
    
  }, []);
  return (
    <>
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "4px 20px 20px 0",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            Area Assign To Distributor
          </Typography.h4>
        </GridLayoutItem>
        <GridLayoutItem>
          <DistributorGridComponent />
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

interface PageState {
  skip: number;
  take: number;
}

const initialDataState: PageState = { skip: 0, take: 50 };

const DistributorGridComponent: React.FC = () => {
  const gridRef = useRef<any>(null);
  const navigate = useNavigate();
  const isEditAccess = checkAcessRights(location.pathname, EDIT_ACCESS);
  const DistributorList = useAppSelector(
    (state) => state.areaAssign.DistributorList
  );
  const loading = useAppSelector((state) => state.areaAssign.loading);

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);
  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();

  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All" ? DistributorList.length : event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleEditItem = (id: any) => {
      navigate("/area-assign/edit", {
        state: {
          distributor_id: id,
          distributor_name: props.dataItem.account_name,
        },
      });
    };

    return (
      <td
        className="action-td"
        style={{
          ...props.style,
        }}
      >
        {isEditAccess && (
          <Button
            type="button"
            fillMode={"solid"}
            themeColor={"primary"}
            title="Assign"
            // className="absolute-position"
            // style={{ fontSize: "20px" }}
            onClick={() => handleEditItem(props.dataItem.id)}
          >
            Assign
          </Button>
          // <IconButton
          //   type="button"
          //   fillMode="solid"
          //   size="small"
          //   title="Edit"
          //   onClick={() => handleEditItem(props.dataItem.id)}
          // >
          //   Assign
          //   <FiEdit
          //     className="absolute-position"
          //     style={{ fontSize: "20px" }}
          //   />
          // </IconButton>
        )}
      </td>
    );
  };

  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
    setPage({ skip: 0, take: page.take });
  };
  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className="table-header"
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      {DistributorList && DistributorList.length ? (
        <KendoGrid
          style={{ maxHeight: "calc(100vh - 152px)" }}
          className="responsive-table"
          filterable={true}
          filter={filter}
          data={
            filter
              ? filterBy(DistributorList, filter).slice(
                  page.skip,
                  page.take + page.skip
                )
              : DistributorList.slice(page.skip, page.take + page.skip)
          }
          onFilterChange={handleFilterChange}
          skip={page.skip}
          take={page.take}
          total={DistributorList.length}
          pageable={{
            buttonCount: 5,
            pageSizes: [10, 25, 50, "All"],
            pageSizeValue: pageSizeValue,
            type: "input",
          }}
          onPageChange={pageChange}
          cells={{
            headerCell: HeaderCustomCell,
            data: MyDataCustomCell,
          }}
        >
          <Column
            field="account_name"
            title="Distributor Name"
            cell={(props: any) => (
              <td>
                <div
                  className={isEditAccess ? "hoverableName" : "normalName"}
                  onClick={() =>
                    isEditAccess &&
                    navigate("/area-assign/edit", {
                      state: {
                        distributor_id: props.dataItem?.id,
                        distributor_name: props.dataItem.account_name,
                      },
                    })
                  }
                >
                  {props.dataItem?.account_name}
                </div>
              </td>
            )}
          />
          <Column field="city_name" title="City" />
          <Column field="state_name" title="State" />
          <Column field="contact_person_name" title="Contact Person" />
          <Column field="mobile_number" title="Mobile No." />
          <Column field="total_area" title="Total Area" />
          {isEditAccess && (
            <Column
              field="item_group_guid"
              title="Actions"
              cell={MyEditCommandCell}
              width={"110px"}
              filterable={false}
            />
          )}
        </KendoGrid>
      ) : (
        <AlertBox />
      )}
    </>
  );
};

export default AreaAssignToDistributor;
