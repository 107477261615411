import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import moment from "moment";

export const getAllInwards = createAsyncThunk(
  "Inward/FindAllInward",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Inward/FindAllInward`,
        formData
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Inwards:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createInward = createAsyncThunk(
  "Inward/InsertInward",
  async (InwardData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Inward/InsertInward`,
        InwardData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating inward:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateInward = createAsyncThunk(
  "Inward/UpdateInward",
  async (InwardData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Inward/UpdateInward`,
        InwardData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating inward:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getInwardByID = createAsyncThunk(
  "Inward/FindByIDInward",
  async (FormData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Inward/FindByIDInward`,
        FormData
      );
      const result = response.data?.Data as any;
      return {
        id: result?.id,
        inward_guid: result?.inward_guid,
        inward_no: result?.inward_no,
        po_no: result?.po_no,
        po_no_label: result?.po_no_label,
        po_date: result?.po_date
          ? moment(result?.po_date, "YYYY-MM-DD").toDate()
          : "",
        inward_date: result?.inward_date
          ? moment(result?.inward_date, "YYYY-MM-DD").toDate()
          : "",
        bill_no: result?.bill_no,
        vendor_id: result?.vendor_id,
        vendor_options: result?.vendor_options || [],
        account_name: result?.account_name,
        vehicle_no: result?.vehicle_no,
        remarks: result?.remarks,
        chalan_no: result?.chalan_no,
        bill_date: result?.bill_date
          ? moment(result?.bill_date, "YYYY-MM-DD").toDate()
          : "",
        vehicle_in_time: result?.vehicle_in_time
          ? moment(result?.vehicle_in_time, "HH:mm:ss").toDate()
          : "",
        vehicle_out_time: result?.vehicle_out_time
          ? moment(result?.vehicle_out_time, "HH:mm:ss").toDate()
          : "",
        vehicle_condition: result?.vehicle_condition,
        region: result?.region,
        financial_year: result?.financial_year,
        total_amount: result?.total_amount,
        inward_items: result?.inward_items
          ? result?.inward_items?.map((inwarditem: any) => {
              return {
                id: inwarditem?.id ? +inwarditem?.id : null,
                grn_no: inwarditem?.grn_no ? +inwarditem?.grn_no : null,
                grn_no_string: inwarditem?.grn_no_string
                  ? inwarditem?.grn_no_string
                  : "",
                inward_id: inwarditem?.inward_id
                  ? +inwarditem?.inward_id
                  : null,
                item_id: inwarditem?.item_id ? +inwarditem?.item_id : null,
                item_options: inwarditem?.item_options
                  ? inwarditem?.item_options
                  : [],
                item_name: inwarditem?.item_name ? inwarditem?.item_name : "",
                warehouse_name: inwarditem?.warehouse_name
                  ? inwarditem?.warehouse_name
                  : "",
                unit_name: inwarditem?.unit_name ? inwarditem?.unit_name : "",
                process_name: inwarditem?.process_name
                  ? inwarditem?.process_name
                  : "",
                dagina: inwarditem?.dagina ? +inwarditem?.dagina : null,
                discount: inwarditem?.discount ? inwarditem?.discount : null,
                discount_type: inwarditem?.discount_type === 1 ? "₹" : "%",
                weight: inwarditem?.weight ? +inwarditem?.weight : null,
                rate: inwarditem?.rate ? +inwarditem?.rate : null,
                total: inwarditem?.total ? +inwarditem?.total : null,
                cold_storage_id: inwarditem?.cold_storage_id
                  ? +inwarditem?.cold_storage_id
                  : null,
                receipt_no: inwarditem?.receipt_no
                  ? inwarditem?.receipt_no
                  : "",
                unit_id: inwarditem?.unit_id ? +inwarditem?.unit_id : null,
                unit_decimal: inwarditem?.unit_decimal
                  ? +inwarditem?.unit_decimal
                  : 0,
                sgst_per: inwarditem?.sgst_per ? +inwarditem?.sgst_per : null,
                sgst_amount: inwarditem?.sgst_amount
                  ? +inwarditem?.sgst_amount
                  : null,
                cgst_per: inwarditem?.cgst_per ? +inwarditem?.cgst_per : null,
                cgst_amount: inwarditem?.cgst_amount
                  ? +inwarditem?.cgst_amount
                  : null,
                igst_per: inwarditem?.igst_per ? +inwarditem?.igst_per : null,
                igst_amount: inwarditem?.igst_amount
                  ? +inwarditem?.igst_amount
                  : null,
                total_gst_amount: inwarditem?.total_gst_amount
                  ? +inwarditem?.total_gst_amount
                  : null,
                // gst_id: inwarditem?.gst_id ? +inwarditem?.gst_id : null,
                gst_id: inwarditem?.gst_id
                  ? `${inwarditem?.gst_id}--${inwarditem?.gst || "0"}`
                  : null,
                gst: inwarditem?.gst ? +inwarditem?.gst : null,
                process_id: inwarditem?.process_id
                  ? +inwarditem?.process_id
                  : null,
              };
            })
          : [],
        inward_duty_and_taxes: result?.inward_duty_and_taxes
          ? result.inward_duty_and_taxes?.map((tax: any) => {
              return {
                ledger_id: tax?.ledger_id,
                ledger_name: tax?.ledger_name,
                gst_per: tax?.gst_per,
                gst_amount: tax?.gst_amount,
                ledger_options: tax?.ledger_options ? tax?.ledger_options : [],
              };
            })
          : [],
      } as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching inward:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteInward = createAsyncThunk(
  "Inward/DeleteInward",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Inward/DeleteInward`,
        { inward_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting inward:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getCurrentStockLotNo = createAsyncThunk(
  "Inward/FindAllCurrentStockLotno",
  async (item_id: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Inward/FindAllCurrentStockLotno`,
        {
          item_id: item_id,
        }
      );
      return response.data?.Data as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching lot no list:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllLimitedInwards = createAsyncThunk(
  "Inward/GetAllInward",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Inward/GetAllInward`,
        formData
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Inwards:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
