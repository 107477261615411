import React, { useCallback, useEffect } from "react";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import FormDatePicker from "../../../components/formFields/FormDateField";
import moment from "moment";
import { Typography } from "@progress/kendo-react-common";
import ShadowCard from "../../../components/common/ShadowCard";
import {
  clearSchemeReportDetails,
  clearSchemeReportList,
  setSchemeReport,
} from "../reportsSlice";
import AlertBox from "../../../components/common/AlertBox";
import { LoadingPanel } from "../../../components/layout/Loading";
import { requiredValidator } from "../../../components/formFields/CommonValidator";
import FormIncrementalSearch from "../../../components/formFields/FormIncrementalSearch";
import { Button } from "@progress/kendo-react-buttons";
// import Logo from "../../../assets/Images/logo.png";
import {
  checkAcessRights,
  formatIndianNumber,
  formatIndianNumberForQty,
} from "../../../_helper/helper";
import { EXCEL_EXPORT_ACCESS } from "../../../_contstants/common";
import { getAllAccountIncremental } from "../../account/services/account.services";
import { getSchemeReport } from "../services/reports.services";
import { getAllSchemes } from "../../scheme/services/scheme.services";
import FormSelectionField from "../../../components/formFields/FormSelectionField";
import { IScheme } from "../../scheme/schemeModel";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}
const DatechangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const fromdate = moment()?.startOf("month")?.toDate() || "";
  const todate = moment()?.toDate() || "";
  useEffect(() => {
    formRenderProps.onChange("from_date", {
      value: fromdate,
    });
    formRenderProps.onChange("to_Date", {
      value: todate,
    });
  }, []);

  return null;
};

const SchemeReportForm: React.FC = () => {
  const gridRef = React.useRef<any>(null);
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.reports.loading);
  const SchemeReportList = useAppSelector(
    (state) => state.reports.SchemeReportList
  );
  const SchemeReportDetails = useAppSelector(
    (state) => state.reports.SchemeReportDetails
  );
  const SchemeList = useAppSelector((state) => state.scheme.SchemeList);

  useEffect(() => {
    dispatch(getAllSchemes());
    return () => {
      dispatch(clearSchemeReportList());
      dispatch(clearSchemeReportDetails());
    };
  }, []);

  const handleVendorSearchChange = useCallback(
    async (search: string, field: string, formRenderProps: FormRenderProps) => {
      const result = await dispatch(getAllAccountIncremental(search));
      formRenderProps.onChange(field, {
        value: result.payload,
      });
    },
    [dispatch]
  );

  const handleSubmit = (values: any) => {
    const payload = {
      scheme_id: values?.scheme_id ? values?.scheme_id : null,
      vendor_id: values?.vendor_id ? values?.vendor_id : null,
      from_date: values?.from_date
        ? moment(values?.from_date).format("YYYY-MM-DD")
        : null,
      to_Date: values?.to_Date
        ? moment(values?.to_Date).format("YYYY-MM-DD")
        : null,
    };
    dispatch(setSchemeReport(payload));
    dispatch(getSchemeReport(payload));
  };
  return (
    <GridLayout gap={{ rows: 10 }} cols={[{ width: "100%" }]}>
      <GridLayoutItem>
        <ShadowCard>
          <Form
            initialValues={SchemeReportDetails}
            onSubmit={handleSubmit}
            render={(formRenderProps: FormRenderProps) => (
              <FormElement style={{ width: "100%" }}>
                <GridLayout
                  style={{ marginRight: 30 }}
                  gap={{ rows: 10, cols: 10 }}
                  cols={[
                    { width: "25%" },
                    { width: "25%" },
                    { width: "25%" },
                    { width: "25%" },
                  ]}
                >
                  <GridLayoutItem colSpan={4}>
                    <Typography.h4
                      style={{ marginBottom: 0, marginLeft: "3px" }}
                    >
                      Scheme Report
                    </Typography.h4>
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="scheme_id"
                      label="Scheme"
                      placeholder="Scheme"
                      astrike={true}
                      validator={requiredValidator}
                      component={FormSelectionField}
                      options={SchemeList?.map((scheme: IScheme) => {
                        return {
                          value: scheme?.id,
                          label: scheme?.scheme_name,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="from_date"
                      label="From Date"
                      format="dd/MM/yyyy"
                      component={FormDatePicker}
                      validator={requiredValidator}
                      astrike={true}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="to_Date"
                      label="To Date"
                      format="dd/MM/yyyy"
                      minDate={moment(
                        formRenderProps.valueGetter("from_date")
                      ).toDate()}
                      component={FormDatePicker}
                      validator={requiredValidator}
                      astrike={true}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="vendor_id"
                      label="Distributor"
                      placeholder="Type here to search distributor..."
                      component={FormIncrementalSearch}
                      fetchIncrementalData={(search: any) =>
                        handleVendorSearchChange(
                          search,
                          `vendor_options`,
                          formRenderProps
                        )
                      }
                      options={
                        formRenderProps
                          .valueGetter("vendor_options")
                          ?.map((item: any) => {
                            return {
                              value: item.id,
                              label: item.account_name,
                            };
                          }) || []
                      }
                    />
                  </GridLayoutItem>
                  <GridLayoutItem
                    colSpan={4}
                    style={{
                      textAlign: "end",
                      marginTop: 10,
                    }}
                  >
                    <ButtonWithLoading
                      label={"View"}
                      type="submit"
                      disabled={!formRenderProps.allowSubmit || loading}
                      loading={loading}
                    />
                  </GridLayoutItem>
                </GridLayout>
                <DatechangeWatcher formRenderProps={formRenderProps} />
              </FormElement>
            )}
          />
        </ShadowCard>
        {loading ? (
          <LoadingPanel gridRef={gridRef} />
        ) : (
          SchemeReportList &&
          SchemeReportList.length > 0 && (
            <ShadowCard style={{ marginTop: 10 }}>
              <PurchaseRegisterGridCommponent />
            </ShadowCard>
          )
        )}
      </GridLayoutItem>
    </GridLayout>
  );
};
const PurchaseRegisterGridCommponent: React.FC = () => {
  // const isPrintAccess = checkAcessRights(location.pathname, PRINT_ACCESS);
  const isExcelAccess = checkAcessRights(
    location.pathname,
    EXCEL_EXPORT_ACCESS
  );
  const SchemeReportList = useAppSelector(
    (state) => state.reports.SchemeReportList
  );
  const SchemeReportDetails = useAppSelector(
    (state) => state.reports.SchemeReportDetails
  );

  const handleExportExcel = () => {
    const datetime = moment().format("DD-MM-YYYY HH-mm");
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.table_to_sheet(
      document.querySelector(".currentstock-print-table")
    );
    XLSX.utils.book_append_sheet(wb, ws, "SchemeReport");

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(data, `Scheme Report ${datetime}`);
  };

  const itemGroups = Array.from(
    new Set(
      SchemeReportList?.flatMap((scheme: any) =>
        scheme?.item_group?.map((group: any) => group?.item_group)
      )
    )
  );
  const totals = itemGroups.reduce((acc, group) => {
    acc[group] = { totalQuantity: 0, totalAmount: 0 };
    SchemeReportList.forEach((scheme: any) => {
      const item = scheme?.item_group?.find(
        (g: any) => g?.item_group === group
      );
      if (item && item?.item_data?.length > 0) {
        const data = item?.item_data[0];
        acc[group].totalQuantity += data?.total_quantity || 0;
        acc[group].totalAmount += data?.total_amount || 0;
      }
    });
    return acc;
  }, {});

  return (
    <>
      {SchemeReportList && SchemeReportList.length ? (
        // <Form
        //   initialValues={initialValues}
        //   render={(formRenderProps: FormRenderProps) => {
        //     return (
        //       <FormElement
        //         style={{ width: "100%", minHeight: "calc(100vh - 290px)" }}
        //       >
        <GridLayout gap={{ rows: 10, cols: 10 }} cols={[{ width: "100%" }]}>
          <GridLayoutItem className="d-flex justify-content-between align-items-center">
            <Typography.h4 className="m-0">
              Scheme Report List{" "}
              {(SchemeReportDetails?.from_date ||
                SchemeReportDetails?.to_Date) && (
                <span style={{ fontSize: 13 }}>
                  {"("}
                  {SchemeReportDetails?.from_date
                    ? moment(
                        SchemeReportDetails?.from_date,
                        "YYYY-MM-DD"
                      ).format("DD/MM/YYYY")
                    : ""}
                  {SchemeReportDetails?.from_date &&
                    SchemeReportDetails?.to_Date &&
                    " - "}
                  {SchemeReportDetails?.to_Date
                    ? moment(SchemeReportDetails?.to_Date, "YYYY-MM-DD").format(
                        "DD/MM/YYYY"
                      )
                    : ""}
                  {")"}
                </span>
              )}
            </Typography.h4>
            <div className="position-relative d-flex">
              {isExcelAccess && (
                <Button
                  type="button"
                  fillMode={"solid"}
                  themeColor={"primary"}
                  onClick={handleExportExcel}
                  style={{ marginRight: 5 }}
                >
                  Excel Export
                </Button>
              )}
              {/* {isPrintAccess && (
                <Button
                  style={{ marginRight: 5 }}
                  type="button"
                  fillMode={"solid"}
                  themeColor={"primary"}
                  //   onClick={() => printTable(SchemeReportList, formRenderProps)}
                >
                  Print
                </Button>
              )} */}
            </div>
          </GridLayoutItem>
          <GridLayoutItem
            style={{
              maxHeight: "calc(100vh - 345px)",
              overflow: "scroll",
              scrollbarWidth: "thin",
            }}
          >
            <table
              cellSpacing={1}
              cellPadding={2}
              className="table table-bordered w-100  currentstock-print-table"
              style={{ marginTop: 1 }}
            >
              <tr className="text-center">
                <th rowSpan={2} style={{ minWidth: 110 }}>
                  Invoice Date
                </th>
                <th rowSpan={2} style={{ minWidth: 150 }}>
                  Invoice No
                </th>
                {itemGroups?.map((group: any) => (
                  <React.Fragment key={group}>
                    <th colSpan={2} style={{ textAlign: "center" }}>
                      {group}
                    </th>
                  </React.Fragment>
                ))}
                <th rowSpan={2} style={{ minWidth: 130 }}>
                  Total Amount
                </th>
              </tr>
              <tr className="text-center">
                {itemGroups?.map((group: any) => (
                  <React.Fragment key={group}>
                    <th style={{ minWidth: 130 }}>Quantity</th>
                    <th style={{ minWidth: 130 }}>Amount</th>
                  </React.Fragment>
                ))}
              </tr>
              {SchemeReportList.map((scheme: any) => {
                const billTotal = scheme?.item_group.reduce(
                  (sum: any, group: any) => {
                    const data =
                      group.item_data.length > 0 ? group.item_data[0] : null;
                    return sum + (data ? data.total_amount : 0);
                  },
                  0
                );

                return (
                  <tr key={scheme?.invoice_no}>
                    <td>
                      {scheme?.invoice_date
                        ? moment(scheme?.invoice_date, "DD/MM/YYYY")?.format(
                            "DD MMM YYYY"
                          )
                        : ""}
                    </td>
                    <td>{scheme?.invoice_no_string}</td>
                    {itemGroups?.map((group: any) => {
                      const item = scheme?.item_group?.find(
                        (g: any) => g?.item_group === group
                      );
                      const data =
                        item && item?.item_data?.length > 0
                          ? item?.item_data[0]
                          : null;

                      return (
                        <React.Fragment key={group}>
                          <td className="text-end">
                            {data
                              ? formatIndianNumberForQty(data?.total_quantity)
                              : ""}
                          </td>
                          <td className="text-end">
                            {data ? formatIndianNumber(data?.total_amount) : ""}
                          </td>
                        </React.Fragment>
                      );
                    })}
                    <td
                      style={{
                        fontWeight: "bold",
                        background: "whitesmoke",
                        textAlign: "end",
                      }}
                    >
                      {formatIndianNumber(billTotal)}
                    </td>
                  </tr>
                );
              })}
              <tr style={{ background: "whitesmoke" }}>
                <td colSpan={2} className="fw-600">
                  Total
                </td>
                {itemGroups?.map((group: any) => (
                  <React.Fragment key={group}>
                    <td className="text-end fw-600">
                      {formatIndianNumberForQty(totals[group]?.totalQuantity)}
                    </td>
                    <td className="text-end fw-600">
                      {formatIndianNumber(totals[group]?.totalAmount)}
                    </td>
                  </React.Fragment>
                ))}
                <td style={{ fontWeight: "bold", textAlign: "end" }}>
                  {formatIndianNumber(
                    SchemeReportList?.reduce((sum: any, scheme: any) => {
                      return (
                        sum +
                        scheme?.item_group?.reduce(
                          (groupSum: any, group: any) => {
                            const data =
                              group?.item_data?.length > 0
                                ? group?.item_data[0]
                                : null;
                            return groupSum + (data ? data?.total_amount : 0);
                          },
                          0
                        )
                      );
                    }, 0)
                  )}
                </td>
              </tr>
              {/* <tr className="text-center fw-600" style={{ fontSize: 12 }}>
                <th style={{ width: "40%" }}>Description Of Goods</th>
                <th style={{ width: "10%" }}>Quantity</th>
                <th style={{ width: "10%" }}>Total Amount Before GST</th>
              </tr>
              {SchemeReportList &&
                SchemeReportList?.length > 0 &&
                SchemeReportList?.map((item: any) => {
                  let totalAmount = 0;
                  return (
                    <>
                      <tr style={{ background: "whitesmoke" }}>
                        <td
                          className="fw-600"
                          colSpan={7}
                          style={{ fontSize: 12 }}
                        >
                          {item?.item_group}
                        </td>
                      </tr>
                      {item?.item_data &&
                        item?.item_data?.length > 0 &&
                        item?.item_data?.map((subitem: any) => {
                          totalAmount += +subitem?.amount;
                          return (
                            <tr>
                              <td>{subitem?.product_name}</td>
                              <td className="text-end">
                                {formatIndianNumberForQty(
                                  subitem?.total_quantity || 0
                                )}
                              </td>
                              <td className="text-end">
                                {formatIndianNumber(subitem?.amount || 0)}
                              </td>
                            </tr>
                          );
                        })}
                      <tr className="text-end fw-600">
                        <td colSpan={2}>Total</td>
                        <td>{formatIndianNumber(totalAmount)}</td>
                      </tr>
                    </>
                  );
                })} */}
            </table>
          </GridLayoutItem>
        </GridLayout>
      ) : (
        //       </FormElement>
        //     );
        //   }}
        // />
        <AlertBox />
      )}
    </>
  );
};
export default SchemeReportForm;
