import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import moment from "moment";

// export const getAllReceipt = createAsyncThunk(
//   "Receipt/ReceiptFindAll",
//   async (formData: any) => {
//     try {
//       const response = await axiosApiInstance.post(
//         `${API_URL}/Receipt/ReceiptFindAll`,
//         formData
//       );
//       return response.data?.Data;
//     } catch (error: any) {
//       // ErrorToast(error?.response?.data?.Details || "Something went wrong");
//       console.error("Error Fetching Receipt :", error);
//       return Promise.reject({
//         message: error?.response?.data?.Details || "Something went wrong",
//       });
//     }
//   }
// );

export const getAllReceipt = createAsyncThunk(
  "Receipt/ReceiptFindAll",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Receipt/ReceiptFindAll`
      );
      return response.data?.Data;
    } catch (error: any) {
      console.error("Error While Fetch Receipt:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createReceipt = createAsyncThunk(
  "Receipt/InsertReceipt",
  async (fomedata: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Receipt/InsertReceipt`,
        fomedata
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating Receipt :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateReceipt = createAsyncThunk(
  "Receipt/UpdateReceipt",
  async (formdata: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Receipt/UpdateReceipt`,
        formdata
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating Receipt :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteReceipt = createAsyncThunk(
  "Receipt/DeleteReceipt",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Receipt/DeleteReceipt`,
        { receipt_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting Receipt :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getReceiptByID = createAsyncThunk(
  "Receipt/FindByIDReceipt",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Receipt/FindByIDReceipt`,
        formData
      );
      const result = response.data?.Data as any;
      return {
        id: result?.id,
        receipt_guid: result?.receipt_guid,
        receipt_no: result?.receipt_no,
        receipt_no_string: result?.receipt_no_string,
        financial_year: result?.financial_year,
        receipt_date: result?.receipt_date
          ? moment(result?.receipt_date, "YYYY-MM-DD").toDate()
          : "",
        receipt_total_amount: result?.receipt_total_amount,
        remarks: result?.remarks,
        receipt_entry: result?.receipt_entry,
      } as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching DebitNote details:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllOutstandingReceipt = createAsyncThunk(
  "Payment/OutstandingPaymentFindAll",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Payment/OutstandingPaymentFindAll`,
        formData
      );
      const result = response.data?.Data as any;
      return result as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching Outstanding Receipt details:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const generateReceiptNoForReceipt = createAsyncThunk(
  "Receipt/InvoiceNumberReceipt",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Receipt/InvoiceNumberReceipt`
      );
      return response.data?.Data;
    } catch (error: any) {
      console.error("Error generating paymnet no. :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
