import { Button } from "@progress/kendo-react-buttons";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import {
  CompositeFilterDescriptor,
  filterBy,
} from "@progress/kendo-data-query";
import {
  GridCellProps,
  GridCustomCellProps,
  GridCustomHeaderCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  Grid as KendoGrid,
  GridColumn as Column,
} from "@progress/kendo-react-grid";
import { HeaderThElement } from "@progress/kendo-react-data-tools";
import IconButton from "../../components/common/IconButton";
import { MdDelete } from "react-icons/md";
import { TextBox } from "@progress/kendo-react-inputs";
import { LoadingPanel } from "../../components/layout/Loading";
import AlertBox from "../../components/common/AlertBox";
import {
  deleteLoanEntry,
  getAllLoanEntrys,
} from "./services/loanentry.services";
import {
  clearLoanEntryDetails,
  setFilterLoanEntry,
  setLoanEntryID,
} from "./loanentrySlice";
import { FiEdit } from "react-icons/fi";
import { checkAcessRights } from "../../_helper/helper";
import {
  DELETE_ACCESS,
  EDIT_ACCESS,
  INSERT_ACCESS,
  MONTHS_MAP,
} from "../../_contstants/common";
import { setCurrentPage, setPageLimit } from "../pagination/paginationSlice";
import _debounce from "lodash/debounce";
import { filterClearIcon } from "@progress/kendo-svg-icons";

const LoanEntry: React.FC = () => {
  const navigate = useNavigate();
  const isAddAccess = checkAcessRights(location.pathname, INSERT_ACCESS);
  const handleLoanEntryCreate = () => {
    navigate("/loanentry/create");
  };

  return (
    <>
      <DeleteLoanEntryDialog />
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "4px 20px 20px 0",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            Loan Entry List
          </Typography.h4>

          {isAddAccess && (
            <Button
              onClick={handleLoanEntryCreate}
              fillMode={"solid"}
              themeColor={"primary"}
            >
              Add New
            </Button>
          )}
        </GridLayoutItem>
        <GridLayoutItem>
          <LoanEntryGridComponent />
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

const LoanEntryGridComponent: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isEditAccess = checkAcessRights(location.pathname, EDIT_ACCESS);
  const isDeleteAccess = checkAcessRights(location.pathname, DELETE_ACCESS);
  const isEditBtnRef = useRef<any>(false);
  const LoanEntryList = useAppSelector(
    (state) => state.loanentry.LoanEntryList
  );
  const loading = useAppSelector((state) => state.loanentry.loading);
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);
  const FilterLoanEntry: any = useAppSelector(
    (state) => state.loanentry.FilterLoanEntry
  );
  useEffect(() => {
    return () => {
      dispatch(closeDialog());
      dispatch(setCurrentPage(0));
      dispatch(setCurrentPage(0));
      if (!isEditBtnRef.current) {
        dispatch(clearLoanEntryDetails());
      }
    };
  }, []);

  useEffect(() => {
    const payload = {
      ...FilterLoanEntry,
      limit: +pageLimit,
      skip: +currentPage * +pageLimit,
    };
    dispatch(getAllLoanEntrys(payload));
  }, [currentPage, pageLimit]);

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);

  const pageChange = (event: GridPageChangeEvent) => {
    const newPage = event.page.skip / event.page.take;
    dispatch(setPageLimit(event.page.take));
    dispatch(setCurrentPage(newPage));
  };

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleEditLoanEntry = (loan_guid: any) => {
      navigate("/loanentry/edit", {
        state: { loan_guid: loan_guid },
      });
    };

    const handleOpenDeleteDialog = (loan_guid: any) => {
      dispatch(openDialog("deleteLoanEntry"));
      dispatch(setLoanEntryID(loan_guid));
    };

    return (
      <td className={`action-td ${props.className}`} style={props.style}>
        {isEditAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Edit"
            ref={isEditBtnRef}
            onClick={() => {
              isEditBtnRef.current = true;
              handleEditLoanEntry(props.dataItem.loan_guid);
            }}
          >
            <FiEdit
              className="absolute-position"
              style={{ fontSize: "20px" }}
            />
          </IconButton>
        )}
        {isDeleteAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Delete"
            onClick={() => handleOpenDeleteDialog(props.dataItem.loan_guid)}
          >
            <MdDelete
              className="absolute-position"
              style={{ fontSize: "24px" }}
            />
          </IconButton>
        )}
      </td>
    );
  };
  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
  };

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className={`table-header ${props.index === 9 ? "locked-header" : ""}`}
    >
      {props.children}
    </HeaderThElement>
  );
  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };
  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  const gridRef = useRef<any>(null);

  return (
    <>
      {loading && <LoadingPanel gridRef={gridRef} />}
      {LoanEntryList && LoanEntryList.length ? (
        <KendoGrid
          style={{ maxHeight: "calc(100vh - 152px)" }}
          className="responsive-table"
          filterable={true}
          filter={filter}
          data={filter ? filterBy(LoanEntryList, filter) : LoanEntryList}
          onFilterChange={handleFilterChange}
          skip={currentPage * pageLimit}
          take={pageLimit}
          total={LoanEntryList[0]?.itemcount || LoanEntryList?.length}
          pageable={{
            buttonCount: 5,
            pageSizes: [10, 50, 100, 300, 500],
            pageSizeValue: pageLimit,
            type: "input",
          }}
          onPageChange={pageChange}
          cells={{
            headerCell: HeaderCustomCell,
            data: MyDataCustomCell,
          }}
        >
          <Column
            field="Loan_date"
            title="Loan Date"
            width={250}
            filterCell={CommonFilterCell}
            cell={(props: any) => (
              <td>
                <div
                  className={isEditAccess ? "hoverableName" : "normalName"}
                  onClick={() => {
                    if (isEditAccess) {
                      isEditBtnRef.current = true;
                      navigate("/loanentry/edit", {
                        state: { loan_guid: props.dataItem?.loan_guid },
                      });
                    }
                  }}
                >
                  {props.dataItem?.Loan_date}
                </div>
              </td>
            )}
          />
          {/* <Column field="account_name" title="Distributor Name" width={200} /> */}
          <Column field="employee_name" title="Employee Name" width={350} />
          <Column field="employee_code" title="Employee Code" width={150} />
          <Column field="loan_amout" title="Loan Amount" width={200} />
          <Column
            field="loan_installment"
            title="Loan Installment"
            width={200}
          />
          <Column
            field="installment_start_month"
            title="Installment Start Month"
            width={200}
            cell={(props: any) => (
              <td>
                <div>
                  {MONTHS_MAP[
                    props.dataItem?.installment_start_month
                  ]?.toUpperCase()}
                </div>
              </td>
            )}
          />
          <Column
            field="installment_end_month"
            title="Installment End Month"
            width={200}
            cell={(props: any) => (
              <td>
                <div>
                  {MONTHS_MAP[
                    props.dataItem?.installment_end_month
                  ]?.toUpperCase()}
                </div>
              </td>
            )}
          />
          <Column
            field="total_installment"
            title="Total Installment"
            width={200}
          />
          <Column field="cash_receive" title="Cash Receive" width={250} />
          {(isEditAccess || isDeleteAccess) && (
            <Column
              field="loan_guid"
              title="Actions"
              cell={MyEditCommandCell}
              width={"110px"}
              filterable={false}
              locked={true}
            />
          )}
        </KendoGrid>
      ) : (
        <AlertBox />
      )}
    </>
  );
};

const DeleteLoanEntryDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const LoanEntryID = useAppSelector((state) => state.loanentry.LoanEntryID);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);
  const FilterLoanEntry: any = useAppSelector(
    (state) => state.loanentry.FilterLoanEntry
  );
  const handleDeleteAction = async (ID: number | null) => {
    if (ID) {
      const response = await dispatch(deleteLoanEntry(ID));
      const fetchpayload = {
        ...FilterLoanEntry,
        limit: +pageLimit,
        skip: +currentPage * +pageLimit,
      };
      if (response.payload?.Data.Status === 200) {
        dispatch(getAllLoanEntrys(fetchpayload));
        dispatch(closeDialog());
        dispatch(clearLoanEntryDetails());
      } else {
        dispatch(getAllLoanEntrys(fetchpayload));
        dispatch(closeDialog());
        dispatch(clearLoanEntryDetails());
      }
    }
  };

  return (
    <>
      {dialogName === "deleteLoanEntry" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Loan Entry ?"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this Loan Entry?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => {
                    handleDeleteAction(LoanEntryID);
                  }}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

const CommonFilterCell = (props: any) => {
  const dispatch = useAppDispatch();
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);
  const FilterLoanEntry: any = useAppSelector(
    (state) => state.loanentry.FilterLoanEntry
  );
  const isFilterRef = useRef(true);
  const [inputValue, setInputValue] = useState(props.value || "");
  const [inputField, setInputField] = useState(props.field || "");

  const onChange = (event: any) => {
    setInputValue(event.target.value);
    setInputField(event.target.name);
  };

  const delayedApiCall = React.useCallback(
    _debounce((updatedFilters: any) => {
      dispatch(setCurrentPage(0));
      const payload = {
        ...updatedFilters,
        limit: +pageLimit,
        skip: 0 * +pageLimit,
      };

      dispatch(getAllLoanEntrys(payload));
    }, 300),
    [dispatch]
  );

  useEffect(() => {
    if (!isFilterRef.current) {
      const updatedFilters = { ...FilterLoanEntry, [inputField]: inputValue };
      dispatch(setFilterLoanEntry(updatedFilters));
      delayedApiCall(updatedFilters);
    } else {
      isFilterRef.current = false;
    }
    return () => {
      delayedApiCall.cancel();
    };
  }, [inputValue, delayedApiCall]);

  const onClearButtonClick = (event: any) => {
    event.preventDefault();
    props.onChange({
      value: null,
      operator: "",
      syntheticEvent: event,
    });

    const updatedFilters = { ...FilterLoanEntry, [props.field]: "" };
    dispatch(setFilterLoanEntry(updatedFilters));

    const payload = {
      ...updatedFilters,
      limit: +pageLimit,
      skip: +currentPage * +pageLimit,
    };

    dispatch(getAllLoanEntrys(payload));
  };

  return (
    <div className="d-flex">
      <TextBox
        value={FilterLoanEntry[props.field] || ""}
        name={props.field || ""}
        onChange={onChange}
      />
      <Button
        svgIcon={filterClearIcon}
        title="Clear"
        disabled={!FilterLoanEntry[props.field]}
        onClick={onClearButtonClick}
        type="button"
        style={{
          marginLeft: "8px",
        }}
      ></Button>
    </div>
  );
};

export default LoanEntry;
