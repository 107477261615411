import React, { useCallback, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Typography } from "@progress/kendo-react-common";
import {
  Field,
  FieldArray,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import FormTextField from "../../components/formFields/FormTextField";
import FormDatePicker from "../../components/formFields/FormDateField";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import ShadowCard from "../../components/common/ShadowCard";
import { Button } from "@progress/kendo-react-buttons";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { LoadingPanel } from "../../components/layout/Loading";
import { getGRNByItemID } from "../inwardreturn/services/inwardReturn.services";
import { IMachine } from "../machine/machineModel";
import FormTimePicker from "../../components/formFields/FormTimeField";
import { findAllActiveMachine } from "../machine/services/machine.services";
import { IProcess } from "../process/processModel";
import { findAllActiveProcess } from "../process/services/process.services";
import moment from "moment";
import { IUser } from "../user/userModel";
import { getAllActiveUsers } from "../user/services/user.services";
import { clearRoastingDetails } from "./roastingSlice";
import {
  createRoastingNew,
  generateLotNoForRoasting,
  getRoastingByIDNew,
  updateRoastingNew,
} from "./services/roasting.services";
import { getCurrentStock } from "../stocktransfer/services/stockTransfer.services";
import { clearItemList } from "../Item/itemSlice";
import FormIncrementalSearch from "../../components/formFields/FormIncrementalSearch";
import { getAllItemIncremental } from "../Item/services/item.services";
import {
  formatIndianNumberForQty,
  getLocalStorageItem,
} from "../../_helper/helper";
import { FINANCIAL_YEAR } from "../../_contstants/common";
import FormTextArea from "../../components/formFields/FormTextArea";
import RoastingItemDetailsArray from "./RoastingItemDetailsArray";
import { ErrorToast } from "../../components/toast/Toasts";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}
const QtychangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const total_qty = formRenderProps.valueGetter("total_qty");
  const received_qty = formRenderProps.valueGetter("received_qty");
  const process_loss_qty = formRenderProps.valueGetter("process_loss_qty");

  useEffect(() => {
    formRenderProps.onChange("process_loss_qty", {
      value: +total_qty - +received_qty,
    });
  }, [total_qty, received_qty]);

  if (process_loss_qty < 0) {
    formRenderProps.onChange("process_loss_qty", {
      value: 0,
    });
  }

  useEffect(() => {
    const lossPercentage = (process_loss_qty / total_qty) * 100;
    formRenderProps.onChange("roasting_loss_percentage", {
      value: `${lossPercentage ? lossPercentage?.toFixed(2) : 0} %`,
    });
  }, [total_qty, process_loss_qty]);

  return null;
};

const LotNoChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const roasting_guid = location.state?.roasting_guid;
  const isLotNoRef = useRef(false);

  useEffect(() => {
    const fetchlotNo = async () => {
      const response = await dispatch(
        generateLotNoForRoasting({ financial_year: FINANCIAL_YEAR })
      );
      if (response.meta.requestStatus === "fulfilled") {
        formRenderProps.onChange("lot_no", {
          value: response.payload?.lot_no || "",
        });
      }
    };
    if (!isLotNoRef.current) {
      if (!roasting_guid) {
        fetchlotNo();
      }
      isLotNoRef.current = true;
    } else {
      isLotNoRef.current = true;
    }
  }, []);

  return null;
};

const TakenQtyWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const roasting_items = formRenderProps.valueGetter("roasting_items");
  let totalQty = 0;
  let totalLossQty = 0;

  useEffect(() => {
    if (roasting_items && roasting_items.length > 0) {
      roasting_items?.map((item: any, index: number) => {
        totalQty += +item?.taken_qty || 0;
        totalLossQty += +item?.wastage_qty || 0;

        const TakenQTY = item.taken_qty;
        const WastageQTY = item.wastage_qty;
        if (WastageQTY > TakenQTY) {
          formRenderProps.onChange(`roasting_items.${index}.wastage_qty`, {
            value: TakenQTY,
          });
        }

        const lossPercentage = (WastageQTY / TakenQTY) * 100;
        formRenderProps.onChange(`roasting_items.${index}.loss_percentage`, {
          value: `${lossPercentage ? lossPercentage?.toFixed(2) : 0} %`,
        });
      });
    }

    formRenderProps.onChange("total_qty", {
      value: totalQty,
    });
    formRenderProps.onChange("total_loss_qty", {
      value: totalLossQty,
    });
  }, [
    roasting_items?.map((item: any) => item?.taken_qty).join("-"),
    roasting_items?.map((item: any) => item?.wastage_qty).join("-"),
  ]);

  return null;
};

const InnerItemChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const roasting_items = formRenderProps.valueGetter("roasting_items");
  const isFetchGrnOption = React.useRef(true);
  const roasting_guid = location.state?.roasting_guid;

  const fetchGRNOptions = useCallback(
    async (index: number, item: any) => {
      if (item?.item_id && item?.process_id) {
        const grnPayload = {
          item_id: item.item_id,
          process_id: item?.process_id,
          warehouse_id: 1,
        };
        const response = await dispatch(getGRNByItemID(grnPayload));
        const result =
          response.payload
            ?.filter((grn: any) => grn.item_id === item.item_id)
            .map((grnno: any) => ({
              value: `${grnno?.grn_no}`,
              label: `${grnno?.grn_no} - ${formatIndianNumberForQty(
                grnno?.current_stock || 0
              )}`,
            })) || [];
        formRenderProps.onChange(`roasting_items.${index}.grnNoOptions`, {
          value: result,
        });
      } else {
        formRenderProps.onChange(`roasting_items.${index}.grnNoOptions`, {
          value: [],
        });
        formRenderProps.onChange(`roasting_items.${index}.grn_no`, {
          value: null,
        });
      }
    },
    [dispatch, formRenderProps]
  );

  const fetchCurrentStock = useCallback(
    async (index: number, item: any) => {
      if (item?.item_id && item?.grn_no && item?.process_id) {
        const payload = {
          item_id: +item.item_id,
          grn_no: item.grn_no,
          process_id: +item?.process_id,
          warehouse_id: 1,
        };
        const response = await dispatch(getCurrentStock(payload));
        formRenderProps.onChange(`roasting_items.${index}.current_qty`, {
          value: response.payload?.actual_stock || 0,
        });
        formRenderProps.onChange(`roasting_items.${index}.dagina`, {
          value: response.payload?.dagina || 0,
        });
      }
    },
    [dispatch, formRenderProps]
  );

  useEffect(() => {
    if (isFetchGrnOption.current && roasting_guid) {
      roasting_items.forEach((item: any, index: number) => {
        fetchGRNOptions(index, item);
        fetchCurrentStock(index, item);
      });
      isFetchGrnOption.current = false;
    }
  }, [roasting_guid]);

  return null;
};

// const ItemChangeWatcher: React.FC<FormChangeWatcherProps> = ({
//   formRenderProps,
// }) => {
//   const dispatch = useAppDispatch();
//   const item_id = formRenderProps.valueGetter("item_id");
//   const location = useLocation();
//   const roasting_guid = location.state?.roasting_guid;
//   const isItemRef = React.useRef(true);

//   useEffect(() => {
//     const fetchGRNOptions = async () => {
//       if (roasting_guid) {
//         if (!isItemRef.current) {
//           formRenderProps.onChange("grn_no", {
//             value: null,
//           });
//         } else {
//           isItemRef.current = false;
//         }
//       } else {
//         formRenderProps.onChange("grn_no", {
//           value: null,
//         });
//       }
//       if (item_id) {
//         const response: any = await dispatch(getGRNByItemID(item_id));
//         if (response && response.payload) {
//           const result = response.payload
//             .filter((grn: any) => grn?.item_id === item_id)
//             .map((grnno: any) => ({
//               value: `${grnno?.grn_no}`,
//               label: `${grnno?.grn_no}`,
//             }));

//           formRenderProps.onChange("grnNoOptions", {
//             value: result?.length > 0 && result,
//           });
//         } else {
//           formRenderProps.onChange("grnNoOptions", { value: [] });
//           formRenderProps.onChange("grn_no", {
//             value: null,
//           });
//         }
//       } else {
//         formRenderProps.onChange("grnNoOptions", { value: [] });
//         formRenderProps.onChange("grn_no", {
//           value: null,
//         });
//       }
//     };

//     fetchGRNOptions();
//   }, [item_id]);

//   return null;
// };

// const GRNChangeWatcher: React.FC<FormChangeWatcherProps> = ({
//   formRenderProps,
// }) => {
//   const dispatch = useAppDispatch();
//   const location = useLocation();
//   const roasting_guid = location.state?.roasting_guid;
//   const previous_process_id = formRenderProps.valueGetter(
//     "previous_process_id"
//   );
//   const item_id = formRenderProps.valueGetter("item_id");
//   const grn_no = formRenderProps.valueGetter("grn_no");
//   const current_stock = formRenderProps.valueGetter("current_stock");

//   useEffect(() => {
//     if (grn_no && previous_process_id) {
//       const fetchStock = async (grn_no: any) => {
//         const payload = {
//           item_id: item_id ? item_id : null,
//           grn_no: grn_no ? grn_no : "",
//           process_id: previous_process_id ? previous_process_id : null,
//           warehouse_id: 1,
//         };
//         const res = await dispatch(getCurrentStock(payload));
//         formRenderProps.onChange("current_stock", {
//           value: res?.payload?.actual_stock || 0,
//         });
//       };

//       fetchStock(grn_no);
//     } else {
//       formRenderProps.onChange("current_stock", {
//         value: null,
//       });
//     }
//   }, [grn_no, previous_process_id]);

//   useEffect(() => {
//     if ((current_stock === null || current_stock < 1) && !roasting_guid) {
//       formRenderProps.onChange("total_qty", {
//         value: 0,
//       });
//       formRenderProps.onChange("received_qty", {
//         value: 0,
//       });
//     }
//   }, [current_stock]);

//   return null;
// };

const UserChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const location = useLocation();
  const UserID = getLocalStorageItem("UserID");
  const roasting_guid = location.state?.roasting_guid;

  useEffect(() => {
    if (!roasting_guid) {
      formRenderProps.onChange("checker_by", {
        value: UserID ? +UserID : null,
      });
    }
  }, [UserID]);

  return null;
};

const CreateRoasting: React.FC = () => {
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const roasting_guid = location.state?.roasting_guid;
  const loading = useAppSelector((state) => state.roasting.loading);
  const RoastingDetail = useAppSelector(
    (state) => state.roasting.RoastingDetail
  );
  const MachineList = useAppSelector((state) => state.machine.MachineList);

  const ProcessList = useAppSelector((state) => state.process.ProcessList);
  const UserList = useAppSelector((state) => state.user.UserList);

  const [formKey, setFormKey] = React.useState(1);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [RoastingDetail]);

  useEffect(() => {
    dispatch(findAllActiveMachine());
    dispatch(findAllActiveProcess());
    dispatch(getAllActiveUsers());

    return () => {
      dispatch(clearRoastingDetails());
      dispatch(clearItemList());
    };
  }, []);

  useEffect(() => {
    if (roasting_guid) {
      const payload = {
        roasting_guid: roasting_guid,
      };
      dispatch(getRoastingByIDNew(payload));
    }
  }, [roasting_guid]);

  const handleItemSearchChange = useCallback(
    async (search: string, field: string, formRenderProps: FormRenderProps) => {
      const result = await dispatch(getAllItemIncremental({ search }));
      formRenderProps.onChange(field, {
        value: result.payload,
      });
    },
    [dispatch]
  );

  const handleSubmit = async (values: any) => {
    if (
      values?.process_loss_qty?.toFixed(3) != values?.total_loss_qty?.toFixed(3)
    ) {
      ErrorToast("Roasting loss quantity must be same.");
    } else {
      if (roasting_guid) {
        try {
          const updatePayload = {
            roasting_guid: RoastingDetail?.roasting_guid,
            roasting_date: values?.roasting_date
              ? moment(values?.roasting_date).format("YYYY-MM-DD")
              : "",
            roasting_no: values?.roasting_no ? +values?.roasting_no : null,
            ready_product_id: values?.ready_product_id
              ? +values?.ready_product_id
              : null,
            // item_id: values?.item_id ? +values?.item_id : null,
            // grn_no: values?.grn_no ? values?.grn_no : "",
            lot_no: values?.lot_no ? `${values?.lot_no}` : "",
            no_of_workers: values?.no_of_workers
              ? +values?.no_of_workers
              : null,
            machine_id: values?.machine_id ? +values?.machine_id : null,
            start_time: values?.start_time
              ? moment(values?.start_time).format("HH:mm:ss")
              : "",
            end_time: values?.end_time
              ? moment(values?.end_time).format("HH:mm:ss")
              : "",
            total_qty: values?.total_qty ? +values?.total_qty : 0,
            received_qty: values?.received_qty ? +values?.received_qty : 0,
            process_loss_qty: values?.process_loss_qty
              ? +values?.process_loss_qty
              : 0,
            roasting_loss_percentage: values?.roasting_loss_percentage
              ? +values?.roasting_loss_percentage?.replace("%", "")
              : null,
            checker_by: values?.checker_by ? +values?.checker_by : null,
            financial_year: FINANCIAL_YEAR,
            // previous_process_id: values?.previous_process_id
            //   ? +values?.previous_process_id
            //   : null,
            process_id: values?.process_id ? +values?.process_id : null,
            remarks: values?.remarks ? values?.remarks : "",
            roasting_items: values?.roasting_items
              ? values?.roasting_items?.map((roastitem: any) => {
                  return {
                    id: roastitem?.id ? +roastitem?.id : 0,
                    process_id: roastitem?.process_id
                      ? +roastitem?.process_id
                      : null,
                    item_id: roastitem?.item_id ? +roastitem?.item_id : null,
                    grn_no: roastitem?.grn_no ? roastitem?.grn_no : "",
                    taken_qty: roastitem?.taken_qty
                      ? +roastitem?.taken_qty
                      : null,
                    wastage_qty: roastitem?.wastage_qty
                      ? +roastitem?.wastage_qty
                      : null,
                    loss_percentage: roastitem?.loss_percentage
                      ? +roastitem?.loss_percentage?.replace("%", "")
                      : null,
                  };
                })
              : [],
          };
          const response = await dispatch(updateRoastingNew(updatePayload));
          if (response?.meta?.requestStatus === "fulfilled") {
            navigate("/roasting");
          }
        } catch (error) {
          console.error("Error in handleSubmit:", error);
          throw error;
        }
      } else {
        try {
          const insertPayload = {
            roasting_date: values?.roasting_date
              ? moment(values?.roasting_date).format("YYYY-MM-DD")
              : "",
            roasting_no: values?.roasting_no ? +values?.roasting_no : null,
            ready_product_id: values?.ready_product_id
              ? +values?.ready_product_id
              : null,
            // item_id: values?.item_id ? +values?.item_id : null,
            // grn_no: values?.grn_no ? values?.grn_no : "",
            lot_no: values?.lot_no ? `${values?.lot_no}` : "",
            no_of_workers: values?.no_of_workers
              ? +values?.no_of_workers
              : null,
            machine_id: values?.machine_id ? +values?.machine_id : null,
            start_time: values?.start_time
              ? moment(values?.start_time).format("HH:mm:ss")
              : "",
            end_time: values?.end_time
              ? moment(values?.end_time).format("HH:mm:ss")
              : "",
            total_qty: values?.total_qty ? +values?.total_qty : 0,
            received_qty: values?.received_qty ? +values?.received_qty : 0,
            process_loss_qty: values?.process_loss_qty
              ? +values?.process_loss_qty
              : 0,
            roasting_loss_percentage: values?.roasting_loss_percentage
              ? +values?.roasting_loss_percentage?.replace("%", "")
              : null,
            checker_by: values?.checker_by ? +values?.checker_by : null,
            financial_year: FINANCIAL_YEAR,
            // previous_process_id: values?.previous_process_id
            //   ? +values?.previous_process_id
            //   : null,
            process_id: values?.process_id ? +values?.process_id : null,
            remarks: values?.remarks ? values?.remarks : "",
            roasting_items: values?.roasting_items
              ? values?.roasting_items?.map((roastitem: any) => {
                  return {
                    process_id: roastitem?.process_id
                      ? +roastitem?.process_id
                      : null,
                    item_id: roastitem?.item_id ? +roastitem?.item_id : null,
                    grn_no: roastitem?.grn_no ? roastitem?.grn_no : "",
                    taken_qty: roastitem?.taken_qty
                      ? +roastitem?.taken_qty
                      : null,
                    wastage_qty: roastitem?.wastage_qty
                      ? +roastitem?.wastage_qty
                      : null,
                    loss_percentage: roastitem?.loss_percentage
                      ? +roastitem?.loss_percentage?.replace("%", "")
                      : null,
                  };
                })
              : [],
          };
          const response = await dispatch(createRoastingNew(insertPayload));
          if (response?.meta?.requestStatus === "fulfilled") {
            navigate("/roasting");
          }
        } catch (error) {
          console.error("Error in handleSubmit:", error);
          throw error;
        }
      }
    }
  };

  const handleRoastingNoChange = (
    ev: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (ev.key === "Enter" || ev.key === "Tab") {
      ev.preventDefault();
      if ((ev.target as HTMLInputElement).value) {
        const payload = {
          roasting_no: (ev.target as HTMLInputElement).value,
          financial_year: FINANCIAL_YEAR,
        };
        dispatch(getRoastingByIDNew(payload));
      }
    }
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      <Form
        key={formKey}
        onSubmit={handleSubmit}
        initialValues={RoastingDetail}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12 }}>
              <GridLayout
                style={{ marginRight: 30 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                ]}
              >
                <GridLayoutItem colSpan={4}>
                  <Typography.h4>
                    {roasting_guid ? "Update Roasting" : "Add Roasting"}
                  </Typography.h4>
                </GridLayoutItem>
                {roasting_guid && (
                  <GridLayoutItem>
                    <Field
                      name="roasting_no"
                      onKeyDown={handleRoastingNoChange}
                      label="Roasting No"
                      placeholder="Roasting No"
                      component={FormTextField}
                      validator={requiredValidator}
                      astrike={true}
                    />
                  </GridLayoutItem>
                )}
                {roasting_guid && <GridLayoutItem colSpan={3}></GridLayoutItem>}
                <GridLayoutItem>
                  <Field
                    name="roasting_date"
                    label="Roasting Date"
                    format="dd/MM/yyyy"
                    component={FormDatePicker}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                {/* <GridLayoutItem
                  colSpan={roasting_guid ? 2 : 3}
                ></GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="w-100"
                    name="previous_process_id"
                    label="Material Taken From Department"
                    placeholder="Material Taken From Department"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    astrike={true}
                    options={ProcessList?.map((process: any) => {
                      return {
                        value: process.id,
                        label: process.process_name,
                      };
                    })}
                  />
                </GridLayoutItem> */}
                <GridLayoutItem>
                  <Field
                    name="ready_product_id"
                    label="Ready Product"
                    placeholder="Product Name"
                    component={FormIncrementalSearch}
                    validator={requiredValidator}
                    astrike={true}
                    fetchIncrementalData={(search: any) =>
                      handleItemSearchChange(
                        search,
                        `item_options`,
                        formRenderProps
                      )
                    }
                    options={
                      formRenderProps
                        .valueGetter("item_options")
                        ?.map((item: any) => {
                          return {
                            value: item.id,
                            label: item.product_name,
                          };
                        }) || []
                    }
                    // onChange={(e) => handleItemChange(e, formRenderProps)}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="w-100"
                    name="lot_no"
                    label="Lot No."
                    placeholder="0"
                    component={FormTextField}
                    disabled={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="machine_id"
                    label="Machine No"
                    placeholder="Machine No"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    astrike={true}
                    options={MachineList?.map((machine: IMachine) => {
                      return {
                        value: machine?.id,
                        label: machine?.machine_name,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="right-alighned-field"
                    name="no_of_workers"
                    label="No Of Workers"
                    placeholder="0"
                    component={FormTextField}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                {/* <GridLayoutItem
                // style={{ display: "flex", gap: 10, alignItems: "start" }}
                >
                  <Field
                    // wrapperStyle={{ width: "50%" }}
                    name="grn_no"
                    label="GRN No"
                    placeholder="GRN No"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    astrike={true}
                    options={formRenderProps.valueGetter("grnNoOptions") || []}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="stock-label center-alighned-field d-flex flex-wrap justify-content-center"
                    wrapperStyle={{ color: "red" }}
                    name="current_stock"
                    label="Current Stock"
                    disabled={true}
                    placeholder="0"
                    component={FormNumericTextField}
                  />
                </GridLayoutItem> */}
                <GridLayoutItem
                // style={{ display: "flex", gap: 10 }}
                >
                  <Field
                    wrapperStyle={{ width: "100%" }}
                    name="start_time"
                    label="Start Time"
                    component={FormTimePicker}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperStyle={{ width: "100%" }}
                    name="end_time"
                    label="End Time"
                    component={FormTimePicker}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="process_id"
                    label="Material Issued to Department"
                    placeholder="Material Issued to Department"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    astrike={true}
                    options={ProcessList?.map((process: IProcess) => {
                      return {
                        value: process?.id,
                        label: process?.process_name,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="checker_by"
                    label="Roast Checker By"
                    placeholder="Checker Name"
                    component={FormSelectionField}
                    options={UserList?.map((user: IUser) => {
                      return {
                        value: user?.id,
                        label: `${user?.first_name} ${user?.last_name}`,
                      };
                    })}
                  />
                </GridLayoutItem>
                {/* <GridLayoutItem
                // colSpan={2}
                // style={{ display: "flex", gap: 10 }}
                >
                  <Field
                    wrapperClassName="w-100 right-alighned-field"
                    name="total_qty"
                    label="Total Qty (KG)"
                    placeholder="0"
                    min={0}
                    max={
                      roasting_guid
                        ? RoastingDetail?.total_qty +
                          formRenderProps.valueGetter("current_stock")
                        : formRenderProps.valueGetter("current_stock")
                    }
                    component={FormNumericTextField}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="w-100 right-alighned-field"
                    name="received_qty"
                    label="Received Qty (KG)"
                    placeholder="0"
                    min={0}
                    max={formRenderProps.valueGetter("total_qty")}
                    component={FormNumericTextField}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="w-100 center-alighned-field red-highlight-field d-flex flex-wrap justify-content-center"
                    name="process_loss_qty"
                    label="Roasting Loss Qty (KG)"
                    placeholder="0"
                    disabled={true}
                    component={FormNumericTextField}
                  />
                </GridLayoutItem> */}
                <GridLayoutItem colSpan={3}>
                  <Field
                    name="remarks"
                    label="Remarks"
                    placeholder="Remarks"
                    component={FormTextArea}
                    rows={2}
                  />
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>

            <ShadowCard style={{ padding: 12, marginTop: 10 }}>
              <GridLayout
                style={{ marginRight: 30 }}
                gap={{ rows: 10, cols: 10 }}
                cols={[
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                ]}
              >
                <GridLayoutItem colSpan={4}>
                  <Typography.h4>Item Details</Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem colSpan={4}>
                  <FieldArray
                    formRenderProps={formRenderProps}
                    component={RoastingItemDetailsArray}
                    handleItemSearchChange={handleItemSearchChange}
                    name="roasting_items"
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={4}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "end",
                    marginTop: 10,
                  }}
                >
                  <div>
                    <ButtonWithLoading
                      label={roasting_guid ? "Update" : "Save"}
                      type="submit"
                      disabled={!formRenderProps.allowSubmit || loading}
                      loading={loading}
                    />
                    <Button
                      type="button"
                      fillMode={"outline"}
                      themeColor={"primary"}
                      style={{ marginLeft: 4 }}
                      onClick={() => navigate("/roasting")}
                    >
                      Cancel
                    </Button>
                  </div>
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
            <QtychangeWatcher formRenderProps={formRenderProps} />
            <LotNoChangeWatcher formRenderProps={formRenderProps} />
            <TakenQtyWatcher formRenderProps={formRenderProps} />
            <InnerItemChangeWatcher formRenderProps={formRenderProps} />
            {/* <ItemChangeWatcher formRenderProps={formRenderProps} />
            <GRNChangeWatcher formRenderProps={formRenderProps} /> */}
            <UserChangeWatcher formRenderProps={formRenderProps} />
          </FormElement>
        )}
      />
    </>
  );
};

export default CreateRoasting;
