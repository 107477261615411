import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getAllUsers,
  createUser,
  updateUser,
  deleteUser,
  getUserByID,
  getAllActiveUsers,
  activeInActiveUsers,
  updateUserRights,
  getUserRightsByID,
  getAllActiveDrivers,
  changeUserPassword,
  resetUserPassword,
} from "./services/user.services";
import { IDriverList, IUser, IUserState } from "./userModel";

const initialState: IUserState = {
  loading: false,
  error: "",
  UserList: [],
  DriverList: [],
  UserID: null,
  UserDetails: {
    id: null,
    usertypeid: null,
  },
  FilterCustomUser: {
    name: "",
    email: "",
    mobile_no: "",
  },
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    clearUserDetails: (state) => {
      state.UserDetails = initialState.UserDetails;
    },
    setUserID: (state, action) => {
      state.UserID = action.payload;
    },
    clearUserID: (state) => {
      state.UserID = initialState.UserID;
    },
    setFilterCustomUser: (state, action) => {
      state.FilterCustomUser = action.payload;
    },
    clearFilterCustomUser: (state) => {
      state.FilterCustomUser = initialState.FilterCustomUser;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllUsers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getAllUsers.fulfilled,
      (state, action: PayloadAction<IUser[]>) => {
        state.loading = false;
        state.UserList = action.payload || [];
        state.error = "";
      }
    );
    builder.addCase(getAllUsers.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.UserList = [];
    });
    builder.addCase(createUser.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(createUser.fulfilled, (state) => {
      state.loading = true;
    });
    builder.addCase(createUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateUser.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateUser.fulfilled, (state) => {
      state.loading = true;
    });
    builder.addCase(updateUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateUserRights.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateUserRights.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateUserRights.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getUserByID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getUserByID.fulfilled, (state, action: any) => {
      state.loading = false;
      state.UserDetails = action.payload;
    });
    builder.addCase(getUserByID.rejected, (state, action) => {
      state.loading = false;
      state.UserDetails = initialState.UserDetails;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getUserRightsByID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getUserRightsByID.fulfilled, (state, action: any) => {
      state.loading = false;
      state.UserDetails = action.payload;
    });
    builder.addCase(getUserRightsByID.rejected, (state, action) => {
      state.loading = false;
      state.UserDetails = initialState.UserDetails;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteUser.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteUser.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getAllActiveUsers.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveUsers.fulfilled,
      (state, action: PayloadAction<IUser[]>) => {
        state.loading = false;
        state.UserList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveUsers.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.UserList = [];
    });
    builder.addCase(activeInActiveUsers.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(activeInActiveUsers.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(activeInActiveUsers.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getAllActiveDrivers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getAllActiveDrivers.fulfilled,
      (state, action: PayloadAction<IDriverList[]>) => {
        state.loading = false;
        state.DriverList = action.payload || [];
        state.error = "";
      }
    );
    builder.addCase(getAllActiveDrivers.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.DriverList = [];
    });

    builder.addCase(changeUserPassword.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(changeUserPassword.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(changeUserPassword.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(resetUserPassword.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(resetUserPassword.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(resetUserPassword.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});
export const {
  clearUserDetails,
  setUserID,
  clearUserID,
  setFilterCustomUser,
  clearFilterCustomUser,
} = userSlice.actions;
export default userSlice.reducer;
