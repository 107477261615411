import React, { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { LoadingPanel } from "../../../components/layout/Loading";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import { COMPANY_NAME } from "../../../_contstants/common";

import { getEmployeeByID } from "../../employee/services/employee.services";
import moment from "moment";

const NominationFormReport = () => {
  const state = JSON.parse(
    sessionStorage.getItem("nominationformState") || "{}"
  );
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);
  const loading = useAppSelector((state) => state.employee.loading);
  const EmployeeDetails = useAppSelector(
    (state) => state.employee.EmployeeDetails
  );

  const setPrintStyles = () => {
    const style = document.createElement("style");
    style.type = "text/css";
    style.innerHTML = `
      @media print {
        @page {
        border:1px solid #ccc;
          margin-left: 0.9cm;
          margin-right: 0.9cm;
        }
      }
    `;
    document.head.appendChild(style);
  };
  const fetchReport = async () => {
    const user_guid = state.user_guid;
    setPrintStyles();
    await dispatch(getEmployeeByID(user_guid));
  };

  useEffect(() => {
    const handleTabClose = () => {
      sessionStorage.removeItem("nominationformState");
    };
    fetchReport();
    return () => {
      window.removeEventListener("unload", handleTabClose);
    };
  }, []);

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      {EmployeeDetails && (
        <div>
          <ButtonWithLoading
            buttonStyle={{ position: "fixed", right: 0, top: 0, zIndex: 100 }}
            label={"Print"}
            className="no-visible-print-btn"
            type="button"
            onClick={() => window.print()}
          />
          <div
            style={{
              margin: "0 auto",
              fontSize: "11px",
            }}
          >
            <div
              style={{
                padding: "5px 7px 0px 5px",
              }}
            >
              <div
                style={{
                  textAlign: "center",
                }}
              >
                <p className="mb-0">The Payment of Gratuity Act 1972</p>
                <h6
                  style={{
                    letterSpacing: "5px",
                    fontWeight: "900",
                    fontSize: "15px",
                    marginBottom: "0px",
                  }}
                >
                  FORM 'F'
                </h6>
                <p style={{ marginBottom: "0px" }}>
                  [ See sub - rule (1) of rule (6)]
                </p>
                <p
                  className="mb-0"
                  style={{
                    fontWeight: "900",
                  }}
                >
                  NOMINATION
                </p>
              </div>
              <div className="company-to">
                <p className="mb-0">To,</p>
                <div>
                  <h6
                    className="mb-0"
                    style={{
                      fontSize: "15px",
                      paddingTop: "10px",
                      margin: "0px",
                    }}
                  >
                    {COMPANY_NAME}
                  </h6>
                  <p style={{ margin: "0" }}>
                    519/1, PHASE IV, VATVA, GIDC. AHMEDABAD-45
                  </p>
                </div>
              </div>
              <div className="nominee-name-details">
                <ol
                  style={{
                    paddingLeft: "5px",
                  }}
                >
                  <li>
                    Mr/Mrs/Ms.
                    <span
                      style={{
                        marginLeft: "20px",
                        fontWeight: "900",
                        fontSize: "13px",
                        textTransform: "uppercase",
                      }}
                    >
                      {EmployeeDetails?.first_name}&nbsp;
                      {EmployeeDetails?.middle_name}&nbsp;
                      {EmployeeDetails?.last_name}
                    </span>
                  </li>
                  <p
                    style={{
                      textIndent: "50px",
                    }}
                  >
                    Whose Particulars are given in the below, hereby nominate
                    the person(s) mentioned below to receive the gratuity
                    payable after my death as also gratuity standing to my
                    credit in the event of my death before the amount has become
                    payable, or having become payable has not been paid and
                    direct that the said amount of gratuity shall be paid in the
                    proportion indicated against the name (s) of the <br />{" "}
                    nominee (s).
                  </p>

                  <li>
                    I here by certify that the person(s) mentioned is/are a
                    member(s) of my family within the meaning of cluse (h) of
                    section 2 of Payment of Gratuity Act, 1972.
                  </li>

                  <li>
                    I hereby declare that i have no family within the meaning of
                    clause (h) of section 2 of said Act.
                  </li>

                  <li>
                    (a) My father/mother/parents is/are not dependent on me.
                  </li>

                  <li>
                    (b) My husband's Father/mother/parents is /are not dependent
                    on my husband.
                  </li>

                  <li>
                    I have excluded my husband from my family by a noticed dated
                    the _____________________ to the according authority in
                    terms of the previous to clause(h) of section 2 of said Act.
                  </li>

                  <li>
                    Nomination made here in invalid dates my previous nomination
                  </li>
                </ol>
              </div>
            </div>
            <div className="table-nominee">
              <h6
                style={{
                  fontWeight: "900",
                  fontSize: "14px",
                  textAlign: "center",
                }}
              >
                Nominee(s)
              </h6>
              <table style={{ width: "100%" }}>
                <tbody>
                  <tr>
                    <th
                      colSpan={4}
                      style={{
                        borderLeft: "0px",
                      }}
                    >
                      Name in full with full address <br /> of the nominee(s)
                    </th>
                    <th>Relationship with the Employee</th>
                    <th>Date of Birth/ Age</th>
                    <th
                      style={{
                        borderRight: "0",
                        margin: "0px",

                        width: "20%",
                      }}
                    >
                      Proportion by which the gratuity will be shared
                    </th>
                  </tr>
                  <tr>
                    <th
                      colSpan={4}
                      style={{
                        borderLeft: "0px",
                      }}
                    >
                      1
                    </th>
                    <th>2</th>
                    <th>3</th>
                    <th
                      style={{
                        borderRight: "0",
                      }}
                    >
                      4
                    </th>
                  </tr>
                  <tr style={{ textAlign: "start" }}>
                    <td
                      colSpan={2}
                      style={{
                        borderLeft: "0px",
                        width: "30%",
                        verticalAlign: "baseline",
                      }}
                    >
                      {EmployeeDetails?.nomination_name}
                    </td>
                    <td
                      colSpan={2}
                      style={{
                        padding: "0px 10px 0px 10px",
                        textTransform: "uppercase",
                      }}
                    >
                      {EmployeeDetails?.permenent_address} <br />
                      {EmployeeDetails?.permenent_street} <br />
                      {EmployeeDetails?.permenent_area} <br />
                      {EmployeeDetails?.permenent_city}-
                      {EmployeeDetails?.permenent_pincode} <br />
                      {EmployeeDetails?.permenent_state_name}&nbsp;
                    </td>
                    <td>{EmployeeDetails?.nomination_relation}</td>
                    <td>
                      {EmployeeDetails?.nomination_dob
                        ? moment(EmployeeDetails?.nomination_dob).format(
                            "DD/MM/YYYY"
                          )
                        : ""}
                    </td>
                    <td
                      style={{
                        borderRight: "0",
                      }}
                    >
                      {EmployeeDetails?.nomination_name ? "100%" : ""}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="nominee-employe-details">
              <ol
                style={{
                  paddingLeft: "10px",
                }}
              >
                <li>
                  <div>
                    <p>Name of employee in full :-</p>
                    <p
                      style={{
                        fontWeight: "900",
                        fontSize: "13px",
                        textTransform: "uppercase",
                      }}
                    >
                      {EmployeeDetails?.first_name}&nbsp;
                      {EmployeeDetails?.middle_name}&nbsp;
                      {EmployeeDetails?.last_name}
                    </p>
                  </div>
                </li>
                <li>
                  <div>
                    <p>Sex</p>
                    <p> {EmployeeDetails?.gender}</p>
                  </div>
                </li>
                <li>
                  <div>
                    <p>Religion</p>
                    <p> {EmployeeDetails?.religion}</p>
                  </div>
                </li>
                <li>
                  <div>
                    <p> Wheather unmarried/married/widow/widower : </p>
                    <p> {EmployeeDetails?.marital_status}</p>
                  </div>
                </li>
                <li>
                  <div>
                    <p>Department/Branch/Section where employed :</p>
                    <p> {EmployeeDetails?.department_name}</p>
                  </div>
                </li>
                <li>
                  <div>
                    <p>Poist held with Ticket or Serial No if any.</p>
                    <p> {EmployeeDetails?.employee_code_string}</p>
                  </div>
                </li>
                <li>
                  <div>
                    <p>Date of appoitment</p>
                    <p>
                      {EmployeeDetails?.date_of_joining
                        ? moment(EmployeeDetails?.date_of_joining).format(
                            "DD/MM/YYYY"
                          )
                        : ""}
                    </p>
                  </div>
                </li>
                <li>
                  <div>
                    <p>Permanent address</p>
                    <p
                      style={{
                        textTransform: "uppercase",
                      }}
                    >
                      {EmployeeDetails?.permenent_address}&nbsp;
                      {EmployeeDetails?.permenent_street}&nbsp;
                      {EmployeeDetails?.permenent_area}&nbsp;
                      {EmployeeDetails?.permenent_city}&nbsp;
                      {EmployeeDetails?.permenent_pincode}&nbsp;
                      {EmployeeDetails?.permenent_state_name}&nbsp;
                    </p>
                  </div>
                </li>
              </ol>
            </div>

            <div className="footer-place">
              <p>Place: Ahmedabad</p>
              <p> Date: </p>
              <p>Signature of thumb impression of the Employee </p>
            </div>
          </div>

          <div
            style={{
              fontSize: "13px",
            }}
            className="nomination-form-first-page"
          >
            <div
              style={{
                paddingBottom: "30px",
              }}
            >
              <h6
                style={{
                  textAlign: "center",
                  fontSize: "17px",
                  paddingTop: "20px",
                  fontWeight: "600",
                }}
              >
                Declaration by Witness
              </h6>
              <p className="mb-0" style={{ paddingLeft: "10px" }}>
                Nomination signed/thumb-impression before me
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: "11px",
                  paddingRight: "80px",
                }}
              >
                <p className="mb-0">Name & full address of witness </p>
                <p className="mb-0">Signature of witness</p>
              </div>
              <hr />

              <div
                style={{
                  display: "flex",
                  fontSize: "15px",
                  justifyContent: "space-between",
                  padding: "20px 80px 0px 10px",
                }}
              >
                <p>1.</p>
                <p
                  style={{
                    paddingTop: "30px",
                  }}
                >
                  _____________________________
                </p>
              </div>

              <div
                style={{
                  display: "flex",
                  fontSize: "15px",
                  justifyContent: "space-between",
                  padding: "20px 80px 30px 10px",
                }}
              >
                <p>2. </p>
                <p
                  style={{
                    paddingTop: "30px",
                  }}
                >
                  _____________________________
                </p>
              </div>
            </div>

            <div
              style={{
                borderTop: "1px solid #ccc",
                paddingBottom: "50px",
              }}
            >
              <h6
                style={{
                  textAlign: "center",
                  fontSize: "17px",
                  fontWeight: "600",
                  paddingTop: "10px",
                }}
              >
                Certificate by Employer
              </h6>
              <p style={{ textAlign: "center", fontSize: "12px" }}>
                Certified that the particulars of the above nomination have been
                verified and recorded in this establishment
              </p>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "40px 20px 48px 20px",
                }}
              >
                <div>Employer's Refrence No, if any</div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <span
                    className="mb-0"
                    style={{
                      paddingLeft: "20px",
                      paddingRight: "20px",
                      borderBottom: "1px solid #ccc",
                    }}
                  >
                    Signature of Employer/Officer/Authorised
                  </span>

                  <p
                    style={{
                      paddingLeft: "120px",
                      fontWeight: "600",
                      paddingTop: "10px",
                    }}
                  >
                    For HARIHAR FOODS PVT LTD
                  </p>
                </div>
              </div>
              <div
                style={{
                  paddingLeft: "20px",
                }}
              >
                <p className="mb-0">Place: Ahmedabad</p>
                <p>Date:</p>
              </div>
            </div>

            <div>
              <div style={{ borderTop: "1px solid #ccc" }}>
                <div
                  style={{
                    padding: "10px 40px 10px 10px",
                  }}
                >
                  <h6
                    style={{
                      textAlign: "center",
                      fontSize: "17px",
                      fontWeight: "600",
                    }}
                  >
                    Acknowledgement by the employer
                  </h6>
                  <p style={{ fontSize: "12px", paddingLeft: "10px" }}>
                    Receive the duplicate copy of nomination in Form 'F' field
                    by me and duly certified by the employer
                  </p>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingTop: "60px",
                      marginRight: "30px",
                      paddingLeft: "20px",
                    }}
                  >
                    <div>
                      <p className="mb-0">Place: Ahmedabad</p>
                      <p>Date:</p>
                    </div>
                    <div>
                      <p>Signature of the Employee</p>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",

                      padding: "50px 0px 40px 20px",
                    }}
                  >
                    <p
                      style={{
                        paddingTop: "20px",
                      }}
                    >
                      Note:- Strike out the words / paragraphs not applicable
                    </p>
                    <p>__________________________________</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default NominationFormReport;
