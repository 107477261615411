import { useEffect, useRef } from "react";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import { COMPANY_ADDRESS, COMPANY_NAME } from "../../../_contstants/common";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { getAllAdultWorkersReport } from "../services/reports.services";
import { LoadingPanel } from "../../../components/layout/Loading";

const FormNo15 = () => {
  const AdultWorkersReportList = useAppSelector(
    (state) => state.reports.AdultWorkersReportList
  );
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);
  const loading = useAppSelector((state) => state.reports.loading);

  useEffect(() => {
    const payload = {
      name: "",
      email: "",
      mobile_no: "",
    };
    dispatch(getAllAdultWorkersReport(payload));
  }, []);

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      <div
        style={{
          fontSize: "11px",
          padding: "10px",
        }}
      >
        <ButtonWithLoading
          buttonStyle={{ position: "fixed", right: 0, top: 0, zIndex: 100 }}
          label={"Print"}
          className="no-visible-print-btn"
          type="button"
          onClick={() => window.print()}
        />
        <div>
          <h6 className="m-0">{COMPANY_NAME}</h6>
          <p>{COMPANY_ADDRESS}</p>
        </div>
        <div
          style={{
            textAlign: "center",
            fontWeight: "900",
          }}
        >
          <h6 className="m-0">
            REGISTER OF ADULT WORKERS &nbsp; &nbsp; &nbsp; FORM NO.15
          </h6>
          <p
            style={{
              fontSize: "12px",
            }}
          >
            (Prescribed Under Rule 88)
          </p>
        </div>
        <div className="form-no15-table">
          <table
            style={{
              width: "100%",
            }}
          >
            <thead>
              <tr>
                <th rowSpan={2}>Sr. No</th>
                <th
                  rowSpan={2}
                  style={{
                    width: "15%",
                  }}
                >
                  Name
                </th>
                <th rowSpan={2}>
                  Date of <br /> Birth
                </th>
                <th rowSpan={2}>Sex</th>
                <th rowSpan={2} style={{ width: "16%" }}>
                  Residential <br /> Address
                </th>
                <th rowSpan={2} style={{ width: "13%" }}>
                  Father's/ <br /> Husband Name
                </th>
                <th rowSpan={2}>Date of Appoitment</th>
                <th colSpan={2}>Group to which worker belongs </th>
                <th rowSpan={2} style={{ width: "10%" }}>
                  Number of relay if working in shift
                </th>
                <th colSpan={2} style={{ width: "24%" }}>
                  Adolecent if <br /> certified
                </th>
                <th rowSpan={2}>Remarks</th>
              </tr>
              <tr>
                <th>Alphabet assigned</th>
                <th
                  style={{
                    width: "12%",
                  }}
                >
                  Nature of <br /> Work
                </th>
                <th style={{ width: "10%" }}>
                  No of date <br /> of certificate
                </th>
                <th style={{ width: "12%", padding: "0px" }}>
                  Token no under <br /> section 88
                </th>
              </tr>
              <tr>
                <th>1</th>
                <th>2</th>
                <th>3</th>
                <th>4</th>
                <th>5</th>
                <th>6</th>
                <th>7</th>
                <th>8</th>
                <th>9</th>
                <th>10</th>
                <th>11</th>
                <th>12</th>
                <th>13</th>
              </tr>
            </thead>
            <tbody>
              {AdultWorkersReportList && AdultWorkersReportList.length > 0 ? (
                AdultWorkersReportList.map((employee: any, index: number) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td
                      style={{
                        textAlign: "start",
                        width: "20%",
                      }}
                    >
                      {employee?.first_name} {employee?.middle_name}{" "}
                      {employee?.last_name}
                    </td>
                    <td>{employee?.dob}</td>
                    <td>{employee?.gender}</td>
                    <td
                      style={{
                        textAlign: "start",
                      }}
                    >
                      {employee?.permenent_address} &nbsp;
                      {employee?.permenent_street} &nbsp;
                      {employee?.permenent_area}
                      <br />
                      {employee?.permenent_city}
                      {employee.permenent_city ? "-" : ""}
                      {employee?.permenent_pincode}
                      &nbsp;
                      {employee?.permenent_state_name}&nbsp;
                    </td>
                    <td
                      style={{
                        textAlign: "start",
                      }}
                    >
                      {employee?.middle_name}
                    </td>
                    <td>{employee?.date_of_joining}</td>
                    <td></td>
                    <td
                      style={{
                        textAlign: "start",
                      }}
                    >
                      {employee?.designation_name}
                    </td>
                    <td></td>
                    <td>- </td>
                    <td>-</td>
                    <td></td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default FormNo15;
