import React, { useEffect } from "react";
import {
  Field,
  FieldArrayRenderProps,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { Typography } from "@progress/kendo-react-common";
import IconButton from "../../components/common/IconButton";
import { MdDelete } from "react-icons/md";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import { useLocation } from "react-router-dom";
import FormNumericTextField from "../../components/formFields/FormNumericTextField";
import FormIncrementalSearch from "../../components/formFields/FormIncrementalSearch";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { axiosApiInstance } from "../../app/axios";
import { API_URL } from "../../app/config";
import { ErrorToast, SuccessToast } from "../../components/toast/Toasts";
import FormTextField from "../../components/formFields/FormTextField";
import { setSTMDeleteIndex } from "./stockTransferMultiSlice";
import FormDatePicker from "../../components/formFields/FormDateField";

const StockTransferMultiItemArray = (
  fieldArrayRenderProps: FieldArrayRenderProps
) => {
  const dispatch = useAppDispatch();
  const InputRef = React.useRef<any>(null);
  const location = useLocation();
  const stock_transfer_guid = location.state?.stock_transfer_guid;

  const STMDeleteIndex = useAppSelector(
    (state) => state.stockTransferMulti.STMDeleteIndex
  );
  const StockTransferDetail = useAppSelector(
    (state) => state.stockTransferMulti.StockTransferDetail
  );
  const dialogName = useAppSelector((state) => state.dialog.dialogName);

  useEffect(() => {
    if (!stock_transfer_guid) {
      pushElementInarray();
    }
  }, []);

  const handleOpenDeleteDialog = (index: number) => {
    dispatch(openDialog("stmItemDeleteDialog"));
    dispatch(setSTMDeleteIndex(index));
  };

  //   const handleReceivedQtyChange = (
  //     e: any,
  //     fieldArrayRenderProps: FieldArrayRenderProps
  //   ) => {
  //     const total_qty =
  //       fieldArrayRenderProps.formRenderProps.valueGetter("total_qty");
  //     const stock_item_options =
  //       fieldArrayRenderProps.formRenderProps.valueGetter(
  //         "stock_item_options"
  //       );

  //     const rejection_qty = total_qty - e?.value || 0;

  //     if (stock_item_options && stock_item_options.length > 0) {
  //       stock_item_options?.map((item: any, index: number) => {
  //         fieldArrayRenderProps.formRenderProps.onChange(
  //           `stock_item_options.${index}.wastage_qty`,
  //           {
  //             value:
  //               rejection_qty == 0.0
  //                 ? "0.000"
  //                 : rejection_qty /
  //                   (stock_item_options &&
  //                   typeof stock_item_options === "object" &&
  //                   stock_item_options?.length > 0
  //                     ? stock_item_options?.length
  //                     : 0),
  //           }
  //         );
  //       });
  //     }
  //   };

  const handleDeleteAction = React.useCallback(
    async (index: number) => {
      if (fieldArrayRenderProps?.value[index].id) {
        try {
          const response = await axiosApiInstance.post(
            `${API_URL}/StockTransfer/DeleteMultipleStockTransferItem`,
            { id: fieldArrayRenderProps?.value[index].id }
          );
          fieldArrayRenderProps.onRemove({ index: index });
          dispatch(closeDialog());
          dispatch(setSTMDeleteIndex(-1));
          SuccessToast(response.data?.Details || "Success");
        } catch (error: any) {
          ErrorToast(error?.response?.data?.Details || "Something went wrong");
          console.error("Error deleting Item:", error);
          return Promise.reject({
            message: error?.response?.data?.Details || "Something went wrong",
          });
        }
      } else {
        fieldArrayRenderProps.onRemove({ index: index });
        dispatch(closeDialog());
        dispatch(setSTMDeleteIndex(-1));
      }
    },
    [fieldArrayRenderProps]
  );

  const pushElementInarray = React.useCallback(() => {
    fieldArrayRenderProps.onPush({
      value: {
        item_id: null,
        grn_no: null,
        current_qty: null,
        stock_qty: null,
        vaccal_no: "",
        katta: null,
        cold_storage_receipt_no: "",
        inward_no: null,
        inward_date: "",
      },
    });

    setTimeout(() => {
      InputRef.current?.element?.children[0]?.children[1]?.children[0]?.focus();
    }, 0);
  }, [fieldArrayRenderProps]);

  //   const fetchGRNOptions = useCallback(
  //     async (index: number, item: any) => {
  //       if (item?.item_id) {
  //         const grnPayload = {
  //           item_id: item.item_id,
  //           process_id: 1,
  //           warehouse_id: 1,
  //         };
  //         const response = await dispatch(getGRNByItemID(grnPayload));
  //         const result =
  //           response.payload
  //             ?.filter((grn: any) => grn.item_id === item.item_id)
  //             .map((grnno: any) => ({
  //               value: `${grnno?.grn_no}`,
  //               label: `${grnno?.grn_no} - ${formatIndianNumberForQty(
  //                 grnno?.current_stock || 0
  //               )}`,
  //             })) || [];
  //         fieldArrayRenderProps?.formRenderProps.onChange(
  //           `stock_item_options.${index}.grnNoOptions`,
  //           {
  //             value: result,
  //           }
  //         );
  //       } else {
  //         fieldArrayRenderProps?.formRenderProps.onChange(
  //           `stock_item_options.${index}.grnNoOptions`,
  //           {
  //             value: [],
  //           }
  //         );
  //         fieldArrayRenderProps?.formRenderProps.onChange(
  //           `stock_item_options.${index}.grn_no`,
  //           {
  //             value: null,
  //           }
  //         );
  //       }
  //     },
  //     [dispatch, fieldArrayRenderProps?.formRenderProps]
  //   );

  //   const fetchCurrentStock = useCallback(
  //     async (index: number, item: any) => {
  //       if (item?.item_id && item?.grn_no) {
  //         const payload = {
  //           item_id: +item.item_id,
  //           grn_no: item.grn_no,
  //           process_id: 1,
  //           warehouse_id: 1,
  //         };
  //         const response = await dispatch(getCurrentStock(payload));
  //         fieldArrayRenderProps?.formRenderProps.onChange(
  //           `stock_item_options.${index}.current_qty`,
  //           {
  //             value: response.payload?.actual_stock || 0,
  //           }
  //         );
  //       }
  //     },
  //     [dispatch, fieldArrayRenderProps?.formRenderProps]
  //   );

  //   const handleFieldChange = (field: string, index: number, item: any) => {
  //     switch (field) {
  //       case "item_id":
  //         fetchGRNOptions(index, item);
  //         fetchCurrentStock(index, item);
  //         break;
  //       case "grn_no":
  //         fetchCurrentStock(index, item);
  //         break;
  //       default:
  //         break;
  //     }
  //   };

  //   const debouncedHandleProductChange = React.useRef(
  //     _.debounce((field: any, index: number, item: any) => {
  //       handleFieldChange(field, index, item);
  //     }, 300)
  //   ).current;

  useEffect(() => {
    return () => {
      fieldArrayRenderProps.debouncedHandleProductChange.cancel();
    };
  }, []);

  const handleChange = (
    field: any,
    index: number,
    item: any,
    formRenderProps: FormRenderProps
  ) => {
    fieldArrayRenderProps.debouncedHandleProductChange(
      field,
      index,
      item,
      formRenderProps,
      true
    );
  };

  return (
    <>
      <GridLayout
        cols={[{ width: "100%" }]}
        style={{ overflowX: "scroll", scrollbarWidth: "thin" }}
      >
        <GridLayoutItem
          className="bg-dark text-white fw-600"
          style={{ width: 1800 }}
        >
          <GridLayout
            style={{
              marginRight: "0.5%",
              padding: 10,
            }}
            gap={{ rows: 10, cols: 5 }}
            cols={[
              { width: "15%" },
              { width: "10%" },
              { width: "10%" },
              { width: "10%" },
              { width: "10%" },
              { width: "10%" },
              { width: "10%" },
              { width: "10%" },
              { width: "10%" },
              { width: "3%" },
            ]}
          >
            <GridLayoutItem>Item</GridLayoutItem>
            <GridLayoutItem>GRN NO</GridLayoutItem>
            <GridLayoutItem>Current Stock</GridLayoutItem>
            <GridLayoutItem>Qty</GridLayoutItem>
            <GridLayoutItem>Vacal No</GridLayoutItem>
            <GridLayoutItem>Katta</GridLayoutItem>
            <GridLayoutItem>Receipt No.</GridLayoutItem>
            <GridLayoutItem>Inward No.</GridLayoutItem>
            <GridLayoutItem>Inward Date</GridLayoutItem>
            <GridLayoutItem></GridLayoutItem>
          </GridLayout>
        </GridLayoutItem>
        {dialogName === "stmItemDeleteDialog" && (
          <AppDialog>
            <>
              <Typography.h5>{"Delete Item"}</Typography.h5>
              <GridLayout>
                <GridLayoutItem>
                  <Typography.p>
                    Are you sure you want to delete this?
                  </Typography.p>
                </GridLayoutItem>
                <GridLayoutItem
                  style={{
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <DialogCloseButton themeColor="error" />
                  <Button
                    fillMode={"solid"}
                    themeColor={"error"}
                    onClick={() => handleDeleteAction(STMDeleteIndex)}
                    type="button"
                  >
                    Delete
                  </Button>
                </GridLayoutItem>
              </GridLayout>
            </>
          </AppDialog>
        )}
        {fieldArrayRenderProps.value &&
          fieldArrayRenderProps.value.length > 0 &&
          fieldArrayRenderProps.value.map(
            (stock_item_options: any, index: number) => (
              <GridLayoutItem key={index} style={{ width: 1800 }}>
                {/* <Card className="fieldarray-card" style={{ padding: 10 }}> */}
                <GridLayout
                  style={{
                    marginRight: "0.5%",
                    padding: 10,
                    position: "relative",
                  }}
                  gap={{ rows: 10, cols: 5 }}
                  cols={[
                    { width: "15%" },
                    { width: "10%" },
                    { width: "10%" },
                    { width: "10%" },
                    { width: "10%" },
                    { width: "10%" },
                    { width: "10%" },
                    { width: "10%" },
                    { width: "10%" },
                    { width: "3%" },
                  ]}
                >
                  <GridLayoutItem ref={InputRef}>
                    <Field
                      wrapperStyle={{ margin: 0 }}
                      name={`stock_item_options.${index}.item_id`}
                      placeholder="Product"
                      isAddNew={true}
                      addNewLink="item/create"
                      component={FormIncrementalSearch}
                      validator={requiredValidator}
                      onChange={() =>
                        handleChange(
                          `item_id`,
                          index,
                          fieldArrayRenderProps.value[index],
                          fieldArrayRenderProps.formRenderProps
                        )
                      }
                      fetchIncrementalData={(search: any) =>
                        fieldArrayRenderProps.handleItemSearchChange(
                          search,
                          `stock_item_options.${index}.item_options`,
                          fieldArrayRenderProps.formRenderProps
                        )
                      }
                      options={
                        fieldArrayRenderProps.value[index]?.item_options?.map(
                          (item: any) => {
                            return {
                              value: item.id,
                              label: item.product_name,
                            };
                          }
                        ) || []
                      }
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperStyle={{ margin: 0 }}
                      name={`stock_item_options.${index}.grn_no`}
                      placeholder="GRN No"
                      component={FormSelectionField}
                      onChange={() =>
                        handleChange(
                          `grn_no`,
                          index,
                          fieldArrayRenderProps.value[index],
                          fieldArrayRenderProps?.formRenderProps
                        )
                      }
                      validator={requiredValidator}
                      options={
                        fieldArrayRenderProps.value[index]?.grnNoOptions || []
                      }
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperClassName="stock-label right-alighned-field"
                      wrapperStyle={{ margin: 0 }}
                      name={`stock_item_options.${index}.current_qty`}
                      placeholder="0"
                      format="n3"
                      disabled={true}
                      component={FormNumericTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperClassName="right-alighned-field"
                      wrapperStyle={{ margin: 0 }}
                      name={`stock_item_options.${index}.stock_qty`}
                      max={
                        stock_transfer_guid &&
                        StockTransferDetail?.stock_item_options[index]
                          ?.stock_qty
                          ? StockTransferDetail?.stock_item_options[index]
                              ?.stock_qty +
                            fieldArrayRenderProps.formRenderProps.valueGetter(
                              `stock_item_options.${index}.current_qty`
                            )
                          : fieldArrayRenderProps.formRenderProps.valueGetter(
                              `stock_item_options.${index}.current_qty`
                            )
                      }
                      placeholder="0"
                      type="number"
                      format="n3"
                      minNumber="0"
                      validator={requiredValidator}
                      component={FormNumericTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperStyle={{ margin: 0 }}
                      name={`stock_item_options.${index}.vaccal_no`}
                      placeholder="Vacal No"
                      component={FormTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperClassName="right-alighned-field"
                      wrapperStyle={{ margin: 0 }}
                      name={`stock_item_options.${index}.katta`}
                      placeholder="0"
                      component={FormNumericTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperStyle={{ margin: 0 }}
                      name={`stock_item_options.${index}.cold_storage_receipt_no`}
                      placeholder="Receipt No"
                      component={FormTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperStyle={{ margin: 0 }}
                      name={`stock_item_options.${index}.inward_no`}
                      placeholder="Inward No"
                      component={FormNumericTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperStyle={{ margin: 0 }}
                      name={`stock_item_options.${index}.inward_date`}
                      format="dd/MM/yyyy"
                      component={FormDatePicker}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem
                    style={{
                      textAlign: "end",
                    }}
                  >
                    <IconButton
                      onClick={() => handleOpenDeleteDialog(index)}
                      disabled={fieldArrayRenderProps.value.length === 1}
                      themeColor={"error"}
                      size={"small"}
                      fillMode={"solid"}
                      type="button"
                      style={{
                        height: 32,
                        width: 32,
                      }}
                    >
                      <MdDelete
                        className="absolute-position"
                        style={{ fontSize: "16px" }}
                      />
                    </IconButton>
                  </GridLayoutItem>
                </GridLayout>
              </GridLayoutItem>
            )
          )}
      </GridLayout>
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{ marginTop: 10, borderTop: "1px solid lightgray" }}
        >
          <GridLayout
            style={{ marginRight: 25, padding: 10 }}
            gap={{ rows: 10, cols: 5 }}
            cols={[
              { width: "25%" },
              { width: "14%" },
              { width: "12%" },
              { width: "16%" },
              { width: "16%" },
              { width: "12%" },
              { width: "5%" },
            ]}
          >
            <GridLayoutItem colSpan={2} className="d-flex align-items-end">
              <Button
                fillMode={"solid"}
                themeColor={"primary"}
                onClick={pushElementInarray}
                type="button"
              >
                Add Row
              </Button>
            </GridLayoutItem>
            {/* <GridLayoutItem
              className="d-flex justify-content-end align-items-center fw-600"
              colSpan={3}
            >
              Total Qty
            </GridLayoutItem>
            <GridLayoutItem colSpan={2}>
              <Field
                wrapperClassName="right-alighned-field"
                wrapperStyle={{ margin: 0 }}
                name="total_qty"
                placeholder="0"
                format="n3"
                component={FormNumericTextField}
                disabled={true}
              />
            </GridLayoutItem>
            <GridLayoutItem
              colSpan={5}
              className="d-flex justify-content-end align-items-start fw-600 mt-1"
            >
              Received Qty <span className="text-danger">&nbsp;*</span>
            </GridLayoutItem>
            <GridLayoutItem colSpan={2}>
              <Field
                wrapperClassName=" right-alighned-field"
                wrapperStyle={{ margin: 0 }}
                name="received_qty"
                placeholder="0"
                format="n3"
                max={fieldArrayRenderProps.formRenderProps.valueGetter(
                  "total_qty"
                )}
                onChange={(e: any) =>
                  handleReceivedQtyChange(e, fieldArrayRenderProps)
                }
                component={FormNumericTextField}
                validator={requiredValidator}
              />
            </GridLayoutItem>
            <GridLayoutItem
              colSpan={5}
              className="d-flex justify-content-end align-items-center fw-600"
            >
              Cleaning Loss Qty
            </GridLayoutItem>
            <GridLayoutItem colSpan={2}>
              <Field
                wrapperClassName="w-100 right-alighned-field red-highlight-field d-flex flex-column align-items-center"
                wrapperStyle={{ margin: 0 }}
                name="rejection_qty"
                format="n3"
                placeholder="0"
                disabled={true}
                component={FormNumericTextField}
              />
            </GridLayoutItem>
            <GridLayoutItem
              colSpan={5}
              className="d-flex justify-content-end align-items-center fw-600"
            >
              Cleaning Loss Qty (%)
            </GridLayoutItem>
            <GridLayoutItem colSpan={2}>
              <div className="w-100">
                <Field
                  wrapperStyle={{ margin: 0 }}
                  wrapperClassName="red-highlight-field right-alighned-field d-flex flex-wrap justify-content-center"
                  name="cleaning_loss_percentage"
                  // label="Cleaning Loss Qty (%)"
                  placeholder="0"
                  disabled={true}
                  component={FormTextField}
                />
              </div>
            </GridLayoutItem> */}
          </GridLayout>
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

export default StockTransferMultiItemArray;
