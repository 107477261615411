import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICompetitors, ICompetitorsState } from "./competitorsModel";
import {
  competitorsActiveInactive,
  createCompetitors,
  deleteCompetitors,
  getAllActiveCompetitors,
  getAllCompetitors,
  getCompetitorsByID,
  updateCompetitors,
} from "./services/competitorsServices";

const initialState: ICompetitorsState = {
  loading: false,
  formLoading: false,
  error: "",
  CompetitorsList: [],
  CompetitorsID: null,
  CompetitorsDetail: {
    competitors: "",
    isactive: 1,
  },
  FilterCompetitors: {
    competitors: "",
  },
};

const competitorsSlice = createSlice({
  name: "competitors",
  initialState,
  reducers: {
    clearCompetitorsDetails: (state) => {
      state.CompetitorsDetail = initialState.CompetitorsDetail;
    },
    setCompetitorsID: (state, action) => {
      state.CompetitorsID = action.payload;
    },
    clearCompetitorsID: (state) => {
      state.CompetitorsID = initialState.CompetitorsID;
    },
    setFilterCompetitors: (state, action) => {
      state.FilterCompetitors = action.payload;
    },
    clearFilterCompetitors: (state) => {
      state.FilterCompetitors = initialState.FilterCompetitors;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllCompetitors.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllCompetitors.fulfilled,
      (state, action: PayloadAction<ICompetitors[]>) => {
        state.loading = false;
        state.CompetitorsList = action.payload || [];
      }
    );
    builder.addCase(getAllCompetitors.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.CompetitorsList = [];
    });
    builder.addCase(getAllActiveCompetitors.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveCompetitors.fulfilled,
      (state, action: PayloadAction<ICompetitors[]>) => {
        state.loading = false;
        state.CompetitorsList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveCompetitors.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.CompetitorsList = [];
    });
    builder.addCase(createCompetitors.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(createCompetitors.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createCompetitors.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateCompetitors.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateCompetitors.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateCompetitors.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteCompetitors.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteCompetitors.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteCompetitors.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(competitorsActiveInactive.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(competitorsActiveInactive.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(competitorsActiveInactive.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getCompetitorsByID.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getCompetitorsByID.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.CompetitorsDetail = action.payload;
    });
    builder.addCase(getCompetitorsByID.rejected, (state, action) => {
      state.formLoading = false;
      state.CompetitorsDetail = initialState.CompetitorsDetail;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearCompetitorsDetails,
  setCompetitorsID,
  clearCompetitorsID,
  clearFilterCompetitors,
  setFilterCompetitors,
} = competitorsSlice.actions;
export default competitorsSlice.reducer;
