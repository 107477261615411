import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  createPayment,
  deletePayment,
  getAllOutstandingPayment,
  getAllPayment,
  getPaymentByID,
  updatePayment,
} from "./services/payment.services";
import { IPayment, IPaymentInitialState } from "./paymentModel";
import moment from "moment";

const initialState: IPaymentInitialState = {
  loading: false,
  error: "",
  PaymentEntryDeleteIndex: -1,
  PaymentBillDialogOpenIndex: -1,

  PaymentID: null,
  PaymentGUID: "",
  PaymentList: [],
  OutstandingPaymentList: [],
  PaymentDetail: {
    financial_year: null,
    payment_date: moment().toDate(),
    payment_total_amount: null,
    remarks: "",
    ledger_id: null,
    payment_entry: [],
  },
};

const PaymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    setPaymentID: (state, action) => {
      state.PaymentID = action.payload;
    },
    setPaymentGUID: (state, action) => {
      state.PaymentGUID = action.payload;
    },
    clearPaymentDetails: (state) => {
      state.PaymentDetail = initialState.PaymentDetail;
    },
    clearOutstandingPaymentList: (state) => {
      state.OutstandingPaymentList = initialState.OutstandingPaymentList;
    },
    setPaymentEntryDeleteIndex: (state, action) => {
      state.PaymentEntryDeleteIndex = action.payload;
    },
    setPaymentBillDialogOpenIndex: (state, action) => {
      state.PaymentBillDialogOpenIndex = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllPayment.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllPayment.fulfilled,
      (state, action: PayloadAction<IPayment[]>) => {
        state.loading = false;
        state.PaymentList = action.payload || [];
      }
    );
    builder.addCase(getAllPayment.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.PaymentList = [];
    });

    builder.addCase(createPayment.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(createPayment.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createPayment.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(updatePayment.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updatePayment.fulfilled, (state) => {
      state.loading = true;
    });
    builder.addCase(updatePayment.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(deletePayment.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deletePayment.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deletePayment.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getPaymentByID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getPaymentByID.fulfilled, (state, action: any) => {
      state.loading = false;
      state.PaymentDetail = action.payload;
    });
    builder.addCase(getPaymentByID.rejected, (state, action) => {
      state.loading = false;
      state.PaymentDetail = initialState.PaymentDetail;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getAllOutstandingPayment.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllOutstandingPayment.fulfilled,
      (state, action: any) => {
        state.loading = false;
        state.OutstandingPaymentList = action.payload;
      }
    );
    builder.addCase(getAllOutstandingPayment.rejected, (state, action) => {
      state.loading = false;
      state.OutstandingPaymentList = initialState.OutstandingPaymentList;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearPaymentDetails,
  setPaymentEntryDeleteIndex,
  setPaymentID,
  setPaymentBillDialogOpenIndex,
  setPaymentGUID,

  clearOutstandingPaymentList,
} = PaymentSlice.actions;
export default PaymentSlice.reducer;
