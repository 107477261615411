import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { ICompetitors } from "../competitorsModel";

export const getAllCompetitors = createAsyncThunk(
  "Competitors/FindAllCompetitors",
  async (formdata:any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Competitors/FindAllCompetitors`,
        formdata
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Competitors:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveCompetitors = createAsyncThunk(
  "Competitors/FindAllActiveCompetitors",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Competitors/FindAllActiveCompetitors`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Competitors:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createCompetitors = createAsyncThunk(
  "Competitors/InsertCompetitors",
  async (CompetitorsData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Competitors/InsertCompetitors`,
        CompetitorsData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating Competitors:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateCompetitors = createAsyncThunk(
  "Competitors/UpdateCompetitors",
  async (CompetitorsData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Competitors/UpdateCompetitors`,
        CompetitorsData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating CompetitorsData:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getCompetitorsByID = createAsyncThunk(
  "Competitors/FindByIDCompetitors",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Competitors/FindByIDCompetitors`,
        { competitors_guid: ID }
      );
      const result = response.data?.Data as any;
      return {
        id: result.id,
        competitors_guid: result.competitors_guid,
        competitors: result.competitors,
        isactive: result.isactive === true ? 1 : 2,
      } as ICompetitors;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching   Competitors:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const competitorsActiveInactive = createAsyncThunk(
  "Competitors/ActiveInActiveCompetitors",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Competitors/ActiveInActiveCompetitors`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive Competitors:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteCompetitors = createAsyncThunk(
  "Competitors/DeleteCompetitors",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Competitors/DeleteCompetitors`,
        { competitors_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting Competitors:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
