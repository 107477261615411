import React, { useCallback, useEffect } from "react";
import { Field, FieldArrayRenderProps } from "@progress/kendo-react-form";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { Typography } from "@progress/kendo-react-common";
import IconButton from "../../components/common/IconButton";
import { MdDelete } from "react-icons/md";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { useLocation } from "react-router-dom";
import { IUnit } from "../unit/unitModel";
import { IWarehouse } from "../warehouse/warehouseModel";
import { setInwardReturnDeleteIndex } from "./inwardReturnSlice";
import FormFilterSelectionField from "../../components/formFields/FormFilterSelectionField";
import { getAllInwardGRN } from "./services/inwardReturn.services";
import { API_URL } from "../../app/config";
import { ErrorToast, SuccessToast } from "../../components/toast/Toasts";
import { axiosApiInstance } from "../../app/axios";
import FormNumericTextField from "../../components/formFields/FormNumericTextField";
import { getItemById } from "../Item/services/item.services";
import { IInwardItemList } from "./inwardReturnModel";
import { FINANCIAL_YEAR } from "../../_contstants/common";

const InwardReturnItemDetailsArray = (
  fieldArrayRenderProps: FieldArrayRenderProps
) => {
  const dispatch = useAppDispatch();
  const InputRef = React.useRef<any>(null);
  const location = useLocation();
  // const isItemUnitRef = React.useRef(true);
  const inward_return_guid = location.state?.inward_return_guid;

  const InwardReturnDeleteIndex = useAppSelector(
    (state) => state.inwardReturn.InwardReturnDeleteIndex
  );
  const InwardReturnDetail = useAppSelector(
    (state) => state.inwardReturn.InwardReturnDetail
  );
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const ItemList = useAppSelector((state) => state.inwardReturn.ItemList);
  const UnitList = useAppSelector((state) => state.unit.UnitList);
  const warehouseList = useAppSelector(
    (state) => state.warehouse.warehouseList
  );

  useEffect(() => {
    if (inward_return_guid) {
      const inwardItems = InwardReturnDetail?.inward_return_items || [];
      inwardItems?.forEach((mainItem: any, index: number) => {
        const matchingItems = ItemList?.filter(
          (item: any) => item?.item_id === mainItem?.item_id
        );
        if (matchingItems.length === 1) {
          fetchGRNOptions(index, matchingItems[0]);
        }
      });
    } else {
      ItemList.forEach((item: any, index: number) => {
        if (item) {
          fetchGRNOptions(index, item);
        }
      });
    }
  }, [ItemList]);

  const fetchItemDetails = async (
    item_id: number,
    index: number,
    fieldArrayRenderProps: FieldArrayRenderProps
  ) => {
    if (item_id) {
      const payload = {
        id: item_id,
      };
      const res = (await dispatch(getItemById(payload))) as any;
      fieldArrayRenderProps.formRenderProps.onChange(
        `inward_return_items.${index}.unit_id`,
        {
          value: res.payload?.unit_id,
        }
      );
      fieldArrayRenderProps.formRenderProps.onChange(
        `inward_return_items.${index}.unit_decimal`,
        {
          value: res.payload?.unit_decimal,
        }
      );
    } else {
      fieldArrayRenderProps.formRenderProps.onChange(
        `inward_return_items.${index}.unit_id`,
        {
          value: null,
        }
      );
      fieldArrayRenderProps.formRenderProps.onChange(
        `inward_return_items.${index}.unit_decimal`,
        {
          value: null,
        }
      );
    }
  };

  const fetchGRNOptions = useCallback(
    async (innerIndex: number, item: any) => {
      const inward_return_items =
        fieldArrayRenderProps.formRenderProps.valueGetter(
          "inward_return_items"
        );
      const bill_no =
        fieldArrayRenderProps.formRenderProps.valueGetter("bill_no");
      if (item?.item_id) {
        // if (!isItemUnitRef.current) {
        //   const payload = {
        //     id: item?.item_id,
        //   };

        //   const res = (await dispatch(getItemById(payload))) as any;
        //   inward_return_items &&
        //     inward_return_items.length > 0 &&
        //     inward_return_items.map((item: any, index: number) => {
        //       if (innerIndex === index) {
        //         if (res.meta.requestStatus === "fulfilled") {
        //           fieldArrayRenderProps.formRenderProps.onChange(
        //             `inward_return_items.${index}.unit_id`,
        //             {
        //               value: res.payload?.unit_id,
        //             }
        //           );
        //           fieldArrayRenderProps.formRenderProps.onChange(
        //             `inward_return_items.${index}.unit_decimal`,
        //             {
        //               value: res.payload?.unit_decimal || 0,
        //             }
        //           );
        //         } else {
        //           fieldArrayRenderProps.formRenderProps.onChange(
        //             `inward_return_items.${index}.unit_id`,
        //             {
        //               value: null,
        //             }
        //           );
        //           fieldArrayRenderProps.formRenderProps.onChange(
        //             `inward_return_items.${index}.unit_decimal`,
        //             {
        //               value: 0,
        //             }
        //           );
        //         }
        //       }
        //     });
        // } else {
        //   isItemUnitRef.current = false;
        // }

        if (bill_no) {
          const grnPayload: any = {
            inward_id: bill_no,
            item_id: item?.item_id,
            financial_year: FINANCIAL_YEAR,
          };

          const response: any = await dispatch(getAllInwardGRN(grnPayload));
          if (response && response.payload) {
            const result = response.payload.filter(
              (grn: any) => grn?.item_id === item?.item_id
            );
            const options = result?.map((grnno: any) => ({
              value: `${grnno?.grn_no}`,
              label: `${grnno?.grn_no}`,
            }));
            fieldArrayRenderProps.formRenderProps.onChange(
              `inward_return_items.${innerIndex}.grnNoOptions`,
              {
                value: options,
              }
            );
          } else {
            fieldArrayRenderProps.formRenderProps.onChange(
              `inward_return_items.${innerIndex}.grnNoOptions`,
              {
                value: [],
              }
            );
            fieldArrayRenderProps.formRenderProps.onChange(
              `inward_return_items.${innerIndex}.grn_no`,
              {
                value: null,
              }
            );
          }
        } else {
          inward_return_items &&
            inward_return_items.length > 0 &&
            inward_return_items.map((item: any, index: number) => {
              if (innerIndex === index) {
                fieldArrayRenderProps.formRenderProps.onChange(
                  `inward_return_items.${index}.unit_id`,
                  {
                    value: null,
                  }
                );
                fieldArrayRenderProps.formRenderProps.onChange(
                  `inward_return_items.${index}.grn_no`,
                  {
                    value: null,
                  }
                );
              }
            });
        }
      }
    },
    [dispatch, fieldArrayRenderProps?.formRenderProps]
  );

  const handleOpenDeleteDialog = (index: number) => {
    dispatch(openDialog("inwardReturnItemDeleteDialog"));
    dispatch(setInwardReturnDeleteIndex(index));
  };

  useEffect(() => {
    if (!inward_return_guid) {
      pushElementInarray();
    }
  }, []);

  const handleDeleteAction = React.useCallback(
    async (index: number) => {
      if (fieldArrayRenderProps?.value[index].id) {
        try {
          const response = await axiosApiInstance.post(
            `${API_URL}/InwardReturn/DeleteInwardReturnItem`,
            { id: fieldArrayRenderProps?.value[index].id }
          );
          fieldArrayRenderProps.onRemove({ index: index });
          dispatch(closeDialog());
          dispatch(setInwardReturnDeleteIndex(-1));
          SuccessToast(response.data?.Details || "Success");
        } catch (error: any) {
          ErrorToast(error?.response?.data?.Details || "Something went wrong");
          console.error("Error deleting Item:", error);
          return Promise.reject({
            message: error?.response?.data?.Details || "Something went wrong",
          });
        }
      } else {
        fieldArrayRenderProps.onRemove({ index: index });
        dispatch(closeDialog());
        dispatch(setInwardReturnDeleteIndex(-1));
      }
    },
    [fieldArrayRenderProps]
  );

  const handleFieldChange = (field: string, index: number, item: any) => {
    switch (field) {
      case "item_id":
        fetchGRNOptions(index, item);
        break;
      default:
        break;
    }
  };

  const pushElementInarray = React.useCallback(() => {
    fieldArrayRenderProps.onPush({
      value: {
        inward_return_id: null,
        item_id: null,
        grn_no: null,
        weight: null,
        unit_id: null,
        rate: null,
        dagina: null,
        total_amount: null,
        warehouse_id: null,
      },
    });

    setTimeout(() => {
      InputRef.current?.element?.children[0]?.children[1]?.children[0]?.focus();
    }, 0);
  }, [fieldArrayRenderProps]);

  return (
    <GridLayout cols={[{ width: "100%" }]}>
      <GridLayoutItem className="bg-dark text-white fw-600">
        <GridLayout
          style={{ marginRight: 40, padding: 10 }}
          gap={{ rows: 10, cols: 5 }}
          cols={[
            { width: "20%" },
            { width: "10%" },
            { width: "10%" },
            { width: "12%" },
            { width: "10%" },
            { width: "10%" },
            { width: "10%" },
            { width: "15%" },
            { width: "3%" },
          ]}
        >
          <GridLayoutItem>Item Name</GridLayoutItem>
          <GridLayoutItem>GRN No</GridLayoutItem>
          <GridLayoutItem>Weight</GridLayoutItem>
          <GridLayoutItem>Unit</GridLayoutItem>
          <GridLayoutItem>Rate</GridLayoutItem>
          <GridLayoutItem>Dagina</GridLayoutItem>
          <GridLayoutItem>Amount</GridLayoutItem>
          <GridLayoutItem>Warehouse</GridLayoutItem>
        </GridLayout>
      </GridLayoutItem>
      {dialogName === "inwardReturnItemDeleteDialog" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Item"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(InwardReturnDeleteIndex)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
      {fieldArrayRenderProps.value &&
        fieldArrayRenderProps.value.length > 0 &&
        fieldArrayRenderProps.value.map(
          (inward_return_items: any, index: number) => (
            <GridLayoutItem key={index} style={{ padding: 10 }}>
              {/* <Card className="fieldarray-card" style={{ padding: 10 }}> */}
              <GridLayout
                style={{ marginRight: 40 }}
                gap={{ rows: 10, cols: 5 }}
                cols={[
                  { width: "20%" },
                  { width: "10%" },
                  { width: "10%" },
                  { width: "12%" },
                  { width: "10%" },
                  { width: "10%" },
                  { width: "10%" },
                  { width: "15%" },
                  { width: "3%" },
                ]}
              >
                <GridLayoutItem ref={InputRef}>
                  <Field
                    wrapperStyle={{ margin: 0 }}
                    name={`inward_return_items.${index}.item_id`}
                    placeholder="Item"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={ItemList?.map((item: IInwardItemList) => {
                      return {
                        value: item?.item_id,
                        label: item?.item_name,
                      };
                    })}
                    onChange={(e: any) => {
                      handleFieldChange(
                        `item_id`,
                        index,
                        fieldArrayRenderProps.value[index]
                      );
                      fetchItemDetails(e?.value, index, fieldArrayRenderProps);
                    }}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperStyle={{ margin: 0 }}
                    name={`inward_return_items.${index}.grn_no`}
                    placeholder="GRN No"
                    component={FormFilterSelectionField}
                    validator={requiredValidator}
                    options={
                      fieldArrayRenderProps.value[index]?.grnNoOptions || []
                    }
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="right-alighned-field"
                    wrapperStyle={{ margin: 0 }}
                    name={`inward_return_items.${index}.weight`}
                    placeholder="0"
                    format={
                      fieldArrayRenderProps.value[index]?.unit_decimal &&
                      fieldArrayRenderProps.value[index]?.unit_decimal > 0
                        ? `n${fieldArrayRenderProps.value[index]?.unit_decimal}`
                        : "n0"
                    }
                    validator={requiredValidator}
                    component={FormNumericTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperStyle={{ margin: 0 }}
                    name={`inward_return_items.${index}.unit_id`}
                    placeholder="Unit"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={UnitList?.map((unit: IUnit) => {
                      return {
                        value: unit?.id,
                        label: unit?.unit_code,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="right-alighned-field"
                    wrapperStyle={{ margin: 0 }}
                    name={`inward_return_items.${index}.rate`}
                    placeholder="0"
                    format="n3"
                    minNumber="0"
                    validator={requiredValidator}
                    component={FormNumericTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="right-alighned-field"
                    wrapperStyle={{ margin: 0 }}
                    name={`inward_return_items.${index}.dagina`}
                    placeholder="0"
                    type="number"
                    minNumber="0"
                    component={FormNumericTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="child-calculation-total right-alighned-field"
                    wrapperStyle={{ margin: 0 }}
                    name={`inward_return_items.${index}.total_amount`}
                    placeholder="0"
                    disabled="true"
                    format="n2"
                    component={FormNumericTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperStyle={{ margin: 0 }}
                    name={`inward_return_items.${index}.warehouse_id`}
                    placeholder="Warehouse"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={warehouseList?.map((warehouse: IWarehouse) => {
                      return {
                        value: warehouse?.id,
                        label: warehouse?.warehouse_name,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem style={{ position: "relative" }}>
                  <IconButton
                    onClick={() => handleOpenDeleteDialog(index)}
                    disabled={fieldArrayRenderProps.value.length === 1}
                    themeColor={"error"}
                    size={"small"}
                    fillMode={"solid"}
                    type="button"
                    style={{
                      position: "absolute",
                      height: 32,
                      width: 32,
                      top: 1,
                      right: 0,
                    }}
                  >
                    <MdDelete
                      className="absolute-position"
                      style={{ fontSize: "16px" }}
                    />
                  </IconButton>
                </GridLayoutItem>
              </GridLayout>
              {/* </Card> */}
            </GridLayoutItem>
          )
        )}

      <GridLayoutItem
        style={{ marginTop: 10, borderTop: "1px solid lightgray" }}
      >
        <GridLayout
          style={{ marginRight: 40, padding: 10 }}
          gap={{ rows: 10, cols: 5 }}
          cols={[
            { width: "20%" },
            { width: "10%" },
            { width: "10%" },
            { width: "12%" },
            { width: "10%" },
            { width: "10%" },
            { width: "10%" },
            { width: "15%" },
            { width: "3%" },
          ]}
        >
          <GridLayoutItem className="d-flex align-items-end" colSpan={2}>
            <Button
              fillMode={"solid"}
              themeColor={"primary"}
              onClick={pushElementInarray}
              type="button"
            >
              Add Row
            </Button>
          </GridLayoutItem>
          <GridLayoutItem
            colSpan={4}
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              fontWeight: 600,
              fontSize: 17,
            }}
          >
            Total Amount :
          </GridLayoutItem>
          {/* <GridLayoutItem className="d-flex justify-content-end align-items-center">
            Total
          </GridLayoutItem> */}
          <GridLayoutItem>
            <Field
              wrapperClassName="parent-calculation-label right-alighned-field"
              wrapperStyle={{ margin: 0 }}
              name="total_amount"
              format="n2"
              component={FormNumericTextField}
              disabled="true"
            />
          </GridLayoutItem>
        </GridLayout>
      </GridLayoutItem>
    </GridLayout>
  );
};

export default InwardReturnItemDetailsArray;
