import React, { useEffect, useRef } from "react";
import { getEmployeeRegisterReport } from "../services/reports.services";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { LoadingPanel } from "../../../components/layout/Loading";
// import { MONTHS_MAP } from "../../../_contstants/common";
// import { convertToWords } from "../../../_helper/helper";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import { COMPANY_ADDRESS, COMPANY_NAME } from "../../../_contstants/common";

const EmployeeRegister = () => {
  const state = JSON.parse(
    sessionStorage.getItem("employeeRegisterState") || "{}"
  );

  const setPrintStyles = () => {
    const style = document.createElement("style");
    style.type = "text/css";
    style.innerHTML = `
      @media print {
        @page {
          margin-left: 0.5cm;
          margin-right: 0.5cm;
        }
      }
    `;
    document.head.appendChild(style);
  };

  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);
  const loading = useAppSelector((state) => state.reports.loading);

  const EmployeeRegisterReportList = useAppSelector(
    (state) => state.reports.EmployeeRegisterReportList
  );
  const fetchReport = async () => {
    const payload = {
      department_id: state?.department_id,
    };
    await dispatch(getEmployeeRegisterReport(payload));
    setPrintStyles();

    setTimeout(() => {
      window.print();
    }, 2000);
  };

  useEffect(() => {
    const handleTabClose = () => {
      sessionStorage.removeItem("employeeRegisterState");
    };
    fetchReport();
    return () => {
      window.removeEventListener("unload", handleTabClose);
    };
  }, []);

  const style: Record<string, React.CSSProperties> = {
    headingText: {
      fontSize: "14px",
      fontWeight: "bold",
      margin: 0,
    },
    headingTextSub: {
      fontSize: "12px",
      margin: 0,
    },
    hr: { margin: "1rem 0", borderTop: "1px dashed #000" },
  };
  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      {EmployeeRegisterReportList && EmployeeRegisterReportList.length > 0 && (
        <div>
          <ButtonWithLoading
            buttonStyle={{ position: "fixed", right: 0, top: 0, zIndex: 100 }}
            label={"Print"}
            className="no-visible-print-btn"
            type="button"
            onClick={() => window.print()}
          />
          <div className="printMargin">
            <div className="d-flex justify-content-between ">
              <div className="text-start">
                <p style={style.headingText}> {COMPANY_NAME}</p>
                <p style={style.headingTextSub}>{COMPANY_ADDRESS}</p>
              </div>
            </div>
            <div className="d-flex align-items-end justify-content-between">
              <div style={{ width: "25%" }}>
                <p style={style.headingText}>Department :-</p>
              </div>
              <div style={{ textAlign: "center", width: "50%" }}>
                <p style={style.headingText}>SCHEDULE</p>
                <p style={style.headingTextSub}>[See Rules 2 (1)]</p>
                <p style={style.headingText}>FORM A</p>
                <p style={style.headingText}>EMPLOYEE REGISTER</p>
              </div>
              <div style={{ textAlign: "start", width: "25%" }}>
                <p style={style.headingText}>LIN No :-</p>
              </div>
            </div>
            <table className="employee-register-print-table">
              <thead>
                <tr>
                  <th>Sr. No</th>
                  <th style={{ textAlign: "start", lineHeight: "14px" }}>
                    <p>Employee Name</p>
                    <p>Employee Father Name</p>
                    <p>Employee Surname</p>
                    <br />
                    <p
                      className="d-flex align-items-center justify-content-between gap-5"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      <span>Sr.No</span>
                      <span>Employee Code</span>
                    </p>
                  </th>
                  <th>Sex</th>
                  <th>Birth Date</th>
                  <th>
                    <p>Nationality</p>
                    <p>Education Level</p>
                  </th>
                  <th>Date Of Joining</th>
                  <th>
                    <p>Designation</p>
                    <p>Category Add. (HS/S/SS/US)</p>
                  </th>
                  <th style={{ fontSize: "9px" }}>Type of Employment</th>
                  <th>
                    <p style={{ whiteSpace: "nowrap" }}>Mobile No.</p>
                    <p>UAN No</p>
                    <p>PAN No</p>
                    <p>AADHAR</p>
                    <p>ESIC IP</p>
                    <p>WC</p>
                  </th>
                  <th>
                    <p style={{ whiteSpace: "nowrap" }}>Bank A/c No</p>
                    <p>Bank Name</p>
                    <p>Branch</p>
                    <p>AADHAR</p>
                    <p>IFSC</p>
                  </th>
                  <th>
                    <p style={{ whiteSpace: "nowrap" }}>Present Add.</p>
                    <p>Permanent Add.</p>
                  </th>
                  <th>Service Book No</th>
                  <th>
                    <p>Date of Exit</p>
                    <p>Reason of Exit</p>
                  </th>
                  <th> Mark of Identifi cation</th>
                  <th>Photo</th>
                  <th>
                    <p>Specimen</p>
                    <p>Signature/ Thumb Impression</p>
                    <p>Remarks</p>
                  </th>
                </tr>
              </thead>
              <tbody>
                {EmployeeRegisterReportList.map((item: any, index: number) => (
                  <tr key={index} className="employee-register-print-table-row">
                    <td style={{ textAlign: "center" }}>{index + 1}</td>
                    <td
                      style={{
                        textAlign: "start",
                        lineHeight: "14px",
                        fontSize: "12px",
                      }}
                    >
                      <p style={{ textTransform: "uppercase" }}>
                        {item?.first_name}
                      </p>
                      <p style={{ textTransform: "uppercase" }}>
                        {item?.middle_name}
                      </p>
                      <p style={{ textTransform: "uppercase" }}>
                        {item?.last_name}
                      </p>
                      <br />
                      <p
                        className="d-flex align-items-center justify-content-between gap-3"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        <span>{item?.id}</span>
                        <span>{item?.employee_code_string}</span>
                      </p>
                    </td>
                    <td>{item?.gender}</td>
                    <td>{item?.dob}</td>
                    <td>
                      <p style={{ textAlign: "center" }}>{item?.nationality}</p>
                      <br />
                      {/* <p>{item?.education_level}</p> */}
                    </td>
                    <td>{item?.date_of_joining}</td>
                    <td>
                      <p>{item?.designation_name}</p>
                      <p>{item?.category}</p>
                    </td>
                    <td>Contract</td>
                    <td style={{ fontSize: "9px", textTransform: "uppercase" }}>
                      <p style={{ whiteSpace: "nowrap" }}>
                        {item?.mobile_no ? item?.mobile_no : "-"}
                      </p>
                      <p>{item?.uan_no ? item?.uan_no : "-"}</p>
                      <p style={{ textTransform: "uppercase" }}>
                        {item?.pan_card ? item?.pan_card : "-"}
                      </p>
                      <p>{item?.adharcard ? item?.adharcard : "-"}</p>
                      {/* <p>{item?.esic_ip ?? "-"}</p>
                      <p>{item?.wc ?? "-"}</p> */}
                    </td>
                    <td style={{ textTransform: "uppercase", fontSize: "9px" }}>
                      <p style={{ whiteSpace: "nowrap" }}>
                        {item?.account_no ? item?.account_no : "-"}
                      </p>
                      <p>{item?.bank_name ? item?.bank_name : "-"}</p>
                      <p>{item?.bank_branch ? item?.bank_branch : "-"}</p>
                      <p>{item?.ifsc_code ? item?.ifsc_code : "-"}</p>
                    </td>
                    <td style={{ lineHeight: "11px" }}>
                      <p
                        style={{
                          fontSize: "9px",
                          textTransform: "uppercase",
                        }}
                      >
                        {[
                          item?.address,
                          item?.street,
                          item?.area,
                          item?.city ? `${item.city}-${item.pincode}` : "",
                          item?.state_name,
                        ]
                          .filter(Boolean)
                          .join(", ")}
                      </p>
                      {/* {item?.address && item?.permenent_address ? (
                        <hr style={{ margin: "3px 0" }} />
                      ) : (
                        ""
                      )}
                      <p
                        style={{
                          fontSize: "9px",
                          textTransform: "uppercase",
                        }}
                      >
                        {[
                          item?.permenent_address,
                          item?.permenent_street,
                          item?.permenent_area,
                          item?.permenent_city
                            ? `${item.permenent_city}${
                                item.permenent_pincode
                                  ? `-${item.permenent_pincode}`
                                  : ""
                              }`
                            : "",
                          item?.permenent_state_name,
                        ]
                          .filter(Boolean)
                          .join(", ")}
                      </p> */}
                    </td>
                    <td></td>
                    <td>
                      <p>{item?.left_date}</p>
                      <br />
                      <p style={{ textTransform: "uppercase" }}>
                        {item?.reason_of_exit}
                      </p>
                    </td>
                    <td></td>
                    <td style={{ verticalAlign: "middle" }}>
                      <div className="d-flex justify-content-center align-items-center">
                        {item?.user_image ? (
                          <img
                            style={{
                              width: "70px",
                              objectFit: "cover",
                            }}
                            src={item?.user_image}
                            alt="Employee Image"
                          />
                        ) : (
                          "No Image"
                        )}
                      </div>
                    </td>
                    <td
                    // style={{ paddingRight: "20px", paddingLeft: "20px" }}
                    ></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
};

export default EmployeeRegister;
