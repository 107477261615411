import React, { useEffect } from "react";
import { Field, FieldArrayRenderProps } from "@progress/kendo-react-form";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { Typography } from "@progress/kendo-react-common";

import FormSelectionField from "../../components/formFields/FormSelectionField";

import FormNumericTextField from "../../components/formFields/FormNumericTextField";
import FormIncrementalSearch from "../../components/formFields/FormIncrementalSearch";
// import { axiosApiInstance } from "../../app/axios";
// import { API_URL } from "../../app/config";
// import { ErrorToast, SuccessToast } from "../../components/toast/Toasts";

import {
  // clearOutstandingReceiptList,
  setReceiptBillDialogOpenIndex,
  setReceiptEntryDeleteIndex,
} from "./receiptSlice";
import { getAllOutstandingReceipt } from "./services/receipt.services";
import { useLocation } from "react-router-dom";
import {
  requiredValidator,
  skipValidator,
} from "../../components/formFields/CommonValidator";
// import { getAllOutstandingReceipt } from "./services/receipt.services";
// import { getUnitById } from "../unit/services/unit.services";

const ReceiptEntryDetailsArray = (
  fieldArrayRenderProps: FieldArrayRenderProps
) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  // const debit_note_guid = location.state?.debit_note_guid;
  const receipt_guid = location.state?.receipt_guid;
  const ReceiptEntryDeleteIndex = useAppSelector(
    (state) => state.receipt.ReceiptEntryDeleteIndex
  );

  const InputRef = React.useRef<any>(null);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);

  // const handleOpenDeleteDialog = (index: number) => {
  //   dispatch(openDialog("debitNoteItemDeleteDialog"));
  //   dispatch(setReceiptDeleteIndex(index));
  // };

  const handleDeleteAction = React.useCallback(
    async (index: number) => {
      fieldArrayRenderProps.onRemove({ index: index });
      dispatch(closeDialog());
      dispatch(setReceiptEntryDeleteIndex(-1));
      const element = document.getElementsByName(
        `receipt_entry.${index - 1}.amount`
      );
      if (element?.[0]) {
        element?.[0]?.focus();
      }
    },
    [fieldArrayRenderProps]
  );

  const pushElementInarrayFirst = React.useCallback(() => {
    fieldArrayRenderProps.onPush({
      value: {
        debit_credit: "Credit",
        ledger_id: "",
        amount: null,
        receipt_entry_referance: [],
      },
    });

    // setTimeout(() => {
    //   InputRef.current?.element?.children[0]?.children[1]?.children[0]?.focus();
    // }, 0);
  }, [fieldArrayRenderProps]);

  const pushElementInarray = React.useCallback(
    (debitAmountTotal: number) => {
      fieldArrayRenderProps.onPush({
        value: {
          debit_credit: "Debit",
          ledger_id: "",
          amount: debitAmountTotal,
          receipt_entry_referance: [],
        },
      });

      // setTimeout(() => {
      //   InputRef.current?.element?.children[0]?.children[1]?.children[0]?.focus();
      // }, 0);
    },
    [fieldArrayRenderProps]
  );

  const handleSearchLedger = (search: any, index: any) => {
    if (search) {
      fieldArrayRenderProps.handleVendorSearchChange(
        search,
        `receipt_entry.${index}.ledger_options`,
        fieldArrayRenderProps.formRenderProps
      );
    }
  };

  const handleCrediDebitChange = (
    index: number,
    fieldArrayRenderProps: any
  ) => {
    fieldArrayRenderProps.formRenderProps.onChange(
      `receipt_entry.${index}.receipt_entry_referance`,
      {
        value: [],
      }
    );
    fieldArrayRenderProps.formRenderProps.onChange(
      `receipt_entry.${index}.ledger_id`,
      {
        value: null,
      }
    );
    const debit_credit = fieldArrayRenderProps.formRenderProps.valueGetter(
      `receipt_entry.${index}.debit_credit`
    );

    if (debit_credit === "Credit") {
      fieldArrayRenderProps.formRenderProps.onChange(
        `receipt_entry.${index}.amount`,
        {
          value: null,
        }
      );
    }
    dispatch(setReceiptBillDialogOpenIndex(-1));
  };
  const handleChange = (index: number, fieldArrayRenderProps: any) => {
    fieldArrayRenderProps.formRenderProps.onChange(
      `receipt_entry.${index}.receipt_entry_referance`,
      {
        value: [],
      }
    );
    const debit_credit = fieldArrayRenderProps.formRenderProps.valueGetter(
      `receipt_entry.${index}.debit_credit`
    );

    if (debit_credit === "Credit") {
      fieldArrayRenderProps.formRenderProps.onChange(
        `receipt_entry.${index}.amount`,
        {
          value: null,
        }
      );
    }

    dispatch(setReceiptBillDialogOpenIndex(-1));
  };
  const handleKeyEvent = (
    e: any,
    index: number,
    fieldArrayRenderProps: FieldArrayRenderProps
  ) => {
    const value = parseFloat(e?.target?.value?.replace(/,/g, ""));
    const DebitTotal =
      (Array.isArray(fieldArrayRenderProps?.value) &&
        fieldArrayRenderProps?.value.length > 0 &&
        fieldArrayRenderProps.value?.[0]?.db_total?.DebitTotal) ||
      null;

    const CreditTotal =
      (Array.isArray(fieldArrayRenderProps?.value) &&
        fieldArrayRenderProps?.value.length > 0 &&
        fieldArrayRenderProps.value?.[0]?.db_total?.CreditTotal) ||
      null;
    if (
      e.key === "Tab" &&
      value &&
      fieldArrayRenderProps.value[index].ledger_id
    ) {
      if (!e.shiftKey) {
        const isCredit =
          fieldArrayRenderProps.value[index]?.debit_credit === "Credit";
        // const isCredit =
        //   fieldArrayRenderProps.value[index]?.debit_credit === "Credit";

        if (isCredit) {
          const payloadFindBill = {
            receipt_guid: receipt_guid ? receipt_guid : null,
            credit_or_debit: "CR",
            ledger_id: fieldArrayRenderProps.value[index].ledger_id,
          };
          const fetchBillList = async () => {
            const IsReceiptRefID =
              fieldArrayRenderProps.value[index].receipt_entry_referance?.[0]
                ?.receipt_ref_id;
            if (
              fieldArrayRenderProps.value[index].receipt_entry_referance
                .length > 0 &&
              !IsReceiptRefID
            ) {
              dispatch(setReceiptBillDialogOpenIndex(index));
              dispatch(openDialog("CreditBillItemDialog"));
            } else {
              const response = await dispatch(
                getAllOutstandingReceipt(payloadFindBill)
              );
              if (response.meta.requestStatus === "fulfilled") {
                dispatch(openDialog("CreditBillItemDialog"));
                dispatch(setReceiptBillDialogOpenIndex(index));
              }
            }
          };
          fetchBillList();
          if (
            fieldArrayRenderProps.value.length === index + 1 &&
            DebitTotal !== CreditTotal
          ) {
            pushElementInarray(CreditTotal - DebitTotal);
          }
        } else {
          if (
            fieldArrayRenderProps.value.length === index + 1 &&
            DebitTotal !== CreditTotal
          ) {
            pushElementInarray(CreditTotal - DebitTotal);
          }
        }
      }
    }
    if (
      e.key === "Escape" &&
      fieldArrayRenderProps.value.length !== 1 &&
      index !== 0
    ) {
      dispatch(openDialog("CreditReceiptItemDeleteDialog"));
      dispatch(setReceiptEntryDeleteIndex(index));
    }
  };

  useEffect(() => {
    if (fieldArrayRenderProps.value?.length === 0) {
      pushElementInarrayFirst();
    }
  }, []);

  const PAYMENT_INITIAL = [
    {
      label: "Cr",
      value: "Credit",
    },

    {
      label: "Dr",
      value: "Debit",
    },
  ];

  return (
    <>
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem className="bg-dark text-white fw-600">
          <GridLayout
            style={{
              padding: 10,
              marginRight: 10,
            }}
            gap={{ rows: 10, cols: 5 }}
            cols={[
              { width: "12%" },
              { width: "58%" },
              { width: "15%" },
              { width: "15%" },
            ]}
          >
            <GridLayoutItem>Initial</GridLayoutItem>
            <GridLayoutItem>Ledger Name</GridLayoutItem>
            <GridLayoutItem>Debit</GridLayoutItem>
            <GridLayoutItem>Credit</GridLayoutItem>
          </GridLayout>
        </GridLayoutItem>

        {dialogName === "CreditReceiptItemDeleteDialog" && (
          <AppDialog>
            <>
              <Typography.h5>{"Delete Item"}</Typography.h5>
              <GridLayout>
                <GridLayoutItem>
                  <Typography.p>
                    Are you sure you want to delete this?
                  </Typography.p>
                </GridLayoutItem>
                <GridLayoutItem
                  style={{
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <Button
                    fillMode={"solid"}
                    themeColor={"error"}
                    onClick={() => handleDeleteAction(ReceiptEntryDeleteIndex)}
                    type="button"
                    style={{ marginRight: 5 }}
                  >
                    Delete
                  </Button>
                  <DialogCloseButton themeColor="error" />
                </GridLayoutItem>
              </GridLayout>
            </>
          </AppDialog>
        )}
        {fieldArrayRenderProps.value &&
          fieldArrayRenderProps.value.length > 0 &&
          fieldArrayRenderProps.value.map(
            (receipt_entry: any, index: number) => (
              <GridLayoutItem key={index}>
                <GridLayout
                  style={{
                    marginRight: 10,
                    padding: 10,
                  }}
                  gap={{ rows: 10, cols: 5 }}
                  cols={[
                    { width: "12%" },
                    { width: "58%" },
                    { width: "15%" },
                    { width: "15%" },
                  ]}
                >
                  <GridLayoutItem>
                    <Field
                      // wrapperClassName="right-alighned-field"
                      wrapperStyle={{ margin: 0 }}
                      name={`receipt_entry.${index}.debit_credit`}
                      id={`receipt_entry.${index}.debit_credit_id`}
                      format="n2"
                      component={FormSelectionField}
                      disabled={index === 0}
                      onChange={() =>
                        handleCrediDebitChange(index, fieldArrayRenderProps)
                      }
                      options={PAYMENT_INITIAL.map((init: any) => {
                        return {
                          value: init?.value,
                          label: init?.label,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem ref={InputRef}>
                    <Field
                      wrapperStyle={{ margin: 0 }}
                      name={`receipt_entry.${index}.ledger_id`}
                      placeholder="search ledger"
                      // isAddNew={true}
                      // addNewLink="account/create"
                      component={FormIncrementalSearch}
                      onChange={() =>
                        handleChange(index, fieldArrayRenderProps)
                      }
                      fetchIncrementalData={(search: any) =>
                        handleSearchLedger(search, index)
                      }
                      validator={
                        fieldArrayRenderProps.value[index].amount
                          ? requiredValidator
                          : skipValidator
                      }
                      options={
                        fieldArrayRenderProps.value[index]?.ledger_options?.map(
                          (item: any) => {
                            return {
                              value: item?.id,
                              label: item?.account_name,
                            };
                          }
                        ) || []
                      }
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    {fieldArrayRenderProps.value[index]?.debit_credit ===
                      "Debit" && (
                      <Field
                        wrapperClassName="right-alighned-field"
                        wrapperStyle={{ margin: 0 }}
                        name={`receipt_entry.${index}.amount`}
                        placeholder="0"
                        format="n2"
                        component={FormNumericTextField}
                        onKeyDown={(e: any) =>
                          handleKeyEvent(e, index, fieldArrayRenderProps)
                        }
                      />
                    )}
                  </GridLayoutItem>
                  <GridLayoutItem>
                    {fieldArrayRenderProps.value[index]?.debit_credit ===
                      "Credit" && (
                      <Field
                        wrapperClassName="right-alighned-field"
                        wrapperStyle={{ margin: 0 }}
                        name={`receipt_entry.${index}.amount`}
                        placeholder="0"
                        format="n2"
                        component={FormNumericTextField}
                        onKeyDown={(e: any) =>
                          handleKeyEvent(e, index, fieldArrayRenderProps)
                        }
                      />
                    )}
                  </GridLayoutItem>
                </GridLayout>
              </GridLayoutItem>
            )
          )}

        <GridLayoutItem style={{ borderTop: "1px solid #ccc", marginTop: 10 }}>
          <GridLayout
            style={{
              marginRight: 10,
              padding: 10,
            }}
            gap={{ rows: 10, cols: 5 }}
            cols={[
              { width: "8%" },
              { width: "62%" },
              { width: "15%" },
              { width: "15%" },
            ]}
          >
            <GridLayoutItem colSpan={2}></GridLayoutItem>
            <GridLayoutItem>
              <h5
                style={{
                  textAlign: "end",
                  fontWeight: "bold",
                }}
              >
                ₹{" "}
                {(Array.isArray(fieldArrayRenderProps?.value) &&
                  fieldArrayRenderProps?.value.length > 0 &&
                  fieldArrayRenderProps.value?.[0]?.db_total?.DebitTotal) ||
                  0}
              </h5>
            </GridLayoutItem>
            <GridLayoutItem>
              <h5
                style={{
                  textAlign: "end",
                  fontWeight: "bold",
                }}
              >
                ₹{" "}
                {(Array.isArray(fieldArrayRenderProps?.value) &&
                  fieldArrayRenderProps?.value.length > 0 &&
                  fieldArrayRenderProps.value?.[0]?.db_total?.CreditTotal) ||
                  0}
              </h5>
            </GridLayoutItem>
          </GridLayout>
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

export default ReceiptEntryDetailsArray;
