import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import moment from "moment";
import { IPacking } from "../packingModel";

export const getAllPacking = createAsyncThunk(
  "Packing/FindAllPacking",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Packing/FindAllPacking`,
        formData
      );
      return response.data?.Data;
    } catch (error: any) {
      console.error("Error Fetching Packing:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createPacking = createAsyncThunk(
  "Packing/InsertPacking",
  async (Packing: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Packing/InsertPacking`,
        Packing
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Packing:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updatePacking = createAsyncThunk(
  "Packing/UpdatePacking",
  async (Packing: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Packing/UpdatePacking`,
        Packing
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Packing:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getPackingById = createAsyncThunk(
  "Packing/FindByIDPacking",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Packing/FindByIDPacking`,
        formData
      );
      // SuccessToast(response.data?.Details || "Success")
      const result = response.data?.Data as any;
      return {
        id: result?.id,
        packing_guid: result?.packing_guid,
        packing_no: result?.packing_no,
        packing_date: result?.packing_date
          ? moment(result?.packing_date, "YYYY-MM-DD").toDate()
          : "",
        raw_item: result?.raw_item,
        raw_item_options: result?.raw_item_options || [],
        raw_item_name: result?.raw_item_name,
        lot_no: result?.lot_no,
        packing_item: result?.packing_item,
        packing_item_options: result?.packing_item_options || [],
        packing_item_name: result?.packing_item_name,
        batch_no: result?.batch_no,
        process_id: result?.process_id,
        loss_percentage: result?.loss_percentage,
        taken_qty: result?.taken_qty,
        used_qty: result?.used_qty,
        wastage_qty: result?.wastage_qty,
        ready_qty_in_nos: result?.ready_qty_in_nos,
        mfg_date: result?.mfg_date
          ? moment(result?.mfg_date, "YYYY-MM-DD").toDate()
          : "",
        exp_date: result?.exp_date
          ? moment(result?.exp_date, "YYYY-MM-DD").toDate()
          : "",
        remarks: result?.remarks,
        verify_by: result?.verify_by,
        financial_year: result?.financial_year,
        packing_item_details: result?.packing_item_details
          ? result?.packing_item_details?.map((packing: any) => {
              return {
                id: packing?.id ? packing?.id : null,
                item_id: packing?.item_id ? packing?.item_id : null,
                used_qty: packing?.used_qty ? packing?.used_qty : null,
                item_options: packing?.item_options
                  ? packing?.item_options?.map((item: any) => {
                      return {
                        packing_item_id: item?.id,
                        packing_item_name: item?.product_name,
                      };
                    })
                  : [],
              };
            })
          : [],
      } as IPacking;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Packing:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deletePacking = createAsyncThunk(
  "Packing/DeletePacking",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Packing/DeletePacking`,
        {
          packing_guid: ID,
        }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Deleting Packing:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getPackingItemCurrentStock = createAsyncThunk(
  "Packing/FindByPackingItemStock",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Packing/FindByPackingItemStock`,
        { item_id: ID }
      );
      return response.data?.Data as any;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching current stcok:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
