import { useEffect, useRef } from "react";

import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  getAccountStatustoryByUserID,
  getEmployeeByID,
  getFamilyMemberByUserID,
} from "../../employee/services/employee.services";
import { LoadingPanel } from "../../../components/layout/Loading";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import { COMPANY_ADDRESS, COMPANY_NAME } from "../../../_contstants/common";

const Form5view = () => {
  const state = JSON.parse(
    sessionStorage.getItem("nominationanddeclarationformState") || "{}"
  );
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);

  const loading = useAppSelector((state) => state.employee.loading);

  const setPrintStyles = () => {
    const style = document.createElement("style");
    style.type = "text/css";
    style.innerHTML = `
          @media print {
            @page {
            margin-left: 0.9cm;
            margint-top:0.2cm
            margin-right: 0.9cm;
            }
          }
        `;
    document.head.appendChild(style);
  };
  const fetchReport = async () => {
    const user_guid = state.user_guid;
    const id = state.id;
    setPrintStyles();
    await dispatch(getEmployeeByID(user_guid));
    await dispatch(getAccountStatustoryByUserID(user_guid));
    await dispatch(getFamilyMemberByUserID(id));
  };

  useEffect(() => {
    const handleTabClose = () => {
      sessionStorage.removeItem("nominationanddeclarationformState");
    };
    fetchReport();
    return () => {
      window.removeEventListener("unload", handleTabClose);
    };
  }, []);

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      <div
        style={{
          fontSize: "11px",
        }}
      >
        <ButtonWithLoading
          buttonStyle={{ position: "fixed", right: 0, top: 0, zIndex: 100 }}
          label={"Print"}
          className="no-visible-print-btn"
          type="button"
          onClick={() => window.print()}
        />

        <div
          style={{
            textAlign: "center",
          }}
        >
          <h6
            className="m-0"
            style={{
              fontWeight: "900",
            }}
          >
            <u> FORM 5 </u>
          </h6>
          <p className="m-0">
            Returns of tax payable by employer under Sub-section (1) of Section
            6 of <br /> the Gujarat State Tax on Professions, Trades. Callings
            and Employments Act, 1976 <br /> (See Rule 11)
          </p>
        </div>
        <div>
          <p
            className="m-0"
            style={{
              display: "flex",
              gap: "30px",
              fontWeight: "900",
            }}
          >
            <span>AMC-RC NO</span>
            <span style={{ width: "30%", border: "2px solid black", paddingLeft:"3px" }}>
              PRC 014400099
            </span>
          </p>
          <p className="m-0">
            Return of tax payable for the month ending on:-
            <b>From Date 01-Nov-24 To 30-Nov-24</b>
          </p>
          <p style={{ display: "flex" }} className="m-0">
            <span
              style={{
                width: "20%",
                fontSize: "12px",
              }}
            >
              Name of the Employer:
            </span>
            <span>
              <b> {COMPANY_NAME}</b>
            </span>
          </p>
          <p style={{ display: "flex" }}>
            <span
              style={{
                width: "20%",
              }}
            >
              Address :
            </span>
            <span>{COMPANY_ADDRESS}</span>
          </p>
        </div>

        <div>
          <p className="m-0">
            Details of employes during the month in respect of whom tax is
            payable are as under:
          </p>
          <p
            className="m-0"
            style={{
              display: "flex",
            }}
          >
            I Details for tax calculation for tax payable in respect pf salaries
            for the month ending on
            <span
              style={{
                fontWeight: "900",
                borderBottom: "1px dotted",
                width: "30%",
                textAlign:"center",
              }}
            >
              <b>From Date 01-Nov-24 To 30-Nov-24</b>
            </span>
          </p>
        </div>
        <div
          className="form-5-first-table"
          style={{
            paddingTop: "10px",
          }}
        >
          <table style={{ width: "100%" }}>
            <thead>
              <tr style={{ padding: "10px" }}>
                <th
                  style={{
                    width: "20%",
                  }}
                >
                  Employees Whose monthly salaries or <br /> wages are
                </th>
                <th>No of employees</th>
                <th
                  style={{
                    width: "20%",
                  }}
                >
                  Number of <br /> employees for whom <br /> no tax is payable{" "}
                  <br /> under provision to <br /> section <b>4</b>
                </th>
                <th
                  style={{
                    width: "20%",
                  }}
                >
                  Number of <br /> employees in <br /> respect of whom <br />{" "}
                  tax is payable
                  <br /> (i.e Col. 2 minus)
                </th>
                <th>Rate of tax per month per employees</th>
                <th>
                  Amount of tax <br /> deducted
                </th>
              </tr>
              <tr>
                <th> 1</th>
                <th> 2</th>
                <th> 3</th>
                <th> 4</th>
                <th> 5</th>
                <th> 6</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>i) Rs. 0 to 2999</td>
                <td>59</td>
                <td>59</td>
                <td>0</td>
                <td>0 </td>
                <td>0</td>
              </tr>
              <tr>
                <td>i)Rs. 3000 to 5999</td>
                <td>0</td>
                <td></td>
                <td>0</td>
                <td>20</td>
                <td></td>
              </tr>
              <tr>
                <td>ii) Rs. 6000 to 8999</td>
                <td>0</td>
                <td></td>
                <td>0</td>
                <td>80</td>
                <td></td>
              </tr>
              <tr>
                <td>iv) Rs. 9000 to 11999</td>
                <td>0</td>
                <td></td>
                <td>0</td>
                <td>150</td>
                <td></td>
              </tr>
              <tr>
                <td>iv) Rs. 12000 & above</td>
                <td>91</td>
                <td></td>
                <td>91</td>
                <td>200</td>
                <td>18200</td>
              </tr>
              <tr>
                <td>Total A Rs</td>
                <td>150</td>
                <td>59</td>
                <td>91</td>
                <td></td>
                <td>18200</td>
              </tr>
            </tbody>
          </table>

          <p className="m-0">
            11. Details of employess in respect of whom tax is payble at the
            enhanced rate for previous period on account of arrears salaries or
            wages paid during the month.
          </p>
        </div>
        <div className="form-5-second-table">
          <table>
            <thead>
              <tr>
                <th rowSpan={2}>
                  Number of employees liable to tax at enhanced rate to be shown
                  separately according to column 4 and column 5
                </th>
                <th
                  colSpan={2}
                  style={{
                    width: "30%",
                    fontWeight: "900",
                  }}
                >
                  RATE OF TAX
                </th>
                <th rowSpan={2}>Difference of Rate (col.2 minus Col.3)</th>
                <th rowSpan={2}>
                  No. of month for which arrears is paid (Col.5)
                </th>
                <th rowSpan={2}>Additional tax Payable (Col.1. col 4 and)</th>
              </tr>
              <tr>
                <th>Payable on account of arrears salaries and wages</th>
                <th style={{ width: "15%" }}>At which tax was paid</th>
              </tr>
              <tr>
                <th>1</th>
                <th>2</th>
                <th>3</th>
                <th>4</th>
                <th>5</th>
                <th>6</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td style={{ height: "50px" }}></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>

              <tr style={{
                borderTop:"2px solid black"
              }}>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td
                  colSpan={2}
                  style={{
                    textAlign: "start",
                  }}
                >
                  Total B Rs
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div>
          <p
            style={{
              display: "flex",
              paddingTop: "5px",
            }}
            className="m-0"
          >
            <span
              style={{
                width: "72%",
                fontWeight: "900",
                fontSize: "12px",
              }}
            >
              Total Tax payable i.e. Total A+B{" "}
            </span>
            <span style={{ wordSpacing: "70px" }}>
              <b> Rs 18200 </b>
            </span>
          </p>
          <p className="m-0" style={{ display: "flex", alignItems: "end" }}>
            <span
              style={{
                width: "72%",
              }}
            >
              Add Simple interest payable (if any) on the <br /> above amount at
              *[one and a half percent] per month <br /> or part there of (vide
              section 9(2)of the Act)
            </span>
            <span>
              <b>Rs</b>
            </span>
          </p>
          <p className="m-0" style={{ display: "flex" }}>
            <span
              style={{
                width: "72%",
              }}
            >
              Total Tax and Interest Payable
            </span>
            <span>
              <span style={{ wordSpacing: "70px" }}>
                <b> Rs 18200 </b>
              </span>
            </span>
          </p>
          <p className="m-0">Amount paid by Receipt No. :-</p>
          <p className="m-0">Bank Name:- </p>
        </div>
        <div>
          <p className="m-0" style={{ textAlign: "center" }}>
            Chq. No & Dt:-
          </p>
          <p className="m-0">
            I certify that all the employees who ae liable to pay the tax in my
            employ during the period of return have <br /> been convered by the
            foregoing particulars. I also certify that the necessary revision in
            the amount of the tax deductable from the salary or wages of the{" "}
            <br />
            employees on account of variation in the salary or wages by <br />{" "}
            them has been made where necessary.
          </p>
          <p className="m-0">
            I.shri
            ......................................................................................................................................................................................................
          </p>
          <p
            style={{
              display: "flex",
            }}
            className="m-0"
          >
            <span style={{ width: "60%" }}>
              Declare that the above statements are true to the best of my
              knowledge
            </span>
            <span>Signature:-</span>
          </p>
          <p className="m-0" style={{ display: "flex" }}>
            <span style={{ width: "60%" }}>Place:-Ahmedabad</span>
            <span
              style={{
                fontSize: "14px",
                fontWeight: "900",
                paddingLeft: "20px",
              }}
            >
              For, {COMPANY_NAME}
            </span>
          </p>
          <p className="m-0">Date:-</p>
          <p>Stamp</p>
        </div>
        <p
          style={{
            paddingTop:"30px",
            textAlign: "right",
          }}
        >
          1
        </p>
      </div>
    </>
  );
};

export default Form5view;
