import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import { Typography } from "@progress/kendo-react-common";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  FieldArray,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import FormTextField from "../../components/formFields/FormTextField";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import ShadowCard from "../../components/common/ShadowCard";
import {
  createStockTransfer,
  getCurrentStock,
  getStockTransferByID,
  updateStockTransfer,
} from "./services/stockTransferMulti.services";
import { findAllActiveWarehouse } from "../warehouse/services/warehouse.services";
import { clearStockTransferDetails } from "./stockTransferMultiSlice";
import FormDatePicker from "../../components/formFields/FormDateField";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { IWarehouse } from "../warehouse/warehouseModel";
import moment from "moment";
import { IProcess } from "../process/processModel";
import { findAllActiveProcess } from "../process/services/process.services";
import { FINANCIAL_YEAR } from "../../_contstants/common";
import { ErrorToast } from "../../components/toast/Toasts";
import FormTextArea from "../../components/formFields/FormTextArea";
import { getAllItemIncremental } from "../Item/services/item.services";
import StockTransferMultiItemArray from "./StockTransferMultiItemArray";
import { getGRNByItemID } from "../inwardreturn/services/inwardReturn.services";
import { formatIndianNumberForQty } from "../../_helper/helper";
import _ from "lodash";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
  handleChange?: any;
}

const QtyChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
  // currentStock,
}) => {
  // const stock_qty = formRenderProps.valueGetter("stock_qty");
  const grn_no = formRenderProps.valueGetter("grn_no");
  const isFetchLotOption = React.useRef(true);
  const location = useLocation();
  const stock_transfer_guid = location.state?.stock_transfer_guid;

  useEffect(() => {
    const currentStockCheck = () => {
      // if (currentStock) {
      //   if (currentStock < stock_qty) {
      formRenderProps.onChange("stock_qty", {
        value: "",
      });
      //     }
      //   } else {
      //     formRenderProps.onChange("stock_qty", {
      //       value: "",
      //     });
      //   }
    };

    if (isFetchLotOption.current && stock_transfer_guid) {
      isFetchLotOption.current = false;
    } else {
      currentStockCheck();
      isFetchLotOption.current = false;
    }
  }, [grn_no]);

  return null;
};

const ItemChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
  handleChange,
}) => {
  // const stock_qty = formRenderProps.valueGetter("stock_qty");
  const grn_no = formRenderProps.valueGetter("grn_no");
  const isFetchItem = React.useRef(true);
  const location = useLocation();
  const stock_transfer_guid = location.state?.stock_transfer_guid;

  useEffect(() => {
    if (isFetchItem.current && stock_transfer_guid) {
      handleChange("item_id", formRenderProps);
      isFetchItem.current = false;
    } else {
      isFetchItem.current = false;
    }
  }, [grn_no]);

  return null;
};

const CreateStockTransferMulti: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const stock_transfer_guid = location.state?.stock_transfer_guid;
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.stockTransferMulti.loading);
  const StockTransferDetail = useAppSelector(
    (state) => state.stockTransferMulti.StockTransferDetail
  );
  const warehouseList = useAppSelector(
    (state) => state.warehouse.warehouseList
  );
  const ProcessList = useAppSelector((state) => state.process.ProcessList);
  const [formKey, setFormKey] = useState(1);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [StockTransferDetail, stock_transfer_guid]);

  useEffect(() => {
    if (stock_transfer_guid) {
      const payload = {
        stock_transfer_guid: stock_transfer_guid,
      };
      dispatch(getStockTransferByID(payload));
    }
  }, [stock_transfer_guid]);

  useEffect(() => {
    dispatch(findAllActiveProcess());
    dispatch(findAllActiveWarehouse());

    return () => {
      dispatch(clearStockTransferDetails());
    };
  }, []);

  const fetchGRNOptions = useCallback(
    async (
      index: number,
      item: any,
      formRenderProps: FormRenderProps,
      isItemChange?: boolean
    ) => {
      const from_warehouse_id =
        formRenderProps.valueGetter("from_warehouse_id");
      const from_department_id =
        formRenderProps.valueGetter("from_department_id");
      const stock_item_options =
        formRenderProps.valueGetter("stock_item_options");

      if (item?.item_id && from_warehouse_id && from_department_id) {
        const grnPayload = {
          item_id: item.item_id,
          process_id: from_department_id ? from_department_id : null,
          warehouse_id: from_warehouse_id ? from_warehouse_id : null,
        };
        const response = await dispatch(getGRNByItemID(grnPayload));
        const result =
          response.payload
            ?.filter((grn: any) => grn.item_id === item.item_id)
            .map((grnno: any) => ({
              value: `${grnno?.grn_no}`,
              label: `${grnno?.grn_no} - ${formatIndianNumberForQty(
                grnno?.current_stock || 0
              )}`,
            })) || [];
        formRenderProps.onChange(`stock_item_options.${index}.grnNoOptions`, {
          value: result,
        });
      } else if (isItemChange) {
        formRenderProps.onChange(`stock_item_options.${index}.grnNoOptions`, {
          value: [],
        });
        formRenderProps.onChange(`stock_item_options.${index}.grn_no`, {
          value: null,
        });
      } else {
        stock_item_options?.map((item: any, newindex: number) => {
          formRenderProps.onChange(
            `stock_item_options.${newindex}.grnNoOptions`,
            {
              value: [],
            }
          );
          formRenderProps.onChange(`stock_item_options.${newindex}.grn_no`, {
            value: null,
          });
        });
      }
    },
    [dispatch]
  );

  const fetchCurrentStock = useCallback(
    async (
      index: number,
      item: any,
      formRenderProps: FormRenderProps,
      isItemChange?: boolean
    ) => {
      const from_warehouse_id =
        formRenderProps.valueGetter("from_warehouse_id");
      const from_department_id =
        formRenderProps.valueGetter("from_department_id");
      const stock_item_options =
        formRenderProps.valueGetter("stock_item_options");

      if (
        item?.item_id &&
        item?.grn_no &&
        from_warehouse_id &&
        from_department_id
      ) {
        const payload = {
          item_id: +item.item_id,
          grn_no: item.grn_no,
          process_id: from_department_id ? from_department_id : null,
          warehouse_id: from_warehouse_id ? from_warehouse_id : null,
        };
        const response = await dispatch(getCurrentStock(payload));
        formRenderProps.onChange(`stock_item_options.${index}.current_qty`, {
          value: response.payload?.actual_stock || 0,
        });
        if (item?.stock_qty > response.payload?.actual_stock) {
          formRenderProps.onChange(`stock_item_options.${index}.stock_qty`, {
            value: "",
          });
        }
      } else if (isItemChange) {
        formRenderProps.onChange(`stock_item_options.${index}.current_qty`, {
          value: null,
        });
      } else {
        stock_item_options?.map((item: any, newindex: number) => {
          formRenderProps.onChange(
            `stock_item_options.${newindex}.current_qty`,
            {
              value: null,
            }
          );
        });
      }
    },
    [dispatch]
  );

  const handleFieldChange = (
    field: string,
    index: number,
    item: any,
    formRenderProps: FormRenderProps,
    isItemChange?: boolean
  ) => {
    console.log("field", field);
    switch (field) {
      // case "item_id":
      //   fetchGRNOptions(index, item, formRenderProps);
      //   fetchCurrentStock(index, item, formRenderProps);
      //   break;
      // case "from_warehouse_id":
      //   fetchGRNOptions(index, item, formRenderProps);
      //   fetchCurrentStock(index, item, formRenderProps);
      //   break;
      // case "from_department_id":
      //   fetchGRNOptions(index, item, formRenderProps);
      //   fetchCurrentStock(index, item, formRenderProps);
      //   break;
      case "grn_no":
        fetchCurrentStock(index, item, formRenderProps, isItemChange);
        console.log("grn");
        break;

      default:
        console.log("default");
        fetchGRNOptions(index, item, formRenderProps, isItemChange);
        fetchCurrentStock(index, item, formRenderProps, isItemChange);
        break;
    }
  };

  const debouncedHandleProductChange = React.useRef(
    _.debounce(
      (
        field: any,
        index: number,
        item: any,
        formRenderProps: FormRenderProps,
        isItemChange?: boolean
      ) => {
        handleFieldChange(field, index, item, formRenderProps, isItemChange);
      },
      300
    )
  ).current;

  const handleChange = (field: any, formRenderProps: FormRenderProps) => {
    const stock_item_options =
      formRenderProps.valueGetter("stock_item_options");
    console.log("first");
    console.log("stock_item_options", stock_item_options);
    return stock_item_options?.map((item: any, index: number) => {
      console.log("index", index);
      // return debouncedHandleProductChange(field, index, item, formRenderProps);
      handleFieldChange(field, index, item, formRenderProps);
    });
  };

  const handleTransferNoChange = (
    ev: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (ev.key === "Enter" || ev.key === "Tab") {
      ev.preventDefault();
      if ((ev.target as HTMLInputElement).value) {
        const payload = {
          stock_transfer_no: (ev.target as HTMLInputElement).value,
          financial_year: FINANCIAL_YEAR,
        };

        dispatch(getStockTransferByID(payload));
      }
    }
  };

  const handleItemSearchChange = useCallback(
    async (search: string, field: string, formRenderProps: FormRenderProps) => {
      const result = await dispatch(getAllItemIncremental({ search }));
      formRenderProps.onChange(field, {
        value: result.payload,
      });
    },
    [dispatch]
  );

  const handleSubmit = async (values: any) => {
    console.log("values", values);
    if (
      values.from_warehouse_id &&
      values.to_warehouse_id &&
      values.from_department_id &&
      values.to_department_id &&
      values.from_warehouse_id === values.to_warehouse_id &&
      values.from_department_id === values.to_department_id
    ) {
      ErrorToast("Warehouse and Department cannot be the same.");
    } else {
      if (stock_transfer_guid) {
        try {
          const updatePayload = {
            id: StockTransferDetail?.id,
            stock_transfer_guid: StockTransferDetail?.stock_transfer_guid,
            stock_transfer_date: values?.stock_transfer_date
              ? moment(values?.stock_transfer_date).format("YYYY-MM-DD")
              : "",
            from_warehouse_id: values?.from_warehouse_id
              ? +values?.from_warehouse_id
              : null,
            to_warehouse_id: values?.to_warehouse_id
              ? +values?.to_warehouse_id
              : null,
            from_department_id: values?.from_department_id
              ? +values?.from_department_id
              : null,
            to_department_id: values?.to_department_id
              ? +values?.to_department_id
              : null,
            outward_no: values?.outward_no ? values?.outward_no : "",
            vehicle_no: values?.vehicle_no ? values?.vehicle_no : "",
            financial_year: FINANCIAL_YEAR,
            remarks: values?.remarks ? values?.remarks : "",
            stock_item_options: values?.stock_item_options
              ? values?.stock_item_options?.map((stitem: any) => {
                  return {
                    id: stitem?.id ? +stitem?.id : 0,
                    item_id: stitem?.item_id ? +stitem?.item_id : null,
                    grn_no: stitem?.grn_no ? stitem?.grn_no : "",
                    stock_qty: stitem?.stock_qty ? +stitem?.stock_qty : 0,
                    katta: stitem?.katta ? +stitem?.katta : null,
                    vaccal_no: stitem?.vaccal_no ? `${stitem?.vaccal_no}` : "",
                    cold_storage_receipt_no: stitem?.cold_storage_receipt_no
                      ? `${stitem?.cold_storage_receipt_no}`
                      : "",
                    inward_no: stitem?.inward_no ? +stitem?.inward_no : null,
                    inward_date: stitem?.inward_date
                      ? moment(stitem?.inward_date).format("YYYY-MM-DD")
                      : null,
                  };
                })
              : [],
          };
          const response = await dispatch(updateStockTransfer(updatePayload));
          if (response?.meta?.requestStatus === "fulfilled") {
            navigate("/stocktransfermulti");
          }
        } catch (error) {
          console.error("Error in handleSubmit:", error);
          throw error;
        }
      } else {
        try {
          const insertPayload = {
            stock_transfer_date: values?.stock_transfer_date
              ? moment(values?.stock_transfer_date).format("YYYY-MM-DD")
              : "",
            from_warehouse_id: values?.from_warehouse_id
              ? +values?.from_warehouse_id
              : null,
            to_warehouse_id: values?.to_warehouse_id
              ? +values?.to_warehouse_id
              : null,
            from_department_id: values?.from_department_id
              ? +values?.from_department_id
              : null,
            to_department_id: values?.to_department_id
              ? +values?.to_department_id
              : null,
            outward_no: values?.outward_no ? values?.outward_no : "",
            vehicle_no: values?.vehicle_no ? values?.vehicle_no : "",
            financial_year: FINANCIAL_YEAR,
            remarks: values?.remarks ? values?.remarks : "",
            stock_item_options: values?.stock_item_options
              ? values?.stock_item_options?.map((stitem: any) => {
                  return {
                    item_id: stitem?.item_id ? +stitem?.item_id : null,
                    grn_no: stitem?.grn_no ? stitem?.grn_no : "",
                    stock_qty: stitem?.stock_qty ? +stitem?.stock_qty : 0,
                    katta: stitem?.katta ? +stitem?.katta : null,
                    vaccal_no: stitem?.vaccal_no ? `${stitem?.vaccal_no}` : "",
                    cold_storage_receipt_no: stitem?.cold_storage_receipt_no
                      ? `${stitem?.cold_storage_receipt_no}`
                      : "",
                    inward_no: stitem?.inward_no ? +stitem?.inward_no : null,
                    inward_date: stitem?.inward_date
                      ? moment(stitem?.inward_date).format("YYYY-MM-DD")
                      : null,
                  };
                })
              : [],
          };
          const response = await dispatch(createStockTransfer(insertPayload));
          if (response?.meta?.requestStatus === "fulfilled") {
            navigate("/stocktransfermulti");
          }
        } catch (error) {
          console.error("Error in handleSubmit:", error);
          throw error;
        }
      }
    }
  };

  return (
    <>
      {loading && <LoadingPanel gridRef={gridRef} />}
      <Form
        key={formKey}
        onSubmit={handleSubmit}
        initialValues={StockTransferDetail}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12 }}>
              <GridLayout
                style={{ marginRight: 30 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                ]}
              >
                <GridLayoutItem colSpan={4}>
                  <Typography.h4>
                    {stock_transfer_guid
                      ? "Update Stock Transfer"
                      : "Add Stock Transfer"}
                  </Typography.h4>
                </GridLayoutItem>
                {stock_transfer_guid && (
                  <GridLayoutItem>
                    <Field
                      wrapperClassName="w-100 right-alighned-field"
                      name="stock_transfer_no"
                      onKeyDown={handleTransferNoChange}
                      label="Transfer No"
                      placeholder="Transfer No"
                      component={FormTextField}
                      validator={requiredValidator}
                      astrike={true}
                    />
                  </GridLayoutItem>
                )}
                <GridLayoutItem>
                  <Field
                    name="stock_transfer_date"
                    label="Transfer Date"
                    format="dd/MM/yyyy"
                    component={FormDatePicker}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={stock_transfer_guid ? 2 : 3}
                ></GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="from_warehouse_id"
                    label="From Warehouse"
                    placeholder="From Warehouse"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    onChange={() =>
                      handleChange("from_warehouse_id", formRenderProps)
                    }
                    astrike={true}
                    options={warehouseList?.map((warehouse: IWarehouse) => {
                      return {
                        value: warehouse?.id,
                        label: warehouse?.warehouse_name,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="from_department_id"
                    label="From Department"
                    placeholder="From Department"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    onChange={() =>
                      handleChange("from_department_id", formRenderProps)
                    }
                    astrike={true}
                    options={ProcessList?.map((process: IProcess) => {
                      return {
                        value: process?.id,
                        label: process?.process_name,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="to_warehouse_id"
                    label="To Warehouse"
                    placeholder="To Warehouse"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    astrike={true}
                    options={warehouseList?.map((warehouse: IWarehouse) => {
                      return {
                        value: warehouse?.id,
                        label: warehouse?.warehouse_name,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="to_department_id"
                    label="To Department"
                    placeholder="To Department"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    astrike={true}
                    options={ProcessList?.map((process: IProcess) => {
                      return {
                        value: process?.id,
                        label: process?.process_name,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="outward_no"
                    label="Outward No"
                    placeholder="Outward No"
                    component={FormTextField}
                    // validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem colSpan={2} rowSpan={2}>
                  <Field
                    name="remarks"
                    label="Remarks"
                    placeholder="Remarks"
                    component={FormTextArea}
                    rows={4}
                  />
                </GridLayoutItem>
                <GridLayoutItem></GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="vehicle_no"
                    label="Vehicle No"
                    placeholder="Vehicle No"
                    component={FormTextField}
                    // validator={requiredValidator}
                  />
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>

            <ShadowCard style={{ padding: 12, marginTop: 10 }}>
              <GridLayout
                style={{ marginRight: 30 }}
                gap={{ rows: 10, cols: 10 }}
                cols={[
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                ]}
              >
                <GridLayoutItem colSpan={4}>
                  <Typography.h4>Item Details</Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem colSpan={4}>
                  <FieldArray
                    formRenderProps={formRenderProps}
                    component={StockTransferMultiItemArray}
                    handleItemSearchChange={handleItemSearchChange}
                    name="stock_item_options"
                    debouncedHandleProductChange={debouncedHandleProductChange}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={4}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "end",
                  }}
                >
                  <ButtonWithLoading
                    label={stock_transfer_guid ? "Update" : "Save"}
                    type="submit"
                    disabled={!formRenderProps.allowSubmit || loading}
                    loading={loading}
                  />
                  <Button
                    type="button"
                    fillMode={"outline"}
                    themeColor={"primary"}
                    style={{ marginLeft: 4 }}
                    onClick={() => navigate("/stocktransfermulti")}
                  >
                    Cancel
                  </Button>
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
            <QtyChangeWatcher
              formRenderProps={formRenderProps}
              // currentStock={currentStock}
            />
            <ItemChangeWatcher
              formRenderProps={formRenderProps}
              handleChange={handleChange}
            />
          </FormElement>
        )}
      />
    </>
  );
};

export default CreateStockTransferMulti;
