import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { LoadingPanel } from "../../components/layout/Loading";
import ShadowCard from "../../components/common/ShadowCard";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";

import FormSelectionField from "../../components/formFields/FormSelectionField";
import { MONTHS } from "../../_contstants/common";
import {
  convertNumToIndianCurrency,
  getYearsArray,
  PercentageValue,
} from "../../_helper/helper";

import {
  clearSalaryGenerationDetail,
  clearSalaryGenerationList,
  clearUpdateSalaryDetails,
  setIsGeneratedSalary,
  setSalaryGenerationDetails,
  setUpdateSalaryDetails,
} from "./salryGenerationSlice";
import {
  getEmployeeSalaryByIDDaysMonthYear,
  getEmployeeSalaryByIDYear,
  getEmployeeSalaryDaysByIDMonthYear,
  updateEmployeeSalaryGeneration,
} from "./services/salaryGeneration.services";
import { getAllActiveEmployee } from "../employee/services/employee.services";
import { IEmployee } from "../employee/employeeModel";
import FormTextField from "../../components/formFields/FormTextField";
import FormTextArea from "../../components/formFields/FormTextArea";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { Button } from "@progress/kendo-react-buttons";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import { SuccessToast } from "../../components/toast/Toasts";
import { findIndex } from "lodash";
import moment from "moment";
import {
  getAllActiveProfessionTaxDate,
  getProfessionTaxSlabByDate,
} from "../ProfessionTaxSlab/services/professionTaxSlab.services";
import FormNumericTextField from "../../components/formFields/FormNumericTextField";
import { useLocation, useNavigate } from "react-router-dom";

interface SalaryGenerationChangeWatcherProps {
  formRenderProps: FormRenderProps;
}

const SalaryGenerationChangeWatcher: React.FC<
  SalaryGenerationChangeWatcherProps
> = ({ formRenderProps }) => {
  const EmployeeList = useAppSelector((state) => state.employee.EmployeeList);
  const PayoutMonth = formRenderProps.valueGetter("payout_month")?.toString();
  const PayoutYear = formRenderProps.valueGetter("payout_year");
  const EmployeeID = formRenderProps.valueGetter("employee_id");
  const EmployeeCode = formRenderProps.valueGetter("employee_code_string");
  const IsGeneratedSalary = useAppSelector(
    (state) => state.salarygeneration.IsGeneratedSalary
  );

  // const TotalDays = +formRenderProps.valueGetter("total_days") || 0;
  // const WorkingDays = +formRenderProps.valueGetter("working_days") || 0;
  // const PresetDays = +formRenderProps.valueGetter("present_days") || 0;
  // const CasualLeaveDays = +formRenderProps.valueGetter("casual_leave") || 0;

  const dispatch = useAppDispatch();
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  // const timeoutRefTwo = useRef<NodeJS.Timeout | null>(null);

  const updateFormFields = (Obj: Object) => {
    Object.keys(Obj).forEach((key) => {
      formRenderProps.onChange(key, {
        value: Obj[key as keyof typeof Obj],
      });
    });
  };

  useEffect(() => {
    if (EmployeeCode) {
      const employeeIndex = findIndex(
        EmployeeList,
        (employee: any) =>
          employee?.employee_code_string?.toLowerCase() ===
          EmployeeCode?.toLowerCase()
      );
      if (employeeIndex > -1) {
        const Employee = EmployeeList[employeeIndex];
        formRenderProps.onChange("employee_id", { value: Employee.id });
      }
    }
  }, [EmployeeCode]);

  useEffect(() => {
    const fetchData = async () => {
      if (IsGeneratedSalary) {
        setTimeout(() => {
          dispatch(setIsGeneratedSalary(false));
        }, 0);
        return false;
      }

      if (PayoutMonth && PayoutYear && EmployeeID) {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(async () => {
          const viewPayload = {
            employee_id: EmployeeID,
            year: PayoutYear,
          };
          const result = await dispatch(getEmployeeSalaryByIDYear(viewPayload));
          if (result.meta.requestStatus === "fulfilled") {
            const GeneratedSalaryList = result.payload;
            const GeneratedSalaryIndex = findIndex(GeneratedSalaryList, {
              payout_month: PayoutMonth,
            });
            if (GeneratedSalaryIndex > -1) {
              dispatch(openDialog("alreadygeneratedsalarydialog"));
              const Employee = GeneratedSalaryList[GeneratedSalaryIndex];
              dispatch(clearUpdateSalaryDetails());

              dispatch(
                setUpdateSalaryDetails({
                  ...Employee,
                  employee_code_string: EmployeeCode,
                })
              );
              return false;
            }
          }
          const payload = {
            employee_id: EmployeeID,
            payout_month: PayoutMonth,
            payout_year: PayoutYear,
          };
          const response = await dispatch(
            getEmployeeSalaryDaysByIDMonthYear(payload)
          );
          if (response.meta.requestStatus === "fulfilled") {
            updateFormFields(response?.payload);
            const total_days = response?.payload.total_days;
            const working_days = response?.payload.working_days;
            const present_days = response?.payload.present_days;
            if (
              EmployeeID &&
              PayoutMonth &&
              PayoutYear &&
              total_days &&
              working_days &&
              present_days
            ) {
              const SalaryPayload = {
                employee_id: EmployeeID,
                payout_month: PayoutMonth,
                payout_year: PayoutYear,
                total_days,
                working_days,
                present_days,
              };
              const res = await dispatch(
                getEmployeeSalaryByIDDaysMonthYear(SalaryPayload)
              );
              if (res.meta.requestStatus === "fulfilled") {
                updateFormFields(res?.payload);
              }
            }
          }
        }, 300);
      }
    };
    fetchData();
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [PayoutMonth, PayoutYear, EmployeeID]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     if (TotalDays && WorkingDays && PresetDays) {
  //       if (timeoutRefTwo.current) {
  //         clearTimeout(timeoutRefTwo.current);
  //       }
  //       timeoutRefTwo.current = setTimeout(async () => {
  //         const payload = {
  //           employee_id: EmployeeID,
  //           payout_month: PayoutMonth,
  //           payout_year: PayoutYear,
  //           total_days: TotalDays,
  //           working_days: WorkingDays,
  //           present_days: PresetDays + CasualLeaveDays,
  //         };
  //         const response = await dispatch(
  //           getEmployeeSalaryByIDDaysMonthYear(payload)
  //         );
  //         if (response.meta.requestStatus === "fulfilled") {
  //           updateFormFields(response?.payload);
  //         }
  //       }, 1000);
  //     } else {
  //       dispatch(clearSalaryGenerationDetail());
  //     }
  //   };
  //   fetchData();
  //   return () => {
  //     if (timeoutRefTwo.current) {
  //       clearTimeout(timeoutRefTwo.current);
  //     }
  //   };
  // }, [TotalDays, WorkingDays, PresetDays, CasualLeaveDays]);
  return null;
};
interface SalaryChangeWatcherProps {
  formRenderProps: FormRenderProps;
}
const SalaryChangeWatcher: React.FC<SalaryChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const PayoutMonth = formRenderProps.valueGetter("payout_month")?.toString();
  const PayoutYear = formRenderProps.valueGetter("payout_year");
  const EmployeeID = formRenderProps.valueGetter("employee_id");
  const EmployeeCode = formRenderProps.valueGetter("employee_code_string");

  const TotalDays = +formRenderProps.valueGetter("total_days") || 0;
  const WorkingDays = +formRenderProps.valueGetter("working_days") || 0;
  const PresetDays = +formRenderProps.valueGetter("present_days") || 0;
  const CasualLeaveDays = +formRenderProps.valueGetter("casual_leave") || 0;

  const EmployeeFilterRef = useRef<Boolean>(false);

  const ApplyFromDate = new Date(`01/${PayoutMonth}/${PayoutYear}`);
  const dispatch = useAppDispatch();

  const ProfessionTaxSlabDateList = useAppSelector(
    (state) => state.professionTaxSlab.ProfessionTaxSlabDateList
  );
  const ProfessionTaxSlabDetails = useAppSelector(
    (state) => state.professionTaxSlab.ProfessionTaxSlabDetail
  );

  const PFPercent = +formRenderProps.valueGetter("pf_per") || 0;
  const ESIPercent = +formRenderProps.valueGetter("esi_per") || 0;

  const Basic = +formRenderProps.valueGetter("basic") || 0;
  const CellingAmount = +formRenderProps.valueGetter("celling_amount") || 0;
  const HRA = +formRenderProps.valueGetter("hra") || 0;
  const Wash = +formRenderProps.valueGetter("wash") || 0;
  const ProdInsAll = +formRenderProps.valueGetter("prod_ins_all") || 0;
  const Bonus = +formRenderProps.valueGetter("adv_bonus") || 0;
  const Leave = +formRenderProps.valueGetter("adv_leave") || 0;
  const AdvStdBe = +formRenderProps.valueGetter("adv_std_be") || 0;
  const Food = +formRenderProps.valueGetter("food") || 0;
  const DA = +formRenderProps.valueGetter("da") || 0;
  const Other_Earning = +formRenderProps.valueGetter("other_earning") || 0;
  const TotalIncome = +formRenderProps.valueGetter("total_income") || 0;

  const PF = +formRenderProps.valueGetter("pf") || 0;
  const ESI = +formRenderProps.valueGetter("esi") || 0;
  const IT = +formRenderProps.valueGetter("it") || 0;
  const PT = +formRenderProps.valueGetter("pt") || 0;
  const Loan = +formRenderProps.valueGetter("loan") || 0;
  const Advance = +formRenderProps.valueGetter("advance") || 0;
  const LWF = +formRenderProps.valueGetter("lwf") || 0;
  const TDS = +formRenderProps.valueGetter("tds") || 0;
  const Other = +formRenderProps.valueGetter("other") || 0;
  const TotalDeduction = +formRenderProps.valueGetter("total_deduction") || 0;
  const Net_Payble_Salary = formRenderProps.valueGetter("payable_amount") || 0;

  // PROFESSION TAX SLAB

  useEffect(() => {
    EmployeeFilterRef.current = true;

    if (!(TotalDays - PresetDays)) {
      formRenderProps.onChange("casual_leave", { value: 0 });
    }
  }, [
    PayoutMonth,
    PayoutYear,
    EmployeeID,
    EmployeeCode,
    TotalDays,
    WorkingDays,
    PresetDays,
    CasualLeaveDays,
  ]);

  useEffect(() => {
    if (ApplyFromDate) {
      ProfessionTaxSlabDateList.forEach((taxSlab: any) => {
        const { effective_from_date, effective_to_date } = taxSlab;
        if (effective_from_date) {
          const fromDate = moment(effective_from_date, "DD/MM/YYYY");
          const toDate = effective_to_date
            ? moment(effective_to_date, "DD/MM/YYYY")
            : moment();

          const isDateWithinTaxSlab = moment(ApplyFromDate).isBetween(
            fromDate,
            toDate,
            undefined,
            "[]" // Inclusive
          );

          if (isDateWithinTaxSlab) {
            const EffectiveFromDate = fromDate.format("YYYY-MM-DD");
            dispatch(getProfessionTaxSlabByDate(EffectiveFromDate));
          }
        }
      });
    }
  }, [PayoutMonth, PayoutYear]);

  useEffect(() => {
    if (EmployeeFilterRef.current) return;

    const TaxList = ProfessionTaxSlabDetails?.proffestional_tax;
    const matchingTax = TaxList.find(
      (tax) =>
        tax.salary_from !== null &&
        tax.salary_till !== null &&
        (tax.salary_from ?? 0) <= TotalIncome &&
        TotalIncome <= (tax.salary_till ?? 0)
    );
    if (matchingTax) {
      formRenderProps.onChange("pt", {
        value: matchingTax.tax_amount || 0,
      });
    } else {
      formRenderProps.onChange("pt", {
        value: 0,
      });
    }
  }, [TotalIncome]);

  // AMOUNT CALCULATION
  useEffect(() => {
    if (EmployeeFilterRef.current) {
      EmployeeFilterRef.current = false;
      return;
    }
    formRenderProps.onChange("pf", {
      value: Math.round(CellingAmount * (PFPercent / 100)),
    });
    if (TotalIncome > 21000) {
      formRenderProps.onChange("esi", {
        value: 0,
      });
    } else {
      formRenderProps.onChange("esi", {
        value: Math.ceil(TotalIncome * (ESIPercent / 100)),
      });
    }
    formRenderProps.onChange("total_income", {
      value: Math.ceil(
        Basic +
          DA +
          HRA +
          Wash +
          Bonus +
          Leave +
          Food +
          Other_Earning +
          AdvStdBe +
          ProdInsAll
      ),
    });

    formRenderProps.onChange("total_deduction", {
      value: Math.ceil(PF + ESI + PT + IT + LWF + Advance + Loan + Other + TDS),
    });
    formRenderProps.onChange("payable_amount", {
      value: Math.ceil(+(TotalIncome - TotalDeduction)),
    });
  }, [
    Basic,
    CellingAmount,
    DA,
    PFPercent,
    ESIPercent,
    HRA,
    Wash,
    Bonus,
    Leave,
    Food,
    Other_Earning,
    AdvStdBe,
    ProdInsAll,
    TotalIncome,
    Net_Payble_Salary,

    PF,
    ESI,
    PT,
    IT,
    Loan,
    LWF,
    Advance,
    TDS,
    Other,
    TotalDeduction,
  ]);

  useEffect(() => {
    formRenderProps.onChange("pf_per", { value: PercentageValue(PFPercent) });
    formRenderProps.onChange("esi_per", { value: PercentageValue(ESIPercent) });
  }, [PFPercent, ESIPercent]);

  return null;
};

const SalaryGeneration = () => {
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [formKey, setFormKey] = useState(1);
  const loading = useAppSelector((state) => state.salarygeneration.loading);
  const SalaryGenerationDetail = useAppSelector(
    (state) => state.salarygeneration.SalaryGenerationDetail
  );
  const location = useLocation();
  const EmployeeID = location.state?.EmployeeID;
  const PayoutYear = location.state?.PayoutYear;
  const PayoutMonth = location.state?.PayoutMonth;
  const IsEditMode = location.state?.IsEditMode;
  useEffect(() => {
    dispatch(getAllActiveEmployee());
    dispatch(getAllActiveProfessionTaxDate());
    return () => {
      dispatch(clearSalaryGenerationDetail());
      dispatch(clearSalaryGenerationList());
    };
  }, []);
  useEffect(() => {
    if (EmployeeID && PayoutMonth && PayoutYear) {
      const viewPayload = {
        employee_id: EmployeeID,
        year: PayoutYear,
      };
      const fetchData = async () => {
        const result = await dispatch(getEmployeeSalaryByIDYear(viewPayload));
        if (result.meta.requestStatus === "fulfilled") {
          const GeneratedSalaryList = result.payload;
          const GeneratedSalaryIndex = findIndex(GeneratedSalaryList, {
            payout_month: PayoutMonth,
          });
          if (GeneratedSalaryIndex > -1) {
            const Employee = GeneratedSalaryList[GeneratedSalaryIndex];
            dispatch(
              setSalaryGenerationDetails({
                ...Employee,
                payout_month: +Employee.payout_month,
                total_days: +Employee.total_days,
                working_days: +Employee.working_days,
                present_days: +Employee.present_days,
                employee_code_string: Employee.employee_code_string,
              })
            );
            dispatch(setIsGeneratedSalary(true));
            setFormKey((prev) => prev + 1);
          }
        }
      };

      fetchData();
    }
  }, [EmployeeID, PayoutYear, PayoutMonth]);

  const EmployeeList = useAppSelector((state) => state.employee.EmployeeList);
  useEffect(() => {
    setFormKey(formKey + 1);
  }, [SalaryGenerationDetail]);

  const handleSubmit = async (values: any) => {
    try {
      const payload = {
        employee_id: values?.employee_id,
        payout_month: values?.payout_month
          ? values?.payout_month?.toString()
          : "",
        payout_year: values?.payout_year,
        total_days: values?.total_days?.toString(),
        working_days: values?.working_days?.toString(),
        present_days: values?.present_days?.toString(),
        basic: +values?.basic,
        celling_amount: +values?.celling_amount,
        da_per: +values?.da_per,
        da: +values?.da,
        hra_per: +values?.hra_per,
        hra: +values?.hra,
        wash: +values?.wash,
        adv_bonus: +values?.adv_bonus,
        adv_leave: +values?.adv_leave,
        prod_ins_all: +values?.prod_ins_all,
        adv_std_be: +values?.adv_std_be,
        food: +values?.food,
        other_earning: +values?.other_earning,
        casual_leave: +values?.casual_leave,
        pf_per: +values?.pf_per,
        pf: +values?.pf,
        esi_per: +values?.esi_per,
        esi: +values?.esi,
        pt: +values?.pt,
        it: +values?.it,
        lwf: +values?.lwf,
        tds: +values?.tds,
        advance: +values?.advance,
        loan: +values?.loan,
        other: +values?.other,
        remarks: values?.remarks,
        total_income: +values?.total_income,
        total_deduction: +values?.total_deduction,
        payable_amount: +values?.payable_amount,
        isstatus: true,
      };
      const response = await dispatch(updateEmployeeSalaryGeneration(payload));
      if (response.meta.requestStatus === "fulfilled") {
        if (IsEditMode) {
          navigate(-1);
        }
        SuccessToast(response.payload?.Details || "Success");
        setFormKey(formKey + 1);
        dispatch(clearSalaryGenerationDetail());
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  const handleDaysChange = (
    e: React.KeyboardEvent<HTMLInputElement>,
    formRenderProps: FormRenderProps
  ) => {
    if (e.key === "Enter" || e.key === "Tab") {
      const PayoutMonth = formRenderProps
        .valueGetter("payout_month")
        ?.toString();
      const PayoutYear = formRenderProps.valueGetter("payout_year");
      const EmployeeID = formRenderProps.valueGetter("employee_id");

      const TotalDays = +formRenderProps.valueGetter("total_days") || 0;
      const WorkingDays = +formRenderProps.valueGetter("working_days") || 0;
      const PresetDays = +formRenderProps.valueGetter("present_days") || 0;
      const CasualLeaveDays = +formRenderProps.valueGetter("casual_leave") || 0;

      const updateFormFields = (Obj: Object) => {
        Object.keys(Obj).forEach((key) => {
          formRenderProps.onChange(key, {
            value: Obj[key as keyof typeof Obj],
          });
        });
      };
      if (TotalDays && WorkingDays && PresetDays) {
        const fetchData = async () => {
          const payload = {
            employee_id: EmployeeID,
            payout_month: PayoutMonth,
            payout_year: PayoutYear,
            total_days: TotalDays,
            working_days: WorkingDays,
            present_days: PresetDays + CasualLeaveDays,
          };
          const response = await dispatch(
            getEmployeeSalaryByIDDaysMonthYear(payload)
          );
          if (response.meta.requestStatus === "fulfilled") {
            updateFormFields(response?.payload);
          }
        };
        fetchData();
      } else {
        dispatch(clearSalaryGenerationDetail());
      }
    }
  };

  const handleChange = (e: any, formRenderProps: FormRenderProps) => {
    if (e?.value) {
      const employeeIndex = findIndex(EmployeeList, {
        id: e?.value,
      });
      formRenderProps.onChange("total_days", { value: "" });
      formRenderProps.onChange("working_days", { value: "" });
      formRenderProps.onChange("present_days", { value: "" });
      formRenderProps.onChange("casual_leave", { value: "" });
      if (employeeIndex > -1) {
        const Employee = EmployeeList[employeeIndex];
        formRenderProps.onChange("employee_code_string", {
          value: Employee.employee_code_string,
        });
      }
    } else {
      formRenderProps.onChange("total_days", { value: "" });
      formRenderProps.onChange("working_days", { value: "" });
      formRenderProps.onChange("present_days", { value: "" });
      formRenderProps.onChange("casual_leave", { value: "" });
      dispatch(clearSalaryGenerationDetail());
      setFormKey((prev) => prev + 1);
    }
  };

  return (
    <>
      {loading && <LoadingPanel gridRef={gridRef} />}
      <AlreadyGeneratedSalaryDialog setFormKey={setFormKey} />
      <Form
        key={formKey}
        onSubmit={handleSubmit}
        initialValues={SalaryGenerationDetail}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12, marginTop: 10 }}>
              <Typography.h4>Salary Generation</Typography.h4>
              <div className="container p-0" style={{ maxWidth: 850 }}>
                <GridLayout
                  style={{ marginRight: 40 }}
                  gap={{ rows: 10, cols: 10 }}
                  cols={[
                    { width: "25%" },
                    { width: "25%" },
                    { width: "25%" },
                    { width: "25%" },
                  ]}
                >
                  <GridLayoutItem colSpan={4} className="d-flex gap-2">
                    <Field
                      name="payout_month"
                      label="Payout Month"
                      wrapperStyle={{ width: "33.33%" }}
                      placeholder="Payout Month"
                      disabled={IsEditMode}
                      component={FormSelectionField}
                      options={MONTHS.map((month: any) => ({
                        value: month.value,
                        label: month.label,
                      }))}
                      validator={requiredValidator}
                      astrike={true}
                    />
                    <Field
                      name="payout_year"
                      label="Payout Year"
                      disabled={IsEditMode}
                      wrapperStyle={{ width: "33.33%" }}
                      placeholder="Payout Year"
                      component={FormSelectionField}
                      options={getYearsArray(2000, new Date().getFullYear() + 1)
                        ?.reverse()
                        ?.map((year: any) => {
                          return {
                            value: year?.value,
                            label: year?.label,
                          };
                        })}
                      validator={requiredValidator}
                      astrike={true}
                    />
                    <Field
                      name="employee_code_string"
                      label="Employee Code"
                      disabled={IsEditMode}
                      wrapperStyle={{ width: "33.33%" }}
                      placeholder="i.e.E10002"
                      component={FormTextField}
                    />
                  </GridLayoutItem>

                  <GridLayoutItem colSpan={4} className="d-flex gap-2">
                    <Field
                      name="employee_id"
                      label="Employee"
                      disabled={IsEditMode}
                      wrapperStyle={{ width: "33.33%" }}
                      placeholder="Select Employee"
                      component={FormSelectionField}
                      options={EmployeeList.map((emp: IEmployee) => ({
                        value: emp.id,
                        label: emp.name,
                      }))}
                      onChange={(e: any) => handleChange(e, formRenderProps)}
                      validator={requiredValidator}
                      astrike={true}
                    />
                    <Field
                      name="total_days"
                      label="Total Days"
                      placeholder="Total Days"
                      wrapperStyle={{ width: "16.66%" }}
                      component={FormNumericTextField}
                      type="number"
                      disabled
                      min={0}
                      validator={requiredValidator}
                      astrike={true}
                    />
                    <Field
                      name="working_days"
                      label="Working Days"
                      wrapperStyle={{ width: "16.66%" }}
                      placeholder="Working Days"
                      component={FormNumericTextField}
                      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
                        handleDaysChange(e, formRenderProps)
                      }
                      type="number"
                      min={0}
                      // maxNumber={formRenderProps.valueGetter("total_days") || 0}
                      disabled={loading}
                      validator={requiredValidator}
                      astrike={true}
                    />
                    <Field
                      name="present_days"
                      label="Present Days"
                      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
                        handleDaysChange(e, formRenderProps)
                      }
                      // maxNumber={formRenderProps.valueGetter("total_days") || 0}
                      wrapperStyle={{ width: "16.66%" }}
                      placeholder="Present Days"
                      component={FormNumericTextField}
                      type="number"
                      min={0}
                      disabled={loading}
                      validator={requiredValidator}
                      astrike={true}
                    />

                    <Field
                      name="casual_leave"
                      label="Casual Leave"
                      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
                        handleDaysChange(e, formRenderProps)
                      }
                      // maxNumber={
                      //   (formRenderProps.valueGetter("total_days") || 0) -
                      //   (formRenderProps.valueGetter("present_days") || 0)
                      // }
                      wrapperStyle={{ width: "16.66%" }}
                      placeholder="Casual Leave"
                      min={0}
                      component={FormNumericTextField}
                      disabled={
                        !(
                          (formRenderProps.valueGetter("total_days") || 0) -
                          (formRenderProps.valueGetter("present_days") || 0)
                        )
                      }
                      type="number"
                    />
                  </GridLayoutItem>
                  {/* Salary Structure */}
                  <GridLayoutItem
                    colSpan={4}
                    style={{
                      border: "1px solid #ccc",
                      // backgroundColor:"#000",
                      padding: 10,
                      borderRadius: 5,
                    }}
                  >
                    <GridLayout
                      style={{ marginRight: 10 }}
                      gap={{ rows: 10, cols: 10 }}
                      cols={[{ width: "50%" }, { width: "50%" }]}
                    >
                      {/* Earnings */}
                      <GridLayoutItem>
                        <GridLayout>
                          <GridLayoutItem>
                            <Typography.h5
                              style={{ textDecoration: "underline" }}
                            >
                              {"Earnings"}
                            </Typography.h5>
                          </GridLayoutItem>
                          <GridLayoutItem className="d-flex gap-3">
                            <Field
                              name="basic"
                              label="Basic"
                              component={FormTextField}
                              wrapperStyle={{ width: "50%" }}
                              type="number"
                              placeholder="Basic"
                              validator={requiredValidator}
                              astrike={true}
                            />
                            <Field
                              name="celling_amount"
                              label="Celling Amount"
                              wrapperStyle={{ width: "50%" }}
                              component={FormTextField}
                              type="number"
                              placeholder="Celling Amount"
                            />
                          </GridLayoutItem>

                          <GridLayoutItem className="d-flex gap-3">
                            {/* <Field
                              name="hra_per"
                              label="% HRA"
                              // max={2}
                              wrapperStyle={{ width: "40%" }}
                              type="number"
                              placeholder="i.e. 5"
                              component={FormTextField}
                            //validator={requiredValidator}
                            /> */}
                            <Field
                              name="hra"
                              label="HRA"
                              // disabled
                              wrapperStyle={{ width: "100%" }}
                              type="number"
                              format="n2"
                              placeholder="HRA"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem>
                            <Field
                              name="wash"
                              label="Wash"
                              type="number"
                              placeholder="Wash"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem>
                            <Field
                              name="prod_ins_all"
                              label="Production Incentive Allowance"
                              type="number"
                              placeholder="Production Incentive Allowance"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem>
                            <Field
                              name="adv_bonus"
                              label="Advance Bonus"
                              type="number"
                              placeholder="Advance Bonus"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem>
                            <Field
                              name="adv_leave"
                              label="Advance Leave"
                              type="number"
                              placeholder="Advance Leave"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem>
                            <Field
                              name="adv_std_be"
                              label="Advance Standard Benefit"
                              type="number"
                              placeholder="Advance Standard Benefit"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem>
                            <Field
                              name="food"
                              label="Food"
                              type="number"
                              placeholder="Food"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem className="d-flex gap-3">
                            {/* <Field
                              name="da_per"
                              label="% DA"
                              // max={2}
                              wrapperStyle={{ width: "40%" }}
                              type="number"
                              placeholder="i.e. 5"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            /> */}
                            <Field
                              name="da"
                              label="DA"
                              wrapperStyle={{ width: "100%" }}
                              format="n2"
                              type="number"
                              placeholder="DA"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem>
                            <Field
                              name="other_earning"
                              label="Other"
                              type="number"
                              placeholder="Other"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                          </GridLayoutItem>
                        </GridLayout>
                      </GridLayoutItem>

                      {/* Deduction */}
                      <GridLayoutItem
                        style={{
                          borderLeft: "2px solid #000",
                          paddingLeft: 10,
                        }}
                      >
                        <GridLayout>
                          <GridLayoutItem>
                            <Typography.h5
                              style={{ textDecoration: "underline" }}
                            >
                              {"Deduction"}
                            </Typography.h5>
                          </GridLayoutItem>
                          <GridLayoutItem className="d-flex gap-3">
                            <Field
                              name="pf_per"
                              label="% PF"
                              type="number"
                              wrapperStyle={{ width: "40%" }}
                              placeholder="i.e. 5"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                            <Field
                              name="pf"
                              label="PF"
                              wrapperStyle={{ width: "100%" }}
                              disabled
                              type="number"
                              placeholder="PF"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem className="d-flex gap-3">
                            <Field
                              name="esi_per"
                              label="% ESI"
                              wrapperStyle={{ width: "40%" }}
                              type="number"
                              placeholder="i.e. 5"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                            <Field
                              name="esi"
                              label="ESI"
                              wrapperStyle={{ width: "100%" }}
                              type="number"
                              disabled
                              placeholder="ESI"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem>
                            <Field
                              name="it"
                              label="IT"
                              type="number"
                              placeholder="IT"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem>
                            <Field
                              name="pt"
                              label="PT"
                              type="number"
                              placeholder="PT"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem>
                            <Field
                              name="loan"
                              label="Loan"
                              type="number"
                              placeholder="Loan"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem>
                            <Field
                              name="advance"
                              label="Advance"
                              type="number"
                              placeholder="Advance"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem>
                            <Field
                              name="lwf"
                              label="LWF"
                              type="number"
                              placeholder="LWF"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem>
                            <Field
                              name="tds"
                              label="TDS"
                              type="number"
                              placeholder="TDS"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem>
                            <Field
                              name="other"
                              label="Other"
                              type="number"
                              placeholder="Other"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                          </GridLayoutItem>
                        </GridLayout>
                      </GridLayoutItem>
                    </GridLayout>
                  </GridLayoutItem>
                  <GridLayoutItem
                    colSpan={2}
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                      backgroundColor: "#e7e6e6",
                      padding: "5px 20px",
                      borderRadius: "5px",
                      marginTop: 20,
                    }}
                  >
                    <Typography.h5 style={{ margin: 0 }}>
                      {" "}
                      Gross Earning:
                    </Typography.h5>
                    <Typography.h4 style={{ margin: 0 }}>
                      {convertNumToIndianCurrency(
                        formRenderProps.valueGetter("total_income")
                      )}
                    </Typography.h4>
                  </GridLayoutItem>
                  <GridLayoutItem
                    colSpan={2}
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                      backgroundColor: "#e7e6e6",
                      padding: "5px 20px",
                      borderRadius: "5px",
                      marginLeft: 10,
                      marginTop: 20,
                    }}
                  >
                    <Typography.h5 style={{ margin: 0 }}>
                      Gross Deduction :
                    </Typography.h5>
                    <Typography.h4 style={{ margin: 0 }}>
                      {convertNumToIndianCurrency(
                        formRenderProps.valueGetter("total_deduction")
                      )}
                    </Typography.h4>
                  </GridLayoutItem>
                  <GridLayoutItem colSpan={2}>
                    <Field
                      name="remarks"
                      label="Remarks"
                      placeholder="Remarks..."
                      component={FormTextArea}
                      rows={2}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem
                    colSpan={2}
                    style={{
                      padding: "13px 0 11px  9px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                        backgroundColor: "rgb(231, 230, 230)",
                        padding: "5px 20px",
                        borderRadius: "5px",
                        marginTop: "20px",
                        minHeight: "68px",
                      }}
                    >
                      <Typography.h5 style={{ color: "green", margin: "0" }}>
                        Net Salary:
                      </Typography.h5>
                      <Typography.h4 style={{ color: "green", margin: "0" }}>
                        {convertNumToIndianCurrency(
                          formRenderProps.valueGetter("payable_amount")
                        )}
                      </Typography.h4>
                    </div>
                  </GridLayoutItem>

                  <SalaryChangeWatcher formRenderProps={formRenderProps} />
                  <SalaryGenerationChangeWatcher
                    formRenderProps={formRenderProps}
                  />
                  <GridLayoutItem
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "end",
                    }}
                    colSpan={4}
                  >
                    <div>
                      {/* <Button
                      type="button"
                      fillMode={"outline"}
                      themeColor={"primary"}
                      style={{ marginRight: 4 }}
                      onClick={() => navigate("/salarygeneration")}
                    >
                      Cancel
                    </Button> */}
                      <ButtonWithLoading
                        label={"Save"}
                        type="submit"
                        disabled={!formRenderProps.allowSubmit || loading}
                        loading={loading}
                      />
                    </div>
                  </GridLayoutItem>
                  {/* <DepartmentChangeWatcher
                  formRenderProps={formRenderProps}
                  setRowSelectionModel={setRowSelectionModel}
                  setSelectedState={setSelectedState}
                /> */}
                </GridLayout>
              </div>
            </ShadowCard>
          </FormElement>
        )}
      />
    </>
  );
};

interface AlreadyGeneratedSalaryDialogProps {
  setFormKey: React.Dispatch<React.SetStateAction<number>>;
}
const AlreadyGeneratedSalaryDialog: React.FC<
  AlreadyGeneratedSalaryDialogProps
> = ({ setFormKey }) => {
  const dispatch = useAppDispatch();
  const UpdateSalaryDetails = useAppSelector(
    (state) => state.salarygeneration.UpdateSalaryDetails
  );
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const handleClickFunction = (formdata: any) => {
  

    dispatch(
      setSalaryGenerationDetails({
        ...formdata,
        payout_month: +formdata.payout_month,
        total_days: +formdata.total_days,
        working_days: +formdata.working_days,
        present_days: +formdata.present_days,
      })
    );
    dispatch(setIsGeneratedSalary(true));
    setFormKey((prev) => prev + 1);
    dispatch(closeDialog());
  };
  return (
    <>
      {dialogName === "alreadygeneratedsalarydialog" && (
        <AppDialog>
          <>
            <Typography.h5>{"Salary is already Generated"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p> Are you want to Modify it?</Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton text="No" themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleClickFunction(UpdateSalaryDetails)}
                  type="button"
                >
                  Yes
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

export default SalaryGeneration;
