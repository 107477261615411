import React, { useEffect } from "react";
import { Field, FieldArrayRenderProps } from "@progress/kendo-react-form";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { Typography } from "@progress/kendo-react-common";
import { setDebitNoteTaxItemDeleteIndex } from "./debitNoteSlice";
import { useLocation } from "react-router-dom";
import FormNumericTextField from "../../components/formFields/FormNumericTextField";
import FormIncrementalSearch from "../../components/formFields/FormIncrementalSearch";

import _ from "lodash";

const debitNoteDutyAndTaxArray = (
  fieldArrayRenderProps: FieldArrayRenderProps
) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const debit_note_guid = location.state?.debit_note_guid;

  const DebitNoteTaxItemDeleteIndex = useAppSelector(
    (state) => state.debitnote.DebitNoteTaxItemDeleteIndex
  );
  const InputRef = React.useRef<any>(null);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const handleOpenDeleteDialog = (index: number) => {
    dispatch(openDialog("DebitNoteTaxItemDeleteDialog"));
    dispatch(setDebitNoteTaxItemDeleteIndex(index));
  };

  useEffect(() => {
    if (!debit_note_guid || fieldArrayRenderProps.value.length === 0) {
      pushElementInarray();
    }
  }, []);

  const handleDeleteAction = React.useCallback(
    (index: number) => {
      fieldArrayRenderProps.onRemove({ index: index });
      const nextIndex = fieldArrayRenderProps.value.length - 1;
      const focusedElement = document.getElementsByName(
        `debit_note_duty_and_taxes.${nextIndex}.gst_amount`
      );
      focusedElement[0].focus();
      dispatch(closeDialog());
      dispatch(setDebitNoteTaxItemDeleteIndex(-1));
    },
    [fieldArrayRenderProps]
  );

  const pushElementInarray = React.useCallback(() => {
    fieldArrayRenderProps.onPush({
      value: {
        ledger_id: null,
        gst_per: null,
        gst_amount: null,
      },
    });
    setTimeout(() => {
      InputRef.current?.element?.children[0]?.children[1]?.children[0]?.focus();
    }, 0);
  }, [fieldArrayRenderProps]);

  const handleTaxItemChange = async (
    e: any,
    innerIndex: number,
    fieldArrayRenderProps: FieldArrayRenderProps
  ) => {
    const debit_note_duty_and_taxes =
      fieldArrayRenderProps.formRenderProps.valueGetter(
        "debit_note_duty_and_taxes"
      );
    if (e?.value) {
      // const match = e?.label.match(/@([\d.]+)/);
      const matched =
        fieldArrayRenderProps.value[innerIndex]?.ledger_options?.filter(
          (item: any) => item.id === e?.value
        ) || [];
      if (matched[0]?.tax_percentage) {
        // const GST = parseFloat(match[1]);
        debit_note_duty_and_taxes &&
          debit_note_duty_and_taxes.length > 0 &&
          debit_note_duty_and_taxes.map((item: any, index: number) => {
            if (innerIndex === index) {
              if (matched[0]?.type_of_supply !== "Service") {
                fieldArrayRenderProps.formRenderProps.onChange(
                  `debit_note_duty_and_taxes.${index}.gst_per`,
                  {
                    value: matched[0]?.tax_percentage || null,
                  }
                );
              }
              fieldArrayRenderProps.formRenderProps.onChange(
                `debit_note_duty_and_taxes.${index}.type_of_supply`,
                {
                  value: matched[0]?.type_of_supply || "",
                }
              );
            }
          });
      }
    } else {
      debit_note_duty_and_taxes &&
        debit_note_duty_and_taxes.length > 0 &&
        debit_note_duty_and_taxes.map((item: any, index: number) => {
          if (innerIndex === index) {
            fieldArrayRenderProps.formRenderProps.onChange(
              `debit_note_duty_and_taxes.${index}.gst_per`,
              {
                value: null,
              }
            );
          }
        });
    }
  };

  const debouncedHandleTaxItemChange = React.useRef(
    _.debounce((item_id: any, index: number, fieldArrayRenderProps: any) => {
      handleTaxItemChange(item_id, index, fieldArrayRenderProps);
    }, 300)
  ).current;

  useEffect(() => {
    return () => {
      debouncedHandleTaxItemChange.cancel();
    };
  }, []);

  const handleChange = (
    item_id: any,
    index: number,
    fieldArrayRenderProps: any
  ) => {
    fieldArrayRenderProps.formRenderProps.onChange(
      `debit_note_duty_and_taxes.${index}.ledger_name`,
      {
        value: item_id?.label,
      }
    );

    if (item_id?.value) {
      debouncedHandleTaxItemChange(item_id, index, fieldArrayRenderProps);
    }
  };

  const handleKeyEvent = (
    e: any,
    index: number,
    fieldArrayRenderProps: FieldArrayRenderProps
  ) => {
    if (
      e.key === "Tab" &&
      fieldArrayRenderProps.value.length === index + 1 &&
      fieldArrayRenderProps.value[index].ledger_id
    ) {
      if (!e.shiftKey) {
        pushElementInarray();
      }
    }
    if (e.key === "Escape" && fieldArrayRenderProps.value.length !== 1) {
      handleOpenDeleteDialog(index);
    }
  };

  const handleSearchLedger = (search: any, index: any) => {
    if (search) {
      fieldArrayRenderProps.handleLedgerSearchChange(
        search,
        `debit_note_duty_and_taxes.${index}.ledger_options`,
        fieldArrayRenderProps.formRenderProps
      );
    }
  };

  return (
    <>
      <GridLayout cols={[{ width: "100%" }]}>
        {dialogName === "DebitNoteTaxItemDeleteDialog" && (
          <AppDialog>
            <>
              <Typography.h5>{"Delete Item"}</Typography.h5>
              <GridLayout>
                <GridLayoutItem>
                  <Typography.p>
                    Are you sure you want to delete this?
                  </Typography.p>
                </GridLayoutItem>
                <GridLayoutItem
                  style={{
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <Button
                    style={{ marginRight: 5 }}
                    fillMode={"solid"}
                    themeColor={"error"}
                    onClick={() =>
                      handleDeleteAction(DebitNoteTaxItemDeleteIndex)
                    }
                    type="button"
                  >
                    Delete
                  </Button>
                  <DialogCloseButton themeColor="error" />
                </GridLayoutItem>
              </GridLayout>
            </>
          </AppDialog>
        )}
        {fieldArrayRenderProps.value &&
          fieldArrayRenderProps.value.length > 0 &&
          fieldArrayRenderProps.value.map(
            (debit_note_duty_and_taxes: any, index: number) => (
              <GridLayoutItem key={index}>
                <GridLayout
                  style={{ marginRight: 15, padding: 5, position: "relative" }}
                  gap={{ rows: 10, cols: 5 }}
                  cols={[
                    { width: "49%" },
                    { width: "25%" },
                    { width: "8%" },
                    { width: "18%" },
                  ]}
                >
                  <GridLayoutItem></GridLayoutItem>
                  <GridLayoutItem ref={InputRef}>
                    <Field
                      wrapperStyle={{ margin: 0 }}
                      name={`debit_note_duty_and_taxes.${index}.ledger_id`}
                      placeholder="search Ledger"
                      // isAddNew={true}
                      // addNewLink="account/create"
                      component={FormIncrementalSearch}
                      onChange={
                        (e: any) =>
                          handleChange(e, index, fieldArrayRenderProps)

                        // handleProductChange(e, index, fieldArrayRenderProps)
                      }
                      fetchIncrementalData={(search: any) =>
                        handleSearchLedger(search, index)
                      }
                      options={
                        fieldArrayRenderProps.value[index]?.ledger_options?.map(
                          (item: any) => {
                            return {
                              value: item?.id,
                              label: item?.account_name,
                            };
                          }
                        ) || []
                      }
                    />
                  </GridLayoutItem>
                  {/* <GridLayoutItem></GridLayoutItem> */}
                  <GridLayoutItem>
                    <Field
                      wrapperClassName="right-alighned-field"
                      wrapperStyle={{ margin: 0 }}
                      name={`debit_note_duty_and_taxes.${index}.gst_per`}
                      placeholder="0"
                      min="0"
                      disabled={fieldArrayRenderProps.value[
                        index
                      ]?.ledger_name?.includes("GST")}
                      // validator={requiredValidator}
                      component={FormNumericTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperClassName="right-alighned-field"
                      wrapperStyle={{ margin: 0 }}
                      name={`debit_note_duty_and_taxes.${index}.gst_amount`}
                      placeholder="0"
                      format="n2"
                      // ref={(el: any) => (refs.current[index] = el)}
                      // disabled={
                      //   fieldArrayRenderProps.value[index].ledger_id
                      //     ? false
                      //     : true
                      // }
                      component={FormNumericTextField}
                      onKeyDown={(e: any) =>
                        handleKeyEvent(e, index, fieldArrayRenderProps)
                      }
                    />
                  </GridLayoutItem>
                  {/* <GridLayoutItem
                    style={{
                      position: "sticky",
                      top: 0,
                      right: 0,
                      zIndex: 9,
                    }}
                  >
                    <IconButton
                      onClick={() => handleOpenDeleteDialog(index)}
                      disabled={fieldArrayRenderProps.value.length === 1}
                      themeColor={"error"}
                      size={"small"}
                      fillMode={"solid"}
                      type="button"
                      style={{
                        position: "absolute",
                        height: 32,
                        width: 32,
                        top: 0,
                        right: 0,
                      }}
                    >
                      <MdDelete
                        className="absolute-position"
                        style={{ fontSize: "16px" }}
                      />
                    </IconButton>
                  </GridLayoutItem> */}
                </GridLayout>
              </GridLayoutItem>
            )
          )}
      </GridLayout>
    </>
  );
};

export default debitNoteDutyAndTaxArray;
