import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Typography } from "@progress/kendo-react-common";
import {
  Field,
  FieldArray,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import FormTextField from "../../components/formFields/FormTextField";
import FormDatePicker from "../../components/formFields/FormDateField";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import ShadowCard from "../../components/common/ShadowCard";
import { Button } from "@progress/kendo-react-buttons";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { LoadingPanel } from "../../components/layout/Loading";
import { clearPackingDetails } from "./packingSlice";
import {
  createPacking,
  getPackingById,
  getPackingItemCurrentStock,
  updatePacking,
} from "./services/packing.services";
import { IUser } from "../user/userModel";
import { getAllActiveUsers } from "../user/services/user.services";
import FormNumericTextField from "../../components/formFields/FormNumericTextField";
import { IItem } from "../Item/itemModel";
import {
  getAllItemIncremental,
  getItemById,
  getPackingMaterialItemIncremental,
} from "../Item/services/item.services";
// import { getCurrentStockLotNo } from "../inward/services/inward.services";
import { getCurrentStock } from "../stocktransfer/services/stockTransfer.services";
import moment from "moment";
import {
  formatIndianNumberForQty,
  getLocalStorageItem,
} from "../../_helper/helper";
import FormIncrementalSearch from "../../components/formFields/FormIncrementalSearch";
import { FINANCIAL_YEAR } from "../../_contstants/common";
import { findAllActiveProcess } from "../process/services/process.services";
import { IProcess } from "../process/processModel";
import { getGRNByItemID } from "../inwardreturn/services/inwardReturn.services";
import PackingMaterialItemDetailsArray from "./PackingMaterialItemDetailsArray";
import FormTextArea from "../../components/formFields/FormTextArea";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}

const QtyPercentageWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const taken_qty = formRenderProps.valueGetter("taken_qty");
  const wastage_qty = formRenderProps.valueGetter("wastage_qty");

  useEffect(() => {
    const lossPercentage = (wastage_qty / taken_qty) * 100;
    formRenderProps.onChange("loss_percentage", {
      value: `${lossPercentage ? lossPercentage?.toFixed(2) : 0} %`,
    });
  }, [taken_qty, wastage_qty]);

  return null;
};

const QtychangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const taken_qty = formRenderProps.valueGetter("taken_qty");
  const used_qty = formRenderProps.valueGetter("used_qty");
  const wastage_qty = formRenderProps.valueGetter("wastage_qty");

  useEffect(() => {
    formRenderProps.onChange("wastage_qty", {
      value: taken_qty - used_qty,
    });
  }, [taken_qty, used_qty]);

  if (wastage_qty < 0) {
    formRenderProps.onChange("wastage_qty", {
      value: 0,
    });
  }

  return null;
};

const LotNochangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const packing_guid = location.state?.packing_guid;
  // const isFetchGrnOption = React.useRef(true);
  const raw_item = formRenderProps.valueGetter("raw_item");
  const lot_no = formRenderProps.valueGetter("lot_no");
  const process_id = formRenderProps.valueGetter("process_id");
  const current_stock = formRenderProps.valueGetter("current_stock");

  const fetchGRNOptions = useCallback(
    async (raw_item: number, process_id: number) => {
      if (raw_item && process_id) {
        const grnPayload = {
          item_id: raw_item ? raw_item : null,
          process_id: process_id ? process_id : null,
          warehouse_id: 1,
        };
        const response = await dispatch(getGRNByItemID(grnPayload));
        const result =
          response.payload
            ?.filter((grn: any) => grn.item_id === raw_item)
            .map((grnno: any) => ({
              value: `${grnno?.grn_no}`,
              label: `${grnno?.grn_no} - ${formatIndianNumberForQty(
                grnno?.current_stock || 0
              )}`,
            })) || [];
        formRenderProps.onChange(`lotNoOptions`, {
          value: result,
        });
      } else {
        formRenderProps.onChange(`lotNoOptions`, {
          value: [],
        });
        formRenderProps.onChange(`lot_no`, {
          value: null,
        });
      }
    },
    [dispatch, formRenderProps]
  );

  useEffect(() => {
    fetchGRNOptions(raw_item, process_id);
  }, [raw_item, process_id]);
  // useEffect(() => {
  //   if (isFetchGrnOption.current && packing_guid) {
  //     fetchGRNOptions(raw_item, lot_no);
  //     isFetchGrnOption.current = false;
  //   }
  // }, [packing_guid, raw_item, lot_no]);

  // useEffect(() => {
  //   if (raw_item) {
  //     dispatch(getCurrentStockLotNo(raw_item ? raw_item : null));
  //   } else {
  //     formRenderProps.onChange("current_stock", {
  //       value: null,
  //     });
  //   }
  // }, [raw_item]);

  useEffect(() => {
    if (raw_item && lot_no && process_id) {
      const fetchStock = async () => {
        const payload = {
          item_id: raw_item ? raw_item : null,
          grn_no: lot_no ? lot_no : "",
          process_id: process_id ? +process_id : null,
          warehouse_id: 1,
        };
        const res = await dispatch(getCurrentStock(payload));
        formRenderProps.onChange("current_stock", {
          value: res?.payload?.actual_stock,
        });
      };

      fetchStock();
    } else {
      formRenderProps.onChange("current_stock", {
        value: null,
      });
    }
  }, [raw_item, lot_no, process_id]);

  useEffect(() => {
    if ((current_stock === null || current_stock < 1) && !packing_guid) {
      formRenderProps.onChange("taken_qty", {
        value: 0,
      });
      formRenderProps.onChange("used_qty", {
        value: 0,
      });
    }
  }, [current_stock]);

  return null;
};

const UserChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const location = useLocation();
  const UserID = getLocalStorageItem("UserID");
  const machine_cleaning_guid = location.state?.machine_cleaning_guid;

  useEffect(() => {
    if (!machine_cleaning_guid) {
      formRenderProps.onChange("verify_by", {
        value: UserID ? +UserID : null,
      });
    }
  }, [UserID]);

  return null;
};

const PackingItemChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const dispatch = useAppDispatch();
  const packing_item = formRenderProps.valueGetter("packing_item");

  // const packing_item = formRenderProps.valueGetter("packing_item");
  const ready_qty_in_nos = formRenderProps.valueGetter("ready_qty_in_nos");
  const mfg_date = formRenderProps.valueGetter("mfg_date");
  const taken_qty = formRenderProps.valueGetter("taken_qty");
  const used_qty = formRenderProps.valueGetter("used_qty");
  const isCurrentRef = useRef(true);
  const isLossPercentageRef = useRef(true);
  const [conversionNo, setConversionNo] = useState<any>(null);

  const fetchItem = async () => {
    if (packing_item) {
      const res: any = await dispatch(getItemById({ id: +packing_item }));
      if (res.meta.requestStatus === "fulfilled") {
        const currentDate = moment(mfg_date);
        const targetDate = currentDate.add(res.payload?.expiry_days, "days");
        formRenderProps.onChange("exp_date", {
          value: targetDate.toDate(),
        });
        // formRenderProps.onChange("ready_qty_in_nos", {
        //   value:
        //     used_qty /
        //     (res.payload?.weight_number ? res.payload?.weight_number : 1),
        // });
        setConversionNo(
          res.payload?.weight_number ? res.payload?.weight_number : 1
        );
      }
    }
  };
  const fetchConversation = async () => {
    if (packing_item) {
      const res: any = await dispatch(getItemById({ id: +packing_item }));
      if (res.meta.requestStatus === "fulfilled") {
        setConversionNo(
          res.payload?.weight_number ? res.payload?.weight_number : 1
        );
      }
    }
  };

  useEffect(() => {
    if (!isCurrentRef.current) {
      fetchItem();
    } else {
      fetchConversation();
      isCurrentRef.current = false;
    }
  }, [packing_item, mfg_date]);

  useEffect(() => {
    if (!isLossPercentageRef.current) {
      formRenderProps.onChange("used_qty", {
        value: ready_qty_in_nos / +conversionNo || 0,
      });
    } else {
      isLossPercentageRef.current = false;
    }
  }, [ready_qty_in_nos]);

  useEffect(() => {
    if (used_qty > taken_qty) {
      formRenderProps.onChange("ready_qty_in_nos", {
        value: taken_qty * +conversionNo,
      });
    }
  }, [used_qty]);

  return null;
};

const InnerItemChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const packing_item_details = formRenderProps.valueGetter(
    "packing_item_details"
  );
  const isFetchStock = React.useRef(true);
  const packing_guid = location.state?.packing_guid;

  const fetchCurrentStock = useCallback(
    async (index: number, item: any) => {
      if (item?.item_id) {
        const response = await dispatch(
          getPackingItemCurrentStock(item.item_id)
        );
        formRenderProps.onChange(`packing_item_details.${index}.current_qty`, {
          value: response.payload?.actual_stock || 0,
        });
      }
    },
    [dispatch, formRenderProps]
  );

  useEffect(() => {
    if (isFetchStock.current && packing_guid) {
      packing_item_details.forEach((item: any, index: number) => {
        fetchCurrentStock(index, item);
      });
      isFetchStock.current = false;
    }
  }, [packing_guid]);

  return null;
};

const CreatePacking: React.FC = () => {
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const packing_guid = location.state?.packing_guid;
  const loading = useAppSelector((state) => state.packing.loading);
  const PackingDetail = useAppSelector((state) => state.packing.PackingDetail);
  const UserList = useAppSelector((state) => state.user.UserList);
  // const LotNoList = useAppSelector((state) => state.inward.LotNoList);
  const ProcessList = useAppSelector((state) => state.process.ProcessList);

  const [formKey, setFormKey] = useState(1);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [PackingDetail]);

  useEffect(() => {
    dispatch(getAllActiveUsers());
    dispatch(findAllActiveProcess());

    return () => {
      dispatch(clearPackingDetails());
    };
  }, []);

  useEffect(() => {
    if (packing_guid) {
      const payload = {
        packing_guid: packing_guid,
      };
      dispatch(getPackingById(payload));
      // fetchGRNOptions(PackingDetail?.item_id);
    }
  }, [packing_guid]);

  const handleItemSearchChange = useCallback(
    async (search: string, field: string, formRenderProps: FormRenderProps) => {
      const result = await dispatch(getAllItemIncremental({ search }));
      formRenderProps.onChange(field, {
        value: result.payload,
      });
    },
    [dispatch]
  );

  const handlePackingItemSearchChange = useCallback(
    async (search: string, field: string, formRenderProps: FormRenderProps) => {
      const result = await dispatch(
        getPackingMaterialItemIncremental({ search })
      );
      formRenderProps.onChange(field, {
        value: result.payload,
      });
    },
    [dispatch]
  );

  const handleSubmit = async (values: any) => {
    if (packing_guid) {
      try {
        const updatePayload = {
          id: PackingDetail?.id,
          packing_guid: PackingDetail?.packing_guid,
          packing_date: values?.packing_date
            ? moment(values?.packing_date).format("YYYY-MM-DD")
            : "",
          raw_item: values?.raw_item ? +values?.raw_item : null,
          lot_no: values?.lot_no ? values?.lot_no : "",
          packing_item: values?.packing_item ? +values?.packing_item : null,
          batch_no: values?.batch_no ? +values?.batch_no : null,
          taken_qty: values?.taken_qty ? +values?.taken_qty : 0,
          used_qty: values?.used_qty ? +values?.used_qty : 0,
          process_id: values?.process_id ? +values?.process_id : null,
          loss_percentage: values.loss_percentage
            ? +values.loss_percentage?.replace("%", "")
            : null,
          wastage_qty: values?.wastage_qty ? +values?.wastage_qty : 0,
          ready_qty_in_nos: values?.ready_qty_in_nos
            ? +values?.ready_qty_in_nos
            : 0,
          mfg_date: values?.mfg_date
            ? moment(values?.mfg_date).format("YYYY-MM-DD")
            : "",
          exp_date: values?.exp_date
            ? moment(values?.exp_date).format("YYYY-MM-DD")
            : "",
          remarks: values?.remarks ? values?.remarks : "",
          verify_by: values?.verify_by ? +values?.verify_by : null,
          financial_year: FINANCIAL_YEAR,
          packing_item_details: values?.packing_item_details
            ? values?.packing_item_details?.map((packing: any) => {
                return {
                  id: packing?.id ? +packing?.id : null,
                  item_id: packing?.item_id ? +packing?.item_id : null,
                  used_qty: packing?.used_qty ? +packing?.used_qty : null,
                };
              })
            : [],
        };
        const response = await dispatch(updatePacking(updatePayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate("/packing");
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    } else {
      try {
        const insertPayload = {
          packing_date: values?.packing_date
            ? moment(values?.packing_date).format("YYYY-MM-DD")
            : "",
          raw_item: values?.raw_item ? +values?.raw_item : null,
          lot_no: values?.lot_no ? values?.lot_no : "",
          packing_item: values?.packing_item ? +values?.packing_item : null,
          batch_no: values?.batch_no ? +values?.batch_no : null,
          process_id: values?.process_id ? +values?.process_id : null,
          loss_percentage: values.loss_percentage
            ? +values.loss_percentage?.replace("%", "")
            : null,
          taken_qty: values?.taken_qty ? +values?.taken_qty : 0,
          used_qty: values?.used_qty ? +values?.used_qty : 0,
          wastage_qty: values?.wastage_qty ? +values?.wastage_qty : 0,
          ready_qty_in_nos: values?.ready_qty_in_nos
            ? +values?.ready_qty_in_nos
            : 0,
          mfg_date: values?.mfg_date
            ? moment(values?.mfg_date).format("YYYY-MM-DD")
            : "",
          exp_date: values?.exp_date
            ? moment(values?.exp_date).format("YYYY-MM-DD")
            : "",
          remarks: values?.remarks ? values?.remarks : "",
          verify_by: values?.verify_by ? +values?.verify_by : null,
          financial_year: FINANCIAL_YEAR,
          packing_item_details: values?.packing_item_details
            ? values?.packing_item_details?.map((packing: any) => {
                return {
                  item_id: packing?.item_id ? +packing?.item_id : null,
                  used_qty: packing?.used_qty ? +packing?.used_qty : null,
                };
              })
            : [],
        };
        const response = await dispatch(createPacking(insertPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate("/packing");
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    }
  };

  const handlePackingNoChange = (ev: React.KeyboardEvent<HTMLInputElement>) => {
    if (ev.key === "Enter" || ev.key === "Tab") {
      ev.preventDefault();
      if ((ev.target as HTMLInputElement).value) {
        const payload = {
          packing_no: +(ev.target as HTMLInputElement).value,
          financial_year: FINANCIAL_YEAR,
        };
        dispatch(getPackingById(payload));
      }
    }
  };

  // if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      {loading && <LoadingPanel gridRef={gridRef} />}
      <Form
        key={formKey}
        onSubmit={handleSubmit}
        initialValues={PackingDetail}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12 }}>
              <GridLayout
                style={{ marginRight: 30 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                ]}
              >
                <GridLayoutItem colSpan={4}>
                  <Typography.h4>
                    {packing_guid ? "Update Packing" : "Add Packing"}
                  </Typography.h4>
                </GridLayoutItem>
                {packing_guid && (
                  <GridLayoutItem>
                    <Field
                      wrapperClassName="w-100"
                      name="packing_no"
                      onKeyDown={handlePackingNoChange}
                      label="Packing No"
                      placeholder="Packing No"
                      component={FormTextField}
                      validator={requiredValidator}
                      astrike={true}
                    />
                  </GridLayoutItem>
                )}
                <GridLayoutItem>
                  <Field
                    name="packing_date"
                    label="Packing Date"
                    format="dd/MM/yyyy"
                    component={FormDatePicker}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem colSpan={packing_guid ? 2 : 3}></GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    label="Department"
                    name={"process_id"}
                    placeholder="Material taken from Department"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={ProcessList?.map((process: IProcess) => {
                      return {
                        value: process?.id,
                        label: process?.process_name,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="raw_item"
                    label="Raw Item"
                    placeholder="Raw Item"
                    component={FormIncrementalSearch}
                    validator={requiredValidator}
                    astrike={true}
                    fetchIncrementalData={(search: any) =>
                      handleItemSearchChange(
                        search,
                        `raw_item_options`,
                        formRenderProps
                      )
                    }
                    options={
                      formRenderProps
                        .valueGetter("raw_item_options")
                        ?.map((item: IItem) => {
                          return {
                            value: item.id,
                            label: item.product_name,
                          };
                        }) || []
                    }
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="lot_no"
                    label="GRN / Lot No"
                    placeholder="GRN / Lot No"
                    component={FormSelectionField}
                    // validator={requiredValidator}
                    options={formRenderProps.valueGetter("lotNoOptions") || []}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="stock-label center-alighned-field d-flex flex-wrap justify-content-center"
                    wrapperStyle={{ color: "red" }}
                    name="current_stock"
                    label="Current Stock"
                    disabled={true}
                    placeholder="0"
                    component={FormNumericTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="packing_item"
                    label="Packing Item"
                    placeholder="Packing Item"
                    component={FormIncrementalSearch}
                    validator={requiredValidator}
                    astrike={true}
                    fetchIncrementalData={(search: any) =>
                      handleItemSearchChange(
                        search,
                        `packing_item_options`,
                        formRenderProps
                      )
                    }
                    options={
                      formRenderProps
                        .valueGetter("packing_item_options")
                        ?.map((item: IItem) => {
                          return {
                            value: item.id,
                            label: item.product_name,
                          };
                        }) || []
                    }
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="batch_no"
                    label="Batch No"
                    placeholder="Batch No"
                    component={FormTextField}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="mfg_date"
                    label="MFG Date"
                    format="dd/MM/yyyy"
                    component={FormDatePicker}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="exp_date"
                    label="EXP Date"
                    format="dd/MM/yyyy"
                    component={FormDatePicker}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="w-100 right-alighned-field"
                    name="taken_qty"
                    label="Taken Qty (KG)"
                    placeholder="0"
                    min={0}
                    max={
                      packing_guid
                        ? PackingDetail?.taken_qty +
                          formRenderProps.valueGetter("current_stock")
                        : formRenderProps.valueGetter("current_stock")
                    }
                    component={FormNumericTextField}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={3}
                  style={{ display: "flex", gap: 10 }}
                >
                  <Field
                    wrapperClassName="w-100 right-alighned-field"
                    name="ready_qty_in_nos"
                    label="Ready Qty in NOS"
                    placeholder="0"
                    min={0}
                    disabled={
                      formRenderProps.valueGetter("packing_item") ? false : true
                    }
                    // max={formRenderProps.valueGetter("current_stock")}
                    component={FormNumericTextField}
                    validator={requiredValidator}
                    astrike={true}
                  />
                  {/* </GridLayoutItem>
                <GridLayoutItem> */}
                  <Field
                    wrapperClassName="w-100 right-alighned-field"
                    name="used_qty"
                    label="Used Qty"
                    placeholder="0"
                    min={0}
                    disabled={true}
                    max={formRenderProps.valueGetter("taken_qty")}
                    component={FormNumericTextField}
                    validator={requiredValidator}
                    astrike={true}
                  />
                  {/* </GridLayoutItem>
                <GridLayoutItem> */}
                  <Field
                    wrapperClassName="w-100 center-alighned-field red-highlight-field d-flex flex-column align-items-center"
                    name="wastage_qty"
                    label="Packing Loss Qty"
                    placeholder="0"
                    disabled={true}
                    component={FormNumericTextField}
                  />
                  <div className="w-100">
                    <Field
                      wrapperClassName="red-highlight-field center-alighned-field d-flex flex-wrap justify-content-center"
                      name="loss_percentage"
                      label="Packing Loss Qty (%)"
                      placeholder="0"
                      disabled={true}
                      component={FormTextField}
                    />
                  </div>
                </GridLayoutItem>
                <GridLayoutItem colSpan={2}>
                  <Field
                    name="remarks"
                    label="Remarks"
                    placeholder="Remarks"
                    component={FormTextArea}
                    rows={2}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="verify_by"
                    label="Verify By"
                    placeholder="Verify By"
                    component={FormSelectionField}
                    options={UserList?.map((user: IUser) => {
                      return {
                        value: user?.id,
                        label: `${user?.first_name} ${user?.last_name}`,
                      };
                    })}
                  />
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>

            <ShadowCard style={{ padding: 12, marginTop: 10 }}>
              <GridLayout
                style={{ marginRight: 30 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                ]}
              >
                <GridLayoutItem colSpan={4}>
                  <Typography.h4>Packing Item</Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem colSpan={4}>
                  <FieldArray
                    formRenderProps={formRenderProps}
                    component={PackingMaterialItemDetailsArray}
                    handlePackingItemSearchChange={
                      handlePackingItemSearchChange
                    }
                    name="packing_item_details"
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={4}
                  style={{
                    // position: "relative",
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "end",
                    // marginTop: 10,
                  }}
                >
                  <div
                  // style={{ position: "absolute", right: 0, top: 36 }}
                  >
                    <ButtonWithLoading
                      label={packing_guid ? "Update" : "Save"}
                      type="submit"
                      disabled={!formRenderProps.allowSubmit || loading}
                      loading={loading}
                    />
                    <Button
                      type="button"
                      fillMode={"outline"}
                      themeColor={"primary"}
                      style={{ marginLeft: 4 }}
                      onClick={() => navigate("/packing")}
                    >
                      Cancel
                    </Button>
                  </div>
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
            <QtyPercentageWatcher formRenderProps={formRenderProps} />
            <QtychangeWatcher formRenderProps={formRenderProps} />
            <LotNochangeWatcher formRenderProps={formRenderProps} />
            <UserChangeWatcher formRenderProps={formRenderProps} />
            <PackingItemChangeWatcher formRenderProps={formRenderProps} />
            <InnerItemChangeWatcher formRenderProps={formRenderProps} />
          </FormElement>
        )}
      />
    </>
  );
};

export default CreatePacking;
