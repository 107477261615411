import React, { useEffect } from "react";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import { Typography } from "@progress/kendo-react-common";
import ShadowCard from "../../../components/common/ShadowCard";
import { requiredValidator } from "../../../components/formFields/CommonValidator";
import FormSelectionField from "../../../components/formFields/FormSelectionField";
import { getAllActiveEmployee } from "../../employee/services/employee.services";
import { IEmployee } from "../../employee/employeeModel";
import { checkAcessRights } from "../../../_helper/helper";
import { PRINT_ACCESS } from "../../../_contstants/common";
import {
  clearAccountAndStatutoryDetails,
  clearEmployeeDetails,
} from "../../employee/employeeSlice";

const EmployeeProvidentFundReport: React.FC = () => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.reports.loading);
  const EmployeeList = useAppSelector((state) => state.employee.EmployeeList);
  const isPrintAccess = checkAcessRights(location.pathname, PRINT_ACCESS);

  useEffect(() => {
    dispatch(getAllActiveEmployee());
    return () => {
      dispatch(clearEmployeeDetails());
      dispatch(clearAccountAndStatutoryDetails());
    };
  }, []);

  const handleSubmit = (values: any) => {
    const state = {
      user_guid: values.user_guid,
    };
    sessionStorage.setItem("providentfundformState", JSON.stringify(state));
    const reportTab = window.open("/employeesprovidentfund", "_blank");
    const interval = setInterval(() => {
      if (reportTab?.closed) {
        sessionStorage.removeItem("providentfundformState");
        clearInterval(interval);
      }
    }, 1000);
  };
  const InitialValues = {
    user_guid: "",
  };

  return (
    <GridLayout gap={{ rows: 10 }} cols={[{ width: "100%" }]}>
      <GridLayoutItem>
        <ShadowCard>
          <Form
            initialValues={InitialValues}
            onSubmit={handleSubmit}
            render={(formRenderProps: FormRenderProps) => (
              <FormElement style={{ width: "100%" }}>
                <GridLayout
                  style={{ marginRight: 20 }}
                  gap={{ rows: 10, cols: 10 }}
                  cols={[
                    { width: "33.33%" },
                    { width: "33.33%" },
                    { width: "33.33%" },
                  ]}
                >
                  <GridLayoutItem colSpan={3}>
                    <Typography.h4
                      style={{ marginBottom: 0, marginLeft: "3px" }}
                    >
                      Employee's Provident Fund Form
                    </Typography.h4>
                  </GridLayoutItem>

                  <GridLayoutItem>
                    <Field
                      name="user_guid"
                      label="Employee"
                      placeholder="Select Employee"
                      component={FormSelectionField}
                      options={EmployeeList.map((emp: IEmployee) => ({
                        value: emp.user_guid,
                        label: emp.name,
                      }))}
                      validator={requiredValidator}
                      astrike={true}
                    />
                  </GridLayoutItem>

                  <GridLayoutItem>
                    <div style={{ marginTop: "35px" }}>
                      {isPrintAccess && (
                        <ButtonWithLoading
                          label={"View"}
                          type="submit"
                          disabled={!formRenderProps.allowSubmit || loading}
                          loading={loading}
                        />
                      )}
                    </div>
                  </GridLayoutItem>
                </GridLayout>
              </FormElement>
            )}
          />
        </ShadowCard>
      </GridLayoutItem>
    </GridLayout>
  );
};
export default EmployeeProvidentFundReport;
