import {} from "react";

import ButtonWithLoading from "../../../components/common/ButtonWithLoading";

const FormNo29 = () => {
  return (
    <>
      <div
        className="formno29"
        style={{
          textAlign: "center",
        }}
      >
        <ButtonWithLoading
          buttonStyle={{ position: "fixed", right: 0, top: 0, zIndex: 100 }}
          label={"Print"}
          className="no-visible-print-btn"
          type="button"
          onClick={() => window.print()}
        />
        <h6
          style={{
            fontSize: "17px",
            fontWeight: "900",
            paddingTop: "20px",
          }}
          className="m-0"
        >
          FORM NO.29
        </h6>
        <span>(Prescribed under Rule 1 1 1)</span>

        <h6
          style={{
            fontSize: "17px",
            fontWeight: "900",
            paddingTop: "20px",
          }}
        >
          Register of accidents, major accidents and dangerous occurrences
        </h6>

        <div
          style={{
            paddingTop: "20px",
          }}
        >
          <table
            style={{
              border: "1px solid #ccc",
              width: "90%",
              margin: "0 auto",
            }}
          >
            <thead>
              <tr>
                <th rowSpan={3}>Serial Number</th>
                <th rowSpan={3}>Date & Time of notice</th>
                <th rowSpan={3}>
                  Name and Serial number of the person <br /> inovlved in the
                  <br /> register of adult/Child register
                </th>
                <th rowSpan={3}>ESIC Insurance Number</th>
                <th rowSpan={3}>Date</th>

                <th
                  colSpan={5}
                  style={{
                    writingMode: "horizontal-tb",
                    padding: "0px",
                    textAlign: "center",
                    transform: "rotate(360deg)",
                  }}
                >
                  Injury/dangerous occurrence
                </th>
                <th rowSpan={3}>Name of the person giving the notice</th>
                <th rowSpan={3}>
                  Number, address and <br /> occupation of two witnesses
                </th>

                <th rowSpan={3}>Date of return of injured person to work</th>
                <th rowSpan={3}>
                  Number of days the injured person was absent <br /> from the
                  work including holidays and off days
                </th>
                <th rowSpan={3}>
                  Signature and designation of the person who <br /> makes the
                  entry with date.
                </th>
              </tr>

              <tr>
                <th>Time</th>
                <th>Place</th>
                <th>
                  Cause of accident/major <br /> Accident / Dangerous Occurrence
                </th>
                <th>Nature of Injury/dangerous Occurrence</th>
                <th>
                  What exactly was the injured person, if any <br /> doing at
                  that notice
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>2</td>
                <td>3</td>
                <td>4</td>
                <td>5</td>
                <td>6</td>
                <td>7</td>
                <td>8</td>
                <td>9</td>
                <td>10</td>
                <td>11</td>
                <td>12</td>
                <td>13</td>
                <td>14</td>
                <td>15</td>
              </tr>
              <tr>
                <td
                  colSpan={15}
                  style={{
                    fontSize: "18px",
                    fontWeight: "900",
                    height: "50px",
                  }}
                >
                  "No Accidents, major accidents and dangerous occurrences in
                  this month."
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default FormNo29;
