import React, { useEffect } from "react";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import { Typography } from "@progress/kendo-react-common";
import ShadowCard from "../../../components/common/ShadowCard";
import { requiredValidator } from "../../../components/formFields/CommonValidator";
import { checkAcessRights, getYearsArray } from "../../../_helper/helper";
import FormSelectionField from "../../../components/formFields/FormSelectionField";
import { getAllActiveEmployee } from "../../employee/services/employee.services";
import { IEmployee } from "../../employee/employeeModel";
import { PRINT_ACCESS } from "../../../_contstants/common";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}

const DateChangeWatcher = ({ formRenderProps }: FormChangeWatcherProps) => {
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();
  const previousYear = currentMonth === 1 ? currentYear - 1 : currentYear;
  useEffect(() => {
    formRenderProps.onChange("year", {
      value: `${previousYear}`,
    });
  }, []);
  return null;
};
const RegisterOfLeaveWithWagesForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.reports.loading);
  const EmployeeList = useAppSelector((state) => state.employee.EmployeeList);
  const isPrintAccess = checkAcessRights(location.pathname, PRINT_ACCESS);
  useEffect(() => {
    dispatch(getAllActiveEmployee());
  }, []);

  const handleSubmit = (values: any) => {
    const state = {
      employee_id: values.employee_id,
      year: values.year,
      FormTitle: 18,
    };
    sessionStorage.setItem("leavewithwagescardState", JSON.stringify(state));
    const reportTab = window.open("/leavewtihwages", "_blank");
    const interval = setInterval(() => {
      if (reportTab?.closed) {
        sessionStorage.removeItem("leavewithwagescardState");
        clearInterval(interval);
      }
    }, 1000);
  };
  const InitialValues = {
    employee_id: "",
    year: "",
  };
  return (
    <GridLayout gap={{ rows: 10 }} cols={[{ width: "100%" }]}>
      <GridLayoutItem>
        <ShadowCard>
          <Form
            initialValues={InitialValues}
            onSubmit={handleSubmit}
            render={(formRenderProps: FormRenderProps) => (
              <FormElement style={{ width: "100%" }}>
                <GridLayout
                  style={{ marginRight: 20 }}
                  gap={{ rows: 10, cols: 10 }}
                  cols={[
                    { width: "40%" },
                    { width: "60%" },
                  ]}
                >
                  <GridLayoutItem colSpan={2}>
                    <Typography.h4
                      style={{ marginBottom: 0, marginLeft: "3px" }}
                    >
                      Register Of Leave With Wages
                    </Typography.h4>
                  </GridLayoutItem>
                  <GridLayoutItem className="d-flex  gap-2">
                    <Field
                      name="year"
                      label="Year"
                      placeholder="Year"
                      wrapperClassName="w-25"
                      component={FormSelectionField}
                      options={getYearsArray(2000, new Date().getFullYear() + 1)
                        ?.reverse()
                        ?.map((year: any) => {
                          return {
                            value: year?.value,
                            label: year?.label,
                          };
                        })}
                      validator={requiredValidator}
                      astrike={true}
                    />
                    <Field
                      name="employee_id"
                      label="Employee"
                       wrapperClassName="w-75"
                      placeholder="Select Employee"
                      component={FormSelectionField}
                      options={EmployeeList.map((emp: IEmployee) => ({
                        value: emp.id,
                        label: emp.name,
                      }))}
                      validator={requiredValidator}
                      astrike={true}
                    />
                  </GridLayoutItem>
                  <DateChangeWatcher formRenderProps={formRenderProps} />
                  <GridLayoutItem>
                    <div style={{ marginTop: "35px" }}>
                      {isPrintAccess && (
                        <ButtonWithLoading
                          label={"View"}
                          type="submit"
                          disabled={!formRenderProps.allowSubmit || loading}
                          loading={loading}
                        />
                      )}
                    </div>
                  </GridLayoutItem>
                </GridLayout>
              </FormElement>
            )}
          />
        </ShadowCard>
      </GridLayoutItem>
    </GridLayout>
  );
};
export default RegisterOfLeaveWithWagesForm;
