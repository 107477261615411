import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  IAreaAssignToDistributorInitial,
  IAssignAreaList,
  IDistributor,
} from "./areaAssignToDistributorModel";
import {
  createAssingArea,
  deleteAssignArea,
  findAllAreaAssignDistributor,
  getAllAreaAssignToDistributor,
} from "./services/areaAssignToDistributor.services";

const initialState: IAreaAssignToDistributorInitial = {
  loading: false,
  formloading:false,
  error: "",
  AssignAreaID: null,
  DistributorList: [],
  AssignAreaList: [],
  AssignAreaDetails: {
    city_id: null,
    distributor_id: null,
    area_id: "",
  },
};
const areaAssignSlice = createSlice({
  name: "areaAssign",
  initialState,
  reducers: {
    clearAssignAreaDetails: (state) => {
      state.AssignAreaDetails = initialState.AssignAreaDetails;
    },
    setAssignAreaID: (state, action) => {
      state.AssignAreaID = action.payload;
    },
  },
  extraReducers: (builder) => {
    /* For FindALl Distributor */
    builder.addCase(findAllAreaAssignDistributor.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      findAllAreaAssignDistributor.fulfilled,
      (state, action: PayloadAction<IDistributor[]>) => {
        state.loading = false;
        state.DistributorList = action.payload;
      }
    );
    builder.addCase(findAllAreaAssignDistributor.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.DistributorList = [];
    });

    /* For Assign Area To Distributor */

    builder.addCase(getAllAreaAssignToDistributor.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllAreaAssignToDistributor.fulfilled,
      (state, action: PayloadAction<IAssignAreaList[]>) => {
        state.loading = false;
        state.AssignAreaList = action.payload || [];
      }
    );
    builder.addCase(getAllAreaAssignToDistributor.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.AssignAreaList = [];
    });

    /* For createAssingArea */

    builder.addCase(createAssingArea.pending, (state) => {
      state.formloading = true;
      state.error = "";
    });
    builder.addCase(createAssingArea.fulfilled, (state) => {
      state.formloading = false;
    });
    builder.addCase(createAssingArea.rejected, (state, action) => {
      state.formloading = false;
      state.error = action.error.message || "Something went wrong";
    });

    /* For deleteAssignArea */

    builder.addCase(deleteAssignArea.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteAssignArea.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteAssignArea.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const { clearAssignAreaDetails, setAssignAreaID } =
  areaAssignSlice.actions;
export default areaAssignSlice.reducer;
