import React, { useEffect } from "react";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import FormDatePicker from "../../../components/formFields/FormDateField";
import moment from "moment";
import { Typography } from "@progress/kendo-react-common";
import ShadowCard from "../../../components/common/ShadowCard";
import * as XLSX from "xlsx";
import {
  Grid,
  GridColumn,
  GridCustomCellProps,
  GridCustomHeaderCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
} from "@progress/kendo-react-grid";
import {
  CompositeFilterDescriptor,
  filterBy,
} from "@progress/kendo-data-query";
import {
  HeaderThElement,
  PagerTargetEvent,
} from "@progress/kendo-react-data-tools";
import AlertBox from "../../../components/common/AlertBox";
import { LoadingPanel } from "../../../components/layout/Loading";
import { requiredValidator } from "../../../components/formFields/CommonValidator";
import { Button } from "@progress/kendo-react-buttons";
import { saveAs } from "file-saver";
import { checkAcessRights } from "../../../_helper/helper";
import { EXCEL_EXPORT_ACCESS, PRINT_ACCESS } from "../../../_contstants/common";
import Logo from "../../../assets/Images/logo.png";
import FormSelectionField from "../../../components/formFields/FormSelectionField";
import { IUser } from "../../user/userModel";
import {
  clearOfficeStaffOutReportDetails,
  clearOfficeStaffOutReportList,
  setOfficeStaffOutReport,
} from "../reportsSlice";
import { getOfficeStaffOutReport } from "../services/reports.services";
import { getAllActiveUsers } from "../../user/services/user.services";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}
const DatechangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const fromdate = moment()?.startOf("month")?.toDate() || "";
  const todate = moment()?.toDate() || "";
  useEffect(() => {
    formRenderProps.onChange("from_date", {
      value: fromdate,
    });
    formRenderProps.onChange("to_date", {
      value: todate,
    });
  }, []);

  return null;
};

const OfficeStaffOutReportForm: React.FC = () => {
  const gridRef = React.useRef<any>(null);
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.reports.loading);
  const UserList = useAppSelector((state) => state.user.UserList);
  const OfficeStaffOutReportList = useAppSelector(
    (state) => state.reports.OfficeStaffOutReportList
  );
  const OfficeStaffOutReportDetails = useAppSelector(
    (state) => state.reports.OfficeStaffOutReportDetails
  );

  useEffect(() => {
    dispatch(getAllActiveUsers());

    return () => {
      dispatch(clearOfficeStaffOutReportList());
      dispatch(clearOfficeStaffOutReportDetails());
    };
  }, []);

  const handleSubmit = (values: any) => {
    const payload = {
      user_name: values?.user_name ? values?.user_name : "",
      from_date: values?.from_date
        ? moment(values?.from_date).format("YYYY-MM-DD")
        : null,
      to_date: values?.to_date
        ? moment(values?.to_date).format("YYYY-MM-DD")
        : null,
    };
    dispatch(setOfficeStaffOutReport(payload));
    dispatch(getOfficeStaffOutReport(payload));
  };
  return (
    <GridLayout gap={{ rows: 10 }} cols={[{ width: "100%" }]}>
      <GridLayoutItem>
        <ShadowCard>
          <Form
            initialValues={OfficeStaffOutReportDetails}
            onSubmit={handleSubmit}
            render={(formRenderProps: FormRenderProps) => (
              <FormElement style={{ width: "100%" }}>
                <GridLayout
                  style={{ marginRight: 10 }}
                  gap={{ rows: 10, cols: 10 }}
                  cols={[
                    { width: "25%" },
                    { width: "25%" },
                    { width: "25%" },
                    { width: "25%" },
                  ]}
                >
                  <GridLayoutItem colSpan={4}>
                    <Typography.h4
                      style={{ marginBottom: 0, marginLeft: "3px" }}
                    >
                      Office Staff Out Report
                    </Typography.h4>
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperStyle={{ width: "100%" }}
                      name="from_date"
                      label="From Date"
                      format="dd/MM/yyyy"
                      component={FormDatePicker}
                      validator={requiredValidator}
                      astrike={true}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperStyle={{ width: "100%" }}
                      name="to_date"
                      label="To Date"
                      format="dd/MM/yyyy"
                      minDate={moment(
                        formRenderProps.valueGetter("from_date")
                      ).toDate()}
                      component={FormDatePicker}
                      validator={requiredValidator}
                      astrike={true}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperClassName="w-100"
                      name="user_name"
                      label="Employee"
                      placeholder="Employee Name"
                      component={FormSelectionField}
                      options={UserList?.map((user: IUser) => {
                        return {
                          value: `${user?.first_name}`,
                          label: `${user?.first_name} ${user?.last_name}`,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <div style={{ marginTop: 35 }}>
                      <ButtonWithLoading
                        label={"View"}
                        type="submit"
                        disabled={!formRenderProps.allowSubmit || loading}
                        loading={loading}
                      />
                    </div>
                  </GridLayoutItem>
                </GridLayout>
                <DatechangeWatcher formRenderProps={formRenderProps} />
              </FormElement>
            )}
          />
        </ShadowCard>
        {loading ? (
          <LoadingPanel gridRef={gridRef} />
        ) : (
          OfficeStaffOutReportList &&
          OfficeStaffOutReportList.length > 0 && (
            <ShadowCard style={{ marginTop: 10 }}>
              <OfficeStaffOutGridComponent />
            </ShadowCard>
          )
        )}
      </GridLayoutItem>
    </GridLayout>
  );
};

interface PageState {
  skip: number;
  take: number;
}

const initialDataState: PageState = { skip: 0, take: 50 };

const OfficeStaffOutGridComponent: React.FC = () => {
  const isExcelAccess = checkAcessRights(
    location.pathname,
    EXCEL_EXPORT_ACCESS
  );
  const isPrintAccess = checkAcessRights(location.pathname, PRINT_ACCESS);

  //   const loading = useAppSelector((state) => state.reports.loading);
  const OfficeStaffOutReportList = useAppSelector(
    (state) => state.reports.OfficeStaffOutReportList
  );
  const OfficeStaffOutReportDetails = useAppSelector(
    (state) => state.reports.OfficeStaffOutReportDetails
  );

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);
  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();

  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All"
        ? OfficeStaffOutReportList.length
        : event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
    setPage({ skip: 0, take: page.take });
  };

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className="table-header"
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  const handleExportExcel = () => {
    const datetime = moment().format("DD-MM-YYYY HH-mm");
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.table_to_sheet(
      document.querySelector(".table-bordered")
    );
    XLSX.utils.book_append_sheet(wb, ws, "OfficeStaffOutReport");

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(data, `Office Staff Out Report ${datetime}`);
  };

  const printTable = (data: any[]) => {
    let printbuttons = document.querySelector(
      ".printbuttons"
    ) as HTMLElement | null;
    let report_container = document.querySelector(
      ".report-container"
    ) as HTMLElement | null;

    if (printbuttons) printbuttons.style.display = "none";
    if (report_container) report_container.style.padding = "20px 0";

    let tableRows = data.length;
    let maxRows = tableRows;
    let pageCount = Math.ceil(tableRows / maxRows);
    let content = "";

    for (let i = 0; i < pageCount; i++) {
      content += '<div class="print-page">';
      content +=
        '<table class="table table-bordered" style="font-size:10px;margin-bottom:30px;">';
      content += "<tr>";
      content += `<td rowSpan="3" style="width:15%" ><img src="${Logo}" width="100%" /></td>`;
      content +=
        '<td colSpan="2" style="text-align: center;font-size: 14px;font-weight: 600;color: gray;">Harihar Foods Pvt Ltd.</td>';
      content += "</tr>";
      content += "<tr>";
      content +=
        '<td colSpan="2"><h3 style="margin:0;">Office Staff Out Report</h3></td>';
      content += "</tr>";
      content += "<tr>";
      content += `<td style="text-align:end;">Page : ${i + 1}</td>`;
      content += "</tr>";

      content += "</table>";

      content += '<table class="table table-bordered" style="font-size:10px;">';
      content += '<thead style="background:lightgray;">';
      content += "<tr>";
      content += '<th style="width:30px;">Sr. No.</th>';
      content += "<th>Staff Out No.</th>";
      content += "<th>Staff Out Date</th>";
      content += "<th>Employee Name</th>";
      content += "<th>Reason</th>";
      content += "<th>In Time</th>";
      content += "<th>Out Time</th>";
      content += "<th>Approved By Name</th>";
      content += "</tr>";
      content += "</thead>";
      content += "<tbody>";

      let startRow = i * maxRows;
      let endRow = Math.min((i + 1) * maxRows, tableRows);

      for (let j = startRow; j < endRow; j++) {
        content += "<tr>";
        content += `<td style="text-align:center;">${j + 1}</td>`;
        content += `<td style="text-align:center;">${data[j]?.staff_out_no}</td>`;
        content += `<td style="text-align:center;">${
          data[j]?.staff_out_date
            ? moment(data[j]?.staff_out_date, "DD/MM/YYYY").format(
                "DD MMM YYYY"
              )
            : ""
        }</td>`;
        content += `<td>${data[j]?.user_name}</td>`;
        content += `<td>${data[j]?.reason_for_out}</td>`;
        content += `<td style="text-align:center;">${data[j]?.in_time}</td>`;
        content += `<td style="text-align:center;">${data[j]?.out_time}</td>`;
        content += `<td >${data[j]?.createdby_name}</td>`;
        content += "</tr>";
      }

      content += "</tbody>";
      content += "</table>";

      // content +=
      //   '<table class="table table-bordered" style="font-size:11px; width:100%; margin:30px 0; text-align:center">';
      // content += "<tr>";
      // content += `<td style="width:33%">Prepared By: <br>Mr. GAURANG MEHTA</br></td>`;
      // content += `<td style="width:34%">Document Status: </br>CONTROL COPY</td>`;
      // content += `<td style="width:33%">Approved By: <br>Mr. PARAG SHAH</br> </td>`;
      // content += "</tr>";
      // content += "</table>";

      content += "</div>";
    }

    let printWindow = window.open("", "_blank");
    if (!printWindow) {
      console.error("Failed to open print preview window.");
      return;
    }
    printWindow.document.write(`
        <html>
        <head>
          <title>Print Preview</title>
          <style>
            @page { margin: 1cm; }
            .print-page { page-break-after: always; }
            .table-bordered { width: 100%; border-collapse: collapse; }
            .table-bordered th, .table-bordered td { border: 1px solid black; padding: 8px; }
            body { font-family: Arial, sans-serif; }
          </style>
        </head>
        <body>
          ${content}
          <script>
            window.onload = function() {
              window.print();
            };
          </script>
        </body>
        </html>
      `);
    printWindow.document.close();

    if (printbuttons) printbuttons.style.display = "block";
    if (report_container) report_container.style.padding = "20px 10px";
  };

  return (
    <>
      {OfficeStaffOutReportList && OfficeStaffOutReportList.length ? (
        <GridLayout
          //   style={{ marginRight: 30 }}
          gap={{ rows: 10, cols: 10 }}
          cols={[{ width: "100%" }]}
        >
          <GridLayoutItem className="d-flex justify-content-between align-items-center">
            <Typography.h4 className="m-0">
              Office Staff Out Report List{" "}
              {(OfficeStaffOutReportDetails?.from_date ||
                OfficeStaffOutReportDetails?.to_date) && (
                <span style={{ fontSize: 13 }}>
                  {"("}
                  {OfficeStaffOutReportDetails?.from_date
                    ? moment(
                        OfficeStaffOutReportDetails?.from_date,
                        "YYYY-MM-DD"
                      ).format("DD/MM/YYYY")
                    : ""}
                  {OfficeStaffOutReportDetails?.from_date &&
                    OfficeStaffOutReportDetails?.to_date &&
                    " - "}
                  {OfficeStaffOutReportDetails?.to_date
                    ? moment(
                        OfficeStaffOutReportDetails?.to_date,
                        "YYYY-MM-DD"
                      ).format("DD/MM/YYYY")
                    : ""}
                  {")"}
                </span>
              )}
            </Typography.h4>
            <div>
              {isExcelAccess && (
                <Button
                  type="button"
                  fillMode={"solid"}
                  themeColor={"primary"}
                  onClick={handleExportExcel}
                  style={{ marginRight: 5 }}
                >
                  Excel Export
                </Button>
              )}
              {isPrintAccess && (
                <Button
                  type="button"
                  fillMode={"solid"}
                  themeColor={"primary"}
                  onClick={() => printTable(OfficeStaffOutReportList)}
                >
                  Print
                </Button>
              )}
            </div>
          </GridLayoutItem>
          <GridLayoutItem>
            <Grid
              className="table-bordered responsive-table"
              style={{ maxHeight: "calc(100vh - 152px)" }}
              filterable={true}
              filter={filter}
              data={
                filter
                  ? filterBy(OfficeStaffOutReportList, filter).slice(
                      page.skip,
                      page.take + page.skip
                    )
                  : OfficeStaffOutReportList.slice(
                      page.skip,
                      page.take + page.skip
                    )
              }
              onFilterChange={handleFilterChange}
              skip={page.skip}
              take={page.take}
              total={OfficeStaffOutReportList.length}
              pageable={{
                buttonCount: 5,
                pageSizes: [10, 25, 50, 100, 500, "All"],
                pageSizeValue: pageSizeValue,
                type: "input",
              }}
              onPageChange={pageChange}
              cells={{
                headerCell: HeaderCustomCell,
                data: MyDataCustomCell,
              }}
            >
              <GridColumn
                field="UniqueNo"
                title="Sr. No."
                width={100}
                filterable={false}
              />
              <GridColumn
                field="staff_out_no"
                title="Staff Out No."
                width={150}
              />
              <GridColumn field="user_name" title="Employee Name" width={200} />
              <GridColumn
                field="staff_out_date"
                title="Staff Out Date"
                width={150}
                cell={(props) => (
                  <td>
                    {moment(
                      props.dataItem?.staff_out_date,
                      "DD/MM/YYYY"
                    ).format("DD MMM YYYY")}
                  </td>
                )}
              />
              <GridColumn field="reason_for_out" title="Reason" width={300} />
              <GridColumn
                field="in_time"
                title="In Time"
                filterable={false}
                width={150}
              />
              <GridColumn
                field="out_time"
                title="Out Time"
                width={150}
                filterable={false}
              />
              <GridColumn
                field="createdby_name"
                title="Approved By Name"
                width={200}
              />
            </Grid>
          </GridLayoutItem>
        </GridLayout>
      ) : (
        <AlertBox />
      )}
    </>
  );
};
export default OfficeStaffOutReportForm;
