import { useEffect, useRef } from "react";
import { COMPANY_NAME } from "../../../_contstants/common";
import { upperCase } from "lodash";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  getAccountStatustoryByUserID,
  getEmployeeByID,
} from "../../employee/services/employee.services";
import { LoadingPanel } from "../../../components/layout/Loading";
import moment from "moment";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import {
  clearAccountAndStatutoryDetails,
  clearEmployeeDetails,
} from "../../employee/employeeSlice";

const EmployeeProvidentFundOrganizationForm = () => {
  const state = JSON.parse(
    sessionStorage.getItem("providentfundformState") || "{}"
  );
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);
  const loading = useAppSelector((state) => state.employee.loading);
  const EmployeeDetails = useAppSelector(
    (state) => state.employee.EmployeeDetails
  );
  const AccountAndStatutoryDetails = useAppSelector(
    (state) => state.employee.AccountAndStatutoryDetails
  );

  const setPrintStyles = () => {
    const style = document.createElement("style");
    style.type = "text/css";
    style.innerHTML = `
        @media print {
          @page {
        margin: 0.5cm 0.6cm;
          
          }
        }
      `;
    document.head.appendChild(style);
  };
  const fetchReport = async () => {
    const user_guid = state.user_guid;
    setPrintStyles();
    await dispatch(getEmployeeByID(user_guid));
    await dispatch(getAccountStatustoryByUserID(user_guid));
  };

  useEffect(() => {
    const handleTabClose = () => {
      sessionStorage.removeItem("providentfundformState");
    };
    fetchReport();
    return () => {
      dispatch(clearEmployeeDetails());
      dispatch(clearAccountAndStatutoryDetails());
      window.removeEventListener("unload", handleTabClose);
    };
  }, []);

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      <div>
        <ButtonWithLoading
          buttonStyle={{ position: "fixed", right: 0, top: 0, zIndex: 100 }}
          label={"Print"}
          className="no-visible-print-btn"
          type="button"
          onClick={() => window.print()}
        />
        <div
          style={{
            fontSize: "11px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <h6 className="m-0">{COMPANY_NAME}</h6>
              <p className="m-0">519/1 PHASE IV, VATVA,GIDC, AHMEDABAD-15</p>
            </div>
            <div>
              <p
                style={{
                  fontWeight: "900",
                  fontSize: "14px",
                }}
              >
                New Form No.11 - Declaration
              </p>
            </div>
          </div>

          <div
            style={{
              textAlign: "center",
            }}
          >
            <h6
              className="m-0"
              style={{
                fontWeight: "900",
              }}
            >
              EMPLOYEE'S PROVIDENT FUNDS ORGANISATION
            </h6>
            <p className="m-0">
              Employee's Provident Fund Shceme 1952 (Paragraph 34 & 57) &<br />
              Employee's Pension Scheme 1995 (Paragraph 24) <br />
              (Declaration by a person taking up enployment in any establishment
              on which EPF Scheme 1952 and / or EPS, 1995 is applicable)
            </p>
            <h6
              style={{
                textAlign: "right",
                fontWeight: "900",
              }}
              className="m-0"
            >
              P.F.No:- GJVAT0024846000/{AccountAndStatutoryDetails?.pf_no}
            </h6>
          </div>
          <div className="provident-employee-detail">
            <table
              style={{
                width: "100%",
              }}
            >
              <tbody>
                <tr>
                  <td
                    style={{
                      border: "1px solid #000 !important",
                    }}
                  >
                    1
                  </td>
                  <td>Name of the member: (IN CAPITAL LETTERS) </td>
                  <td
                    style={{
                      textTransform: "uppercase",
                      verticalAlign: "center",
                    }}
                  >
                    {" "}
                    {`${EmployeeDetails?.first_name} ${EmployeeDetails?.middle_name} ${EmployeeDetails?.last_name} `}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "1px solid #000 !important",
                    }}
                  >
                    2
                  </td>
                  <td>Father's Name [ ] Spouce's Name [ ] </td>
                  <td style={{ textTransform: "uppercase" }}>
                    {EmployeeDetails?.middle_name}&nbsp;
                    {EmployeeDetails?.last_name}&nbsp;
                    {EmployeeDetails?.spouse_name ? "/" : ""}&nbsp;
                    {EmployeeDetails?.spouse_name}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "1px solid #000 !important",
                    }}
                  >
                    3
                  </td>
                  <td>Date Of Birth: </td>
                  <td style={{ textTransform: upperCase }}>
                    {EmployeeDetails?.dob
                      ? moment(EmployeeDetails?.dob).format("DD/MM/YYYY")
                      : ""}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "1px solid #000 !important",
                    }}
                  >
                    4
                  </td>
                  <td>Gender : </td>
                  <td>{EmployeeDetails?.gender}</td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "1px solid #000 !important",
                    }}
                  >
                    5
                  </td>
                  <td>
                    Marital Statues (Maried/Unmanned/Widow/Widower/Divorcee)
                  </td>
                  <td style={{ textTransform: "uppercase" }}>
                    {EmployeeDetails?.marital_status}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "1px solid #000 !important",
                    }}
                  >
                    6
                  </td>
                  <td>
                    a) Email id <br />
                    a) Mobile No
                  </td>
                  <td>
                    {EmployeeDetails?.email}
                    <br />
                    {EmployeeDetails?.mobile_no}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "1px solid #000 !important",
                    }}
                  >
                    7
                  </td>
                  <td>
                    Whether earlier a member of Employee's Provident Fund Scheme
                    1952
                  </td>
                  <td>Yes/No</td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "1px solid #000 !important",
                    }}
                  >
                    8
                  </td>
                  <td>
                    Whether earlier a member of Employee's Pension Scheme, 1995
                  </td>
                  <td>Yes/No</td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "1px solid #000 !important",
                    }}
                  >
                    9
                  </td>
                  <td>
                    Previous Employement Details [ if Yes to 7 AND/OR & above]
                    <br />
                    a) Universal Account Number:
                    <br /> b) Previous PF Account Number:
                    <br /> c) Date of exit from previous <br /> d) Scheme
                    Certificate No. (if issued) <br /> e) Pension Payment Order
                    (PPO) No. (if issued)
                  </td>
                  <td>
                    <p style={{ marginBottom: "4rem" }}>
                      {AccountAndStatutoryDetails?.uan_no}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "1px solid #000 !important",
                    }}
                  >
                    10
                  </td>
                  <td>
                    a) International Worker: <br />
                    b) If Yes state country of origin(India/Name Of Other
                    Country) <br />
                    c) Passport No.: <br />
                    d) Validity of passport [(DD/MM/YYYY) TO (DD/MM/YYYY)]
                  </td>
                  <td
                    style={{
                      verticalAlign: "baseline",
                      textAlign: "start",
                      lineHeight: "25px",
                    }}
                  >
                    No
                    <br />
                    {AccountAndStatutoryDetails?.passport_no}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "1px solid #000 !important",
                    }}
                  >
                    11
                  </td>
                  <td>
                    KYC Details: (attach self attested copies of following KYCs)
                    <br />
                    a) Bank Account No. & IFSC Code:
                    <br /> b) AADHAR No.:
                    <br /> c) Permanent Account Number (PAN), if available
                  </td>
                  <td style={{ textTransform: upperCase }}>
                    {" "}
                    <p
                      className="m-0"
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "start",
                      }}
                    >
                      <span
                        style={{
                          width: "50%",
                        }}
                      >
                        <br />
                        {AccountAndStatutoryDetails?.account_no} <br />
                        {AccountAndStatutoryDetails?.aadharcard} <br />
                        {AccountAndStatutoryDetails?.pancard} <br />
                      </span>{" "}
                      <span
                        style={{
                          textAlign: "start",
                          textTransform: "uppercase",
                        }}
                      >
                        <br />
                        IFSC: &nbsp;{AccountAndStatutoryDetails?.ifsc_code}
                      </span>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            style={{
              padding: "2px 20px 0px 10px",
            }}
          >
            <h6
              style={{
                textAlign: "center",
                fontSize: "13px",
              }}
              className="m-0"
            >
              UNDERTAKING
            </h6>

            <p className="m-0">
              1) Certified that the particulars are true to the best of my
              knowledge
            </p>
            <p className="m-0">
              2) I authorize EPFO to use my Aadhar for
              verification/authentication/eKYC purpose for service delivery.
            </p>
            <p className="m-0">
              3) Kindly transfer th efunds and service details if applicable,
              from the previos PF Account as declared above to the present PF
              Account.
            </p>
            <p
              className="m-0"
              style={{
                textIndent: "10px",
              }}
            >
              (The transfer would be possible only if the identified KYC detail
              approved by previous employer has been verified by present
              employer using his Digital Signature Certificate)
            </p>
            <p className="m-0">
              4) In case of changes in above details the same will be intimated
              to employer at the earliest.
            </p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>
                <p className="m-0">Date</p>
                <p className="m-0">Place</p>
              </div>
              <div>
                <p>Signature of Member</p>
              </div>
            </div>
          </div>

          <div>
            <div className="present-employer-pf">
              <h6
                className="m-0"
                style={{
                  fontSize: "13px",
                  textAlign: "center",
                }}
              >
                DECLARATION BY PRESENT EMPLOYER
              </h6>

              <ol>
                <li>
                  The member Mr./Ms./Mrs
                  <b>
                    <span
                      style={{
                        textTransform: "uppercase",
                      }}
                    >
                      {" "}
                      &nbsp;
                      {`${EmployeeDetails?.first_name} ${EmployeeDetails?.middle_name} ${EmployeeDetails?.last_name} `}
                    </span>
                  </b>
                  has joined on &nbsp;
                  <b>
                    {EmployeeDetails?.date_of_joining
                      ? moment(EmployeeDetails?.date_of_joining).format(
                          "DD/MM/YYYY"
                        )
                      : ""}
                    &nbsp;
                  </b>
                  and has been alloted PF number &nbsp;
                  <b>{AccountAndStatutoryDetails?.pf_no}</b>
                </li>

                <li>
                  In case the person was earlier not a member of EPF Scheme,
                  1952 and EPS, 1955:
                  <p className="m-0">
                    .(Post allotment of UAN) The UAN alloted for the member is
                    <b> {AccountAndStatutoryDetails?.uan_no}</b>
                  </p>
                  <p className="m-0">.Please Tick the Appropriate Option</p>
                </li>
                <p
                  style={{
                    marginBottom: "0px",
                    marginLeft: "-8px",
                  }}
                >
                  The KYC details of the above member in the UAN database
                </p>
                <p className="m-0">- Have not been uploaded</p>
                <p className="m-0">- Have not been uploaded but not approved</p>
                <p className="m-0">
                  - Have not been uploaded but not approved with DSC
                </p>

                <li>
                  In case the person was earlier a member of EPF Scheme, 1952
                  and EPS, 1955:
                  <p className="m-0">
                    . The Above PF account number /UAN of the member as
                    mentioned in (A) above has been tagged with his/her
                    UAN/Previous
                    <br /> Member ID as declared by member
                  </p>
                  <p className="m-0">.Please Tick the Appropriate Option:</p>
                  <p
                    className="m-0"
                    style={{
                      paddingLeft: "10px",
                    }}
                  >
                    The KYC details of the above member in the UAN database have
                    been approved with Digital Signature Certificate and <br />
                    transfer request has been generated on portal. <br /> As the
                    DSC of establishment are not registered with EPFO the member
                    has been informed to the physical claim (Form 13)for
                    transfer fo funds from his previous establishment.
                  </p>
                </li>
              </ol>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "0px 15px",
            }}
          >
            <p className="m-0">Date</p>
            <p className="m-0">
              Signature of Employer with Seal Of Establishment
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeProvidentFundOrganizationForm;
