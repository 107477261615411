import React, { useEffect, useRef } from "react";
// import { getEmployeeRegisterReport } from "../services/reports.services";
import {
  useAppDispatch,
  // useAppDispatch,
  useAppSelector,
} from "../../../app/hooks";
import { LoadingPanel } from "../../../components/layout/Loading";
// import { MONTHS_MAP } from "../../../_contstants/common";
// import { convertToWords } from "../../../_helper/helper";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import {
  COMPANY_ADDRESS,
  COMPANY_NAME,
  MONTHS_MAP,
} from "../../../_contstants/common";
import { getSalaryRegisterReport } from "../services/reports.services";

const WageRegisterReport = () => {
  const state = JSON.parse(sessionStorage.getItem("wageregisterState") || "{}");

  const setPrintStyles = () => {
    const style = document.createElement("style");
    style.type = "text/css";
    style.innerHTML = `
      @media print {
        @page {
          margin-left: 0.6cm;
          margin-right: 0.6cm;
        }
      }
    `;
    document.head.appendChild(style);
  };

  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);
  const loading = useAppSelector((state) => state.reports.loading);

  const WageRegisterReportList = useAppSelector(
    (state) => state.reports.SalaryRegisterList
  );
  const fetchReport = async () => {
    const payload = {
      location: state.location,
      payout_month_year: `${String(state.payout_month).padStart(2, "0")}-${
        state.payout_year
      }`,
    };
    setPrintStyles();
    await dispatch(getSalaryRegisterReport(payload));
    // window.print();
  };

  useEffect(() => {
    const handleTabClose = () => {
      sessionStorage.removeItem("wageregisterState");
    };
    fetchReport();
    return () => {
      window.removeEventListener("unload", handleTabClose);
    };
  }, []);

  const style: Record<string, React.CSSProperties> = {
    headingText: {
      fontSize: "14px",
      fontWeight: "bold",
      margin: 0,
    },
    headingTextSub: {
      fontSize: "12px",
      margin: 0,
    },
    hr: { margin: "1rem 0", borderTop: "1px dashed #000" },
  };
  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      {WageRegisterReportList && WageRegisterReportList.length > 0 && (
        <div>
          <ButtonWithLoading
            buttonStyle={{ position: "fixed", right: 0, top: 0, zIndex: 100 }}
            label={"Print"}
            className="no-visible-print-btn"
            type="button"
            onClick={() => window.print()}
          />
          <div>
            <div
              className="d-flex align-items-end justify-content-between"
              style={{ borderBottom: "1px solid #ccc" }}
            >
              <div style={{ width: "25%" }}></div>
              <div style={{ textAlign: "center", width: "50%" }}>
                <p style={{ fontSize: "16px", fontWeight: "bold", margin: 0 }}>
                  FORM B <br /> WAGE REGISTER
                </p>
              </div>
              <div style={{ width: "25%" }}>
                <p style={style.headingTextSub}>
                  <b>LIN No:</b>
                </p>
              </div>
            </div>
            <div
              className="d-flex justify-content-between py-2"
              style={{ borderBottom: "1px solid #ccc" }}
            >
              <div className="text-start">
                <p style={style.headingText}> {COMPANY_NAME}</p>
                <p style={style.headingTextSub}>{COMPANY_ADDRESS}</p>
              </div>
            </div>
            <div className="d-flex align-items-end justify-content-between py-1">
              <div>
                <p style={style.headingTextSub}>
                  <b>Department :-</b>
                </p>
              </div>
              <div style={{ textAlign: "start" }}>
                <p style={style.headingTextSub}>
                  <b>
                    For The Month Of {MONTHS_MAP[state.payout_month]} -{" "}
                    {state.payout_year}
                  </b>
                </p>
              </div>
            </div>
            <table className="wage-register-print-table">
              <thead>
                <tr>
                  <th rowSpan={2}>Sr. No</th>
                  <th rowSpan={2}>Employee Code</th>
                  <th
                    rowSpan={2}
                    style={{ textAlign: "start", lineHeight: "14px" }}
                  >
                    <p style={{ whiteSpace: "nowrap" }}>Employee Name</p>
                    <p>Designation</p>
                    <p>P.F. No</p>
                    <p>UAN No.</p>
                  </th>
                  <th rowSpan={2}>Rate of Wages</th>
                  <th rowSpan={2}>No of Days Worked</th>
                  <th rowSpan={2}>OT Hours Worked</th>
                  <th rowSpan={2}>
                    <p>Basic</p>
                    <p>Sp.Basic</p>
                  </th>
                  <th rowSpan={2} style={{ padding: "5px 15px" }}>
                    <p>DA</p>
                    <p>HRA</p>
                  </th>
                  <th rowSpan={2}>
                    <p>Overtime</p>
                    <p>Wash</p>
                    <p>Prod.Ins.Al</p>
                    <p>Adv.Bonus</p>
                    <p>Adv.Leave</p>
                    <p>Adv.Std.Be</p>
                    <p>Other</p>
                  </th>
                  <th rowSpan={2} style={{ padding: "5px 15px" }}>
                    Gross Salary
                  </th>
                  <th colSpan={3}>DEDUCTION</th>
                  <th rowSpan={2}>Total Deduction</th>
                  <th rowSpan={2}>Net Payment</th>
                  <th rowSpan={2}>Employer Share pf Welfare fund</th>
                  <th rowSpan={2}>
                    Rec. By the employee / Bank Transaction ID
                  </th>
                  <th rowSpan={2}>Date Of Payment / Remarks</th>
                </tr>
                <tr>
                  <th style={{ padding: "5px 15px" }}>
                    <p>P.F.</p>
                    <p>ESI</p>
                    <p>P.T.</p>
                  </th>
                  <th>
                    <p>I.T.</p>
                    <p>LWF</p>
                    <p>Insuran.</p>
                    <p>TDS</p>
                  </th>
                  <th style={{ padding: "5px 10px" }}>
                    <p>Adv</p>
                    <p>Loan</p>
                    <p>Oth.Ded</p>
                  </th>
                </tr>
              </thead>
              <tbody>
                {WageRegisterReportList.map((item: any, index: number) => (
                  <tr className="wages-register-print-table-row">
                    <td>{index + 1}</td>
                    <td>{item?.employee_code_string}</td>
                    <td
                      style={{
                        textTransform: "uppercase",
                        textAlign: "start",
                        lineHeight: "16px",
                        fontSize: "12px",
                        minWidth: "200px",
                      }}
                    >
                      <p>
                        <b>{item?.employee_name}</b>
                      </p>
                      <p>{item?.designation_name}</p>
                      <p>{item?.pf_no}</p>
                      <p>{item?.uan_no}</p>
                    </td>
                    <td className="wage-register-table-cell">
                      <p>
                        <span>BASIC</span>
                        <span>
                          {Number(item?.actual_basic || 0)?.toFixed(2)}
                        </span>
                      </p>
                      <p>
                        <span>DA</span>
                        <span>{Number(item?.actual_da || 0)?.toFixed(2)}</span>
                      </p>
                      <p>
                        <span>HRA</span>
                        <span>{Number(item?.actual_hra || 0)?.toFixed(2)}</span>
                      </p>
                      <p>
                        <span>Other All</span>
                        <span>
                          {(
                            Number(item?.actual_wash || 0) +
                            Number(item?.actual_prod_ins_all || 0) +
                            Number(item?.actual_adv_bonus || 0) +
                            Number(item?.actual_adv_leave || 0) +
                            Number(item?.actual_adv_std_be || 0) +
                            Number(item?.actual_food || 0) +
                            Number(item?.actual_other_earning || 0)
                          )?.toFixed(2)}
                        </span>
                      </p>
                      <br />
                      <p style={{ padding: "2px 0" }}>
                        <span>Total</span>
                        <span>
                          {(
                            Number(item?.actual_basic || 0) +
                            Number(item?.actual_hra || 0) +
                            Number(item?.actual_da || 0) +
                            Number(item?.actual_wash || 0) +
                            Number(item?.actual_prod_ins_all || 0) +
                            Number(item?.actual_adv_bonus || 0) +
                            Number(item?.actual_adv_leave || 0) +
                            Number(item?.actual_adv_std_be || 0) +
                            Number(item?.actual_food || 0) +
                            Number(item?.actual_other_earning || 0)
                          )?.toFixed(2)}
                        </span>
                      </p>
                    </td>
                    <td className="wage-register-table-cell">
                      <p>
                        <span>WD</span>
                        <span>
                          {Number(item?.working_days || 0)?.toFixed(2)}
                        </span>
                      </p>
                      <p>
                        <span>WO</span>
                        <span>
                          {" "}
                          {Number(item?.weekly_off || 0)?.toFixed(2)}
                        </span>
                      </p>
                      <p>
                        <span>PD</span>
                        <span>
                          {Number(item?.present_days || 0)?.toFixed(2)}
                        </span>
                      </p>
                      <p>
                        <span>PH</span>
                        <span>{Number(item?.ph || 0)?.toFixed(2)}</span>
                      </p>
                      <br />
                      <p style={{ padding: "2px 0" }}>
                        <span>TOT</span>
                        <span>
                          {(
                            Number(item?.present_days || 0) +
                            Number(item?.weekly_off || 0)
                          )?.toFixed(2)}
                        </span>
                      </p>
                    </td>
                    <td>0.00</td>
                    <td>{Number(item?.basic || 0)}</td>
                    <td>
                      <p>{Number(item?.da || 0)}</p>
                      <p>{Number(item?.hra || 0)}</p>
                    </td>
                    <td>
                      <p>0</p>
                      <p>{Number(item?.wash || 0)}</p>
                      <p>{Number(item?.prod_ins_all || 0)}</p>
                      <p>{Number(item?.adv_bonus || 0)}</p>
                      <p>{Number(item?.adv_leave || 0)}</p>
                      <p>{Number(item?.adv_std_be || 0)}</p>
                      <p>{Number(item?.other_earning || 0)}</p>
                    </td>
                    <td>
                      <b>{Number(item?.total_income || 0)}</b>
                    </td>
                    <td>
                      <p>{Number(item?.pf || 0)}</p>
                      <p>{Number(item?.esi || 0)}</p>
                      <p>{Number(item?.pt || 0)}</p>
                    </td>
                    <td>
                      <p>{Number(item?.it || 0)}</p>
                      <p>{Number(item?.lwf || 0)}</p>
                      <p>{Number(item?.lwf || 0)}</p>
                      <p>{Number(item?.tds || 0)}</p>
                    </td>
                    <td>
                      <p>{Number(item?.advance || 0)}</p>
                      <p>{Number(item?.loan || 0)}</p>
                      <p>{Number(item?.other || 0)}</p>
                    </td>
                    <td>{Number(item?.total_deduction || 0)}</td>
                    <td>
                      <b>{Number(item?.payable_amount || 0)?.toFixed(2)}</b>
                    </td>
                    <td>{Number(item?.pf || 0)}</td>
                    <td
                      style={{
                        textTransform: "uppercase",
                        textAlign: "start",
                        lineHeight: "16px",
                        fontSize: "11px",
                      }}
                    >
                      <p>
                        <b>{item?.bank_name}</b>
                      </p>
                      <p>{item?.account_no}</p>
                      <p>{item?.ifsc_code}</p>
                    </td>
                    <td></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
};

export default WageRegisterReport;
