import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Field,
  FieldArray,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import ShadowCard from "../../components/common/ShadowCard";
import {
  GridLayout,
  GridLayoutItem,
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import FormTextField from "../../components/formFields/FormTextField";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Button } from "@progress/kendo-react-buttons";
import { ITEMTYPEOPTIONS, STATUSARRAY } from "../../_contstants/common";
import {
  createItem,
  createItemMarginRate,
  createItemMrp,
  deleteItemMarginRate,
  getItemById,
  getLatestItemCode,
  getPackingMaterialItemIncremental,
  updateItem,
  updateItemMarginRate,
  updateItemMrp,
} from "./services/item.services";
import {
  clearItemDetail,
  clearItemMarginRateID,
  setItemTab,
} from "./itemSlice";
import { LoadingPanel } from "../../components/layout/Loading";
import { useLocation, useNavigate } from "react-router-dom";
import FormRadioGroup from "../../components/formFields/FormRadioGroup";
import FormCheckbox from "../../components/formFields/FormCheckbox";
import { getAllItemGroupIncremental } from "../ItemGroup/services/itemgroup.services";
import { findAllActiveItemCategory } from "../Item Category/services/Itemcategory.services";
import { IItemCategory } from "../Item Category/ItemCategoryModel";
import { findAllActiveUnit } from "../unit/services/unit.services";
import { IUnit } from "../unit/unitModel";
import { getAllActiveGST } from "../gst/services/gst.services";
import { IGst } from "../gst/gstModel";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormRichTextField from "../../components/formFields/FormRichTextField";
import ItemWarehouseArray from "./ItemWarehouseArray";
import { findAllActiveWarehouse } from "../warehouse/services/warehouse.services";
import FormNumericTextField from "../../components/formFields/FormNumericTextField";
import AppDrawer from "../../components/muidrawer/Drawer";
import CreateItemCategory from "../Item Category/CreateItemCategory";
import CreateUnit from "../unit/CreateUnit";
import { closed } from "../../components/muidrawer/muiDrawerSlice";
import ButtonForAll from "../../components/common/Button";
import { closeDialog } from "../../components/dialog/dialogSlice";
import moment from "moment";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { FaPlus } from "react-icons/fa";
import MRPDetailsArray from "./MRPDetailsArray";
import TabStripTitle from "../../components/common/TabStripTitle";
import MarginStructureDetails from "./MarginStructureDetails";
import FormIncrementalSearch from "../../components/formFields/FormIncrementalSearch";

const MANAGE_STOCK = [
  { label: "Normal", value: 1 },
  { label: "Batchwise", value: 2 },
  { label: "Lot Wise", value: 3 },
];
const NEGATIVE_QUTY = [
  { label: "True", value: true },
  { label: "False", value: false },
];
const LOW_STOCK_WARNING = [
  { label: "True", value: true },
  { label: "False", value: false },
];

interface ChangeWatcherProps {
  formRenderProps: FormRenderProps;
}

const MRPChangeWatcher: React.FC<ChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const mrp_before_discount =
    formRenderProps.valueGetter("mrp_before_discount") || 0;
  const discount = formRenderProps.valueGetter("discount") || 0;

  useEffect(() => {
    formRenderProps.onChange("mrpamount", {
      value: +mrp_before_discount - +discount,
    });
    if (!mrp_before_discount) {
      formRenderProps.onChange("discount", {
        value: "",
      });
    }
  }, [mrp_before_discount, discount]);
  return null;
};

interface ItemCodeRetrieveChangeWatcher {
  formRenderProps: FormRenderProps;
  setLastItemCode: any;
}
const ItemCodeRetrieveWatcher: React.FC<ItemCodeRetrieveChangeWatcher> = ({
  formRenderProps,
  setLastItemCode,
}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const item_guid = location.state?.item_guid;
  const Pathname = location?.pathname?.split("/")[1];
  const item_type = formRenderProps.valueGetter("item_type");

  useEffect(() => {
    const fetchLatestItemCode = async () => {
      const payload = {
        item_type:
          Pathname === "rawmaterialitem"
            ? "Raw Material"
            : Pathname === "semifinisheditem"
            ? "Semi Finished"
            : Pathname === "finisheditem"
            ? "Finished"
            : Pathname === "packingmaterialitem"
            ? "Packing Material"
            : item_type
            ? item_type
            : "",
      };
      const res = await dispatch(getLatestItemCode(payload));
      if (res.meta.requestStatus === "fulfilled") {
        // formRenderProps.onChange("latest_item_code", {
        //   value: res.payload?.product_code || "",
        // });
        setLastItemCode(res.payload?.product_code || "");
      }
    };

    if (!item_guid) {
      fetchLatestItemCode();
    } else {
      setLastItemCode();
    }
  }, [item_type, item_guid]);
  return null;
};

const CreateItem: React.FC = () => {
  const gridRef = useRef<any>(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const item_guid = location.state?.item_guid;
  const Pathname = location?.pathname?.split("/")[1];

  const ItemDetail = useAppSelector((state) => state.item.ItemDetail);
  const loading = useAppSelector((state) => state.item.loading);
  const itemGroupLoading = useAppSelector((state) => state.itemgroup.loading);
  // const itemLoading = useAppSelector((state) => state.item.incrementalLoading);
  // const ItemGroupList = useAppSelector(
  //   (state) => state.itemgroup.ItemGroupList
  // );
  const ItemCategoryList = useAppSelector(
    (state) => state.itemCategory.ItemCategoryList
  );
  const UnitList = useAppSelector((state) => state.unit.UnitList);
  const GstList = useAppSelector((state) => state.gst.GstList);
  const isDrawerOpen = useAppSelector((state) => state.muidrawer.open);
  const drawerName = useAppSelector((state) => state.muidrawer.name);
  const Item_tab = useAppSelector((state) => state.item.Item_tab);

  const [showMRP, setShowMRP] = useState(false);
  const [showMarginRate, setShowMarginRate] = useState(false);
  const [submitAction, setSubmitAction] = useState("value");
  const [formKey, setFormKey] = useState(1);
  const [lastItemCode, setLastItemCode] = useState("");

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [ItemDetail]);

  useEffect(() => {
    if (item_guid) {
      const payload = {
        item_guid: item_guid,
      };
      dispatch(getItemById(payload));
    }
  }, [item_guid]);

  useEffect(() => {
    // dispatch(findAllActiveItemGroup());
    dispatch(findAllActiveItemCategory());
    dispatch(findAllActiveWarehouse());
    dispatch(findAllActiveUnit());
    dispatch(getAllActiveGST());

    return () => {
      dispatch(setItemTab(0));
      dispatch(clearItemDetail());
      dispatch(closed());
    };
  }, []);

  const handleSelect = (e: TabStripSelectEventArguments) => {
    // setSelected(e.selected);
    dispatch(setItemTab(e.selected));
  };

  const handleFormClose = () => {
    if (Pathname === "rawmaterialitem") {
      navigate("/rawmaterialitem");
    } else if (Pathname === "semifinisheditem") {
      navigate("/semifinisheditem");
    } else if (Pathname === "finisheditem") {
      navigate("/finisheditem");
    } else if (Pathname === "packingmaterialitem") {
      navigate("/packingmaterialitem");
    } else {
      navigate("/item");
    }
    dispatch(closed());
    dispatch(clearItemDetail());
  };

  const handleItemSearchChange = useCallback(
    async (search: string, field: string, formRenderProps: FormRenderProps) => {
      const result = await dispatch(
        getPackingMaterialItemIncremental({ search })
      );
      formRenderProps.onChange(field, {
        value: result.payload,
      });
    },
    [dispatch]
  );

  const handleItemGroupSearchChange = useCallback(
    async (search: string, field: string, formRenderProps: FormRenderProps) => {
      const result = await dispatch(getAllItemGroupIncremental({ search }));
      formRenderProps.onChange(field, {
        value: result.payload,
      });
    },
    [dispatch]
  );

  const handleSubmit = async (values: any) => {
    const item_type =
      Pathname === "rawmaterialitem"
        ? "Raw Material"
        : Pathname === "semifinisheditem"
        ? "Semi Finished"
        : Pathname === "finisheditem"
        ? "Finished"
        : Pathname === "packingmaterialitem"
        ? "Packing Material"
        : values?.item_type
        ? values?.item_type
        : "";

    if (submitAction === "value") {
      if (item_guid) {
        try {
          const editPayload = {
            item_guid: item_guid,
            item_type: item_type,
            product_name: values.product_name,
            description: values.description,
            item_group_id: values.item_group_id,
            item_category_id: values.item_category_id
              ? +values.item_category_id
              : null,
            unit_id: values.unit_id ? +values.unit_id : null,
            gst_id: values.gst_id ? +values.gst_id : null,
            manage_stock: values.manage_stock ? +values.manage_stock : null,
            product_code: values.product_code,
            opening_stock_qty: values.opening_stock_qty
              ? +values.opening_stock_qty
              : null,
            negative_qty_allowed: values.negative_qty_allowed,
            low_stock_warning: values.low_stock_warning,
            show_item_in_purchase: values.show_item_in_purchase,
            show_item_in_sales: values.show_item_in_sales,
            // mrp: values.mrp ? +values.mrp : null,
            purchase_price: values.purchase_price
              ? +values.purchase_price
              : null,
            // sales_price: values.sales_price ? +values.sales_price : null,
            // markup: values.markup ? +values.markup : null,
            // min_markup: values.min_markup ? +values.min_markup : null,
            expiry_days: values.expiry_days ? +values.expiry_days : null,
            weight_number: values.weight_number ? +values.weight_number : null,
            sequence_no: values.sequence_no ? `${values.sequence_no}` : null,
            packing_item_id: values.packing_item_id
              ? +values.packing_item_id
              : null,
            hsn_sac_code: values.hsn_sac_code ? values.hsn_sac_code : "",
            isactive: values.isactive === 1,
            ItemDeatails: values?.ItemDeatails
              ? values?.ItemDeatails?.map((item: any) => {
                  return {
                    item_id: ItemDetail?.id ? +ItemDetail?.id : 0,
                    item_qty: item?.item_qty ? +item?.item_qty : null,
                    grn_no: item?.grn_no ? `${item?.grn_no}` : "",
                    warehouse_id: item?.warehouse_id
                      ? +item?.warehouse_id
                      : null,
                  };
                })
              : [],
          };
          const response = await dispatch(updateItem(editPayload));
          if (response?.meta?.requestStatus === "fulfilled") {
            if (Pathname === "rawmaterialitem") {
              navigate("/rawmaterialitem");
            } else if (Pathname === "semifinisheditem") {
              navigate("/semifinisheditem");
            } else if (Pathname === "finisheditem") {
              navigate("/finisheditem");
            } else if (Pathname === "packingmaterialitem") {
              navigate("/packingmaterialitem");
            } else {
              navigate("/item");
            }
            dispatch(closed());
            dispatch(clearItemDetail());
          }
        } catch (error) {
          console.error("Error in handleSubmit:", error);
        }
      } else {
        try {
          const insertPayload = {
            item_type: item_type,
            product_name: values.product_name,
            description: values.description,
            item_group_id: values.item_group_id,
            item_category_id: values.item_category_id
              ? +values.item_category_id
              : null,
            unit_id: values.unit_id ? +values.unit_id : null,
            gst_id: values.gst_id ? +values.gst_id : null,
            manage_stock: values.manage_stock ? +values.manage_stock : null,
            product_code: values.product_code,
            // opening_stock_qty: values.opening_stock_qty
            //   ? +values.opening_stock_qty
            //   : null,
            negative_qty_allowed: values.negative_qty_allowed,
            low_stock_warning: values.low_stock_warning,
            show_item_in_purchase: values.show_item_in_purchase,
            show_item_in_sales: values.show_item_in_sales,
            // mrp: values.mrp ? +values.mrp : null,
            purchase_price: values.purchase_price
              ? +values.purchase_price
              : null,
            hsn_sac_code: values.hsn_sac_code ? values.hsn_sac_code : "",
            // sales_price: values.sales_price ? +values.sales_price : null,
            // markup: values.markup ? +values.markup : null,
            // min_markup: values.min_markup ? +values.min_markup : null,
            expiry_days: values.expiry_days ? +values.expiry_days : null,
            weight_number: values.weight_number ? +values.weight_number : null,
            sequence_no: values?.sequence_no ? `${values?.sequence_no}` : null,
            packing_item_id: values?.packing_item_id
              ? +values?.packing_item_id
              : null,
            isactive: values.isactive === 1,
            ItemDeatails: values?.ItemDeatails
              ? values?.ItemDeatails?.map((item: any) => {
                  return {
                    item_id: values?.item_id ? +values?.item_id : 0,
                    item_qty: item?.item_qty ? +item?.item_qty : null,
                    grn_no: item?.grn_no ? `${item?.grn_no}` : "",
                    warehouse_id: item?.warehouse_id
                      ? +item?.warehouse_id
                      : null,
                  };
                })
              : [],
          };
          const response = await dispatch(createItem(insertPayload));
          if (response?.meta?.requestStatus === "fulfilled") {
            dispatch(clearItemDetail());
            if (Pathname === "rawmaterialitem") {
              navigate("/rawmaterialitem/edit", {
                state: { item_guid: response?.payload?.item_guid },
              });
            } else if (Pathname === "semifinisheditem") {
              navigate("/semifinisheditem/edit", {
                state: { item_guid: response?.payload?.item_guid },
              });
            } else if (Pathname === "finisheditem") {
              navigate("/finisheditem/edit", {
                state: { item_guid: response?.payload?.item_guid },
              });
            } else if (Pathname === "packingmaterialitem") {
              navigate("/packingmaterialitem/edit", {
                state: { item_guid: response?.payload?.item_guid },
              });
            } else {
              navigate("/item/edit", {
                state: {
                  item_guid: response?.payload?.item_guid,
                },
              });
            }
            // dispatch(closed());
          }
        } catch (error) {
          console.error("Error in handleSubmit:", error);
          throw error;
        }
      }
    } else if (submitAction === "marginstructure") {
      try {
        if (!values?.item_margin_structure_id) {
          const insertPayload = {
            item_group_marginstructure_id:
              ItemDetail?.item_group_marginstructure_id
                ? ItemDetail?.item_group_marginstructure_id
                : 0,
            item_id: ItemDetail?.id ? ItemDetail?.id : null,
            item_group_id: ItemDetail?.item_group_id
              ? ItemDetail?.item_group_id
              : null,
            from_date: values?.from_date
              ? moment(values?.from_date).format("YYYY-MM-DD")
              : "",
            to_date: values?.to_date
              ? moment(values?.to_date).format("YYYY-MM-DD")
              : "",
            agent_margin: values?.agent_margin ? +values?.agent_margin : null,
            agent_marginvalue1: values?.agent_marginvalue1
              ? +values?.agent_marginvalue1
              : 0,
            agent_marginvalue2: values?.agent_marginvalue2
              ? +values?.agent_marginvalue2
              : 0,
            retailer_margin: values?.retailer_margin
              ? +values?.retailer_margin
              : null,
            retailer_value: values?.retailer_value
              ? +values?.retailer_value
              : 0,
            conversion_unit: values?.conversion_unit
              ? +values?.conversion_unit
              : 0,
            on_mrp_free_qty: values?.on_mrp_free_qty
              ? values?.on_mrp_free_qty
              : false,
          };
          const response = await dispatch(createItemMarginRate(insertPayload));
          if (response?.meta?.requestStatus === "fulfilled") {
            const payload = {
              item_guid: item_guid,
            };
            dispatch(getItemById(payload));
          }
        } else {
          const updatePayload = {
            item_margin_structure_id: values?.item_margin_structure_id
              ? values?.item_margin_structure_id
              : null,
            item_group_marginstructure_id: values?.item_group_marginstructure_id
              ? values?.item_group_marginstructure_id
              : 0,
            item_id: ItemDetail?.id ? ItemDetail?.id : null,
            item_group_id: ItemDetail?.item_group_id
              ? ItemDetail?.item_group_id
              : null,
            from_date: values?.from_date
              ? moment(values?.from_date).format("YYYY-MM-DD")
              : "",
            to_date: values?.to_date
              ? moment(values?.to_date).format("YYYY-MM-DD")
              : "",
            agent_margin: values?.agent_margin ? +values?.agent_margin : null,
            agent_marginvalue1: values?.agent_marginvalue1
              ? +values?.agent_marginvalue1
              : 0,
            agent_marginvalue2: values?.agent_marginvalue2
              ? +values?.agent_marginvalue2
              : 0,
            retailer_margin: values?.retailer_margin
              ? +values?.retailer_margin
              : null,
            retailer_value: values?.retailer_value
              ? +values?.retailer_value
              : 0,
            conversion_unit: values?.conversion_unit
              ? +values?.conversion_unit
              : 0,
            on_mrp_free_qty: values?.on_mrp_free_qty
              ? values?.on_mrp_free_qty
              : false,
          };
          const response = await dispatch(updateItemMarginRate(updatePayload));
          if (response?.meta?.requestStatus === "fulfilled") {
            const payload = {
              item_guid: item_guid,
            };
            dispatch(getItemById(payload));
          }
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    } else if (submitAction === "mrp") {
      try {
        if (!values?.item_mrp_guid) {
          const insertPayload = {
            item_id: ItemDetail?.id ? ItemDetail?.id : null,
            from_date: values?.start_date
              ? moment(values?.start_date).format("YYYY-MM-DD")
              : "",
            mrp_before_discount: values?.mrp_before_discount
              ? +values?.mrp_before_discount
              : null,
            discount: values?.discount ? +values?.discount : null,
            mrp: values?.mrpamount ? +values?.mrpamount : null,
          };
          const response = await dispatch(createItemMrp(insertPayload));
          if (response?.meta?.requestStatus === "fulfilled") {
            const payload = {
              item_guid: item_guid,
            };
            dispatch(getItemById(payload));
          }
        } else {
          const updatePayload = {
            item_mrp_guid: values?.item_mrp_guid ? values?.item_mrp_guid : null,
            from_date: values?.start_date
              ? moment(values?.start_date).format("YYYY-MM-DD")
              : "",
            mrp_before_discount: values?.mrp_before_discount
              ? +values?.mrp_before_discount
              : null,
            discount: values?.discount ? +values?.discount : null,
            mrp: values?.mrpamount ? +values?.mrpamount : null,
          };
          const response = await dispatch(updateItemMrp(updatePayload));
          if (response?.meta?.requestStatus === "fulfilled") {
            const payload = {
              item_guid: item_guid,
            };
            dispatch(getItemById(payload));
          }
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    }
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      <DeleteMarginRateDialog />
      {isDrawerOpen && drawerName === "itemCategoryForm" && (
        <AppDrawer>
          <CreateItemCategory />
        </AppDrawer>
      )}
      {isDrawerOpen && drawerName === "unitForm" && (
        <AppDrawer>
          <CreateUnit />
        </AppDrawer>
      )}
      <Form
        key={formKey}
        onSubmit={handleSubmit}
        initialValues={ItemDetail}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement style={{ paddingBottom: 10 }}>
            <ShadowCard style={{ padding: 12 }}>
              <GridLayout
                style={{ marginRight: 20 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "33.33%" },
                  { width: "33.33%" },
                  { width: "33.33%" },
                ]}
              >
                <GridLayoutItem
                  colSpan={3}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography.h4>
                    {item_guid ? "Update Item" : "Add Item"}
                  </Typography.h4>
                  {lastItemCode && (
                    <div
                      style={{
                        background: "whitesmoke",
                        padding: "10px 15px",
                        borderRadius: "5px",
                        boxShadow: "inset -2px -2px 3px gainsboro",
                      }}
                    >
                      <h5 className="m-0">
                        Last Item Code :{" "}
                        <span style={{ color: "green" }}>{lastItemCode}</span>
                      </h5>
                    </div>
                  )}
                </GridLayoutItem>
                {Pathname === "item" && (
                  <GridLayoutItem colSpan={3} style={{ display: "flex" }}>
                    <div
                      style={{
                        background: "#edeaea",
                        borderRadius: 10,
                        padding: "1px 10px 15px",
                        fontWeight: 500,
                      }}
                    >
                      <Field
                        id={"item_type"}
                        name={"item_type"}
                        // label={"Item Type"}
                        // marginRight={50}
                        // marginBeetween={8}
                        component={FormRadioGroup}
                        layout="horizontal"
                        options={ITEMTYPEOPTIONS}
                        validator={requiredValidator}
                        // astrike={true}
                      />
                    </div>
                  </GridLayoutItem>
                )}
                <GridLayoutItem>
                  <Field
                    name="product_name"
                    label="Product Name"
                    placeholder="Product Name"
                    component={FormTextField}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="product_code"
                    label="Product Code"
                    placeholder="Product Code"
                    component={FormTextField}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="item_category_id"
                    label="Item Category"
                    placeholder="Item Category"
                    isAddNew={true}
                    isIndirect={true}
                    drawerName="itemCategoryForm"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    astrike={true}
                    options={ItemCategoryList?.map(
                      (category: IItemCategory) => {
                        return {
                          value: category?.id,
                          label: category?.item_category,
                        };
                      }
                    )}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="item_group_id"
                    label="Item Group"
                    placeholder="Item Group"
                    isAddNew={true}
                    addNewLink="itemgroup/create"
                    component={FormIncrementalSearch}
                    validator={requiredValidator}
                    astrike={true}
                    loading={itemGroupLoading}
                    // options={ItemGroupList?.map((itemgroup: IItemGroup) => {
                    //   return {
                    //     value: itemgroup?.id,
                    //     label: itemgroup?.item_group,
                    //   };
                    // })}
                    fetchIncrementalData={(search: any) =>
                      handleItemGroupSearchChange(
                        search,
                        `item_group_options`,
                        formRenderProps
                      )
                    }
                    options={
                      formRenderProps
                        .valueGetter("item_group_options")
                        ?.map((item: any) => {
                          return {
                            value: +item?.item_group_id,
                            label: item?.item_group,
                          };
                        }) || []
                    }
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="unit_id"
                    label="Unit"
                    placeholder="Unit"
                    isAddNew={true}
                    isIndirect={true}
                    drawerName="unitForm"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    astrike={true}
                    options={UnitList?.map((unit: IUnit) => {
                      return {
                        value: unit?.id,
                        label: unit?.unit_name,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="gst_id"
                    label="GST(%)"
                    placeholder="GST(%)"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    astrike={true}
                    options={GstList?.map((gst: IGst) => {
                      return {
                        value: gst?.id,
                        label: gst?.gst_title,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="hsn_sac_code"
                    label="HSN/SAC Code"
                    placeholder="HSN/SAC Code"
                    component={FormTextField}
                  />
                </GridLayoutItem>

                <GridLayoutItem colSpan={2} rowSpan={3}>
                  <Field
                    name="description"
                    label="Description"
                    placeholder="Description"
                    wrapperClassName="item-description"
                    component={FormRichTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  style={{
                    marginTop: "18px",
                    background: "#edeaea",
                    borderRadius: 10,
                    padding: "0 10px 15px",
                    fontWeight: 500,
                    color: "black",
                  }}
                >
                  <Field
                    labelClassName="ms-2"
                    name="show_item_in_purchase"
                    maxLength="100"
                    label="Show Item In Purchase"
                    component={FormCheckbox}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  style={{
                    marginTop: "18px",
                    background: "#edeaea",
                    borderRadius: 10,
                    padding: "0 10px 15px",
                    fontWeight: 500,
                    color: "black",
                  }}
                >
                  <Field
                    labelClassName="ms-2"
                    name="show_item_in_sales"
                    maxLength="100"
                    label="Show Item In Sales Order"
                    component={FormCheckbox}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  style={{
                    background: "#edeaea",
                    borderRadius: 10,
                    padding: "0 10px 5px",
                    marginTop: 15,
                  }}
                >
                  <Field
                    wrapperStyle={{ marginTop: 8 }}
                    label="Manage Stock"
                    component={FormRadioGroup}
                    name={`manage_stock`}
                    layout="horizontal"
                    options={MANAGE_STOCK}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  style={{
                    background: "#edeaea",
                    borderRadius: 10,
                    padding: "0 10px 5px",
                    marginTop: 15,
                  }}
                >
                  <Field
                    wrapperStyle={{ marginTop: 8 }}
                    label="Negative Qty Allowed"
                    component={FormRadioGroup}
                    name={`negative_qty_allowed`}
                    layout="horizontal"
                    options={NEGATIVE_QUTY}
                    defaultValue={false}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  style={{
                    background: "#edeaea",
                    borderRadius: 10,
                    padding: "0 10px 5px",
                    marginTop: 15,
                  }}
                >
                  <Field
                    wrapperStyle={{ marginTop: 8 }}
                    label="Low Stock Warning"
                    component={FormRadioGroup}
                    name={`low_stock_warning`}
                    layout="horizontal"
                    options={LOW_STOCK_WARNING}
                  />
                </GridLayoutItem>
                <GridLayoutItem style={{ display: "flex", gap: 10 }}>
                  {/* <Field
                    wrapperClassName="w-100 right-alighned-field"
                    name="mrp"
                    label="MRP"
                    placeholder="0"
                    component={FormNumericTextField}
                  /> */}
                  <Field
                    wrapperClassName="w-100 right-alighned-field"
                    name="purchase_price"
                    label="Purchase Price"
                    placeholder="0"
                    component={FormNumericTextField}
                  />
                  <Field
                    wrapperClassName="w-100 right-alighned-field"
                    name="expiry_days"
                    label="Expiry Days"
                    placeholder="0"
                    component={FormNumericTextField}
                  />
                  {/* <Field
                    wrapperClassName="w-100 right-alighned-field"
                    name="sales_price"
                    label="Sales Price"
                    placeholder="0"
                    component={FormNumericTextField}
                  /> */}
                </GridLayoutItem>
                {/* <GridLayoutItem style={{ display: "flex", gap: 10 }}>
                  <Field
                    wrapperClassName="w-100 right-alighned-field"
                    name="markup"
                    label="Markup"
                    placeholder="0"
                    component={FormNumericTextField}
                  />
                  <Field
                    wrapperClassName="w-100 right-alighned-field"
                    name="min_markup"
                    label="Min Markup"
                    placeholder="0"
                    component={FormNumericTextField}
                  />
                </GridLayoutItem> */}
                <GridLayoutItem style={{ display: "flex", gap: 10 }}>
                  <Field
                    wrapperClassName="w-100 right-alighned-field"
                    name="weight_number"
                    label="Conversion Unit"
                    placeholder="0"
                    component={FormNumericTextField}
                    validator={requiredValidator}
                    astrike={true}
                  />
                  <Field
                    wrapperClassName="w-100 right-alighned-field"
                    name="sequence_no"
                    label="Sequence No"
                    placeholder="0"
                    component={FormNumericTextField}
                  />
                </GridLayoutItem>
                {Pathname === "finisheditem" && (
                  <GridLayoutItem>
                    <Field
                      name="packing_item_id"
                      label="Packing Material Name"
                      placeholder="Packing Material Name"
                      component={FormIncrementalSearch}
                      // validator={requiredValidator}
                      // loading={itemLoading}
                      fetchIncrementalData={(search: any) =>
                        handleItemSearchChange(
                          search,
                          `packing_item_options`,
                          formRenderProps
                        )
                      }
                      options={
                        formRenderProps
                          .valueGetter("packing_item_options")
                          ?.map((item: any) => {
                            return {
                              value: +item?.packing_item_id,
                              label: item?.packing_item_name,
                            };
                          }) || []
                      }
                    />
                  </GridLayoutItem>
                )}
                <GridLayoutItem>
                  <Field
                    id={"isactive"}
                    name={"isactive"}
                    component={FormSelectionField}
                    validator={requiredValidator}
                    astrike={true}
                    label={"Status"}
                    options={STATUSARRAY?.map((status: any) => {
                      return {
                        value: status.value,
                        label: status.label,
                      };
                    })}
                  />
                </GridLayoutItem>
                {Item_tab !== 0 && (
                  <GridLayoutItem
                    colSpan={3}
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "end",
                      marginTop: 15,
                    }}
                  >
                    <div>
                      <ButtonForAll
                        label={item_guid ? "Update" : "Save"}
                        type="submit"
                        disabled={!formRenderProps.allowSubmit || loading}
                        onClick={() => setSubmitAction("value")}
                      />
                      <Button
                        type="button"
                        fillMode={"outline"}
                        themeColor={"primary"}
                        style={{ marginLeft: 4 }}
                        onClick={handleFormClose}
                      >
                        Cancel
                      </Button>
                    </div>
                  </GridLayoutItem>
                )}
              </GridLayout>
            </ShadowCard>
            <ShadowCard style={{ padding: 12, marginTop: 10 }}>
              <TabStrip
                selected={Item_tab}
                onSelect={handleSelect}
                className="item-grid"
              >
                <TabStripTab
                  title={
                    <TabStripTitle
                      title={"Opening Stock Details"}
                      // icon={TbUserCheck}
                    />
                  }
                >
                  <GridLayout
                    gap={{ rows: 0, cols: 10 }}
                    cols={[{ width: "100%" }]}
                  >
                    <GridLayoutItem>
                      <Typography.h4 className="text-dark">
                        Opening Stock Details
                      </Typography.h4>
                    </GridLayoutItem>
                    <GridLayoutItem
                      style={{ borderBottom: "1px solid lightgray" }}
                    >
                      <FieldArray
                        formRenderProps={formRenderProps}
                        component={ItemWarehouseArray}
                        name="ItemDeatails"
                      />
                    </GridLayoutItem>
                    <GridLayoutItem
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "end",
                        marginTop: 15,
                      }}
                    >
                      <div style={{ width: "fit-content" }}>
                        <ButtonForAll
                          label={item_guid ? "Update" : "Save"}
                          type="submit"
                          disabled={!formRenderProps.allowSubmit || loading}
                          onClick={() => setSubmitAction("value")}
                        />
                        <Button
                          type="button"
                          fillMode={"outline"}
                          themeColor={"primary"}
                          style={{ marginLeft: 4 }}
                          onClick={handleFormClose}
                        >
                          Cancel
                        </Button>
                      </div>
                    </GridLayoutItem>
                  </GridLayout>
                </TabStripTab>
                {item_guid && (
                  <TabStripTab
                    title={
                      <TabStripTitle
                        title={"MRP Details"}
                        // icon={TbUserCheck}
                      />
                    }
                  >
                    <MRPDetailsArray
                      formRenderProps={formRenderProps}
                      setSubmitAction={setSubmitAction}
                      showMRP={showMRP}
                      setShowMRP={setShowMRP}
                    />
                  </TabStripTab>
                )}
                {item_guid && (
                  <TabStripTab
                    title={
                      <TabStripTitle
                        title={"Margin Structure Details"}
                        // icon={TbUserSearch}
                      />
                    }
                  >
                    <MarginStructureDetails
                      formRenderProps={formRenderProps}
                      setSubmitAction={setSubmitAction}
                      showMarginRate={showMarginRate}
                      setShowMarginRate={setShowMarginRate}
                    />
                  </TabStripTab>
                )}
                {/* {ItemDetail &&
                  ItemDetail?.item_margin_rate &&
                  ItemDetail?.item_margin_rate?.length > 0 &&
                  item_guid && (
                    <TabStripTab
                      title={
                        <TabStripTitle
                          title={"Margin Rate Details"}
                          // icon={TbUserSearch}
                        />
                      }
                    >
                      <MarginRateDetails />
                    </TabStripTab>
                  )} */}
              </TabStrip>
            </ShadowCard>
            <MRPChangeWatcher formRenderProps={formRenderProps} />
            <ItemCodeRetrieveWatcher
              formRenderProps={formRenderProps}
              setLastItemCode={setLastItemCode}
            />
          </FormElement>
        )}
      />
      {item_guid && (
        <Button
          fillMode={"solid"}
          themeColor={"primary"}
          style={{
            position: "fixed",
            top: 100,
            right: 50,
            zIndex: 9,
            height: 50,
            width: 50,
            borderRadius: "50%",
            boxShadow: "0 0 25px gray",
          }}
          onClick={() => {
            dispatch(closed());
            dispatch(setItemTab(0));
            dispatch(clearItemDetail());
            if (Pathname === "rawmaterialitem") {
              navigate("/rawmaterialitem/create");
            } else if (Pathname === "semifinisheditem") {
              navigate("/semifinisheditem/create");
            } else if (Pathname === "finisheditem") {
              navigate("/finisheditem/create");
            } else if (Pathname === "packingmaterialitem") {
              navigate("/packingmaterialitem/create");
            } else {
              navigate("/item");
            }
          }}
        >
          <FaPlus />
        </Button>
      )}
    </>
  );
};

const DeleteMarginRateDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const item_guid = location.state?.item_guid;
  const ItemMarginRateID = useAppSelector(
    (state) => state.item.ItemMarginRateID
  );
  const dialogName = useAppSelector((state) => state.dialog.dialogName);

  const handleDeleteAction = async (ID: number | null) => {
    if (ID) {
      const response = await dispatch(deleteItemMarginRate(ID));
      const payload = {
        item_guid: item_guid,
      };
      if (response?.meta?.requestStatus === "fulfilled") {
        dispatch(getItemById(payload));
        dispatch(closeDialog());
        dispatch(clearItemMarginRateID());
      } else {
        dispatch(getItemById(payload));
        dispatch(closeDialog());
        dispatch(clearItemMarginRateID());
      }
    }
  };

  return (
    <>
      {dialogName === "deleteItemMarginRate" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Margin rate"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this margin rate?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(ItemMarginRateID)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

export default CreateItem;
