import { useEffect, useRef } from "react";

import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { getEmployeeByID } from "../../employee/services/employee.services";
import { LoadingPanel } from "../../../components/layout/Loading";
import { COMPANY_ADDRESS, COMPANY_NAME } from "../../../_contstants/common";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import moment from "moment";
import { clearEmployeeDetails } from "../../employee/employeeSlice";

const FormNo35 = () => {
  const state = JSON.parse(sessionStorage.getItem("form35State") || "{}");
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);
  const EmployeeDetails = useAppSelector(
    (state) => state.employee.EmployeeDetails
  );

  const formloading = useAppSelector((state) => state.employee.formloading);

  const setPrintStyles = () => {
    const style = document.createElement("style");
    style.type = "text/css";
    style.innerHTML = `
          @media print {
            @page {
            margin-left: 0.7cm;
              margin-right: 0.7cm;
            }
          }
        `;
    document.head.appendChild(style);
  };
  const fetchReport = async () => {
    const user_guid = state.user_guid;
    setPrintStyles();
    await dispatch(getEmployeeByID(user_guid));
  };

  useEffect(() => {
    const handleTabClose = () => {
      sessionStorage.removeItem("form35State");
    };
    fetchReport();
    return () => {
      +dispatch(clearEmployeeDetails());
      window.removeEventListener("unload", handleTabClose);
    };
  }, []);

  if (formloading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      <div>
        <ButtonWithLoading
          buttonStyle={{ position: "fixed", right: 0, top: 0, zIndex: 100 }}
          label={"Print"}
          className="no-visible-print-btn"
          type="button"
          onClick={() => window.print()}
        />
        <h6
          style={{
            fontWeight: "900",
          }}
        >
          {COMPANY_NAME}
        </h6>
        <p>{COMPANY_ADDRESS}</p>

        <div
          style={{
            border: "1px solid #ccc",
            padding: "10px",
          }}
        >
          <div
            style={{
              textAlign: "center",
              paddingTop: "20px",
            }}
          >
            <h6
              className="m-0"
              style={{
                fontWeight: "900",
                fontSize: "16px",
              }}
            >
              FORM No :- 35
            </h6>
            <p
              style={{
                fontWeight: "900",
                fontSize: "14px",
              }}
            >
              (Prescribed under Rule 100)
            </p>
          </div>
          <div
            style={{
              padding: "0px 10px",
            }}
          >
            <p
              style={{
                textIndent: "50px",
              }}
            >
              Nomination for payment of wages in lieu of the quantum of leave to
              which he was entitled in the event of death of worker.
            </p>
            <p
              className="m-0"
              style={{
                display: "flex",
                gap: "15px",
              }}
            >
              <span>|</span>
              <span>
                <b>
                  {`${EmployeeDetails?.first_name} ${EmployeeDetails?.middle_name} ${EmployeeDetails?.last_name} `}
                </b>
              </span>
              <span> here by nominate</span>
            </p>
            <p
              className="m-0"
              style={{
                display: "flex",
                gap: "150px",
                paddingBottom: "5px",
              }}
            >
              <span> who is my and resides at : - </span>
              <span>
                <b>
                  {EmployeeDetails?.permenent_address} &nbsp;
                  {EmployeeDetails?.permenent_street} &nbsp;
                  {EmployeeDetails?.permenent_area}&nbsp; <br />
                  {EmployeeDetails?.permenent_city}&nbsp;
                  <br />
                  {EmployeeDetails?.permenent_pincode} &nbsp;
                  {EmployeeDetails?.permenent_state_name}&nbsp;
                </b>
              </span>
            </p>
            <p className="m-0">
              as to receive the amount of the balance of my wages in lieu of
              quantum of leave and not availed of, in the event of my death
              before resuming work.
            </p>
            <p
              style={{
                paddingTop: "7px",
              }}
            >
              Dated this
              <b>
                &nbsp;
                {EmployeeDetails?.dob
                  ? moment(EmployeeDetails?.date_of_joining).format(
                      "DD [Day of] MMM-YYYY"
                    )
                  : ""}
              </b>
              &nbsp; at
              <span
                style={{
                  textTransform: "uppercase",
                }}
              >
                <b> {EmployeeDetails?.permenent_city}</b>
              </span>
            </p>

            <div>
              <h6
                style={{
                  fontWeight: "900",
                  paddingTop: "20px",
                  paddingBottom: "10px",
                }}
              >
                Witness
              </h6>
              <p style={{ display: "flex", gap: "20px" }}>
                <span>1</span>{" "}
                <span style={{ wordSpacing: "15px" }}>Signature :-</span>
              </p>
              <p style={{ paddingLeft: "24px", wordSpacing: "45px" }}>
                Name :-
              </p>
              <p style={{ paddingLeft: "24px", wordSpacing: "30px" }}>
                Address :-
              </p>
            </div>

            <div
              style={{
                paddingTop: "30px",
                paddingBottom: "20px",
              }}
            >
              <p style={{ display: "flex", gap: "20px" }}>
                <span>1</span>{" "}
                <span style={{ wordSpacing: "15px" }}>Signature :-</span>
              </p>
              <p style={{ paddingLeft: "24px", wordSpacing: "45px" }}>
                Name :-
              </p>
              <p style={{ paddingLeft: "24px", wordSpacing: "30px" }}>
                Address :-
              </p>
            </div>
          </div>
          <div
            style={{
              textAlign: "end",
              paddingTop: "70px",
              paddingRight: "10px",
            }}
          >
            <h6
              style={{
                fontWeight: "900",
              }}
            >
              Signature of left thumb <br /> Impression of the worker
            </h6>

            <p
              style={{
                paddingTop: "20px",
              }}
            >
              Particulars of worker <br />
              (Such as Serial Number in the <br /> Register of adult/child
              workers, <br /> Section or department , etc. )
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormNo35;
