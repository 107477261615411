import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosApiInstance } from "../../../app/axios";
import { API_URL } from "../../../app/config";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IUserDetails } from "../userModel";

export const getAllUsers = createAsyncThunk("User/FindAllUser", async () => {
  try {
    const response = await axiosApiInstance.post(
      `${API_URL}/User/FindAllUser`,
      {
        first_name: "",
      }
    );
    return response.data.Data;
  } catch (error: any) {
    // ErrorToast(error?.response?.data?.Details || "Something went wrong");
    console.error("Error fetching users:", error);
    return Promise.reject({
      message: error?.response?.data?.Details || "Something went wrong",
    });
  }
});

export const createUser = createAsyncThunk(
  "User/InsertUser",
  async (UserData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/InsertUser`,
        UserData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating user:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateUser = createAsyncThunk(
  "User/UpdateUser",
  async (UserData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/UpdateUser`,
        UserData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating user:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteUser = createAsyncThunk(
  "User/DeleteUser",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/DeleteUser`,
        { user_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting user:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getUserByID = createAsyncThunk(
  "User/FindByIDUser",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/FindByIDUser`,
        { user_guid: ID }
      );
      const result = response.data?.Data as any;
      return {
        id: result.id,
        user_guid: result.user_guid,
        first_name: result.first_name,
        last_name: result.last_name,
        mobileno: result.mobileno,
        alternate_mobileno: result.alternate_mobileno,
        emailid: result.emailid,
        alternate_emailid: result.alternate_emailid,
        password: result.password,
        isactive: result.isactive === true ? 1 : 2,
        usertypeid: result.usertypeid,
        ...result?.UserRightsAssign?.reduce(
          (
            acc: any,
            { menu_id, rights_id }: { menu_id: any; rights_id: any }
          ) => {
            if (!rights_id) {
              acc[menu_id] = true;
            } else {
              acc[`${menu_id}Rights`] = rights_id.split(",").map(Number);
              acc[menu_id] = true;
            }
            return acc;
          },
          {}
        ),
      } as IUserDetails;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching user:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const activeInActiveUsers = createAsyncThunk(
  "User/ActiveInActiveUser",
  async (Data: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/ActiveInActiveUser`,
        Data
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error in status change:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveUsers = createAsyncThunk(
  "User/FindAllActiveUser",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/User/FindAllActiveUser`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Users:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateUserRights = createAsyncThunk(
  "User/UpdateEmployeeUser",
  async (UserData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/UpdateEmployeeUser`,
        UserData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating user right assign:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getUserRightsByID = createAsyncThunk(
  "User/FindByIDEmployeeUser",
  async (id: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/FindByIDEmployeeUser`,
        { id }
      );
      const result = response.data?.Data as any;
      return {
        id: result.id,
        user_guid: result.user_guid,
        password: result.password,
        usertypeid: result.usertypeid,
        ...result?.UserRightsAssign?.reduce(
          (
            acc: any,
            { menu_id, rights_id }: { menu_id: any; rights_id: any }
          ) => {
            if (!rights_id) {
              acc[menu_id] = true;
            } else {
              acc[`${menu_id}Rights`] = rights_id.split(",").map(Number);
              acc[menu_id] = true;
            }
            return acc;
          },
          {}
        ),
      } as IUserDetails;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching user:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveDrivers = createAsyncThunk(
  "DeliveryDetails/FindAllActiveDriver",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/DeliveryDetails/FindAllActiveDriver`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Drivers:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const changeUserPassword = createAsyncThunk(
  "UserLogin/ChangePassword",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/UserLogin/ChangePassword`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data.Data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error changing user password:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const resetUserPassword = createAsyncThunk(
  "UserLogin/ResetPassword",
  async (formdata: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/UserLogin/ResetPassword`,
        formdata
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data.Data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error reseting user password:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
