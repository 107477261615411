import React, { useEffect } from "react";
import { Field, FieldArrayRenderProps } from "@progress/kendo-react-form";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { Typography } from "@progress/kendo-react-common";
import IconButton from "../../components/common/IconButton";
import { MdDelete } from "react-icons/md";
import { setCreditNoteDeleteIndex } from "./creditNoteSlice";
import {
  requiredValidator,
  skipValidator,
} from "../../components/formFields/CommonValidator";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { IItem } from "../Item/itemModel";
import { useLocation } from "react-router-dom";
import { IUnit } from "../unit/unitModel";
import { IGst } from "../gst/gstModel";
import { getItemById } from "../Item/services/item.services";
import FormNumericTextField from "../../components/formFields/FormNumericTextField";
import FormIncrementalSearch from "../../components/formFields/FormIncrementalSearch";
import { axiosApiInstance } from "../../app/axios";
import { API_URL } from "../../app/config";
import { ErrorToast, SuccessToast } from "../../components/toast/Toasts";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import _ from "lodash";
// import { getUnitById } from "../unit/services/unit.services";

const CreditNoteItemDetailsArray = (
  fieldArrayRenderProps: FieldArrayRenderProps
) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const credit_note_guid = location.state?.credit_note_guid;
  const CreditNoteDetail = useAppSelector(
    (state) => state.creditnote.CreditNoteDetail
  );
  const CreditNoteDeleteIndex = useAppSelector(
    (state) => state.creditnote.CreditNoteDeleteIndex
  );

  const InputRef = React.useRef<any>(null);

  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const UnitList = useAppSelector((state) => state.unit.UnitList);
  const GstList = useAppSelector((state) => state.gst.GstList);

  const handleOpenDeleteDialog = (index: number) => {
    dispatch(openDialog("creditNoteItemDeleteDialog"));
    dispatch(setCreditNoteDeleteIndex(index));
  };

  useEffect(() => {
    if (!credit_note_guid) {
      pushElementInarray();
    }
  }, []);

  // const handleDeleteAction = React.useCallback(
  //   (index: number) => {
  //     fieldArrayRenderProps.onRemove({ index: index });
  //     dispatch(closeDialog());
  //     dispatch(setCreditNoteDeleteIndex(-1));
  //   },
  //   [fieldArrayRenderProps]
  // );

  const handleDeleteAction = React.useCallback(
    async (index: number) => {
      if (fieldArrayRenderProps?.value[index]?.id) {
        try {
          const response = await axiosApiInstance.post(
            `${API_URL}/CreditNote/DeleteCreditNoteItem`,
            { id: fieldArrayRenderProps?.value[index]?.id }
          );
          fieldArrayRenderProps.onRemove({ index: index });
          dispatch(closeDialog());
          dispatch(setCreditNoteDeleteIndex(-1));
          SuccessToast(response.data?.Details || "Success");
        } catch (error: any) {
          ErrorToast(error?.response?.data?.Details || "Something went wrong");
          console.error("Error deleting Item:", error);
          return Promise.reject({
            message: error?.response?.data?.Details || "Something went wrong",
          });
        }
      } else {
        fieldArrayRenderProps.onRemove({ index: index });
        dispatch(closeDialog());
        dispatch(setCreditNoteDeleteIndex(-1));
      }
    },
    [fieldArrayRenderProps]
  );

  const pushElementInarray = React.useCallback(() => {
    fieldArrayRenderProps.onPush({
      value: {
        item_id: null,
        quantity: null,
        free_quantity: null,
        unit_id: null,
        rate: null,
        discount: null,
        discount_type: "₹",
        gst_id: null,
        amount: null,

        sgst_per: null,
        sgst_amount: null,
        cgst_per: null,
        cgst_amount: null,
        igst_per: null,
        igst_amount: null,
        total_gst_amount: null,
      },
    });

    setTimeout(() => {
      InputRef.current?.element?.children[0]?.children[1]?.children[0]?.focus();
    }, 0);
  }, [fieldArrayRenderProps]);

  const handleProductChange = async (
    e: any,
    innerIndex: number,
    fieldArrayRenderProps: FieldArrayRenderProps
  ) => {
    const credit_note_items =
      fieldArrayRenderProps.formRenderProps.valueGetter("credit_note_items");
    if (e?.value) {
      const payload = {
        id: e?.value,
      };
      const response: any = await dispatch(getItemById(payload));
      credit_note_items &&
        credit_note_items.length > 0 &&
        credit_note_items.map((item: any, index: number) => {
          if (innerIndex === index) {
            if (response.meta.requestStatus === "fulfilled") {
              fieldArrayRenderProps.formRenderProps.onChange(
                `credit_note_items.${index}.gst_id`,
                {
                  value: `${response.payload?.gst_id}--${
                    response.payload?.gst || "0"
                  }`,
                }
              );
              fieldArrayRenderProps.formRenderProps.onChange(
                `credit_note_items.${index}.unit_id`,
                {
                  value: response.payload?.unit_id,
                }
              );
              fieldArrayRenderProps.formRenderProps.onChange(
                `credit_note_items.${index}.unit_decimal`,
                {
                  value: response.payload?.unit_decimal,
                }
              );
              fieldArrayRenderProps.formRenderProps.onChange(
                `credit_note_items.${index}.mrp`,
                {
                  value: response.payload?.mrp,
                }
              );
              fieldArrayRenderProps.formRenderProps.onChange(
                `credit_note_items.${index}.rate`,
                {
                  value: response.payload?.creditNote_price,
                }
              );
            } else {
              fieldArrayRenderProps.formRenderProps.onChange(
                `credit_note_items.${index}.gst_id`,
                {
                  value: null,
                }
              );
              fieldArrayRenderProps.formRenderProps.onChange(
                `credit_note_items.${index}.unit_id`,
                {
                  value: null,
                }
              );
              fieldArrayRenderProps.formRenderProps.onChange(
                `credit_note_items.${index}.unit_decimal`,
                {
                  value: null,
                }
              );
              fieldArrayRenderProps.formRenderProps.onChange(
                `credit_note_items.${index}.mrp`,
                {
                  value: "",
                }
              );
              fieldArrayRenderProps.formRenderProps.onChange(
                `credit_note_items.${index}.rate`,
                {
                  value: "",
                }
              );
            }
          }
        });
    } else {
      credit_note_items &&
        credit_note_items.length > 0 &&
        credit_note_items.map((item: any, index: number) => {
          if (innerIndex === index) {
            fieldArrayRenderProps.formRenderProps.onChange(
              `credit_note_items.${index}.gst_id`,
              {
                value: null,
              }
            );
            fieldArrayRenderProps.formRenderProps.onChange(
              `credit_note_items.${index}.unit_id`,
              {
                value: null,
              }
            );
            fieldArrayRenderProps.formRenderProps.onChange(
              `credit_note_items.${index}.unit_decimal`,
              {
                value: null,
              }
            );
            fieldArrayRenderProps.formRenderProps.onChange(
              `credit_note_items.${index}.mrp`,
              {
                value: "",
              }
            );
            fieldArrayRenderProps.formRenderProps.onChange(
              `credit_note_items.${index}.rate`,
              {
                value: "",
              }
            );
          }
        });
    }
  };

  const debouncedHandleProductChange = React.useRef(
    _.debounce((item_id: any, index: number, fieldArrayRenderProps: any) => {
      handleProductChange(item_id, index, fieldArrayRenderProps);
    }, 300)
  ).current;

  useEffect(() => {
    return () => {
      debouncedHandleProductChange.cancel();
    };
  }, []);

  const handleChange = (
    item_id: any,
    index: number,
    fieldArrayRenderProps: any
  ) => {
    debouncedHandleProductChange(item_id, index, fieldArrayRenderProps);
  };

  // const handleUnitChange = async (
  //   e: any,
  //   innerIndex: number,
  //   fieldArrayRenderProps: FieldArrayRenderProps
  // ) => {
  //   const credit_note_items =
  //     fieldArrayRenderProps.formRenderProps.valueGetter("credit_note_items");
  //   if (e?.value) {
  //     const payload = {
  //       id: e?.value,
  //     };
  //     const response: any = await dispatch(getUnitById(payload));
  //     credit_note_items &&
  //       credit_note_items.length > 0 &&
  //       credit_note_items.map((item: any, index: number) => {
  //         if (innerIndex === index) {
  //           if (response.meta.requestStatus === "fulfilled") {
  //             fieldArrayRenderProps.formRenderProps.onChange(
  //               `credit_note_items.${index}.unit_decimal`,
  //               {
  //                 value: response.payload?.unit_decimal,
  //               }
  //             );
  //           } else {
  //             fieldArrayRenderProps.formRenderProps.onChange(
  //               `credit_note_items.${index}.unit_decimal`,
  //               {
  //                 value: null,
  //               }
  //             );
  //           }
  //         }
  //       });
  //   } else {
  //     credit_note_items &&
  //       credit_note_items.length > 0 &&
  //       credit_note_items.map((item: any, index: number) => {
  //         if (innerIndex === index) {
  //           fieldArrayRenderProps.formRenderProps.onChange(
  //             `credit_note_items.${index}.unit_decimal`,
  //             {
  //               value: null,
  //             }
  //           );
  //         }
  //       });
  //   }
  // };

  const handleTotalChange = async (
    e: any,
    total: number,
    index: number,
    fieldArrayRenderProps: FieldArrayRenderProps
  ) => {
    e?.syntheticEvent?.preventDefault();
    const qty = fieldArrayRenderProps.formRenderProps.valueGetter(
      `credit_note_items.${index}.quantity`
    );
    const unit_decimal = fieldArrayRenderProps.formRenderProps.valueGetter(
      `credit_note_items.${index}.unit_decimal`
    );
    const discount = fieldArrayRenderProps.formRenderProps.valueGetter(
      `credit_note_items.${index}.discount`
    );
    const discount_type = fieldArrayRenderProps.formRenderProps.valueGetter(
      `credit_note_items.${index}.discount_type`
    );

    if (discount_type && discount_type === "₹") {
      fieldArrayRenderProps.formRenderProps.onChange(
        `credit_note_items.${index}.rate`,
        {
          value:
            (total + discount) / qty?.toFixed(unit_decimal ? unit_decimal : 0),
        }
      );
    } else if (discount_type && discount_type === "%") {
      let perc = 100 - (discount < 100 ? discount : 100);

      fieldArrayRenderProps.formRenderProps.onChange(
        `credit_note_items.${index}.rate`,
        {
          value:
            (total * 100) /
            perc /
            qty?.toFixed(unit_decimal ? unit_decimal : 0),
        }
      );
    } else {
      fieldArrayRenderProps.formRenderProps.onChange(
        `credit_note_items.${index}.rate`,
        {
          value: total / qty?.toFixed(unit_decimal ? unit_decimal : 0),
        }
      );
    }
  };

  return (
    <>
      <GridLayout
        cols={[{ width: "100%" }]}
        style={{ overflowX: "scroll", scrollbarWidth: "thin" }}
      >
        <GridLayoutItem
          className="bg-dark text-white fw-600"
          style={{ width: 2500 }}
        >
          <GridLayout
            style={{
              marginRight: "0.2%",
              padding: 10,
            }}
            gap={{ rows: 10, cols: 5 }}
            cols={[
              { width: "15%" },
              { width: "5%" },
              { width: "5%" },
              { width: "5%" },
              { width: "5%" },
              { width: "8%" },

              { width: "7%" },

              { width: "3%" },
              { width: "7%" },
              { width: "3%" },
              { width: "7%" },
              { width: "3%" },
              { width: "7%" },

              { width: "7%" },
              { width: "7%" },

              { width: "3%" },
            ]}
          >
            <GridLayoutItem>Item</GridLayoutItem>
            <GridLayoutItem>Quantity</GridLayoutItem>
            <GridLayoutItem>Free Quantity</GridLayoutItem>
            <GridLayoutItem>Rate</GridLayoutItem>
            <GridLayoutItem>Unit</GridLayoutItem>
            <GridLayoutItem>Discount</GridLayoutItem>

            <GridLayoutItem>GST</GridLayoutItem>

            <GridLayoutItem>CGST %</GridLayoutItem>
            <GridLayoutItem>CGST</GridLayoutItem>
            <GridLayoutItem>SGST %</GridLayoutItem>
            <GridLayoutItem>SGST</GridLayoutItem>
            <GridLayoutItem>IGST %</GridLayoutItem>
            <GridLayoutItem>IGST</GridLayoutItem>

            <GridLayoutItem>Total GST</GridLayoutItem>
            <GridLayoutItem>Amount</GridLayoutItem>
            <GridLayoutItem></GridLayoutItem>
          </GridLayout>
        </GridLayoutItem>
        {dialogName === "creditNoteItemDeleteDialog" && (
          <AppDialog>
            <>
              <Typography.h5>{"Delete Item"}</Typography.h5>
              <GridLayout>
                <GridLayoutItem>
                  <Typography.p>
                    Are you sure you want to delete this?
                  </Typography.p>
                </GridLayoutItem>
                <GridLayoutItem
                  style={{
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <DialogCloseButton themeColor="error" />
                  <Button
                    fillMode={"solid"}
                    themeColor={"error"}
                    onClick={() => handleDeleteAction(CreditNoteDeleteIndex)}
                    type="button"
                  >
                    Delete
                  </Button>
                </GridLayoutItem>
              </GridLayout>
            </>
          </AppDialog>
        )}
        {fieldArrayRenderProps.value &&
          fieldArrayRenderProps.value.length > 0 &&
          fieldArrayRenderProps.value.map(
            (credit_note_items: any, index: number) => (
              <GridLayoutItem key={index} style={{ width: 2500 }}>
                <GridLayout
                  style={{
                    marginRight: "0.2%",
                    padding: 10,
                    position: "relative",
                  }}
                  gap={{ rows: 10, cols: 5 }}
                  cols={[
                    { width: "15%" },
                    { width: "5%" },
                    { width: "5%" },
                    { width: "5%" },
                    { width: "5%" },
                    { width: "8%" },

                    { width: "7%" },

                    { width: "3%" },
                    { width: "7%" },
                    { width: "3%" },
                    { width: "7%" },
                    { width: "3%" },
                    { width: "7%" },

                    { width: "7%" },
                    { width: "7%" },

                    { width: "3%" },
                  ]}
                >
                  <GridLayoutItem ref={InputRef}>
                    <Field
                      wrapperStyle={{ margin: 0 }}
                      name={`credit_note_items.${index}.item_id`}
                      placeholder="Product"
                      isAddNew={true}
                      addNewLink="item/create"
                      component={FormIncrementalSearch}
                      validator={requiredValidator}
                      onChange={
                        (e: any) =>
                          handleChange(e, index, fieldArrayRenderProps)
                        // handleProductChange(e, index, fieldArrayRenderProps)
                      }
                      fetchIncrementalData={(search: any) =>
                        fieldArrayRenderProps.handleItemSearchChange(
                          search,
                          `credit_note_items.${index}.item_options`,
                          fieldArrayRenderProps.formRenderProps
                        )
                      }
                      options={
                        fieldArrayRenderProps.value[index]?.item_options?.map(
                          (item: IItem) => {
                            return {
                              value: item.id,
                              label: item.product_name,
                            };
                          }
                        ) || []
                      }
                    />
                  </GridLayoutItem>
                  {/* <GridLayoutItem>
                    <Field
                      wrapperClassName="right-alighned-field"
                      wrapperStyle={{ margin: 0 }}
                      name={`credit_note_items.${index}.mrp`}
                      placeholder="0"
                      type="number"
                      minNumber="0"
                      validator={requiredValidator}
                      component={FormNumericTextField}
                    />
                  </GridLayoutItem> */}
                  <GridLayoutItem>
                    <Field
                      wrapperClassName="right-alighned-field"
                      wrapperStyle={{ margin: 0 }}
                      name={`credit_note_items.${index}.quantity`}
                      placeholder="0"
                      min="0"
                      format={
                        fieldArrayRenderProps.value[index]?.unit_decimal &&
                        fieldArrayRenderProps.value[index]?.unit_decimal > 0
                          ? `n${fieldArrayRenderProps.value[index]?.unit_decimal}`
                          : "n0"
                      }
                      // validator={requiredValidator}
                      component={FormNumericTextField}
                      validator={
                        !fieldArrayRenderProps.formRenderProps.valueGetter(
                          `credit_note_items.${index}.free_quantity`
                        )
                          ? requiredValidator
                          : skipValidator
                      }
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperClassName="right-alighned-field"
                      wrapperStyle={{ margin: 0 }}
                      name={`credit_note_items.${index}.free_quantity`}
                      placeholder="0"
                      min="0"
                      format={
                        fieldArrayRenderProps.value[index]?.unit_decimal &&
                        fieldArrayRenderProps.value[index]?.unit_decimal > 0
                          ? `n${fieldArrayRenderProps.value[index]?.unit_decimal}`
                          : "n0"
                      }
                      component={FormNumericTextField}
                      validator={
                        !fieldArrayRenderProps.formRenderProps.valueGetter(
                          `credit_note_items.${index}.quantity`
                        )
                          ? requiredValidator
                          : skipValidator
                      }
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperClassName="right-alighned-field"
                      wrapperStyle={{ margin: 0 }}
                      name={`credit_note_items.${index}.rate`}
                      placeholder="0"
                      min="0"
                      validator={requiredValidator}
                      component={FormNumericTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperStyle={{ margin: 0 }}
                      name={`credit_note_items.${index}.unit_id`}
                      placeholder="Unit"
                      component={FormSelectionField}
                      validator={requiredValidator}
                      // onChange={(e) =>
                      //   handleUnitChange(e, index, fieldArrayRenderProps)
                      // }
                      options={UnitList?.map((unit: IUnit) => {
                        return {
                          value: unit?.id,
                          label: unit?.unit_code,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem style={{ position: "relative" }}>
                    <Field
                      // wrapperClassName="right-alighned-field"
                      wrapperStyle={{ margin: 0 }}
                      name={`credit_note_items.${index}.discount`}
                      placeholder="0 %"
                      min="0"
                      component={FormNumericTextField}
                    />
                    <DropDownList
                      style={{
                        position: "absolute",
                        top: 1,
                        right: 1,
                        width: "30%",
                        zIndex: 1,
                      }}
                      onChange={(e) => {
                        fieldArrayRenderProps?.formRenderProps?.onChange(
                          `credit_note_items.${index}.discount_type`,
                          {
                            value: e.value,
                          }
                        );
                      }}
                      name={`credit_note_items.${index}.discount_type`}
                      data={["₹", "%"]}
                      defaultValue={
                        credit_note_guid
                          ? CreditNoteDetail &&
                            CreditNoteDetail?.credit_note_items.length > 0 &&
                            CreditNoteDetail?.credit_note_items[index]
                              ?.discount_type === "₹"
                            ? "₹"
                            : CreditNoteDetail?.credit_note_items[index]
                                ?.discount_type === "%"
                            ? "%"
                            : "₹"
                          : "₹"
                      }
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperStyle={{ margin: 0 }}
                      name={`credit_note_items.${index}.gst_id`}
                      placeholder="GST (%)"
                      component={FormSelectionField}
                      validator={requiredValidator}
                      options={GstList?.map((gst: IGst) => {
                        return {
                          value: `${gst?.id}--${gst?.gst}`,
                          label: gst?.gst_title,
                        };
                      })}
                    />
                  </GridLayoutItem>

                  <GridLayoutItem>
                    <Field
                      wrapperClassName="right-alighned-field"
                      wrapperStyle={{ margin: 0 }}
                      name={`credit_note_items.${index}.cgst_per`}
                      placeholder="0"
                      min="0"
                      disabled
                      component={FormNumericTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperClassName="right-alighned-field"
                      wrapperStyle={{ margin: 0 }}
                      name={`credit_note_items.${index}.cgst_amount`}
                      placeholder="0"
                      min="0"
                      component={FormNumericTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperClassName="right-alighned-field"
                      wrapperStyle={{ margin: 0 }}
                      name={`credit_note_items.${index}.sgst_per`}
                      placeholder="0"
                      min="0"
                      disabled
                      component={FormNumericTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperClassName="right-alighned-field"
                      wrapperStyle={{ margin: 0 }}
                      name={`credit_note_items.${index}.sgst_amount`}
                      placeholder="0"
                      min="0"
                      component={FormNumericTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperClassName="right-alighned-field"
                      wrapperStyle={{ margin: 0 }}
                      name={`credit_note_items.${index}.igst_per`}
                      placeholder="0"
                      min="0"
                      disabled
                      component={FormNumericTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperClassName="right-alighned-field"
                      wrapperStyle={{ margin: 0 }}
                      name={`credit_note_items.${index}.igst_amount`}
                      placeholder="0"
                      min="0"
                      component={FormNumericTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperClassName="right-alighned-field"
                      wrapperStyle={{ margin: 0 }}
                      name={`credit_note_items.${index}.total_gst_amount`}
                      placeholder="0"
                      min="0"
                      component={FormNumericTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperClassName="right-alighned-field"
                      wrapperStyle={{ margin: 0 }}
                      name={`credit_note_items.${index}.amount`}
                      placeholder="0"
                      format="n2"
                      // disabled={true}
                      component={FormNumericTextField}
                      onChange={(e) =>
                        handleTotalChange(
                          e,
                          e?.value,
                          index,
                          fieldArrayRenderProps
                        )
                      }
                    />
                  </GridLayoutItem>
                  <GridLayoutItem
                    style={{
                      position: "sticky",
                      top: 0,
                      right: 0,
                      zIndex: 9,
                    }}
                  >
                    <IconButton
                      onClick={() => handleOpenDeleteDialog(index)}
                      disabled={fieldArrayRenderProps.value.length === 1}
                      themeColor={"error"}
                      size={"small"}
                      fillMode={"solid"}
                      type="button"
                      style={{
                        position: "absolute",
                        height: 32,
                        width: 32,
                        top: 0,
                        right: 0,
                      }}
                    >
                      <MdDelete
                        className="absolute-position"
                        style={{ fontSize: "16px" }}
                      />
                    </IconButton>
                  </GridLayoutItem>
                </GridLayout>
              </GridLayoutItem>
            )
          )}
      </GridLayout>
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{ marginTop: 10, borderTop: "1px solid lightgray" }}
        >
          <GridLayout
            style={{ marginRight: 45, padding: 10 }}
            gap={{ rows: 10, cols: 5 }}
            cols={[
              { width: "25%" },
              { width: "10%" },
              { width: "10%" },
              { width: "15%" },
              { width: "10%" },
              { width: "15%" },
              { width: "10%" },
              { width: "5%" },
            ]}
          >
            <GridLayoutItem colSpan={2} className="d-flex align-items-end">
              <Button
                fillMode={"solid"}
                themeColor={"primary"}
                onClick={pushElementInarray}
                type="button"
              >
                Add Row
              </Button>
            </GridLayoutItem>
            {/* <GridLayoutItem
              colSpan={4}
              className="d-flex justify-content-end align-items-center fw-600"
            >
              Total
            </GridLayoutItem>
            <GridLayoutItem colSpan={2}>
              <Field
                wrapperClassName="right-alighned-field"
                wrapperStyle={{ margin: 0 }}
                name="taxable_amount"
                format="n2"
                component={FormNumericTextField}
                disabled="true"
              />
            </GridLayoutItem> */}
          </GridLayout>
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

export default CreditNoteItemDetailsArray;
