import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import moment from "moment";

export const getAllSalesChallan = createAsyncThunk(
  "SalesChallan/FindAllSalesChallan",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SalesChallan/FindAllSalesChallan`,
        formData
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Sales Challan :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllLimitedSalesChallan = createAsyncThunk(
  "SalesChallan/GetAllSalesChallan",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SalesChallan/GetAllSalesChallan`,
        formData
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Sales Challan :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createSalesChallan = createAsyncThunk(
  "SalesChallan/InsertSalesChallan",
  async (SalesChallanData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SalesChallan/InsertSalesChallan`,
        SalesChallanData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating sales challan :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateSalesChallan = createAsyncThunk(
  "SalesChallan/UpdateSalesChallan",
  async (SalesChallanData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SalesChallan/UpdateSalesChallan`,
        SalesChallanData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating sales challan :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteSalesChallan = createAsyncThunk(
  "SalesChallan/DeleteSalesChallan",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SalesChallan/DeleteSalesChallan`,
        { sales_challan_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting sales challan :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getSalesChallanByID = createAsyncThunk(
  "SalesChallan/FindByIDSalesChallan",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SalesChallan/FindByIDSalesChallan`,
        formData
      );
      const result = response.data?.Data as any;
      return {
        id: result?.id,
        sale_guid: result?.sale_guid,
        sales_order_no: result?.sales_order_no,
        sales_order_id: result?.sales_order_id,
        sales_order_id_label: result?.sales_order_id_label,
        financial_year: result?.financial_year,
        challan_no_string: result?.challan_no_string,
        cartoon: result?.cartoon,
        scheme: result?.scheme
          ? result?.scheme?.split(",")?.map((scheme: any) => +scheme)
          : [],
        remarks: result?.remarks,
        route_id: result?.route_id,
        bag: result?.bag,
        vehicle_no: result?.vehicle_no,
        transport_name: result?.transport_name,
        is_delivered: result?.is_delivered,
        card_scheme: result?.card_scheme,
        free_scheme: result?.free_scheme,
        // delivery_slip_no: result?.delivery_slip_no,
        challan_date: result?.challan_date
          ? moment(result?.challan_date, "YYYY-MM-DD").toDate()
          : "",
        vendor_id: result?.vendor_id,
        account_name: result?.account_name,
        terms_day: result?.terms_day,
        due_date: result?.due_date
          ? moment(result?.due_date, "YYYY-MM-DD").toDate()
          : "",
        // sales_ledger_id: result?.sales_ledger_id,
        total_amount: result?.total_amount,
        gst: result?.gst,
        cgst: result?.cgst,
        sgst: result?.sgst,
        igst: result?.igst,
        sales_challan_items: result?.sales_challan_items
          ? result?.sales_challan_items?.map((salesitem: any) => {
              return {
                id: salesitem?.id ? +salesitem?.id : null,
                sales_challan_id: salesitem?.sales_challan_id
                  ? +salesitem?.sales_challan_id
                  : null,
                item_id: salesitem?.item_id ? +salesitem?.item_id : null,
                item_name: salesitem?.item_name ? salesitem?.item_name : "",
                mrp: salesitem?.mrp ? +salesitem?.mrp : null,
                quantity: salesitem?.quantity ? +salesitem?.quantity : null,
                free_quantity: salesitem?.free_quantity
                  ? +salesitem?.free_quantity
                  : null,
                lot_no_list: salesitem?.lot_no_list
                  ? salesitem?.lot_no_list
                  : [],
                unit_id: salesitem?.unit_id ? +salesitem?.unit_id : null,
                unit_decimal: salesitem?.unit_decimal
                  ? +salesitem?.unit_decimal
                  : 0,
                unit_name: salesitem?.unit_name ? salesitem?.unit_name : "",
                rate: salesitem?.rate ? +salesitem?.rate : null,
                lot_no: salesitem?.lot_no ? salesitem?.lot_no : null,
                gst_id: salesitem?.gst_id
                  ? `${salesitem?.gst_id}--${salesitem?.gst || "0"}`
                  : null,
                amount: salesitem?.amount ? +salesitem?.amount : null,
                item_options: salesitem?.item_options
                  ? salesitem?.item_options
                  : [],
              };
            })
          : [],
        vendor_options: result?.vendor_options ? result?.vendor_options : [],
      } as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching Sales Challan details:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const generateChallanNoForSalesChallan = createAsyncThunk(
  "SalesChallan/SalesChallanNumber",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/SalesChallan/SalesChallanNumber`
      );
      return response.data?.Data;
    } catch (error: any) {
      console.error("Error generating challan no. :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getSalesChallanPrint = createAsyncThunk(
  "SalesChallan/SaleschallanRegisterPrint",
  async (sales_challan_guid: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SalesChallan/SaleschallanRegisterPrint`,
        { sales_challan_guid: sales_challan_guid }
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Sales Challan Print:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getProductionLotNoByItemID = createAsyncThunk(
  "ProductCleaning/FindByLotNoWise",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ProductCleaning/FindByLotNoWise`,
        { item_id: ID }
      );
      const result = response.data?.Data as any;
      return result?.map((item: any) => {
        return {
          item_id: item?.item_id,
          lot_no: item?.lot_no,
        };
      });
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching lot no:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getProductionLotNoCurrentStock = createAsyncThunk(
  "StockTransfer/FindAllLotNoCurrentStock",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/StockTransfer/FindAllLotNoCurrentStock`,
        formData
      );
      return response.data?.Data as any;
      // return result?.map((item: any) => {
      //   return {
      //     item_id: item?.item_id,
      //     lot_no: item?.lot_no,
      //   };
      // });
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching current stock:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getRetailerDistributorRates = createAsyncThunk(
  "Retailer/FindByIDREtailerDistributorrate",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Retailer/FindByIDREtailerDistributorrate`,
        formData
      );
      return response.data?.Data as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching rates:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
