import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IArea } from "../areaModel";

export const getAllAreas = createAsyncThunk(
  "Area/FindAllArea",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Area/FindAllArea`,
        formData
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Areas:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createArea = createAsyncThunk(
  "Area/InsertArea",
  async (AreaData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Area/InsertArea`,
        AreaData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating area:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateArea = createAsyncThunk(
  "Area/UpdateArea",
  async (AreaData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Area/UpdateArea`,
        AreaData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating area:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteArea = createAsyncThunk(
  "Area/DeleteArea",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Area/DeleteArea`,
        { area_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting area:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAreaByID = createAsyncThunk(
  "Area/FindByIDArea",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Area/FindByIDArea`,
        { area_guid: ID }
      );
      const result = response.data?.Data as any;
      return {
        id: result?.id,
        area_guid: result?.area_guid,
        area_name: result?.area_name,
        sequence_no: result?.sequence_no,
        city_id: result?.city_id,
        state_id: result?.state_id,
        country_id: result?.country_id,
        pincode: result?.pincode,
        isactive: result.isactive === true ? 1 : 2,
      } as IArea;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching area:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAreaByCityID = createAsyncThunk(
  "Area/FindAllCityWiseArea",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Area/FindAllCityWiseArea`,
        { city_id: ID }
      );
      const result = response.data?.Data as any;
      return result;
    } catch (error: any) {
      console.error("Error fetching area:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveAreas = createAsyncThunk(
  "Area/FindAllActiveArea",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Area/FindAllActiveArea`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching areas:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const activeInactiveArea = createAsyncThunk(
  "Area/ActiveInActiveArea",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Area/ActiveInActiveArea`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive area:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
