import { useEffect, useRef } from "react";

import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  getAccountStatustoryByUserID,
  getEmployeeByID,
  getFamilyMemberByUserID,
} from "../../employee/services/employee.services";
import { LoadingPanel } from "../../../components/layout/Loading";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import { COMPANY_NAME } from "../../../_contstants/common";

const FormAview = () => {
  const state = JSON.parse(
    sessionStorage.getItem("nominationanddeclarationformState") || "{}"
  );
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);

  const loading = useAppSelector((state) => state.employee.loading);

  const setPrintStyles = () => {
    const style = document.createElement("style");
    style.type = "text/css";
    style.innerHTML = `
          @media print {
            @page {
            margin-left: 0.9cm;
              margin-right: 0.9cm;
            }
          }
        `;
    document.head.appendChild(style);
  };
  const fetchReport = async () => {
    const user_guid = state.user_guid;
    const id = state.id;
    setPrintStyles();
    await dispatch(getEmployeeByID(user_guid));
    await dispatch(getAccountStatustoryByUserID(user_guid));
    await dispatch(getFamilyMemberByUserID(id));
  };

  useEffect(() => {
    const handleTabClose = () => {
      sessionStorage.removeItem("nominationanddeclarationformState");
    };
    fetchReport();
    return () => {
      window.removeEventListener("unload", handleTabClose);
    };
  }, []);

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      <div
        style={{
          paddingRight: "20px",
        }}
      >
        <ButtonWithLoading
          buttonStyle={{ position: "fixed", right: 0, top: 0, zIndex: 100 }}
          label={"Print"}
          className="no-visible-print-btn"
          type="button"
          onClick={() => window.print()}
        />
        <div>
          <p>
            Note: Cheque/DD should be in favour of{" "}
            <span
              style={{
                fontSize: "15px",
                fontWeight: "900",
              }}
            >
              "Gujarat Labour Welfare Fund"
            </span>
          </p>
          <div
            style={{
              textAlign: "center",
            }}
          >
            <h6
              style={{
                fontSize: "28px",
                fontWeight: "900",
              }}
            >
              Form - A : 1 (See Rule No. 3B)
            </h6>
            <p
              className="m-0"
              style={{
                fontSize: "18px",
                fontWeight: "500",
              }}
            >
              (Employees and Employer Contribution Form for the month ending{" "}
              <br /> on 30<sup>th</sup> June/31<sup>st</sup> December)
            </p>
            <hr className="m-0" />
          </div>
        </div>
        <div>
          <p
            className="m-0"
            style={{
              textAlign: "end",
              fontWeight: "900",
            }}
          >
            Account No. <u> HO|0000857</u>
          </p>
          <div className="form-a-details">
            <ol>
              <li> Name of the Factory/Institution : {COMPANY_NAME}</li>
              <li>
                Factory/Institution Email Address : hr@shreeganeshmasala.com
              </li>
              <li>Factory/Institution Mobile Number: 9909425371</li>
              <li> Name of the Owner/Employer: Parag Parmanand Shah</li>
              <li>
                Nature of Established Factory/Institution (like Workshops,{" "}
                <br />
                Motor Omni Bus Service, Shops, Business Firms,
                <br /> Resident Hotels, Restaurants, Refreshment Place,
                <br /> Cinema or Other Places of Public Excursion and
                Entertainment) : Factory
              </li>
              <li>
                <p style={{ display: "flex" }} className="m-0">
                  <span
                    style={{
                      width: "60%",
                    }}
                  >
                    Address of the Factory/Institution
                  </span>
                  :
                  <span>
                    519/1, Phase-4, G.I.D.C. Vatva <br /> Nr. Ramol Police
                    Chowki, Vatva Ahmedabad-382445
                  </span>
                </p>
              </li>
              <li>
                Total number of employees registered in the institution as{" "}
                <br /> <b> on 30th June/31st December: 143</b>{" "}
              </li>
              <li>
                <p className="m-0">
                  (i) (1) Employees/Workers Share as per Column #5{" "}
                </p>
                <p style={{ display: "flex", gap: "100px" }}>
                  <span>
                    @ <b> Rs. 6.00 per Employee/Worker </b>
                  </span>
                  :<span>858 </span>
                </p>
                <p className="m-0">
                  (ii) (1) Employer/Owner Share as per Column #5{" "}
                </p>
                <p style={{ display: "flex", gap: "100px" }}>
                  <span>
                    @ <b> Rs. 12.00 per Employee/Worker</b>
                  </span>
                  : <span> 1716</span>
                </p>
              </li>

              <li className="form-a-employee-share">
                <p
                  style={{
                    gap: "50px",
                  }}
                >
                  <span>
                    Details of total fund to be paid to the Welfare Commissioner
                  </span>
                  : <span>2574</span>
                </p>
                <p>
                  <span>A. Numbers of Employees</span> : <span>143</span>
                </p>
                <p>
                  <span
                    style={{
                      paddingLeft: "13px",
                    }}
                  >
                    {" "}
                    Employee's Share{" "}
                  </span>{" "}
                  : <span> 858 </span>
                </p>
                <p>
                  <span>B. Employer's Share </span> : <span> 1716 </span>
                </p>
                <p>
                  <span>C. Total</span> : <span> 2574</span>
                </p>
                <p>
                  <span> D. Date Of Payment </span> : <span> 10/07/2024 </span>
                </p>
                <p>
                  <span>
                    E. Mode of Payment <br />
                    (Cash/Cheque/DD)
                  </span>
                  : <span>Online</span>
                </p>
              </li>
              <li>
                <p style={{ display: "flex", gap: "50px" }}>
                  <span>
                    Reasons for if the contribution not paid / <br /> less paid
                    if any,{" "}
                  </span>
                  : <span>NA</span>
                </p>
              </li>
            </ol>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p>
              <b>Date:</b> 10/07/2024
            </p>
            <p> Signature of Employer</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormAview;
