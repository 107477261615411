import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ICreditNoteRegisterList,
  IDebitNoteRegisterList,
  IDistributorCurrentStockDetails,
  IEmployeeDetailsReportList,
  IEmployeeRegisterReportList,
  IGhantiCleaningReportList,
  IInwardPackingMaterialStockReportList,
  IInwardRegisterList,
  IMetalCleaningReportList,
  IOfficeStaffOutReportList,
  IOutwardPackingMaterialStockReportList,
  IPackingMaterialStockReportList,
  IPackingRegisterReportList,
  IPackingStockReportList,
  IPORegisterList,
  IProductCleaningReportList,
  IProductionReportList,
  IProductMillingReportList,
  IPurchaseDueDateReportList,
  IPurchaseRegisterList,
  IReportInitialState,
  IRetailerRateListReportList,
  IRoastingReportList,
  ISalaryRegisterList,
  ISalesRegisterList,
  ISchemeReportList,
  IStockReportList,
  IVisitorReportList,
} from "./reportsModel";
import {
  getAccountReport,
  getAllAdultWorkersReport,
  getCreditNoteRegisterReport,
  getDebitNoteRegisterReport,
  getDistributorCurrentStockReportByID,
  getEmployeeDetailsReport,
  getEmployeeRegisterReport,
  getGhantiCleaningReport,
  getInwardPackingMaterialStockReport,
  getInwardRegisterReport,
  getItemStockReport,
  getLeaveWithWageReport,
  getMetalCleaningReport,
  getOfficeStaffOutReport,
  getOutwardPackingMaterialStockReport,
  getPackingMaterialStockReport,
  getPackingRegisterReport,
  getPackingStoreWiseStockReport,
  getPORegisterReport,
  getProductCleaningReport,
  getProductionReport,
  getProductMillingReport,
  getPurchaseDueDateInvoiceReport,
  getPurchaseRegisterReport,
  getRetailerRateReport,
  getRoastingReport,
  getSalaryRegisterReport,
  getSalesRegisterReport,
  getSchemeReport,
  getVisitorReport,
} from "./services/reports.services";
import moment from "moment";
import { IEmployee } from "../employee/employeeModel";

const currentMonth = new Date().getMonth() + 1;
const currentYear = new Date().getFullYear();

const previousMonth = currentMonth === 1 ? 12 : currentMonth - 1;
const previousYear = currentMonth === 1 ? currentYear - 1 : currentYear;

const initialState: IReportInitialState = {
  loading: false,
  listLoading: false,
  error: "",
  ProductMillingReportList: [],
  ProductMillingReportDetails: {
    item_id: null,
    from_date: "",
    to_date: "",
  },
  ProductionReportList: [],
  ProductionReportDetails: {
    item_id: null,
    from_date: "",
    to_date: "",
  },
  GhantiCleaningReportList: [],
  GhantiCleaningReportDetails: {
    machine_no: null,
    from_date: "",
    to_date: "",
  },
  AdultWorkersReportList: [],
  MetalCleaningReportList: [],
  MetalCleaningReportDetails: {
    item_id: null,
    machine_no: null,
    from_date: "",
    to_date: "",
  },
  ProductCleaningReportList: [],
  ProductCleaningReportDetails: {
    item_id: null,
    from_date: "",
    to_date: "",
  },
  RoastingReportList: [],
  RoastingReportDetails: {
    item_id: null,
    from_date: "",
    to_date: "",
  },
  InwardPackingMaterialStockList: [],
  InwardPackingMaterialStocksDetails: {
    item_id: null,
    from_date: "",
    to_date: "",
  },
  OutwardPackingMaterialStockList: [],
  OutwardPackingMaterialStocksDetails: {
    item_id: null,
    from_date: "",
    to_date: "",
  },
  InwardRegisterList: [],
  InwardRegisterDetails: {
    item_id: null,
    start_date: moment().startOf("month")?.format("YYYY-MM-DD") || "",
    end_Date: moment()?.format("YYYY-MM-DD") || "",
  },
  StockReportList: [],
  StockReportDetails: {
    item_id: null,
    grn_no: null,
  },
  PackingRegisterReportList: [],
  PackingRegisterReportDetails: {
    item_id: null,
    from_date: "",
    to_date: "",
  },
  PackingMaterialStockReportList: [],
  PackingMaterialStockReportDetails: {
    item_id: null,
    from_date: "",
    to_date: "",
  },
  VisitorReportList: [],
  VisitorReportDetails: {
    user_name: "",
    from_date: "",
    to_date: "",
  },
  OfficeStaffOutReportList: [],
  OfficeStaffOutReportDetails: {
    user_name: "",
    from_date: "",
    to_date: "",
  },
  PurchaseDueDateReportList: [],
  PurchaseDueDateReportDetails: {
    from_date: "",
    to_date: "",
  },
  DistributorCurrentStockFormDetails: {
    accountid: null,
    from_date: "",
    to_date: "",
  },
  DistributorCurrentStockDetails: {
    accountid: null,
    date: "",
    distributer_stock: [],
  },
  RetailerRateListReportList: [],
  PORegisterList: [],
  PORegisterDetails: {
    from_date: moment().startOf("month")?.format("YYYY-MM-DD") || "",
    to_Date: moment()?.format("YYYY-MM-DD") || "",
    item_id: null,
    vendor_id: null,
  },
  PurchaseRegisterList: [],
  PurchaseRegisterDetails: {
    from_date: moment().startOf("month")?.format("YYYY-MM-DD") || "",
    to_Date: moment()?.format("YYYY-MM-DD") || "",
    item_id: null,
    vendor_id: null,
  },
  SalesRegisterList: [],
  SalesRegisterDetails: {
    from_date: moment().startOf("month")?.format("YYYY-MM-DD") || "",
    to_Date: moment()?.format("YYYY-MM-DD") || "",
    item_group_id: null,
    item_id: null,
    vendor_id: null,
  },
  SalaryRegisterList: [],
  SalaryRegisterDetails: {
    location: "",
    payout_year: `${previousYear}`,
    payout_month: previousMonth,
    isSalaryRegisterRef: false,
  },
  EmployeeDetailsReportList: [],
  EmployeeDetailsReport: {
    department_id: null,
    employee_id: null,
  },
  EmployeeRegisterReportList: [],
  EmployeeRegisterReportDetails: {
    department_id: null,
  },
  SchemeReportList: [],
  SchemeReportDetails: {
    from_date: "",
    to_Date: "",
    scheme_id: null,
    vendor_id: null,
  },
  AccountReportList: [],
  LeaveWithWageReportCard: {
    id: null,
    first_name: "",
    middle_name: "",
    last_name: "",
    gender: "",
    department_id: null,
    department_name: "",
    designation_id: null,
    designation_name: "",
    date_of_joining: "",
    employee_code_string: "",
    month_data: [],
  },
  PackingStockReportList: [],
  PackingStockReportDetails: {
    from_date: moment().startOf("month")?.format("YYYY-MM-DD") || "",
    to_date: moment()?.format("YYYY-MM-DD") || "",
    item_group_id: null,
    item_category_id: null,
    item_id: null,
  },
  DebitNoteRegisterList: [],
  DebitNoteRegisterDetails: {
    from_date: moment().startOf("month")?.format("YYYY-MM-DD") || "",
    to_date: moment()?.format("YYYY-MM-DD") || "",
    item_id: null,
    vendor_id: null,
  },
  CreditNoteRegisterList: [],
  CreditNoteRegisterDetails: {
    from_date: moment().startOf("month")?.format("YYYY-MM-DD") || "",
    to_date: moment()?.format("YYYY-MM-DD") || "",
    item_id: null,
    vendor_id: null,
  },
};

const reportSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    setProductMillingReport: (state, action) => {
      state.ProductMillingReportDetails = action.payload;
    },
    clearProductMillingReportList: (state) => {
      state.ProductMillingReportList = [];
    },
    clearProductMillingReportDetails: (state) => {
      state.ProductMillingReportDetails =
        initialState.ProductMillingReportDetails;
    },
    setProductionReport: (state, action) => {
      state.ProductionReportDetails = action.payload;
    },
    clearProductionReportList: (state) => {
      state.ProductionReportList = [];
    },
    clearProductionReportDetails: (state) => {
      state.ProductionReportDetails = initialState.ProductionReportDetails;
    },
    setGhantiCleaningReport: (state, action) => {
      state.GhantiCleaningReportDetails = action.payload;
    },
    clearGhantiCleaningReportList: (state) => {
      state.GhantiCleaningReportList = [];
    },
    clearGhantiCleaningReportDetails: (state) => {
      state.GhantiCleaningReportDetails =
        initialState.GhantiCleaningReportDetails;
    },
    setMetalCleaningReport: (state, action) => {
      state.MetalCleaningReportDetails = action.payload;
    },
    clearMetalCleaningReportList: (state) => {
      state.MetalCleaningReportList = [];
    },
    clearPackingRegisterReportDetails: (state) => {
      state.PackingRegisterReportDetails =
        initialState.PackingRegisterReportDetails;
    },
    clearMetalCleaningReportDetails: (state) => {
      state.MetalCleaningReportDetails =
        initialState.MetalCleaningReportDetails;
    },
    setProductCleaningReport: (state, action) => {
      state.ProductCleaningReportDetails = action.payload;
    },
    clearProductCleaningReportList: (state) => {
      state.ProductCleaningReportList = [];
    },
    clearProductCleaningReportDetails: (state) => {
      state.ProductCleaningReportDetails =
        initialState.ProductCleaningReportDetails;
    },
    setRoastingReport: (state, action) => {
      state.RoastingReportDetails = action.payload;
    },
    clearRoastingReportList: (state) => {
      state.RoastingReportList = [];
    },
    clearRoastingReportDetails: (state) => {
      state.RoastingReportDetails = initialState.RoastingReportDetails;
    },
    setInwardPackingStockReport: (state, action) => {
      state.InwardPackingMaterialStocksDetails = action.payload;
    },
    clearInwardPackingStockList: (state) => {
      state.InwardPackingMaterialStockList = [];
    },
    clearInwardPackingMaterialStocksDetails: (state) => {
      state.InwardPackingMaterialStocksDetails =
        initialState.InwardPackingMaterialStocksDetails;
    },
    setOutwardPackingStockReport: (state, action) => {
      state.OutwardPackingMaterialStocksDetails = action.payload;
    },
    clearOutwardPackingStockList: (state) => {
      state.OutwardPackingMaterialStockList = [];
    },
    clearOutwardPackingMaterialStocksDetails: (state) => {
      state.OutwardPackingMaterialStocksDetails =
        initialState.OutwardPackingMaterialStocksDetails;
    },
    setInwardRegisterReport: (state, action) => {
      state.InwardRegisterDetails = action.payload;
    },
    clearInwardRegisterList: (state) => {
      state.InwardRegisterList = [];
    },
    clearInwardRegisterDetails: (state) => {
      state.InwardRegisterDetails = initialState.InwardRegisterDetails;
    },
    clearStockReportList: (state) => {
      state.StockReportList = [];
    },
    setPackingRegisterReport: (state, action) => {
      state.PackingRegisterReportDetails = action.payload;
    },
    clearPackingRegisterReportList: (state) => {
      state.PackingRegisterReportList = [];
    },
    clearPackingMaterialStockReportList: (state) => {
      state.PackingMaterialStockReportList = [];
    },
    setVisitorReport: (state, action) => {
      state.VisitorReportDetails = action.payload;
    },
    clearVisitorReportList: (state) => {
      state.VisitorReportList = [];
    },
    clearVisitorReportDetails: (state) => {
      state.VisitorReportDetails = initialState.VisitorReportDetails;
    },
    setOfficeStaffOutReport: (state, action) => {
      state.OfficeStaffOutReportDetails = action.payload;
    },
    clearOfficeStaffOutReportList: (state) => {
      state.OfficeStaffOutReportList = [];
    },
    clearOfficeStaffOutReportDetails: (state) => {
      state.OfficeStaffOutReportDetails =
        initialState.OfficeStaffOutReportDetails;
    },
    setPurchaseDueDateReport: (state, action) => {
      state.PurchaseDueDateReportDetails = action.payload;
    },
    clearPurchaseDueDateReportList: (state) => {
      state.PurchaseDueDateReportList = [];
    },
    clearPurchaseDueDateReportDetails: (state) => {
      state.PurchaseDueDateReportDetails =
        initialState.PurchaseDueDateReportDetails;
    },
    clearDistributorCurrentStockDetails: (state) => {
      state.DistributorCurrentStockDetails =
        initialState.DistributorCurrentStockDetails;
    },
    clearRetailerRateListReportList: (state) => {
      state.RetailerRateListReportList = [];
    },
    clearPORegisterList: (state) => {
      state.PORegisterList = [];
    },
    clearPORegisterDetails: (state) => {
      state.PORegisterDetails = initialState.PORegisterDetails;
    },
    setPORegisterReport: (state, action) => {
      state.PORegisterDetails = action.payload;
    },
    setDistributorCurrentStockFormDetails: (state, action) => {
      state.DistributorCurrentStockFormDetails = action.payload;
    },
    clearPurchaseRegisterList: (state) => {
      state.PurchaseRegisterList = [];
    },
    clearPurchaseRegisterDetails: (state) => {
      state.PurchaseRegisterDetails = initialState.PurchaseRegisterDetails;
    },
    setPurchaseRegisterReport: (state, action) => {
      state.PurchaseRegisterDetails = action.payload;
    },
    clearSalesRegisterList: (state) => {
      state.SalesRegisterList = [];
    },
    clearSalesRegisterDetails: (state) => {
      state.SalesRegisterDetails = initialState.SalesRegisterDetails;
    },
    setSalesRegisterReport: (state, action) => {
      state.SalesRegisterDetails = action.payload;
    },
    clearSalaryRegisterList: (state) => {
      state.SalaryRegisterList = [];
    },
    clearSalaryRegisterDetails: (state) => {
      state.SalaryRegisterDetails = initialState.SalaryRegisterDetails;
    },
    setSalaryRegisterReport: (state, action) => {
      state.SalaryRegisterDetails = action.payload;
    },

    clearEmployeeDetailsReportList: (state) => {
      state.EmployeeDetailsReportList = [];
    },
    clearEmployeeDetailsReport: (state) => {
      state.EmployeeDetailsReport = initialState.EmployeeDetailsReport;
    },
    setEmployeeDetailsReport: (state, action) => {
      state.EmployeeDetailsReport = action.payload;
    },

    clearEmployeeRegisterReportList: (state) => {
      state.EmployeeRegisterReportList = [];
    },
    clearEmployeeRegisterReportDetails: (state) => {
      state.EmployeeRegisterReportDetails =
        initialState.EmployeeRegisterReportDetails;
    },
    setEmployeeRegisterReportDetails: (state, action) => {
      state.EmployeeRegisterReportDetails = action.payload;
    },
    clearSchemeReportList: (state) => {
      state.SchemeReportList = [];
    },
    clearSchemeReportDetails: (state) => {
      state.SchemeReportDetails = initialState.SchemeReportDetails;
    },
    setSchemeReport: (state, action) => {
      state.SchemeReportDetails = action.payload;
    },
    clearAccountReportList: (state) => {
      state.AccountReportList = initialState.AccountReportList;
    },
    clearPackingStockReportList: (state) => {
      state.PackingStockReportList = [];
    },
    clearPackingStockReportDetails: (state) => {
      state.PackingStockReportDetails = initialState.PackingStockReportDetails;
    },
    setPackingStockReportReport: (state, action) => {
      state.PackingStockReportDetails = action.payload;
    },

    clearDebitNoteRegisterList: (state) => {
      state.DebitNoteRegisterList = [];
    },
    clearDebitNoteRegisterDetails: (state) => {
      state.DebitNoteRegisterDetails = initialState.DebitNoteRegisterDetails;
    },
    setDebitNoteRegisterReport: (state, action) => {
      state.DebitNoteRegisterDetails = action.payload;
    },
    clearCreditNoteRegisterList: (state) => {
      state.CreditNoteRegisterList = [];
    },
    clearCreditNoteRegisterDetails: (state) => {
      state.CreditNoteRegisterDetails = initialState.CreditNoteRegisterDetails;
    },
    setCreditNoteRegisterReport: (state, action) => {
      state.CreditNoteRegisterDetails = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProductMillingReport.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getProductMillingReport.fulfilled,
      (state, action: PayloadAction<IProductMillingReportList[]>) => {
        state.loading = false;
        state.ProductMillingReportList = action.payload || [];
      }
    );
    builder.addCase(getProductMillingReport.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.ProductMillingReportList = [];
    });
    builder.addCase(getProductionReport.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getProductionReport.fulfilled,
      (state, action: PayloadAction<IProductionReportList[]>) => {
        state.loading = false;
        state.ProductionReportList = action.payload || [];
      }
    );
    builder.addCase(getProductionReport.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.ProductionReportList = [];
    });
    builder.addCase(getGhantiCleaningReport.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getGhantiCleaningReport.fulfilled,
      (state, action: PayloadAction<IGhantiCleaningReportList[]>) => {
        state.loading = false;
        state.GhantiCleaningReportList = action.payload || [];
      }
    );
    builder.addCase(getGhantiCleaningReport.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.GhantiCleaningReportList = [];
    });
    builder.addCase(getMetalCleaningReport.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getMetalCleaningReport.fulfilled,
      (state, action: PayloadAction<IMetalCleaningReportList[]>) => {
        state.loading = false;
        state.MetalCleaningReportList = action.payload || [];
      }
    );
    builder.addCase(getMetalCleaningReport.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.MetalCleaningReportList = [];
    });
    builder.addCase(getProductCleaningReport.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getProductCleaningReport.fulfilled,
      (state, action: PayloadAction<IProductCleaningReportList[]>) => {
        state.loading = false;
        state.ProductCleaningReportList = action.payload || [];
      }
    );
    builder.addCase(getProductCleaningReport.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.ProductCleaningReportList = [];
    });
    builder.addCase(getRoastingReport.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getRoastingReport.fulfilled,
      (state, action: PayloadAction<IRoastingReportList[]>) => {
        state.loading = false;
        state.RoastingReportList = action.payload || [];
      }
    );
    builder.addCase(getRoastingReport.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.RoastingReportList = [];
    });
    builder.addCase(getInwardPackingMaterialStockReport.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getInwardPackingMaterialStockReport.fulfilled,
      (
        state,
        action: PayloadAction<IInwardPackingMaterialStockReportList[]>
      ) => {
        state.loading = false;
        state.InwardPackingMaterialStockList = action.payload || [];
      }
    );
    builder.addCase(
      getInwardPackingMaterialStockReport.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
        state.InwardPackingMaterialStockList = [];
      }
    );

    builder.addCase(getOutwardPackingMaterialStockReport.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getOutwardPackingMaterialStockReport.fulfilled,
      (
        state,
        action: PayloadAction<IOutwardPackingMaterialStockReportList[]>
      ) => {
        state.loading = false;
        state.OutwardPackingMaterialStockList = action.payload || [];
      }
    );
    builder.addCase(
      getOutwardPackingMaterialStockReport.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
        state.OutwardPackingMaterialStockList = [];
      }
    );
    builder.addCase(getInwardRegisterReport.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getInwardRegisterReport.fulfilled,
      (state, action: PayloadAction<IInwardRegisterList[]>) => {
        state.loading = false;
        state.InwardRegisterList = action.payload || [];
      }
    );
    builder.addCase(getInwardRegisterReport.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.InwardRegisterList = [];
    });

    builder.addCase(getItemStockReport.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getItemStockReport.fulfilled,
      (state, action: PayloadAction<IStockReportList[]>) => {
        state.loading = false;
        state.StockReportList = action.payload || [];
      }
    );
    builder.addCase(getItemStockReport.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.StockReportList = [];
    });

    builder.addCase(getPackingRegisterReport.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getPackingRegisterReport.fulfilled,
      (state, action: PayloadAction<IPackingRegisterReportList[]>) => {
        state.loading = false;
        state.PackingRegisterReportList = action.payload || [];
      }
    );
    builder.addCase(getPackingRegisterReport.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.PackingRegisterReportList = [];
    });

    builder.addCase(getPackingMaterialStockReport.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getPackingMaterialStockReport.fulfilled,
      (state, action: PayloadAction<IPackingMaterialStockReportList[]>) => {
        state.loading = false;
        state.PackingMaterialStockReportList = action.payload || [];
      }
    );
    builder.addCase(getPackingMaterialStockReport.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.PackingMaterialStockReportList = [];
    });

    builder.addCase(getVisitorReport.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getVisitorReport.fulfilled,
      (state, action: PayloadAction<IVisitorReportList[]>) => {
        state.loading = false;
        state.VisitorReportList = action.payload || [];
      }
    );
    builder.addCase(getVisitorReport.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.VisitorReportList = [];
    });

    builder.addCase(getOfficeStaffOutReport.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getOfficeStaffOutReport.fulfilled,
      (state, action: PayloadAction<IOfficeStaffOutReportList[]>) => {
        state.loading = false;
        state.OfficeStaffOutReportList = action.payload || [];
      }
    );
    builder.addCase(getOfficeStaffOutReport.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.OfficeStaffOutReportList = [];
    });

    builder.addCase(getPurchaseDueDateInvoiceReport.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getPurchaseDueDateInvoiceReport.fulfilled,
      (state, action: PayloadAction<IPurchaseDueDateReportList[]>) => {
        state.loading = false;
        state.PurchaseDueDateReportList = action.payload || [];
      }
    );
    builder.addCase(
      getPurchaseDueDateInvoiceReport.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
        state.PurchaseDueDateReportList = [];
      }
    );

    builder.addCase(getDistributorCurrentStockReportByID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getDistributorCurrentStockReportByID.fulfilled,
      (state, action: PayloadAction<IDistributorCurrentStockDetails>) => {
        state.loading = false;
        state.DistributorCurrentStockDetails = action.payload;
      }
    );
    builder.addCase(
      getDistributorCurrentStockReportByID.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
        state.DistributorCurrentStockDetails =
          initialState.DistributorCurrentStockDetails;
      }
    );

    builder.addCase(getRetailerRateReport.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getRetailerRateReport.fulfilled,
      (state, action: PayloadAction<IRetailerRateListReportList[]>) => {
        state.loading = false;
        state.RetailerRateListReportList = action.payload || [];
      }
    );
    builder.addCase(getRetailerRateReport.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.RetailerRateListReportList = [];
    });

    builder.addCase(getPORegisterReport.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getPORegisterReport.fulfilled,
      (state, action: PayloadAction<IPORegisterList[]>) => {
        state.loading = false;
        state.PORegisterList = action.payload || [];
      }
    );
    builder.addCase(getPORegisterReport.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.PORegisterList = [];
    });

    builder.addCase(getPurchaseRegisterReport.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getPurchaseRegisterReport.fulfilled,
      (state, action: PayloadAction<IPurchaseRegisterList[]>) => {
        state.loading = false;
        state.PurchaseRegisterList = action.payload || [];
      }
    );
    builder.addCase(getPurchaseRegisterReport.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.PurchaseRegisterList = [];
    });

    builder.addCase(getSalesRegisterReport.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getSalesRegisterReport.fulfilled,
      (state, action: PayloadAction<ISalesRegisterList[]>) => {
        state.loading = false;
        state.SalesRegisterList = action.payload || [];
      }
    );
    builder.addCase(getSalesRegisterReport.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.SalesRegisterList = [];
    });

    builder.addCase(getSalaryRegisterReport.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getSalaryRegisterReport.fulfilled,
      (state, action: PayloadAction<ISalaryRegisterList[]>) => {
        state.loading = false;
        state.SalaryRegisterList = action.payload || [];
      }
    );
    builder.addCase(getSalaryRegisterReport.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.SalaryRegisterList = [];
    });

    builder.addCase(getEmployeeDetailsReport.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getEmployeeDetailsReport.fulfilled,
      (state, action: PayloadAction<IEmployeeDetailsReportList[]>) => {
        state.loading = false;
        state.EmployeeDetailsReportList = action.payload || [];
      }
    );
    builder.addCase(getEmployeeDetailsReport.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.EmployeeDetailsReportList = [];
    });

    builder.addCase(getEmployeeRegisterReport.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getEmployeeRegisterReport.fulfilled,
      (state, action: PayloadAction<IEmployeeRegisterReportList[]>) => {
        state.loading = false;
        state.EmployeeRegisterReportList = action.payload || [];
      }
    );
    builder.addCase(getEmployeeRegisterReport.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.EmployeeRegisterReportList = [];
    });

    builder.addCase(getSchemeReport.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getSchemeReport.fulfilled,
      (state, action: PayloadAction<ISchemeReportList[]>) => {
        state.loading = false;
        state.SchemeReportList = action.payload || [];
      }
    );
    builder.addCase(getSchemeReport.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.SchemeReportList = [];
    });

    builder.addCase(getAccountReport.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAccountReport.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.AccountReportList = action.payload || [];
      }
    );
    builder.addCase(getAccountReport.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.AccountReportList = [];
    });

    builder.addCase(getLeaveWithWageReport.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getLeaveWithWageReport.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.LeaveWithWageReportCard = action.payload || null;
      }
    );
    builder.addCase(getLeaveWithWageReport.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.LeaveWithWageReportCard = initialState.LeaveWithWageReportCard;
    });

    builder.addCase(getPackingStoreWiseStockReport.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getPackingStoreWiseStockReport.fulfilled,
      (state, action: PayloadAction<IPackingStockReportList[]>) => {
        state.loading = false;
        state.PackingStockReportList = action.payload || [];
      }
    );
    builder.addCase(
      getPackingStoreWiseStockReport.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
        state.PackingStockReportList = [];
      }
    );
    builder.addCase(getDebitNoteRegisterReport.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getDebitNoteRegisterReport.fulfilled,
      (state, action: PayloadAction<IDebitNoteRegisterList[]>) => {
        state.loading = false;
        state.DebitNoteRegisterList = action.payload || [];
      }
    );
    builder.addCase(getDebitNoteRegisterReport.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.DebitNoteRegisterList = [];
    });

    builder.addCase(getCreditNoteRegisterReport.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getCreditNoteRegisterReport.fulfilled,
      (state, action: PayloadAction<ICreditNoteRegisterList[]>) => {
        state.loading = false;
        state.CreditNoteRegisterList = action.payload || [];
      }
    );
    builder.addCase(getCreditNoteRegisterReport.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.CreditNoteRegisterList = [];
    });

    builder.addCase(getAllAdultWorkersReport.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllAdultWorkersReport.fulfilled,
      (state, action: PayloadAction<IEmployee[]>) => {
        state.loading = false;
        state.AdultWorkersReportList = action.payload || [];
      }
    );
    builder.addCase(getAllAdultWorkersReport.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.AdultWorkersReportList = [];
    });
  },
});

export const {
  setProductMillingReport,
  clearProductMillingReportList,
  clearProductMillingReportDetails,
  setProductionReport,
  clearProductionReportList,
  clearProductionReportDetails,
  setGhantiCleaningReport,
  clearGhantiCleaningReportList,
  clearGhantiCleaningReportDetails,
  setMetalCleaningReport,
  clearMetalCleaningReportList,
  clearPackingRegisterReportDetails,
  clearMetalCleaningReportDetails,
  setProductCleaningReport,
  clearProductCleaningReportList,
  clearProductCleaningReportDetails,
  setRoastingReport,
  clearRoastingReportList,
  clearRoastingReportDetails,
  setInwardPackingStockReport,
  clearInwardPackingStockList,
  clearInwardPackingMaterialStocksDetails,
  setOutwardPackingStockReport,
  clearOutwardPackingMaterialStocksDetails,
  clearOutwardPackingStockList,
  setInwardRegisterReport,
  clearInwardRegisterList,
  clearInwardRegisterDetails,
  clearStockReportList,
  setPackingRegisterReport,
  clearPackingRegisterReportList,
  clearPackingMaterialStockReportList,
  setVisitorReport,
  clearVisitorReportList,
  clearVisitorReportDetails,
  setOfficeStaffOutReport,
  clearOfficeStaffOutReportList,
  clearOfficeStaffOutReportDetails,
  setPurchaseDueDateReport,
  clearPurchaseDueDateReportList,
  clearPurchaseDueDateReportDetails,
  clearDistributorCurrentStockDetails,
  setDistributorCurrentStockFormDetails,
  clearRetailerRateListReportList,
  clearPORegisterList,
  clearPORegisterDetails,
  setPORegisterReport,
  setPurchaseRegisterReport,
  clearPurchaseRegisterList,
  clearPurchaseRegisterDetails,
  setSalesRegisterReport,
  clearSalesRegisterList,
  clearSalesRegisterDetails,
  clearSalaryRegisterDetails,
  clearSalaryRegisterList,
  setSalaryRegisterReport,
  clearEmployeeDetailsReport,
  clearEmployeeDetailsReportList,
  setEmployeeDetailsReport,
  clearEmployeeRegisterReportDetails,
  clearEmployeeRegisterReportList,
  setEmployeeRegisterReportDetails,
  clearSchemeReportList,
  clearSchemeReportDetails,
  setSchemeReport,
  clearAccountReportList,
  setPackingStockReportReport,
  clearPackingStockReportDetails,
  clearPackingStockReportList,
  clearDebitNoteRegisterDetails,
  setDebitNoteRegisterReport,
  clearDebitNoteRegisterList,
  clearCreditNoteRegisterDetails,
  setCreditNoteRegisterReport,
  clearCreditNoteRegisterList,
} = reportSlice.actions;
export default reportSlice.reducer;
