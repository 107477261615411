import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  filterBy,
  CompositeFilterDescriptor,
} from "@progress/kendo-data-query";
import {
  Grid as KendoGrid,
  GridColumn as Column,
  // GridCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  GridCustomHeaderCellProps,
  GridCustomCellProps,
  GridSelectionChangeEvent,
  GridHeaderSelectionChangeEvent,
} from "@progress/kendo-react-grid";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import {
  HeaderThElement,
  getSelectedState,
} from "@progress/kendo-react-data-tools";
import { getter, Typography } from "@progress/kendo-react-common";
import { Button } from "@progress/kendo-react-buttons";
// import { FiEdit } from "react-icons/fi";
import { LoadingPanel } from "../../components/layout/Loading";
// import IconButton from "../../components/common/IconButton";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
// import { MdDelete } from "react-icons/md";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import { setCurrentPage, setPageLimit } from "../pagination/paginationSlice";
import { checkAcessRights } from "../../_helper/helper";
import {
  DELETE_ACCESS,
  // EDIT_ACCESS,
  INSERT_ACCESS,
} from "../../_contstants/common";
import {
  clearFilterCustomSalesRepresentative,
  clearSalesRepresentativeID,
  setFilterCustomSalesRepresentative,
  setSalesRepresentativeID,
} from "./salesRepresentativeSlice";
import { TextBox } from "@progress/kendo-react-inputs";
import { filterClearIcon } from "@progress/kendo-svg-icons";
import _debounce from "lodash/debounce";
import {
  deleteSalesRepresentative,
  getAllSalesRepresentativeAssignArea,
} from "./services/salesRepresentative.services";
import IconButton from "../../components/common/IconButton";
import { MdDelete } from "react-icons/md";

const SalesRepresentative: React.FC = () => {
  const navigate = useNavigate();
  const isAddAccess = checkAcessRights(location.pathname, INSERT_ACCESS);

  const handleCreate = () => {
    navigate("/salesrepresentativeschedule/create");
  };

  return (
    <>
      <DeleteSalesRepresentativeDialog />
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "4px 20px 20px 0",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            Sales Representative Schedule List
          </Typography.h4>
          {isAddAccess && (
            <Button
              onClick={handleCreate}
              fillMode={"solid"}
              themeColor={"primary"}
            >
              {"Add New"}
            </Button>
          )}
        </GridLayoutItem>
        <GridLayoutItem>
          <SalesRepresentaiveGridComponent />
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

const SalesRepresentaiveGridComponent: React.FC = () => {
  const gridRef = useRef<any>(null);
  const isEditBtnRef = useRef<any>(false);
  const dispatch = useAppDispatch();
  // const navigate = useNavigate();
  // const isEditAccess = checkAcessRights(location.pathname, EDIT_ACCESS);
  const isDeleteAccess = checkAcessRights(location.pathname, DELETE_ACCESS);

  const SalesRepresentativeAssignAreaList = useAppSelector(
    (state) => state.salesrepresentative.SalesRepresentativeAssignAreaList
  );
  const loading = useAppSelector((state) => state.salesrepresentative.loading);
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);
  const FilterCustomSalesRepresentative = useAppSelector(
    (state) => state.salesrepresentative.FilterCustomSalesRepresentative
  );
  const [selectedState, setSelectedState] = React.useState<{
    [id: string]: boolean | number[];
  }>({});

  const [rowSelectionModel, setRowSelectionModel] = React.useState<number[]>(
    []
  );

  useEffect(() => {
    setRowSelectionModel([]);
  }, [SalesRepresentativeAssignAreaList]);

  const dataState = SalesRepresentativeAssignAreaList?.map((dataItem: any) =>
    Object.assign({ selected: false }, dataItem)
  );

  const DATA_ITEM_KEY: string = "id";
  const SELECTED_FIELD: string = "selected";
  const idGetter = getter(DATA_ITEM_KEY);

  useEffect(() => {
    return () => {
      dispatch(setCurrentPage(0));
      if (!isEditBtnRef.current) {
        dispatch(clearFilterCustomSalesRepresentative());
      }
    };
  }, []);

  useEffect(() => {
    const payload = {
      ...FilterCustomSalesRepresentative,
      limit: +pageLimit,
      skip: +currentPage * +pageLimit,
    };
    dispatch(getAllSalesRepresentativeAssignArea(payload));
  }, [currentPage, pageLimit]);

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);

  const pageChange = (event: GridPageChangeEvent) => {
    const newPage = event.page.skip / event.page.take;
    dispatch(setPageLimit(event.page.take));
    dispatch(setCurrentPage(newPage));
  };

  // const MyEditCommandCell = (props: GridCellProps) => {
  //   const handleEdit = (purchase_guid: any) => {
  //     navigate("/purchase/edit", {
  //       state: { purchase_guid: purchase_guid },
  //     });
  //   };

  //   const handleOpenDeleteDialog = (purchase_guid: any) => {
  //     dispatch(openDialog("deletePurchase"));
  //     dispatch(setPurchaseID(purchase_guid));
  //   };

  //   return (
  //     <td
  //       className={`action-td ${props.className}`}
  //       style={{
  //         ...props.style,
  //       }}
  //     >
  //       {isEditAccess && (
  //         <IconButton
  //           type="button"
  //           fillMode="flat"
  //           size="small"
  //           title="Edit"
  //           ref={isEditBtnRef}
  //           onClick={() => {
  //             isEditBtnRef.current = true;
  //             handleEdit(props.dataItem?.purchase_guid);
  //           }}
  //         >
  //           <FiEdit
  //             className="absolute-position"
  //             style={{ fontSize: "20px" }}
  //           />
  //         </IconButton>
  //       )}
  //       {isDeleteAccess && (
  //         <IconButton
  //           type="button"
  //           fillMode="flat"
  //           size="small"
  //           title="Delete"
  //           onClick={() =>
  //             handleOpenDeleteDialog(props.dataItem?.purchase_guid)
  //           }
  //         >
  //           <MdDelete
  //             className="absolute-position"
  //             style={{ fontSize: "24px" }}
  //           />
  //         </IconButton>
  //       )}
  //     </td>
  //   );
  // };

  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
  };
  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className={`table-header ${props.index === 9 ? "locked-header" : ""}`}
    >
      {props.children}
    </HeaderThElement>
  );

  const onSelectionChange = React.useCallback(
    (event: GridSelectionChangeEvent) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: DATA_ITEM_KEY,
      });
      setSelectedState(newSelectedState);
      const trueKeys = Object.keys(newSelectedState)
        .filter((key) => newSelectedState[key])
        .map(Number);
      setRowSelectionModel(trueKeys);
    },
    [selectedState]
  );

  const onHeaderSelectionChange = React.useCallback(
    (event: GridHeaderSelectionChangeEvent) => {
      const checkboxElement: any = event.syntheticEvent.target;
      const checked = checkboxElement.checked;
      const newSelectedState: { [key: string]: boolean } = {};

      event.dataItems.forEach((item) => {
        newSelectedState[idGetter(item)] = checked;
      });
      setSelectedState(newSelectedState);
      const trueKeys = Object.keys(newSelectedState)
        .filter((key: string) => newSelectedState[key])
        .map(Number);
      setRowSelectionModel(trueKeys);
    },
    []
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );
  const handleOpenDeleteDialog = (id: any) => {
    dispatch(openDialog("deletesalesRepresentative"));
    dispatch(setSalesRepresentativeID(id));
  };

  return (
    <>
      {loading && <LoadingPanel gridRef={gridRef} />}
      {/* {isAddAccess && ( */}
      {isDeleteAccess && rowSelectionModel && rowSelectionModel.length > 0 && (
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="Delete"
          style={{
            position: "absolute",
            right: "130px",
            top: "20px",
          }}
          onClick={() => handleOpenDeleteDialog(rowSelectionModel.join(","))}
        >
          <MdDelete
            className="absolute-position"
            style={{ fontSize: "24px" }}
          />
        </IconButton>
      )}

      {/* )} */}
      <KendoGrid
        style={{ maxHeight: "calc(100vh - 152px)" }}
        className="responsive-table"
        filterable={true}
        filter={filter}
        dataItemKey={DATA_ITEM_KEY}
        data={
          filter
            ? filterBy(SalesRepresentativeAssignAreaList, filter)?.map(
                (item: any) => ({
                  ...item,
                  [SELECTED_FIELD]: selectedState[idGetter(item)],
                })
              )
            : SalesRepresentativeAssignAreaList?.map((item: any) => ({
                ...item,
                [SELECTED_FIELD]: selectedState[idGetter(item)],
              }))
        }
        onFilterChange={handleFilterChange}
        total={
          SalesRepresentativeAssignAreaList[0]?.itemcount ||
          SalesRepresentativeAssignAreaList?.length
        }
        selectedField={SELECTED_FIELD}
        skip={currentPage * pageLimit}
        take={pageLimit}
        pageable={{
          buttonCount: 5,
          pageSizes: [10, 50, 100, 300, 500],
          pageSizeValue: pageLimit,
          type: "input",
        }}
        onSelectionChange={onSelectionChange}
        onHeaderSelectionChange={onHeaderSelectionChange}
        onPageChange={pageChange}
        cells={{
          headerCell: HeaderCustomCell,
          data: MyDataCustomCell,
        }}
      >
        <Column
          filterable={false}
          field={SELECTED_FIELD}
          width={50}
          headerSelectionValue={
            dataState?.findIndex(
              (item: any) => !selectedState[idGetter(item)]
            ) === -1
          }
        />
        <Column
          field="sr_name"
          title="Sales Representative Name"
          filterable={false}
          width={250}
          filterCell={CommonFilterCell}
          cell={(props: any) => (
            <td>
              {/* <div
                className={isEditAccess ? "hoverableName" : "normalName"}
                onClick={() =>
                  isEditAccess &&
                  navigate("/purchase/edit", {
                    state: {
                      purchase_guid: props.dataItem?.purchase_guid,
                    },
                  })
                }
              > */}
              {props.dataItem?.sr_name?.toUpperCase()}
              {/* </div> */}
            </td>
          )}
        />
        <Column field="date" title="Route Plan Date" width={150} />
        <Column
          field="distributor_name"
          title="Distributor Name"
          width={250}
          cell={(props: any) => (
            <td>{props.dataItem?.distributor_name?.toUpperCase()}</td>
          )}
        />
        <Column
          field="retailer_name"
          title="Retailer"
          width={250}
          // cell={(props: any) => (
          //   <td>
          //     <div
          //       className={isEditAccess ? "hoverableName" : "normalName"}
          //       onClick={() =>
          //         isEditAccess &&
          //         navigate("/purchase/edit", {
          //           state: {
          //             purchase_guid: props.dataItem?.purchase_guid,
          //           },
          //         })
          //       }
          //     >
          //       {props.dataItem?.invoice_no}
          //     </div>
          //   </td>
          // )}
        />
        <Column field="area_name" title="Area" width={200} />
        {/* {(isEditAccess || isDeleteAccess) && (
          <Column
            field="id"
            title="Actions"
            cell={MyEditCommandCell}
            width={"110px"}
            locked={true}
            filterable={false}
          />
        )} */}
      </KendoGrid>
    </>
  );
};

const DeleteSalesRepresentativeDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const SalesRepresentativeID = useAppSelector(
    (state) => state.salesrepresentative.SalesRepresentativeID
  );
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);
  const FilterCustomSalesRepresentative = useAppSelector(
    (state) => state.salesrepresentative.FilterCustomSalesRepresentative
  );

  const handleDeleteAction = async (ID: any) => {
    if (ID) {
      const response = await dispatch(deleteSalesRepresentative(ID));
      dispatch(setCurrentPage(0));
      const payload = {
        ...FilterCustomSalesRepresentative,
        limit: +pageLimit,
        skip: +currentPage * +pageLimit,
      };
      if (response?.payload?.Data?.Status === 200) {
        dispatch(getAllSalesRepresentativeAssignArea(payload));
        dispatch(closeDialog());
        dispatch(clearSalesRepresentativeID());
      } else {
        dispatch(getAllSalesRepresentativeAssignArea(payload));
        dispatch(closeDialog());
        dispatch(clearSalesRepresentativeID());
      }
    }
  };

  return (
    <>
      {dialogName === "deletesalesRepresentative" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Sales Representative"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this Sales Representative?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(SalesRepresentativeID)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

const CommonFilterCell = (props: any) => {
  const dispatch = useAppDispatch();
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);
  const FilterCustomSalesRepresentative: any = useAppSelector(
    (state) => state.salesrepresentative.FilterCustomSalesRepresentative
  );
  const isFilterRef = useRef(true);
  const [inputValue, setInputValue] = useState(props.value || "");
  const [inputField, setInputField] = useState(props.field || "");

  const onChange = (event: any) => {
    setInputValue(event.target.value);
    setInputField(event.target.name);
  };

  const delayedApiCall = React.useCallback(
    _debounce((updatedFilters: any) => {
      dispatch(setCurrentPage(0));
      const payload = {
        ...updatedFilters,
        limit: +pageLimit,
        skip: 0 * +pageLimit,
      };

      dispatch(getAllSalesRepresentativeAssignArea(payload));
    }, 300),
    [dispatch]
  );

  useEffect(() => {
    if (!isFilterRef.current) {
      const updatedFilters = {
        ...FilterCustomSalesRepresentative,
        [inputField]: inputValue,
      };
      dispatch(setFilterCustomSalesRepresentative(updatedFilters));
      delayedApiCall(updatedFilters);
    } else {
      isFilterRef.current = false;
    }
    return () => {
      delayedApiCall.cancel();
    };
  }, [inputValue, delayedApiCall]);

  const onClearButtonClick = (event: any) => {
    event.preventDefault();
    props.onChange({
      value: null,
      operator: "",
      syntheticEvent: event,
    });

    const updatedFilters = {
      ...FilterCustomSalesRepresentative,
      [props.field]: "",
    };
    dispatch(setFilterCustomSalesRepresentative(updatedFilters));

    const payload = {
      ...updatedFilters,

      limit: +pageLimit,
      skip: +currentPage * +pageLimit,
    };

    dispatch(getAllSalesRepresentativeAssignArea(payload));
  };

  return (
    <div className="d-flex">
      <TextBox
        value={FilterCustomSalesRepresentative[props.field] || ""}
        name={props.field || ""}
        onChange={onChange}
      />
      <Button
        svgIcon={filterClearIcon}
        title="Clear"
        disabled={!FilterCustomSalesRepresentative[props.field]}
        onClick={onClearButtonClick}
        type="button"
        style={{
          marginLeft: "8px",
        }}
      ></Button>
    </div>
  );
};

export default SalesRepresentative;
