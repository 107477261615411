import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import moment from "moment";

export const getAllSales = createAsyncThunk(
  "Sale/FindAllSale",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Sale/FindAllSale`,
        formData
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Sales :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createSales = createAsyncThunk(
  "Sale/InsertSale",
  async (SalesData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Sale/InsertSale`,
        SalesData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating sales :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateSales = createAsyncThunk(
  "Sale/UpdateSale",
  async (SalesData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Sale/UpdateSale`,
        SalesData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating Sales :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteSales = createAsyncThunk(
  "Sale/DeleteSale",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Sale/DeleteSale`,
        { sale_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting Sales :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getSalesByID = createAsyncThunk(
  "Sale/FindByIDSale",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Sale/FindByIDSale`,
        formData
      );
      const result = response.data?.Data as any;
      return {
        id: result?.id,
        sale_guid: result?.sale_guid,
        driver_id: result?.driver_id,
        sales_challan_id: result?.sales_challan_id,
        sales_challan_id_label: result?.sales_challan_id_label,
        financial_year: result?.financial_year,
        invoice_no: result?.invoice_no,
        voucher_type_id: result?.voucher_type_id,
        invoice_no_string: result?.invoice_no_string,
        cartoon: result?.cartoon,
        scheme: result?.scheme
          ? result?.scheme?.split(",")?.map((scheme: any) => +scheme)
          : [],
        card_scheme: result?.card_scheme,
        free_scheme: result?.free_scheme,
        bag: result?.bag,
        frieght: result?.frieght,
        vehicle_no: result?.vehicle_no,
        transport_name: result?.transport_name,
        remarks: result?.remarks,
        // delivery_slip_no: result?.delivery_slip_no,
        invoice_date: result?.invoice_date
          ? moment(result?.invoice_date, "YYYY-MM-DD").toDate()
          : "",
        vendor_id: result?.vendor_id,
        account_name: result?.account_name,
        terms_day: result?.terms_day,
        terms_and_condition_id: result?.terms_and_condition_id,
        due_date: result?.due_date
          ? moment(result?.due_date, "YYYY-MM-DD").toDate()
          : "",
        // sales_ledger_id: result?.sales_ledger_id,
        total_amount: result?.total_amount,
        total_gst: result?.total_gst,
        gst: result?.gst,
        cgst: result?.cgst,
        sgst: result?.sgst,
        igst: result?.igst,
        sales_items: result?.sales_items
          ? result?.sales_items?.map((salesitem: any) => {
              return {
                id: salesitem?.id ? +salesitem?.id : null,
                sales_id: salesitem?.sales_id ? +salesitem?.sales_id : null,
                item_id: salesitem?.item_id ? +salesitem?.item_id : null,
                item_name: salesitem?.item_name ? salesitem?.item_name : "",
                item_description: salesitem?.item_description
                  ? salesitem?.item_description
                  : "",
                mrp: salesitem?.mrp ? +salesitem?.mrp : null,
                quantity: salesitem?.quantity ? +salesitem?.quantity : null,
                free_quantity: salesitem?.free_quantity
                  ? +salesitem?.free_quantity
                  : null,
                lot_no: salesitem?.lot_no ? salesitem?.lot_no : "",
                unit_id: salesitem?.unit_id ? +salesitem?.unit_id : null,
                unit_decimal: salesitem?.unit_decimal
                  ? +salesitem?.unit_decimal
                  : 0,
                unit_name: salesitem?.unit_name ? salesitem?.unit_name : "",
                rate: salesitem?.rate ? +salesitem?.rate : null,
                gst_id: salesitem?.gst_id
                  ? `${salesitem?.gst_id}--${salesitem?.gst || "0"}`
                  : null,
                amount: salesitem?.amount ? +salesitem?.amount : null,
                vatav: salesitem?.vatav ? +salesitem?.vatav : null,
                vatav_per: salesitem?.vatav_per ? +salesitem?.vatav_per : null,
                item_options: salesitem?.item_options
                  ? salesitem?.item_options
                  : [],
              };
            })
          : [],
        sales_duty_and_taxes: result?.sales_duty_and_taxes
          ? result.sales_duty_and_taxes?.map((tax: any) => {
              return {
                ledger_id: tax?.ledger_id,
                ledger_name: tax?.ledger_name,
                gst_per: tax?.gst_per,
                gst_amount: tax?.gst_amount,
                ledger_options: tax?.ledger_options ? tax?.ledger_options : [],
              };
            })
          : [],
        vendor_options: result?.vendor_options ? result?.vendor_options : [],
        sales_ledger_options: result?.sales_ledger_options
          ? result?.sales_ledger_options
          : [],
      } as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching Sales details:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const generateInvoiceNoForSales = createAsyncThunk(
  "Sale/InvoiceNumberSale",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Sale/InvoiceNumberSale`,
        formData
      );
      return response.data?.Data;
    } catch (error: any) {
      console.error("Error generating invoice :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getSalesPrint = createAsyncThunk(
  "Sale/SalesRegisterPrint",
  async (sale_guid: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Sale/SalesRegisterPrint`,
        { sale_guid: sale_guid }
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Sales Print:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const assignDriverToSales = createAsyncThunk(
  "DeliveryDetails/DriverAssigned",
  async (SalesData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/DeliveryDetails/DriverAssigned`,
        SalesData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error assigning driver :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllSalesLedgeAccount = createAsyncThunk(
  "Account/SalesAccountLedger",
  async (search: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Account/SalesAccountLedger`,
        { account_name: search }
      );
      return response.data?.Data;
    } catch (error: any) {
      console.error("Error Fetching Sales Ledger :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
