import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";

export const getAllAreaAssignToDistributor= createAsyncThunk(
  "Area/FindAllDistributorAssignArea",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Area/FindAllDistributorAssignArea`,
        { distributor_id: ID }
      );
      return response.data?.Data;
    } catch (error: any) {
      console.error("Error Fetching Distributor Assign Area:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createAssingArea = createAsyncThunk(
  "Area/InsertAssignArea",
  async (formdata: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Area/InsertAssignArea`,
        formdata
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Assign Area:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteAssignArea = createAsyncThunk(
  "Area/DeleteAssignArea",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Area/DeleteAssignArea`,
        { id: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Deleting Assing Area:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const findAllAreaAssignDistributor = createAsyncThunk(
  "Area/FindAllDistributor",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Area/FindAllDistributor`
      );
      return response.data.Data;
    } catch (error: any) {
      console.error("Error get FindAllDistributor:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
