import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { ILoanEntryDetail } from "../loanentryModel";
import moment from "moment";

export const getAllLoanEntrys = createAsyncThunk(
  "LoanEntry/FindAllLoanEntry",
  async (formdata: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/LoanEntry/FindAllLoanEntry`,
        formdata
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Loan Entry:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createLoanEntry = createAsyncThunk(
  "LoanEntry/InsertLoanEntry",
  async (LoanEntryData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/LoanEntry/InsertLoanEntry`,
        LoanEntryData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating loanentry:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateLoanEntry = createAsyncThunk(
  "LoanEntry/UpdateLoanEntry",
  async (LoanEntryData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/LoanEntry/UpdateLoanEntry`,
        LoanEntryData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating loanentry:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteLoanEntry = createAsyncThunk(
  "LoanEntry/DeleteLoanEntry",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/LoanEntry/DeleteLoanEntry`,
        { loan_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting loanentry:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getLoanEntryByID = createAsyncThunk(
  "LoanEntry/FindByIDLoanEntry",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/LoanEntry/FindByIDLoanEntry`,
        formData
      );
      const result = response.data?.Data as any;
      return {
        id: result?.id,
        loan_guid: result?.loan_guid,
        itemcount: result?.itemcount,
        totalpages: result?.totalpages,
        Loan_date: result?.Loan_date
          ? moment(result?.Loan_date, "YYYY-MM-DD").toDate()
          : "",
        employee_id: result?.employee_id,
        employee_code: result?.employee_code,
        employee_name: result?.employee_name,
        loan_amout: result?.loan_amout,
        loan_installment: result?.loan_installment,
        installment_start_month: result?.installment_start_month,
        installment_end_month: result?.installment_end_month,
        total_installment: result?.total_installment,
        cash_receive: result?.cash_receive,
        loan_paid_monthly_status: result?.loan_paid_monthly_status,
      } as ILoanEntryDetail;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching loanentry:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveLoanEntrys = createAsyncThunk(
  "LoanEntry/FindAllActiveLoanEntry",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/LoanEntry/FindAllActiveLoanEntry`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching LoanEntrys:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
