import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import moment from "moment";

export const getAllPurchase = createAsyncThunk(
  "Purchase/FindAllPurchase",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Purchase/FindAllPurchase`,
        formData
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Purchase :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createPurchase = createAsyncThunk(
  "Purchase/InsertPurchase",
  async (PurchaseData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Purchase/InsertPurchase`,
        PurchaseData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating purchase :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updatePurchase = createAsyncThunk(
  "Purchase/UpdatePurchase",
  async (PurchaseData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Purchase/UpdatePurchase`,
        PurchaseData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating Purchase :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deletePurchase = createAsyncThunk(
  "Purchase/DeletePurchase",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Purchase/DeletePurchase`,
        { purchase_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting Purchase :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getPurchaseByID = createAsyncThunk(
  "Purchase/FindByIDPurchase",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Purchase/FindByIDPurchase`,
        formData
      );
      const result = response.data?.Data as any;
      return {
        id: result?.id,
        purchase_no: result?.purchase_no,
        purchase_no_string: result?.purchase_no_string,
        purchase_guid: result?.purchase_guid,
        purchase_order_id: result?.purchase_order_id,
        po_no: result?.po_no,
        financial_year: result?.financial_year,
        mobile_no: result?.mobile_no,
        email_id: result?.email_id,
        // pan_no: result?.pan_no,
        // gst_no: result?.gst_no,
        place_of_supply_id: result?.place_of_supply_id,
        // purchase_ledger_id: result?.purchase_ledger_id,
        terms_days: result?.terms_days,
        packing: result?.packing,
        labour: result?.labour,
        dalali: result?.dalali,
        inward_no: result?.inward_no,
        imward_no_label: result?.imward_no_label,
        invoice_no: result?.invoice_no,
        invoice_date: result?.invoice_date
          ? moment(result?.invoice_date, "YYYY-MM-DD").toDate()
          : "",
        vendor_id: result?.vendor_id,
        account_name: result?.account_name,
        due_date: result?.due_date
          ? moment(result?.due_date, "YYYY-MM-DD").toDate()
          : "",
        total_amount: result?.total_amount,
        sub_total: result?.sub_total,
        round_off: result?.round_off,
        total_gst: result?.total_gst,
        gst: result?.gst,
        cgst: result?.cgst,
        sgst: result?.sgst,
        igst: result?.igst,
        purchase_items: result?.purchase_items
          ? result?.purchase_items?.map((purchaseitem: any) => {
              return {
                id: purchaseitem?.id ? +purchaseitem?.id : null,
                purchase_id: purchaseitem?.purchase_id
                  ? +purchaseitem?.purchase_id
                  : null,
                item_id: purchaseitem?.item_id ? +purchaseitem?.item_id : null,
                item_name: purchaseitem?.item_name
                  ? purchaseitem?.item_name
                  : "",
                mrp: purchaseitem?.mrp ? +purchaseitem?.mrp : null,
                quantity: purchaseitem?.quantity
                  ? +purchaseitem?.quantity
                  : null,
                free_quantity: purchaseitem?.free_quantity
                  ? +purchaseitem?.free_quantity
                  : null,
                unit_id: purchaseitem?.unit_id ? +purchaseitem?.unit_id : null,
                unit_decimal: purchaseitem?.unit_decimal
                  ? +purchaseitem?.unit_decimal
                  : 0,
                unit_name: purchaseitem?.unit_name
                  ? purchaseitem?.unit_name
                  : "",
                rate: purchaseitem?.rate ? +purchaseitem?.rate : null,
                gst_id: purchaseitem?.gst_id
                  ? `${purchaseitem?.gst_id}--${purchaseitem?.gst || "0"}`
                  : null,

                sgst_per: purchaseitem?.sgst_per
                  ? +purchaseitem?.sgst_per
                  : null,
                sgst_amount: purchaseitem?.sgst_amount
                  ? +purchaseitem?.sgst_amount
                  : null,
                cgst_per: purchaseitem?.cgst_per
                  ? +purchaseitem?.cgst_per
                  : null,
                cgst_amount: purchaseitem?.cgst_amount
                  ? +purchaseitem?.cgst_amount
                  : null,
                igst_per: purchaseitem?.igst_per
                  ? +purchaseitem?.igst_per
                  : null,
                igst_amount: purchaseitem?.igst_amount
                  ? +purchaseitem?.igst_amount
                  : null,
                total_gst_amount: purchaseitem?.total_gst_amount
                  ? +purchaseitem?.total_gst_amount
                  : null,

                amount: purchaseitem?.amount ? +purchaseitem?.amount : null,
                discount: purchaseitem?.discount
                  ? purchaseitem?.discount
                  : null,
                discount_type: purchaseitem?.discount_type === 1 ? "₹" : "%",
                item_options: purchaseitem?.item_options
                  ? purchaseitem?.item_options
                  : [],
              };
            })
          : [],
        purchase_duty_and_taxes: result?.purchase_duty_and_taxes
          ? result.purchase_duty_and_taxes?.map((tax: any) => {
              return {
                ledger_id: tax?.ledger_id,
                ledger_name: tax?.ledger_name,
                gst_per: tax?.gst_per,
                gst_amount: tax?.gst_amount,
                ledger_options: tax?.ledger_options ? tax?.ledger_options : [],
              };
            })
          : [],
        vendor_options: result?.vendor_options ? result?.vendor_options : [],
        purchase_ledger: result?.purchase_ledger ? result?.purchase_ledger : [],
      } as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching Purchase details:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const generatePurchaseNoForPurchase = createAsyncThunk(
  "Purchase/InvoiceNumberpurchase",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Purchase/InvoiceNumberpurchase`
      );
      return response.data?.Data;
    } catch (error: any) {
      console.error("Error generating purchase no. :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllPurchaseLedgeAccount = createAsyncThunk(
  "Account/Purchaseledger",
  async (search: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Account/Purchaseledger`,
        { account_name: search }
      );
      return response.data?.Data;
    } catch (error: any) {
      console.error("Error Fetching Purchase Ledger :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllDutyAndTaxLedgeAccount = createAsyncThunk(
  "Account/DutyandTaxes",
  async (search: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Account/DutyandTaxes`,
        { account_name: search }
      );
      return response.data?.Data;
    } catch (error: any) {
      console.error("Error Fetching Duty and Tax Ledger :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
