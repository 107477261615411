import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import {
  createDebitNote,
  deleteDebitNote,
  getAllDebitNote,
  getDebitNoteByID,
  updateDebitNote,
} from "./services/debitNote.services";
import { IDebitNote, IDebitNoteInitialState } from "./debitNoteModel";

const initialState: IDebitNoteInitialState = {
  loading: false,
  error: "",
  DebitNoteStateForGST: null,
  DebitNoteDeleteIndex: -1,
  DebitNoteTaxItemDeleteIndex: -1,
  DebitNoteID: null,
  DebitNoteList: [],
  DebitNoteDetail: {
    debit_note_date: moment().toDate(),
    bill_no: "",
    bill_date: "",
    vendor_id: null,
    total_amount: null,
    total_gst: null,
    gst: null,
    cgst: null,
    sgst: null,
    igst: null,
    remarks: "",
    financial_year: null,
    purchase_ledger_id: null,
    sub_total: null,
    round_off: null,
    taxable_amount: null,
    debit_note_items: [],
    debit_note_duty_and_taxes: [],
  },
  FilterCustomDebitNote: {
    account_name: "",
  },
};

const debitNoteSlice = createSlice({
  name: "debitnote",
  initialState,
  reducers: {
    clearDebitNoteDetails: (state) => {
      state.DebitNoteDetail = initialState.DebitNoteDetail;
    },
    setDebitNoteID: (state, action) => {
      state.DebitNoteID = action.payload;
    },
    clearDebitNoteID: (state) => {
      state.DebitNoteID = initialState.DebitNoteID;
    },
    setDebitNoteDeleteIndex: (state, action) => {
      state.DebitNoteDeleteIndex = action.payload;
    },
    setDebitNoteStateForGST: (state, action) => {
      state.DebitNoteStateForGST = action.payload;
    },
    clearDebitNoteStateForGST: (state) => {
      state.DebitNoteStateForGST = initialState.DebitNoteStateForGST;
    },
    setFilterCustomDebitNote: (state, action) => {
      state.FilterCustomDebitNote = action.payload;
    },
    setDebitNoteTaxItemDeleteIndex: (state, action) => {
      state.DebitNoteTaxItemDeleteIndex = action.payload;
    },
    clearFilterCustomDebitNote: (state) => {
      state.FilterCustomDebitNote = initialState.FilterCustomDebitNote;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllDebitNote.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllDebitNote.fulfilled,
      (state, action: PayloadAction<IDebitNote[]>) => {
        state.loading = false;
        state.DebitNoteList = action.payload || [];
      }
    );
    builder.addCase(getAllDebitNote.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.DebitNoteList = [];
    });

    builder.addCase(createDebitNote.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(createDebitNote.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createDebitNote.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(updateDebitNote.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateDebitNote.fulfilled, (state) => {
      state.loading = true;
    });
    builder.addCase(updateDebitNote.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(deleteDebitNote.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteDebitNote.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteDebitNote.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getDebitNoteByID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getDebitNoteByID.fulfilled, (state, action: any) => {
      state.loading = false;
      state.DebitNoteDetail = action.payload;
    });
    builder.addCase(getDebitNoteByID.rejected, (state, action) => {
      state.loading = false;
      state.DebitNoteDetail = initialState.DebitNoteDetail;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearDebitNoteDetails,
  clearDebitNoteID,
  setDebitNoteID,
  setDebitNoteDeleteIndex,
  setDebitNoteStateForGST,
  clearDebitNoteStateForGST,
  setFilterCustomDebitNote,
  clearFilterCustomDebitNote,
  setDebitNoteTaxItemDeleteIndex,
} = debitNoteSlice.actions;
export default debitNoteSlice.reducer;
