import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import moment from "moment";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";

export const getAllStockTransfers = createAsyncThunk(
  "StockTransfer/MultiFindAllStockTransfer",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/StockTransfer/MultiFindAllStockTransfer`,
        formData
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Stock Transfers:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createStockTransfer = createAsyncThunk(
  "StockTransfer/InsertMultiStockTransfer",
  async (StockTransferData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/StockTransfer/InsertMultiStockTransfer`,
        StockTransferData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating stock transfer:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateStockTransfer = createAsyncThunk(
  "StockTransfer/UpdateMultiStockTransfer",
  async (StockTransferData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/StockTransfer/UpdateMultiStockTransfer`,
        StockTransferData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating Stock Transfer:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteStockTransfer = createAsyncThunk(
  "StockTransfer/DeleteStockTransfer",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/StockTransfer/DeleteStockTransfer`,
        { stock_transfer_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting Stock Transfer:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getStockTransferByID = createAsyncThunk(
  "StockTransfer/MultiFindbYIDStockTransfer",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/StockTransfer/MultiFindbYIDStockTransfer`,
        formData
      );
      const result = response.data?.Data as any;
      return {
        id: result.id,
        stock_transfer_guid: result?.stock_transfer_guid,
        stock_transfer_date: result.stock_transfer_date
          ? moment(result.stock_transfer_date, "YYYY-MM-DD").toDate()
          : "",
        stock_transfer_no: result?.stock_transfer_no,
        from_warehouse_id: result?.from_warehouse_id,
        from_warehouse: result?.from_warehouse,
        to_warehouse_id: result?.to_warehouse_id,
        to_warehouse: result?.to_warehouse,
        from_department_id: result?.from_department_id,
        to_department_id: result?.to_department_id,
        outward_no: result?.outward_no,
        financial_year: result?.financial_year,
        vehicle_no: result?.vehicle_no,
        remarks: result?.remarks,
        stock_item_options: result?.stock_item_options
          ? result?.stock_item_options?.map((stItem: any) => {
              return {
                id: stItem?.id,
                katta: stItem?.katta,
                vehicle_no: stItem?.vehicle_no,
                item_id: stItem?.item_id,
                item_options: stItem?.item_options || [],
                product_name: stItem?.product_name,
                grn_no: stItem?.grn_no,
                stock_qty: stItem?.stock_qty,
                vaccal_no: stItem?.vaccal_no,
                cold_storage_receipt_no: stItem?.cold_storage_receipt_no,
                inward_no: stItem?.inward_no,
                inward_date: stItem.inward_date
                  ? moment(stItem.inward_date, "YYYY-MM-DD").toDate()
                  : "",
              };
            })
          : [],
      } as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching Stock Transfer:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getCurrentStock = createAsyncThunk(
  "StockTransfer/FindAllCurrentStock",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/StockTransfer/FindAllCurrentStock`,
        formData
      );
      return response.data?.Data as any;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching Stock Transfer:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
