export const INVALID_EMAIL = "Invalid email";
export const INVALID_PHONE = "Invalid Phone Number";
export const INVALID_PINCODE = "Invalid Pincode";
export const REQUIRED_FIELD = "Required";
export const MIN_LENGTH_PWD =
  "Password is too short - should be 6 chars minimum.";
export const CONFIRM_PWD = "Please Confirm Your Password";
export const MIN_LENGTH_MOBILE = "Invalid Mobile Number";
export const SUCCESS = "Success";
export const INVALID = "Invalid";
