import React, { useEffect } from "react";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import FormDatePicker from "../../../components/formFields/FormDateField";
import moment from "moment";
import { Typography } from "@progress/kendo-react-common";
import ShadowCard from "../../../components/common/ShadowCard";
import { getAllItemIncremental } from "../../Item/services/item.services";
import { IItem } from "../../Item/itemModel";
import * as XLSX from "xlsx";
import {
  Grid,
  GridColumn,
  GridCustomCellProps,
  GridCustomHeaderCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
} from "@progress/kendo-react-grid";
import {
  CompositeFilterDescriptor,
  filterBy,
} from "@progress/kendo-data-query";
import {
  HeaderThElement,
  PagerTargetEvent,
} from "@progress/kendo-react-data-tools";
import AlertBox from "../../../components/common/AlertBox";
import { LoadingPanel } from "../../../components/layout/Loading";
import { requiredValidator } from "../../../components/formFields/CommonValidator";
import {
  clearOutwardPackingMaterialStocksDetails,
  clearOutwardPackingStockList,
  setOutwardPackingStockReport,
} from "../reportsSlice";
import { getOutwardPackingMaterialStockReport } from "../services/reports.services";
import { Button } from "@progress/kendo-react-buttons";
import { saveAs } from "file-saver";
import {
  checkAcessRights,
  formatIndianNumberForQty,
} from "../../../_helper/helper";
import { EXCEL_EXPORT_ACCESS, PRINT_ACCESS } from "../../../_contstants/common";
import FormIncrementalSearch from "../../../components/formFields/FormIncrementalSearch";
import { clearItemList } from "../../Item/itemSlice";
import Logo from "../../../assets/Images/logo.png";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}
const DatechangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const fromdate = moment()?.startOf("month")?.toDate() || "";
  const todate = moment()?.toDate() || "";
  useEffect(() => {
    formRenderProps.onChange("from_date", {
      value: fromdate,
    });
    formRenderProps.onChange("to_date", {
      value: todate,
    });
  }, []);

  return null;
};

const OutwardPackingMaterialStockForm: React.FC = () => {
  const gridRef = React.useRef<any>(null);
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.reports.loading);
  const itemLoading = useAppSelector((state) => state.item.loading);
  const ItemList = useAppSelector((state) => state.item.ItemList);
  const OutwardPackingMaterialStockList = useAppSelector(
    (state) => state.reports.OutwardPackingMaterialStockList
  );
  const OutwardPackingMaterialStocksDetails = useAppSelector(
    (state) => state.reports.OutwardPackingMaterialStocksDetails
  );

  useEffect(() => {
    return () => {
      dispatch(clearOutwardPackingStockList());
      dispatch(clearOutwardPackingMaterialStocksDetails());
      dispatch(clearItemList());
    };
  }, []);
  const handleSubmit = (values: any) => {
    const payload = {
      item_id: values?.item_id ? values?.item_id : 0,
      from_date: values?.from_date
        ? moment(values?.from_date).format("YYYY-MM-DD")
        : null,
      to_date: values?.to_date
        ? moment(values?.to_date).format("YYYY-MM-DD")
        : null,
    };
    dispatch(setOutwardPackingStockReport(payload));
    dispatch(getOutwardPackingMaterialStockReport(payload));
  };
  return (
    <GridLayout gap={{ rows: 10 }} cols={[{ width: "100%" }]}>
      <GridLayoutItem>
        <ShadowCard>
          <Form
            initialValues={OutwardPackingMaterialStocksDetails}
            onSubmit={handleSubmit}
            render={(formRenderProps: FormRenderProps) => (
              <FormElement style={{ width: "100%" }}>
                <GridLayout
                  style={{ marginRight: 10 }}
                  gap={{ rows: 10, cols: 10 }}
                  cols={[
                    { width: "25%" },
                    { width: "25%" },
                    { width: "25%" },
                    { width: "25%" },
                  ]}
                >
                  <GridLayoutItem colSpan={4}>
                    <Typography.h4
                      style={{ marginBottom: 0, marginLeft: "3px" }}
                    >
                      Outward Packing Material Stock Detail Report
                    </Typography.h4>
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperStyle={{ width: "100%" }}
                      name="from_date"
                      label="From Date"
                      format="dd/MM/yyyy"
                      component={FormDatePicker}
                      validator={requiredValidator}
                      astrike={true}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperStyle={{ width: "100%" }}
                      name="to_date"
                      label="To Date"
                      format="dd/MM/yyyy"
                      minDate={moment(
                        formRenderProps.valueGetter("from_date")
                      ).toDate()}
                      component={FormDatePicker}
                      validator={requiredValidator}
                      astrike={true}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperClassName="w-100"
                      name="item_id"
                      label="Product Name"
                      placeholder="Type here to search item..."
                      component={FormIncrementalSearch}
                      fetchIncrementalData={(search: any) =>
                        dispatch(getAllItemIncremental({ search }))
                      }
                      loading={itemLoading}
                      options={ItemList?.map((item: IItem) => {
                        return {
                          value: item?.id,
                          label: item?.product_name,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <div style={{ marginTop: 35 }}>
                      <ButtonWithLoading
                        label={"View"}
                        type="submit"
                        disabled={!formRenderProps.allowSubmit || loading}
                        loading={loading}
                      />
                    </div>
                  </GridLayoutItem>
                </GridLayout>
                <DatechangeWatcher formRenderProps={formRenderProps} />
              </FormElement>
            )}
          />
        </ShadowCard>
        {loading ? (
          <LoadingPanel gridRef={gridRef} />
        ) : (
          OutwardPackingMaterialStockList &&
          OutwardPackingMaterialStockList.length > 0 && (
            <ShadowCard style={{ marginTop: 10 }}>
              <OutwardPackingMaterialStockGridComponent />
            </ShadowCard>
          )
        )}
      </GridLayoutItem>
    </GridLayout>
  );
};

interface PageState {
  skip: number;
  take: number;
}

const initialDataState: PageState = { skip: 0, take: 50 };

const OutwardPackingMaterialStockGridComponent: React.FC = () => {
  const isExcelAccess = checkAcessRights(
    location.pathname,
    EXCEL_EXPORT_ACCESS
  );
  const isPrintAccess = checkAcessRights(location.pathname, PRINT_ACCESS);

  //   const loading = useAppSelector((state) => state.reports.loading);
  const OutwardPackingMaterialStockList = useAppSelector(
    (state) => state.reports.OutwardPackingMaterialStockList
  );
  const OutwardPackingMaterialStocksDetails = useAppSelector(
    (state) => state.reports.OutwardPackingMaterialStocksDetails
  );

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);
  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();

  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All"
        ? OutwardPackingMaterialStockList.length
        : event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
    setPage({ skip: 0, take: page.take });
  };

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className="table-header"
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  const handleExportExcel = () => {
    const datetime = moment().format("DD-MM-YYYY HH-mm");
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.table_to_sheet(
      document.querySelector(".table-bordered")
    );
    XLSX.utils.book_append_sheet(wb, ws, "OutwardPackingMaterialReport");

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(data, `Outward Packing Material Stock Report ${datetime}`);
  };

  const printTable = (data: any[]) => {
    let printbuttons = document.querySelector(
      ".printbuttons"
    ) as HTMLElement | null;
    let report_container = document.querySelector(
      ".report-container"
    ) as HTMLElement | null;

    if (printbuttons) printbuttons.style.display = "none";
    if (report_container) report_container.style.padding = "20px 0";

    let tableRows = data.length;
    let maxRows = tableRows;
    let pageCount = Math.ceil(tableRows / maxRows);
    let content = "";

    for (let i = 0; i < pageCount; i++) {
      content += '<div class="print-page">';
      content +=
        '<table class="table table-bordered" style="font-size:10px;margin-bottom:30px;">';
      content += "<tr>";
      content += `<td rowSpan="3" style="width:15%" ><img src="${Logo}" width="100%" /></td>`;
      content +=
        '<td colSpan="2" style="text-align: center;font-size: 14px;font-weight: 600;color: gray;">Harihar Foods Pvt Ltd.</td>';
      content += "</tr>";
      content += "<tr>";
      content +=
        '<td colSpan="2"><h3 style="margin:0;">Outward Packing Material Stock Report</h3></td>';
      content += "</tr>";
      content += "<tr>";
      content += `<td style="text-align:end;">Page : ${i + 1}</td>`;
      content += "</tr>";

      content += "</table>";

      content += '<table class="table table-bordered" style="font-size:10px;">';
      content += '<thead style="background:lightgray;">';
      content += "<tr>";
      content += '<th style="width:30px;">Sr. No.</th>';
      content += "<th>Outward Packing No.</th>";
      content += "<th>Outward Packing Date</th>";
      content += '<th style="width:200px;">Item Name</th>';
      content += "<th>Qty</th>";
      content += "<th>Unit</th>";
      content += "<th>Roll</th>";
      content += "<th>Outward Type</th>";
      content += "</tr>";
      content += "</thead>";
      content += "<tbody>";

      let startRow = i * maxRows;
      let endRow = Math.min((i + 1) * maxRows, tableRows);

      for (let j = startRow; j < endRow; j++) {
        content += "<tr>";
        content += `<td style="text-align:center;">${j + 1}</td>`;
        content += `<td style="text-align:center;">${data[j]?.outward_packing_no}</td>`;
        content += `<td>${
          data[j]?.outward_packing_material_date
            ? moment(
                data[j]?.outward_packing_material_date,
                "DD/MM/YYYY"
              ).format("DD MMM YYYY")
            : ""
        }</td>`;
        content += `<td>${data[j]?.product_name}</td>`;
        content += `<td style="text-align:end;">${formatIndianNumberForQty(
          +data[j]?.outward_item_qty || 0
        )}</td>`;
        content += `<td>${data[j]?.unit_name}</td>`;
        content += `<td style="text-align:center;">${data[j]?.roll}</td>`;
        content += `<td>${data[j]?.outward_type}</td>`;
        content += "</tr>";
      }

      content += "</tbody>";
      content += "</table>";

      content += "</div>";
    }

    let printWindow = window.open("", "_blank");
    if (!printWindow) {
      console.error("Failed to open print preview window.");
      return;
    }
    printWindow.document.write(`
        <html>
        <head>
          <title>Print Preview</title>
          <style>
            @page { margin: 1cm; }
            .print-page { page-break-after: always; max-width: 1140px; margin: auto; }
            .table-bordered { width: 100%; border-collapse: collapse; }
            .table-bordered th, .table-bordered td { border: 1px solid black; padding: 8px; }
            body { font-family: Arial, sans-serif; }
          </style>
        </head>
        <body>
          ${content}
          <script>
            window.onload = function() {
              window.print();
            };
          </script>
        </body>
        </html>
      `);
    printWindow.document.close();

    if (printbuttons) printbuttons.style.display = "block";
    if (report_container) report_container.style.padding = "20px 10px";
  };

  return (
    <>
      {OutwardPackingMaterialStockList &&
      OutwardPackingMaterialStockList.length ? (
        <GridLayout
          //   style={{ marginRight: 30 }}
          gap={{ rows: 10, cols: 10 }}
          cols={[{ width: "100%" }]}
        >
          <GridLayoutItem className="d-flex justify-content-between align-items-center">
            <Typography.h4 className="m-0">
              Outward Packing Material Stock List{" "}
              {(OutwardPackingMaterialStocksDetails?.from_date ||
                OutwardPackingMaterialStocksDetails?.to_date) && (
                <span style={{ fontSize: 13 }}>
                  {"("}
                  {OutwardPackingMaterialStocksDetails?.from_date
                    ? moment(
                        OutwardPackingMaterialStocksDetails?.from_date,
                        "YYYY-MM-DD"
                      ).format("DD/MM/YYYY")
                    : ""}
                  {OutwardPackingMaterialStocksDetails?.from_date &&
                    OutwardPackingMaterialStocksDetails?.to_date &&
                    " - "}
                  {OutwardPackingMaterialStocksDetails?.to_date
                    ? moment(
                        OutwardPackingMaterialStocksDetails?.to_date,
                        "YYYY-MM-DD"
                      ).format("DD/MM/YYYY")
                    : ""}
                  {")"}
                </span>
              )}
            </Typography.h4>
            <div>
              {isExcelAccess && (
                <Button
                  type="button"
                  fillMode={"solid"}
                  themeColor={"primary"}
                  onClick={handleExportExcel}
                >
                  Excel Export
                </Button>
              )}
              {isPrintAccess && (
                <Button
                  style={{ marginLeft: 5 }}
                  type="button"
                  fillMode={"solid"}
                  themeColor={"primary"}
                  onClick={() => printTable(OutwardPackingMaterialStockList)}
                >
                  Print
                </Button>
              )}
            </div>
          </GridLayoutItem>
          <GridLayoutItem>
            <Grid
              className="table-bordered responsive-table"
              style={{ maxHeight: "calc(100vh - 152px)" }}
              filterable={true}
              filter={filter}
              data={
                filter
                  ? filterBy(OutwardPackingMaterialStockList, filter).slice(
                      page.skip,
                      page.take + page.skip
                    )
                  : OutwardPackingMaterialStockList.slice(
                      page.skip,
                      page.take + page.skip
                    )
              }
              onFilterChange={handleFilterChange}
              skip={page.skip}
              take={page.take}
              total={OutwardPackingMaterialStockList.length}
              pageable={{
                buttonCount: 5,
                pageSizes: [10, 25, 50, 100, 500, "All"],
                pageSizeValue: pageSizeValue,
                type: "input",
              }}
              onPageChange={pageChange}
              cells={{
                headerCell: HeaderCustomCell,
                data: MyDataCustomCell,
              }}
            >
              <GridColumn
                field="UniqueNo"
                title="Sr. No."
                width={80}
                filterable={false}
              />
              <GridColumn
                field="outward_packing_no"
                title="Outward Packing No."
                width={150}
              />
              <GridColumn
                field="outward_packing_material_date"
                title="Outward Packing Date"
                width={200}
                cell={(props) => (
                  <td>
                    {props.dataItem?.outward_packing_material_date
                      ? moment(
                          props.dataItem?.outward_packing_material_date,
                          "DD/MM/YYYY"
                        ).format("DD MMM YYYY")
                      : ""}
                  </td>
                )}
              />
              <GridColumn field="product_name" title="Item Name" width={250} />
              <GridColumn
                field="outward_item_qty"
                title="Qty"
                width={150}
                cell={(props: any) => (
                  <td style={{ textAlign: "end" }}>
                    {props.dataItem?.outward_item_qty
                      ? formatIndianNumberForQty(
                          props.dataItem?.outward_item_qty
                        )
                      : null}
                  </td>
                )}
              />
              <GridColumn
                field="unit_name"
                title="Unit"
                width={100}
                filterable={false}
              />
              <GridColumn
                field="roll"
                title="Roll"
                width={100}
                filterable={false}
                cell={(props: any) => (
                  <td style={{ textAlign: "end" }}>{props.dataItem?.roll}</td>
                )}
              />
              <GridColumn
                field="outward_type"
                title="Outward Type"
                width={150}
              />
            </Grid>
          </GridLayoutItem>
        </GridLayout>
      ) : (
        <AlertBox />
      )}
    </>
  );
};
export default OutwardPackingMaterialStockForm;
