import React, { useEffect } from "react";
import { Field, FieldArrayRenderProps } from "@progress/kendo-react-form";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { Typography } from "@progress/kendo-react-common";

// import FormSelectionField from "../../components/formFields/FormSelectionField";

import FormNumericTextField from "../../components/formFields/FormNumericTextField";
import FormIncrementalSearch from "../../components/formFields/FormIncrementalSearch";
// import { axiosApiInstance } from "../../app/axios";
// import { API_URL } from "../../app/config";
// import { ErrorToast, SuccessToast } from "../../components/toast/Toasts";

import {
  // clearOutstandingPaymentList,
  setPaymentBillDialogOpenIndex,
  setPaymentEntryDeleteIndex,
} from "./paymentSlice";
import { getAllOutstandingPayment } from "./services/payment.services";
import { useLocation } from "react-router-dom";
import {
  requiredValidator,
  skipValidator,
} from "../../components/formFields/CommonValidator";
// import { getAllOutstandingPayment } from "./services/payment.services";
// import { getUnitById } from "../unit/services/unit.services";

const PaymentEntryDetailsArray = (
  fieldArrayRenderProps: FieldArrayRenderProps
) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  // const debit_note_guid = location.state?.debit_note_guid;
  const payment_guid = location.state?.payment_guid;
  const PaymentEntryDeleteIndex = useAppSelector(
    (state) => state.payment.PaymentEntryDeleteIndex
  );

  const InputRef = React.useRef<any>(null);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);

  // const handleOpenDeleteDialog = (index: number) => {
  //   dispatch(openDialog("debitNoteItemDeleteDialog"));
  //   dispatch(setPaymentDeleteIndex(index));
  // };

  const handleDeleteAction = React.useCallback(
    async (index: number) => {
      fieldArrayRenderProps.onRemove({ index: index });
      dispatch(closeDialog());
      dispatch(setPaymentEntryDeleteIndex(-1));
      const element = document.getElementsByName(
        `payment_entry.${index - 1}.amount`
      );
      if (element?.[0]) {
        element?.[0]?.focus();
      }
    },
    [fieldArrayRenderProps]
  );

  const pushElementInarrayFirst = React.useCallback(() => {
    fieldArrayRenderProps.onPush({
      value: {
        debit_credit: "Debit",
        ledger_id: "",
        amount: null,
        payment_entry_referance: [],
      },
    });

    // setTimeout(() => {
    //   InputRef.current?.element?.children[0]?.children[1]?.children[0]?.focus();
    // }, 0);
  }, [fieldArrayRenderProps]);

  const handleSearchLedger = (search: any, index: any) => {
    if (search) {
      fieldArrayRenderProps.handleVendorSearchChange(
        search,
        `payment_entry.${index}.ledger_options`,
        fieldArrayRenderProps.formRenderProps
      );
    }
  };

  // const handleCrediDebitChange = (
  //   index: number,
  //   fieldArrayRenderProps: any
  // ) => {
  //   fieldArrayRenderProps.formRenderProps.onChange(
  //     `payment_entry.${index}.payment_entry_referance`,
  //     {
  //       value: [],
  //     }
  //   );
  //   fieldArrayRenderProps.formRenderProps.onChange(
  //     `payment_entry.${index}.ledger_id`,
  //     {
  //       value: null,
  //     }
  //   );
  //   const debit_credit = fieldArrayRenderProps.formRenderProps.valueGetter(
  //     `payment_entry.${index}.debit_credit`
  //   );

  //   if (debit_credit === "Debit") {
  //     fieldArrayRenderProps.formRenderProps.onChange(
  //       `payment_entry.${index}.amount`,
  //       {
  //         value: null,
  //       }
  //     );
  //   }

  //   dispatch(setPaymentBillDialogOpenIndex(-1));
  // };
  const handleChange = (index: number, fieldArrayRenderProps: any) => {
    fieldArrayRenderProps.formRenderProps.onChange(
      `payment_entry.${index}.payment_entry_referance`,
      {
        value: [],
      }
    );
    const debit_credit = fieldArrayRenderProps.formRenderProps.valueGetter(
      `payment_entry.${index}.debit_credit`
    );

    if (debit_credit === "Debit") {
      fieldArrayRenderProps.formRenderProps.onChange(
        `payment_entry.${index}.amount`,
        {
          value: null,
        }
      );
    }

    dispatch(setPaymentBillDialogOpenIndex(-1));
  };
  const handleKeyEvent = (
    e: any,
    index: number,
    fieldArrayRenderProps: FieldArrayRenderProps
  ) => {
    const value = parseFloat(e?.target?.value?.replace(/,/g, ""));
    if (
      e.key === "Tab" &&
      value &&
      fieldArrayRenderProps.value[index].ledger_id
    ) {
      if (!e.shiftKey) {
        const payloadFindBill = {
          payment_guid: payment_guid ? payment_guid : null,
          credit_or_debit: "DB",
          ledger_id: fieldArrayRenderProps.value[index].ledger_id,
        };
        const fetchBillList = async () => {
          const IsPaymentRefID =
            fieldArrayRenderProps.value[index].payment_entry_referance?.[0]
              ?.payment_ref_id;
          if (
            fieldArrayRenderProps.value[index].payment_entry_referance.length >
              0 &&
            !IsPaymentRefID
          ) {
            dispatch(setPaymentBillDialogOpenIndex(index));
            dispatch(openDialog("BillItemDeleteDialog"));
          } else {
            const response = await dispatch(
              getAllOutstandingPayment(payloadFindBill)
            );
            if (response.meta.requestStatus === "fulfilled") {
              dispatch(openDialog("BillItemDeleteDialog"));
              dispatch(setPaymentBillDialogOpenIndex(index));
            }
          }
        };
        fetchBillList();
        if (fieldArrayRenderProps.value.length === index + 1) {
          pushElementInarrayFirst();
        }
      }
    }
    if (
      e.key === "Escape" &&
      fieldArrayRenderProps.value.length !== 1 &&
      index !== 0
    ) {
      dispatch(openDialog("PaymentItemDeleteDialog"));
      dispatch(setPaymentEntryDeleteIndex(index));
    }
  };

  useEffect(() => {
    if (fieldArrayRenderProps.value?.length === 0) {
      pushElementInarrayFirst();
    }
  }, []);

  // const PAYMENT_INITIAL = [
  //   {
  //     label: "Cr",
  //     value: "Credit",
  //   },

  //   {
  //     label: "Dr",
  //     value: "Debit",
  //   },
  // ];

  return (
    <>
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem className="bg-dark text-white fw-600">
          <GridLayout
            style={{
              padding: 10,
              marginRight: 10,
            }}
            gap={{ rows: 10, cols: 5 }}
            cols={[{ width: "60%" }, { width: "20%" }, { width: "20%" }]}
          >
            <GridLayoutItem>Ledger Name</GridLayoutItem>
            <GridLayoutItem></GridLayoutItem>
            <GridLayoutItem style={{ textAlign: "end", marginRight: "10px" }}>
              Amount
            </GridLayoutItem>
          </GridLayout>
        </GridLayoutItem>

        {dialogName === "PaymentItemDeleteDialog" && (
          <AppDialog>
            <>
              <Typography.h5>{"Delete Item"}</Typography.h5>
              <GridLayout>
                <GridLayoutItem>
                  <Typography.p>
                    Are you sure you want to delete this?
                  </Typography.p>
                </GridLayoutItem>
                <GridLayoutItem
                  style={{
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <Button
                    fillMode={"solid"}
                    themeColor={"error"}
                    onClick={() => handleDeleteAction(PaymentEntryDeleteIndex)}
                    type="button"
                    style={{ marginRight: 5 }}
                  >
                    Delete
                  </Button>
                  <DialogCloseButton themeColor="error" />
                </GridLayoutItem>
              </GridLayout>
            </>
          </AppDialog>
        )}
        {fieldArrayRenderProps.value &&
          fieldArrayRenderProps.value.length > 0 &&
          fieldArrayRenderProps.value.map(
            (payment_entry: any, index: number) => (
              <>
                <GridLayoutItem key={index}>
                  <GridLayout
                    style={{
                      marginRight: 10,
                      padding: 10,
                    }}
                    gap={{ rows: 10, cols: 5 }}
                    cols={[
                      { width: "60%" },
                      { width: "20%" },
                      { width: "20%" },
                    ]}
                  >
                    {/* <GridLayoutItem>
                    <Field
                      // wrapperClassName="right-alighned-field"
                      wrapperStyle={{ margin: 0 }}
                      name={`payment_entry.${index}.debit_credit`}
                      id={`payment_entry.${index}.debit_credit_id`}
                      format="n2"
                      component={FormSelectionField}
                      disabled={index === 0}
                      onChange={() =>
                        handleCrediDebitChange(index, fieldArrayRenderProps)
                      }
                      options={PAYMENT_INITIAL.map((init: any) => {
                        return {
                          value: init?.value,
                          label: init?.label,
                          };
                      })}
                    />
                    </GridLayoutItem> */}
                    <GridLayoutItem ref={InputRef}>
                      <Field
                        wrapperStyle={{ margin: 0 }}
                        name={`payment_entry.${index}.ledger_id`}
                        placeholder="search ledger"
                        id={`payment_entry.${index}.ledger_id_id`}
                        // isAddNew={true}
                        // addNewLink="account/create"
                        component={FormIncrementalSearch}
                        onChange={() =>
                          handleChange(index, fieldArrayRenderProps)
                        }
                        onBlur={() => console.log("blur event run")}
                        fetchIncrementalData={(search: any) =>
                          handleSearchLedger(search, index)
                        }
                        validator={
                          fieldArrayRenderProps.value[index].amount
                            ? requiredValidator
                            : skipValidator
                        }
                        options={
                          fieldArrayRenderProps.value[
                            index
                          ]?.ledger_options?.map((item: any) => {
                            return {
                              value: item?.id,
                              label: item?.account_name,
                            };
                          }) || []
                        }
                      />
                    </GridLayoutItem>
                    <GridLayoutItem></GridLayoutItem>
                    <GridLayoutItem>
                      {/* {fieldArrayRenderProps.value[index]?.debit_credit ===
                      "Debit" && ( */}
                      <Field
                        wrapperClassName="right-alighned-field"
                        wrapperStyle={{ margin: 0 }}
                        name={`payment_entry.${index}.amount`}
                        placeholder="0"
                        format="n2"
                        component={FormNumericTextField}
                        onKeyDown={(e: any) =>
                          handleKeyEvent(e, index, fieldArrayRenderProps)
                        }
                      />
                      {/* )} */}
                    </GridLayoutItem>
                    {/* <GridLayoutItem>
                    {fieldArrayRenderProps.value[index]?.debit_credit ===
                      "Credit" && (
                      <Field
                        wrapperClassName="right-alighned-field"
                        wrapperStyle={{ margin: 0 }}
                        name={`payment_entry.${index}.amount`}
                        placeholder="0"
                        format="n2"
                        component={FormNumericTextField}
                        onKeyDown={(e: any) =>
                          handleKeyEvent(e, index, fieldArrayRenderProps)
                        }
                      />
                    )}
                  </GridLayoutItem> */}
                  </GridLayout>
                </GridLayoutItem>
                {fieldArrayRenderProps.value?.[
                  index
                ]?.payment_entry_referance?.filter(
                  (pay_ref: any) => pay_ref?.referance_amount
                ).length > 0 && (
                  <GridLayoutItem>
                    <GridLayout
                      style={{
                        marginRight: 10,
                        padding: "0 30px",
                      }}
                      gap={{ rows: 5, cols: 5 }}
                    >
                      {fieldArrayRenderProps.value?.[
                        index
                      ]?.payment_entry_referance
                        ?.filter((pay_ref: any) => pay_ref?.referance_amount)
                        .map((item: any, ind: number) => (
                          <GridLayoutItem key={ind}>
                            <div>
                              <span
                                style={{
                                  fontWeight: "bold",
                                  width: "15px",
                                  display: "inline-block",
                                }}
                              >
                                {ind + 1}
                              </span>
                              <span
                                style={{
                                  fontWeight: "bold",
                                  marginLeft: "10px",
                                  background: "#e9e9e9",
                                  padding: "2px",
                                  width: "200px",
                                  display: "inline-block",
                                }}
                              >
                                {item?.bill_no_string ? item?.bill_no_string : item?.refernace_name }
                              </span>
                              <span
                                style={{
                                  marginLeft: "20px",
                                  fontWeight: "bold",
                                }}
                              >
                                {item?.referance_amount} Dr.
                              </span>
                            </div>
                          </GridLayoutItem>
                        ))}
                    </GridLayout>
                  </GridLayoutItem>
                )}

                <div
                  style={{
                    background: "goldenrod",
                    height: "1px",
                    opacity: "1",
                    margin: "10px 0",
                  }}
                />
              </>
            )
          )}

        <GridLayoutItem style={{ borderTop: "1px solid #ccc", marginTop: 10 }}>
          <GridLayout
            style={{
              marginRight: 10,
              padding: 10,
            }}
            gap={{ rows: 10, cols: 5 }}
            cols={[
              { width: "8%" },
              { width: "62%" },
              { width: "15%" },
              { width: "15%" },
            ]}
          >
            <GridLayoutItem colSpan={3}></GridLayoutItem>
            <GridLayoutItem>
              <h5
                style={{
                  textAlign: "end",
                  fontWeight: "bold",
                }}
              >
                ₹{" "}
                {(Array.isArray(fieldArrayRenderProps?.value) &&
                  fieldArrayRenderProps?.value.length > 0 &&
                  fieldArrayRenderProps.value?.[0]?.db_total
                    ?.payment_total_amount) ||
                  0}
              </h5>
            </GridLayoutItem>
          </GridLayout>
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

export default PaymentEntryDetailsArray;
