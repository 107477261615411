import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IItemGroup, IItemGroupInitialState } from "./itemgroupModel";
import {
  createItemGroup,
  createItemGroupMarginRate,
  deleteItemGroup,
  deleteItemGroupMarginRate,
  findAllActiveItemGroup,
  getAllItemGroup,
  getAllItemGroupIncremental,
  getItemGroupById,
  ItemGroupActiveInactive,
  updateItemGroup,
  updateItemGroupMarginRate,
} from "./services/itemgroup.services";

const initialState: IItemGroupInitialState = {
  loading: false,
  formLoading: false,
  error: "",
  ItemGroupMarginRateID: null,
  ItemGroupList: [],
  ItemGroupID: null,
  ItemGroupDetail: {
    item_group: "",
    isdisplaysalesorder:false,
    remarks: "",
    isactive: 1,
  },
};

export const itemgroupSlice = createSlice({
  name: "itemgroup",
  initialState,
  reducers: {
    setItemGroupDetails: (state, action) => {
      state.ItemGroupDetail = action.payload;
    },
    clearItemGroupDetails: (state) => {
      state.ItemGroupDetail = initialState.ItemGroupDetail;
    },
    setItemGroupID: (state, action) => {
      state.ItemGroupID = action.payload;
    },
    clearItemGroupID: (state) => {
      state.ItemGroupID = null;
    },
    setItemGroupMarginRateID: (state, action) => {
      state.ItemGroupMarginRateID = action.payload;
    },
    clearItemGroupMarginRateID: (state) => {
      state.ItemGroupMarginRateID = null;
    },
  },
  extraReducers: (builder) => {
    /* For getAll ItemCategory */

    builder.addCase(getAllItemGroup.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllItemGroup.fulfilled,
      (state, action: PayloadAction<IItemGroup[]>) => {
        state.loading = false;
        state.ItemGroupList = action.payload;
      }
    );
    builder.addCase(getAllItemGroup.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.ItemGroupList = [];
    });

    /* For FindAllActiveItemCategory */

    builder.addCase(findAllActiveItemGroup.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      findAllActiveItemGroup.fulfilled,
      (state, action: PayloadAction<IItemGroup[]>) => {
        state.loading = false;
        state.ItemGroupList = action.payload || [];
      }
    );
    builder.addCase(findAllActiveItemGroup.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.ItemGroupList = [];
    });

    /* For createItemCategory */

    builder.addCase(createItemGroup.pending, (state) => {
      state.formLoading = false;
      state.error = "";
    });
    builder.addCase(createItemGroup.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createItemGroup.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    /* For updateItemCategory */

    builder.addCase(updateItemGroup.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateItemGroup.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateItemGroup.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    /* For DeleteItemCategory */

    builder.addCase(deleteItemGroup.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteItemGroup.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteItemGroup.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    /* For ActiveInactiveItemCategory */

    builder.addCase(ItemGroupActiveInactive.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(ItemGroupActiveInactive.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(ItemGroupActiveInactive.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    /* For ItemCategoryById */

    builder.addCase(getItemGroupById.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getItemGroupById.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.ItemGroupDetail = action.payload;
    });
    builder.addCase(getItemGroupById.rejected, (state, action) => {
      state.formLoading = false;
      state.ItemGroupDetail = initialState.ItemGroupDetail;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(createItemGroupMarginRate.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(createItemGroupMarginRate.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createItemGroupMarginRate.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(updateItemGroupMarginRate.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateItemGroupMarginRate.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateItemGroupMarginRate.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(deleteItemGroupMarginRate.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(deleteItemGroupMarginRate.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(deleteItemGroupMarginRate.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getAllItemGroupIncremental.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllItemGroupIncremental.fulfilled,
      (state, action: PayloadAction<any[]>) => {
        state.loading = false;
        state.ItemGroupList = action.payload || [];
      }
    );
    builder.addCase(getAllItemGroupIncremental.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.ItemGroupList = [];
    });
  },
});

export const {
  setItemGroupDetails,
  clearItemGroupDetails,
  setItemGroupID,
  clearItemGroupID,
  setItemGroupMarginRateID,
  clearItemGroupMarginRateID,
} = itemgroupSlice.actions;
export default itemgroupSlice.reducer;
