import React, { useEffect, useRef } from "react";
import { Field, FieldArrayRenderProps } from "@progress/kendo-react-form";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closeDialog } from "../../components/dialog/dialogSlice";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import AppDialog from "../../components/dialog/Dialog";
import FormNumericTextField from "../../components/formFields/FormNumericTextField";
import { Typography } from "@progress/kendo-react-common";
import { setReceiptBillDialogOpenIndex } from "./receiptSlice";
import { LoadingPanel } from "../../components/layout/Loading";
import { useLocation } from "react-router-dom";
import { Button } from "@progress/kendo-react-buttons";

const ReceiptBillDetailsArray = (
  fieldArrayRenderProps: FieldArrayRenderProps
) => {
  const dispatch = useAppDispatch();
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const loading = useAppSelector((state) => state.receipt.loading);
  const gridRef = useRef<any>(null);
  const location = useLocation();
  const receipt_guid = location.state?.receipt_guid;
  const ReceiptBillDialogOpenIndex = useAppSelector(
    (state) => state.receipt.ReceiptBillDialogOpenIndex
  );
  const ReceiptDetail = useAppSelector((state) => state.receipt.ReceiptDetail);

  useEffect(() => {
    const element = document.getElementsByName(
      `receipt_bill_entry.0.referance_amount`
    );
    if (!loading) {
      if (element?.[0]) {
        setTimeout(() => {
          element?.[0]?.focus();
          if (receipt_guid) {
            element?.[0]?.blur();
          }
        }, 1);
      }
    }
  }, [loading]);

  const handleCloseDialog = () => {
    dispatch(closeDialog());
    dispatch(setReceiptBillDialogOpenIndex(-1));
    const element = document.getElementById(
      `receipt_entry.${ReceiptBillDialogOpenIndex + 1}.debit_credit_id`
    );
    if (element) {
      setTimeout(() => {
        element.focus();
      }, 1);
    } else {
      const element = document.getElementById(`remarks_for_focus`);
      if (element) {
        setTimeout(() => {
          element.focus();
        }, 1);
      }
    }
  };

  const handleKeyEvent = (
    e: any,
    index: number,
    fieldArrayRenderProps: FieldArrayRenderProps
  ) => {
    const isremainingAmount0 =
      Array.isArray(fieldArrayRenderProps?.value) &&
      fieldArrayRenderProps?.value.length > 0 &&
      fieldArrayRenderProps.value[0]?.IndexDetails?.remainingAmount;

    let pendingAmount = 0;
    if (receipt_guid) {
      pendingAmount =
        fieldArrayRenderProps.value[index].final_pending_amount +
        +ReceiptDetail?.receipt_entry?.[ReceiptBillDialogOpenIndex]
          ?.receipt_entry_referance?.[index]?.referance_amount;
    } else {
      pendingAmount = fieldArrayRenderProps.value[index].final_pending_amount;
    }

    const remainingAmount =
      Array.isArray(fieldArrayRenderProps?.value) &&
      fieldArrayRenderProps?.value.length > 0 &&
      fieldArrayRenderProps.value[0]?.IndexDetails?.remainingAmount +
        fieldArrayRenderProps.value[index].referance_amount;
    if (pendingAmount > remainingAmount) {
      fieldArrayRenderProps.formRenderProps.onChange(
        `receipt_bill_entry.${index}.MaxAmount`,
        {
          value: +remainingAmount,
        }
      );
    } else {
      fieldArrayRenderProps.formRenderProps.onChange(
        `receipt_bill_entry.${index}.MaxAmount`,
        {
          value: +pendingAmount,
        }
      );
    }

    if (e.key === "Tab" && isremainingAmount0 === 0) {
      if (!e.shiftKey) {
        dispatch(closeDialog());
        const element = document.getElementById(
          `receipt_entry.${ReceiptBillDialogOpenIndex + 1}.debit_credit_id`
        );
        if (element) {
          setTimeout(() => {
            element.focus();
          }, 1);
        } else {
          const element = document.getElementById(`remarks_for_focus`);
          if (element) {
            setTimeout(() => {
              element.focus();
            }, 1);
          }
        }
        dispatch(setReceiptBillDialogOpenIndex(-1));
      }
    }
  };

  return (
    <>
      {dialogName === "CreditBillItemDialog" && (
        <AppDialog>
          <GridLayout cols={[{ width: "900px" }]}>
            <GridLayoutItem>
              <Typography.p className="m-0 text-center">
                Bill-wise Details For :{" "}
                <b>
                  {Array.isArray(fieldArrayRenderProps?.value) &&
                    fieldArrayRenderProps?.value.length > 0 &&
                    fieldArrayRenderProps.value[0]?.IndexDetails?.ledger_name}
                </b>
              </Typography.p>
              <Typography.p className="m-0 text-center">
                Up to :{" "}
                <b>
                  ₹{" "}
                  {Array.isArray(fieldArrayRenderProps?.value) &&
                    fieldArrayRenderProps?.value.length > 0 &&
                    fieldArrayRenderProps.value[0]?.IndexDetails?.amount}{" "}
                  Cr.
                </b>
              </Typography.p>
              <Typography.p
                className=" text-center"
                style={{ position: "relative" }}
              >
                Remaining Amount: ₹{" "}
                <b>
                  {Array.isArray(fieldArrayRenderProps?.value) &&
                    fieldArrayRenderProps?.value.length > 0 &&
                    fieldArrayRenderProps.value[0]?.IndexDetails
                      ?.remainingAmount}{" "}
                  Cr.
                </b>
                {/* <button style={{visibility: "hidden", position: "absolute" }} id="use-me-for-focus">
                  Focus Button
                </button> */}
              </Typography.p>
            </GridLayoutItem>
            <GridLayoutItem className="bg-dark text-white fw-600">
              <GridLayout
                gap={{ rows: 10, cols: 0 }}
                cols={[
                  { width: "8%" },
                  { width: "15%" },
                  { width: "15%" },
                  { width: "15%" },
                  { width: "15%" },
                  { width: "17%" },
                  { width: "15%" },
                ]}
              >
                <GridLayoutItem
                  style={{ padding: "5px 10px", border: "0.5px solid grey" }}
                >
                  Bill No
                </GridLayoutItem>
                <GridLayoutItem
                  style={{ padding: "5px 10px", border: "0.5px solid grey" }}
                >
                  Bill Code
                </GridLayoutItem>
                <GridLayoutItem
                  style={{ padding: "5px 10px", border: "0.5px solid grey" }}
                >
                  Bill Date
                </GridLayoutItem>
                <GridLayoutItem
                  style={{ padding: "5px 10px", border: "0.5px solid grey" }}
                >
                  Due Date
                </GridLayoutItem>
                <GridLayoutItem
                  style={{ padding: "5px 10px", border: "0.5px solid grey" }}
                >
                  Bill Amount
                </GridLayoutItem>
                <GridLayoutItem
                  style={{ padding: "5px 10px", border: "0.5px solid grey" }}
                >
                  Pending Amount
                </GridLayoutItem>
                <GridLayoutItem
                  style={{ padding: "5px 10px", border: "0.5px solid grey" }}
                >
                  Amount
                </GridLayoutItem>
              </GridLayout>
            </GridLayoutItem>
            {loading ? (
              <LoadingPanel gridRef={gridRef} />
            ) : (
              <>
                {fieldArrayRenderProps.value &&
                  fieldArrayRenderProps.value.length > 0 &&
                  fieldArrayRenderProps.value.map(
                    (receipt_bill_entry: any, index: number) => (
                      <GridLayoutItem
                        key={index}
                        style={{
                          backgroundColor:
                            index % 2 === 1 ? "#f4f1f1" : "#f9fdf9",
                        }}
                      >
                        <GridLayout
                          style={{
                            padding: "5px 0",
                          }}
                          gap={{ rows: 10, cols: 0 }}
                          cols={[
                            { width: "8%" },
                            { width: "15%" },
                            { width: "15%" },
                            { width: "15%" },
                            { width: "15%" },
                            { width: "17%" },
                            { width: "15%" },
                          ]}
                        >
                          <GridLayoutItem
                            style={{
                              display: "flex",
                              alignItems: "center",
                              padding: "5px 10px",
                            }}
                          >
                            {receipt_bill_entry?.bill_no}
                          </GridLayoutItem>
                          <GridLayoutItem
                            style={{
                              display: "flex",
                              alignItems: "center",
                              padding: "5px 10px",
                            }}
                          >
                            {receipt_bill_entry?.bill_no_string}
                          </GridLayoutItem>
                          <GridLayoutItem
                            style={{
                              display: "flex",
                              alignItems: "center",
                              padding: "5px 10px",
                            }}
                          >
                            {receipt_bill_entry?.invoice_date}
                          </GridLayoutItem>
                          <GridLayoutItem
                            style={{
                              display: "flex",
                              alignItems: "center",
                              padding: "5px 10px",
                            }}
                          >
                            {receipt_bill_entry?.due_date}
                          </GridLayoutItem>
                          <GridLayoutItem
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "end",
                              padding: "5px 10px",
                            }}
                          >
                            {Number(receipt_bill_entry?.total_amount).toFixed(
                              2
                            ) || 0}
                          </GridLayoutItem>
                          <GridLayoutItem
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "end",
                              padding: "5px 15px",
                            }}
                          >
                            {Number(
                              receipt_bill_entry?.final_pending_amount
                            ).toFixed(2) || 0}
                          </GridLayoutItem>
                          <GridLayoutItem>
                            <Field
                              wrapperClassName="right-alighned-field"
                              wrapperStyle={{ margin: 0 , padding: "5px"}}
                              name={`receipt_bill_entry.${index}.referance_amount`}
                              placeholder="0"
                              format="n2"
                              max={
                                +fieldArrayRenderProps.formRenderProps.valueGetter(
                                  `receipt_bill_entry.${index}.MaxAmount`
                                )
                              }
                              component={FormNumericTextField}
                              onKeyDown={(e: any) =>
                                handleKeyEvent(e, index, fieldArrayRenderProps)
                              }
                            />
                          </GridLayoutItem>
                        </GridLayout>
                      </GridLayoutItem>
                    )
                  )}
              </>
            )}
            <GridLayoutItem>
              <GridLayout cols={[{ width: "900px" }]}>
                <GridLayoutItem
                  colSpan={4}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    marginTop: "10px",
                  }}
                >
                  <Button themeColor={"primary"} onClick={handleCloseDialog}>
                    Close
                  </Button>
                  {/* // <DialogCloseButton themeColor="error" /> */}
                </GridLayoutItem>
              </GridLayout>
            </GridLayoutItem>
          </GridLayout>
        </AppDialog>
      )}
    </>
  );
};

export default ReceiptBillDetailsArray;
