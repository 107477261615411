import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IVoucherType } from "../voucherTypeModel";

export const getAllVoucherTypes = createAsyncThunk(
  "VoucherType/FindAllVoucherType",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/VoucherType/FindAllVoucherType`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching voucher types:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createVoucherType = createAsyncThunk(
  "VoucherType/InsertVoucherType",
  async (VoucherTypeData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/VoucherType/InsertVoucherType`,
        VoucherTypeData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating voucher type:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateVoucherType = createAsyncThunk(
  "VoucherType/UpdateVoucherType",
  async (VoucherTypeData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/VoucherType/UpdateVoucherType`,
        VoucherTypeData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating voucher type:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getVoucherTypeByID = createAsyncThunk(
  "VoucherType/FindByIDVoucherType",
  async (ID: string) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/VoucherType/FindByIDVoucherType`,
        { voucher_type_guid: ID }
      );
      const result = response.data?.Data as any;
      return {
        id: result?.id,
        voucher_type_guid: result?.voucher_type_guid,
        type: result?.type,
        name: result?.name,
        prefix_no: result?.prefix_no,
        // invoice_no_sequence: result?.invoice_no_sequence,
      } as IVoucherType;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching voucher type:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteVoucherType = createAsyncThunk(
  "VoucherType/DeleteVoucherType",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/VoucherType/DeleteVoucherType`,
        { voucher_type_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting voucher type:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
