import React, { useCallback, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import { Typography } from "@progress/kendo-react-common";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  FieldArray,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import FormTextField from "../../components/formFields/FormTextField";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import ShadowCard from "../../components/common/ShadowCard";
import FormDatePicker from "../../components/formFields/FormDateField";
import {
  getAccountByID,
  // getAllAccountIncremental,
  getAllSPDCIncremental,
} from "../account/services/account.services";
import { getAllItemIncremental } from "../Item/services/item.services";
import { findAllActiveUnit } from "../unit/services/unit.services";
import FormIncrementalSearch from "../../components/formFields/FormIncrementalSearch";
import {
  createCreditNote,
  generateCreditNoteNoForCreditNote,
  getCreditNoteByID,
  updateCreditNote,
} from "./services/creditNote.services";
import {
  clearCreditNoteDetails,
  setCreditNoteStateForGST,
} from "./creditNoteSlice";
import { getAllActiveGST } from "../gst/services/gst.services";
import FormNumericTextField from "../../components/formFields/FormNumericTextField";
import { FINANCIAL_YEAR, GUJARATIDFORGST } from "../../_contstants/common";
import moment from "moment";
import {
  calculateAmount,
  calculateGST,
} from "../../_helper/helper";
import CreditNoteItemDetailsArray from "./CreditNoteItemDetailsArray";
import FormTextArea from "../../components/formFields/FormTextArea";
import { getAllSalesLedgeAccount } from "../sales/services/sales.services";
import { getAllDutyAndTaxLedgeAccount } from "../purchase/services/purchase.services";
import CreditNoteDutyAndTaxArray from "./CreditNoteDutyAndTaxArray";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}

const VendorChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const credit_note_guid = location.state?.credit_note_guid;
  const vendor_id = formRenderProps.valueGetter("vendor_id");
  const isVendorRef = useRef(true);

  useEffect(() => {
    if (vendor_id) {
      const handleVendorChange = async () => {
        const payload = {
          id: vendor_id,
        };
        const response = await dispatch(getAccountByID(payload));
        if (response.meta.requestStatus === "fulfilled") {
          dispatch(setCreditNoteStateForGST(response.payload?.state_id));
          formRenderProps.onChange("state_id", {
            value: response.payload?.state_id,
          });
        } else {
          formRenderProps.onChange("state_id", {
            value: null,
          });
        }
      };
      if (!isVendorRef.current) {
        handleVendorChange();
      } else {
        if (!credit_note_guid) {
          handleVendorChange();
        }
        isVendorRef.current = false;
      }
    } else {
      dispatch(setCreditNoteStateForGST(null));
      formRenderProps.onChange("state_id", {
        value: "",
      });
    }
  }, [vendor_id]);

  return null;
};
const QtyChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const credit_note_items =
    formRenderProps.valueGetter("credit_note_items") || 0;
  const credit_note_duty_and_taxes = formRenderProps.valueGetter(
    "credit_note_duty_and_taxes"
  );
  const taxable_amount = formRenderProps.valueGetter("taxable_amount") || 0;
  // const gst = formRenderProps.valueGetter("gst") || 0;
  // const packing = formRenderProps.valueGetter("packing") || 0;
  // const labour = formRenderProps.valueGetter("labour") || 0;
  // const dalali = formRenderProps.valueGetter("dalali") || 0;
  const sub_total = formRenderProps.valueGetter("sub_total") || 0;
  const round_off = formRenderProps.valueGetter("round_off") || 0;
  const CreditNoteStateForGST = useAppSelector(
    (state) => state.creditnote.CreditNoteStateForGST
  );

  const UnitList = useAppSelector((state) => state.unit.UnitList);

  useEffect(() => {
    let totalGSTamount = 0;
    let totalGST5 = 0;
    let totalGST12 = 0;
    let totalGST18 = 0;
    let totalGST28 = 0;

    credit_note_items?.map((item: any, index: number) => {
      const gst_id = item?.gst_id;
      const amount = item?.amount;
      const gstid = gst_id ? parseInt(gst_id?.split("--")[1]) : 0;
      if (gstid === 5) {
        totalGST5 += (+amount * +gstid) / 100;
      } else if (gstid === 12) {
        totalGST12 += (+amount * +gstid) / 100;
      } else if (gstid === 18) {
        totalGST18 += (+amount * +gstid) / 100;
      } else if (gstid === 28) {
        totalGST28 += (+amount * +gstid) / 100;
      }
      totalGSTamount += (+amount * +gstid) / 100;
      if (!gst_id) {
        formRenderProps.onChange(`credit_note_items.${index}.gst_id`, {
          value: null,
        });
      }
    });
    formRenderProps.onChange("gst", {
      value: totalGSTamount || 0,
    });

    if (CreditNoteStateForGST === GUJARATIDFORGST) {
      formRenderProps.onChange("cgst", {
        value: totalGSTamount / 2 || 0,
      });
      formRenderProps.onChange("sgst", {
        value: totalGSTamount / 2 || 0,
      });
    } else {
      formRenderProps.onChange("igst", {
        value: totalGSTamount || 0,
      });
    }
    formRenderProps.onChange("gst5", {
      value: totalGST5 || 0,
    });
    formRenderProps.onChange("gst12", {
      value: totalGST12 || 0,
    });
    formRenderProps.onChange("gst18", {
      value: totalGST18 || 0,
    });
    formRenderProps.onChange("gst28", {
      value: totalGST28 || 0,
    });

    // Account Implementation Starts
    const gstConfig = {
      2.5: (isGujarat: boolean) => (isGujarat ? totalGST5 / 2 : 0),
      5: (isGujarat: boolean) => (!isGujarat ? totalGST5 : 0),
      6: (isGujarat: boolean) => (isGujarat ? totalGST12 / 2 : 0),
      12: (isGujarat: boolean) => (!isGujarat ? totalGST12 : 0),
      9: (isGujarat: boolean) => (isGujarat ? totalGST18 / 2 : 0),
      18: (isGujarat: boolean) => (!isGujarat ? totalGST18 : 0),
      14: (isGujarat: boolean) => (isGujarat ? totalGST28 / 2 : 0),
      28: (isGujarat: boolean) => (!isGujarat ? totalGST28 : 0),
    };

    credit_note_duty_and_taxes?.forEach((item: any, index: number) => {
      const gst_per = item?.gst_per;
      // const ledger_name = item?.ledger_name;
      const ledger_data =
        credit_note_duty_and_taxes[index].ledger_options?.filter(
          (tax: any) => tax.id === item?.ledger_id
        ) || [];
      const ledger_name = ledger_data[0]?.tax_type || "";
      const typeOfSupply = ledger_data[0]?.type_of_supply || "";

      const isGujarat = CreditNoteStateForGST === GUJARATIDFORGST;
      // Handle invalid or unexpected gst_per values
      if (item?.ledger_id) {
        // if (ledger_name?.includes("GST")) {
        if (ledger_name?.toLowerCase()?.includes("gst")) {
          let gstAmount =
            gst_per && gstConfig[gst_per as keyof typeof gstConfig]
              ? gstConfig[gst_per as keyof typeof gstConfig](isGujarat)
              : 0; // Default value for invalid gst_per
          // formRenderProps.onChange(
          //   `credit_note_duty_and_taxes.${index}.gst_amount`,
          //   {
          //     value: gstAmount,
          //   }
          // );

          // Add additional GST if the supply type is "Service" and the ledger is labour
          if (typeOfSupply === "Service") {
            // const additionalGST =
            //   (item?.tax_percentage || 0) * (item?.gst_amount || 0);
            // gstAmount += additionalGST;
            if (item?.gst_per) {
              formRenderProps.onChange(
                `credit_note_duty_and_taxes.${index}.gst_amount`,
                {
                  value: (taxable_amount * item?.gst_per) / 100,
                }
              );
            }
          } else {
            formRenderProps.onChange(
              `credit_note_duty_and_taxes.${index}.gst_amount`,
              {
                value: gstAmount,
              }
            );
          }
        } else {
          if (gst_per) {
            formRenderProps.onChange(
              `credit_note_duty_and_taxes.${index}.gst_amount`,
              {
                value: (taxable_amount * gst_per) / 100,
              }
            );
          }
        }
      } else {
        formRenderProps.onChange(
          `credit_note_duty_and_taxes.${index}.gst_amount`,
          {
            value: 0,
          }
        );
        formRenderProps.onChange(
          `credit_note_duty_and_taxes.${index}.gst_per`,
          {
            value: 0,
          }
        );
      }
    });

    const grandTotals = credit_note_duty_and_taxes?.reduce(
      (acc: Record<string, number>, item: any, index: number) => {
        // const firstWord = item?.ledger_name?.split(" ")[0];
        const firstWord =
          credit_note_duty_and_taxes[index].ledger_options?.filter(
            (tax: any) => tax.id === item?.ledger_id
          )[0]?.tax_type || "";

        acc[firstWord] = (acc[firstWord] || 0) + item?.gst_amount;
        acc["duty_tax_total"] = (acc["duty_tax_total"] || 0) + item?.gst_amount;
        acc["sub_total"] =
          (acc["duty_tax_total"] || 0) + (+taxable_amount || 0);
        return acc;
      },
      {} as Record<string, number>
    );

    formRenderProps.onChange("sub_total", {
      value: grandTotals?.sub_total || 0,
    });

    if (CreditNoteStateForGST === GUJARATIDFORGST) {
      formRenderProps.onChange("cgst", {
        value: grandTotals?.CGST || 0,
      });
      formRenderProps.onChange("sgst", {
        value: grandTotals?.SGST || 0,
      });
      formRenderProps.onChange("gst", {
        value: +(+grandTotals?.CGST + +grandTotals?.SGST)?.toFixed(2) || 0,
      });
    } else {
      formRenderProps.onChange("igst", {
        value: grandTotals?.IGST || 0,
      });
      formRenderProps.onChange("gst", {
        value: +grandTotals?.IGST?.toFixed(2) || 0,
      });
    }
  }, [
    CreditNoteStateForGST,
    taxable_amount,
    credit_note_items?.map((item: any) => item?.gst_id).join("-"),
    credit_note_items?.map((item: any) => item?.amount).join("-"),
    credit_note_duty_and_taxes?.map((item: any) => item?.ledger_id).join("-"),
    credit_note_duty_and_taxes?.map((item: any) => item?.gst_per).join("-"),
    credit_note_duty_and_taxes?.map((item: any) => item?.gst_amount).join("-"),
  ]);

  useEffect(() => {
    if (credit_note_items && credit_note_items.length > 0) {
      credit_note_items.forEach((item: any, index: number) => {
        const quantity = parseFloat(
          item?.quantity?.toFixed(item?.unit_decimal || 0) || "0"
        );
        const rate = item?.rate || 0;
        const discount = item?.discount || 0;
        const discountType = item?.discount_type || "₹";

        const gstIdStr = item?.gst_id;
        const gstid = gstIdStr ? parseInt(gstIdStr.split("--")[1], 10) : 0;

        const amount = calculateAmount(quantity, rate, discount, discountType);
        const gstDetails = calculateGST(
          amount,
          gstid,
          CreditNoteStateForGST === GUJARATIDFORGST
        );

        // Update the form fields
        const updates: Record<string, any> = {
          [`credit_note_items.${index}.amount`]: { value: amount },
          [`credit_note_items.${index}.sgst_amount`]: {
            value: gstDetails.sgstAmount,
          },
          [`credit_note_items.${index}.cgst_amount`]: {
            value: gstDetails.cgstAmount,
          },
          [`credit_note_items.${index}.igst_amount`]: {
            value: gstDetails.igstAmount,
          },
          [`credit_note_items.${index}.total_gst_amount`]: {
            value:
              gstDetails.sgstAmount +
              gstDetails.cgstAmount +
              gstDetails.igstAmount,
          },
          [`credit_note_items.${index}.sgst_per`]: {
            value: gstDetails.sgstPer,
          },
          [`credit_note_items.${index}.cgst_per`]: {
            value: gstDetails.cgstPer,
          },
          [`credit_note_items.${index}.igst_per`]: {
            value: gstDetails.igstPer,
          },
        };

        // Apply all updates
        Object.entries(updates).forEach(([key, update]) => {
          formRenderProps.onChange(key, update);
        });
      });
    }
  }, [
    CreditNoteStateForGST,

    credit_note_items?.map((item: any) => item?.gst_id).join("-"),
    credit_note_items.map((item: any) => item?.quantity).join("-"),
    credit_note_items.map((item: any) => item?.rate).join("-"),
    credit_note_items.map((item: any) => item?.discount).join("-"),
    credit_note_items.map((item: any) => item?.discount_type).join("-"),
    credit_note_items.map((item: any) => item?.unit_decimal).join("-"),
  ]);

  let totalamount = 0;
  let totalQty = 0;

  useEffect(() => {
    if (credit_note_items && credit_note_items.length > 0) {
      credit_note_items?.map((item: any) => {
        totalQty += +item?.quantity || 0;
        totalamount += +item?.amount || 0;
      });
    }
    formRenderProps.onChange("totalQty", {
      value: totalQty,
    });
    formRenderProps.onChange("taxable_amount", {
      value: totalamount,
    });
  }, [
    credit_note_items.map((item: any) => item?.quantity).join("-"),
    credit_note_items.map((item: any) => item?.amount).join("-"),
  ]);

  useEffect(() => {
    if (credit_note_items && credit_note_items.length > 0) {
      credit_note_items?.map((item: any, index: number) => {
        const unit_id = item?.unit_id;

        const unit = UnitList.find((unit: any) => unit?.id === unit_id);
        if (unit) {
          formRenderProps.onChange(`credit_note_items.${index}.unit_decimal`, {
            value: unit?.unit_decimal || 0,
          });
        } else {
          formRenderProps.onChange(`credit_note_items.${index}.unit_decimal`, {
            value: 0,
          });
        }
      });
    }
  }, [credit_note_items.map((item: any) => item?.unit_id).join("-")]);

  useEffect(() => {
    formRenderProps.onChange("round_off", {
      value: Math.round(+sub_total) - +sub_total,
    });
    formRenderProps.onChange("grand_total", {
      value: +sub_total + +round_off || 0,
    });
  }, [sub_total, round_off]);

  return null;
};

const CreditNoteNoChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const credit_note_guid = location.state?.credit_note_guid;
  const isCreditNoteNoRef = useRef(false);

  useEffect(() => {
    const fetchCreditNoteNo = async () => {
      const response = await dispatch(generateCreditNoteNoForCreditNote());
      if (response.meta.requestStatus === "fulfilled") {
        formRenderProps.onChange("credit_note_no_string", {
          value: response.payload?.credit_note_no_string || "",
        });
      }
    };
    if (!isCreditNoteNoRef.current) {
      if (!credit_note_guid) {
        fetchCreditNoteNo();
      }
      isCreditNoteNoRef.current = true;
    } else {
      isCreditNoteNoRef.current = true;
    }
  }, []);

  return null;
};

const CreateCreditNote = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const credit_note_guid = location.state?.credit_note_guid;
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.creditnote.loading);
  const CreditNoteDetail = useAppSelector(
    (state) => state.creditnote.CreditNoteDetail
  );
  const CreditNoteStateForGST = useAppSelector(
    (state) => state.creditnote.CreditNoteStateForGST
  );

  const [formKey, setFormKey] = React.useState(1);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [CreditNoteDetail]);

  useEffect(() => {
    if (credit_note_guid) {
      const payload = {
        credit_note_guid: credit_note_guid,
      };
      dispatch(getCreditNoteByID(payload));
    }
  }, [credit_note_guid]);

  useEffect(() => {
    dispatch(findAllActiveUnit());
    dispatch(getAllActiveGST());
    return () => {
      dispatch(clearCreditNoteDetails());
    };
  }, []);

  useEffect(() => {
    const fetchAccountDetails = async () => {
      const payload = {
        id: CreditNoteDetail?.vendor_id,
      };
      const response = await dispatch(getAccountByID(payload));
      if (response.meta.requestStatus === "fulfilled") {
        dispatch(setCreditNoteStateForGST(response.payload?.state_id));
      } else {
        dispatch(setCreditNoteStateForGST(null));
      }
    };
    if (CreditNoteDetail?.vendor_id) {
      fetchAccountDetails();
    }
  }, [CreditNoteDetail]);

  const handleItemSearchChange = useCallback(
    async (search: string, field: string, formRenderProps: FormRenderProps) => {
      const result = await dispatch(getAllItemIncremental({ search }));
      formRenderProps.onChange(field, {
        value: result.payload,
      });
    },
    [dispatch]
  );

  const handleVendorSearchChange = useCallback(
    async (search: string, field: string, formRenderProps: FormRenderProps) => {
      const result = await dispatch(getAllSPDCIncremental(search));
      formRenderProps.onChange(field, {
        value: result.payload,
      });
    },
    [dispatch]
  );
  const handleLedgerSearchChange = useCallback(
    async (search: string, field: string, formRenderProps: FormRenderProps) => {
      const result = await dispatch(getAllDutyAndTaxLedgeAccount(search));
      formRenderProps.onChange(field, {
        value: result.payload,
      });
    },
    [dispatch]
  );

  const handleSalesLedgerSearchChange = useCallback(
    async (search: string, field: string, formRenderProps: FormRenderProps) => {
      const result = await dispatch(getAllSalesLedgeAccount(search));
      formRenderProps.onChange(field, {
        value: result.payload,
      });
    },
    [dispatch]
  );

  const handleSubmit = async (values: any) => {
    const formdata: any = {
      credit_note_date: values?.credit_note_date
        ? moment(values?.credit_note_date).format("YYYY-MM-DD")
        : "",
      bill_no: values?.bill_no,
      bill_date: values?.bill_date
        ? moment(values?.bill_date).format("YYYY-MM-DD")
        : "",
      vendor_id: values?.vendor_id ? +values?.vendor_id : null,
      total_amount: values?.grand_total ? +values?.grand_total : null,
      gst: values?.gst ? +values?.gst?.toFixed(2) : null,
      remarks: values?.remarks ? values?.remarks : "",
      financial_year: values?.financial_year
        ? +values?.financial_year
        : FINANCIAL_YEAR,

      vehicle_no: values?.vehicle_no,
      transport_name: values?.transport_name,

      sales_ledger_id: values?.sales_ledger_id
        ? +values?.sales_ledger_id
        : null,
      credit_note_items: values?.credit_note_items
        ? values?.credit_note_items?.map((creditNoteitem: any) => {
            return {
              id: creditNoteitem?.id ? +creditNoteitem?.id : 0,
              item_id: creditNoteitem?.item_id
                ? +creditNoteitem?.item_id
                : null,
              quantity: creditNoteitem?.quantity
                ? +creditNoteitem?.quantity?.toFixed(
                    creditNoteitem.unit_decimal
                      ? creditNoteitem.unit_decimal
                      : 0
                  )
                : 0,
              unit_id: creditNoteitem?.unit_id
                ? +creditNoteitem?.unit_id
                : null,
              rate: creditNoteitem?.rate ? +creditNoteitem?.rate : null,

              free_quantity: creditNoteitem?.free_quantity
                ? +creditNoteitem?.free_quantity?.toFixed(
                    creditNoteitem.unit_decimal
                      ? creditNoteitem.unit_decimal
                      : 0
                  )
                : 0,
              discount_type: creditNoteitem?.discount_type === "%" ? 2 : 1,
              discount: creditNoteitem?.discount
                ? +creditNoteitem?.discount
                : null,
              gst_id: creditNoteitem?.gst_id
                ? parseInt(creditNoteitem?.gst_id.split("--")[0])
                : null,

              sgst_per: creditNoteitem?.sgst_per
                ? +creditNoteitem?.sgst_per?.toFixed(2)
                : 0,
              sgst_amount: creditNoteitem?.sgst_amount
                ? +creditNoteitem?.sgst_amount?.toFixed(2)
                : 0,
              cgst_per: creditNoteitem?.cgst_per
                ? +creditNoteitem?.cgst_per?.toFixed(2)
                : 0,
              cgst_amount: creditNoteitem?.cgst_amount
                ? +creditNoteitem?.cgst_amount?.toFixed(2)
                : 0,
              igst_per: creditNoteitem?.igst_per
                ? +creditNoteitem?.igst_per?.toFixed(2)
                : 0,
              igst_amount: creditNoteitem?.igst_amount
                ? +creditNoteitem?.igst_amount?.toFixed(2)
                : 0,
              total_gst_amount: creditNoteitem?.total_gst_amount
                ? +creditNoteitem?.total_gst_amount?.toFixed(2)
                : 0,
              amount: creditNoteitem?.amount ? +creditNoteitem?.amount : null,
            };
          })
        : [],
      credit_note_duty_and_taxes: values?.credit_note_duty_and_taxes
        ? values.credit_note_duty_and_taxes
            .filter((creditnoteduty: any) => creditnoteduty?.ledger_id && creditnoteduty?.gst_amount)
            .map((creditnoteduty: any) => ({
              ledger_id: +creditnoteduty.ledger_id,
              gst_per: creditnoteduty?.gst_per ? +creditnoteduty.gst_per : null,
              gst_amount: creditnoteduty?.gst_amount
                ? +creditnoteduty.gst_amount
                : null,
            }))
        : [],
    };
    if (CreditNoteStateForGST === GUJARATIDFORGST) {
      formdata.cgst = values?.cgst ? +values?.cgst?.toFixed(2) : null;
      formdata.sgst = values?.sgst ? +values?.sgst?.toFixed(2) : null;
      formdata.igst = null;
    } else {
      formdata.igst = values?.igst ? +values?.igst?.toFixed(2) : null;
      formdata.cgst = null;
      formdata.sgst = null;
    }

    if (credit_note_guid) {
      try {
        const updatePayload: any = {
          credit_note_guid: credit_note_guid,
          id: values?.id ? +values?.id : null,
          ...formdata,
        };

        const response = await dispatch(updateCreditNote(updatePayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate(-1);
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    } else {
      try {
        const response = await dispatch(createCreditNote(formdata));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate("/creditnote");
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    }
  };

  return (
    <>
      {loading && <LoadingPanel gridRef={gridRef} />}
      <Form
        key={formKey}
        onSubmit={handleSubmit}
        initialValues={CreditNoteDetail}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12 }}>
              <GridLayout
                style={{ marginRight: 30 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                ]}
              >
                <GridLayoutItem colSpan={4}>
                  <Typography.h4>
                    {credit_note_guid
                      ? "Update Sales Return"
                      : "Add Sales Return"}
                  </Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="credit_note_no_string"
                    label="Sales Return No"
                    disabled={true}
                    placeholder="Sales Return No"
                    component={FormTextField}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="credit_note_date"
                    label="Sales Return Date"
                    format="dd/MM/yyyy"
                    component={FormDatePicker}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem colSpan={2}></GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="bill_no"
                    label="Bill No"
                    placeholder="Bill No"
                    component={FormTextField}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="bill_date"
                    label="Bill Date"
                    format="dd/MM/yyyy"
                    component={FormDatePicker}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="vendor_id"
                    label="Customer Name"
                    placeholder="Search Customer Name"
                    isAddNew={true}
                    addNewLink="account/create"
                    component={FormIncrementalSearch}
                    validator={requiredValidator}
                    astrike={true}
                    fetchIncrementalData={(search: any) =>
                      handleVendorSearchChange(
                        search,
                        `vendor_options`,
                        formRenderProps
                      )
                    }
                    options={
                      formRenderProps
                        .valueGetter("vendor_options")
                        ?.map((item: any) => {
                          return {
                            value: item.id,
                            label: item.account_name,
                          };
                        }) || []
                    }
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="transport_name"
                    label="Transport Name"
                    placeholder="i.e. John Smith"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="vehicle_no"
                    label="Vehicle No"
                    placeholder="i.e. GJ01AA1111"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="sales_ledger_id"
                    label="Sales Ledger"
                    placeholder="Search Sales Ledger"
                    // isAddNew={true}
                    // addNewLink="account/create"
                    component={FormIncrementalSearch}
                    validator={requiredValidator}
                    astrike={true}
                    fetchIncrementalData={(search: any) =>
                      handleSalesLedgerSearchChange(
                        search,
                        `credit_note_ledger`,
                        formRenderProps
                      )
                    }
                    options={
                      formRenderProps
                        .valueGetter("credit_note_ledger")
                        ?.map((item: any) => {
                          return {
                            value: item.id,
                            label: item.account_name,
                          };
                        }) || []
                    }
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="remarks"
                    label="Remarks"
                    placeholder="Remarks.."
                    component={FormTextArea}
                    rows={2}
                  />
                </GridLayoutItem>
                <QtyChangeWatcher formRenderProps={formRenderProps} />
                <VendorChangeWatcher formRenderProps={formRenderProps} />
                <CreditNoteNoChangeWatcher formRenderProps={formRenderProps} />
              </GridLayout>
            </ShadowCard>
            <ShadowCard style={{ padding: 12, marginTop: 10 }}>
              <GridLayout
                style={{ marginRight: 30 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                ]}
              >
                <GridLayoutItem colSpan={4}>
                  <Typography.h4>Item Details</Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={4}
                  // style={{ borderBottom: "1px solid lightgray" }}
                >
                  <FieldArray
                    formRenderProps={formRenderProps}
                    handleItemSearchChange={handleItemSearchChange}
                    component={CreditNoteItemDetailsArray}
                    name="credit_note_items"
                  />
                </GridLayoutItem>
                
                <GridLayoutItem colSpan={2}></GridLayoutItem>
                <GridLayoutItem
                  colSpan={2}
                  style={{ display: "flex", alignItems: "end" }}
                >
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      <tr>
                        <td
                          style={{
                            width: "50%",
                            textAlign: "end",
                            fontWeight: 600,
                          }}
                        >
                          Total
                        </td>
                        <td style={{ width: "5%", textAlign: "center" }}>:</td>
                        <td style={{ width: "45%" }}>
                          <Field
                            wrapperClassName="calculation-label"
                            wrapperStyle={{ margin: 0, width: "100%" }}
                            name="taxable_amount"
                            format="n2"
                            component={FormNumericTextField}
                            disabled="true"
                          />
                        </td>
                      </tr>
                    </table>
                  </div>
                </GridLayoutItem>
                <GridLayoutItem colSpan={4}>
                  <FieldArray
                    formRenderProps={formRenderProps}
                    handleLedgerSearchChange={handleLedgerSearchChange}
                    component={CreditNoteDutyAndTaxArray}
                    name="credit_note_duty_and_taxes"
                  />
                  {/* <CreditNoteInvoiceTaxSummary
                    formRenderProps={formRenderProps}
                  /> */}
                </GridLayoutItem>
                <GridLayoutItem colSpan={2}></GridLayoutItem>
                <GridLayoutItem
                  colSpan={2}
                  style={{ display: "flex", alignItems: "end" }}
                >
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      {/* <tr>
                        <td
                          style={{
                            width: "50%",
                            textAlign: "end",
                            fontWeight: 600,
                          }}
                        >
                          Total
                        </td>
                        <td style={{ width: "5%", textAlign: "center" }}>:</td>
                        <td style={{ width: "45%" }}>
                          <Field
                            wrapperClassName="calculation-label"
                            wrapperStyle={{ margin: 0, width: "100%" }}
                            name="taxable_amount"
                            format="n2"
                            component={FormNumericTextField}
                            disabled="true"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            width: "50%",
                            textAlign: "end",
                            fontWeight: 600,
                          }}
                        >
                          Packing
                        </td>
                        <td style={{ width: "5%", textAlign: "center" }}>:</td>
                        <td style={{ width: "45%" }}>
                          <Field
                            wrapperClassName="right-alighned-field"
                            wrapperStyle={{ margin: 0, width: "100%" }}
                            name="packing"
                            format="n2"
                            placeholder="0.00"
                            component={FormNumericTextField}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            width: "50%",
                            textAlign: "end",
                            fontWeight: 600,
                          }}
                        >
                          Labour
                        </td>
                        <td style={{ width: "5%", textAlign: "center" }}>:</td>
                        <td style={{ width: "45%" }}>
                          <Field
                            wrapperClassName="right-alighned-field"
                            wrapperStyle={{ margin: 0, width: "100%" }}
                            name="labour"
                            format="n2"
                            placeholder="0.00"
                            component={FormNumericTextField}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            width: "50%",
                            textAlign: "end",
                            fontWeight: 600,
                          }}
                        >
                          Dalali
                        </td>
                        <td style={{ width: "5%", textAlign: "center" }}>:</td>
                        <td style={{ width: "45%" }}>
                          <Field
                            wrapperClassName="right-alighned-field"
                            wrapperStyle={{ margin: 0, width: "100%" }}
                            name="dalali"
                            format="n2"
                            placeholder="0.00"
                            component={FormNumericTextField}
                          />
                        </td>
                      </tr>
                      {CreditNoteStateForGST === GUJARATIDFORGST && (
                        <tr>
                          <td
                            style={{
                              width: "50%",
                              textAlign: "end",
                              fontWeight: 600,
                            }}
                          >
                            CGST
                          </td>
                          <td style={{ width: "5%", textAlign: "center" }}>
                            :
                          </td>
                          <td style={{ width: "45%" }}>
                            <Field
                              wrapperClassName="calculation-label"
                              wrapperStyle={{ margin: 0, width: "100%" }}
                              name="cgst"
                              placeholder="0"
                              disabled={true}
                              format="n2"
                              component={FormNumericTextField}
                            />
                          </td>
                        </tr>
                      )}
                      {CreditNoteStateForGST === GUJARATIDFORGST && (
                        <tr>
                          <td
                            style={{
                              width: "50%",
                              textAlign: "end",
                              fontWeight: 600,
                            }}
                          >
                            SGST
                          </td>
                          <td style={{ width: "5%", textAlign: "center" }}>
                            :
                          </td>
                          <td style={{ width: "45%" }}>
                            <Field
                              wrapperClassName="calculation-label"
                              wrapperStyle={{ margin: 0, width: "100%" }}
                              name="sgst"
                              placeholder="0"
                              disabled={true}
                              format="n2"
                              component={FormNumericTextField}
                            />
                          </td>
                        </tr>
                      )}
                      {CreditNoteStateForGST !== GUJARATIDFORGST && (
                        <tr>
                          <td
                            style={{
                              width: "50%",
                              textAlign: "end",
                              fontWeight: 600,
                            }}
                          >
                            IGST
                          </td>
                          <td style={{ width: "5%", textAlign: "center" }}>
                            :
                          </td>
                          <td style={{ width: "45%" }}>
                            <Field
                              wrapperClassName="calculation-label"
                              wrapperStyle={{ margin: 0, width: "100%" }}
                              name="igst"
                              placeholder="0"
                              disabled={true}
                              format="n2"
                              component={FormNumericTextField}
                            />
                          </td>
                        </tr>
                      )} */}
                      <tr>
                        <td
                          style={{
                            width: "50%",
                            textAlign: "end",
                            fontWeight: 600,
                          }}
                        >
                          Sub Total
                        </td>
                        <td style={{ width: "5%", textAlign: "center" }}>:</td>
                        <td style={{ width: "45%" }}>
                          <Field
                            wrapperClassName="calculation-label"
                            wrapperStyle={{ margin: 0, width: "100%" }}
                            name="sub_total"
                            placeholder="0"
                            disabled={true}
                            format="n2"
                            component={FormNumericTextField}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            width: "50%",
                            textAlign: "end",
                            fontWeight: 600,
                          }}
                        >
                          Round Off
                        </td>
                        <td style={{ width: "5%", textAlign: "center" }}>:</td>
                        <td style={{ width: "45%" }}>
                          <Field
                            wrapperClassName="calculation-label"
                            wrapperStyle={{ margin: 0, width: "100%" }}
                            name="round_off"
                            placeholder="0"
                            disabled={true}
                            format="n2"
                            component={FormNumericTextField}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            width: "50%",
                            textAlign: "end",
                            fontWeight: 600,
                          }}
                        >
                          Grand Total
                        </td>
                        <td style={{ width: "5%", textAlign: "center" }}>:</td>
                        <td style={{ width: "45%" }}>
                          <Field
                            wrapperClassName="calculation-label"
                            wrapperStyle={{ margin: 0, width: "100%" }}
                            name="grand_total"
                            placeholder="0"
                            disabled={true}
                            format="n2"
                            component={FormNumericTextField}
                          />
                        </td>
                      </tr>
                    </table>
                  </div>
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={4}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "end",
                    marginTop: 15,
                  }}
                >
                  <div>
                    <ButtonWithLoading
                      label={credit_note_guid ? "Update" : "Save"}
                      type="submit"
                      disabled={!formRenderProps.allowSubmit || loading}
                      loading={loading}
                    />
                    <Button
                      type="button"
                      fillMode={"outline"}
                      themeColor={"primary"}
                      style={{ marginLeft: 4 }}
                      onClick={() =>
                        // navigate("/creditNote")
                        navigate(-1)
                      }
                    >
                      Cancel
                    </Button>
                  </div>
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
          </FormElement>
        )}
      />
    </>
  );
};

// const CreditNoteInvoiceTaxSummary: React.FC<FormChangeWatcherProps> = ({
//   formRenderProps,
// }) => {
//   const location = useLocation();
//   const CreditNoteStateForGST = useAppSelector(
//     (state) => state.creditnote.CreditNoteStateForGST
//   );
//   const credit_note_guid = location.state?.credit_note_guid;
//   const CreditNoteDetail = useAppSelector(
//     (state) => state.creditnote.CreditNoteDetail
//   );
//   const isVendorRef = useRef(true);
//   const [isIGST, setIsIgst] = React.useState<boolean>(false);

//   const totalGST = formRenderProps.valueGetter("gst");
//   const totalGST5 = formRenderProps.valueGetter("gst5");
//   const totalGST12 = formRenderProps.valueGetter("gst12");
//   const totalGST18 = formRenderProps.valueGetter("gst18");
//   const totalGST28 = formRenderProps.valueGetter("gst28");

//   useEffect(() => {
//     if (credit_note_guid) {
//       if (!isVendorRef.current) {
//         setIsIgst(CreditNoteStateForGST !== GUJARATIDFORGST);
//       } else {
//         if (CreditNoteDetail?.igst && CreditNoteDetail?.igst !== null) {
//           setIsIgst(true);
//         } else {
//           setIsIgst(false);
//         }
//         isVendorRef.current = false;
//       }
//     } else {
//       setIsIgst(CreditNoteStateForGST !== GUJARATIDFORGST);
//     }
//   }, [CreditNoteStateForGST]);

//   return (
//     <>
//       <div>
//         <table style={{ width: "100%" }} className="sctaxsummary-table">
//           {(totalGST5 > 0 ||
//             totalGST12 > 0 ||
//             totalGST18 > 0 ||
//             totalGST28 > 0) && (
//             <tr style={{ background: "lightgray" }}>
//               <td colSpan={4}>
//                 <h5 className="m-0">Tax Summery</h5>
//               </td>
//             </tr>
//           )}
//           {isIGST && totalGST5 > 0 && (
//             <tr>
//               <td
//                 style={{
//                   width: "30%",
//                   fontWeight: 600,
//                 }}
//               >
//                 IGST 5%
//               </td>
//               <td style={{ width: "10%", textAlign: "center" }}>:</td>
//               <td style={{ width: "10%", textAlign: "center" }}>5 %</td>
//               <td
//                 style={{
//                   width: "50%",
//                   textAlign: "right",
//                   paddingRight: 45,
//                 }}
//               >
//                 {formatIndianNumber(totalGST5)}
//               </td>
//             </tr>
//           )}
//           {isIGST && totalGST12 > 0 && (
//             <tr>
//               <td
//                 style={{
//                   width: "30%",
//                   fontWeight: 600,
//                 }}
//               >
//                 IGST 12%
//               </td>
//               <td style={{ width: "10%", textAlign: "center" }}>:</td>
//               <td style={{ width: "10%", textAlign: "center" }}>12 %</td>
//               <td
//                 style={{
//                   width: "50%",
//                   textAlign: "right",
//                   paddingRight: 45,
//                 }}
//               >
//                 {formatIndianNumber(totalGST12)}
//               </td>
//             </tr>
//           )}
//           {isIGST && totalGST18 > 0 && (
//             <tr>
//               <td
//                 style={{
//                   width: "30%",
//                   fontWeight: 600,
//                 }}
//               >
//                 IGST 18%
//               </td>
//               <td style={{ width: "10%", textAlign: "center" }}>:</td>
//               <td style={{ width: "10%", textAlign: "center" }}>18 %</td>
//               <td
//                 style={{
//                   width: "50%",
//                   textAlign: "right",
//                   paddingRight: 45,
//                 }}
//               >
//                 {formatIndianNumber(totalGST18)}
//               </td>
//             </tr>
//           )}
//           {isIGST && totalGST28 > 0 && (
//             <tr>
//               <td
//                 style={{
//                   width: "30%",
//                   fontWeight: 600,
//                 }}
//               >
//                 IGST 28%
//               </td>
//               <td style={{ width: "10%", textAlign: "center" }}>:</td>
//               <td style={{ width: "10%", textAlign: "center" }}>28 %</td>
//               <td
//                 style={{
//                   width: "50%",
//                   textAlign: "right",
//                   paddingRight: 45,
//                 }}
//               >
//                 {formatIndianNumber(totalGST28)}
//               </td>
//             </tr>
//           )}
//           {!isIGST && totalGST5 > 0 && (
//             <>
//               <tr>
//                 <td
//                   style={{
//                     width: "30%",
//                     fontWeight: 600,
//                   }}
//                 >
//                   CGST 2.5%
//                 </td>
//                 <td style={{ width: "10%", textAlign: "center" }}>:</td>
//                 <td style={{ width: "10%", textAlign: "center" }}>2.5 %</td>
//                 <td
//                   style={{
//                     width: "50%",
//                     textAlign: "right",
//                     paddingRight: 45,
//                   }}
//                 >
//                   {formatIndianNumber(totalGST5 / 2)}
//                 </td>
//               </tr>
//               <tr>
//                 <td
//                   style={{
//                     width: "30%",
//                     fontWeight: 600,
//                   }}
//                 >
//                   SGST 2.5%
//                 </td>
//                 <td style={{ width: "10%", textAlign: "center" }}>:</td>
//                 <td style={{ width: "10%", textAlign: "center" }}>2.5 %</td>
//                 <td
//                   style={{
//                     width: "50%",
//                     textAlign: "right",
//                     paddingRight: 45,
//                   }}
//                 >
//                   {formatIndianNumber(totalGST5 / 2)}
//                 </td>
//               </tr>
//             </>
//           )}
//           {!isIGST && totalGST12 > 0 && (
//             <>
//               <tr>
//                 <td
//                   style={{
//                     width: "30%",
//                     fontWeight: 600,
//                   }}
//                 >
//                   CGST 6%
//                 </td>
//                 <td style={{ width: "10%", textAlign: "center" }}>:</td>
//                 <td style={{ width: "10%", textAlign: "center" }}>6 %</td>
//                 <td
//                   style={{
//                     width: "50%",
//                     textAlign: "right",
//                     paddingRight: 45,
//                   }}
//                 >
//                   {formatIndianNumber(totalGST12 / 2)}
//                 </td>
//               </tr>
//               <tr>
//                 <td
//                   style={{
//                     width: "30%",
//                     fontWeight: 600,
//                   }}
//                 >
//                   SGST 6%
//                 </td>
//                 <td style={{ width: "10%", textAlign: "center" }}>:</td>
//                 <td style={{ width: "10%", textAlign: "center" }}>6 %</td>
//                 <td
//                   style={{
//                     width: "50%",
//                     textAlign: "right",
//                     paddingRight: 45,
//                   }}
//                 >
//                   {formatIndianNumber(totalGST12 / 2)}
//                 </td>
//               </tr>
//             </>
//           )}
//           {!isIGST && totalGST18 > 0 && (
//             <>
//               <tr>
//                 <td
//                   style={{
//                     width: "30%",
//                     fontWeight: 600,
//                   }}
//                 >
//                   CGST 9%
//                 </td>
//                 <td style={{ width: "10%", textAlign: "center" }}>:</td>
//                 <td style={{ width: "10%", textAlign: "center" }}>9 %</td>
//                 <td
//                   style={{
//                     width: "50%",
//                     textAlign: "right",
//                     paddingRight: 45,
//                   }}
//                 >
//                   {formatIndianNumber(totalGST18 / 2)}
//                 </td>
//               </tr>
//               <tr>
//                 <td
//                   style={{
//                     width: "30%",
//                     fontWeight: 600,
//                   }}
//                 >
//                   SGST 9%
//                 </td>
//                 <td style={{ width: "10%", textAlign: "center" }}>:</td>
//                 <td style={{ width: "10%", textAlign: "center" }}>9 %</td>
//                 <td
//                   style={{
//                     width: "50%",
//                     textAlign: "right",
//                     paddingRight: 45,
//                   }}
//                 >
//                   {formatIndianNumber(totalGST18 / 2)}
//                 </td>
//               </tr>
//             </>
//           )}
//           {!isIGST && totalGST28 > 0 && (
//             <>
//               <tr>
//                 <td
//                   style={{
//                     width: "30%",
//                     fontWeight: 600,
//                   }}
//                 >
//                   CGST 14%
//                 </td>
//                 <td style={{ width: "10%", textAlign: "center" }}>:</td>
//                 <td style={{ width: "10%", textAlign: "center" }}>14 %</td>
//                 <td
//                   style={{
//                     width: "50%",
//                     textAlign: "right",
//                     paddingRight: 45,
//                   }}
//                 >
//                   {formatIndianNumber(totalGST28 / 2)}
//                 </td>
//               </tr>
//               <tr>
//                 <td
//                   style={{
//                     width: "30%",
//                     fontWeight: 600,
//                   }}
//                 >
//                   SGST 14%
//                 </td>
//                 <td style={{ width: "10%", textAlign: "center" }}>:</td>
//                 <td style={{ width: "10%", textAlign: "center" }}>14 %</td>
//                 <td
//                   style={{
//                     width: "50%",
//                     textAlign: "right",
//                     paddingRight: 45,
//                   }}
//                 >
//                   {formatIndianNumber(totalGST28 / 2)}
//                 </td>
//               </tr>
//             </>
//           )}

//           {!isIGST && totalGST > 0 && (
//             <>
//               <tr style={{ background: "whitesmoke" }}>
//                 <td
//                   style={{
//                     width: "30%",
//                     fontWeight: 600,
//                   }}
//                 >
//                   Total CGST
//                 </td>
//                 <td style={{ width: "10%", textAlign: "center" }}>:</td>
//                 <td style={{ width: "10%", textAlign: "center" }}></td>
//                 <td
//                   style={{
//                     width: "50%",
//                     textAlign: "right",
//                     paddingRight: 45,
//                   }}
//                 >
//                   {formatIndianNumber(totalGST / 2)}
//                 </td>
//               </tr>
//               <tr style={{ background: "whitesmoke" }}>
//                 <td
//                   style={{
//                     width: "30%",
//                     fontWeight: 600,
//                   }}
//                 >
//                   Total SGST
//                 </td>
//                 <td style={{ width: "10%", textAlign: "center" }}>:</td>
//                 <td style={{ width: "10%", textAlign: "center" }}></td>
//                 <td
//                   style={{
//                     width: "50%",
//                     textAlign: "right",
//                     paddingRight: 45,
//                   }}
//                 >
//                   {formatIndianNumber(totalGST / 2)}
//                 </td>
//               </tr>
//             </>
//           )}
//           {isIGST && totalGST > 0 && (
//             <tr style={{ background: "whitesmoke" }}>
//               <td
//                 style={{
//                   width: "30%",
//                   fontWeight: 600,
//                 }}
//               >
//                 Total IGST
//               </td>
//               <td style={{ width: "10%", textAlign: "center" }}>:</td>
//               <td style={{ width: "10%", textAlign: "center" }}></td>
//               <td
//                 style={{
//                   width: "50%",
//                   textAlign: "right",
//                   paddingRight: 45,
//                 }}
//               >
//                 {formatIndianNumber(totalGST)}
//               </td>
//             </tr>
//           )}
//         </table>
//       </div>
//     </>
//   );
// };

export default CreateCreditNote;
