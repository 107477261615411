import React from "react";
import {
  // Field,
  FieldArray,
  FieldArrayRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
// import FormMultiSelectionFiled from "../../components/formFields/FormMultiSelectionFiled";
import moment from "moment";
import DistributorDetailsArray from "./DistributorDetailsArray";

const SalesRepresentativeDetailsArray = (
  fieldArrayRenderProps: FieldArrayRenderProps
) => {
  return (
    <>
      <GridLayout
        cols={[{ width: "100%" }]}
        style={{ overflowX: "scroll", scrollbarWidth: "thin" }}
      >
        <GridLayoutItem
          className="bg-dark text-white fw-600"
          style={{ width: 2000 }}
        >
          <GridLayout
            style={{
              padding: 10,
              marginRight: "0.5%",
            }}
            gap={{ rows: 10, cols: 5 }}
            cols={[
              { width: "12%" },
              { width: "12.5%" },
              { width: "12.5%" },
              { width: "12.5%" },
              { width: "12.5%" },
              { width: "12.5%" },
              { width: "12.5%" },
              { width: "12.5%" },
            ]}
          >
            <GridLayoutItem></GridLayoutItem>
            {fieldArrayRenderProps.value &&
              fieldArrayRenderProps.value.length > 0 &&
              fieldArrayRenderProps.value?.[0]?.distributor_details?.map(
                (day: any, index: any) => (
                  <GridLayoutItem key={index} style={{ textAlign: "center" }}>
                    <Typography.h6 style={{ marginBottom: "5px" }}>
                      {day.date}
                    </Typography.h6>
                    <Typography.h6 style={{ marginBottom: 0 }}>
                      {moment(day.date, "DD/MM/YYYY").format("dddd")}
                    </Typography.h6>
                  </GridLayoutItem>
                )
              )}
          </GridLayout>
        </GridLayoutItem>
        {fieldArrayRenderProps.value &&
          fieldArrayRenderProps.value.length > 0 &&
          fieldArrayRenderProps.value.map(
            (sales_representative: any, index: number) => {
              return (
                <GridLayoutItem key={index} style={{ width: 2000 }}>
                  <GridLayout
                    style={{
                      marginRight: "0.5%",
                      padding: 10,
                    }}
                    gap={{ rows: 10, cols: 5 }}
                    cols={[
                      { width: "12%" },
                      { width: "12.5%" },
                      { width: "12.5%" },
                      { width: "12.5%" },
                      { width: "12.5%" },
                      { width: "12.5%" },
                      { width: "12.5%" },
                      { width: "12.5%" },
                    ]}
                  >
                    <GridLayoutItem>
                      <Typography.h6 style={{ textTransform: "uppercase" }}>
                        {sales_representative.sr_name}
                      </Typography.h6>
                    </GridLayoutItem>

                    <FieldArray
                      formRenderProps={fieldArrayRenderProps.formRenderProps}
                      component={DistributorDetailsArray}
                      handleDistributorSearchChange={
                        fieldArrayRenderProps.handleDistributorSearchChange
                      }
                      name={`${fieldArrayRenderProps?.name}.${index}.distributor_details`}
                    />

                    {/* {sales_representative?.distributor_details?.length > 0 &&
                      sales_representative?.distributor_details?.map(
                        (distributor_details: any, ind: any) => (
                          <GridLayoutItem>
                            <Field
                              wrapperStyle={{ margin: 0 }}
                              name={`sales_representative.${index}.distributor_details.${ind}.distributor_id`}
                              component={FormMultiSelectionFiled}
                              options={PAYMENT_INITIAL.map((init: any) => {
                                return {
                                  value: init?.value,
                                  label: init?.label,
                                };
                              })}
                            />
                          </GridLayoutItem>
                        )
                      )} */}
                  </GridLayout>
                </GridLayoutItem>
              );
            }
          )}
      </GridLayout>
    </>
  );
};

export default SalesRepresentativeDetailsArray;
