import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
// import moment from "moment";

export const getAllSalesRepresentativeAssignArea = createAsyncThunk(
  "SalesRepresentative/FindAllSalesRepresentative",
  async (formdata: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SalesRepresentative/FindAllSalesRepresentative`,
        formdata
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Sales Representative:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
export const getAllSalesRepresentative = createAsyncThunk(
  "SalesRepresentative/FindAllSalesRepresentativelist",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/SalesRepresentative/FindAllSalesRepresentativelist`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Sales Representative:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllDistributorWithArea = createAsyncThunk(
  "SalesRepresentative/FindAllDistributoAreaInremental",
  async (search: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SalesRepresentative/FindAllDistributoAreaInremental`,
        { search: search }
      );
      return response.data?.Data;
    } catch (error: any) {
      console.error("Error Fetching Distributor With Area :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createSalesRespresentative = createAsyncThunk(
  "SalesRepresentative/InsertSalesRespresentative",
  async (SchemeData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SalesRepresentative/InsertSalesRespresentative`,
        SchemeData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating scheme:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteSalesRepresentative = createAsyncThunk(
  "SalesRepresentative/DeleteSRPlan",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SalesRepresentative/DeleteSRPlan`,
        { plan_id: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting SR Plan:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
