import React, { useEffect, useState } from "react";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import FormDatePicker from "../../../components/formFields/FormDateField";
import moment from "moment";
import { Typography } from "@progress/kendo-react-common";
import ShadowCard from "../../../components/common/ShadowCard";
import { getAllItemIncremental } from "../../Item/services/item.services";
import {
  clearInwardRegisterDetails,
  clearInwardRegisterList,
  setInwardRegisterReport,
} from "../reportsSlice";
import { IItem } from "../../Item/itemModel";
import { getInwardRegisterReport } from "../services/reports.services";
import AlertBox from "../../../components/common/AlertBox";
import { LoadingPanel } from "../../../components/layout/Loading";
import { requiredValidator } from "../../../components/formFields/CommonValidator";
import FormIncrementalSearch from "../../../components/formFields/FormIncrementalSearch";
import { clearItemList } from "../../Item/itemSlice";
import { Button } from "@progress/kendo-react-buttons";
import Logo from "../../../assets/Images/logo.png";
import {
  checkAcessRights,
  formatIndianNumber,
  formatIndianNumberForQty,
} from "../../../_helper/helper";
import {
  EDIT_ACCESS,
  EXCEL_EXPORT_ACCESS,
  PRINT_ACCESS,
} from "../../../_contstants/common";
import { useLocation, useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { RxCross2 } from "react-icons/rx";
import FormCheckboxGroup from "../../../components/formFields/FormCheckboxGroup";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Tooltip } from "@progress/kendo-react-tooltip";
import IconButton from "../../../components/common/IconButton";
import { FiEdit } from "react-icons/fi";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}
const DummyChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const createdBy = localStorage.getItem("UserID");
  useEffect(() => {
    formRenderProps.onChange("UserID", {
      value: createdBy,
    });
  }, []);

  return null;
};

const InwardRegisterReportForm: React.FC = () => {
  // const gridRef = React.useRef<any>(null);
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.reports.loading);
  const itemLoading = useAppSelector((state) => state.item.loading);
  const ItemList = useAppSelector((state) => state.item.ItemList);
  const InwardRegisterList = useAppSelector(
    (state) => state.reports.InwardRegisterList
  );
  const InwardRegisterDetails = useAppSelector(
    (state) => state.reports.InwardRegisterDetails
  );
  const [initialValue, setInitialValue] = useState<any>({});
  const [formKey, setFormKey] = useState<any>(1);

  useEffect(() => {
    setInitialValue({
      ...InwardRegisterDetails,
      start_date: InwardRegisterDetails?.start_date
        ? moment(InwardRegisterDetails?.start_date, "YYYY-MM-DD").toDate()
        : null,
      end_Date: InwardRegisterDetails?.end_Date
        ? moment(InwardRegisterDetails?.end_Date, "YYYY-MM-DD").toDate()
        : null,
    });
    setFormKey(formKey + 1);
  }, [InwardRegisterDetails]);

  const handleSubmit = (values: any) => {
    const payload = {
      item_id: values?.item_id ? values?.item_id : null,
      start_date: values?.start_date
        ? moment(values?.start_date).format("YYYY-MM-DD")
        : null,
      end_Date: values?.end_Date
        ? moment(values?.end_Date).format("YYYY-MM-DD")
        : null,
    };
    const setPayload = {
      ...payload,
      item_options: values?.item_options || [],
      isInwardRef: true,
    };
    dispatch(setInwardRegisterReport(setPayload));
    dispatch(getInwardRegisterReport(payload));
  };

  return (
    <GridLayout gap={{ rows: 10 }} cols={[{ width: "100%" }]}>
      <GridLayoutItem>
        <ShadowCard>
          <Form
            key={formKey}
            initialValues={initialValue}
            onSubmit={handleSubmit}
            render={(formRenderProps: FormRenderProps) => (
              <FormElement style={{ width: "100%" }}>
                <GridLayout
                  style={{ marginRight: 30 }}
                  gap={{ rows: 10, cols: 10 }}
                  cols={[
                    { width: "25%" },
                    { width: "25%" },
                    { width: "25%" },
                    { width: "25%" },
                  ]}
                >
                  <GridLayoutItem colSpan={4}>
                    <Typography.h4
                      style={{ marginBottom: 0, marginLeft: "3px" }}
                    >
                      Inward Register
                    </Typography.h4>
                  </GridLayoutItem>
                  <GridLayoutItem
                  // style={{ display: "flex", gap: 10 }}
                  >
                    <Field
                      wrapperStyle={{ width: "100%" }}
                      name="start_date"
                      label="From Date"
                      format="dd/MM/yyyy"
                      component={FormDatePicker}
                      validator={requiredValidator}
                      astrike={true}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      wrapperStyle={{ width: "100%" }}
                      name="end_Date"
                      label="To Date"
                      format="dd/MM/yyyy"
                      minDate={moment(
                        formRenderProps.valueGetter("start_date")
                      ).toDate()}
                      component={FormDatePicker}
                      validator={requiredValidator}
                      astrike={true}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem
                  // style={{ display: "flex", alignItems: "end", gap: 10 }}
                  >
                    <Field
                      wrapperClassName="w-100"
                      name="item_id"
                      label="Product Name"
                      placeholder="Type here to search item..."
                      // component={FormSelectionField}
                      // options={ItemList?.map((item: IItem) => {
                      //   return {
                      //     value: item?.id,
                      //     label: item?.product_name,
                      //   };
                      // })}
                      component={FormIncrementalSearch}
                      fetchIncrementalData={(search: any) =>
                        dispatch(getAllItemIncremental({ search }))
                      }
                      loading={itemLoading}
                      options={ItemList?.map((item: IItem) => {
                        return {
                          value: item?.id,
                          label: item?.product_name,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <div
                      style={{
                        marginTop: 35,
                      }}
                    >
                      <ButtonWithLoading
                        label={"View"}
                        type="submit"
                        disabled={!formRenderProps.allowSubmit || loading}
                        loading={loading}
                      />
                    </div>
                  </GridLayoutItem>
                </GridLayout>
                <DummyChangeWatcher formRenderProps={formRenderProps} />
              </FormElement>
            )}
          />
        </ShadowCard>
        {/* {loading ? (
          <LoadingPanel gridRef={gridRef} />
        ) : ( */}
        {InwardRegisterList && InwardRegisterList.length > 0 && (
          <ShadowCard style={{ marginTop: 10 }}>
            <InwardRegisterGridCommponent />
          </ShadowCard>
        )}
        {/* )} */}
      </GridLayoutItem>
    </GridLayout>
  );
};

const columns = [
  // { label: "Po No", value: "po_no" },
  { label: "Inward No", value: "inward_no" },
  { label: "Inward Date", value: "inward_date" },
  { label: "Item Name", value: "item_name" },
  { label: "Grn No", value: "grn_no_string" },
  { label: "Dagina", value: "dagina" },
  { label: "Weight", value: "weight" },
  { label: "Unit", value: "unit_code" },
  { label: "Rate", value: "rate" },
  { label: "Total", value: "total" },
  { label: "Warehouse Name", value: "warehouse_name" },
  { label: "Receipt No", value: "receipt_no" },
  { label: "Account Name", value: "account_name" },
  { label: "Chalan No", value: "chalan_no" },
  { label: "Bill No", value: "bill_no" },
  { label: "Bill Date", value: "bill_date" },
  { label: "Vehicle No", value: "vehicle_no" },
  { label: "Vehicle In Time", value: "vehicle_in_time" },
  { label: "Vehicle Out Time", value: "vehicle_out_time" },
  { label: "Remarks", value: "remarks" },
];

const InwardRegisterGridCommponent: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const gridRef = React.useRef<any>(null);
  const isEditBtnRef = React.useRef<any>(false);
  const isEditAccess = checkAcessRights("/inward", EDIT_ACCESS);
  const isPrintAccess = checkAcessRights(location.pathname, PRINT_ACCESS);
  const isExcelAccess = checkAcessRights(
    location.pathname,
    EXCEL_EXPORT_ACCESS
  );

  const loading = useAppSelector((state) => state.reports.loading);
  const InwardRegisterList = useAppSelector(
    (state) => state.reports.InwardRegisterList
  );
  const InwardRegisterDetails = useAppSelector(
    (state) => state.reports.InwardRegisterDetails
  );
  const [showfilterColumns, setShowFilterColumns] = React.useState(false);
  const [selectedGroup, setSelectedGroup] = React.useState<string | null>(null);

  useEffect(() => {
    return () => {
      if (!isEditBtnRef.current) {
        dispatch(clearInwardRegisterList());
        dispatch(clearInwardRegisterDetails());
        dispatch(clearItemList());
      }
    };
  }, []);

  // useEffect(() => {
  //   if (!isInwardRegisterRef.current) {
  //     const payload = {
  //       item_id: InwardRegisterDetails?.item_id
  //         ? InwardRegisterDetails?.item_id
  //         : null,
  //       start_date: InwardRegisterDetails?.start_date
  //         ? InwardRegisterDetails?.start_date
  //         : "",
  //       end_Date: InwardRegisterDetails?.end_Date
  //         ? InwardRegisterDetails?.end_Date
  //         : "",
  //     };
  //     dispatch(getInwardRegisterReport(payload));
  //   } else {
  //     isInwardRegisterRef.current = false;
  //   }
  // }, []);

  const initialValues = {
    filter_columns: [
      // "po_no",
      "inward_no",
      "inward_date",
      "item_name",
      "grn_no_string",
      "dagina",
      "weight",
      "unit_code",
      "rate",
      "total",
      "warehouse_name",
      "receipt_no",
      "account_name",
      "chalan_no",
      "bill_no",
      "bill_date",
      "vehicle_no",
      "vehicle_in_time",
      "vehicle_out_time",
      "remarks",
    ],
  };

  const GrandTotalArray: any = ["weight", "total"];

  const handleRowClick = (inward_guid: string) => {
    const groupKey = `${inward_guid}`;
    setSelectedGroup(selectedGroup === groupKey ? null : groupKey);
  };

  const groupedData = InwardRegisterList.reduce((acc: any, item: any) => {
    const { inward_guid } = item;
    acc[inward_guid] = acc[inward_guid] || [];
    acc[inward_guid].push(item);
    return acc;
  }, {});
  const groupedArray = Object.values(groupedData);

  let totalQty = 0;
  let totalAmount = 0;

  groupedArray &&
    groupedArray.length > 0 &&
    groupedArray?.map((group: any) => {
      group?.map((item: any) => {
        totalQty += item?.weight || 0;
        totalAmount += item?.total || 0;
      });
    });

  const handleExportExcel = () => {
    const datetime = moment().format("DD-MM-YYYY HH-mm");
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.table_to_sheet(
      document.querySelector(".currentstock-print-table")
    );
    XLSX.utils.book_append_sheet(wb, ws, "InwardRegisterReport");

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(data, `Inward Register Report ${datetime}`);
  };

  const printTable = (data: any[], formRenderProps: any) => {
    let printbuttons = document.querySelector(
      ".printbuttons"
    ) as HTMLElement | null;
    let report_container = document.querySelector(
      ".report-container"
    ) as HTMLElement | null;

    if (printbuttons) printbuttons.style.display = "none";
    if (report_container) report_container.style.padding = "20px 0";

    let tableRows = data.length;
    let maxRows = tableRows;
    let pageCount = Math.ceil(tableRows / maxRows);
    let content = "";

    for (let i = 0; i < pageCount; i++) {
      content += '<div class="print-page">';
      //   content += `<div class="page-header">Page ${i + 1}</div>`;
      content +=
        '<table class="table table-bordered" style="font-size:10px;margin-bottom:30px;">';
      content += "<tr>";
      content += `<td rowSpan="3" style="width:15%" ><img src="${Logo}" width="100%" /></td>`;
      content +=
        '<td colSpan="2" style="text-align: center;font-size: 14px;font-weight: 600;color: gray;">Harihar Foods Pvt Ltd.</td>';
      content += "</tr>";
      content += "<tr>";
      content +=
        '<td colSpan="2"><h3 style="margin:0;">Inward Register Report</h3></td>';
      content += "</tr>";
      content += "<tr>";
      content += `<td style="text-align:end;">Page : ${i + 1}</td>`;
      content += "</tr>";

      content += "</table>";

      content += '<table class="table table-bordered" style="font-size:10px;">';
      content += '<thead style="background:lightgray;">';
      content += "<tr>";
      content += "<th>PO No.</th>";
      {
        formRenderProps.valueGetter("filter_columns")?.includes("inward_no") &&
          (content += "<th>Inward No.</th>");
      }
      {
        formRenderProps
          .valueGetter("filter_columns")
          ?.includes("inward_date") && (content += "<th>Inward Date</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("item_name") &&
          (content += "<th>Item Name</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("grn_no_string") &&
          (content += "<th>GRN No.</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("dagina") &&
          (content += "<th>Dagina</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("weight") &&
          (content += "<th>Weight</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("unit_code") &&
          (content += "<th>Unit</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("rate") &&
          (content += "<th>Rate</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("total") &&
          (content += "<th>Total</th>");
      }
      {
        formRenderProps
          .valueGetter("filter_columns")
          ?.includes("warehouse_name") &&
          (content += "<th>Warehouse Name</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("receipt_no") &&
          (content += "<th>Receipt No.</th>");
      }
      {
        formRenderProps
          .valueGetter("filter_columns")
          ?.includes("account_name") && (content += "<th>Account Name</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("chalan_no") &&
          (content += "<th>Challan No.</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("bill_no") &&
          (content += "<th>Bill No.</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("bill_date") &&
          (content += "<th>Bill Date</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("vehicle_no") &&
          (content += "<th>Vehicle No.</th>");
      }
      {
        formRenderProps
          .valueGetter("filter_columns")
          ?.includes("vehicle_in_time") &&
          (content += "<th>Vehicle In Time</th>");
      }
      {
        formRenderProps
          .valueGetter("filter_columns")
          ?.includes("vehicle_out_time") &&
          (content += "<th>Vehicle Out Time</th>");
      }
      {
        formRenderProps.valueGetter("filter_columns")?.includes("remarks") &&
          (content += "<th>Remarks</th>");
      }
      content += "</tr>";
      content += "</thead>";
      content += "<tbody>";

      // let startRow = i * maxRows;
      // let endRow = Math.min((i + 1) * maxRows, tableRows);

      data.map((group: any) =>
        group.map((item: any, index: any) => {
          content += "<tr>";
          index === 0 &&
            (content += `<td rowspan="${group.length}" style="text-align:center;">${item.po_no}</td>`);
          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("inward_no") &&
              index === 0 &&
              (content += `<td rowspan="${
                group.length
              }" style="text-align:center;">${
                item.inward_no ? item.inward_no : ""
              }</td>`);
          }
          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("inward_date") &&
              index === 0 &&
              (content += `<td rowspan="${
                group.length
              }" style="text-align:center;">${
                item?.inward_date
                  ? moment(item?.inward_date, "DD/MM/YYYY").format(
                      "DD MMM YYYY"
                    )
                  : ""
              }</td>`);
          }
          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("item_name") &&
              (content += `<td>${item.item_name}</td>`);
          }
          {
            formRenderProps.valueGetter("filter_columns")?.includes("grn_no_string") &&
              (content += `<td style="text-align:center;">${item.grn_no_string}</td>`);
          }
          {
            formRenderProps.valueGetter("filter_columns")?.includes("dagina") &&
              (content += `<td style="text-align:end;">${item.dagina}</td>`);
          }
          {
            formRenderProps.valueGetter("filter_columns")?.includes("weight") &&
              (content += `<td style="text-align:end;">${
                item.weight ? formatIndianNumberForQty(item.weight) : 0
              }</td>`);
          }
          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("unit_code") &&
              (content += `<td>${item.unit_code}</td>`);
          }
          {
            formRenderProps.valueGetter("filter_columns")?.includes("rate") &&
              (content += `<td style="text-align:end;">${
                item.rate ? formatIndianNumber(item.rate) : 0
              }</td>`);
          }
          {
            formRenderProps.valueGetter("filter_columns")?.includes("total") &&
              (content += `<td style="text-align:end;">${
                item.total ? formatIndianNumber(item.total) : 0
              }</td>`);
          }
          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("warehouse_name") &&
              (content += `<td>${item.warehouse_name}</td>`);
          }
          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("receipt_no") &&
              (content += `<td>${item.receipt_no}</td>`);
          }
          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("account_name") &&
              index === 0 &&
              (content += `<td rowspan="${group.length}">${item.account_name}</td>`);
          }
          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("chalan_no") &&
              index === 0 &&
              (content += `<td rowspan="${group.length}">${item.chalan_no}</td>`);
          }
          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("bill_no") &&
              index === 0 &&
              (content += `<td rowspan="${group.length}">${item.bill_no}</td>`);
          }
          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("bill_date") &&
              index === 0 &&
              (content += `<td rowspan="${
                group.length
              }" style="text-align:center;">${
                item?.bill_date
                  ? moment(item?.bill_date, "DD/MM/YYYY").format("DD MMM YYYY")
                  : ""
              }</td>`);
          }
          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("vehicle_no") &&
              index === 0 &&
              (content += `<td rowspan="${group.length}">${item.vehicle_no}</td>`);
          }
          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("vehicle_in_time") &&
              index === 0 &&
              (content += `<td rowspan="${group.length}">${item.vehicle_in_time}</td>`);
          }
          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("vehicle_out_time") &&
              index === 0 &&
              (content += `<td rowspan="${group.length}">${item.vehicle_out_time}</td>`);
          }
          {
            formRenderProps
              .valueGetter("filter_columns")
              ?.includes("remarks") &&
              index === 0 &&
              (content += `<td rowspan="${group.length}"><div className="remarks-content" >${item.remarks}</div> </td>`);
          }
          content += "</tr>";
        })
      );
      if (data && data?.length > 0) {
        content += '<tr style="background:whitesmoke; font-weight:600;">';

        content += `<td  style="text-align:center;">Total</td>`;
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("inward_no") &&
            (content += `<td  style="text-align:center;"></td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("inward_date") &&
            (content += `<td  style="text-align:center;"></td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("item_name") && (content += `<td></td>`);
        }
        {
          formRenderProps.valueGetter("filter_columns")?.includes("grn_no_string") &&
            (content += `<td style="text-align:center;"></td>`);
        }
        {
          formRenderProps.valueGetter("filter_columns")?.includes("dagina") &&
            (content += `<td style="text-align:end;"></td>`);
        }
        {
          formRenderProps.valueGetter("filter_columns")?.includes("weight") &&
            (content += `<td style="text-align:end;">${formatIndianNumberForQty(
              totalQty
            )}</td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("unit_code") && (content += `<td></td>`);
        }
        {
          formRenderProps.valueGetter("filter_columns")?.includes("rate") &&
            (content += `<td style="text-align:end;"></td>`);
        }
        {
          formRenderProps.valueGetter("filter_columns")?.includes("total") &&
            (content += `<td style="text-align:end;">${formatIndianNumber(
              totalAmount
            )}</td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("warehouse_name") && (content += `<td></td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("receipt_no") && (content += `<td></td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("account_name") && (content += `<td ></td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("chalan_no") && (content += `<td ></td>`);
        }
        {
          formRenderProps.valueGetter("filter_columns")?.includes("bill_no") &&
            (content += `<td ></td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("bill_date") &&
            (content += `<td  style="text-align:center;"></td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("vehicle_no") && (content += `<td ></td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("vehicle_in_time") && (content += `<td ></td>`);
        }
        {
          formRenderProps
            .valueGetter("filter_columns")
            ?.includes("vehicle_out_time") && (content += `<td ></td>`);
        }
        {
          formRenderProps.valueGetter("filter_columns")?.includes("remarks") &&
            (content += `<td ><div className="remarks-content" ></div> </td>`);
        }
        content += "</tr>";
      }

      content += "</tbody>";
      content += "</table>";

      content += "</div>";
    }

    let printWindow = window.open("", "_blank");
    if (!printWindow) {
      console.error("Failed to open print preview window.");
      return;
    }
    printWindow.document.write(`
      <html>
      <head>
        <title>Print Preview</title>
        <style>
          @page { margin: 1cm; }
          .print-page { page-break-after: always; }
          .table-bordered { width: 100%; border-collapse: collapse; }
          .table-bordered th, .table-bordered td { border: 1px solid black; padding: 8px; }
          body { font-family: Arial, sans-serif; }
        </style>
      </head>
      <body>
        ${content}
        <script>
          window.onload = function() {
            window.print();
          };
        </script>
      </body>
      </html>
    `);
    printWindow.document.close();

    if (printbuttons) printbuttons.style.display = "block";
    if (report_container) report_container.style.padding = "20px 10px";
  };

  return (
    <>
      {loading && <LoadingPanel gridRef={gridRef} />}
      {InwardRegisterList && InwardRegisterList.length ? (
        <Form
          initialValues={initialValues}
          render={(formRenderProps: FormRenderProps) => {
            const isMatchFound = GrandTotalArray?.some((subItem: any) =>
              formRenderProps.valueGetter("filter_columns")?.includes(subItem)
            );

            return (
              <FormElement
                style={{ width: "100%", minHeight: "calc(100vh - 290px)" }}
              >
                <GridLayout
                  //   style={{ marginRight: 30 }}
                  gap={{ rows: 10, cols: 10 }}
                  cols={[{ width: "100%" }]}
                >
                  <GridLayoutItem className="d-flex justify-content-between align-items-center">
                    <Typography.h4 className="m-0">
                      Inward Register List{" "}
                      {(InwardRegisterDetails?.start_date ||
                        InwardRegisterDetails?.end_Date) && (
                        <span style={{ fontSize: 13 }}>
                          {"("}
                          {InwardRegisterDetails?.start_date
                            ? moment(
                                InwardRegisterDetails?.start_date,
                                "YYYY-MM-DD"
                              ).format("DD/MM/YYYY")
                            : ""}
                          {InwardRegisterDetails?.start_date &&
                            InwardRegisterDetails?.end_Date &&
                            " - "}
                          {InwardRegisterDetails?.end_Date
                            ? moment(
                                InwardRegisterDetails?.end_Date,
                                "YYYY-MM-DD"
                              ).format("DD/MM/YYYY")
                            : ""}
                          {")"}
                        </span>
                      )}
                    </Typography.h4>
                    <div className="position-relative d-flex">
                      {isPrintAccess && (
                        <Button
                          style={{ marginRight: 5 }}
                          type="button"
                          fillMode={"solid"}
                          themeColor={"primary"}
                          onClick={() =>
                            printTable(groupedArray, formRenderProps)
                          }
                        >
                          Print
                        </Button>
                      )}
                      {isExcelAccess && (
                        <Button
                          type="button"
                          fillMode={"solid"}
                          themeColor={"primary"}
                          onClick={handleExportExcel}
                          style={{ marginRight: 5 }}
                        >
                          Excel Export
                        </Button>
                      )}
                      <Tooltip
                        anchorElement="target"
                        position="left"
                        parentTitle={true}
                      >
                        <Button
                          title="Filter Columns"
                          type="button"
                          themeColor={"primary"}
                          fillMode={"solid"}
                          onClick={() => setShowFilterColumns(true)}
                        >
                          Filter
                          <BsThreeDotsVertical />
                        </Button>
                      </Tooltip>
                      {showfilterColumns && (
                        <div
                          style={{
                            position: "absolute",
                            top: "32px",
                            right: "0",
                            background: "white",
                            padding: "12px",
                            border: "1px solid whitesmoke",
                            width: "250px",
                            zIndex: 2,
                          }}
                        >
                          <div className="d-flex justify-content-between align-items-baseline">
                            <p className="m-0 fw-600">Filter</p>
                            <Button
                              themeColor={"primary"}
                              fillMode={"flat"}
                              onClick={() => setShowFilterColumns(false)}
                            >
                              <RxCross2 />
                            </Button>
                          </div>
                          <hr className="m-1" style={{ color: "lightgray" }} />
                          <Field
                            wrapperClassName="d-flex flex-column checkBoxGroup-vertical"
                            inputClassName="checkBoxGroup-vertical-input"
                            id={"filter_columns"}
                            name={"filter_columns"}
                            marginRight={0}
                            marginBeetween={8}
                            component={FormCheckboxGroup}
                            options={columns}
                          />
                        </div>
                      )}
                    </div>
                  </GridLayoutItem>
                  <GridLayoutItem
                    style={{
                      maxHeight: "calc(100vh - 334px)",
                      overflow: "scroll",
                      scrollbarWidth: "thin",
                    }}
                  >
                    <table
                      cellSpacing={1}
                      cellPadding={2}
                      className="table table-bordered w-100  currentstock-print-table"
                      style={{
                        marginTop: 1,
                      }}
                    >
                      <tr
                        className="text-center fw-600"
                        style={{
                          fontSize: 12,
                          position: "sticky",
                          top: 0,
                          outline: "1px solid #3b3b3b",
                          zIndex: 1,
                        }}
                      >
                        <th style={{ minWidth: 100 }}>PO No.</th>
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("inward_no") && (
                          <th style={{ minWidth: 120 }}>Inward No.</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("inward_date") && (
                          <th style={{ minWidth: 150 }}>Inward Date</th>
                        )}

                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("item_name") && (
                          <th style={{ minWidth: 300 }}>Item Name</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("grn_no_string") && (
                          <th style={{ minWidth: 100 }}>GRN No.</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("dagina") && (
                          <th style={{ minWidth: 100 }}>Dagina</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("weight") && (
                          <th style={{ minWidth: 150 }}>Qty</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("unit_code") && (
                          <th style={{ minWidth: 120 }}>Unit</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("rate") && (
                          <th style={{ minWidth: 150 }}>Rate</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("total") && (
                          <th style={{ minWidth: 150 }}>Total</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("warehouse_name") && (
                          <th style={{ minWidth: 200 }}>Warehouse Name</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("receipt_no") && (
                          <th style={{ minWidth: 120 }}>Receipt No.</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("account_name") && (
                          <th style={{ minWidth: 250 }}>Account Name</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("chalan_no") && (
                          <th style={{ minWidth: 120 }}>Challan No.</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("bill_no") && (
                          <th style={{ minWidth: 120 }}>Bill No.</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("bill_date") && (
                          <th style={{ minWidth: 150 }}>Bill Date</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("vehicle_no") && (
                          <th style={{ minWidth: 150 }}>Vehicle No.</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("vehicle_in_time") && (
                          <th style={{ minWidth: 150 }}>Vehicle In Time</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("vehicle_out_time") && (
                          <th style={{ minWidth: 150 }}>Vehicle Out Time</th>
                        )}
                        {formRenderProps
                          .valueGetter("filter_columns")
                          ?.includes("remarks") && (
                          <th style={{ minWidth: 250 }}>Remarks</th>
                        )}
                      </tr>
                      {groupedArray &&
                        groupedArray?.length > 0 &&
                        groupedArray?.map((group: any) =>
                          group.map((item: any, index: number) => {
                            const groupKey = `${item?.inward_guid}`;
                            const isSelected = selectedGroup === groupKey;
                            return (
                              <tr
                                className="text-center"
                                style={{
                                  background: isSelected
                                    ? "rgb(255 198 198 / 53%)"
                                    : "transparent",
                                }}
                                onClick={() => handleRowClick(item.inward_guid)}
                              >
                                {index === 0 && (
                                  <td
                                    rowSpan={group.length}
                                    className="text-start register-edit-column"
                                  >
                                    {item.po_no}
                                    {isEditAccess && (
                                      <IconButton
                                        type="button"
                                        fillMode="flat"
                                        themeColor={"primary"}
                                        size="small"
                                        ref={isEditBtnRef}
                                        style={{
                                          padding: "15px",
                                          marginLeft: "5px",
                                        }}
                                        onClick={() => {
                                          isEditBtnRef.current = true;
                                          navigate("/inward/edit", {
                                            state: {
                                              inward_guid: item?.inward_guid,
                                            },
                                          });
                                        }}
                                      >
                                        <FiEdit
                                          className="absolute-position"
                                          style={{
                                            fontSize: "20px",
                                            zIndex: 0,
                                            color: "red",
                                          }}
                                        />
                                      </IconButton>
                                    )}
                                  </td>
                                )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("inward_no") &&
                                  index === 0 && (
                                    <td rowSpan={group.length}>
                                      {item.inward_no}
                                    </td>
                                  )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("inward_date") &&
                                  index === 0 && (
                                    <td rowSpan={group.length}>
                                      {item.inward_date
                                        ? moment(
                                            item.inward_date,
                                            "DD/MM/YYYY"
                                          )?.format("DD MMM YYYY")
                                        : ""}
                                    </td>
                                  )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("item_name") && (
                                  <td className="text-start">
                                    {item?.item_name}
                                  </td>
                                )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("grn_no_string") && (
                                  <td>{item?.grn_no_string}</td>
                                )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("dagina") && (
                                  <td>{item?.dagina}</td>
                                )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("weight") && (
                                  <td className="text-end">
                                    {formatIndianNumberForQty(item?.weight) ||
                                      0}
                                  </td>
                                )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("unit_code") && (
                                  <td>{item?.unit_code}</td>
                                )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("rate") && (
                                  <td className="text-end">
                                    {formatIndianNumber(item?.rate || 0)}
                                  </td>
                                )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("total") && (
                                  <td className="text-end">
                                    {formatIndianNumber(item?.total || 0)}
                                  </td>
                                )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("warehouse_name") && (
                                  <td className="text-start">
                                    {item.warehouse_name}
                                  </td>
                                )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("receipt_no") && (
                                  <td className="text-start">
                                    {item.receipt_no}
                                  </td>
                                )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("account_name") &&
                                  index === 0 && (
                                    <td
                                      rowSpan={group.length}
                                      className="text-start"
                                    >
                                      {item.account_name}
                                    </td>
                                  )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("chalan_no") &&
                                  index === 0 && (
                                    <td
                                      rowSpan={group.length}
                                      className="text-start"
                                    >
                                      {item.chalan_no}
                                    </td>
                                  )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("bill_no") &&
                                  index === 0 && (
                                    <td
                                      rowSpan={group.length}
                                      className="text-start"
                                    >
                                      {item.bill_no}
                                    </td>
                                  )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("bill_date") &&
                                  index === 0 && (
                                    <td rowSpan={group.length}>
                                      {item.bill_date
                                        ? moment(
                                            item.bill_date,
                                            "DD/MM/YYYY"
                                          )?.format("DD MMM YYYY")
                                        : ""}
                                    </td>
                                  )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("vehicle_no") &&
                                  index === 0 && (
                                    <td
                                      rowSpan={group.length}
                                      className="text-start"
                                    >
                                      {item.vehicle_no}
                                    </td>
                                  )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("vehicle_in_time") &&
                                  index === 0 && (
                                    <td
                                      rowSpan={group.length}
                                      className="text-start"
                                    >
                                      {item.vehicle_in_time}
                                    </td>
                                  )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("vehicle_out_time") &&
                                  index === 0 && (
                                    <td
                                      rowSpan={group.length}
                                      className="text-start"
                                    >
                                      {item.vehicle_out_time}
                                    </td>
                                  )}
                                {formRenderProps
                                  .valueGetter("filter_columns")
                                  ?.includes("remarks") &&
                                  index === 0 && (
                                    <td
                                      rowSpan={group.length}
                                      className="text-start"
                                    >
                                      <div
                                        className="remarks-content"
                                        dangerouslySetInnerHTML={{
                                          __html: item.remarks,
                                        }}
                                      />
                                    </td>
                                  )}
                              </tr>
                            );
                          })
                        )}
                      {groupedArray &&
                        groupedArray?.length > 0 &&
                        isMatchFound && (
                          <tr
                            className="text-center"
                            style={{
                              background: "whitesmoke",
                              fontWeight: 600,
                            }}
                          >
                            <td className="text-start ">Total</td>
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("inward_no") && <td></td>}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("inward_date") && <td></td>}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("item_name") && <td></td>}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("grn_no_string") && <td></td>}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("dagina") && <td></td>}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("weight") && (
                              <td className="text-end">
                                {formatIndianNumberForQty(totalQty)}
                              </td>
                            )}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("unit_code") && <td></td>}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("rate") && <td></td>}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("total") && (
                              <td className="text-end">
                                {formatIndianNumber(totalAmount)}
                              </td>
                            )}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("warehouse_name") && <td></td>}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("receipt_no") && <td></td>}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("account_name") && <td></td>}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("chalan_no") && <td></td>}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("bill_no") && <td></td>}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("bill_date") && <td></td>}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("vehicle_no") && <td></td>}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("vehicle_in_time") && <td></td>}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("vehicle_out_time") && <td></td>}
                            {formRenderProps
                              .valueGetter("filter_columns")
                              ?.includes("remarks") && <td></td>}
                          </tr>
                        )}
                    </table>
                  </GridLayoutItem>
                </GridLayout>
              </FormElement>
            );
          }}
        />
      ) : (
        <AlertBox />
      )}
    </>
  );
};
export default InwardRegisterReportForm;
