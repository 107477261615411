import React, { useCallback, useEffect } from "react";
import { Field, FieldArrayRenderProps } from "@progress/kendo-react-form";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { Typography } from "@progress/kendo-react-common";
import IconButton from "../../components/common/IconButton";
import { MdDelete } from "react-icons/md";
import { setSalesDeleteIndex } from "./salesSlice";
import {
  requiredValidator,
  // skipValidator,
} from "../../components/formFields/CommonValidator";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { IItem } from "../Item/itemModel";
import { useLocation } from "react-router-dom";
import { IUnit } from "../unit/unitModel";
import { IGst } from "../gst/gstModel";
import { getItemById } from "../Item/services/item.services";
import FormNumericTextField from "../../components/formFields/FormNumericTextField";
import FormIncrementalSearch from "../../components/formFields/FormIncrementalSearch";
import { axiosApiInstance } from "../../app/axios";
import { API_URL } from "../../app/config";
import { ErrorToast, SuccessToast } from "../../components/toast/Toasts";
import {
  getProductionLotNoByItemID,
  getProductionLotNoCurrentStock,
  getRetailerDistributorRates,
} from "../saleschallan/services/salesChallan.services";
import _ from "lodash";

const SalesItemDetailsArray = (
  fieldArrayRenderProps: FieldArrayRenderProps
) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const sale_guid = location.state?.sale_guid;
  const SalesDeleteIndex = useAppSelector(
    (state) => state.sales.SalesDeleteIndex
  );

  const InputRef = React.useRef<any>(null);

  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const UnitList = useAppSelector((state) => state.unit.UnitList);
  const GstList = useAppSelector((state) => state.gst.GstList);

  const handleOpenDeleteDialog = (index: number) => {
    dispatch(openDialog("salesItemDeleteDialog"));
    dispatch(setSalesDeleteIndex(index));
  };

  useEffect(() => {
    if (!sale_guid) {
      pushElementInarray();
    }
  }, []);

  const handleDeleteAction = React.useCallback(
    async (index: number) => {
      if (fieldArrayRenderProps?.value[index]?.id) {
        try {
          const response = await axiosApiInstance.post(
            `${API_URL}/Sale/DeleteSaleItem`,
            { id: fieldArrayRenderProps?.value[index]?.id }
          );
          fieldArrayRenderProps.onRemove({ index: index });
          dispatch(closeDialog());
          dispatch(setSalesDeleteIndex(-1));
          SuccessToast(response.data?.Details || "Success");
        } catch (error: any) {
          ErrorToast(error?.response?.data?.Details || "Something went wrong");
          console.error("Error deleting Item:", error);
          return Promise.reject({
            message: error?.response?.data?.Details || "Something went wrong",
          });
        }
      } else {
        fieldArrayRenderProps.onRemove({ index: index });
        dispatch(closeDialog());
        dispatch(setSalesDeleteIndex(-1));
      }
    },
    [fieldArrayRenderProps]
  );

  const pushElementInarray = React.useCallback(() => {
    fieldArrayRenderProps.onPush({
      value: {
        item_id: null,
        lot_no: null,
        mrp: null,
        quantity: null,
        free_quantity: null,
        unit_id: null,
        rate: null,
        vatav_per: null,
        gst_id: null,
        amount: null,
        item_description: "",
      },
    });

    setTimeout(() => {
      InputRef.current?.element?.children[0]?.children[1]?.children[0]?.focus();
    }, 0);
  }, [fieldArrayRenderProps]);

  const handleProductChange = async (
    e: any,
    innerIndex: number,
    fieldArrayRenderProps: FieldArrayRenderProps
  ) => {
    const vendor_id =
      fieldArrayRenderProps.formRenderProps.valueGetter("vendor_id");
    const sales_items =
      fieldArrayRenderProps.formRenderProps.valueGetter("sales_items");
    if (e?.value) {
      const payload = {
        id: e?.value,
      };
      const response: any = await dispatch(getItemById(payload));
      sales_items &&
        sales_items.length > 0 &&
        sales_items.map((item: any, index: number) => {
          if (innerIndex === index) {
            if (response.meta.requestStatus === "fulfilled") {
              fieldArrayRenderProps.formRenderProps.onChange(
                `sales_items.${index}.gst_id`,
                {
                  value: `${response.payload?.gst_id}--${
                    response.payload?.gst || "0"
                  }`,
                }
              );
              fieldArrayRenderProps.formRenderProps.onChange(
                `sales_items.${index}.unit_id`,
                {
                  value: response.payload?.unit_id,
                }
              );
              fieldArrayRenderProps.formRenderProps.onChange(
                `sales_items.${index}.unit_decimal`,
                {
                  value: response.payload?.unit_decimal || 0,
                }
              );
            } else {
              fieldArrayRenderProps.formRenderProps.onChange(
                `sales_items.${index}.gst_id`,
                {
                  value: null,
                }
              );
              fieldArrayRenderProps.formRenderProps.onChange(
                `sales_items.${index}.unit_id`,
                {
                  value: null,
                }
              );
              fieldArrayRenderProps.formRenderProps.onChange(
                `sales_items.${index}.unit_decimal`,
                {
                  value: 0,
                }
              );
            }
          }
        });

      const ratePayload = {
        item_id: e?.value,
        account_id: vendor_id,
      };
      const res: any = await dispatch(getRetailerDistributorRates(ratePayload));
      sales_items &&
        sales_items.length > 0 &&
        sales_items.map((item: any, index: number) => {
          if (innerIndex === index) {
            if (res.meta.requestStatus === "fulfilled") {
              fieldArrayRenderProps.formRenderProps.onChange(
                `sales_items.${index}.mrp`,
                {
                  value: res.payload?.mrp,
                }
              );
              fieldArrayRenderProps.formRenderProps.onChange(
                `sales_items.${index}.rate`,
                {
                  value: res.payload?.rate,
                }
              );
            } else {
              fieldArrayRenderProps.formRenderProps.onChange(
                `sales_items.${index}.mrp`,
                {
                  value: "",
                }
              );
              fieldArrayRenderProps.formRenderProps.onChange(
                `sales_items.${index}.rate`,
                {
                  value: "",
                }
              );
            }
          }
        });
    } else {
      sales_items &&
        sales_items.length > 0 &&
        sales_items.map((item: any, index: number) => {
          if (innerIndex === index) {
            fieldArrayRenderProps.formRenderProps.onChange(
              `sales_items.${index}.gst_id`,
              {
                value: null,
              }
            );
            fieldArrayRenderProps.formRenderProps.onChange(
              `sales_items.${index}.unit_id`,
              {
                value: null,
              }
            );
            fieldArrayRenderProps.formRenderProps.onChange(
              `sales_items.${index}.unit_decimal`,
              {
                value: 0,
              }
            );
            fieldArrayRenderProps.formRenderProps.onChange(
              `sales_items.${index}.mrp`,
              {
                value: "",
              }
            );
            fieldArrayRenderProps.formRenderProps.onChange(
              `sales_items.${index}.rate`,
              {
                value: "",
              }
            );
          }
        });
    }
  };

  const fetchLotNoptions = useCallback(
    async (index: number, item: any) => {
      if (item?.item_id) {
        const response = await dispatch(
          getProductionLotNoByItemID(item.item_id)
        );
        const result =
          response.payload
            ?.filter((item: any) => item.item_id === item.item_id)
            .map((lotno: any) => ({
              value: `${lotno.lot_no}`,
              label: `${lotno.lot_no}`,
            })) || [];
        fieldArrayRenderProps?.formRenderProps.onChange(
          `sales_items.${index}.lotNoOptions`,
          {
            value: result,
          }
        );
      } else {
        fieldArrayRenderProps?.formRenderProps.onChange(
          `sales_items.${index}.lotNoOptions`,
          {
            value: [],
          }
        );
        fieldArrayRenderProps?.formRenderProps.onChange(
          `sales_items.${index}.lot_no`,
          {
            value: null,
          }
        );
      }
    },
    [dispatch, fieldArrayRenderProps?.formRenderProps]
  );

  const fetchCurrentStock = useCallback(
    async (index: number, item: any) => {
      if (item?.item_id && item?.lot_no) {
        const payload = {
          item_id: +item.item_id,
          lot_no: item?.lot_no,
        };
        const response = await dispatch(
          getProductionLotNoCurrentStock(payload)
        );
        fieldArrayRenderProps?.formRenderProps.onChange(
          `sales_items.${index}.current_stock`,
          {
            value: response.payload?.actual_stock || 0,
          }
        );
      }
    },
    [dispatch, fieldArrayRenderProps?.formRenderProps]
  );

  const handleFieldChange = (field: string, index: number, item: any) => {
    switch (field) {
      case "item_id":
        fetchLotNoptions(index, item);
        fetchCurrentStock(index, item);
        break;
      case "lot_no":
        fetchCurrentStock(index, item);
        break;
      default:
        break;
    }
  };

  const debouncedHandleFieldChange = React.useRef(
    _.debounce((field: any, index: number, item: any) => {
      handleFieldChange(field, index, item);
    }, 300)
  ).current;
  const debouncedHandleProductChange = React.useRef(
    _.debounce((field: any, index: number, item: any) => {
      handleProductChange(field, index, item);
    }, 300)
  ).current;

  useEffect(() => {
    return () => {
      debouncedHandleFieldChange.cancel();
      debouncedHandleProductChange.cancel();
    };
  }, []);

  const handleChangeField = (field: any, index: number, item: any) => {
    debouncedHandleFieldChange(field, index, item);
  };
  const handleChangeProduct = (field: any, index: number, item: any) => {
    debouncedHandleProductChange(field, index, item);
  };

  return (
    <>
      <GridLayout
        cols={[{ width: "100%" }]}
        style={{ overflowX: "scroll", scrollbarWidth: "thin" }}
      >
        <GridLayoutItem
          className="bg-dark text-white fw-600"
          style={{ width: 2800 }}
        >
          <GridLayout
            style={{
              marginRight: "0.2%",
              padding: 10,
            }}
            gap={{ rows: 10, cols: 5 }}
            cols={[
              { width: "12%" },
              { width: "5%" },
              { width: "5%" },
              { width: "5%" },
              { width: "5%" },
              { width: "5%" },
              { width: "5%" },
              { width: "5%" },
              { width: "5%" },
              { width: "5%" },

              { width: "3%" },
              { width: "5%" },
              { width: "3%" },
              { width: "5%" },
              { width: "3%" },
              { width: "5%" },

              { width: "6%" },

              { width: "7%" },
              { width: "3%" },
            ]}
          >
            <GridLayoutItem>Item</GridLayoutItem>
            <GridLayoutItem>Lot No</GridLayoutItem>
            <GridLayoutItem>MRP</GridLayoutItem>
            <GridLayoutItem>Current Stock</GridLayoutItem>
            <GridLayoutItem>Quantity</GridLayoutItem>
            <GridLayoutItem>Free Quantity</GridLayoutItem>
            <GridLayoutItem>Unit</GridLayoutItem>
            <GridLayoutItem>Rate</GridLayoutItem>
            <GridLayoutItem>Vatav (%)</GridLayoutItem>
            <GridLayoutItem>GST</GridLayoutItem>

            <GridLayoutItem>CGST %</GridLayoutItem>
            <GridLayoutItem>CGST</GridLayoutItem>
            <GridLayoutItem>SGST %</GridLayoutItem>
            <GridLayoutItem>SGST</GridLayoutItem>
            <GridLayoutItem>IGST %</GridLayoutItem>
            <GridLayoutItem>IGST</GridLayoutItem>

            <GridLayoutItem>Total GST</GridLayoutItem>

            <GridLayoutItem>Amount</GridLayoutItem>
            <GridLayoutItem></GridLayoutItem>
          </GridLayout>
        </GridLayoutItem>
        {dialogName === "salesItemDeleteDialog" && (
          <AppDialog>
            <>
              <Typography.h5>{"Delete Item"}</Typography.h5>
              <GridLayout>
                <GridLayoutItem>
                  <Typography.p>
                    Are you sure you want to delete this?
                  </Typography.p>
                </GridLayoutItem>
                <GridLayoutItem
                  style={{
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <DialogCloseButton themeColor="error" />
                  <Button
                    fillMode={"solid"}
                    themeColor={"error"}
                    onClick={() => handleDeleteAction(SalesDeleteIndex)}
                    type="button"
                  >
                    Delete
                  </Button>
                </GridLayoutItem>
              </GridLayout>
            </>
          </AppDialog>
        )}
        {fieldArrayRenderProps.value &&
          fieldArrayRenderProps.value.length > 0 &&
          fieldArrayRenderProps.value.map((sales_items: any, index: number) => (
            <GridLayoutItem key={index} style={{ width: 2800 }}>
              <GridLayout
                style={{
                  marginRight: "0.2%",
                  padding: 10,
                  position: "relative",
                }}
                gap={{ rows: 10, cols: 5 }}
                cols={[
                  { width: "12%" },
                  { width: "5%" },
                  { width: "5%" },
                  { width: "5%" },
                  { width: "5%" },
                  { width: "5%" },
                  { width: "5%" },
                  { width: "5%" },
                  { width: "5%" },
                  { width: "5%" },

                  { width: "3%" },
                  { width: "5%" },
                  { width: "3%" },
                  { width: "5%" },
                  { width: "3%" },
                  { width: "5%" },

                  { width: "6%" },

                  { width: "7%" },
                  { width: "3%" },
                ]}
              >
                <GridLayoutItem ref={InputRef}>
                  <Field
                    wrapperStyle={{ margin: 0 }}
                    name={`sales_items.${index}.item_id`}
                    placeholder="Product"
                    isAddNew={true}
                    addNewLink="item/create"
                    component={FormIncrementalSearch}
                    validator={requiredValidator}
                    onChange={(e: any) => {
                      handleChangeField(
                        `item_id`,
                        index,
                        fieldArrayRenderProps.value[index]
                      );
                      handleChangeProduct(e, index, fieldArrayRenderProps);
                    }}
                    fetchIncrementalData={(search: any) =>
                      fieldArrayRenderProps.handleItemSearchChange(
                        search,
                        `sales_items.${index}.item_options`,
                        fieldArrayRenderProps.formRenderProps
                      )
                    }
                    options={
                      fieldArrayRenderProps.value[index]?.item_options?.map(
                        (item: IItem) => {
                          return {
                            value: item.id,
                            label: item.product_name,
                          };
                        }
                      ) || []
                    }
                  />
                  {/* <Field
                    wrapperStyle={{ marginTop: 1 }}
                    textBoxStyle={{ fontSize: 11 }}
                    name={`sales_items.${index}.item_description`}
                    placeholder="Item Description"
                    component={FormTextField}
                  /> */}
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperStyle={{ margin: 0 }}
                    name={`sales_items.${index}.lot_no`}
                    placeholder="Lot No"
                    component={FormSelectionField}
                    onChange={() =>
                      handleFieldChange(
                        `lot_no`,
                        index,
                        fieldArrayRenderProps.value[index]
                      )
                    }
                    // validator={requiredValidator}
                    options={
                      fieldArrayRenderProps.value[index]?.lotNoOptions || []
                    }
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="right-alighned-field"
                    wrapperStyle={{ margin: 0 }}
                    name={`sales_items.${index}.mrp`}
                    placeholder="0"
                    type="number"
                    minNumber="0"
                    // validator={requiredValidator}
                    component={FormNumericTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="right-alighned-field"
                    wrapperStyle={{ margin: 0 }}
                    name={`sales_items.${index}.current_stock`}
                    placeholder="0"
                    min="0"
                    format="n3"
                    disabled={true}
                    component={FormNumericTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="right-alighned-field"
                    wrapperStyle={{ margin: 0 }}
                    name={`sales_items.${index}.quantity`}
                    placeholder="0"
                    min="0"
                    format={
                      fieldArrayRenderProps.value[index]?.unit_decimal &&
                      fieldArrayRenderProps.value[index]?.unit_decimal > 0
                        ? `n${fieldArrayRenderProps.value[index]?.unit_decimal}`
                        : "n0"
                    }
                    // max={fieldArrayRenderProps.formRenderProps.valueGetter(
                    //   `sales_items.${index}.current_stock`
                    // )}
                    // validator={
                    //   !fieldArrayRenderProps.formRenderProps.valueGetter(
                    //     `sales_items.${index}.free_quantity`
                    //   )
                    //     ? requiredValidator
                    //     : skipValidator
                    // }
                    component={FormNumericTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="right-alighned-field"
                    wrapperStyle={{ margin: 0 }}
                    name={`sales_items.${index}.free_quantity`}
                    placeholder="0"
                    min="0"
                    // max={fieldArrayRenderProps.formRenderProps.valueGetter(
                    //   `sales_items.${index}.current_stock`
                    // )}
                    // validator={
                    //   !fieldArrayRenderProps.formRenderProps.valueGetter(
                    //     `sales_items.${index}.quantity`
                    //   )
                    //     ? requiredValidator
                    //     : skipValidator
                    // }
                    format="n3"
                    component={FormNumericTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperStyle={{ margin: 0 }}
                    name={`sales_items.${index}.unit_id`}
                    placeholder="Unit"
                    component={FormSelectionField}
                    // validator={requiredValidator}
                    options={UnitList?.map((unit: IUnit) => {
                      return {
                        value: unit?.id,
                        label: unit?.unit_code,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="right-alighned-field"
                    wrapperStyle={{ margin: 0 }}
                    name={`sales_items.${index}.rate`}
                    placeholder="0"
                    min="0"
                    // validator={requiredValidator}
                    component={FormNumericTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem style={{ position: "relative" }}>
                  <Field
                    // wrapperClassName="right-alighned-field"
                    wrapperStyle={{ margin: 0 }}
                    name={`sales_items.${index}.vatav_per`}
                    placeholder="0.00 %"
                    format="n2"
                    min="0"
                    component={FormNumericTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperStyle={{ margin: 0 }}
                    name={`sales_items.${index}.gst_id`}
                    placeholder="GST (%)"
                    component={FormSelectionField}
                    // validator={requiredValidator}
                    options={GstList?.map((gst: IGst) => {
                      return {
                        value: `${gst?.id}--${gst?.gst}`,
                        label: gst?.gst_title,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="right-alighned-field"
                    wrapperStyle={{ margin: 0 }}
                    name={`sales_items.${index}.cgst_per`}
                    placeholder="0"
                    min="0"
                    disabled
                    component={FormNumericTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="right-alighned-field"
                    wrapperStyle={{ margin: 0 }}
                    name={`sales_items.${index}.cgst_amount`}
                    placeholder="0"
                    min="0"
                    component={FormNumericTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="right-alighned-field"
                    wrapperStyle={{ margin: 0 }}
                    name={`sales_items.${index}.sgst_per`}
                    placeholder="0"
                    min="0"
                    disabled
                    component={FormNumericTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="right-alighned-field"
                    wrapperStyle={{ margin: 0 }}
                    name={`sales_items.${index}.sgst_amount`}
                    placeholder="0"
                    min="0"
                    component={FormNumericTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="right-alighned-field"
                    wrapperStyle={{ margin: 0 }}
                    name={`sales_items.${index}.igst_per`}
                    placeholder="0"
                    min="0"
                    disabled
                    component={FormNumericTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="right-alighned-field"
                    wrapperStyle={{ margin: 0 }}
                    name={`sales_items.${index}.igst_amount`}
                    placeholder="0"
                    min="0"
                    component={FormNumericTextField}
                  />
                </GridLayoutItem>

                <GridLayoutItem>
                  <Field
                    wrapperClassName="right-alighned-field"
                    wrapperStyle={{ margin: 0 }}
                    name={`sales_items.${index}.total_gst_amount`}
                    placeholder="0"
                    min="0"
                    component={FormNumericTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="right-alighned-field"
                    wrapperStyle={{ margin: 0 }}
                    name={`sales_items.${index}.amount`}
                    placeholder="0"
                    format="n2"
                    disabled={true}
                    component={FormNumericTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  style={{
                    position: "sticky",
                    top: 0,
                    right: 0,
                  }}
                >
                  <IconButton
                    onClick={() => handleOpenDeleteDialog(index)}
                    disabled={fieldArrayRenderProps.value.length === 1}
                    themeColor={"error"}
                    size={"small"}
                    fillMode={"solid"}
                    type="button"
                    style={{
                      position: "absolute",
                      height: 32,
                      width: 32,
                      top: 0,
                      right: 0,
                    }}
                  >
                    <MdDelete
                      className="absolute-position"
                      style={{ fontSize: "16px" }}
                    />
                  </IconButton>
                </GridLayoutItem>
              </GridLayout>
            </GridLayoutItem>
          ))}
      </GridLayout>
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{ marginTop: 10, borderTop: "1px solid lightgray" }}
        >
          <GridLayout
            style={{ marginRight: 45, padding: 10 }}
            gap={{ rows: 10, cols: 5 }}
            cols={[
              { width: "25%" },
              { width: "10%" },
              { width: "10%" },
              { width: "15%" },
              { width: "10%" },
              { width: "15%" },
              { width: "10%" },
              { width: "5%" },
            ]}
          >
            <GridLayoutItem colSpan={2} className="d-flex align-items-end">
              <Button
                fillMode={"solid"}
                themeColor={"primary"}
                onClick={pushElementInarray}
                type="button"
              >
                Add Row
              </Button>
            </GridLayoutItem>
            {/* <GridLayoutItem
              colSpan={4}
              className="d-flex justify-content-end align-items-center fw-600"
            >
              Total
            </GridLayoutItem>
            <GridLayoutItem colSpan={2}>
              <Field
                wrapperClassName="right-alighned-field"
                wrapperStyle={{ margin: 0 }}
                name="taxable_amount"
                format="n2"
                component={FormNumericTextField}
                disabled="true"
              />
            </GridLayoutItem> */}
          </GridLayout>
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

export default SalesItemDetailsArray;
