import React, { useEffect, useRef } from "react";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import { Typography } from "@progress/kendo-react-common";
import ShadowCard from "../../../components/common/ShadowCard";
import {
  clearSalaryRegisterDetails,
  clearSalaryRegisterList,
  setSalaryRegisterReport,
} from "../reportsSlice";
import {
  Grid as KendoGrid,
  GridColumn as Column,
  GridCustomCellProps,
  GridCustomHeaderCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
} from "@progress/kendo-react-grid";
import {
  CompositeFilterDescriptor,
  filterBy,
} from "@progress/kendo-data-query";
// import { setCurrentPage, setPageLimit } from "../../pagination/paginationSlice";
import {
  HeaderThElement,
  PagerTargetEvent,
} from "@progress/kendo-react-data-tools";
import AlertBox from "../../../components/common/AlertBox";
import { LoadingPanel } from "../../../components/layout/Loading";
import { requiredValidator } from "../../../components/formFields/CommonValidator";
import { Button } from "@progress/kendo-react-buttons";
import {
  checkAcessRights,
  getYearsArray,
  // useResponsiveJSX,
} from "../../../_helper/helper";
import { MONTHS, PRINT_ACCESS } from "../../../_contstants/common";
import { clearItemList } from "../../Item/itemSlice";
import FormSelectionField from "../../../components/formFields/FormSelectionField";
import { getSalaryRegisterReport } from "../services/reports.services";
import { ISalaryRegisterDetails } from "../reportsModel";
import { getAllEmployeeLocation } from "../../employee/services/employee.services";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}

const DateChangeWatcher = ({ formRenderProps }: FormChangeWatcherProps) => {
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();

  const previousMonth = currentMonth === 1 ? 12 : currentMonth - 1;
  const previousYear = currentMonth === 1 ? currentYear - 1 : currentYear;
  useEffect(() => {
    formRenderProps.onChange("payout_month", {
      value: previousMonth,
    });
    formRenderProps.onChange("payout_year", {
      value: `${previousYear}`,
    });
  }, []);
  return null;
};

const WageRegisterReportForm: React.FC = () => {
  const gridRef = React.useRef<any>(null);
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.reports.loading);
  const EmployeeLocationList = useAppSelector(
    (state) => state.employee.EmployeeLocationList
  );
  const SalaryRegisterList = useAppSelector(
    (state) => state.reports.SalaryRegisterList
  );
  const SalaryRegisterDetails = useAppSelector(
    (state) => state.reports.SalaryRegisterDetails
  );

  useEffect(() => {
    dispatch(getAllEmployeeLocation());
    return () => {
      dispatch(clearSalaryRegisterDetails());
      dispatch(clearSalaryRegisterList());
      dispatch(clearItemList());
    };
  }, []);

  const handleSubmit = (values: any) => {
    const payload = {
      location: values.location,
      payout_month_year: `${String(values.payout_month).padStart(2, "0")}-${
        values.payout_year
      }`,
    };
    dispatch(
      setSalaryRegisterReport({
        location: values.location,
        payout_year: values.payout_year,
        payout_month: values.payout_month,
      })
    );
    dispatch(getSalaryRegisterReport(payload));
  };
  return (
    <GridLayout gap={{ rows: 10 }} cols={[{ width: "100%" }]}>
      <GridLayoutItem>
        <ShadowCard>
          <Form
            initialValues={SalaryRegisterDetails}
            onSubmit={handleSubmit}
            render={(formRenderProps: FormRenderProps) => (
              <FormElement style={{ width: "100%" }}>
                <GridLayout
                  style={{ marginRight: 10 }}
                  gap={{ rows: 10, cols: 10 }}
                  cols={[
                    { width: "33.33%" },
                    { width: "33.33%" },
                    { width: "33.33%" },
                  ]}
                >
                  <GridLayoutItem colSpan={3}>
                    <Typography.h4
                      style={{ marginBottom: 0, marginLeft: "3px" }}
                    >
                      Wage Register Report
                    </Typography.h4>
                  </GridLayoutItem>
                  <GridLayoutItem className="d-flex  gap-2">
                    <Field
                      name="payout_month"
                      label="Payout Month"
                      placeholder="Payout Month"
                      component={FormSelectionField}
                      wrapperClassName="w-100"
                      options={MONTHS.map((month: any) => ({
                        value: month.value,
                        label: month.label,
                      }))}
                      validator={requiredValidator}
                      astrike={true}
                    />
                    <Field
                      name="payout_year"
                      label="Payout Year"
                      placeholder="Payout Year"
                      wrapperClassName="w-100"
                      component={FormSelectionField}
                      options={getYearsArray(2000, new Date().getFullYear() + 1)
                        ?.reverse()
                        ?.map((year: any) => {
                          return {
                            value: year?.value,
                            label: year?.label,
                          };
                        })}
                      validator={requiredValidator}
                      astrike={true}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="location"
                      label="Location"
                      placeholder="Select Location"
                      component={FormSelectionField}
                      options={EmployeeLocationList.map((loc: any) => ({
                        value: loc.location,
                        label: loc.location,
                      }))}
                    />
                  </GridLayoutItem>
                  <DateChangeWatcher formRenderProps={formRenderProps} />
                  <GridLayoutItem>
                    <div style={{ marginTop: "35px" }}>
                      <ButtonWithLoading
                        label={"View"}
                        type="submit"
                        disabled={!formRenderProps.allowSubmit || loading}
                        loading={loading}
                      />
                    </div>
                  </GridLayoutItem>
                </GridLayout>
              </FormElement>
            )}
          />
        </ShadowCard>
        {loading ? (
          <LoadingPanel gridRef={gridRef} />
        ) : (
          SalaryRegisterList &&
          SalaryRegisterList.length > 0 && (
            <ShadowCard style={{ marginTop: 10 }}>
              <WageRegisterViewGridComponent />
            </ShadowCard>
          )
        )}
      </GridLayoutItem>
    </GridLayout>
  );
};

interface PageState {
  skip: number;
  take: number;
}

const initialDataState: PageState = { skip: 0, take: 50 };

const WageRegisterViewGridComponent: React.FC = () => {
  const SalaryRegisterList = useAppSelector(
    (state) => state.reports.SalaryRegisterList
  );
  const SalaryRegisterDetails = useAppSelector(
    (state) => state.reports.SalaryRegisterDetails
  );
  const isPrintAccess = checkAcessRights(location.pathname, PRINT_ACCESS);
  const loading = useAppSelector((state) => state.retailer.loading);
  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);
  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();
  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All" ? SalaryRegisterList.length : event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
    setPage({ skip: 0, take: page.take });
  };

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      // className={`table-header ${props.index === 0 ? "locked-header" : ""}`}
      className={`table-header `}
    >
      {props.children}
    </HeaderThElement>
  );
  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );
  const gridRef = useRef<any>(null);
  if (loading) return <LoadingPanel gridRef={gridRef} />;

  const handlePrint = (props: ISalaryRegisterDetails, type: string) => {
    const state = {
      location: props.location,
      payout_month: props.payout_month,
      payout_year: props.payout_year,
    };
    if (type === "wageRegisterReport") {
      sessionStorage.setItem("wageregisterState", JSON.stringify(state));
      const reportTab = window.open("/wageregister", "_blank");
      const interval = setInterval(() => {
        if (reportTab?.closed) {
          sessionStorage.removeItem("wageregisterState");
          clearInterval(interval);
        }
      }, 1000);
    }
  };
  return (
    <>
      {SalaryRegisterList && SalaryRegisterList.length ? (
        <GridLayout
          //   style={{ marginRight: 30 }}
          gap={{ rows: 10, cols: 10 }}
          cols={[{ width: "100%" }]}
        >
          <GridLayoutItem className="d-flex justify-content-between align-items-center">
            <Typography.h4 className="m-0">Wage Register Report</Typography.h4>
            <div>
              {isPrintAccess && (
                <Button
                  style={{ marginRight: 5 }}
                  type="button"
                  fillMode={"solid"}
                  themeColor={"primary"}
                  onClick={() =>
                    handlePrint(SalaryRegisterDetails, "wageRegisterReport")
                  }
                >
                  Print Wage Register
                </Button>
              )}
            </div>
          </GridLayoutItem>
          <GridLayoutItem>
            <KendoGrid
              style={{ maxHeight: "calc(100vh - 152px)" }}
              className="responsive-table"
              filterable={true}
              filter={filter}
              data={
                filter
                  ? filterBy(SalaryRegisterList, filter).slice(
                      page.skip,
                      page.take + page.skip
                    )
                  : SalaryRegisterList.slice(page.skip, page.take + page.skip)
              }
              onFilterChange={handleFilterChange}
              skip={page.skip}
              take={page.take}
              total={SalaryRegisterList.length}
              pageable={{
                buttonCount: 5,
                pageSizes: [10, 25, 50, "All"],
                pageSizeValue: pageSizeValue,
                type: "input",
              }}
              onPageChange={pageChange}
              cells={{
                headerCell: HeaderCustomCell,
                data: MyDataCustomCell,
              }}
            >
              <Column
                field="employee_code_string"
                title="Employee Code"
                width={150}
              />
              <Column field="employee_name" title="Employee Name" width={300} />

              <Column
                field="designation_name"
                title="Designation"
                width={150}
              />
              <Column field="pf_no" title="PF No" width={200} />
              <Column field="uan_no" title="UAN No" width={150} />
              <Column field="bank_name" title="Bank Name" width={300} />
              <Column field="account_no" title="Account No" width={150} />
              <Column field="ifsc_code" title="IFSC" width={150} />

              <Column field="working_days" title="WD" width={150} />
              <Column field="weekly_off" title="WO" width={150} />
              <Column field="present_days" title="PD" width={150} />
              <Column field="ph" title="PH" width={150} />

              <Column
                field="actual_basic"
                title="Actual Basic"
                cell={(props: any) => (
                  <td className="text-end">{props.dataItem?.actual_basic}</td>
                )}
                width={150}
              />
              <Column
                field="actual_da"
                title="Actual DA"
                cell={(props: any) => (
                  <td className="text-end">{props.dataItem?.actual_da}</td>
                )}
                width={150}
              />
              <Column
                field="actual_hra"
                title="Actual HRA"
                cell={(props: any) => (
                  <td className="text-end">{props.dataItem?.actual_hra}</td>
                )}
                width={150}
              />
              <Column
                field="basic"
                title="Basic"
                width={150}
                cell={(props: any) => (
                  <td className="text-end">{props.dataItem?.basic}</td>
                )}
              />
              <Column
                field="da"
                title="DA"
                width={150}
                cell={(props: any) => (
                  <td className="text-end">{props.dataItem?.da}</td>
                )}
              />
              <Column
                field="hra"
                title="HRA"
                width={150}
                cell={(props: any) => (
                  <td className="text-end">{props.dataItem?.hra}</td>
                )}
              />

              <Column
                field="total_income"
                title="Gross Salary"
                cell={(props: any) => (
                  <td className="text-end">{props.dataItem?.total_income}</td>
                )}
                width={150}
              />

              <Column
                field="pf"
                title="PF"
                width={150}
                cell={(props: any) => (
                  <td className="text-end">{props.dataItem?.pf}</td>
                )}
              />
              <Column
                field="esi"
                title="ESI"
                width={150}
                cell={(props: any) => (
                  <td className="text-end">{props.dataItem?.esi}</td>
                )}
              />
              <Column
                field="pt"
                title="PT"
                width={150}
                cell={(props: any) => (
                  <td className="text-end">{props.dataItem?.pt}</td>
                )}
              />
              <Column
                field="it"
                title="IT"
                width={150}
                cell={(props: any) => (
                  <td className="text-end">{props.dataItem?.it}</td>
                )}
              />
              <Column
                field="lwf"
                title="LWF"
                width={150}
                cell={(props: any) => (
                  <td className="text-end">{props.dataItem?.lwf}</td>
                )}
              />
              <Column
                field="tds"
                title="TDS"
                width={150}
                cell={(props: any) => (
                  <td className="text-end">{props.dataItem?.tds}</td>
                )}
              />
              <Column
                field="advance"
                title="Advance"
                width={150}
                cell={(props: any) => (
                  <td className="text-end">{props.dataItem?.advance}</td>
                )}
              />
              <Column
                field="loan"
                title="Loan"
                width={150}
                cell={(props: any) => (
                  <td className="text-end">{props.dataItem?.loan}</td>
                )}
              />
              <Column
                field="other"
                title="Other Deduction"
                width={150}
                cell={(props: any) => (
                  <td className="text-end">{props.dataItem?.other}</td>
                )}
              />
              <Column
                field="total_deduction"
                title="Total Deduction"
                width={150}
                cell={(props: any) => (
                  <td className="text-end">
                    {props.dataItem?.total_deduction}
                  </td>
                )}
              />
              <Column
                field="payable_amount"
                title="Net Payment"
                width={150}
                cell={(props: any) => (
                  <td className="text-end">{props.dataItem?.payable_amount}</td>
                )}
              />
            </KendoGrid>
          </GridLayoutItem>
        </GridLayout>
      ) : (
        <AlertBox />
      )}
    </>
  );
};

export default WageRegisterReportForm;
