import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import moment from "moment";

export const getAllSalesOrders = createAsyncThunk(
  "SaleOrder/FindAllSaleOrder",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SaleOrder/FindAllSaleOrder`,
        formData
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Sales Orders:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllLimitedSalesOrders = createAsyncThunk(
  "SaleOrder/GetAllSaleOrder",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SaleOrder/GetAllSaleOrder`,
        formData
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Sales Orders:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createSalesOrder = createAsyncThunk(
  "SaleOrder/InsertSaleOrder",
  async (SalesOrderData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SaleOrder/InsertSaleOrder`,
        SalesOrderData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating sales order:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateSalesOrder = createAsyncThunk(
  "SaleOrder/UpdateSaleOrder",
  async (SalesOrderData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SaleOrder/UpdateSaleOrder`,
        SalesOrderData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating Sales Order:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteSalesOrder = createAsyncThunk(
  "SaleOrder/DeleteSaleOrder",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SaleOrder/DeleteSaleOrder`,
        { sale_order_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting Sales Order:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getSalesOrderByID = createAsyncThunk(
  "SaleOrder/FindByIDSaleOrder",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SaleOrder/FindByIDSaleOrder`,
        formData
      );
      const result = response.data?.Data as any;
      return {
        id: result?.id,
        sale_order_guid: result?.sale_order_guid,
        sales_order_no: result?.sales_order_no,
        sales_date: result?.sales_date
          ? moment(result?.sales_date, "YYYY-MM-DD").toDate()
          : "",
        dealer_id: result?.dealer_id,
        financial_year: result?.financial_year,
        total_amount: result?.total_amount,
        total_gst: result?.total_gst,
        gst: result?.gst,
        cgst: result?.cgst,
        sgst: result?.sgst,
        igst: result?.igst,
        card_scheme: result?.card_scheme,
        free_scheme: result?.free_scheme,
        vendor_options: result?.vendor_options
          ? result?.vendor_options?.map((vendor: any) => {
              return {
                id: vendor?.id ? +vendor?.id : null,
                account_name: vendor?.account_name ? vendor?.account_name : "",
              };
            })
          : [],
        sales_order_items: result?.sales_order_items
          ? result?.sales_order_items?.map((salesitem: any) => {
              return {
                id: salesitem?.id ? +salesitem?.id : null,
                sale_order_id: salesitem?.sale_order_id
                  ? +salesitem?.sale_order_id
                  : null,
                item_id: salesitem?.item_id ? +salesitem?.item_id : null,
                mrp: salesitem?.mrp ? +salesitem?.mrp : null,
                quantity: salesitem?.quantity ? +salesitem?.quantity : null,
                unit_id: salesitem?.unit_id ? +salesitem?.unit_id : null,
                unit_decimal: salesitem?.unit_decimal
                  ? +salesitem?.unit_decimal
                  : 0,
                rate: salesitem?.rate ? +salesitem?.rate : null,
                gst_id: salesitem?.gst_id
                  ? `${salesitem?.gst_id}--${salesitem?.gst || "0"}`
                  : null,
                amount: salesitem?.amount ? +salesitem?.amount : null,
                lot_no_list: salesitem?.lot_no_list
                  ? salesitem?.lot_no_list
                  : [],
                item_options: salesitem?.item_options
                  ? salesitem?.item_options?.map((item: any) => {
                      return {
                        id: item?.id ? +item?.id : null,
                        product_name: item?.product_name
                          ? item?.product_name
                          : "",
                      };
                    })
                  : [],
              };
            })
          : [],
      } as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching Sales Order:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getSalesOrderPrint = createAsyncThunk(
  "SaleOrder/SaleRegisterPrint",
  async (sale_order_guid: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SaleOrder/SaleRegisterPrint`,
        { sale_order_guid: sale_order_guid }
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Sales Order Print:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getSalesOrderChildList = createAsyncThunk(
  "SaleOrder/SaleOrderChildList",
  async (id: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SaleOrder/SaleOrderChildList`,
        { id: id }
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Sales Orders:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
