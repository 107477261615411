import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  filterBy,
  CompositeFilterDescriptor,
} from "@progress/kendo-data-query";
import {
  Grid as KendoGrid,
  GridColumn as Column,
  GridCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  GridCustomHeaderCellProps,
  GridCustomCellProps,
  GridRowProps,
} from "@progress/kendo-react-grid";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { HeaderThElement } from "@progress/kendo-react-data-tools";
import { Typography } from "@progress/kendo-react-common";
import { Button } from "@progress/kendo-react-buttons";
import { FiEdit } from "react-icons/fi";
import { FaEye } from "react-icons/fa";
import AlertBox from "../../components/common/AlertBox";
import { LoadingPanel } from "../../components/layout/Loading";
import IconButton from "../../components/common/IconButton";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { MdDelete } from "react-icons/md";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import {
  deleteInward,
  getAllInwards,
  getInwardByID,
} from "./services/inward.services";
import {
  clearFilterCustomInward,
  clearInwardDetails,
  clearInwardID,
  setFilterCustomInward,
  setInwardID,
} from "./inwardSlice";
import { setCurrentPage, setPageLimit } from "../pagination/paginationSlice";
import {
  checkAcessRights,
  formatIndianNumber,
  formatIndianNumberForQty,
} from "../../_helper/helper";
import {
  DELETE_ACCESS,
  EDIT_ACCESS,
  FINANCIAL_YEAR,
  INSERT_ACCESS,
} from "../../_contstants/common";
import { clearItemList } from "../Item/itemSlice";
import { TextBox } from "@progress/kendo-react-inputs";
import { filterClearIcon } from "@progress/kendo-svg-icons";
import _debounce from "lodash/debounce";

const Inward: React.FC = () => {
  const navigate = useNavigate();
  const isAddAccess = checkAcessRights(location.pathname, INSERT_ACCESS);

  const handleCreate = () => {
    navigate("/inward/create");
  };

  return (
    <>
      <DeleteInwardDialog />
      <InwardChildItemDialog />
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "4px 20px 20px 0",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            Inward List
          </Typography.h4>
          {isAddAccess && (
            <Button
              onClick={handleCreate}
              fillMode={"solid"}
              themeColor={"primary"}
            >
              {"Add New"}
            </Button>
          )}
        </GridLayoutItem>
        <GridLayoutItem>
          <InwardGridComponent />
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

const InwardGridComponent: React.FC = () => {
  const gridRef = useRef<any>(null);
  const isEditBtnRef = useRef<any>(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isEditAccess = checkAcessRights(location.pathname, EDIT_ACCESS);
  const isDeleteAccess = checkAcessRights(location.pathname, DELETE_ACCESS);

  const InwardList = useAppSelector((state) => state.inward.InwardList);
  const loading = useAppSelector((state) => state.inward.loading);
  const FilterCustomInward: any = useAppSelector(
    (state) => state.inward.FilterCustomInward
  );
  const { currentPage, pageLimit } = useAppSelector(
    (state) => state.pagination
  );

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);

  useEffect(() => {
    return () => {
      dispatch(clearItemList());
      dispatch(clearInwardDetails());
      dispatch(setCurrentPage(0));
      if (!isEditBtnRef.current) {
        dispatch(clearFilterCustomInward());
      }
    };
  }, []);

  useEffect(() => {
    const payload = {
      ...FilterCustomInward,
      financial_year: FINANCIAL_YEAR,
      page_no: +currentPage + 1,
      page_size: +pageLimit,
    };
    dispatch(getAllInwards(payload));
  }, [currentPage, pageLimit]);

  const pageChange = (event: GridPageChangeEvent) => {
    const newPage = event.page.skip / event.page.take;
    dispatch(setPageLimit(event.page.take));
    dispatch(setCurrentPage(newPage));
  };

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleEdit = (inward_guid: any) => {
      navigate("/inward/edit", {
        state: { inward_guid: inward_guid },
      });
    };
    const handleOpenDeleteDialog = (inward_guid: any) => {
      dispatch(openDialog("deleteInward"));
      dispatch(setInwardID(inward_guid));
    };
    const handleItemView = (inward_guid: any) => {
      dispatch(openDialog("inwardItemView"));
      dispatch(setInwardID(inward_guid));
    };
    return (
      <td
        className={`action-td ${props.className}`}
        style={{
          ...props.style,
          background: props.dataItem?.isdelivered === true ? "#fff" : "#ffdada",
        }}
      >
        {isEditAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Edit"
            ref={isEditBtnRef}
            onClick={() => {
              isEditBtnRef.current = true;
              handleEdit(props.dataItem?.inward_guid);
            }}
          >
            <FiEdit
              className="absolute-position"
              style={{ fontSize: "20px" }}
            />
          </IconButton>
        )}
        {isDeleteAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Delete"
            onClick={() => handleOpenDeleteDialog(props.dataItem?.inward_guid)}
          >
            <MdDelete
              className="absolute-position"
              style={{ fontSize: "24px" }}
            />
          </IconButton>
        )}
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="View"
          onClick={() => handleItemView(props.dataItem?.inward_guid)}
        >
          <FaEye className="absolute-position" style={{ fontSize: "20px" }} />
        </IconButton>
      </td>
    );
  };

  const rowRender = (
    trElement: React.ReactElement<HTMLTableRowElement>,
    props: GridRowProps
  ) => {
    const isdelivered = props.dataItem.isdelivered;
    const deliver = { backgroundColor: "#ffdada" };
    const notDelivered = { backgroundColor: "transparent" };
    const trProps: any = { style: isdelivered ? notDelivered : deliver };
    let childrenArray: React.ReactNode[] = [];

    if (trElement.props.children) {
      if (trElement.props.children instanceof HTMLCollection) {
        childrenArray = Array.from(trElement.props.children).map(
          (child: any) => {
            return React.isValidElement(child) ? child : <>{child}</>;
          }
        );
      } else {
        childrenArray = [trElement.props.children];
      }
    }

    return React.cloneElement(trElement, { ...trProps }, childrenArray);
  };

  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
  };
  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className={`table-header ${props.index === 8 ? "locked-header" : ""}`}
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  return (
    <>
      {loading && <LoadingPanel gridRef={gridRef} />}
      <KendoGrid
        rowRender={rowRender}
        style={{ maxHeight: "calc(100vh - 152px)" }}
        className="responsive-table"
        filterable={true}
        filter={filter}
        data={filter ? filterBy(InwardList, filter) : InwardList}
        onFilterChange={handleFilterChange}
        total={InwardList[0]?.total_item_count || InwardList.length}
        skip={currentPage * pageLimit}
        take={pageLimit}
        pageable={{
          buttonCount: 5,
          pageSizes: [5, 10, 50, 100, 300, 500],
          pageSizeValue: pageLimit,
          type: "input",
        }}
        onPageChange={pageChange}
        cells={{
          headerCell: HeaderCustomCell,
          data: MyDataCustomCell,
        }}
      >
        <Column
          field="inward_no"
          title="Inward No"
          width={120}
          filterable={false}
          cell={(props: any) => (
            <td>
              <div
                className={isEditAccess ? "hoverableName" : "normalName"}
                onClick={() =>
                  isEditAccess &&
                  navigate("/inward/edit", {
                    state: {
                      inward_guid: props.dataItem?.inward_guid,
                    },
                  })
                }
              >
                {props.dataItem?.inward_no}
              </div>
            </td>
          )}
        />
        <Column field="inward_date" title="Inward Date" width={150} />
        <Column
          field="po_no"
          title="PO No"
          width={100}
          cell={(props: any) => (
            <td className="text-end">{props.dataItem?.po_no}</td>
          )}
        />
        <Column
          field="account_name"
          title="Vendor"
          width={250}
          filterCell={CommonFilterCell}
        />
        <Column
          field="chalan_no"
          title="Chalan No"
          width={150}
          filterCell={CommonFilterCell}
        />
        <Column field="bill_no" title="Bill No" width={150} />
        <Column field="bill_date" title="Bill Date" width={150} />
        <Column
          field="vehicle_no"
          title="Vehicle No"
          width={150}
          filterCell={CommonFilterCell}
        />
        {(isEditAccess || isDeleteAccess) && (
          <Column
            field="id"
            title="Actions"
            cell={MyEditCommandCell}
            width={"140px"}
            filterable={false}
            locked={true}
          />
        )}
      </KendoGrid>
    </>
  );
};

const DeleteInwardDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const InwardID = useAppSelector((state) => state.inward.InwardID);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);
  const FilterCustomInward: any = useAppSelector(
    (state) => state.inward.FilterCustomInward
  );

  const handleDeleteAction = async (ID: number | null) => {
    if (ID) {
      const response = await dispatch(deleteInward(ID));
      dispatch(setCurrentPage(0));
      const payload = {
        ...FilterCustomInward,
        financial_year: FINANCIAL_YEAR,
        page_no: +currentPage + 1,
        page_size: +pageLimit,
      };
      if (response.payload.Data.Status === 200) {
        dispatch(getAllInwards(payload));
        dispatch(closeDialog());
        dispatch(clearInwardID());
      } else {
        dispatch(getAllInwards(payload));
        dispatch(closeDialog());
        dispatch(clearInwardID());
      }
    }
  };

  return (
    <>
      {dialogName === "deleteInward" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Inward"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this Inward?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(InwardID)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

const InwardChildItemDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);
  const InwardID = useAppSelector((state) => state.inward.InwardID);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const InwardDetail = useAppSelector((state) => state.inward.InwardDetail);
  const loading = useAppSelector((state) => state.inward.loading);

  useEffect(() => {
    if (InwardID) {
      const payload = {
        inward_guid: InwardID,
      };
      dispatch(getInwardByID(payload));
    }
  }, [InwardID]);

  useEffect(() => {
    return () => {
      dispatch(clearInwardDetails());
    };
  }, []);

  let totalDagina = 0;
  let totalAmount = 0;

  InwardDetail &&
    InwardDetail?.inward_items &&
    InwardDetail?.inward_items.length > 0 &&
    InwardDetail?.inward_items?.forEach((item: any) => {
      totalDagina += item?.dagina || 0;
      totalAmount += item?.total || 0;
    });

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      {dialogName === "inwardItemView" && (
        <AppDialog className="inward-items-dialog">
          <>
            <Typography.h5>{"Inward Items"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                {InwardDetail &&
                InwardDetail?.inward_items &&
                InwardDetail?.inward_items.length > 0 ? (
                  <div
                    className="table-responsive m-0"
                    style={{
                      maxHeight: "calc(100vh - 227px)",
                      scrollbarWidth: "thin",
                    }}
                  >
                    <table className="table table-bordered m-0">
                      <thead>
                        <tr>
                          <th style={{ background: "black", color: "white" }}>
                            No
                          </th>
                          <th style={{ background: "black", color: "white" }}>
                            Item
                          </th>
                          <th style={{ background: "black", color: "white" }}>
                            Weight (Qty)
                          </th>
                          <th style={{ background: "black", color: "white" }}>
                            Rate
                          </th>
                          <th style={{ background: "black", color: "white" }}>
                            Unit
                          </th>
                          <th style={{ background: "black", color: "white" }}>
                            Discount
                          </th>
                          <th style={{ background: "black", color: "white" }}>
                            Dagina
                          </th>
                          <th style={{ background: "black", color: "white" }}>
                            Total
                          </th>
                          <th style={{ background: "black", color: "white" }}>
                            Warehouse
                          </th>
                          <th style={{ background: "black", color: "white" }}>
                            Warehouse Receipt No
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {InwardDetail?.inward_items?.map(
                          (item: any, index: number) => (
                            <tr>
                              <td>{index + 1}</td>
                              <td>{item?.item_name}</td>
                              <td className="text-end">
                                {formatIndianNumberForQty(item?.weight || 0)}
                              </td>
                              <td className="text-end">
                                {formatIndianNumber(item?.rate || 0, 4)}
                              </td>
                              <td>{item?.unit_name}</td>
                              <td className="text-end">
                                {item?.discount}{" "}
                                {item?.discount && item?.discount_type
                                  ? item?.discount_type
                                  : ""}
                              </td>
                              <td className="text-end">{item?.dagina}</td>
                              <td className="text-end">
                                {item?.total
                                  ? formatIndianNumber(item?.total)
                                  : ""}
                              </td>
                              <td>{item?.warehouse_name}</td>
                              <td>{item?.receipt_no}</td>
                            </tr>
                          )
                        )}
                        <tr className="fw-bold">
                          <td
                            colSpan={6}
                            className="text-end pe-5"
                            style={{
                              background: "lightgray",
                              color: "black",
                            }}
                          >
                            Total
                          </td>
                          <td
                            style={{
                              textAlign: "end",
                              background: "lightgray",
                              color: "black",
                            }}
                          >
                            {totalDagina}
                          </td>
                          <td
                            style={{
                              textAlign: "end",
                              background: "lightgray",
                              color: "black",
                            }}
                          >
                            {formatIndianNumber(Math.round(totalAmount))}
                          </td>
                          <td
                            style={{
                              background: "lightgray",
                              color: "black",
                            }}
                          ></td>
                          <td
                            style={{
                              background: "lightgray",
                              color: "black",
                            }}
                          ></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <AlertBox style={{ marginBottom: 10 }} />
                )}
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginTop: 20,
                }}
              >
                <DialogCloseButton themeColor="error" />
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

const CommonFilterCell = (props: any) => {
  const dispatch = useAppDispatch();
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);
  const FilterCustomInward: any = useAppSelector(
    (state) => state.inward.FilterCustomInward
  );
  const isFilterRef = useRef(true);
  const [inputValue, setInputValue] = useState(props.value || "");
  const [inputField, setInputField] = useState(props.field || "");

  const onChange = (event: any) => {
    setInputValue(event.target.value);
    setInputField(event.target.name);
  };

  const delayedApiCall = React.useCallback(
    _debounce((updatedFilters: any) => {
      dispatch(setCurrentPage(0));
      const payload = {
        ...updatedFilters,
        financial_year: FINANCIAL_YEAR,
        page_no: +currentPage + 1,
        page_size: +pageLimit,
      };

      dispatch(getAllInwards(payload));
    }, 300),
    [dispatch]
  );

  useEffect(() => {
    if (!isFilterRef.current) {
      const updatedFilters = {
        ...FilterCustomInward,
        [inputField]: inputValue,
      };
      dispatch(setFilterCustomInward(updatedFilters));
      delayedApiCall(updatedFilters);
    } else {
      isFilterRef.current = false;
    }
    return () => {
      delayedApiCall.cancel();
    };
  }, [inputValue, delayedApiCall]);

  const onClearButtonClick = (event: any) => {
    event.preventDefault();
    props.onChange({
      value: null,
      operator: "",
      syntheticEvent: event,
    });

    const updatedFilters = { ...FilterCustomInward, [props.field]: "" };
    dispatch(setFilterCustomInward(updatedFilters));

    const payload = {
      ...updatedFilters,
      financial_year: FINANCIAL_YEAR,
      page_no: +currentPage + 1,
      page_size: +pageLimit,
    };

    dispatch(getAllInwards(payload));
  };

  return (
    <div className="d-flex">
      <TextBox
        value={FilterCustomInward[props.field] || ""}
        name={props.field || ""}
        onChange={onChange}
      />
      <Button
        svgIcon={filterClearIcon}
        title="Clear"
        disabled={!FilterCustomInward[props.field]}
        onClick={onClearButtonClick}
        type="button"
        style={{
          marginLeft: "8px",
        }}
      ></Button>
    </div>
  );
};

export default Inward;
