import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISchemeInitialState } from "./schemeModel";
import {
  createScheme,
  deleteScheme,
  getAllSchemes,
  getSchemeByID,
  updateScheme,
} from "./services/scheme.services";

const initialState: ISchemeInitialState = {
  loading: false,
  error: "",
  SchemeList: [],
  SchemeID: null,
  SchemeDetail: {
    scheme_name: "",
    display_name: "",
    from_date: "",
    to_date: "",
    remarks: "",
    scheme_items: [],
  },
};

const schemeSlice = createSlice({
  name: "scheme",
  initialState,
  reducers: {
    clearSchemeDetails: (state) => {
      state.SchemeDetail = initialState.SchemeDetail;
    },
    setSchemeID: (state, action) => {
      state.SchemeID = action.payload;
    },
    clearSchemeID: (state) => {
      state.SchemeID = initialState.SchemeID;
    },
    clearSchemeList: (state) => {
      state.SchemeList = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllSchemes.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllSchemes.fulfilled,
      (state, action: PayloadAction<any[]>) => {
        state.loading = false;
        state.SchemeList = action.payload || [];
      }
    );
    builder.addCase(getAllSchemes.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.SchemeList = [];
    });
    builder.addCase(createScheme.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(createScheme.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createScheme.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateScheme.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateScheme.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateScheme.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteScheme.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteScheme.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteScheme.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getSchemeByID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getSchemeByID.fulfilled, (state, action: any) => {
      state.loading = false;
      state.SchemeDetail = action.payload;
    });
    builder.addCase(getSchemeByID.rejected, (state, action) => {
      state.loading = false;
      state.SchemeDetail = initialState.SchemeDetail;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearSchemeDetails,
  setSchemeID,
  clearSchemeID,
  clearSchemeList,
} = schemeSlice.actions;
export default schemeSlice.reducer;
