import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { API_URL } from "../../../app/config";
import { IGst } from "../gstModel";

export const getAllGST = createAsyncThunk("Gst/FindAllGst", async () => {
  try {
    const response = await axiosApiInstance.post(`${API_URL}/GST/FindAllGST`, {
      GST: "",
    });
    return response.data?.Data;
  } catch (error: any) {
    // ErrorToast(error?.response?.data?.Details || "Something went wrong");
    console.error("Error Fetching GST:", error);
    return Promise.reject({
      message: error?.response?.data?.Details || "Something went wrong",
    });
  }
});

export const getGstByID = createAsyncThunk(
  "Gst/FindByIDGst",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Gst/FindByIDGst`,
        { gst_guid: ID }
      );
      const result = response.data?.Data as any;
      return {
        gst_guid: result.gst_guid,
        gst_title: result.gst_title,
        gst: result.gst,
        cgst: result.cgst,
        sgst: result.sgst,
        igst: result.igst,
        isactive: result.isactive === true ? 1 : 2,
      } as IGst;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching company:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createGST = createAsyncThunk(
  "Gst/InsertGst",
  async (GstData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/GST/InsertGST`,
        GstData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating GST:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const UpdateGST = createAsyncThunk(
  "GST/UpdateGST",
  async (GstData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/GST/UpdateGST`,
        GstData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating company:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const DeleteGST = createAsyncThunk(
  "GST/DeleteGST",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(`${API_URL}/GST/DeleteGST`, {
        gst_guid: ID,
      });
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting GST:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveGST = createAsyncThunk(
  "Gst/FindAllActiveGst",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Gst/FindAllActiveGst`
      );
      return response.data?.Data;
    } catch (error: any) {
      console.error("Error Fetching GST:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const ActiveInactiveGST = createAsyncThunk(
  "Gst/ActiveInActiveGst",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Gst/ActiveInActiveGst`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive GST", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
