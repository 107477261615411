import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ActiveInactiveRetailer,
  createRetailer,
  deleteRetailer,
  findAllActiveRetailerPriceList,
  getAllActiveRetailers,
  getAllRetailers,
  getRetailerByID,
  updateRetailer,
} from "./services/retailer.services";
import { IRetailer, IRetailerInitialState } from "./retailerModel";

const initialState: IRetailerInitialState = {
  loading: false,
  error: "",
  RetailerList: [],
  RetailerPriceList: [],
  RetailerID: null,
  RetailerDetail: {
    retailer_name: "",
    contact_person: "",
    email_id: "",
    mobile_no: null,
    address1: "",
    // address2: "",
    city_id: null,
    pincode: "",
    state_id: null,
    country_id: null,
    area_id: null,
    sequence_no: "",
    isactive: 1,
  },
  FilterRetailer: {
    retailer_name: "",
  },
};

const retailerSlice = createSlice({
  name: "retailer",
  initialState,
  reducers: {
    clearRetailerDetails: (state) => {
      state.RetailerDetail = initialState.RetailerDetail;
    },
    setRetailerID: (state, action) => {
      state.RetailerID = action.payload;
    },
    clearRetailerID: (state) => {
      state.RetailerID = initialState.RetailerID;
    },
    clearRetailerList: (state) => {
      state.RetailerList = [];
    },
    setFilterRetailer: (state, action) => {
      state.FilterRetailer = action.payload;
    },

    clearFilterRetailer: (state) => {
      state.FilterRetailer = initialState.FilterRetailer;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllRetailers.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllRetailers.fulfilled,
      (state, action: PayloadAction<IRetailer[]>) => {
        state.loading = false;
        state.RetailerList = action.payload || [];
      }
    );
    builder.addCase(getAllRetailers.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.RetailerList = [];
    });

    builder.addCase(createRetailer.pending, (state) => {
      state.loading = false;
      state.error = "";
    });
    builder.addCase(createRetailer.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createRetailer.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(updateRetailer.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateRetailer.fulfilled, (state) => {
      state.loading = true;
    });
    builder.addCase(updateRetailer.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(deleteRetailer.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteRetailer.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteRetailer.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getRetailerByID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getRetailerByID.fulfilled, (state, action: any) => {
      state.loading = false;
      state.RetailerDetail = action.payload;
    });
    builder.addCase(getRetailerByID.rejected, (state, action) => {
      state.loading = false;
      state.RetailerDetail = initialState.RetailerDetail;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getAllActiveRetailers.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveRetailers.fulfilled,
      (state, action: PayloadAction<IRetailer[]>) => {
        state.loading = false;
        state.RetailerList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveRetailers.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.RetailerList = [];
    });

    builder.addCase(ActiveInactiveRetailer.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(ActiveInactiveRetailer.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(ActiveInactiveRetailer.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(findAllActiveRetailerPriceList.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      findAllActiveRetailerPriceList.fulfilled,
      (state, action: PayloadAction<IRetailer[]>) => {
        state.loading = false;
        state.RetailerPriceList = action.payload || [];
      }
    );
    builder.addCase(findAllActiveRetailerPriceList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.RetailerPriceList = [];
    });
  },
});

export const {
  clearRetailerDetails,
  setRetailerID,
  clearRetailerID,
  clearRetailerList,
  clearFilterRetailer,
  setFilterRetailer,
} = retailerSlice.actions;
export default retailerSlice.reducer;
