import React from "react";
import { Field, FieldArrayRenderProps } from "@progress/kendo-react-form";
import { GridLayoutItem } from "@progress/kendo-react-layout";
import FormIncrementalMultiSelectionFiled from "../../components/formFields/FormIncrementalMultiSelectionFiled";
const DistributorDetailsArray = (
  fieldArrayRenderProps: FieldArrayRenderProps
) => {
  return (
    <>
      {fieldArrayRenderProps?.value &&
        fieldArrayRenderProps.value.length > 0 &&
        fieldArrayRenderProps?.value.map(
          (distributor_details: any, innerIndex: number) => (
            <GridLayoutItem>
              <Field
                wrapperStyle={{ margin: 0 }}
                name={`${fieldArrayRenderProps?.name}.${innerIndex}.area_assign_id`}
                placeholder="Type for distributor.."
                component={FormIncrementalMultiSelectionFiled}
                fetchIncrementalData={(search: any) =>
                  fieldArrayRenderProps.handleDistributorSearchChange(
                    search,
                    `${fieldArrayRenderProps?.name}.${innerIndex}`,
                    fieldArrayRenderProps.formRenderProps
                  )
                }
                options={
                  fieldArrayRenderProps.value[innerIndex]?.vendor_options?.map(
                    (item: any) => {
                      return {
                        value: item?.id,
                        label: `${item?.distributor_name?.toUpperCase()} - ${item?.area_name?.toUpperCase()}`,
                      };
                    }
                  ) || []
                }
              />
            </GridLayoutItem>
          )
        )}
    </>
  );
};

export default DistributorDetailsArray;
